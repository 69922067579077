import React from 'react';
import { injectIntl, FormattedMessage, defineMessages, FormattedHTMLMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import 'moment/locale/pt-br'; // FIXME locale
import Typography from '@material-ui/core/Typography';
import HumanizeDuration from 'humanize-duration';
import Moment from 'moment';
import LocaleFormatter from '../../Common/LocaleFormatter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import CardAvatar from '../ApplicantCard/CardAvatar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MatchInfo from '../ApplicantCard/MatchInfo';
import AddressController from '../../Common/AddressController';
import ChipHired from '../ApplicantCard/ChipHired';
import ChipSource from '../ApplicantCard/ChipSource';
import ChipInternal from '../ApplicantCard/ChipInternal';
import Utils from '../../Common/Utils';
import UserProfile from '../../Common/UserProfile';
import DetailLocation from '../../Applicant/Detail/DetailLocation';
import Popover from '@material-ui/core/Popover';
import Api from '../../Api/Api';
import CardApplicantMap from '../../Applicant/Card/CardApplicantMap';
import AISuggestionsIcon from '../../Jobs/CrossRank/AISuggestionsIcon';
import striptags from 'striptags';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PhoneActions from './PhoneActions';
import EmailActions from './EmailActions';
import ChipPwd from '../ApplicantCard/ChipPwd';

const messages = defineMessages({
  proficiency_linkedin_ELEMENTARY: {
    id: 'CVProfileInfo.proficiency_linkedin_ELEMENTARY',
    defaultMessage: 'Elementary'
  },
  proficiency_linkedin_LIMITED_WORKING: {
    id: 'CVProfileInfo.proficiency_linkedin_LIMITED_WORKING',
    defaultMessage: 'Limited working'
  },
  proficiency_linkedin_MINIMUM_PROFESSIONAL: {
    id: 'CVProfileInfo.proficiency_linkedin_MINIMUM_PROFESSIONAL',
    defaultMessage: 'Minimum professional'
  },
  proficiency_linkedin_PROFESSIONAL_WORKING: {
    id: 'CVProfileInfo.proficiency_linkedin_PROFESSIONAL_WORKING',
    defaultMessage: 'Full professional'
  },
  proficiency_linkedin_FULL_PROFESSIONAL: {
    id: 'CVProfileInfo.proficiency_linkedin_FULL_PROFESSIONAL',
    defaultMessage: 'Full professional'
  },
  proficiency_linkedin_NATIVE_OR_BILINGUAL: {
    id: 'CVProfileInfo.proficiency_linkedin_NATIVE_OR_BILINGUAL',
    defaultMessage: 'Native or bilingual'
  },
  proficiency_basic: {
    id: 'CVProfileInfo.proficiency_basic',
    defaultMessage: 'Basic'
  },
  proficiency_intermediate: {
    id: 'CVProfileInfo.proficiency_intermediate',
    defaultMessage: 'Intermediate'
  },
  proficiency_advanced: {
    id: 'CVProfileInfo.proficiency_advanced',
    defaultMessage: 'Advanced'
  },
  proficiency_native: {
    id: 'CVProfileInfo.proficiency_native',
    defaultMessage: 'Native'
  },
  present: {
    id: 'CVProfileInfo.present',
    defaultMessage: 'Present'
  }
});

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

const HtmlEntities = require('html-entities').AllHtmlEntities;
const ents = new HtmlEntities();

const useStyles = (theme) => ({
  mainContainer: {
    background: theme.palette.common.white,
    padding: '2rem 1.5rem',
    borderRadius: '1rem'
  },
  optionList: {
    //height: 200,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  heading: {
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: 'normal'
  },
  aIheading: {
    color: theme.palette.success.main
  },
  matchHeading: {
    color: theme.palette.success.main,
    lineHeight: '1'
  },
  scoreMatch: {
    fontSize: '2rem',
    fontWeight: 700
  },
  subHeading: {
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  cvElement: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium,
    fontWeight: 400
  },
  inactiveSeeMore: {
    color: theme.palette.gray.darkerMedium,
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.gray.darkerMedium
    }
  },
  activeSeeMore: {
    color: theme.palette.primary.main,
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  aICvElement: {
    fontSize: '14px',
    color: theme.palette.success.main,
    fontWeight: 400
  },
  aiSummaryContainer: {
    backgroundColor: theme.palette.success.light,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    borderRadius: '10px'
  },
  darkCvElement: {
    fontSize: '14px',
    color: theme.palette.gray.darker,
    fontWeight: 400
  },
  expandFilters: {
    color: theme.palette.gray.darkerLight,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandFiltersOpen: {
    transform: 'rotate(180deg)'
  },
  diversityText: {
    color: theme.palette.purple.dark
  },
  expandAboutButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.gray.darkerLight}`,
    padding: '3px 8px'
  },
  moreInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  diversityElementsContainer: {
    display: 'flex',
    margin: '0 0 16px 20px',
    flexWrap: 'wrap',
    gap: '16px 24px'
  },
  generalChip: {
    background: theme.palette.gray.lighter,
    fontSize: '14px',
    fontWeight: 400
    // marginLeft: theme.spacing(1)
  }
});

const StyledChip = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.purple.dark,
    border: `1px solid ${theme.palette.purple.dark}`,
    fontSize: '14px',
    padding: '0.25rem'
  }
}))(Chip);

const IndustryChip = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main
  }
}))(Chip);

class CVProfileInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Props from parent
      avatar_url: props.profile ? props.profile.avatar_url : undefined,
      name: props.profile ? props.profile.name : undefined,
      //company: props.profile ? props.profile.company : undefined,
      headline: props.profile ? props.profile.title_min : undefined,
      summary: undefined,
      cv_profile: props.profile ? props.profile.cv_profile : undefined,
      profile: props.profile,
      job: props.job,
      // Internal state
      educations: [],
      experiences: [],
      languages: [],
      skills: [],
      certifications: [],
      company: '',
      endDateCompany: '',
      startDateCompany: '',
      currentEmail: '',
      currentPhone: '',
      expandAbout: false,
      industries: [],
      linkedinIndustries: [],
      expandSkills: false,
      firstJobInfo: {},
      // Popover
      anchorEl: null,
      emailAnchorEl: null,
      addressAnchorEl: null,
      phoneAnchorEl: null
    };
  }

  componentDidMount() {
    this.setIndustries();
    this.getFirstJobInfo();
    this.mountProfile();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile !== this.props.profile) {
      if (this.props.profile) {
        this.setState(
          {
            cv_profile: this.props.profile.cv_profile,
            avatar_url: this.props.profile.avatar_url,
            name: this.props.profile.name,
            headline: this.props.profile.title_min,
            profile: this.props.profile
          },
          () => {
            this.getFirstJobInfo();
            this.mountProfile();
          }
        );
      }
    }
    if (prevProps.job !== this.props.job) {
      if (this.props.job) {
        this.setState({
          job: this.props.job
        });
      }
    }
  }

  setIndustries = () => {
    const industries = this.props.industries.industries;
    const linkedinIndustries = this.props.linkedinIndustries.linkedinIndustries;

    this.setState(
      {
        industries: industries,
        linkedinIndustries: linkedinIndustries
      },
      () => {
        this.mountProfile();
      }
    );
  };

  mountProfile = () => {
    if (this.state.cv_profile) {
      const profile = this.state.profile;
      const cv_profile = this.state.cv_profile;
      const extract_industry = profile.extract_industry;

      const company =
        cv_profile.experiences && cv_profile.experiences.length > 0
          ? cv_profile.experiences[0].company
          : '';

      let endDateCompany = '';
      let startDateCompany = '';

      if (cv_profile.experiences && cv_profile.experiences.length > 0) {
        if (cv_profile.experiences[0].endDate) {
          if (Utils.matchPresentDate(cv_profile.experiences[0].endDate)) {
            endDateCompany = (
              <span>
                {this.props.intl.formatMessage({
                  id: 'CVProfileInfo.present'
                })}
              </span>
            );
          } else if (Utils.isValidDate(new Date(cv_profile.experiences[0].endDate))) {
            endDateCompany = (
              <LocaleFormatter
                dateToFormat={Moment(new Date(cv_profile.experiences[0].endDate))
                  .utc()
                  .format('MMM/YYYY')}
              />
            );
          }
        }

        if (cv_profile.experiences[0].startDate) {
          if (Utils.matchPresentDate(cv_profile.experiences[0].startDate)) {
            startDateCompany = (
              <span>
                {this.props.intl.formatMessage({
                  id: 'CVProfileInfo.present'
                })}
              </span>
            );
          } else if (Utils.isValidDate(new Date(cv_profile.experiences[0].startDate))) {
            startDateCompany = (
              <LocaleFormatter
                dateToFormat={Moment(new Date(cv_profile.experiences[0].startDate))
                  .utc()
                  .format('MMM/YYYY')}
              />
            );
          }
        }

        cv_profile.experiences.forEach((currExperience) => {
          const companyName = currExperience.company;

          const industry = extract_industry?.find(
            (currIndustry) => currIndustry.company === companyName
          );

          if (industry) {
            const linkedinIndustry = this.state.linkedinIndustries.find(
              (currLinkedInIndustry) => currLinkedInIndustry.code_full === industry.parents[0]
            );

            if (linkedinIndustry) {
              let convertedIndustry = this.state.industries.find(
                (currIndustry) => currIndustry.code === linkedinIndustry.industry_code
              );

              currExperience.industryTitle = convertedIndustry ? convertedIndustry.name : '';
            }
          }

          currExperience.textExpanded = false;
        });
      }

      let currentEmail = '';
      let currentPhone = '';

      profile.linkedinApplicants && profile.linkedinApplicants.length > 0;

      if (profile.linkedinApplicants && profile.linkedinApplicants.length > 0) {
        currentEmail = profile.linkedinApplicants[0].email;
        currentPhone = profile.linkedinApplicants[0].phone;
      } else if (profile.email || profile.phone_mobile) {
        if (profile.email) {
          currentEmail = profile.email;
        }
        if (profile.phone_mobile) {
          currentPhone = profile.phone_mobile;
        }
      }

      let summary = cv_profile.summary ? cv_profile.summary : cv_profile.title;

      if (summary && summary.length >= 270 && this.state.expandAbout === false) {
        summary = summary.slice(0, 269) + '...';
      }

      this.setState({
        educations: cv_profile.educations,
        experiences: cv_profile.experiences,
        languages: cv_profile.languages,
        skills: cv_profile.skills,
        certifications: cv_profile.certifications,
        company: company,
        startDateCompany: startDateCompany,
        endDateCompany: endDateCompany,
        currentEmail: currentEmail,
        currentPhone: currentPhone,
        summary: summary
      });
    }
  };

  getFirstJobInfo = () => {
    let api = new Api();
    if (this.props.profile && this.props.profile.firstjobid) {
      let filter = {
        fields: ['title', 'type', 'account_page_id'],
        where: {
          id: this.props.profile.firstjobid
        },
        include: {
          relation: 'accountPage',
          scope: {
            fields: ['id', 'path']
          }
        }
      };

      api.get('BaseJobs', filter, (status, data) => {
        if (data && data.length > 0) {
          // console.log(data)
          this.setState({
            firstJobInfo: data[0]
          });
        } else {
          this.setState({
            firstJobInfo: {}
          });
        }
      });
    }
  };

  handleOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleClose = () => {
    if (this.props.handleClose) {
      this.props.handleClose();
    }
  };

  handleOpenEmail = (e) => {
    this.setState({
      emailAnchorEl: e.currentTarget
    });
  };

  handleCloseEmail = () => {
    this.setState({
      emailAnchorEl: null
    });
  };

  handleOpenAddress = (e) => {
    this.setState({
      addressAnchorEl: e.currentTarget
    });
  };

  handleCloseAddress = () => {
    this.setState({
      addressAnchorEl: null
    });
  };

  handleOpenPhone = (e) => {
    this.setState({
      phoneAnchorEl: e.currentTarget
    });
  };

  handleClosePhone = () => {
    this.setState({
      phoneAnchorEl: null
    });
  };

  handleExpandAbout = () => {
    const cv_profile = this.state.cv_profile;
    let summary = cv_profile.summary ? cv_profile.summary : cv_profile.title;

    // Currently open and it's gonna be closed
    if (this.state.expandAbout === true && summary && summary.length >= 270) {
      summary = summary.slice(0, 269) + '...';
    }

    this.setState({ expandAbout: !this.state.expandAbout, summary: summary });
  };

  handleExpandCurrentExperience = (experienceIndex) => {
    const experiences = this.state.experiences;

    const editedExperience = experiences[experienceIndex];
    editedExperience.textExpanded = !editedExperience.textExpanded;

    experiences.splice(experienceIndex, 1, editedExperience);

    this.setState({
      experiences: experiences
    });
  };

  handleExpandSkills = () => {
    this.setState({
      expandSkills: !this.state.expandSkills
    });
  };

  handleUpdateFavorites = (applicantId, editedFavorites) => {
    if (this.props.handleUpdateFavorites) {
      this.props.handleUpdateFavorites(applicantId, editedFavorites);
    }
  };

  render() {
    const { classes } = this.props;

    const profile = this.state.profile;

    const addressOpen = Boolean(this.state.addressAnchorEl);
    const emailOpen = Boolean(this.state.emailAnchorEl);
    const phoneOpen = Boolean(this.state.phoneAnchorEl);

    const job = this.state.job;

    const cv_score = profile.cv_score;
    const hasMatchInfo = !!cv_score;

    let applicantOrigin = profile.origin;

    if (applicantOrigin === 'SCRAP') {
      applicantOrigin = 'HUNTING';
    }

    if (applicantOrigin === 'APPLY') {
      applicantOrigin = 'FORM';
    }

    if (applicantOrigin === 'MAILIN') {
      applicantOrigin = 'MAIL';
    }

    const formatttedOriginString = applicantOrigin
      ? applicantOrigin.slice(0, 1).toUpperCase() + applicantOrigin.slice(1).toLowerCase()
      : null;

    let pso = '';
    let pwr = '';
    let gender = '';

    let address = undefined;
    if (profile.address && Object.keys(profile.address).length > 0) {
      address = profile.address;
    }

    if (
      (UserProfile.isAdmin() || UserProfile.isDiversity()) &&
      profile.pso &&
      profile.pso !== 'no'
    ) {
      switch (profile.pso) {
        case 'A':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.heterosexual'
          });
          break;
        case 'B':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.lesbian'
          });
          break;
        case 'C':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.gay'
          });
          break;
        case 'D':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.bisexual'
          });
          break;
        case 'E':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.transgender'
          });
          break;
        case 'F':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.queer'
          });
          break;
        case 'G':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.intersexuals'
          });
          break;
        case 'H':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.asexuals'
          });
          break;
        case 'I':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.pansexual'
          });
          break;
        case 'J':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.other'
          });
          break;
        case 'OPTOUT':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.opt_out'
          });
          break;
      }
    }

    if (
      (UserProfile.isAdmin() || UserProfile.isDiversity()) &&
      profile.pwr &&
      profile.pwr !== 'no'
    ) {
      switch (profile.pwr) {
        case 'A':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.white'
          });
          break;
        case 'B':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.indigenous'
          });
          break;
        case 'C':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.yellow'
          });
          break;
        case 'D':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.black'
          });
          break;
        case 'E':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.brown'
          });
          break;
        case 'F':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.other'
          });
          break;
        case 'OPTOUT':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.opt_out'
          });
          break;
      }
    }

    if (profile.gender && profile.gender !== '') {
      switch (profile.gender) {
        case 'M':
          gender = this.props.intl.formatMessage({ id: 'InfoGender.male' });
          break;
        case 'F':
          gender = this.props.intl.formatMessage({ id: 'InfoGender.female' });
          break;
        case 'NB':
          gender = this.props.intl.formatMessage({ id: 'InfoGender.nonBinary' });
          break;
        case 'O':
          gender = this.props.intl.formatMessage({ id: 'InfoGender.other' });
          break;
        case 'OPTOUT':
          gender = this.props.intl.formatMessage({ id: 'InfoGender.optOut' });
          break;
      }
    }

    const hasDiversity = pso || pwr || gender;

    const hasEmail = this.state.currentEmail && !this.state.currentEmail.startsWith('profile:');
    const hasDocNumber = this.state.profile && this.state.profile.doc_number;
    const chipsTitle =
      this.props.variant !== 'hunting' ? (
        <Box display="flex" alignItems="center">
          {this.state.profile.pwd && this.state.profile.pwd != 'no' ? (
            <Box mr={1}>
              <ChipPwd />
            </Box>
          ) : null}
          {this.state.profile.hired ? (
            <Box mr={1}>
              <ChipHired hired={true} />
            </Box>
          ) : null}
          <Box mr={1}>
            <ChipSource
              origin={this.state.profile.origin}
              referral={this.state.profile.referral_id}
            />
          </Box>
          {this.state.profile.internal ? (
            <Box>
              <ChipInternal
                internal_verified={this.state.profile.internal_verified}
                employee_id={this.state.profile.employee_id}
                employee_email={this.state.profile.email}
              />
            </Box>
          ) : null}
        </Box>
      ) : null;

    const displayExperiences = () => {
      const experiences = this.state.experiences;

      if (experiences && experiences.length > 0) {
        return (
          <>
            <Box mt={3}>
              <Box mb={3}>
                <Typography className={classes.heading}>
                  <FormattedMessage id="CardCVExperience.title" />
                </Typography>
              </Box>
              <Box>
                <Box display="flex" flexDirection="column" ml={2.5}>
                  {experiences.map((currExperience, i) => {
                    const endDate =
                      currExperience.endDate && !Utils.matchPresentDate(currExperience.endDate)
                        ? Moment(currExperience.endDate)
                        : Moment();

                    const toe_humanize = currExperience.startDate
                      ? HumanizeDuration(
                          Moment(endDate).diff(Moment(currExperience.startDate), 'days'),
                          {
                            language: this.props.user.language.substr(0, 2),
                            units: ['y', 'mo'],
                            unitMeasures: {
                              y: 365,
                              mo: 30,
                              w: 7,
                              d: 1
                            },
                            spacer: ' ',
                            round: true
                          }
                        )
                      : undefined;

                    let startDateLabel = undefined;
                    let endDateLabel = undefined;

                    if (currExperience.startDate && currExperience.startDate !== '') {
                      if (Utils.isValidDate(new Date(currExperience.startDate))) {
                        startDateLabel = (
                          <LocaleFormatter
                            dateToFormat={Moment(new Date(currExperience.startDate))
                              .utc()
                              .format('MMM/YYYY')}
                          />
                        );
                      }
                    }

                    if (currExperience.endDate && currExperience.endDate !== '') {
                      if (Utils.matchPresentDate(currExperience.endDate)) {
                        endDateLabel = (
                          <span>
                            {this.props.intl.formatMessage({
                              id: 'CVProfileInfo.present'
                            })}
                          </span>
                        );
                      } else if (Utils.isValidDate(new Date(currExperience.endDate))) {
                        endDateLabel = (
                          <LocaleFormatter
                            dateToFormat={Moment(new Date(currExperience.endDate))
                              .utc()
                              .format('MMM/YYYY')}
                          />
                        );
                      }
                    }

                    let experienceSummary = currExperience.text ? currExperience.text : '';

                    let hasMoreThan270Characters =
                      experienceSummary && experienceSummary.length >= 270 ? true : false;

                    if (currExperience.textExpanded === false) {
                      if (experienceSummary && experienceSummary.length >= 270) {
                        experienceSummary = experienceSummary.slice(0, 269) + '...';
                      }
                    }

                    return (
                      <Box mb={2}>
                        <Box display="flex" flexDirection="column" mb={2}>
                          <Box mb={1}>
                            <Typography className={classes.subHeading}>
                              {currExperience.jobTitle}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              className={classes.darkCvElement}
                              style={{
                                margin: 0
                              }}
                            >
                              {currExperience.company}
                            </Typography>

                            {currExperience.industryTitle ? (
                              <>
                                <span
                                  style={{
                                    fontSize: '1rem',
                                    fontWeight: 800,
                                    margin: '0 4px'
                                  }}
                                >
                                  &bull;
                                </span>
                                <Box>
                                  <IndustryChip size="small" label={currExperience.industryTitle} />
                                </Box>
                              </>
                            ) : null}
                          </Box>
                          {startDateLabel ? (
                            <Box>
                              <Typography
                                className={classes.cvElement}
                                style={{
                                  margin: '4px 0 0 0'
                                }}
                              >
                                {startDateLabel}
                                {' - '}
                                {endDateLabel}
                                <span
                                  style={{
                                    fontSize: '1rem',
                                    fontWeight: 800,
                                    margin: '0 4px'
                                  }}
                                >
                                  &bull;
                                </span>
                                {toe_humanize}
                              </Typography>
                            </Box>
                          ) : null}
                          {experienceSummary ? (
                            <Box>
                              <Typography
                                className={classes.darkCvElement}
                                style={{
                                  margin: '8px 0 0 0'
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: ents.decode(
                                      experienceSummary.replace(/(?:\r\n|\r|\n)/g, '<br />')
                                    )
                                  }}
                                />
                                {hasMoreThan270Characters ? (
                                  <>
                                    &nbsp;
                                    <a
                                      className={
                                        currExperience.textExpanded
                                          ? classes.inactiveSeeMore
                                          : classes.activeSeeMore
                                      }
                                      onClick={(e) => this.handleExpandCurrentExperience(i)}
                                    >
                                      {currExperience.textExpanded ? (
                                        <FormattedMessage
                                          id="CVProfileInfo.label_seeLess"
                                          defaultMessage="See less"
                                        />
                                      ) : (
                                        <FormattedMessage
                                          id="CVProfileInfo.label_seeMore"
                                          defaultMessage="See more"
                                        />
                                      )}

                                      {currExperience.textExpanded ? (
                                        <KeyboardArrowUpIcon fontSize="small" color="secondary" />
                                      ) : (
                                        <KeyboardArrowDownIcon fontSize="small" color="primary" />
                                      )}
                                    </a>
                                  </>
                                ) : null}
                              </Typography>
                            </Box>
                          ) : null}
                        </Box>

                        {i !== experiences.length - 1 ? <Divider light /> : null}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displayEducations = () => {
      const educations = this.state.educations;

      if (educations && educations.length > 0) {
        educations.sort(dynamicSort('-startYear'));
        return (
          <>
            <Box mt={!hasDiversity ? 3 : 2.5}>
              <Box mb={3}>
                <Typography className={classes.heading}>
                  <FormattedMessage id="CardAcademicLevel2.title" />
                </Typography>
              </Box>
              <Box>
                {
                  <Box ml={2.5}>
                    {educations.map((currEducation, i) => {
                      let startYearLabel = undefined;
                      let endYearLabel = undefined;

                      if (currEducation.startYear && currEducation.startYear !== '') {
                        const startYear =
                          typeof currEducation.startYear === 'string'
                            ? currEducation.startYear
                            : currEducation.startYear.toString();
                        if (Utils.matchPresentDate(startYear)) {
                          startYearLabel = (
                            <span>
                              {this.props.intl.formatMessage({
                                id: 'CVProfileInfo.present'
                              })}
                            </span>
                          );
                        } else if (Utils.isValidDate(new Date(startYear))) {
                          startYearLabel = (
                            <LocaleFormatter
                              dateToFormat={Moment(new Date(startYear)).utc().format('YYYY')}
                            />
                          );
                        }
                      }

                      if (currEducation.endYear && currEducation.endYear !== '') {
                        const endYear =
                          typeof currEducation.endYear === 'string'
                            ? currEducation.endYear
                            : currEducation.endYear.toString();
                        if (Utils.matchPresentDate(endYear)) {
                          endYearLabel = (
                            <span>
                              {this.props.intl.formatMessage({
                                id: 'CVProfileInfo.present'
                              })}
                            </span>
                          );
                        } else if (Utils.isValidDate(new Date(endYear))) {
                          endYearLabel = (
                            <LocaleFormatter
                              dateToFormat={Moment(new Date(endYear)).utc().format('YYYY')}
                            />
                          );
                        }
                      }

                      return (
                        <Box mb={2}>
                          <Box display="flex" flexDirection="column" mb={2}>
                            <Box mb={1}>
                              <Typography className={classes.subHeading}>
                                {currEducation.degree}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                className={classes.darkCvElement}
                                style={{
                                  margin: 0
                                }}
                              >
                                {currEducation.schoolName}
                              </Typography>
                            </Box>
                            {startYearLabel ? (
                              <Box>
                                <Typography
                                  className={classes.cvElement}
                                  style={{
                                    margin: 0
                                  }}
                                >
                                  {startYearLabel}
                                  {' - '}
                                  {endYearLabel}
                                </Typography>
                              </Box>
                            ) : null}
                          </Box>

                          {i !== educations.length - 1 ? <Divider light /> : null}
                        </Box>
                      );
                    })}
                  </Box>
                }
              </Box>
            </Box>
            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displayDiversity = () => {
      if (hasDiversity) {
        return (
          <>
            <Box mt={2.5}>
              <Box mb={3}>
                <Typography className={`${classes.heading} ${classes.diversityText}`}>
                  <FormattedMessage id="JobAllReport.table.diversity_field" />
                </Typography>
              </Box>
              <Box className={classes.diversityElementsContainer}>
                {pwr ? (
                  <Box display="flex" alignItems="center">
                    <Typography
                      className={classes.cvElement}
                      style={{
                        fontWeight: 700
                      }}
                    >
                      {this.props.intl.formatMessage({ id: 'JobDiversity.table.pwr' })}

                      <span
                        style={{
                          fontSize: '1rem',
                          fontWeight: 800,
                          margin: '0 4px'
                        }}
                      >
                        &bull;
                      </span>
                    </Typography>
                    <StyledChip size="small" label={pwr} />
                  </Box>
                ) : null}
                {gender ? (
                  <Box display="flex" alignItems="center">
                    <Typography
                      className={classes.cvElement}
                      style={{
                        fontWeight: 700
                      }}
                    >
                      {this.props.intl.formatMessage({ id: 'JobDiversity.table.gender' })}
                      <span
                        style={{
                          fontSize: '1rem',
                          fontWeight: 800,
                          margin: '0 4px'
                        }}
                      >
                        &bull;
                      </span>
                    </Typography>
                    <StyledChip size="small" label={gender} />
                  </Box>
                ) : null}
                {pso ? (
                  <Box display="flex" alignItems="center">
                    <Typography
                      className={classes.cvElement}
                      style={{
                        fontWeight: 700
                      }}
                    >
                      {this.props.intl.formatMessage({ id: 'DetailSummary.pso' })}

                      <span
                        style={{
                          fontSize: '1rem',
                          fontWeight: 800,
                          margin: '0 4px'
                        }}
                      >
                        &bull;
                      </span>
                    </Typography>
                    <StyledChip size="small" label={pso} />
                  </Box>
                ) : null}
              </Box>
            </Box>

            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displayCertifications = () => {
      const certifications = this.state.certifications;

      if (certifications && certifications.length > 0) {
        return (
          <>
            <Box mt={3}>
              <Box mb={3}>
                <Typography className={classes.heading}>
                  <FormattedMessage
                    id="CVProfileInfo.label_certifications"
                    defaultMessage="Certifications"
                  />
                </Typography>
              </Box>
              <Box>
                {' '}
                <Box display="flex" flexDirection="column" ml={2.5}>
                  {certifications.map((currExperience, i) => {
                    const endDate =
                      currExperience.endDate && currExperience.endDate !== 'present'
                        ? Moment(currExperience.endDate)
                        : Moment();

                    return (
                      <Box mb={2}>
                        <Box display="flex" flexDirection="column" mb={2}>
                          <Box mb={1}>
                            <Typography className={classes.subHeading}>
                              {currExperience.name}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              className={classes.darkCvElement}
                              style={{
                                margin: 0
                              }}
                            >
                              {currExperience.issuer}
                            </Typography>
                            {currExperience.year ? (
                              <Box>
                                <Typography
                                  className={classes.cvElement}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: '1rem',
                                      fontWeight: 800,
                                      margin: '0 4px'
                                    }}
                                  >
                                    &bull;
                                  </span>
                                  {currExperience.year}
                                </Typography>
                              </Box>
                            ) : null}
                          </Box>
                        </Box>

                        {i !== certifications.length - 1 ? <Divider light /> : null}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displayIDDocument = (info) => {
      let docName = '';
      let containerId = 'displayApplicantID';
      const identityDocs = this.props.identityDocs.identityDocs;

      if (identityDocs && identityDocs.length > 0) {
        const applicantDoc = identityDocs.findIndex((doc) => doc.code === info.doc_type);
        if (applicantDoc > -1) {
          docName = identityDocs[applicantDoc].name;
          const docMask = identityDocs[applicantDoc].id_mask;
          AddressController.getMaskedID(containerId, docMask);
        } else {
          docName = this.props.intl.formatMessage({ id: 'IconID.defaultIDLabel' });
        }
      }

      if (info.doc_number) {
        return (
          <Box mb={3}>
            <Box mb={1}>
              <Typography className={classes.subHeading}>{docName}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography id={containerId} className={classes.cvElement}>
                {info.doc_number}
              </Typography>
            </Box>
          </Box>
        );
      } else {
        return null;
      }
    };

    const displayLanguages = () => {
      const languages = this.state.languages;

      if (languages && languages.length > 0) {
        return (
          <>
            <Box mt={3}>
              <Box mb={3}>
                <Typography className={classes.heading}>
                  <FormattedMessage id="IdiomList.title" />
                </Typography>
              </Box>
              <Box>
                <Box display="flex" flexDirection="column" ml={2.5}>
                  {languages.map((currLanguage) => {
                    let proficiency = currLanguage.proficiency;
                    if (currLanguage.proficiency && currLanguage.proficiency !== '') {
                      switch (currLanguage.proficiency) {
                        case 'ELEMENTARY':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_basic'
                          });
                          break;
                        case 'LIMITED_WORKING':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_basic'
                          });
                          break;
                        case 'MINIMUM_PROFESSIONAL':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_intermediate'
                          });
                          break;
                        case 'PROFESSIONAL_WORKING':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_intermediate'
                          });
                          break;
                        case 'FULL_PROFESSIONAL':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_advanced'
                          });
                          break;
                        case 'NATIVE_OR_BILINGUAL':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_native'
                          });
                          break;
                      }
                    }
                    return (
                      <Box mb={1}>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <Typography className={classes.subHeading}>
                              {currLanguage.name}
                              <span
                                style={{
                                  fontSize: '1rem',
                                  fontWeight: 800,
                                  margin: '0 4px'
                                }}
                              >
                                &bull;
                              </span>
                            </Typography>
                          </Box>
                          {currLanguage.proficiency ? (
                            <Box pt={0.25}>
                              <Typography
                                className={classes.darkCvElement}
                                style={{
                                  lineHeight: 'normal'
                                }}
                              >
                                {proficiency}{' '}
                                {this.props.intl.formatMessage({
                                  id:
                                    'CVProfileInfo.proficiency_linkedin_' + currLanguage.proficiency
                                }) !== undefined &&
                                !this.props.intl
                                  .formatMessage({
                                    id:
                                      'CVProfileInfo.proficiency_linkedin_' +
                                      currLanguage.proficiency
                                  })
                                  .startsWith('CVProfileInfo.')
                                  ? `(${this.props.intl.formatMessage({
                                      id:
                                        'CVProfileInfo.proficiency_linkedin_' +
                                        currLanguage.proficiency
                                    })})`
                                  : ''}
                              </Typography>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </>
        );
      } else {
        return null;
      }
    };

    if (this.state.cv_profile) {
      let profileSkills =
        this.state.skills && this.state.skills.length > 0
          ? this.state.skills.join(' • ')
          : undefined;
      const expandSkills = this.state.expandSkills;

      let hasMoreThan270Characters = profileSkills && profileSkills.length >= 270 ? true : false;

      if (profileSkills && profileSkills.length >= 270 && expandSkills === false) {
        profileSkills = profileSkills.slice(0, 269) + '...';
      }

      return (
        <Box className={classes.mainContainer}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" width="100%" flexDirection="column">
              <Box display="flex" justifyContent="space-between" mb={3}>
                <Box display="flex" flexDirection="column">
                  <Box display="flex">
                    <Box mr={1}>
                      <CardAvatar
                        applicant={profile}
                        orientation="horizontal"
                        handleUpdateFavorites={(applicantId, editedFavorites) =>
                          this.handleUpdateFavorites(applicantId, editedFavorites)
                        }
                      />
                    </Box>
                    <Box display="flex" flexDirection="column" mr={1} pt="10px">
                      <Box mb={1}>
                        <Typography
                          variant="h5"
                          style={{
                            fontWeight: 700
                          }}
                        >
                          {this.state.name}
                        </Typography>
                      </Box>

                      {chipsTitle}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    edge="start"
                    color="primary"
                    onClick={this.handleClose}
                    aria-label="close"
                    style={{
                      padding: 0
                    }}
                    //disabled={this.state.submiting}
                  >
                    <CloseIcon
                      style={{
                        fontSize: '22px'
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>

              {hasMatchInfo ? (
                <>
                  <Divider light />

                  <Box my={2}>
                    <MatchInfo applicant={profile} />
                  </Box>
                </>
              ) : null}
            </Box>
          </Box>

          <Divider light />

          <Box mt={2}>
            <Box mb={3} display="flex" alignItems="center">
              <Typography
                className={`${classes.heading} ${
                  applicantOrigin === 'HUNTING' || applicantOrigin === 'SCRAP' || !applicantOrigin
                    ? ''
                    : classes.aIheading
                }`}
              >
                <FormattedMessage id="DetailSummary.about" />
              </Typography>
              {applicantOrigin === 'HUNTING' ||
              applicantOrigin === 'SCRAP' ||
              !applicantOrigin ? null : (
                <Box ml={0.5}>
                  <AISuggestionsIcon />
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="column" ml={2.5}>
              {this.state.summary ? (
                <Box
                  mb={2}
                  className={`${
                    applicantOrigin === 'HUNTING' || applicantOrigin === 'SCRAP' || !applicantOrigin
                      ? ''
                      : classes.aiSummaryContainer
                  }`}
                >
                  <Typography
                    className={`${
                      applicantOrigin === 'HUNTING' ||
                      applicantOrigin === 'SCRAP' ||
                      !applicantOrigin
                        ? classes.cvElement
                        : classes.aICvElement
                    }`}
                    style={{
                      margin: 0
                    }}
                  >
                    {this.state.summary}
                  </Typography>
                </Box>
              ) : null}

              <Collapse in={this.state.expandAbout} timeout="auto">
                <Box mb={2}>
                  <Box mb={1}>
                    <Typography className={classes.subHeading}>
                      <FormattedMessage
                        id="CVProfileInfo.label_contacts"
                        defaultMessage="Contacts"
                      />
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {hasEmail ? (
                      <>
                        <Typography className={classes.cvElement}>
                          <a
                            style={{
                              cursor: 'pointer',
                              color: 'primary'
                            }}
                            onClick={(e) => this.handleOpenEmail(e)}
                          >
                            {this.state.currentEmail}
                          </a>
                        </Typography>
                        <Popover
                          open={emailOpen}
                          anchorEl={this.state.emailAnchorEl}
                          onClose={this.handleCloseEmail}
                        >
                          <EmailActions email={this.state.currentEmail} />
                        </Popover>
                      </>
                    ) : null}

                    {hasEmail && this.state.currentPhone ? (
                      <span
                        style={{
                          fontSize: '1rem',
                          fontWeight: 800,
                          margin: '0 4px'
                        }}
                      >
                        &bull;
                      </span>
                    ) : null}

                    {this.state.currentPhone ? (
                      <>
                        <Typography className={classes.cvElement}>
                          <a
                            style={{
                              cursor: 'pointer',
                              color: 'primary'
                            }}
                            onClick={(e) => this.handleOpenPhone(e)}
                          >
                            +{this.state.currentPhone}
                          </a>
                        </Typography>

                        {/* <span
                          style={{
                            fontSize: '1rem',
                            fontWeight: 800,
                            margin: '0 4px'
                          }}
                        >
                          &bull;
                        </span> */}

                        <Popover
                          open={phoneOpen}
                          anchorEl={this.state.phoneAnchorEl}
                          onClose={this.handleClosePhone}
                        >
                          <PhoneActions phone={this.state.currentPhone} />
                        </Popover>
                      </>
                    ) : null}
                    {(this.state.currentPhone || hasEmail) && profile.profile_url ? (
                      <span
                        style={{
                          fontSize: '1rem',
                          fontWeight: 800,
                          margin: '0 4px'
                        }}
                      >
                        &bull;
                      </span>
                    ) : null}
                  </Box>
                </Box>
                {profile.age ? (
                  <Box mb={2}>
                    <Box mb={1}>
                      <Typography className={classes.subHeading}>
                        <FormattedMessage id="CVProfileInfo.label_age" defaultMessage="Age" />
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography className={classes.cvElement}>
                        {`${profile.age} ${this.props.intl.formatMessage({
                          id: 'InfoAge.years'
                        })}`}
                      </Typography>
                      <span
                        style={{
                          fontSize: '1rem',
                          fontWeight: 800,
                          margin: '0 4px'
                        }}
                      >
                        &bull;
                      </span>
                      <Typography className={classes.cvElement}>
                        <LocaleFormatter dateToFormat={Moment(profile.date_birth).format('ll')} />
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {hasDocNumber ? displayIDDocument(this.state.profile) : null}
                {address ? (
                  <>
                    <Box mb={2}>
                      <Box mb={1}>
                        <Typography className={classes.subHeading}>
                          <FormattedMessage
                            id="CVProfileInfo.label_address"
                            defaultMessage="Address"
                          />
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography className={classes.cvElement}>
                          {AddressController.addrApplicantFull(address)}
                        </Typography>
                        {profile.address_point && profile.address_geocode ? (
                          <>
                            <span
                              style={{
                                fontSize: '1rem',
                                fontWeight: 800,
                                margin: '0 4px'
                              }}
                            >
                              &bull;
                            </span>
                            <a
                              style={{
                                cursor: 'pointer'
                              }}
                              onClick={(e) => this.handleOpenAddress(e)}
                            >
                              {' '}
                              <FormattedMessage
                                id="CVProfileInfo.button_viewInMap"
                                defaultMessage="View map"
                              />
                            </a>
                          </>
                        ) : null}
                      </Box>
                    </Box>
                    <Popover
                      open={addressOpen}
                      anchorEl={this.state.addressAnchorEl}
                      onClose={this.handleCloseAddress}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      transformOrigin={{ vertical: -20, horizontal: -20 }}
                    >
                      <Box minWidth="480px">
                        <CardApplicantMap
                          applicant_point={profile.address_point}
                          applicant_geocode={profile.address_geocode}
                          job_point={job && job.address_point ? job.address_point : null}
                          job_geocode={job && job.address_geocode ? job.address_geocode : null}
                        />
                      </Box>
                    </Popover>
                  </>
                ) : null}

                {formatttedOriginString ? (
                  <Box mb={2}>
                    <Box mb={1}>
                      <Typography className={classes.subHeading}>
                        <FormattedMessage id="CVProfileInfo.label_origin" defaultMessage="Origin" />
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Typography className={classes.cvElement}>
                        {this.props.intl.formatMessage({
                          id: 'LabelOrigin.origin' + formatttedOriginString
                        })}
                        <span
                          style={{
                            fontSize: '1rem',
                            fontWeight: 800,
                            margin: '0 4px'
                          }}
                        >
                          &bull;
                        </span>
                        {this.props.intl.formatMessage({
                          id: 'CardBase.msg_appliedIn'
                        })}{' '}
                        <LocaleFormatter
                          dateToFormat={Moment(profile.date_created).format('lll')}
                        />
                        {this.state.profile &&
                          this.state.firstJobInfo &&
                          Object.keys(this.state.firstJobInfo).length > 0 && (
                            <>
                              {' '}
                              {this.state.firstJobInfo.type === 'PUBLIC_POOL' ? (
                                <FormattedHTMLMessage
                                  id="CVProfileInfo.public_pool_info"
                                  defaultMessage="in the CV Database on the Career Page {careerPage}"
                                  values={{
                                    careerPage:
                                      this.state.firstJobInfo.accountPage &&
                                      this.state.firstJobInfo.accountPage.path
                                        ? this.state.firstJobInfo.accountPage.path
                                        : ''
                                  }}
                                />
                              ) : (
                                <FormattedHTMLMessage
                                  id="CVProfileInfo.others_jobs_info"
                                  defaultMessage="at the Opportunity {title}"
                                  values={{
                                    title: this.state.firstJobInfo.title
                                  }}
                                />
                              )}
                            </>
                          )}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {profile.pwd && profile.pwd !== 'no' ? (
                  <Box display="flex" alignItems="center">
                    <Box mb={2}>
                      <Box mb={1}>
                        <Typography className={classes.subHeading}>
                          <FormattedMessage id="CVProfileInfo.label_pwd" defaultMessage="PwD" />
                        </Typography>
                      </Box>

                      <Typography className={classes.cvElement}>
                        {this.props.intl.formatMessage({
                          id: 'InfoPwd.' + profile.pwd
                        })}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </Collapse>
            </Box>
            <Box className={classes.moreInfoContainer}>
              <Divider
                light
                style={{
                  width: '100%'
                }}
              />
              <Box ml={1} mr={1} minWidth="150px">
                <Button
                  className={classes.expandAboutButton}
                  size="small"
                  onClick={this.handleExpandAbout}
                  endIcon={
                    <ExpandMoreIcon
                      className={clsx(classes.expandFilters, {
                        [classes.expandFiltersOpen]: this.state.expandAbout
                      })}
                    />
                  }
                >
                  <Typography
                    style={{
                      fontSize: '13px'
                    }}
                  >
                    <FormattedMessage
                      id="CVProfileInfo.buttonMoreInfo"
                      defaultMessage="More Info"
                    />
                  </Typography>
                </Button>
              </Box>
              <Divider
                light
                style={{
                  width: '100%'
                }}
              />
            </Box>
          </Box>
          {profileSkills ? (
            <>
              <Box mt={3}>
                <Box mb={3}>
                  <Typography className={classes.heading}>
                    <FormattedMessage
                      id="CVProfileInfo.label_skills"
                      defaultMessage="Technical Skills"
                    />
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    marginLeft: '20px',
                    marginBottom: '24px'
                  }}
                >
                  <Typography className={classes.cvElement}>
                    {profileSkills}
                    {hasMoreThan270Characters ? (
                      <>
                        &nbsp;
                        <a
                          className={expandSkills ? classes.inactiveSeeMore : classes.activeSeeMore}
                          onClick={(e) => this.handleExpandSkills()}
                        >
                          {expandSkills ? (
                            <FormattedMessage id="CVProfileInfo.label_seeLess" />
                          ) : (
                            <FormattedMessage id="CVProfileInfo.label_seeMore" />
                          )}

                          {expandSkills ? (
                            <KeyboardArrowUpIcon fontSize="small" color="secondary" />
                          ) : (
                            <KeyboardArrowDownIcon fontSize="small" color="primary" />
                          )}
                        </a>
                      </>
                    ) : null}
                  </Typography>
                </Box>
              </Box>
              <Divider />
            </>
          ) : null}

          {displayDiversity()}

          {displayEducations()}

          {displayExperiences()}

          {displayCertifications()}

          {displayLanguages()}
        </Box>
      );
    } else {
      return null;
    }
  }
}

CVProfileInfo.propTypes = {
  user: PropTypes.object,
  industries: PropTypes.object,
  linkedinIndustries: PropTypes.object,
  identityDocs: PropTypes.object
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  industries: state.industries,
  linkedinIndustries: state.linkedinIndustries,
  identityDocs: state.identityDocs
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(CVProfileInfo)));
