import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Api from '../../../Api/Api';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Chip from '@material-ui/core/Chip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AddressController from '../../../Common/AddressController';
import LocaleFormatter from '../../../Common/LocaleFormatter';
import Moment from 'moment';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import Avatar from 'react-avatar';
import AvTimerOutlinedIcon from '@material-ui/icons/AvTimerOutlined';
import LoadingData from '../../../Layout/LoadingData';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

const messages = defineMessages({
  msg_through: {
    id: 'JobInfo.msg_through',
    defaultMessage: 'through'
  }
});

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  optionList: {
    //height: 200,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    padding: 0
  },
  darkText: {
    color: theme.palette.gray.darker
  },
  lightText: {
    color: theme.palette.gray.darkerMedium
  },
  greenText: {
    color: theme.palette.success.main
  },
  lighterText: {
    color: theme.palette.gray.darkerLight
  },
  smallText: {
    fontSize: '14px',
    lineHeight: 'normal'
  },
  smallerText: {
    fontSize: '12px'
  },
  jobsListDarkElement: {
    fontSize: '14px',
    color: theme.palette.gray.darker,
    fontWeight: 500
  },
  greenChip: {
    width: 'fit-content',
    color: theme.palette.success.main,
    background: `${theme.palette.success.main}10`,
    '& .MuiChip-icon': {
      color: theme.palette.success.main
    }
  },
  redChip: {
    width: 'fit-content',
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    '& .MuiChip-icon': {
      color: theme.palette.error.dark
    }
  },
  yellowChip: {
    width: 'fit-content',
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    '& .MuiChip-icon': {
      color: theme.palette.warning.main
    }
  }
});

class JobInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Props from parent
      video_answer: props.video_answer,
      job_id: props.job_id,
      user_id: props.user_id,
      answer_status: props.answer_status,
      // Internal state
      applicantData: [],
      loading: false,
      job: undefined,
      accountUser: undefined
    };
  }

  componentDidMount() {
    if (this.state.job_id) {
      this.loadJobData();
    }
  }

  componentDidUpdate(prevProps) {}

  loadJobData = () => {
    this.setState(
      {
        loading: true
      },
      () => {
        const job_id = this.state.job_id;
        let api = new Api();

        let filter = {
          where: {
            id: job_id
          },
          fields: ['id', 'title', 'code', 'address']
        };

        api.get('Jobs', filter, (status, data) => {
          if (data.length > 0) {
            this.setState(
              {
                job: data[0]
              },
              () => {
                this.loadUserData();
              }
            );
          }
        });
      }
    );
  };

  loadUserData = () => {
    const user_id = this.state.user_id;
    let api = new Api();

    let userFilter = {
      fields: ['id', 'avatar_50_path', 'username', 'email'],
      where: {
        id: user_id,
        deleted: false
      }
    };
    api.get('AccountUsers', userFilter, (status, users) => {
      if (users.length > 0) {
        this.setState({
          accountUser: users[0],
          loading: false
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const job = this.state.job;
    const video_answer = this.state.video_answer;
    const accountUser = this.state.accountUser;
    const loading = this.state.loading;
    const answer_status = this.state.answer_status;

    const statusChip = () => {
      let chip = undefined;
      switch (answer_status) {
        case 'ANSWERED':
          chip = (
            <Chip
              className={classes.greenChip}
              icon={<CheckOutlinedIcon />}
              label={<FormattedMessage id="JobInfo.msg_answered" defaultMessage="Completed" />}
              size="small"
            />
          );
          break;
        case 'EXPIRED':
          chip = (
            <Chip
              className={classes.redChip}
              icon={<ErrorOutlineIcon />}
              label={<FormattedMessage id="JobInfo.msg_expired" defaultMessage="Expired" />}
              size="small"
            />
          );
          break;
        case 'WAITING_ANSWER':
          chip = (
            <Chip
              className={classes.yellowChip}
              icon={<HelpOutlineIcon />}
              label={<FormattedMessage id="JobInfo.msg_waitingAnswered" defaultMessage="Waiting" />}
              size="small"
            />
          );
          break;
      }

      return chip;
    };

    if (!loading) {
      if (job) {
        return (
          <Box>
            <Box px={1.5}>
              <Box display="flex" alignItems="center" justifyContent="center" py={1}>
                <Box mr={0.5}>
                  <InfoOutlinedIcon className={classes.lightText} fontSize="small" />
                </Box>
                <Typography
                  className={`${classes.lightText} ${classes.smallerText}`}
                  style={{
                    fontWeight: 400
                  }}
                >
                  <FormattedMessage id="JobInfo.title" defaultMessage="Info" />
                </Typography>
              </Box>

              <Divider
                style={{
                  width: '100%'
                }}
              />
            </Box>
            <Box
              mt={2.25}
              display="flex"
              flexDirection="column"
              style={{
                padding: '0 16px'
              }}
            >
              <Box mb={1}>
                <Typography className={classes.jobsListDarkElement}>
                  {`[${job.code}] ${job.title}`}
                </Typography>
              </Box>

              <Box mb={1}>{statusChip()}</Box>

              <Box mb={1} display="flex" alignItems="center">
                <Box mr={1}>
                  <PinDropOutlinedIcon fontSize="small" className={classes.lightText} />
                </Box>
                <Typography className={`${classes.darkText} ${classes.smallText}`}>
                  {AddressController.addrApplicantShort(job.address)}
                </Typography>
              </Box>
              <Box mb={1} display="flex" alignItems="center">
                <Box mr={1}>
                  <AvTimerOutlinedIcon fontSize="small" className={classes.lightText} />
                </Box>
                <Typography className={`${classes.darkText} ${classes.smallText}`}>
                  {video_answer ? (
                    <LocaleFormatter
                      dateToFormat={Moment(video_answer.date_created).format('ll')}
                    />
                  ) : (
                    <FormattedMessage
                      id="JobInfo.testNoAnsweredDate"
                      defaultMessage="No answer date"
                    />
                  )}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mb={2}>
                <Box mr={0.5}>
                  <Avatar
                    src={accountUser.avatar_50_path}
                    name={accountUser.username}
                    email={accountUser.email}
                    maxInitials={2}
                    round={true}
                    size="24px"
                    alt=""
                    title=""
                  />
                </Box>
                <Typography className={`${classes.darkText} ${classes.smallText}`}>
                  {accountUser.username}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      } else {
        return null;
      }
    } else return <LoadingData />;
  }
}

export default injectIntl(withStyles(useStyles)(JobInfo));
