import React from 'react';
import LoadingData from '../../../Layout/LoadingData';
import Api from '../../../Api/Api';
import CardVideoPlayer from '../../Card/CardVideoPlayer';
import Box from '@material-ui/core/Box';
import CardVideoPlayer2 from './CardVideoPlayer2';
import { withStyles } from '@material-ui/core/styles';
import Utils from '../../../Common/Utils';

const useStyles = (theme) => ({
  mainContainer: {
    display: 'flex',
    overflowX: 'auto',
    gap: '2rem'
  }
});

class ListVideoAnswers2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videointerview: props.videointerview,
      readonly: props.readonly,
      print: props.print,
      // Internal State
      _loading: true,
      videos: {},
      thumbs: {},
      playing: null,
      playingId: ''
    };
  }

  componentWillMount() {
    //console.log(this.state.videointerview.id);
    this.getUrls();
  }

  getUrls() {
    let api = new Api();

    this.setState(
      {
        _loading: true
      },
      () => {
        let params = {
          id: this.state.videointerview.id,
          type: this.state.videointerview.type
        };
        api.getParam('VideoInterviews/get-all-videos-url', params, (status, data) => {
          //console.log(data);
          this.setState(
            {
              _loading: false,
              videos: data
            },
            () => {
              this.getThumbs();
            }
          );
        });
      }
    );
  }

  getThumbs() {
    let api = new Api();

    this.setState(
      {
        _loading: true
      },
      () => {
        let params = {
          id: this.state.videointerview.id,
          type: this.state.videointerview.type
        };
        api.getParam('VideoInterviews/get-all-thumbnails-url', params, (status, data) => {
          this.setState({
            _loading: false,
            thumbs: data
          });
        });
      }
    );
  }

  handlerUpdate = (updApplicant, updRating) => {
    //console.log(updApplicant);
    if (this.props.handlerUpdate) {
      this.props.handlerUpdate(updApplicant, updRating);
    }
  };

  handlePauseOtherVideos = (id) => {
    if (id !== this.state.playingId) {
      this.setState({
        playingId: id
      });
    }
  };

  render() {
    const { classes } = this.props;
    const videointerview = this.state.videointerview;
    const videoQuestions = videointerview.videoQuestions;

    const displayCards = videoQuestions.map((question, i) => {
      let pause = false;
      let answer = null;
      let url = null;
      let thumbnail = null;
      let answerStatus = null;
      if (videointerview.videoAnswers && videointerview.videoAnswers.length > 0) {
        videointerview.videoAnswers.some((ans) => {
          if (ans.videoquestion_id === question.id) {
            answerStatus = 'ANSWERED';
            answer = ans;
            url = this.state.videos[ans.id];
            thumbnail = this.state.thumbs[ans.id];
            return true;
          }
        });
      }

      if (!answer) {
        if (new Date() > new Date(videointerview.date_limit)) {
          answerStatus = 'EXPIRED';
        } else {
          answerStatus = 'WAITING_ANSWER';
        }
      } else {
        pause = this.state.playingId !== answer.id;
      }

      return (
        <CardVideoPlayer2
          video_question={{ ...question, interviewType: videointerview.type }}
          video_answer={answer}
          video_url={url}
          answer_status={answerStatus}
          thumbnail_url={thumbnail}
          handlerUpdate={this.handlerUpdate}
          job_id={videointerview.job_id}
          readonly={this.state.readonly}
          pause={pause}
          handlePauseOtherVideos={(id) => this.handlePauseOtherVideos(id)}
        />
      );
    });

    return <>{this.state._loading ? <LoadingData /> : displayCards}</>;
  }
}

export default withStyles(useStyles)(ListVideoAnswers2);
