import Api from '../Api/Api';
import UserProfile from './UserProfile';
import $ from 'jquery';
import React, { Component } from 'react';
import * as CONST from '../Common/constants';
import 'jquery-mask-plugin';

var AddressController = (function (that) {
  var hasAddress = function (obj) {
    let hasAddress = false;
    if (obj && !$.isEmptyObject(obj)) {
      if (
        obj.hasOwnProperty('street') &&
        obj['street'] !== '' &&
        obj.hasOwnProperty('city') &&
        obj['city'] !== ''
      ) {
        hasAddress = true;
      }
    }
    return hasAddress;
  };

  var getAddrUrl = function (addr) {
    let addressURL = '';
    if (addr && !$.isEmptyObject(addr)) {
      if (addr.hasOwnProperty('street')) {
        addressURL += addr.street;
        if (addr.hasOwnProperty('number')) {
          if (addr.number) {
            addressURL += ' ' + addr.number;
          }
        }
        if (addr.hasOwnProperty('comp')) {
          if (addr.comp) {
            addressURL += ' ' + addr.comp;
          }
        }
        if (addr.hasOwnProperty('zip')) {
          if (addr.zip) {
            addressURL += ', ' + addr.zip;
          }
        }
        if (addr.hasOwnProperty('city')) {
          if (addr.city) {
            addressURL += ', ' + addr.city;
          }
        }
        if (addr.hasOwnProperty('state')) {
          if (addr.state) {
            addressURL += ', ' + addr.state;
          }
        }
      }
    }
    return addressURL;
  };

  var JobAddressFormatter = function (that, value, row) {
    // console.log(row);
    // console.log(value);
    if (value) {
      if (row) {
        let text = '';
        let addr = [];

        if (row.address) {
          if (row.address.city) {
            addr.push(row.address.city);
          }
          if (row.address.state) {
            addr.push(row.address.state);
          }
        }

        if (row.country) {
          addr.push(AddressController.countryToFlag(row.country));
        }

        text = addr.join(' ');
        return <span>{text}</span>;
      } else {
        if (value.length === 2) {
          let addr = [];
          addr.push(AddressController.countryToFlag(value));
          return <span>{addr}</span>;
        } else if (value) {
          return <span>{value}</span>;
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  };

  var JobAddressFormatterNew = function (that, row) {
    if (row) {
      const countries = that.props.countries;

      if (row?.address) {
        const address = row.address;
        const city = address.city;
        const state = address.state;
        const country = row.country;

        if (row.workplace_mode != 'REMOTE') {
          return `${city} ${state}`;
        }
        const countryLabel = countries?.find((countryItem) => countryItem.code == country)?.name;
        const remoteLabel = that.props.intl.formatMessage({
          id: 'JobApplicantList.addressJob_remote'
        });

        return `${remoteLabel} ${countryLabel}`;
      }
    }

    return '';
  };

  var ApplicantAddressFormatter = function (that, value, row, job) {
    if (value) {
      // Display formatted adderss when Hunting or Remote
      if (job.workplace_mode === CONST.JOB_WORKPLACE_MODES['REMOTE']) {
        return <small className="text-muted">{value}</small>;
      } else {
        const job = that.state.job;
        return (
          <small className="text-muted">
            {AddressController.addrApplicantToJob(row.address, job.address)}
          </small>
        );
      }
    } else {
      return null;
    }
  };

  var addrApplicantToJob = function (applicant_address, job_address) {
    if (applicant_address) {
      let hood;
      let city = '';
      let state = '';
      let addrInfo = '';
      let hasCity = false;
      let hasState = false;

      if (applicant_address.neiborhood) {
        hood = applicant_address.neiborhood;
      }

      if (job_address && job_address.city && applicant_address.city) {
        if (job_address.city.toUpperCase() !== applicant_address.city.toUpperCase()) {
          city = applicant_address.city;
          hasCity = true;
        }
      }
      if (job_address && job_address.state && applicant_address.state) {
        if (job_address.state.toUpperCase() !== applicant_address.state.toUpperCase()) {
          state = applicant_address.state;
          hasState = true;
        }
      }

      if (hood) {
        addrInfo = hood;
      }
      if (hasCity || hasState) {
        if (hasCity) {
          if (hood) {
            addrInfo = addrInfo + ' (' + city;
          } else {
            addrInfo = city;
          }
        }
        if (hasState) {
          if (hood) {
            addrInfo = addrInfo + '/' + state + ')';
          } else {
            addrInfo = addrInfo + '/' + state;
          }
        } else if (hood) {
          addrInfo = addrInfo + ')';
        }
      }
      return addrInfo;
    } else {
      return null;
    }
  };

  var addrApplicantFull = function (applicant_address, zip) {
    if (applicant_address) {
      let addrInfo = [];

      if (applicant_address.street) {
        addrInfo.push(applicant_address.street);
      }

      if (applicant_address.number) {
        addrInfo.push(applicant_address.number);
      }

      if (applicant_address.complement) {
        addrInfo.push(applicant_address.complement);
      }

      if (applicant_address.neiborhood) {
        addrInfo.push(applicant_address.neiborhood);
      }

      if (applicant_address.city) {
        addrInfo.push(applicant_address.city);
      }

      if (applicant_address.state) {
        addrInfo.push(applicant_address.state);
      }

      if (zip) {
        addrInfo.push(zip);
      }

      return addrInfo.join(', ');
    } else {
      return null;
    }
  };

  var addrApplicantShort = function (applicant_address) {
    if (applicant_address) {
      let addrInfo = [];

      if (applicant_address.city) {
        addrInfo.push(applicant_address.city);
      }

      if (applicant_address.state) {
        addrInfo.push(applicant_address.state);
      }

      return addrInfo.join(', ');
    } else {
      return null;
    }
  };

  // ISO 3166-1 alpha-2
  // ⚠️ No support for IE 11
  var countryToFlag = function (isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  };

  var getCronofyToken = function (cronofy_token_date, cronofy_token_info, cb) {
    const exp = cronofy_token_info.expires_in;
    const current_token = cronofy_token_info.access_token;
    const refresh_token = cronofy_token_info.refresh_token;
    let expireDate = new Date(cronofy_token_date);
    expireDate.setSeconds(new Date().getSeconds() + exp);
    const now = new Date();

    //console.log(expireDate);
    //console.log(now);
    if (expireDate > now) {
      console.log('Cronofy access token still valid');
      return cb(null, current_token);
    } else {
      console.log('Cronofy token needs refresh!');
      let api = new Api();
      let payload = {
        options: {
          grant_type: 'refresh_token',
          refresh_token: refresh_token
        }
      };
      api.post('AccountUsers/cronofy-refresh-access-token', payload, (status, data) => {
        //console.log(data);
        const new_access_token = data.access_token;
        let new_token_info = {
          ...cronofy_token_info,
          ...data
        };
        let upd = {
          cronofy_token_date: new Date(),
          cronofy_token_info: new_token_info
        };
        api.update('AccountUsers', UserProfile.getId(), upd, (status, data) => {
          //console.log(data);
          return cb(null, new_access_token);
        });
      });
    }
  };

  var getMaskedID = function (containerID, idMask) {
    $(`#${containerID}`).mask(idMask);
  };

  var unMaskedID = function (containerID) {
    $(`#${containerID}`).unmask();
  };

  var getMaskedCurrency = function (value, currency) {
    value = value.toString();
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

    let locale = 'en-US';
    let splitChar = '.';

    if (currency === 'BRL') {
      locale = 'pt-BR';
      splitChar = ',';
    }

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat(locale, options).format(parseFloat(value) / 100);

    const unformattedValue = parseInt(
      result.split(splitChar)[0].replace('.', '').replace(',', '').replace(/\D/g, '')
    );

    return {
      maskedResult: result,
      integerResult: unformattedValue
    };
  };

  return {
    hasAddress: hasAddress,
    getAddrUrl: getAddrUrl,
    addrApplicantToJob: addrApplicantToJob,
    addrApplicantFull: addrApplicantFull,
    addrApplicantShort: addrApplicantShort,
    countryToFlag: countryToFlag,
    getCronofyToken: getCronofyToken,
    JobAddressFormatter: JobAddressFormatter,
    JobAddressFormatterNew: JobAddressFormatterNew,
    ApplicantAddressFormatter: ApplicantAddressFormatter,
    getMaskedID: getMaskedID,
    getMaskedCurrency: getMaskedCurrency,
    unMaskedID: unMaskedID
  };
})();

export default AddressController;
