import { SET_INDUSTRIES } from '../actions/actions';
import produce from 'immer';

const INITIAL_STATE = {
  industries: []
};

export default function industriesReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_INDUSTRIES: {
        draft.industries = action.payload;
        break;
      }
      default:
        return state;
    }
  });
}
