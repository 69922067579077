import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import UserProfile from '../../Common/UserProfile';
import Api from '../../Api/Api';
import * as CONST from '../../Common/constants';
import Avatar from 'react-avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Highlighter from 'react-highlight-words';
import { withStyles } from '@material-ui/core/styles';
import LoadingData from '../../Layout/LoadingData';
import NoteListRun from '../../Applicant/Notes/NoteList.run';
import Moment from 'moment';
import LocaleFormatter from '../../Common/LocaleFormatter';
import Box from '@material-ui/core/Box';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';

const messages = defineMessages({});

const useStyles = (theme) => ({
  cardRoot: {
    border: '1px solid ' + theme.palette.gray.main,
    marginBottom: theme.spacing(1)
  },
  content: {
    flex: 1,
    padding: '6px 16px',
    minWidth: '90%'
  },
  dotPositive: {
    borderColor: theme.palette.success.main
  },
  dotNegative: {
    borderColor: theme.palette.error.main
  },
  dotNeutral: {
    borderColor: theme.palette.secondary.main
  },
  notAccessibleIcon: {
    fontSize: '18px',
    color: theme.palette.error.main
  },
  notAccessibleTitle: {
    color: theme.palette.gray.darker,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px'
  },
  notAccessibleMsg: {
    color: theme.palette.gray.darker,
    fontSize: '12px',
    lineHeight: '18px'
  }
});

class CardNoteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person_id: props.person_id,
      notesCount: props.notesCount ? props.notesCount : 0,
      // Internal State
      _loading: true,
      notes: []
    };
  }

  componentWillMount() {
    this.loadNotes();
  }

  componentDidMount() {
    NoteListRun();
  }

  componentDidUpdate(prevProps) {}

  loadNotes() {
    const person_id = this.state.person_id;

    if (person_id) {
      this.setState(
        {
          _loading: true
        },
        () => {
          let filter = {
            include: [
              {
                relation: 'applicant',
                scope: {
                  fields: ['id', 'name', 'title', 'person_id']
                }
              },
              {
                relation: 'accountUser',
                scope: {
                  fields: [
                    'id',
                    'username',
                    'role',
                    'unique_name',
                    'user_profile',
                    'avatar_50_path'
                  ]
                }
              },
              {
                relation: 'job',
                scope: {
                  fields: ['id', 'code', 'title']
                }
              }
            ],
            where: {
              'applicant@person_id': {
                inq: [person_id]
              }
            },
            order: ['date_created DESC']
          };

          let api = new Api();
          //console.log(filter);
          api.get('ApplicantNotes', filter, (status, notes) => {
            //console.log(notes);
            this.setState(
              {
                _loading: false,
                notes: notes
              },
              () => {
                if (this.props.handlerUpdate) {
                  this.props.handlerUpdate(notes);
                }
                NoteListRun();
              }
            );
          });
        }
      );
    }
  }

  deleteNote = (note_id) => {
    window
      .swal({
        type: 'info',
        title: '',
        text: this.props.intl.formatMessage({
          id: 'NoteList.buttonDeleteConfirmMsg'
        }),
        closeOnConfirm: true,
        buttons: {
          cancel: {
            text: this.props.intl.formatMessage({
              id: 'Base.button.cancel'
            }),
            value: null,
            visible: true,
            className: '',
            closeModal: true
          },
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: '',
            closeModal: true
          }
        }
      })
      .then((isConfirmed) => {
        if (isConfirmed) {
          let api = new Api();
          api.delete('ApplicantNotes', note_id, (status, deleted) => {
            let notes = this.state.notes.map((a) => ({ ...a }));
            const idx = notes.findIndex((a) => a.id === note_id);
            notes.splice(idx, 1);

            this.setState(
              {
                notes: notes
              },
              () => {
                if (this.props.handlerUpdate) {
                  this.props.handlerUpdate(notes);
                }
                NoteListRun();
              }
            );
          });
        }
      });
  };

  render() {
    const { classes } = this.props;

    const notes = this.state.notes;

    const notAccessibleNotes = this.state.notesCount - notes.length;

    const displayNotes = notes.map((note, i) => {
      if (note) {
        let color = 'dotNeutral';
        switch (note.sentiment) {
          case 'POSITIVE':
            color = 'dotPositive';
            break;
          case 'NEGATIVE':
            color = 'dotNegative';
            break;
        }

        const date = Moment(note.date_created).format('lll');

        let mentionsArr = [];
        if (note.mentions && note.mentions.length > 0) {
          note.mentions.forEach((mention) => {
            mentionsArr.push(mention.display);
          });
        }

        let formmattedNote = note.note.replace(/(?:\r\n|\r|\n)/g, <br />);
        const path_avatar =
          'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + note.accountUser.avatar_50_path;

        return (
          <Card
            classes={{
              root: classes.cardRoot
            }}
          >
            <CardHeader
              avatar={
                <Avatar
                  name={note.accountUser.username}
                  src={path_avatar}
                  size="20px"
                  round={true}
                />
              }
              title={
                <small>{`[${note.job.code}] ${note.job.title} - ${note.accountUser.username}`}</small>
              }
              subheader={
                <small>
                  <LocaleFormatter dateToFormat={date} />
                </small>
              }
              //action={buttonDelete}
            />
            <CardContent className="pt0 pb0">
              <p className="text-muted note-text">
                <Highlighter
                  searchWords={mentionsArr}
                  highlightStyle={{
                    backgroundColor: 'transparent',
                    borderBottom: '1px dotted ' + CONST.APP_COLORS['secondary']
                  }}
                  style={{
                    whiteSpace: 'pre-wrap'
                  }}
                  autoEscape={true}
                  textToHighlight={formmattedNote}
                />
              </p>
            </CardContent>
          </Card>
        );
      }
    });

    if (this.state._loading === true) {
      return <LoadingData />;
    } else {
      if (this.state.notesCount > 0) {
        return (
          <>
            {notAccessibleNotes > 0 ? (
              <Box mt={2}>
                <Box>
                  <p className={classes.notAccessibleTitle}>
                    <FormattedMessage
                      id="CardNoteList.title_notAccessibleNotes"
                      defaultMessage="{count} unaccessible notes"
                      values={{
                        count: notAccessibleNotes
                      }}
                    />
                  </p>
                </Box>
                <Box display="flex" pt={0.25}>
                  <Box mr={0.55}>
                    <ErrorOutlineIcon className={classes.notAccessibleIcon} />
                  </Box>
                  <Box pt={0.25}>
                    <p className={classes.notAccessibleMsg}>
                      <FormattedMessage
                        id="CardNoteList.msg_notAccessibleNotes"
                        defaultMessage="You do not have permission to access these notes."
                      />
                    </p>
                  </Box>
                </Box>
              </Box>
            ) : null}
            <div>{displayNotes}</div>
          </>
        );
      } else {
        return (
          <Row>
            <Col sm={12}>
              <p className="text-center text-muted">
                <i className="fa fa-2x icon-speech"></i>
                <br />
                <FormattedMessage id="NoteList.noNotes" />
              </p>
            </Col>
          </Row>
        );
      }
    }
  }
}

export default injectIntl(withStyles(useStyles)(CardNoteList));
