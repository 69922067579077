import * as materialColors from 'material-colors';

export const UtilsMaterial = {
  getFontFamily: (font) => {
    return {
      roboto: 'Roboto',
      helvetica: 'Helvetica',
      courier: 'Courier'
    };
  },
  getThemeColor: (color, brightness) => {
    let c;
    switch (color) {
      case 'deep-purple':
        c = 'deepPurple';
        break;
      case 'light-blue':
        c = 'lightBlue';
        break;
      case 'light-green':
        c = 'lightGreen';
        break;
      case 'deep-orange':
        c = 'deepOrange';
        break;
      case 'blue-grey':
        c = 'blueGrey';
        break;
      default:
        c = color;
        break;
    }

    return materialColors[c][brightness];
  },
  getAllThemeColors: () => {
    const hue = ['300', '400', '500', '600', '700', '800'];
    const colors = [
      'red',
      'pink',
      'purple',
      'deepPurple',
      'indigo',
      'blue',
      'lightBlue',
      'cyan',
      'teal',
      'green',
      'lightGreen',
      'lime',
      'yellow',
      'amber',
      'orange',
      'deepOrange',
      'brown',
      'blueGrey',
      'grey'
    ];

    let themes = [];
    let theme = [];

    colors.map((color) => {
      let materialColor = materialColors[color];
      hue.map((h) => {
        theme.push(materialColor[h]);
      });

      themes.push(theme);
      theme = [];
    });

    return themes;
  },
  getMaterialColorByHex: (value) => {
    let colorArr = Object.keys(materialColors);
    let materialColor = null;
    let materialColor2 = null;
    let materialBright = null;
    colorArr.forEach((color) => {
      let gradientsArr = Object.entries(materialColors[color]);
      gradientsArr.some((grad) => {
        if (grad[1] == value) {
          //console.log(color);
          materialColor2 = color;
          switch (color) {
            case 'deepPurple':
              materialColor = 'deep-purple';
              break;
            case 'lightBlue':
              materialColor = 'light-blue';
              break;
            case 'lightGreen':
              materialColor = 'light-green';
              break;
            case 'deepOrange':
              materialColor = 'deep-orange';
              break;
            case 'blueGrey':
              materialColor = 'blue-grey';
              break;
            default:
              materialColor = color;
          }
          materialBright = grad[0];
          return true;
        }
      });
    });
    return {
      color: materialColor,
      color2: materialColor2,
      brightness: materialBright
    };
  },
  getThemeColorsByHue: (hue) => {
    const colors = [
      'red',
      'pink',
      'purple',
      'deepPurple',
      'indigo',
      'blue',
      'lightBlue',
      'cyan',
      'teal',
      'green',
      'lightGreen',
      'lime',
      'yellow',
      'amber',
      'orange',
      'deepOrange',
      'brown',
      'blueGrey',
      'grey'
    ];

    let themes = [];

    colors.map((color) => {
      themes.push(materialColors[color][hue]);
    });

    return themes;
  }
};
