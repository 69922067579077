import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import Api from '../../Api/Api';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import CardApplicantMap from '../Card/CardApplicantMap';
import CardTimeToJob from '../Card/CardTimeToJob';
import CardFareToJob from '../Card/CardFareToJob';

const messages = defineMessages({});

const useStyles = (theme) => ({
  root: {
    width: '100%'
  }
});

class DetailLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant_id: props.applicant_id,
      // Internal State
      _loading: true,
      info: {
        id: '',
        zip: '',
        address: null,
        address_geocode: null,
        address_point: null,
        distance_job: null,
        directions_job: null,
        job: {
          address_public: false,
          address: null,
          address_geocode: null,
          address_point: null
        }
      }
    };
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {
    //console.log("mounted " + this.state.applicant_id);
    this.loadInfo();
  }

  loadInfo() {
    this.setState(
      {
        _loading: true
      },
      () => {
        const applicant_id = this.state.applicant_id;
        let api = new Api();
        let filter = {
          fields: [
            'id',
            'job_id',
            'zip',
            'address',
            'address_geocode',
            'address_point',
            'distance_job',
            'directions_job'
          ],
          where: {
            id: applicant_id
          },
          include: [
            {
              relation: 'job',
              scope: {
                fields: ['id', 'address_public', 'address', 'address_geocode', 'address_point']
              }
            }
          ]
        };
        api.get('Applicants', filter, (status, data) => {
          //console.log(data);
          if (data.length > 0) {
            this.setState({
              info: data[0],
              _loading: false
            });
          }
        });
      }
    );
  }

  componentWillUnmount() {
    //console.log("unmounted " + this.state.applicant_id)
  }

  render() {
    const { classes } = this.props;

    const info = this.state.info;
    //console.log(info);

    return (
      <div className={classes.root}>
        <Box p={2}>
          <Row>
            <Col md={6} className="pl0">
              {this.state._loading ? (
                <Skeleton variant="rect" animation="wave" width="100%" height={150} />
              ) : (
                <div className="mb-lg">
                  <CardTimeToJob directions_job={info.directions_job} />
                </div>
              )}
            </Col>

            <Col md={6} className="pr0">
              {this.state._loading ? (
                <Skeleton variant="rect" animation="wave" width="100%" height={120} />
              ) : (
                <div className="mb-lg">
                  <CardFareToJob directions_job={info.directions_job} />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12} className="p0">
              {this.state._loading ? (
                <Skeleton variant="rect" animation="wave" width="100%" height={500} />
              ) : (
                <div className="mb-lg">
                  <CardApplicantMap
                    applicant_point={info.address_point}
                    applicant_geocode={info.address_geocode}
                    job_point={info.job && info.job.address_point ? info.job.address_point : null}
                    job_geocode={
                      info.job && info.job.address_geocode ? info.job.address_geocode : null
                    }
                  />
                </div>
              )}
            </Col>
          </Row>
        </Box>
      </div>
    );
  }
}

export default injectIntl(withStyles(useStyles)(DetailLocation));
