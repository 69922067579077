// GLOBAL CONSTANTS
// -----------------------------------
import UserProfile from './UserProfile';

export const APP_COLORS = {
  primary: '#FF5A8A',
  'primary-light': '#E5A8BC',
  'primary-lighter': '#FEF4F7',
  secondary: '#6A6A6A',
  'secondary-light': '#9F9CAE',
  success: '#00A97A',
  'success-light': '#00a97a15',
  info: '#0092E4',
  'info-light': '#F0FAFF',
  warning: '#F57C00', // Ecstasy
  'warning-dark': '#CB6700',
  'warning-light': '#FFF3E0',
  danger: '#F44336', // Pomegranate
  'danger-dark': '#d32f2f',
  'danger-light': '#FFCDD2',
  'danger-lighter': '#FFF4F6',
  inverse: '#690E2C',
  green: '#1EE831',
  'green-dark': '#04B000',
  'green-light': '#C8E6C9',
  'green-lighter': '#EBF7EB',
  pink: '#EC6592',
  purple: '#CC14F5',
  'purple-dark': '#9C27B0',
  yellow: '#FAD732',
  'gray-darker': '#474747',
  'gray-darker-medium': '#6A6A6A',
  'gray-darker-regular': '#979797',
  'gray-darker-light': '#BDBDBD',
  gray: '#DDE6E9',
  'gray-light': '#ECECEC',
  'gray-lighter': '#F5F5F5',
  'gray-bg-lighter': '#FAFBFC',
  white: '#ffffff',
  black: '#000000'
};

export const SERIES_COLORS = [
  APP_COLORS['primary-light'],
  APP_COLORS['primary'],
  APP_COLORS['secondary-light'],
  APP_COLORS['secondary'],
  APP_COLORS['gray-darker'],
  APP_COLORS['gray-dark'],
  APP_COLORS['gray'],
  APP_COLORS['gray-light']
];

export const MATERIAL_THEME = {
  typography: {
    color: APP_COLORS['info'],
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      lighter: APP_COLORS['primary-lighter'],
      light: APP_COLORS['primary-light'],
      main: APP_COLORS['primary'],
      contrastText: APP_COLORS['white']
    },
    secondary: {
      main: APP_COLORS['secondary'],
      contrastText: APP_COLORS['white']
    },
    error: {
      main: APP_COLORS['danger'],
      dark: APP_COLORS['danger-dark'],
      light: APP_COLORS['danger-light'],
      lighter: APP_COLORS['danger-lighter']
    },
    warning: {
      main: APP_COLORS['warning'],
      dark: APP_COLORS['warning-dark'],
      light: APP_COLORS['warning-light']
    },
    info: {
      main: APP_COLORS['info'],
      light: APP_COLORS['info-light']
    },
    success: {
      main: APP_COLORS['success'],
      light: APP_COLORS['success-light']
    },
    green: {
      main: APP_COLORS['green'],
      dark: APP_COLORS['green-dark'],
      light: APP_COLORS['green-light'],
      lighter: APP_COLORS['green-lighter']
    },
    gray: {
      light: APP_COLORS['gray-light'],
      main: APP_COLORS['gray'],
      dark: APP_COLORS['gray-dark'],
      darker: APP_COLORS['gray-darker'],
      darkerMedium: APP_COLORS['gray-darker-medium'],
      darkerRegular: APP_COLORS['gray-darker-regular'],
      darkerLight: APP_COLORS['gray-darker-light'],
      lighter: APP_COLORS['gray-lighter'],
      bgLighter: APP_COLORS['gray-bg-lighter']
    },
    purple: {
      main: APP_COLORS['purple'],
      dark: APP_COLORS['purple-dark']
    },
    pink: {
      main: APP_COLORS['pink']
    },
    yellow: {
      main: APP_COLORS['yellow']
    },
    action: {
      disabledBackground: APP_COLORS['gray'],
      disabled: APP_COLORS['gray-darker-light']
    },
    white: {
      main: APP_COLORS['white']
    },
    black: {
      main: APP_COLORS['black']
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiFilledInput: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiListItem: {
      defaultProps: {
        dense: true
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiFab: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiTable: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense'
      }
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense'
      }
    }
  },
  shape: {
    borderRadius: 16
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 32
      }
    },
    MuiChip: {
      root: {
        fontSize: '12px'
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    }
  }
};

export const APP_MEDIAQUERY = {
  desktopLG: 1200,
  desktop: 992,
  tablet: 768,
  mobile: 480
};

// ACCOUNT FOR LOCALHOST FOR DEBUGGING -> Always change in API also (server.js)!
export const LOCALHOST_ACCOUNT = 'talentfaster';

// DATE FORMAT (FIXME: NEEDS FIXING IN INTL)
export const NOT_AVAILABLE = 'N/A';

// APP TYPES
export const STAGE_CROSSRANK = 'CROSSRANK';
export const STAGE_ALL = 'ALL_APPLICANTS';
export const STAGE_SCREENING = 'SCREENING';
export const STAGE_PERSONALITY = 'PERSONALITY';
export const STAGE_CUSTOMTEST = 'CUSTOMTEST';
export const STAGE_VIDEOINTERVIEW = 'VIDEOINTERVIEW';
export const STAGE_INTERVIEW = 'INTERVIEW';
export const STAGE_REVIEW = 'REVIEW';
export const STAGE_BACKGROUNDCHECK = 'BACKGROUNDCHECK';
export const STAGE_HIRED = 'HIRED';
export const STAGE_DISQUALIFIED = 'DISQUALIFIED';
export const STAGE_SAVEDLATER = 'SAVEDLATER';

export const ICON_STAGES = {
  SCREENING: 'icon-eye',
  PERSONALITY: 'icon-emotsmile',
  VIDEOINTERVIEW: 'fas fa-video',
  INTERVIEW: 'icon-people',
  REVIEW: 'icon-equalizer',
  BACKGROUNDCHECK: 'fa fa-shield',
  HIRED: 'icon-trophy',
  DISQUALIFIED: 'icon-ban',
  SAVEDLATER: 'fa fa-heart-o'
};

export const COLOR_STAGES = {
  SCREENING: APP_COLORS['pink'],
  PERSONALITY: APP_COLORS['purple'],
  VIDEOINTERVIEW: APP_COLORS['green'],
  INTERVIEW: APP_COLORS['info'],
  REVIEW: APP_COLORS['warning'],
  BACKGROUNDCHECK: APP_COLORS['warning'],
  HIRED: APP_COLORS['success'],
  DISQUALIFIED: APP_COLORS['inverse'],
  SAVEDLATER: APP_COLORS['info']
};

export const STAGE_SAVEDLATER_OBJ = {
  id: 1000,
  priority: 1000,
  name: 'type_savedLater',
  type: 'SAVEDLATER'
};

export const HIRED_STATUS = [
  {
    value: 0,
    label: 'ButtonChangeHireStatus.status_0'
  },
  {
    value: 5,
    label: 'ButtonChangeHireStatus.status_5'
  },
  {
    value: 10,
    label: 'ButtonChangeHireStatus.status_10'
  },
  {
    value: 20,
    label: 'ButtonChangeHireStatus.status_20'
  },
  {
    value: 30,
    label: 'ButtonChangeHireStatus.status_30'
  },
  {
    value: 40,
    label: 'ButtonChangeHireStatus.status_40'
  },
  {
    value: 50,
    label: 'ButtonChangeHireStatus.status_50'
  }
];

// APPLICANT ORIGINS
export const ORIGIN_APPLY = 'APPLY';
export const ORIGIN_MAILIN = 'MAILIN';
export const ORIGIN_RECRUITER = 'RECRUITER';
export const ORIGIN_IMPORT = 'IMPORT';
export const ORIGIN_HUNT = 'HUNTING';
export const ORIGIN_SCRAP = 'SCRAP';
export const ORIGIN_CROSSRANK = 'CROSSRANK';
export const ORIGIN_COPY = 'COPY';
export const ORIGIN_MOVE = 'MOVE';
export const ORIGIN_INVITE = 'INVITE';
export const ORIGIN_SEARCH = 'SEARCH';

// S3 Path
export const S3_BASE_URL = 'https://s3-sa-east-1.amazonaws.com/';
export const S3_BUCKET_URL = 'recrutai-dev/';
export const S3_VIDEO_BUCKET_URL = S3_BASE_URL + 'entrevistai-dev/';

// GOOGLEMAPS
export const GMAPS_APIKEY = 'AIzaSyAS5C_sZkxHVGKqTCS4-vn04iG4WyyQS3M';
export const GMAPS_STYLE = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: APP_COLORS['gray-lighter']
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: APP_COLORS['ray-darker-medium']
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: APP_COLORS['gray-lighter']
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: APP_COLORS['gray-darker-light']
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: APP_COLORS['gray-light']
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: APP_COLORS['gray-darker-medium']
      }
    ]
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: APP_COLORS['gray-light']
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: APP_COLORS['gray-darker-regular']
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: APP_COLORS['white']
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: APP_COLORS['gray-darker-medium']
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: APP_COLORS['gray-light']
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: APP_COLORS['primary-light']
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: APP_COLORS['gray-darker-medium']
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: APP_COLORS['gray-darker-medium']
      }
    ]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: APP_COLORS['gray-light']
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: APP_COLORS['gray-light']
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: APP_COLORS['gray-darker-regular']
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: APP_COLORS['gray-darker-regular']
      }
    ]
  }
];

// EMPTY AVATARS
// export const AVATAR_EMPTY_50 = 'img/user/avatar-empty-50.jpg';
export const AVATAR_EMPTY_50 = 'img/user/avatar-no-image.png';
//export const AVATAR_EMPTY_50B = '';
// export const AVATAR_EMPTY_200 = 'img/user/avatar-empty-200.jpg';
export const AVATAR_EMPTY_200 = 'img/user/avatar-no-image.png';

// MASKS
export const BR_CNPJ_MASK = '99.999.999/9999-99';
export const BR_CPF_MASK = '999.999.999-99';

// USER ROLE CONSTS
export const ROLE = {
  ADMIN: 'ADMIN',
  PAGE_ADMIN: 'PAGE_ADMIN',
  USER_ADMIN: 'USER_ADMIN',
  HR_MANAGER: 'HR_MANAGER',
  RECRUITER: 'RECRUITER',
  JOB_MANAGER: 'JOB_MANAGER',
  APPROVER: 'APPROVER',
  REQUESTER: 'REQUESTER',
  PROCESS_MANAGER: 'PROCESS_MANAGER',
  DIVERSITY: 'DIVERSITY',
  TAG_USER: 'TAG_USER',
  REVIEWER: 'REVIEWER'
};

// CRONOFY
export const CRONOFY_ID = 'ZL4jtCxcOryQ4YeS9dqZR14_QsnEE89U';

export const WIDGET_TYPE = {
  TEXT: 'TEXT',
  GALLERY: 'GALLERY',
  VIDEO: 'VIDEO',
  TEAM: 'TEAM',
  BENEFITS: 'BENEFITS'
};

// FIXME: Create multiple
export const LANGS = [
  {
    value: 'ar',
    label: 'Árabe'
  },
  {
    value: 'pt-br',
    label: 'Português (Brasil)'
  },
  {
    value: 'bg',
    label: 'Búlgaro'
  },
  {
    value: 'cs',
    label: 'Checo'
  },
  {
    value: 'da',
    label: 'Dinamarquês'
  },
  {
    value: 'nl',
    label: 'Holandês'
  },
  {
    value: 'en-us',
    label: 'Inglês'
  },
  {
    value: 'fi',
    label: 'Finlandês'
  },
  {
    value: 'fr',
    label: 'Francês'
  },
  {
    value: 'de',
    label: 'Alemão'
  },
  {
    value: 'el',
    label: 'Grego'
  },
  {
    value: 'he',
    label: 'Hebraico'
  },
  {
    value: 'hi',
    label: 'Hindi'
  },
  {
    value: 'hu',
    label: 'Húngaro'
  },
  {
    value: 'id',
    label: 'Indonésio'
  },
  {
    value: 'it',
    label: 'Italiano'
  },
  {
    value: 'ja',
    label: 'Japonês'
  },
  {
    value: 'ko',
    label: 'Coreano'
  },
  {
    value: 'no',
    label: 'Norueguês'
  },
  {
    value: 'pl',
    label: 'Polonês'
  },
  {
    value: 'ro',
    label: 'Romeno'
  },
  {
    value: 'ru',
    label: 'Russo'
  },
  {
    value: 'zh-cn',
    label: 'Chinês simplificado'
  },
  {
    value: 'es',
    label: 'Espanhol'
  },
  {
    value: 'sv',
    label: 'Sueco'
  },
  {
    value: 'th',
    label: 'Tailandês'
  },
  {
    value: 'zh-tw',
    label: 'Chinês tradicional'
  },
  {
    value: 'tr',
    label: 'Turco'
  },
  {
    value: 'vi',
    label: 'Vietnamita'
  },
  {
    value: 'en-gb',
    label: 'Inglês Britânico'
  },
  {
    value: 'fr-ca',
    label: 'Francês (Canadá)'
  },
  {
    value: 'pt',
    label: 'Português'
  },
  {
    value: 'es',
    label: 'Espanhol (Europa)'
  },
  {
    value: 'en-ca',
    label: 'Inglês (Canadá)'
  },
  {
    value: 'af',
    label: 'Africanêr'
  },
  {
    value: 'sq',
    label: 'Albanês'
  },
  {
    value: 'ca',
    label: 'Catalão'
  },
  {
    value: 'hr',
    label: 'Croata'
  },
  {
    value: 'et',
    label: 'Estoniano'
  },
  {
    value: 'is',
    label: 'Islandês'
  },
  {
    value: 'sr',
    label: 'Sérvio'
  },
  {
    value: 'uk',
    label: 'Ucraniano'
  }
];

export const JOB_TYPES = ['PUBLIC_JOB', 'INTERNAL_JOB'];

export const JOB_WORKPLACE_MODES = ['ONSITE', 'HYBRID', 'REMOTE'];

export const JOB_CONTRACT_MODES = {
  BR: [
    {
      value: 'FULLTIME_FLEXIBLE',
      label: 'A definir (CLT ou PJ ou COOPERADO)'
    },
    {
      value: 'FULLTIME_FIXED', // BR_CLT
      label: 'Contrato CLT'
    },
    {
      value: 'INTERNSHIP',
      label: 'Estágio'
    },
    {
      value: 'TEMPORARY',
      label: 'Contrato temporário'
    },
    {
      value: 'TRAINEE',
      label: 'Trainee'
    },
    {
      value: 'FULLTIME_SEASON', // BR_INTERMITENTE
      label: 'Contrato intermintente'
    },
    {
      value: 'SPECIAL_APRENTICE', // BR_MENOR_APRENDIZ
      label: 'Jovem Aprendiz'
    },
    {
      value: 'CONTRACTOR_FREELANCER', // FREELANCER
      label: 'Freelancer'
    },
    {
      value: 'CONTRACTOR', // BR_PRESTADOR_PJ
      label: 'Prestador de Serviços (PJ)'
    },
    {
      value: 'OTHER',
      label: 'Outro'
    }
  ],
  US: [
    {
      value: 'FULLTIME_FIXED', // PERMANENT
      label: 'Full-time contract'
    },
    {
      value: 'TEMPORARY',
      label: 'Temporary'
    },
    {
      value: 'PARTTIME',
      label: 'Part-time contract'
    },
    {
      value: 'INTERNSHIP',
      label: 'Intership'
    },
    {
      value: 'TRAINEE',
      label: 'Trainee'
    },
    {
      value: 'CONTRACTOR_FREELANCER', // FREELANCER
      label: 'Freelancer'
    },
    {
      value: 'OTHER',
      label: 'Other'
    }
  ],
  PT: [
    {
      value: 'FULLTIME_FIXED',
      label: 'Contrato sem termo'
    },
    {
      value: 'FULLTIME_NOTFIXED',
      label: 'Contrato a termo certo'
    },
    {
      value: 'TEMPORARY',
      label: 'Contrato a termo incerto'
    },
    {
      value: 'PARTTIME',
      label: 'Contrato a tempo parcial'
    },
    {
      value: 'TRAINEE',
      label: 'Trainee'
    },
    {
      value: 'FULLTIME_SEASON',
      label: 'Contrato intermintente'
    },
    {
      value: 'CONTRACTOR_FREELANCER',
      label: 'Freelancer'
    },
    {
      value: 'OTHER',
      label: 'Outro'
    }
  ]
};

export const HELP_URL = {
  BR: {
    TOS: 'https://www.recrut.ai/tos/pt-br',
    PRIVACY: 'https://www.recrut.ai/privacy/pt-br',
    LIMIT_USER_VIEW: 'https://meajuda.recrut.ai/hc/pt-br/articles/360042600014',
    USER_TYPES: 'https://meajuda.recrut.ai/hc/pt-br/articles/360042425554',
    SCOPE_TYPES: 'https://meajuda.recrut.ai/hc/pt-br/articles/360043212853',
    ACTIVATE_APPROVAL_FLOW: 'https://meajuda.recrut.ai/hc/pt-br/articles/360043212933',
    HOW_APPROVAL_FLOW_WORKS: 'https://meajuda.recrut.ai/hc/pt-br/articles/360043414913',
    RECRUTASIMPLES_INTEGRATION: 'https://meajuda.recrut.ai/hc/',
    INDEED_INTEGRATIONS:
      'https://meajuda.recrut.ai/hc/pt-br/articles/360047283493-Como-divulgar-minhas-vagas-no-Indeed-',
    JOB_POSITION_HELP: 'https://meajuda.recrut.ai/hc/pt-br/articles/360043943454',
    LINKEDIN_INTEGRATION: 'https://meajuda.recrut.ai/hc/',
    LINKEDIN_COMPANYID_ARTICLE:
      'https://www.linkedin.com/help/linkedin/answer/98972/associating-your-linkedin-company-id-with-the-linkedin-job-board-frequently-asked-questions?lang=pt',
    VIDEOINTERVIEW_ARTICLE: 'https://meajuda.recrut.ai/hc/pt-br/articles/360061279833',
    HUNTING_ARTICLE:
      'https://meajuda.recrut.ai/hc/pt-br/articles/360048404814-Como-funciona-o-Hunting-Automatizado-da-RECRUT-AI-',
    SCREENING_CONFIG:
      'https://meajuda.recrut.ai/hc/pt-br/articles/1500011405001-Como-preencher-os-requisitos-obrigat%C3%B3rios-e-opcionais-',
    DIVERSITY_ORIENTATION:
      'https://meajuda.recrut.ai/hc/pt-br/articles/360042425554-Quais-os-tipos-de-usu%C3%A1rios-existentes-',
    SUPPORT_EMAIL: 'meajuda@job.recrut.ai',
    HOW_CROSS_RANK_WORKS: 'https://meajuda.recrut.ai/hc/pt-br/articles/13618220823959',
    HOW_SEARCH_WORKS:
      'https://meajuda.recrut.ai/hc/pt-br/articles/360042180874-Como-pesquisar-candidatos-',
    TALENT_COMPANYID_ARTICLE:
      'https://meajuda.recrut.ai/hc/pt-br/articles/4405156818071-Como-ativar-as-integra%C3%A7%C3%B5es-para-sites-para-divulgar-minhas-vagas-',
    DISQUALIFY_FEEDBACK:
      'https://meajuda.recrut.ai/hc/pt-br/articles/360042181014-Como-enviar-feedbacks-para-os-candidatos-desclassificados'
  },
  US: {
    TOS: 'https://www.recrut.ai/tos/en',
    PRIVACY: 'https://www.recrut.ai/privacy/en',
    LIMIT_USER_VIEW: 'https://meajuda.recrut.ai/hc/pt-br/articles/360042600014',
    USER_TYPES: 'https://meajuda.recrut.ai/hc/pt-br/articles/360042425554',
    SCOPE_TYPES: 'https://meajuda.recrut.ai/hc/pt-br/articles/360043212853',
    ACTIVATE_APPROVAL_FLOW: 'https://meajuda.recrut.ai/hc/pt-br/articles/360043212933',
    HOW_APPROVAL_FLOW_WORKS: 'https://meajuda.recrut.ai/hc/pt-br/articles/360043414913',
    RECRUTASIMPLES_INTEGRATION: 'https://meajuda.recrut.ai/hc/',
    INDEED_INTEGRATIONS: 'https://meajuda.recrut.ai/hc/',
    JOB_POSITION_HELP: 'https://meajuda.recrut.ai/hc/pt-br/articles/360043943454',
    LINKEDIN_INTEGRATION: 'https://meajuda.recrut.ai/hc/',
    LINKEDIN_COMPANYID_ARTICLE:
      'https://www.linkedin.com/help/linkedin/answer/98972/associating-your-linkedin-company-id-with-the-linkedin-job-board-frequently-asked-questions?lang=en',
    VIDEOINTERVIEW_ARTICLE: 'https://meajuda.recrut.ai/hc/pt-br/articles/360061279833',
    HUNTING_ARTICLE:
      'https://meajuda.recrut.ai/hc/pt-br/articles/360048404814-Como-funciona-o-Hunting-Automatizado-da-RECRUT-AI-',
    SCREENING_CONFIG:
      'https://meajuda.recrut.ai/hc/pt-br/articles/1500011405001-Como-preencher-os-requisitos-obrigat%C3%B3rios-e-opcionais-',
    SUPPORT_EMAIL: 'meajuda@job.recrut.ai',
    HOW_CROSS_RANK_WORKS: 'https://meajuda.recrut.ai/hc/pt-br/articles/13618220823959',
    HOW_SEARCH_WORKS:
      'https://meajuda.recrut.ai/hc/pt-br/articles/360042180874-Como-pesquisar-candidatos-',
    DISQUALIFY_FEEDBACK:
      'https://meajuda.recrut.ai/hc/pt-br/articles/360042181014-Como-enviar-feedbacks-para-os-candidatos-desclassificados'
  }
};

export const PAGE_SIZES = [15, 25, 50, 75, 100];
export const JOB_PAGE_SIZES = [15, 25, 50, 75, 100, 200];
export const DEFAULT_PAGE_SIZE = 25;

export const EDITOR_CUSTOMTEST_TOOLBAR = ['inline', 'textAlign', 'link', 'emoji'];

// TESTS TYPES CONSTS
export const CUSTOMTEST_TYPE = {
  ALTERNATIVES: 'ALTERNATIVES',
  OPEN_TEXT: 'OPEN_TEXT',
  FILE: 'FILE',
  EXPOSITION: 'EXPOSITION',
  LINK: 'LINK'
};

// ACCOUNT TYPES
export const PLAN = {
  STARTUP: 'STARTUP',
  BUSINESS: 'BUSINESS',
  ENTERPRISE: 'ENTERPRISE'
};

export const GITHUB_LANGUAGES = [
  'actionscript',
  'c',
  'c#',
  'c++',
  'clojure',
  'coffeescript',
  'css',
  'go',
  'haskell',
  'html',
  'java',
  'javascript',
  'lua',
  'matlab',
  'objective-c',
  'perl',
  'php',
  'python',
  'r',
  'ruby',
  'scala',
  'shell',
  'swift',
  'tex',
  'vim script',
  '1c enterprise',
  '4d',
  'abap',
  'abnf',
  'ada',
  'adobe font metrics',
  'agda',
  'ags script',
  'alloy',
  'alpine abuild',
  'altium designer',
  'ampl',
  'angelscript',
  'ant build system',
  'antlr',
  'apacheconf',
  'apex',
  'api blueprint',
  'apl',
  'apollo guidance computer',
  'applescript',
  'arc',
  'asciidoc',
  'asn.1',
  'asp',
  'aspectj',
  'assembly',
  'asymptote',
  'ats',
  'augeas',
  'autohotkey',
  'autoit',
  'awk',
  'ballerina',
  'batchfile',
  'befunge',
  'bibtex',
  'bison',
  'bitbake',
  'blade',
  'blitzbasic',
  'blitzmax',
  'bluespec',
  'boo',
  'brainfuck',
  'brightscript',
  'c-objdump',
  'c2hs haskell',
  'cabal config',
  "cap'n proto",
  'cartocss',
  'ceylon',
  'chapel',
  'charity',
  'chuck',
  'cirru',
  'clarion',
  'clean',
  'click',
  'clips',
  'closure templates',
  'cloud firestore security rules',
  'cmake',
  'cobol',
  'codeql',
  'coldfusion',
  'coldfusion cfc',
  'collada',
  'common lisp',
  'common workflow language',
  'component pascal',
  'conll-u',
  'cool',
  'coq',
  'cpp-objdump',
  'creole',
  'crystal',
  'cson',
  'csound',
  'csound document',
  'csound score',
  'csv',
  'cuda',
  'curl config',
  'cweb',
  'cycript',
  'cython',
  'd',
  'd-objdump',
  'darcs patch',
  'dart',
  'dataweave',
  'desktop',
  'dhall',
  'diff',
  'digital command language',
  'dircolors',
  'directx 3d file',
  'dm',
  'dns zone',
  'dockerfile',
  'dogescript',
  'dtrace',
  'dylan',
  'e',
  'eagle',
  'easybuild',
  'ebnf',
  'ec',
  'ecere projects',
  'ecl',
  'eclipse',
  'editorconfig',
  'edje data collection',
  'edn',
  'eiffel',
  'ejs',
  'elixir',
  'elm',
  'emacs lisp',
  'emberscript',
  'eml',
  'eq',
  'erlang',
  'f#',
  'f*',
  'factor',
  'fancy',
  'fantom',
  'faust',
  'figlet font',
  'filebench wml',
  'filterscript',
  'fish',
  'flux',
  'formatted',
  'forth',
  'fortran',
  'freemarker',
  'frege',
  'g-code',
  'game maker language',
  'gaml',
  'gams',
  'gap',
  'gcc machine description',
  'gdb',
  'gdscript',
  'genie',
  'genshi'
];

export const AUTOFORWARD_TYPES = [
  STAGE_SCREENING,
  STAGE_CUSTOMTEST,
  STAGE_VIDEOINTERVIEW,
  STAGE_INTERVIEW,
  STAGE_REVIEW,
  STAGE_HIRED
];

export const DEFAULT_JOBCOVER_PATH =
  'https://s3-sa-east-1.amazonaws.com/recrutai-media/img/knowledgearea/';

export const INTERNAL_STAGES = [0, 900, 999, 1000];

export const CUSTOMIZABLE_STAGES = [
  STAGE_SCREENING,
  STAGE_VIDEOINTERVIEW,
  STAGE_INTERVIEW,
  STAGE_CUSTOMTEST
];

export const INTEGRABLE_STAGES = [
  STAGE_REVIEW,
  STAGE_SCREENING,
  STAGE_VIDEOINTERVIEW,
  STAGE_INTERVIEW,
  STAGE_CUSTOMTEST
];

export const STAGES_CONFIG = [
  {
    type: STAGE_SCREENING,
    multiple: false,
    addable: false,
    modalSize: 'md'
  },
  {
    type: STAGE_VIDEOINTERVIEW,
    multiple: false, // FIXME
    addable: true,
    modalSize: 'md'
  },
  {
    type: STAGE_INTERVIEW,
    multiple: true,
    addable: true,
    modalSize: 'md'
  },
  {
    type: STAGE_CUSTOMTEST,
    multiple: true,
    addable: true,
    modalSize: 'sm'
  },
  {
    type: STAGE_REVIEW,
    multiple: true,
    addable: true,
    modalSize: 'sm'
  },
  {
    type: STAGE_HIRED,
    multiple: false,
    addable: false,
    modalSize: 'sm'
  },
  {
    type: STAGE_DISQUALIFIED,
    multiple: false,
    addable: false,
    modalSize: 'md'
  }
];

export const DEFAULT_PIPELINE = {
  SELECTION: [
    {
      id: 0,
      priority: 0,
      name: 'type_screening',
      type: STAGE_SCREENING,
      filter_profile: false
    },
    {
      id: 2,
      priority: 2,
      name: 'type_videointerview',
      type: STAGE_VIDEOINTERVIEW,
      sendEmail: true,
      sendSms: true
    },
    {
      id: 3,
      priority: 3,
      name: 'type_interview',
      type: STAGE_INTERVIEW,
      enable_scheduler: true,
      sendEmail: true,
      sendSms: true
    },
    {
      id: 4,
      priority: 4,
      name: 'type_review',
      type: STAGE_REVIEW
    },
    {
      id: 900,
      priority: 5,
      name: 'type_hired',
      type: STAGE_HIRED
    },
    {
      id: 999,
      priority: 6,
      name: 'type_disqualified',
      type: STAGE_DISQUALIFIED
    }
  ],
  ASSESSMENT: [
    {
      id: 1,
      priority: 1,
      name: 'type_interview',
      type: STAGE_INTERVIEW,
      sendEmail: true,
      sendSms: true
    },
    {
      id: 2,
      priority: 2,
      name: 'type_review',
      type: STAGE_REVIEW
    }
  ]
};

export var getApplicantDetailBaseFields = function () {
  const actualDate = new Date();
  const APPLICANT_DETAIL_BASEFIELDS = {
    fields: [
      'id',
      'job_id',
      'name',
      'internal',
      'avatar_50_path',
      'email',
      'verified_email',
      'cv_match_name',
      'cv_match_email',
      'age',
      'gender',
      'phone_mobile',
      'origin',
      'pwd',
      'pso',
      'pwr',
      'zip',
      'address',
      'distance_job',
      'pipeline_stage',
      'date_created',
      'referer',
      'hire_status',
      'favorites',
      'tags',
      'internal_verified',
      'employee_id',
      'cv_text_areas',
      'doc_type',
      'doc_number',
      'person_id',
      'cv_profile',
      'cv_text',
      'cv_path',
      'cv_score',
      'salary_info',
      'referral_id',
      'transfer_info'
    ],
    include: [
      {
        relation: 'notes',
        scope: {
          where: {
            deleted: false
          },
          include: {
            relation: 'accountUser',
            scope: {
              fields: ['id', 'username', 'role', 'unique_name', 'user_profile', 'avatar_50_path']
            }
          }
        }
      },
      {
        relation: 'referral',
        scope: {
          fields: ['id', 'name', 'email', 'date_created', 'user_id', 'job_id', 'internal']
        }
      },
      {
        relation: 'views',
        scope: {
          fields: ['user_id', 'date_created'],
          limit: 1,
          where: {
            user_id: UserProfile.getId()
          },
          order: ['date_created DESC']
        }
      },
      {
        relation: 'job',
        scope: {
          fields: [
            'id',
            'code',
            'title',
            'address',
            'address_point',
            'status',
            'reviewers',
            'has_review_stage',
            'review_config',
            'academic_formation',
            'experience',
            'idiom',
            'skill',
            'industry',
            'rank_version',
            'job_profile_id',
            'pipeline_stages',
            'type',
            'user_recruiter',
            'country',
            'user_created',
            'user_requester',
            'user_approver',
            'user_manager'
          ],
          include: [
            {
              relation: 'jobProfile',
              scope: {
                fields: ['academic_formation', 'experience', 'idiom', 'skill', 'industry']
              }
            },
            {
              relation: 'userRecruiter',
              scope: {
                fields: ['username', 'id', 'avatar_50_path', 'email']
              }
            },
            {
              relation: 'userCreated',
              scope: {
                fields: ['username', 'id', 'avatar_50_path', 'email']
              }
            },
            {
              relation: 'userManager',
              scope: {
                fields: ['username', ' id', 'avatar_50_path', 'email']
              }
            },
            {
              relation: 'userRequester',
              scope: {
                fields: ['id', 'username', 'role', 'user_profile', 'avatar_50_path']
              }
            }
          ]
        }
      },
      {
        relation: 'emailBlocklists',
        scope: {
          fields: [
            'id',
            'email',
            'doc_number',
            'user_id',
            'motive_id',
            'date_created',
            'date_limit',
            'active'
          ],
          where: {
            active: true,
            and: [
              {
                or: [{ date_limit: null }, { date_limit: { gte: actualDate.toISOString() } }]
              }
            ]
          },
          order: ['date_created DESC'],
          include: [
            {
              relation: 'accountUser',
              scope: {
                fields: ['id', 'username', 'avatar_50_path']
              }
            },
            {
              relation: 'blockMotives',
              scope: {
                fields: ['id', 'motive']
              }
            }
          ]
        }
      },
      {
        relation: 'docBlocklists',
        scope: {
          fields: [
            'id',
            'email',
            'doc_number',
            'user_id',
            'motive_id',
            'date_created',
            'date_limit',
            'active'
          ],
          where: {
            active: true,
            and: [
              {
                or: [{ date_limit: null }, { date_limit: { gte: actualDate.toISOString() } }]
              }
            ]
          },
          order: ['date_created DESC'],
          include: [
            {
              relation: 'accountUser',
              scope: {
                fields: ['id', 'username', 'avatar_50_path']
              }
            },
            {
              relation: 'blockMotives',
              scope: {
                fields: ['id', 'motive']
              }
            }
          ]
        }
      },
      {
        relation: 'person',
        scope: {
          fields: ['id'],
          include: {
            relation: 'applicants',
            scope: {
              fields: ['id', 'person_id']
            }
          }
        }
      }
    ],
    counts: ['notes']
  };

  return APPLICANT_DETAIL_BASEFIELDS;
};
