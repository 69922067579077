import React from 'react';
import ApplicantsSearch from './ApplicantsSearch';
import ContentWrapper from '../Layout/ContentWrapper';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = (theme) => ({
  whiteContainer: {
    background: theme.palette.common.white,
    borderRadius: '1rem',
    padding: '16px 0 22px 16px'
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 500
  }
});

class TalentPoolPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = 'Recrut.AI | ' + this.props.intl.formatMessage({ id: 'ModalSourcing.title' });
  }

  render() {
    const { classes } = this.props;
    return (
      <ContentWrapper>
        <Box className={classes.whiteContainer} mb={-1.5}>
          <Typography className={classes.title}>
            {this.props.intl.formatMessage({ id: 'ModalSourcing.title' })}
          </Typography>
        </Box>
        <ApplicantsSearch {...this.props} outsideOfPipeline={true} />
      </ContentWrapper>
    );
  }
}

export default injectIntl(withStyles(useStyles)(TalentPoolPage));
