import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CardBase from './CardBase';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';

class CardSkeleton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card
        style={{
          padding: '1.25rem',
          boxShadow: 'none'
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          style={{
            minWidth: 0
          }}
        >
          <Box minWidth="52px"></Box>
          <Box display="flex" width="100%" minWidth={0}>
            <Box mr={3}>
              <Skeleton variant="circle" animation="wave" width={50} height={50} />
            </Box>
            <Box width="100%" minWidth={0}>
              <Skeleton animation="wave" height={16} width={144} style={{ marginBottom: 6 }} />
              <Skeleton
                animation="wave"
                height={14}
                style={{ marginBottom: 6, maxWidth: '280px' }}
              />
              <Skeleton
                animation="wave"
                height={14}
                style={{ marginBottom: 6, maxWidth: '400px' }}
              />
              <Skeleton
                animation="wave"
                height={14}
                style={{ marginBottom: 6, maxWidth: '280px' }}
              />
              <Skeleton
                animation="wave"
                height={14}
                style={{ marginBottom: 6, maxWidth: '400px' }}
              />
              <Skeleton
                animation="wave"
                height={14}
                style={{ marginBottom: 6, maxWidth: '400px' }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    );
  }
}

export default CardSkeleton;
