import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import SearchInstructionsIcon from './SearchInstructionsIcon';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseCities from './CloseCities';
import { withStyles } from '@material-ui/core/styles';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import EditAttributesOutlinedIcon from '@material-ui/icons/EditAttributesOutlined';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';

const useStyles = (theme) => ({
  helpInstructionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0'
  },
  helpInstructionsText: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium,
    //color: theme.palette.gray.dark,
    fontWeight: 400,
    textAlign: 'center'
  },
  keywordsInstructionsText: {
    fontSize: '10px',
    color: theme.palette.gray.darkerMedium
    //color: theme.palette.gray.dark,
  },
  helpExamplesTitle: {
    fontSize: '12px',
    color: theme.palette.primary.main,
    fontWeight: 700
  },
  helpSearchIcon: {
    color: theme.palette.common.white,
    fontSize: '16px'
  },
  helpSearchIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.5rem',
    height: '1.5rem',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100%',
    marginRight: '0.5rem'
  },
  exampleText: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium,
    //color: theme.palette.gray.dark,
    fontWeight: 400,
    fontStyle: 'italic',
    lineHeight: 2
  },
  exampleTextSmall: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium,
    //color: theme.palette.gray.dark,
    fontWeight: 400,
    fontStyle: 'italic',
    lineHeight: 1
  },
  keyword: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}11`,
    fontStyle: 'normal',
    borderRadius: '1rem',
    padding: '0.25rem 0.5rem',
    width: 'fit-content',
    whiteSpace: 'nowrap'
  },
  keywordIcon: {
    fontSize: '16px'
  }
});

const KEYWORDS = {
  ID_DOCUMENT: '111.222.333-44',
  FIRST_KEYWORD: 'Redux',
  SECOND_KEYWORD: 'Tailwind',
  THIRD_KEYWORD: 'AWS',
  SCHOOL: 'UFPE',
  CITY: 'São Paulo'
};

class SearchInstructions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      variant: props.variant ? props.variant : ''
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <Box display="flex" flexDirection="column">
        <Box width="100%" display="flex" alignItems="center" justifyContent="center" mb={4}>
          <Box display="flex" alignItems="center" width="100%" maxWidth="532px">
            <SearchInstructionsIcon />
            <Box ml={2}>
              <Typography className={classes.helpInstructionsText}>
                <FormattedMessage
                  id="SearchInstructions.text_helpInstructions"
                  defaultMessage="Our AI can understand your search in a natural way, obtaining a much more accurate and assertive result. See some examples below..."
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          className={classes.helpInstructionsWrapper}
          style={{
            gap: this.state.variant === 'applicantsSearch' ? '5rem' : '11.5rem'
          }}
        >
          <Box flex="1">
            <Box display="flex" alignItems="center" mb={2}>
              <Box className={classes.helpSearchIconWrapper}>
                <SearchIcon className={classes.helpSearchIcon} />
              </Box>
              <Typography className={classes.helpExamplesTitle}>
                <FormattedMessage
                  id="SearchInstructions.title_example"
                  defaultMessage="Example {num}"
                  values={{
                    num: 1
                  }}
                />
              </Typography>
            </Box>

            <Box mb={1}>
              <Typography className={classes.exampleText}>
                <span className={classes.keyword}>
                  <FormattedMessage
                    id="SearchInstructions.keyword_role"
                    defaultMessage="Store Operator"
                  />{' '}
                  <WorkOutlineOutlinedIcon className={classes.keywordIcon} />
                </span>{' '}
                <FormattedMessage id="SearchInstructions.word_for" defaultMessage="for" />{' '}
                <span className={classes.keyword}>
                  {' '}
                  <FormattedMessage
                    id="SearchInstructions.keyword_replenishment"
                    defaultMessage="replenishment"
                  />{' '}
                  <EditAttributesOutlinedIcon className={classes.keywordIcon} />
                </span>{' '}
                <FormattedMessage id="SearchInstructions.word_thatIs" defaultMessage="that is" />{' '}
                <span className={classes.keyword}>
                  {' '}
                  <FormattedMessage id="DetailSummary.pwd" />{' '}
                  <AccessibleForwardIcon className={classes.keywordIcon} />
                </span>{' '}
                <FormattedMessage id="SearchInstructions.word_and" defaultMessage="and" />{' '}
                <span className={classes.keyword}>
                  {' '}
                  <FormattedMessage
                    id="SearchInstructions.keyword_internal"
                    defaultMessage="internal candidate"
                  />{' '}
                  <PersonOutlineOutlinedIcon className={classes.keywordIcon} />
                </span>{' '}
                <FormattedMessage id="SearchInstructions.word_in" defaultMessage="in" />{' '}
                <span className={classes.keyword}>
                  {KEYWORDS.CITY} <LocationOnIcon className={classes.keywordIcon} />
                </span>{' '}
                <FormattedMessage id="SearchInstructions.word_and" />{' '}
                <span className={classes.keyword}>
                  <FormattedMessage
                    id="SearchInstructions.keyword_nearCities"
                    defaultMessage="near cities"
                  />
                  .
                </span>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={2.5}>
                <CloseCities />
              </Box>
              <Box>
                <Typography className={classes.keywordsInstructionsText}>
                  <span
                    style={{
                      fontWeight: 700
                    }}
                  >
                    <FormattedMessage
                      id="SearchInstructions.text_nearCities"
                      defaultMessage="How does the search for nearby cities work?"
                    />
                  </span>
                  <br />
                  <FormattedMessage
                    id="SearchInstructions.text_nearCitiesExplanation"
                    defaultMessage="Our AI can append to your search cities within 30km of range to {city}."
                    values={{
                      city: 'São Paulo'
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box flex="1">
            <Box display="flex" alignItems="center" mb={2}>
              <Box className={classes.helpSearchIconWrapper}>
                <SearchIcon className={classes.helpSearchIcon} />
              </Box>
              <Typography className={classes.helpExamplesTitle}>
                <FormattedMessage
                  id="SearchInstructions.title_example"
                  defaultMessage="Example {num}"
                  values={{
                    num: 2
                  }}
                />
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.exampleText}>
                <span className={classes.keyword}>
                  {' '}
                  <FormattedMessage
                    id="SearchInstructions.keyword_reactDevelop"
                    defaultMessage="React Developer"
                  />{' '}
                  <WorkOutlineOutlinedIcon className={classes.keywordIcon} />
                </span>{' '}
                <FormattedMessage
                  id="SearchInstructions.word_knowledgeIn"
                  defaultMessage="with knowledge in"
                />{' '}
                <span className={classes.keyword}>
                  {KEYWORDS.FIRST_KEYWORD}{' '}
                  <EditAttributesOutlinedIcon className={classes.keywordIcon} />
                </span>
                {', '}
                <span className={classes.keyword}>
                  {KEYWORDS.SECOND_KEYWORD}{' '}
                  <EditAttributesOutlinedIcon className={classes.keywordIcon} />
                </span>{' '}
                <FormattedMessage id="SearchInstructions.word_and" />{' '}
                <span className={classes.keyword}>
                  {KEYWORDS.THIRD_KEYWORD}{' '}
                  <EditAttributesOutlinedIcon className={classes.keywordIcon} />
                </span>{' '}
                {/*  {' '}
                <FormattedMessage
                  id="SearchInstructions.keyword_graduated"
                  defaultMessage="graduated from"
                />{' '}
                <span className={classes.keyword}>
                  {KEYWORDS.SCHOOL} <SchoolOutlinedIcon className={classes.keywordIcon} />
                </span>{' '} */}
                <FormattedMessage id="SearchInstructions.word_andWith" defaultMessage="and with" />{' '}
                <span className={classes.keyword}>
                  {' '}
                  <FormattedMessage
                    id="SearchInstructions.keyword_english"
                    defaultMessage="English"
                  />{' '}
                  <VpnLockIcon className={classes.keywordIcon} />
                </span>
              </Typography>
            </Box>
          </Box>
          {this.state.variant === 'applicantsSearch' ? (
            <Box flex="1">
              <Box display="flex" alignItems="center" mb={2}>
                <Box className={classes.helpSearchIconWrapper}>
                  <SearchIcon className={classes.helpSearchIcon} />
                </Box>
                <Typography className={classes.helpExamplesTitle}>
                  <FormattedMessage
                    id="SearchInstructions.title_example"
                    defaultMessage="Example {num}"
                    values={{
                      num: 3
                    }}
                  />
                </Typography>
              </Box>

              <Box mb={1}>
                <Typography className={classes.exampleTextSmall}>
                  <FormattedMessage
                    id="SearchInstructions.text_contactSearch"
                    defaultMessage="You can also search for the following data below:"
                  />
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box className={classes.keyword}>
                  <FormattedMessage
                    id="SearchInstructions.keyword_nameSurname"
                    defaultMessage="Name Surname"
                  />{' '}
                  <PersonOutlineOutlinedIcon className={classes.keywordIcon} />
                </Box>
                <Box pl={7}>
                  <Typography className={classes.exampleText}>
                    <FormattedMessage id="SearchInstructions.word_alternate" defaultMessage="or" />
                  </Typography>
                </Box>
                <Box className={classes.keyword}>
                  <FormattedMessage
                    id="SearchInstructions.keyword_email"
                    defaultMessage="name@gmail.com"
                  />{' '}
                  <EmailOutlinedIcon className={classes.keywordIcon} />
                </Box>
                <Box pl={7}>
                  <Typography className={classes.exampleText}>
                    <FormattedMessage id="SearchInstructions.word_alternate" />
                  </Typography>
                </Box>
                <Box className={classes.keyword}>
                  {KEYWORDS.ID_DOCUMENT}{' '}
                  <AssignmentIndOutlinedIcon className={classes.keywordIcon} />
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  }
}

export default injectIntl(withStyles(useStyles)(SearchInstructions));
