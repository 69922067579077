import React from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import InstructionsArrow from './InstructionArrow';
import InstructionsResume from './InstructionsResume';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const messages = defineMessages({});

const useStyles = (theme) => ({
  helperText: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '12px',
    textAlign: 'center'
  }
});

class Instructions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      helperText: props.helperText,
      icon: props.icon
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box display="flex" alignItems="center" mb={0.4} pr={18}>
          <InstructionsArrow />
          {this.state.icon}
        </Box>
        <Box width="75%">
          <Typography className={classes.helperText}>{this.state.helperText}</Typography>
        </Box>
      </Box>
    );
  }
}

export default injectIntl(withStyles(useStyles)(Instructions));
