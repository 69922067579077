import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import PeriodOptions from '../PeriodOptions';
import TwoMonthsPeriodOptions from '../TwoMonthsPeriodOptions';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Divider from '@material-ui/core/Divider';
import { DateRangePicker, DateRangeDelimiter } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Box from '@material-ui/core/Box';
import UserStorage from '../../Common/UserStorage';
import LocaleFormatter from '../../Common/LocaleFormatter';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';

const messages = defineMessages({
  title_period_long: {
    id: 'ButtonFilterPeriod.title_period_long',
    defaultMessage: 'Date range is too long'
  },
  period_today: {
    id: 'ButtonFilterPeriod.period_today',
    defaultMessage: 'Today'
  },
  period_yesterday: {
    id: 'ButtonFilterPeriod.period_yesterday',
    defaultMessage: 'Yesterday'
  },
  period_thisWeek: {
    id: 'ButtonFilterPeriod.period_thisWeek',
    defaultMessage: 'This Week'
  },
  period_lastWeek: {
    id: 'ButtonFilterPeriod.period_lastWeek',
    defaultMessage: 'Last 7 Days'
  },
  period_last2Weeks: {
    id: 'ButtonFilterPeriod.period_last2Weeks',
    defaultMessage: 'Last 2 Weeks'
  },
  period_last30Days: {
    id: 'ButtonFilterPeriod.period_last30Days',
    defaultMessage: 'Last 30 Days'
  },
  period_thisMonth: {
    id: 'ButtonFilterPeriod.period_thisMonth',
    defaultMessage: 'This Month'
  },
  period_lastMonth: {
    id: 'ButtonFilterPeriod.period_lastMonth',
    defaultMessage: 'Last Month'
  },
  period_thisTrimester: {
    id: 'ButtonFilterPeriod.period_thisTrimester',
    defaultMessage: 'This Trimester'
  },
  period_lastTrimester: {
    id: 'ButtonFilterPeriod.period_lastTrimester',
    defaultMessage: 'Last Trimester'
  },
  period_custom: {
    id: 'ButtonFilterPeriod.period_custom',
    defaultMessage: 'Custom Start/End'
  }
});

const useStyles = (theme) => ({
  mainButton: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& .MuiButton-startIcon': {
      paddingLeft: '13px'
    }
  },
  mainButtonLabelContainer: {
    display: 'flex',
    width: '80%'
  },
  mainButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mainButtonLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    marginBottom: '0'
  },
  checkButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  checkButtonLabel: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '0.8125rem',
    fontWeight: '500',
    paddingTop: '0.25rem'
  },
  filterLabel: {
    display: 'flex',
    marginBottom: '0.25rem',
    color: theme.palette.primary.main
  },
  filterLabelActive: {
    color: theme.palette.primary.main
  }
});

class ButtonFilterPeriod extends React.Component {
  constructor(props) {
    super(props);

    const periodOptions = PeriodOptions(this);
    const twoMonthsPeriodOptions = TwoMonthsPeriodOptions(this);

    let currentSelectedOption =
      props.selected !== -2
        ? props.selected !== null && props.selected !== null && props.selected > -1
          ? props.selected
          : 0
        : 0;

    let selectedOption =
      props.selected !== -2
        ? props.selected !== null && props.selected !== null && props.selected > -1
          ? props.selected
          : 0
        : 0;

    this.state = {
      // State from props
      currentSelected: props.required ? currentSelectedOption : props.initialFixedDate || -1,
      selected: props.required ? selectedOption : props.initialFixedDate || 0,
      required: props.required !== null && props.required !== undefined ? props.required : true,
      label: props.label
        ? props.label
        : this.props.intl.formatMessage({ id: 'JobsReportsFiltersContainer.label_filter' }),
      periodLimit:
        props.periodLimit !== null && props.periodLimit !== undefined ? props.periodLimit : null,
      // Internal State
      periodOptions: props.periodLimit === 2 ? twoMonthsPeriodOptions : periodOptions,
      custom_dates: [Moment(periodOptions[0].date_start), Moment(periodOptions[0].date_end)],
      maxDate: null,
      minDate: null,
      // Menu
      openMenu: false,
      anchorEl: null
    };
  }

  componentDidMount() {
    if (this.props.handlerUpdate) {
      const index = this.state.currentSelected;

      // Check if there's custom_dates selected
      const selectedDate = index === -1 ? null : this.state.periodOptions[index];

      this.props.handlerUpdate(selectedDate, null);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selected !== this.props.selected &&
      this.props.selected !== this.state.currentSelected
    ) {
      this.setState(
        {
          selected: this.props.initialFixedDate ? this.props.initialFixedDate : this.props.selected,
          currentSelected: this.props.initialFixedDate
            ? this.props.initialFixedDate
            : this.props.selected
        },
        () => {
          if (this.props.handlerUpdate) {
            const index = this.state.currentSelected;

            // Check if there's custom_dates selected
            const selectedDate = index === -1 ? null : this.state.periodOptions[index];

            this.props.handlerUpdate(selectedDate, null);
          }
        }
      );
    }
  }

  handleMenuOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState(
      {
        anchorEl: null
      },
      () => {
        if (this.props.handlerUpdate) {
          const index = this.state.currentSelected;

          // Check if there's custom_dates selected
          const selectedDate =
            index === -1
              ? null
              : this.state.custom_dates.length === 2
              ? {
                  label: null,
                  date_start: this.state.custom_dates[0]
                    ? Moment(this.state.custom_dates[0]).utc()
                    : null,
                  date_end: this.state.custom_dates[1]
                    ? Moment(this.state.custom_dates[1]).endOf('day').utc()
                    : null
                }
              : this.state.periodOptions[index];

          this.props.handlerUpdate(selectedDate, index);
        }
      }
    );
  };

  handleCustomDatesChange(dates) {
    let newDates = dates;
    //console.log(dates)

    const periodLimit = this.state.periodLimit;
    let minDate = null;
    let maxDate = this.state.maxDate;

    if (periodLimit) {
      const currentDates = this.state.custom_dates;
      //console.log(currentDates)

      const isNotSettingDateEnd =
        (Moment.isMoment(dates[1]) &&
          Moment.isMoment(currentDates[1]) &&
          dates[1].day() === currentDates[1].day()) ||
        !dates[1];

      // Setting date_start
      if (dates[0] !== currentDates[0] && isNotSettingDateEnd) {
        const monthsLimit = periodLimit;

        minDate = null;
        maxDate =
          Moment(dates[1]) > Moment(dates[0]).add(monthsLimit, 'months') || !dates[1]
            ? Moment(dates[0]).add(monthsLimit, 'months') > Moment()
              ? Moment().endOf('day').toDate()
              : Moment(dates[0]).add(monthsLimit, 'months')
            : dates[1];

        newDates = [dates[0], maxDate];
      }
    }

    this.setState({
      custom_dates: newDates,
      minDate: minDate,
      maxDate: maxDate
    });
  }

  setMaxDate = (e) => {
    const inputName = e.target.name;
    let maxDate = this.state.maxDate;

    if (inputName === 'date_end') {
      // Set maxDate before setting date_end to prevent maxDate being null
      const currentDates = this.state.custom_dates;
      if (!maxDate) {
        maxDate =
          Moment(currentDates[1]) > Moment(currentDates[0]).add(this.state.periodLimit, 'months') ||
          !currentDates[1]
            ? Moment(currentDates[0]).add(this.state.periodLimit, 'months') > Moment()
              ? Moment().endOf('day').toDate()
              : Moment(currentDates[0]).add(this.state.periodLimit, 'months')
            : currentDates[1];
      }
    }

    if (inputName === 'date_start') {
      // Set maxDate before setting date_end to prevent maxDate being null
      maxDate = null;
    }

    this.setState({
      maxDate: maxDate
    });
  };

  selectOption = (index) => {
    this.setState(
      {
        selected: index,
        currentSelected: index,
        custom_dates: [
          Moment(this.state.periodOptions[index].date_start),
          Moment(this.state.periodOptions[index].date_end)
        ]
      },
      () => this.handleMenuClose()
    );
  };

  selectCustomOption = () => {
    this.setState({
      selected: -2,
      currentSelected: -2
    });
  };

  checkCustomDatesInterval = () => {
    this.setState(
      {
        selected: -2,
        currentSelected: -2
      },
      () => {
        const custom_dates = this.state.custom_dates;

        const intervalDifference =
          custom_dates &&
          custom_dates.length > 0 &&
          Moment.isMoment(custom_dates[1]) &&
          custom_dates[1].diff(custom_dates[0], 'days');

        const intervalDaysWithAlert = 180;

        if (intervalDifference && intervalDifference >= intervalDaysWithAlert) {
          window.swal({
            type: 'info',
            title: this.props.intl.formatMessage({ id: 'ButtonFilterPeriod.title_period_long' }),
            text: this.props.intl.formatMessage({ id: 'ButtonFilterPeriod.msg_period_long' }),
            closeOnConfirm: true
          });
        }

        if (this.props.handlerUpdate) {
          this.props.handlerUpdate(
            {
              label: null,
              date_start: this.state.custom_dates[0]
                ? Moment(this.state.custom_dates[0]).utc()
                : null,
              date_end: this.state.custom_dates[1]
                ? Moment(this.state.custom_dates[1]).endOf('day').utc()
                : null
            },
            this.state.currentSelected
          );
        }

        this.setState({
          maxDate: null
        });
      }
    );
  };

  handleCheckUncheck = () => {
    const currentSelected = this.state.currentSelected === -1 ? this.state.selected : -1;

    this.setState({
      currentSelected: currentSelected
    });
  };

  render() {
    const { classes } = this.props;
    const periodOptions = this.state.periodOptions;
    const selected = this.state.selected;
    const custom_dates = this.state.custom_dates;

    const periodLimit = this.state.periodLimit;

    const getMinDate = () => {
      if (!periodLimit) {
        return null;
      }

      if (this.state.custom_dates[1]) {
        const monthsLimit = periodLimit;
        const minDate = Moment(this.state.custom_dates[1]).subtract(monthsLimit, 'months');
        return minDate;
      } else {
        return Moment().add(1, 'days');
      }
    };

    const getMaxDate = () => {
      if (!periodLimit) {
        return Moment().endOf('day').toDate();
      }

      if (this.state.custom_dates[0]) {
        const monthsLimit = periodLimit;
        const maxDate =
          Moment(this.state.custom_dates[0]).add(monthsLimit, 'months') > Moment()
            ? Moment().endOf('day').toDate()
            : Moment(this.state.custom_dates[0]).add(monthsLimit, 'months');

        return maxDate;
      } else {
        return null;
      }
    };

    const displayLabel = () => {
      if (selected === -1) {
        return '';
      } else if (selected === -2) {
        const date = (
          <span className={classes.mainButtonLabelContainer}>
            <p
              style={{
                overflow: 'hidden',
                display: 'inline-block',
                marginBottom: '0',
                whiteSpace: 'nowrap'
              }}
            >
              <LocaleFormatter dateToFormat={Moment(custom_dates[0]).format('l')} /> -{' '}
              <LocaleFormatter dateToFormat={Moment(custom_dates[1]).format('l')} />
            </p>
          </span>
        );
        return date;
      } else {
        const date = (
          <span className={classes.mainButtonLabelContainer}>
            <p
              style={{
                overflow: 'hidden',
                display: 'inline-block',
                marginBottom: '0',
                whiteSpace: 'nowrap'
              }}
            >
              <LocaleFormatter
                dateToFormat={Moment(periodOptions[selected].date_start).format('l')}
              />{' '}
              -{' '}
              <LocaleFormatter
                dateToFormat={Moment(periodOptions[selected].date_end).format('l')}
              />
            </p>
          </span>
        );
        return date;
      }
    };

    const displayOptions = periodOptions.map((option, i) => {
      const selected = this.state.selected;
      let active = false;
      if (selected === i) {
        active = true;
      }

      const disabled = selected === -1;

      return (
        <MenuItem selected={active} onClick={() => this.selectOption(i)} disabled={disabled}>
          {option.label}
        </MenuItem>
      );
    });

    const displayRangePicker = () => {
      const disabled = selected === -1;

      if (periodLimit) {
        return (
          <DateRangePicker
            disabled={disabled}
            name="custom_dates"
            startText=""
            endText=""
            calendars={2}
            autoOk={false}
            minDate={this.state.minDate}
            maxDate={this.state.maxDate}
            value={this.state.custom_dates}
            onChange={(dates) => this.handleCustomDatesChange(dates)}
            onClose={this.checkCustomDatesInterval}
            disableFuture
            renderInput={(startProps, endProps) => (
              <>
                <TextField
                  {...startProps}
                  margin="dense"
                  size="small"
                  variant="standard"
                  helperText=""
                  style={{ width: 110 }}
                  name="date_start"
                  onClick={(e) => this.setMaxDate(e)}
                  inputProps={periodLimit ? { ...startProps.inputProps, readOnly: true } : null}
                />
                <DateRangeDelimiter> &gt; </DateRangeDelimiter>
                <TextField
                  {...endProps}
                  margin="dense"
                  size="small"
                  variant="standard"
                  helperText=""
                  style={{ width: 110 }}
                  name="date_end"
                  onClick={(e) => this.setMaxDate(e)}
                  inputProps={periodLimit ? { ...endProps.inputProps, readOnly: true } : null}
                />
              </>
            )}
          />
        );
      } else {
        return (
          <DateRangePicker
            disabled={disabled}
            name="custom_dates"
            startText=""
            endText=""
            calendars={2}
            autoOk={false}
            minDate={getMinDate()}
            maxDate={getMaxDate()}
            value={this.state.custom_dates}
            onChange={(dates) => this.handleCustomDatesChange(dates)}
            onClose={this.checkCustomDatesInterval}
            renderInput={(startProps, endProps) => (
              <>
                <TextField
                  {...startProps}
                  margin="dense"
                  size="small"
                  variant="standard"
                  helperText=""
                  style={{ width: 110 }}
                />
                <DateRangeDelimiter> &gt; </DateRangeDelimiter>
                <TextField
                  {...endProps}
                  margin="dense"
                  size="small"
                  variant="standard"
                  helperText=""
                  style={{ width: 110 }}
                />
              </>
            )}
          />
        );
      }
    };

    const displayCustom = () => {
      let active = false;
      if (selected === -2) {
        active = true;
      }

      const disabled = selected === -1;

      return (
        <>
          <ListSubheader>
            <FormattedMessage id="ButtonFilterPeriod.period_custom" />
          </ListSubheader>
          <ListItem selected={active} disabled={disabled}>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <Box width="100%" mr={1}>
                {displayRangePicker}
              </Box>
              {periodLimit ? (
                <Box width="100%">
                  <small>
                    <FormattedMessage
                      id="ButtonFilterPeriod.label_periodLimit"
                      defaultMessage="Max date range: {days} days"
                      values={{
                        days: periodLimit * 30
                      }}
                    />
                  </small>
                </Box>
              ) : null}
            </Box>
          </ListItem>
        </>
      );
    };

    return (
      <>
        <div className={classes.mainButtonContainer}>
          <Typography
            variant="subtitle1"
            className={`${
              this.state.currentSelected !== -1 ? classes.filterLabelActive : 'text-muted'
            } ${classes.filterLabel}`}
          >
            {this.state.label}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            color={this.state.currentSelected === -1 ? 'secondary' : 'primary'}
            onClick={this.handleMenuOpen}
            className={classes.mainButton}
            startIcon={<DateRangeIcon />}
            endIcon={<ArrowDropDownIcon />}
            style={{
              height: this.props.size === 'large' ? 38 : 32
            }}
          >
            {this.state.currentSelected !== -1 ? displayLabel() : ''}
          </Button>
        </div>

        <Menu
          id="action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
          disableEnforceFocus={true} // https://github.com/mui-org/material-ui-pickers/issues/1852
        >
          {this.state.required ? null : (
            <>
              <div className={classes.checkButton} onClick={this.handleCheckUncheck}>
                <Checkbox checked={this.state.currentSelected !== -1} color="primary" />
                <InputLabel id="duration-label" className={classes.checkButtonLabel}>
                  <FormattedMessage
                    id="ButtonFilterPeriod.label_enableFilter"
                    defaultMessage="Enable Filter"
                  />
                </InputLabel>
              </div>
              <Divider />
            </>
          )}
          {displayOptions}
          <Divider />
          {displayCustom()}
        </Menu>
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ButtonFilterPeriod));
