import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import WcIcon from '@material-ui/icons/Wc';
import * as CONST from '../../Common/constants';

const messages = defineMessages({
  male: {
    id: 'InfoGender.male',
    defaultMessage: 'Male'
  },
  female: {
    id: 'InfoGender.female',
    defaultMessage: 'Female'
  },
  nonBinary: {
    id: 'InfoGender.nonBinary',
    defaultMessage: 'Non Binary'
  },
  other: {
    id: 'InfoGender.other',
    defaultMessage: 'Other'
  },
  optOut: {
    id: 'InfoGender.optOut',
    defaultMessage: 'Opt Out'
  }
});

class InfoGender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: props.gender,
      size: props.size ? props.size : 24
    };
  }

  render() {
    if (this.state.gender) {
      let gen = '';
      switch (this.state.gender) {
        case 'M':
          gen = this.props.intl.formatMessage({ id: 'InfoGender.male' });
          break;
        case 'F':
          gen = this.props.intl.formatMessage({ id: 'InfoGender.female' });
          break;
        case 'NB':
          gen = this.props.intl.formatMessage({ id: 'InfoGender.nonBinary' });
          break;
        case 'O':
          gen = this.props.intl.formatMessage({ id: 'InfoGender.other' });
          break;
        case 'OPTOUT':
          gen = this.props.intl.formatMessage({ id: 'InfoGender.optOut' });
          break;
      }
      return (
        <Tooltip title={gen} arrow>
          <WcIcon
            style={{
              color: CONST.APP_COLORS['gray-darker-regular'],
              fontSize: this.state.size
            }}
          />
        </Tooltip>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(InfoGender);
