import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';
import jobsReducer from './jobs.reducer.js';
import requestsReducer from './requests.reducer.js';
import userReducer from './user.reducer.js';
import planReducer from './plan.reducer.js';
import accountReducer from './account.reducer.js';
import companyTreeReducer from './companyTree.reducer.js';
import applicantTagsReducer from './applicantTags.reducer.js';
import jobTagsReducer from './jobTags.reducer.js';
import identityDocsReducer from './identityDocs.reducer.js';
import industriesReducer from './industries.reducer.js';
import linkedinIndustriesReducer from './linkedinIndustries.reducer.js';
import countriesReducer from './countries.reducer.js';
// const appReducer = combineReducers({
//   state: (state = {}) => state
// });
// const rootReducer = (state, action) => {
//   if(action.type === DESTROY_SESSION)
//      state = undefined;

//   return appReducer(state, action);
// };
// export default rootReducer;

export default combineReducers({
  settings: settingsReducer,
  theme: themesReducer,
  jobs: jobsReducer,
  requests: requestsReducer,
  user: userReducer,
  planInfo: planReducer,
  accountInfo: accountReducer,
  companyTree: companyTreeReducer,
  applicantTags: applicantTagsReducer,
  jobTags: jobTagsReducer,
  identityDocs: identityDocsReducer,
  industries: industriesReducer,
  linkedinIndustries: linkedinIndustriesReducer,
  countries: countriesReducer
});
