import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Moment from 'moment';
import Typography from '@material-ui/core/Typography';
import LocaleFormatter from '../../../Common/LocaleFormatter';
import Chip from '@material-ui/core/Chip';
import * as CONST from '../../../Common/constants';
import CheckIcon from '@material-ui/icons/Check';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import Button from '@material-ui/core/Button';
import TestMenuActions from './TestMenuActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ModalChangeEvent from '../../../Pipeline/ModalChangeEvent';
import CancelIcon from '@material-ui/icons/Cancel';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import TimerIcon from '@material-ui/icons/Timer';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DialogAnswers from './DialogAnswers';
import CardController from '../../CardController';
import MatchElement from '../Common/MatchElement';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Divider from '@material-ui/core/Divider';

const baseFilters = CONST.getApplicantDetailBaseFields();

const messages = defineMessages({});

const useStyles = (theme) => ({
  list: { padding: 0 },
  mainText: {
    fontSize: '12px',
    color: theme.palette.gray.darker,
    lineHeight: '22px'
  },
  dayChip: {
    fontSize: '10px',
    height: '18px'
  },
  mediumChup: {
    fontSize: '12px',
    borderRadius: '4px'
  },
  todayChip: {
    background: theme.palette.info.light,
    color: theme.palette.info.main
  },
  greenChip: {
    color: theme.palette.success.main,
    background: `${theme.palette.success.main}10`,
    '& .MuiChip-icon': {
      color: theme.palette.success.main
    }
  },
  redChip: {
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    '& .MuiChip-icon': {
      color: theme.palette.error.dark
    }
  },
  yellowChip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    '& .MuiChip-icon': {
      color: theme.palette.warning.main
    }
  },
  iconActionContainer: {
    padding: theme.spacing(1)
  },
  avatarGroup: {
    '& .MuiAvatar-root': {
      width: '30px',
      height: '30px',
      fontSize: '14px'
    }
  },
  avatarText: {
    fontSize: '14px',
    color: theme.palette.common.white
  },
  fixedLeftColumn: {
    background: theme.palette.common.white,
    alignSelf: 'stretch',
    position: 'sticky',
    left: 0,
    zIndex: 301
  },
  fixedLeftColumnContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1.5),
    height: '100%'
  },
  fixedRightColumn: {
    background: theme.palette.common.white,
    alignSelf: 'stretch',
    position: 'sticky',
    right: 0,
    zIndex: 301
  },
  listItemGutters: {
    padding: 0
  },
  mainListContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 0',
    width: '100%'
  },
  itemWithBorder: {
    borderBottom: `1px solid ${theme.palette.gray.darkerLight}`
  },
  notAnswered: {
    color: theme.palette.gray.darkerRegular
  },
  notAnsweredScore: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '78px',
    height: '78px',
    backgroundColor: theme.palette.gray.lighter,
    padding: `${theme.spacing(1)}px`,
    borderRadius: '1rem'
  },
  notAnsweredIcon: {
    color: theme.palette.gray.darkerRegular
  },
  printChip: {
    '@media print': {
      color: theme.palette.gray.darkerMedium,
      backgroundColor: `${theme.palette.gray.darkerMedium}10`,
      borderColor: theme.palette.gray.darkerMedium,

      '& .MuiChip-icon': {
        color: theme.palette.gray.darkerMedium
      }
    }
  },
  commonChip: {
    color: theme.palette.gray.darkerMedium
  },
  chip: {
    color: theme.palette.gray.darkerMedium,
    backgroundColor: `${theme.palette.gray.darkerMedium}10`,
    borderColor: theme.palette.gray.darkerMedium
  }
});

class ListCustomTests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tests: props.tests ? props.tests : [],
      pipeline_stages: props.pipeline_stages,
      applicant: props.applicant,
      accountUsers: props.accountUsers ? props.accountUsers : [],
      readonly: props.readonly,
      print: props.print,
      // Internal State],
      rightColumns: ['actions'],
      // Menu
      anchorEl: null,
      modalChange: false,
      modalCancel: false,
      selectedInterviewId: null,
      selectedInterviewJobId: null,
      modalViewAnswers: false,
      selectedTest: undefined
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tests !== this.props.tests) {
      this.setState({
        tests: this.props.tests ? this.props.tests : []
      });
    }

    if (prevProps.pipeline_stages !== this.props.pipeline_stages) {
      this.setState({
        pipeline_stages: this.props.pipeline_stages ? this.props.pipeline_stages : []
      });
    }

    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }

    if (prevProps.accountUsers !== this.props.accountUsers) {
      this.setState({
        accountUsers: this.props.accountUsers
      });
    }
  }

  componentDidMount() {
    //console.log("mounted " + this.state.applicant_id);
  }

  handleOpenMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleModalClose = () => {
    this.setState({
      modalChange: false
    });
  };

  handleModalUpdate = (tests) => {
    this.setState(
      {
        modalChange: false
      },
      () => {
        if (tests && tests.length > 0) {
          this.setState({
            test: tests[0]
          });
        }
      }
    );
  };

  onChangeEvent = (e, test) => {
    e.preventDefault();
    this.setState({
      selectedInterviewId: test.id,
      selectedInterviewJobId: test.job_id,
      modalChange: true,
      anchorEl: null
    });
  };

  handleUpdateTest = (currTest) => {
    const tests = this.state.tests;

    const updatedInterview = Array.isArray(currTest) ? currTest[0] : currTest;

    const updatedInterviews = tests.map((test) => {
      if (test.id === updatedInterview.id) {
        return {
          ...test,
          ...updatedInterview
        };
      } else {
        return test;
      }
    });

    this.setState({
      tests: updatedInterviews
    });
  };

  handleViewAnswers = (test) => {
    this.setState({
      selectedTest: test,
      modalViewAnswers: true
    });
  };

  handleCloseViewAnswers = () => {
    this.setState({ modalViewAnswers: false, selectedTest: undefined });
  };

  render() {
    const { classes } = this.props;
    const applicant = this.state.applicant;

    const print = this.state.print;

    const phone = applicant.phone_mobile;
    let whatsapp;
    if (phone) {
      whatsapp = phone.replace(/\D/g, ''); // https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/?lang=kk
    }

    const testScore = (test) => {
      const score = CardController.getCustomTestScore(test.test_score);
      const scorePerc = CardController.getCustomTestScorePerc(test.test_score);

      if (score > -1) {
        return (
          <MatchElement
            primaryText={score}
            label={<FormattedMessage id="ReviewTab.label_score" />}
          />
        );
      } else {
        return (
          <Box className={classes.notAnsweredScore}>
            <InfoOutlinedIcon className={classes.notAnsweredIcon} />
          </Box>
        );
      }
    };

    const testDetails = (test) => {
      let icon,
        time,
        to,
        isAnswered = false,
        answeredDate;
      const from = new Date();

      // Completed succesfully -> Display time to complete the form
      if (test.completed) {
        to = new Date(from.getTime() + parseInt(test.answers_ellapsed));
        time = Moment(from).from(to, true);
        icon = <TimerIcon fontSize="small" className={classes.commonChip} />;
        isAnswered = !!test.date_answers;
        const parsedAnsweredDate = isAnswered
          ? Moment(test.date_answers).format('ll')
          : 'invalid date';

        if (parsedAnsweredDate.toLowerCase() === 'invalid date') {
          isAnswered = false;
        } else {
          answeredDate = parsedAnsweredDate;
        }
      } else if (new Date() > new Date(test.date_limit)) {
        // Expired -> Display time is has expired
        to = new Date(test.date_limit);
        time = Moment(from).from(to, true);
        icon = <TimerOffIcon fontSize="small" className={classes.commonChip} />;
      } else {
        // Incomplete -> Display time since expiration
        to = new Date(test.date_limit);
        time = Moment(from).to(to, false);
        icon = <AvTimerIcon fontSize="small" className={classes.commonChip} />;
      }

      return (
        <Box>
          {isAnswered ? (
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <CheckCircleOutlineIcon fontSize="small" className={classes.commonChip} />
              </Box>
              <Typography className={classes.mainText}>
                <LocaleFormatter dateToFormat={answeredDate} />
              </Typography>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <HighlightOffIcon fontSize="small" className={classes.commonChip} />
              </Box>
              <Typography className={`${classes.mainText} ${classes.notAnswered}`}>
                {' '}
                <FormattedMessage
                  id="ListCustomTests.label_noAnswersDate"
                  defaultMessage="Waiting answers"
                />
              </Typography>
            </Box>
          )}

          <Box display="flex" alignItems="center">
            <Box mr={1}>{icon}</Box>
            <Typography className={classes.mainText}>{time}</Typography>
          </Box>
        </Box>
      );
    };

    const statusChip = (test) => {
      let title = undefined;
      let icon = undefined;
      let chipClass = undefined;
      if (test.completed === true) {
        icon = <CheckIcon />;
        chipClass = classes.greenChip;
        title = <FormattedMessage id="ListCustomTests.label_answered" defaultMessage="Completed" />;
      } else if (new Date() > new Date(test.date_limit)) {
        icon = <EventBusyIcon />;
        chipClass = classes.redChip;
        title = <FormattedMessage id="ListCustomTets.label_expired" defaultMessage="Expired" />;
      } else {
        icon = <HelpOutlineIcon />;
        chipClass = classes.yellowChip;
        title = (
          <FormattedMessage
            id="ListCustomTests.label_noAnswers"
            defaultMessage="No answer from yet"
          />
        );
      }

      return (
        <Chip
          className={`${chipClass} ${classes.printChip}`}
          icon={icon}
          label={title}
          size="small"
        />
      );
    };

    const stageChip = (test) => {
      const pipeline_stages = this.state.pipeline_stages;
      if (test.pipeline_stage !== null && pipeline_stages && pipeline_stages.length > 0) {
        let stageNameDisplay = '';
        const jobStageStatus = pipeline_stages.find(
          (stageStatus) => stageStatus.id === test.pipeline_stage
        );

        if (jobStageStatus) {
          stageNameDisplay = jobStageStatus.name;
        }

        return <Chip label={stageNameDisplay} className={print ? classes.chip : ''} size="small" />;
      } else {
        return null;
      }
    };

    const actionsContainer = (test) => {
      const pipeline_stages = this.state.pipeline_stages;

      return (
        <Box display="flex" alignItems="center" width="180px">
          <Box mr={1}>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              disabled={!test.completed}
              onClick={() => this.handleViewAnswers(test)}
            >
              <FormattedMessage
                id="ListCustomTests.button_viewAnswers"
                defaultMessage="View Answers"
              />
            </Button>
          </Box>
          {!this.state.readonly && !print ? (
            <Box display="flex" flexDirection="column">
              <Box
                style={{
                  whiteSpace: 'pre-wrap'
                }}
              >
                <TestMenuActions
                  test={test}
                  applicant={applicant}
                  thisStage={test.pipeline_stage}
                  pipeline={pipeline_stages}
                  handleUpdateTest={this.handleUpdateTest}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
      );
    };

    const tests = this.state.tests;

    if (tests.length > 0) {
      const mostRecentTests = tests.slice(0, 3);
      return (
        <>
          <List className={classes.list}>
            {mostRecentTests.map((test, index) => {
              return (
                <>
                  <ListItem
                    key={index}
                    classes={{
                      gutters: classes.listItemGutters
                    }}
                  >
                    <Box
                      className={`${classes.mainListContainer} ${
                        index === mostRecentTests.length - 1 ? '' : classes.itemWithBorder
                      }`}
                    >
                      <Box width="120px" className={classes.fixedLeftColumn}>
                        <Box className={classes.fixedLeftColumnContent}>{testScore(test)}</Box>
                      </Box>

                      <Box display="flex" alignItems="center" width="100%">
                        <Box width="160px">
                          <Typography className={classes.mainText}>
                            {test.customTest.name}
                          </Typography>
                        </Box>

                        <Box width="160px">{statusChip(test)}</Box>
                        <Box width="200px">{testDetails(test)}</Box>
                        <Box width="160px">{stageChip(test)}</Box>
                      </Box>
                      {print ? null : (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          width="180px"
                          pl={1.5}
                          className={classes.fixedRightColumn}
                        >
                          {actionsContainer(test)}
                        </Box>
                      )}
                    </Box>
                  </ListItem>
                </>
              );
            })}
          </List>
          <ModalChangeEvent
            open={this.state.modalChange}
            selected_id={this.state.selectedInterviewId}
            submiting={false}
            handlerClose={this.handleModalClose}
            handlerUpdate={this.handleUpdateTest}
            job_id={this.state.selectedInterviewJobId}
            selected_objs={[this.state.applicant]}
          />
          <DialogAnswers
            test={this.state.selectedTest}
            open={this.state.modalViewAnswers}
            handleClose={this.handleCloseViewAnswers}
          />
        </>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(ListCustomTests));
