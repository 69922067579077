import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import * as CONST from '../Common/constants';
import UserProfile from '../Common/UserProfile';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Api from '../Api/Api';
import Avatar from 'react-avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Moment from 'moment';
import MomentTZ from 'moment-timezone';
import Timezones from '../Common/timezones';
import Select, { Creatable } from 'react-select';
import { connect } from 'react-redux';
import {
  DateRangePicker,
  MobileTimePicker,
  MobileDateTimePicker,
  DateRangeDelimiter
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import 'moment/locale/pt-br'; // FIXME locale
import AddressController from '../Common/AddressController';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import ModalAlertBlock from './ModalAlertBlock';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SelectUsersMultipleCustom from '../SelectUsersMultipleCustom/SelectUsersMultipleCustom';

const messages = defineMessages({
  noUserSync: {
    id: 'ModalEventOptions.noUserSync',
    defaultMessage: 'Calendar Sync'
  },
  noUserSyncMsg: {
    id: 'ModalEventOptions.noUserSyncMsg',
    defaultMessage:
      'At least one selected interviewer must sync its calendar in order to use our Automatic Scheduler.'
  },
  interview_newAddress: {
    id: 'ModalEventOptions.interview_newAddress',
    defaultMessage: 'Create address:'
  },
  err_NO_AVAILABLE_PERIODS: {
    id: 'ModalEventOptions.err_NO_AVAILABLE_PERIODS',
    defaultMessage:
      "We couldn't find available periods with the provided time window, please:\n\n 1. Re-check the avaliabilty of all selected participants\n\n 2. Check if your calendar is synchornized (Settings -> User Profile -> Calencar Sync)"
  },
  interview_warningWithoutLink: {
    id: 'ModalEventOptions.interview_warningWithoutLink',
    defaultMessage:
      'You are about to create a schedule without providing a means of communication. Are you sure you want to proceed?'
  },
  write_phoneNumber: {
    id: 'ModalEventOptions.write_phoneNumber',
    defaultMessage: 'Phone number'
  }
});

const MODE_AUTO = 0;
const MODE_MANUAL = 1;
const MODE_NO = 2;
const MEDIUM_PRESENTIAL = 0;
const MEDIUM_DIGITAL = 1;
const MEDIUM_PHONE = 2;

function getDaysArray(start, end) {
  for (var arr = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
    //console.log(new Date(dt).getDay());
    if (new Date(dt).getDay() != 6 && new Date(dt).getDay() != 0) {
      // Excluding Saturdays and Sundays
      arr.push(new Date(dt));
    }
  }
  return arr;
}

// MUST BE OUTSIDE OF COMPONENT
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '17vw',
    width: '17vw'
  },
  editor: {
    minHeight: 200,
    maxHeight: 300,
    overflow: 'auto'
  },
  iconError: {
    color: `${theme.palette.error.dark} !important`
  },
  iconInfo: {
    color: `${theme.palette.gray.darker} !important`
  },
  iconWarning: {
    color: `${theme.palette.warning.main} !important`
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: '100%' }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} alignItems="center" width="100%">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

class ModalEventOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      nextStageObj: props.nextStageObj,
      job_id: props.job_id,
      selected_ids: props.selected_ids,
      selected_objs: props.selected_objs,
      submiting: props.submiting,
      // Internal state
      user_calendar_sync: true,
      accountUsersList: [],
      timezone: MomentTZ.tz.guess(),
      timezoneList: Timezones,
      addressList: [],
      atendees: [UserProfile.getId()],
      atendees_warning: false,
      manual_dateTime: Moment().add(1, 'days').set({ minute: 0, second: 0, millisecond: 0 }),
      auto_dateWindow: [Moment().add(1, 'days'), Moment().add(7, 'days')],
      auto_timeStart: Moment().set({
        hour: 8,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      auto_timeEnd: Moment().set({
        hour: 18,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      auto_hasTimeBreak: true,
      auto_timeBreakStart: Moment().set({
        hour: 12,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      auto_timeBreakEnd: Moment().set({
        hour: 14,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      duration: 60,
      durationList: [
        { value: 30, label: '30min' },
        { value: 60, label: '1h' },
        { value: 90, label: '1h30' },
        { value: 120, label: '2h' },
        { value: 180, label: '3h' },
        { value: 240, label: '4h' },
        { value: 360, label: '6h' },
        { value: 480, label: '8h' }
      ],
      scheduleMode: MODE_AUTO,
      mediumType: '',
      address: '',
      digital_url: '',
      phone_in: '',
      isPhoneMaskCorrect: true,
      phoneMaskFormat: '',
      countries_name: [],
      country_label: '',
      hasInstructions: false,
      instructions: '',
      isBlocked: false,
      modalOpen: false,
      hasHuntingApplicants: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleInterviewTimeStart = this.handleInterviewTimeStart.bind(this);
    this.handleInterviewTimeEnd = this.handleInterviewTimeEnd.bind(this);
    this.handleInterviewAddressChange = this.handleInterviewAddressChange.bind(this);
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
    this.handleChangeSelectInterviewDuration = this.handleChangeSelectInterviewDuration.bind(this);
    this.handleInterviewManualDateTime = this.handleInterviewManualDateTime.bind(this);
    this.handleDateWindowChange = this.handleDateWindowChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(
        {
          open: this.props.open
        },
        () => {
          this.warningApplicantBlocked();
        }
      );
    }
    if (prevProps.nextStageObj !== this.props.nextStageObj) {
      this.setState({
        nextStageObj: this.props.nextStageObj
      });
    }
    if (prevProps.selected_ids !== this.props.selected_ids) {
      this.setState({
        selected_ids: this.props.selected_ids
      });
    }
    if (prevProps.selected_objs !== this.props.selected_objs) {
      this.setState({
        selected_objs: this.props.selected_objs
      });
    }
    if (prevProps.submiting !== this.props.submiting) {
      this.setState({
        submiting: this.props.submiting
      });
    }
    if (prevProps.job_id !== this.props.job_id) {
      this.setState({
        job_id: this.props.job_id
      });
    }
  }

  warningApplicantBlocked = () => {
    const isAnyBlocked = this.state.selected_objs.some((applicant) => {
      const { emailBlocklists, docBlocklists } = applicant;
      const activeBlockValidation =
        (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
        (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0);
      return activeBlockValidation === true;
    });

    this.setState({
      isBlocked: isAnyBlocked
    });
  };

  loadApplicants() {
    const selected_objs = this.state.selected_objs;

    if (!selected_objs || selected_objs.length === 0) {
      let api = new Api();
      let filter = {
        fields: ['id', 'name', 'email', 'avatar_50_path'],
        where: {
          id: {
            inq: this.state.selected_ids
          }
        },
        order: ['name']
      };
      api.get('Applicants', filter, (status, data) => {
        let hasHuntingApplicants =
          data.length > 0 && data.some((applicant) => applicant.email.startsWith('profile:'))
            ? true
            : false;

        this.setState({
          selected_objs: data,
          hasHuntingApplicants: hasHuntingApplicants
        });
      });
    } else {
      let hasHuntingApplicants =
        this.state.selected_objs.length > 0 &&
        this.state.selected_objs.some((applicant) => applicant.email.startsWith('profile:'))
          ? true
          : false;

      this.setState({
        hasHuntingApplicants: hasHuntingApplicants
      });
    }
  }

  getAccountUsers() {
    let userList = [];
    let filter = {
      where: {
        realm: UserProfile.getRealm(),
        suspended: false,
        internal_user: false
      },
      include: {
        relation: 'account',
        scope: {
          fields: ['address', 'unique_name', 'abbreviation']
        }
      },
      order: ['username']
    };
    let api = new Api();
    api.get('AccountUsers', filter, (status, data) => {
      let userCalendarSync = false;

      data?.map((user) => {
        if (user.cronofy_token_info !== null && user.cronofy_calendar_id !== null) {
          if (user.id === UserProfile.getId()) {
            userCalendarSync = true;
          }
        }
      });

      this.setState({
        accountUsersList: data,
        user_calendar_sync: userCalendarSync
      });
    });
  }

  getInterviewAddress() {
    let api = new Api();

    let params = {
      job_id: this.state.job_id
    };
    let addressList = [];

    api.getParam('Jobs/addresses-info', params, (status, data) => {
      if (status <= 400) {
        if (data[0].job_address) {
          addressList.push(data[0].job_address);
        }
        if (data[0].companyarea_address) {
          addressList.push(data[0].companyarea_address);
        }
        if (data[0].account_address) {
          addressList.push(data[0].account_address);
        }

        let arr = [];
        if (addressList.length > 0) {
          addressList.forEach((addr) => {
            let value = AddressController.getAddrUrl(addr);
            arr.push({
              value: value,
              label: value,
              obj: addr
            });
          });
        }

        this.setState(
          {
            addressList: arr
          },
          () => {
            if (arr.length > 0) {
              this.setState({
                address: arr[0]
              });
            }
          }
        );
      } else {
        console.log('Error on fetching data');
      }
    });
  }

  handleOpen = () => {
    this.getAccountUsers();
    this.getInterviewAddress();
    this.loadApplicants();
  };

  handleClose = () => {
    this.setState(
      {
        open: false,
        isBlocked: false,
        modalOpen: false
      },
      () => {
        if (this.props.handlerClose) {
          this.props.handlerClose();
        }
      }
    );
  };

  handleModalOpen = () => {
    this.setState({
      open: false,
      modalOpen: true
    });
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false
    });
  };

  handleUpdate = (options) => {
    if (this.props.handlerPost) {
      this.props.handlerPost({
        ids: this.state.selected_ids,
        options: options
      });
    }
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleChangePhoneNumber = (event) => {
    const value = event.target.value;
    const expectedLength = this.state.phoneMaskFormat.replace(/\D/g, '').length;
    if (expectedLength === 0) {
      this.setState({
        isPhoneMaskCorrect: true,
        phone_in: value
      });
    } else if (value.replace(/\D/g, '').length === expectedLength) {
      this.setState({
        isPhoneMaskCorrect: true,
        phone_in: value
      });
    } else {
      this.setState({
        isPhoneMaskCorrect: false,
        phone_in: value
      });
    }
  };

  displayPhoneFormat = (selected) => {
    let containerId = 'phone_format';
    if (selected && selected.mobile_mask && selected.mobile_mask !== '') {
      const phoneMask = selected.mobile_mask;
      AddressController.getMaskedID(containerId, phoneMask);
      this.setState({ phoneMaskFormat: phoneMask });
    } else {
      AddressController.unMaskedID(containerId);
      this.setState({ phoneMaskFormat: '' });
    }
  };

  handleChangeSelectCountry = (event, selected) => {
    // console.log(selected)
    if (selected) {
      const selectedCountry = this.state.countries_name.find(
        (country) => country.value === selected.value
      );

      this.setState(
        {
          country: selected.value,
          country_label: selectedCountry ? selectedCountry.label : '',
          phone_in: '',
          phoneMaskFormat: selected.mobile_mask
        },
        () => {
          this.displayPhoneFormat(selected);
        }
      );
    } else {
      this.setState({
        country: null,
        country_label: null,
        phone_in: '',
        phoneMaskFormat: ''
      });
    }
  };

  getCountries = () => {
    const userCountryDefault = this.props.accountInfo.accountInfo.country;
    let api = new Api();
    let filter = {
      order: ['name ASC']
    };
    api.get('Countries', filter, (status, data) => {
      // console.log(data)
      if (data.length > 0) {
        const list = data.map((country) => ({
          value: country.code,
          label: country.name,
          mobile_mask: country.mobile_mask,
          mobile_code: country.mobile_code
        }));
        const selectedCountry = list.find((country) => country.value === userCountryDefault);
        // console.log(selectedCountry)
        this.setState(
          {
            countries_name: list,
            country: userCountryDefault,
            country_label: list.find((country) => country.value === userCountryDefault)?.label || ''
          },
          () => {
            this.displayPhoneFormat(selectedCountry);
          }
        );
      }
    });
  };

  handleChangeInstructions = (editorState) => {
    this.setState({
      instructions: editorState
    });
  };

  handleChangeSelectInterviewUser = (selected) => {
    const int_users = [];
    selected.forEach((option) => {
      int_users.push(option.id);
    });

    this.setState({ atendees: int_users });
  };

  handleChangeSelectInterviewDuration = (selected) => {
    let value = '';
    if (selected) {
      value = selected.value;
    }
    this.setState({ duration: value });
  };

  handleInterviewManualDateTime(date) {
    this.setState({
      manual_dateTime: date
    });
  }

  setInterviewType = (e, value) => {
    this.setState({
      scheduleMode: value
    });
  };

  setInterviewMediumType = (e, value) => {
    this.setState(
      {
        mediumType: value
      },
      () => {
        if (value === 2) {
          this.getCountries();
        }
      }
    );
  };

  handleTimezoneChange = (timezone) => {
    let value = '';
    if (timezone) {
      value = timezone.value;
    }
    this.setState({ timezone: value });
  };

  handleInterviewAddressChange = (address) => {
    //console.log(address);
    this.setState({
      address: address
    });
  };

  handleInterviewTimeStart(date) {
    this.setState({
      auto_timeStart: date
    });
  }

  handleInterviewTimeEnd(date) {
    this.setState({
      auto_timeEnd: date
    });
  }

  handleDateWindowChange(dates) {
    //console.log(dates);
    this.setState({
      auto_dateWindow: dates
    });
  }

  onSubmit = (e) => {
    e.preventDefault();

    const selectedUsers = this.state.atendees;
    const accountUsersList = this.state.accountUsersList;

    let medium;
    let addressString = null;
    let addressObj = null;
    let digital_url = null;
    let phone_in = null;
    let hasSyncCalendar = false;
    let instructions =
      this.state.hasInstructions && this.state.instructions !== ''
        ? this.state.instructions
        : undefined;

    switch (this.state.mediumType) {
      case MEDIUM_PRESENTIAL:
        medium = 'PRESENTIAL';

        const address = this.state.address;
        if (address.obj) {
          addressString = AddressController.getAddrUrl(address.obj);
          this.state.addressList.some((addr) => {
            if (addr.value === addressString) {
              addressObj = addr.obj;
              return true;
            }
          });
        } else {
          addressObj = {
            street: address.value,
            number: '',
            comp: '',
            city: '',
            state: '',
            zip: ''
          };
        }
        break;
      case MEDIUM_DIGITAL:
        medium = 'DIGITAL';
        if (this.state.digital_url || this.state.digital_url != '') {
          digital_url = this.state.digital_url;
        }
        break;
      case MEDIUM_PHONE:
        medium = 'PHONE';
        if (this.state.phone_in || this.state.phone_in != '') {
          const selectedCountry = this.state.countries_name.find(
            (country) => country.value === this.state.country
          );

          if (selectedCountry.mobile_code != '') {
            phone_in = `+${selectedCountry.mobile_code} ${this.state.phone_in}`;
          } else {
            phone_in = this.state.phone_in;
          }
        }
        break;
    }

    let options;
    let execute_actions = false;

    switch (this.state.scheduleMode) {
      case MODE_MANUAL:
        execute_actions = true;

        options = {
          mode: 'MANUAL',
          interviewers_user_id: this.state.atendees,
          datetime_interview: Moment(this.state.manual_dateTime).toDate(),
          duration: this.state.duration,
          timezone: this.state.timezone,
          medium: medium,
          address: addressObj,
          digital_url: digital_url,
          phone_in: phone_in,
          instructions: instructions
        };

        break;
      case MODE_AUTO:
        execute_actions = true;

        let subArr = [];
        let targetArr = [];

        //console.log(accountUsersList);
        selectedUsers.forEach((selected) => {
          accountUsersList.some((accountUser) => {
            if (selected === accountUser.id) {
              if (accountUser.cronofy_token_info && accountUser.cronofy_token_info.sub) {
                hasSyncCalendar = true;

                subArr.push({
                  sub: accountUser.cronofy_token_info.sub
                });
                if (accountUser.cronofy_calendar_id) {
                  targetArr.push({
                    sub: accountUser.cronofy_token_info.sub,
                    calendar_id: accountUser.cronofy_calendar_id
                  });
                }
                return true;
              }
            }
          });
        });

        const startDay = Moment(this.state.auto_dateWindow[0]);
        startDay.set('hour', 0);
        startDay.set('minute', 0);
        startDay.set('second', 0);
        startDay.set('millisecond', 0);

        const endDay = Moment(this.state.auto_dateWindow[1]);
        endDay.set('hour', 0);
        endDay.set('minute', 0);
        endDay.set('second', 0);
        endDay.set('millisecond', 0);

        const startTimeHour = Moment(this.state.auto_timeStart).get('hour');
        const startTimeMinute = Moment(this.state.auto_timeStart).get('minute');
        const endTimeHour = Moment(this.state.auto_timeEnd).get('hour');
        const endTimeMinute = Moment(this.state.auto_timeEnd).get('minute');

        let startBreakHour = null;
        let startBreakMinute = null;
        let endBreakHour = null;
        let endBreakMinute = null;
        if (this.state.auto_hasTimeBreak === true) {
          startBreakHour = Moment(this.state.auto_timeBreakStart).get('hour');
          startBreakMinute = Moment(this.state.auto_timeBreakStart).get('minute');
          endBreakHour = Moment(this.state.auto_timeBreakEnd).get('hour');
          endBreakMinute = Moment(this.state.auto_timeBreakEnd).get('minute');
        }

        let daysArr = getDaysArray(startDay.toDate(), endDay.toDate());
        // console.log(daysArr);

        let periodsArr = [];
        daysArr.forEach((day) => {
          let start = Moment(day).set('hour', startTimeHour);
          start.set('minute', startTimeMinute);
          start.set('second', 0);
          start.set('millisecond', 0);
          let end = Moment(day).set('hour', endTimeHour);
          end.set('minute', endTimeMinute);
          end.set('second', 0);
          end.set('millisecond', 0);

          if (this.state.auto_hasTimeBreak === true) {
            let breakStart = Moment(day).set('hour', startBreakHour);
            breakStart.set('minute', startBreakMinute);
            breakStart.set('second', 0);
            breakStart.set('millisecond', 0);
            let breakEnd = Moment(day).set('hour', endBreakHour);
            breakEnd.set('minute', endBreakMinute);
            breakEnd.set('second', 0);
            breakEnd.set('millisecond', 0);
            if (end.toDate() <= breakStart.toDate() || start.toDate() >= breakEnd.toDate()) {
              periodsArr.push({
                start: start.toISOString(),
                end: end.toISOString()
              });
            } else if (start.toDate() < breakStart.toDate() && end.toDate() > breakEnd.toDate()) {
              periodsArr.push({
                start: start.toISOString(),
                end: breakStart.toISOString()
              });
              periodsArr.push({
                start: breakEnd.toISOString(),
                end: end.toISOString()
              });
            } else if (start.toDate() < breakStart.toDate()) {
              periodsArr.push({
                start: start.toISOString(),
                end: breakStart.toISOString()
              });
            } else if (end.toDate() > breakEnd.toDate()) {
              periodsArr.push({
                start: breakEnd.toISOString(),
                end: end.toISOString()
              });
            }
          } else {
            periodsArr.push({
              start: start.toISOString(),
              end: end.toISOString()
            });
          }
        });
        //console.log(periodsArr);

        let payloadAval = {
          options: {
            access_token: null, // API WILL FILL
            participants: [
              {
                members: subArr,
                required: 'all'
              }
            ],
            required_duration: {
              minutes: this.state.duration
            },
            available_periods: periodsArr
          }
        };
        //console.log(payloadAval);
        //console.log(addressString);

        let payloadRTS = {
          options: {
            oauth: {
              redirect_uri: null, // API WILL FILL
              scope: 'create_event',
              state: {}
            },
            event: {
              event_id: null, // API WILL FILL
              summary: null, // API WILL FILL
              description: null, // API WILL FILL
              url: null, // API WILL FILL
              tzid: this.state.timezone,
              location: {
                description: addressString
              },
              reminders: [{ minutes: 30 }, { minutes: 1440 }]
            },
            availability: {
              participants: [
                {
                  members: subArr,
                  required: 'all'
                }
              ],
              required_duration: {
                minutes: this.state.duration
              },
              start_interval: { minutes: 5 },
              available_periods: null // API WILL FILL
            },
            target_calendars: targetArr,
            callback_url: null // API WILL FILL
          }
        };

        options = {
          mode: 'AUTO',
          interviewers_user_id: this.state.atendees,
          schedule_options: payloadAval,
          cronofy_request: payloadRTS,
          //cronofy_url: rts.url,
          //cronofy_event: payloadRTS.options.event,
          duration: this.state.duration,
          timezone: this.state.timezone,
          medium: medium,
          address: addressObj,
          digital_url: digital_url,
          phone_in: phone_in,
          user_id: UserProfile.getId(),
          instructions: instructions
        };
        break;
      case MODE_NO:
        execute_actions = false;
        options = {
          mode: 'NO'
        };

        break;
    }

    if (this.state.scheduleMode === MODE_AUTO && !hasSyncCalendar) {
      window.swal({
        type: 'warning',
        title: this.props.intl.formatMessage({
          id: 'ModalEventOptions.noUserSync'
        }),
        text: this.props.intl.formatMessage({
          id: 'ModalEventOptions.noUserSyncMsg'
        }),
        closeOnConfirm: true
      });
    } else {
      //console.log(options);

      this.handleUpdate(options);
    }
    this.handleClose();
  };

  checkBeforeSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.scheduleMode !== MODE_NO &&
      ((this.state.mediumType === MEDIUM_DIGITAL && this.state.digital_url === '') ||
        (this.state.mediumType === MEDIUM_PHONE && this.state.phone_in === ''))
    ) {
      window
        .swal({
          type: 'info',
          title: '',
          text: this.props.intl.formatMessage({
            id: 'ModalEventOptions.interview_warningWithoutLink'
          }),
          closeOnConfirm: true,
          buttons: {
            cancel: {
              text: this.props.intl.formatMessage({
                id: 'Base.button.cancel'
              }),
              value: null,
              visible: true,
              className: '',
              closeModal: true
            },
            confirm: {
              text: 'OK',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          }
        })
        .then((isConfirmed) => {
          if (isConfirmed) {
            this.onSubmit(e);
          } else {
            return null;
          }
        });
    } else {
      this.onSubmit(e);
    }
  };

  render() {
    const { classes } = this.props;

    const { countries_name, country_label } = this.state;

    const listApplicants = this.state.selected_objs.map((applicant, i) => {
      let fullPath;
      if (applicant.avatar_50_path) {
        fullPath = 'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + applicant.avatar_50_path;
      }

      return (
        <ListItem key={applicant.id}>
          <ListItemAvatar>
            <Avatar
              name={applicant.name}
              email={applicant.email}
              src={fullPath}
              maxInitials={2}
              color={CONST.APP_COLORS['primary-light']}
              size="30px"
              round={true}
            />
          </ListItemAvatar>
          <ListItemText id={applicant.id} primary={applicant.name} />
        </ListItem>
      );
    });

    const displayInterviewSyncWarning = () => {
      if (!this.state.user_calendar_sync) {
        return (
          <Row>
            <Col md={12}>
              <Alert
                severity="error"
                classes={{ icon: classes.iconError }}
                style={{
                  backgroundColor: CONST.APP_COLORS['danger-light'],
                  color: CONST.APP_COLORS['danger-dark']
                }}
                className="mb"
              >
                <small>
                  <FormattedMessage
                    id="ModalEventOptions.warningSyncCalendar"
                    defaultMessage="You have to sync your calendar in order to use our Automatic Scheduler. Go to 'Seetings' -> 'User Settings' -> 'Profile' -> 'Calendar Sync'."
                  />
                </small>
              </Alert>
            </Col>
          </Row>
        );
      } else {
        return null;
      }
    };

    const displayInterviewUserWarning = () => {
      if (this.state.atendees_warning) {
        return (
          <Row>
            <Col md={12}>
              <Alert
                severity="info"
                classes={{ icon: classes.iconInfo }}
                style={{
                  backgroundColor: CONST.APP_COLORS['gray-light'],
                  color: CONST.APP_COLORS['gray-darker']
                }}
                className="mb"
              >
                <small>
                  <FormattedMessage
                    id="ModalEventOptions.warningSomeUserNotSync"
                    defaultMessage="One of more selected users haven't yet connected their calendars."
                  />
                </small>
              </Alert>
            </Col>
          </Row>
        );
      } else {
        return null;
      }
    };

    const getMinDate = () => {
      if (this.state.auto_dateWindow[0]) {
        return Moment(this.state.auto_dateWindow[0]);
      } else {
        return Moment().add(1, 'days');
      }
    };

    const getMaxDate = () => {
      if (this.state.auto_dateWindow[0]) {
        return Moment(this.state.auto_dateWindow[0]).add(9, 'days');
      } else {
        return null;
      }
    };

    const getMinTime = () => {
      if (this.state.auto_timeStart) {
        return Moment(this.state.auto_timeStart).add(this.state.duration, 'minutes');
      } else {
        return Moment().set({
          hour: 5,
          minute: 0,
          second: 0,
          millisecond: 0
        });
      }
    };

    const stageName = () => {
      const stage = this.state.nextStageObj;
      let stageNameDisplay = stage.name.toLowerCase();
      if (this.props.intl.formatMessage({ id: 'Pipeline.' + stageNameDisplay }) !== undefined) {
        stageNameDisplay = this.props.intl.formatMessage({
          id: 'Pipeline.' + stageNameDisplay
        });
      }
      if (stageNameDisplay.startsWith('Pipeline.')) {
        stageNameDisplay = stage.name;
      }
      return stageNameDisplay;
    };

    return (
      <>
        {this.state.isBlocked && (
          <ModalAlertBlock
            open={this.state.open}
            handleModalOpen={this.handleModalOpen}
            handlerClose={this.handleClose}
          />
        )}
        <Dialog
          fullScreen
          open={this.state.isBlocked ? this.state.modalOpen : this.state.open}
          onEnter={this.handleOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          disableEnforceFocus={true} // https://github.com/mui-org/material-ui-pickers/issues/1852
        >
          <Backdrop open={this.state.submiting} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <form onSubmit={this.checkBeforeSubmit}>
            <AppBar color="default" position="relative">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleClose}
                  aria-label="close"
                  disabled={this.state.submiting}
                >
                  <CloseIcon />
                </IconButton>
                <Box flex={1}>
                  <h5 className="text-center text-uppercase">
                    <FormattedHTMLMessage
                      id="ModalEventOptions.title"
                      defaultMessage="Schedule Event for <b>{name}</b>"
                      values={{
                        name: stageName()
                      }}
                    />
                  </h5>
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    this.state.submiting ||
                    this.state.atendees.length === 0 ||
                    (this.state.scheduleMode === MODE_AUTO && !this.state.user_calendar_sync) ||
                    ((this.state.scheduleMode === MODE_AUTO ||
                      this.state.scheduleMode === MODE_MANUAL) &&
                      this.state.mediumType === '') ||
                    (this.state.mediumType === MEDIUM_PRESENTIAL &&
                      (!this.state.address || this.state.address?.value?.trim()?.length == 0)) ||
                    (this.state.mediumType === MEDIUM_PHONE &&
                      this.state.isPhoneMaskCorrect === false)
                  }
                  type="submit"
                >
                  <FormattedMessage id="ModalCustomTestOptions.buttonSend" />
                </Button>
              </Toolbar>
            </AppBar>
            <Row>
              <Col md={8}>
                <div className="panel panel-body">
                  <Row>
                    <Col md={12}>
                      <label htmlFor="atendees">
                        <FormattedMessage
                          id="ModalEventOptions.fieldUsers"
                          defaultMessage="Select participants"
                        />
                      </label>
                      <SelectUsersMultipleCustom
                        id="atendees"
                        required
                        value={this.state.atendees}
                        options={this.state.accountUsersList}
                        onChange={this.handleChangeSelectInterviewUser}
                        showUserCalendarSync
                      />
                    </Col>
                  </Row>
                  <hr />

                  <div className={classes.root}>
                    <Tabs
                      indicatorColor="primary"
                      orientation="vertical"
                      variant="scrollable"
                      value={this.state.scheduleMode}
                      onChange={(e, value) => this.setInterviewType(e, value)}
                      className={classes.tabs}
                    >
                      <Tab
                        label={
                          <FormattedMessage
                            id="ModalEventOptions.scheduleAutomatic"
                            defaultMessage="Automatic scheduling"
                          />
                        }
                        id={'mode-tab-0'}
                      />
                      <Tab
                        label={
                          <FormattedMessage
                            id="ModalEventOptions.scheduleManual"
                            defaultMessage="Manual schedule"
                          />
                        }
                        id={'mode-tab-1'}
                      />
                      <Tab
                        label={
                          <FormattedMessage
                            id="ModalEventOptions.scheduleNo"
                            defaultMessage="Don't schedule now"
                          />
                        }
                        id={'mode-tab-2'}
                      />
                    </Tabs>
                    <TabPanel value={this.state.scheduleMode} index={MODE_AUTO}>
                      {displayInterviewSyncWarning()}
                      {displayInterviewUserWarning()}
                      <Row>
                        <Col md={12}>
                          <label htmlFor="auto_dateWindow">
                            <FormattedMessage
                              id="ModalEventOptions.fieldDateWindow"
                              defaultMessage="Date window (max. 10 days)"
                            />
                          </label>
                          <DateRangePicker
                            name="auto_dateWindow"
                            startText=""
                            endText=""
                            disablePast={true}
                            ampm={false}
                            autoOk={false}
                            minDate={getMinDate()}
                            maxDate={getMaxDate()}
                            value={this.state.auto_dateWindow}
                            onChange={(dates) => this.handleDateWindowChange(dates)}
                            required={this.state.scheduleMode === MODE_AUTO}
                            disabled={this.state.scheduleMode !== MODE_AUTO}
                            renderInput={(startProps, endProps) => (
                              <>
                                <TextField
                                  {...startProps}
                                  margin="dense"
                                  size="small"
                                  variant="standard"
                                  helperText=""
                                />
                                <DateRangeDelimiter> &gt; </DateRangeDelimiter>
                                <TextField
                                  {...endProps}
                                  margin="dense"
                                  size="small"
                                  variant="standard"
                                  helperText=""
                                />
                              </>
                            )}
                          />
                          <p className="text-muted">
                            <small>
                              <FormattedMessage
                                id="ModalEventOptions.fieldDateWindowHelp"
                                defaultMessage="We'll skip saturdays and sundays"
                              />
                            </small>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <label htmlFor="auto_timeStart">
                            <FormattedMessage
                              id="ModalEventOptions.fieldTimeWindow"
                              defaultMessage="Time window"
                            />
                          </label>
                          <div>
                            <MobileTimePicker
                              name="auto_timeStart"
                              ampm={false}
                              value={this.state.auto_timeStart}
                              minutesStep={5}
                              minTime={Moment().set({
                                hour: 5,
                                minute: 0,
                                second: 0,
                                millisecond: 0
                              })}
                              maxTime={Moment().set({
                                hour: 23,
                                minute: 0,
                                second: 0,
                                millisecond: 0
                              })}
                              onChange={this.handleInterviewTimeStart}
                              required={this.state.scheduleMode === MODE_AUTO}
                              disabled={this.state.scheduleMode !== MODE_AUTO}
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  margin="dense"
                                  size="small"
                                  variant="standard"
                                  helperText=""
                                />
                              )}
                            />
                            <span className="m-lg">&gt;</span>
                            <MobileTimePicker
                              name="auto_timeEnd"
                              ampm={false}
                              value={this.state.auto_timeEnd}
                              minutesStep={5}
                              minTime={getMinTime()}
                              onChange={this.handleInterviewTimeEnd}
                              required={this.state.scheduleMode === MODE_AUTO}
                              disabled={this.state.scheduleMode !== MODE_AUTO}
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  margin="dense"
                                  size="small"
                                  variant="standard"
                                  helperText=""
                                />
                              )}
                            />
                          </div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="auto_hasTimeBreak"
                                color="primary"
                                checked={this.state.auto_hasTimeBreak}
                                onChange={(e) => this.handleChange(e)}
                              />
                            }
                            label={
                              <FormattedMessage
                                id="ModalEventOptions.opt_skipLunchHours"
                                defaultMessage="Skip lunch hours"
                              />
                            }
                          />
                        </Col>
                      </Row>
                    </TabPanel>
                    <TabPanel value={this.state.scheduleMode} index={MODE_MANUAL}>
                      <Row>
                        <Col md={12}>
                          <label htmlFor="manual_dateTime">
                            <FormattedMessage
                              id="ModalEventOptions.manualDate"
                              defaultMessage="Specific date/time"
                            />
                          </label>
                          <br />
                          <MobileDateTimePicker
                            name="manual_dateTime"
                            disablePast={true}
                            minutesStep={5}
                            ampm={false}
                            autoOk={true}
                            value={this.state.manual_dateTime}
                            onChange={this.handleInterviewManualDateTime}
                            required={!this.state.scheduleMode === MODE_MANUAL}
                            disabled={this.state.scheduleMode !== MODE_MANUAL}
                            renderInput={(props) => <TextField {...props} />}
                          />
                          <p className="text-muted">
                            <small>
                              <FormattedMessage
                                id="ModalEventOptions.manualDateHelp"
                                defaultMessage="All selected will candidates be assigned to this specific date/time at once. Use for group meetings or group sessions."
                              />
                            </small>
                          </p>
                        </Col>
                      </Row>
                    </TabPanel>
                    <TabPanel value={this.state.scheduleMode} index={MODE_NO}>
                      <Row>
                        <Col md={12}>
                          <p className="text-gray-darker">
                            <FormattedMessage
                              id="ModalEventOptions.scheduleNoHelp"
                              defaultMessage="Choosing this option will just change the candidate's stage. He/She will not receive any e-mail."
                            />
                          </p>
                        </Col>
                      </Row>
                    </TabPanel>
                  </div>

                  <hr />

                  {this.state.scheduleMode !== MODE_NO ? (
                    <>
                      <Row>
                        <Col md={3} className="text-center">
                          <Button
                            onClick={() =>
                              this.setState({ hasInstructions: !this.state.hasInstructions })
                            }
                          >
                            <FormattedMessage
                              id="ModalEventOptions.writeInstuctions"
                              defaultMessage="Write instructions"
                            />
                          </Button>
                        </Col>
                        <Col md={9}>
                          {!this.state.hasInstructions ? (
                            <Alert
                              severity="info"
                              classes={{ icon: classes.iconInfo }}
                              style={{
                                backgroundColor: CONST.APP_COLORS['gray-light'],
                                color: CONST.APP_COLORS['gray-darker']
                              }}
                            >
                              <FormattedMessage
                                id="ModalEventOptions.writeInstuctionsHelp"
                                defaultMessage="Write instructions, directons or any releveant information about the event here (optional)"
                              />
                            </Alert>
                          ) : null}
                          <Collapse in={this.state.hasInstructions}>
                            <TextField
                              id="instructions"
                              name="instructions"
                              value={this.state.instructions}
                              onChange={this.handleChange}
                              multiline
                              fullWidth
                              rows={6}
                              label={
                                <FormattedMessage
                                  id="ModalEventOptions.field_instructions"
                                  defaultMessage="Message"
                                />
                              }
                              placeholder={this.props.intl.formatMessage({
                                id: 'ModalEventOptions.writeInstuctionsHelp'
                              })}
                            />
                          </Collapse>
                        </Col>
                      </Row>

                      <hr />

                      <Row>
                        <Col md={3}>
                          <label htmlFor="duration">
                            <FormattedMessage
                              id="ModalEventOptions.fieldDuration"
                              defaultMessage="Duration"
                            />
                          </label>
                          <br />
                          <Select
                            name="duration"
                            required={true}
                            clearable={false}
                            value={this.state.duration}
                            options={this.state.durationList}
                            onChange={this.handleChangeSelectInterviewDuration}
                            menuContainerStyle={{ zIndex: 999 }}
                          />
                        </Col>
                        <Col md={9}>
                          <label htmlFor="timezone">
                            <FormattedMessage
                              id="ModalEventOptions.fieldTimezone"
                              defaultMessage="Event timezone"
                            />
                          </label>
                          <br />
                          <Select
                            name="timezone"
                            required={true}
                            clearable={false}
                            value={this.state.timezone}
                            options={this.state.timezoneList}
                            onChange={this.handleTimezoneChange}
                            menuContainerStyle={{ zIndex: 999 }}
                          />
                          <p className="text-muted">
                            <small>
                              <FormattedMessage
                                id="ModalEventOptions.fieldTimezoneHelp"
                                defaultMessage="Change this if the event will occur on a different timezone than yours"
                              />
                            </small>
                          </p>
                        </Col>
                      </Row>
                      <hr />
                      <div className={classes.root}>
                        <Tabs
                          indicatorColor="primary"
                          orientation="vertical"
                          variant="scrollable"
                          value={this.state.mediumType}
                          onChange={(e, value) => this.setInterviewMediumType(e, value)}
                          className={classes.tabs}
                        >
                          <Tab
                            label={
                              <FormattedMessage
                                id="ModalEventOptions.mediumPresential"
                                defaultMessage="Presential"
                              />
                            }
                            id={'medium-tab-0'}
                          />
                          <Tab
                            label={
                              <FormattedMessage
                                id="ModalEventOptions.mediumDigital"
                                defaultMessage="Video call"
                              />
                            }
                            id={'medium-tab-1'}
                          />
                          <Tab
                            label={
                              <FormattedMessage
                                id="ModalEventOptions.mediumPhone"
                                defaultMessage="Phone call"
                              />
                            }
                            id={'medium-tab-3'}
                          />
                        </Tabs>
                        <TabPanel value={this.state.mediumType} index={MEDIUM_PRESENTIAL}>
                          <Row>
                            <Col md={12}>
                              <label htmlFor="address">
                                <i className="icon-directions mr"></i>
                                <FormattedMessage
                                  id="ModalEventOptions.fieldAddress"
                                  defaultMessage="Event location"
                                />
                              </label>
                              <br />
                              <Creatable
                                name="address"
                                value={this.state.address}
                                options={this.state.addressList}
                                onChange={this.handleInterviewAddressChange}
                                menuContainerStyle={{ zIndex: 999 }}
                                promptTextCreator={(label) =>
                                  this.props.intl.formatMessage(messages.interview_newAddress) +
                                  ' ' +
                                  label
                                }
                                shouldKeyDownEventCreateNewOption={(event) => {
                                  if (event.keyCode === 13) {
                                    return true;
                                  }
                                }}
                                required={this.state.mediumType === MEDIUM_PRESENTIAL}
                                disabled={this.state.mediumType !== MEDIUM_PRESENTIAL}
                              />
                              <p className="text-muted">
                                <small>
                                  <FormattedMessage
                                    id="ModalEventOptions.fieldAddressHelp"
                                    defaultMessage="Choose one of your past addresses or clear it (click 'X') and start typing to create a new address for this interview"
                                  />
                                </small>
                              </p>
                            </Col>
                          </Row>
                        </TabPanel>
                        <TabPanel value={this.state.mediumType} index={MEDIUM_DIGITAL}>
                          <Row>
                            <Col md={12}>
                              <label htmlFor="digital_url">
                                <i className="fas fa-video mr"></i>
                                <FormattedMessage
                                  id="ModalEventOptions.fieldDigitalUrl"
                                  defaultMessage="Link to audio/video call"
                                />
                              </label>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="icon-link"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <input
                                  id="digital_url"
                                  name="digital_url"
                                  type="text"
                                  className="form-control"
                                  value={this.state.digital_url}
                                  onChange={this.handleChange}
                                  onBlur={this.handleChange}
                                  disabled={this.state.mediumType !== MEDIUM_DIGITAL}
                                />
                              </InputGroup>
                              <p className="text-muted">
                                <small>
                                  <FormattedMessage
                                    id="ModalEventOptions.digitalUrlHelp"
                                    defaultMessage="(Optional) Provide a link to the audio or video room call if you have it."
                                  />
                                </small>
                              </p>
                            </Col>
                          </Row>
                        </TabPanel>
                        <TabPanel value={this.state.mediumType} index={MEDIUM_PHONE}>
                          <Row>
                            <Col md={12}>
                              <div className="form-group">
                                <label htmlFor="phone_in">
                                  <i className="icon-call-in mr"></i>
                                  <FormattedMessage
                                    id="ModalEventOptions.fieldPhoneIn"
                                    defaultMessage="Phone IN number"
                                  />
                                </label>
                                <Box>
                                  <OutlinedInput
                                    id="phone_format"
                                    name="phone_format"
                                    value={this.state.phone_in}
                                    // onChange={((phone_in) => this.handleChangePhoneNumber(phone_in))}
                                    // onChange={this.handleChange}
                                    onChange={this.handleChangePhoneNumber}
                                    fullWidth
                                    placeholder={this.props.intl.formatMessage({
                                      id: 'ModalEventOptions.write_phoneNumber'
                                    })}
                                    startAdornment={
                                      <Box display="flex" alignItems="center">
                                        <Box mr={1}>
                                          <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                <i className="fa fa-phone-square"></i>
                                              </InputGroupText>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </Box>

                                        <Box mr={1} minWidth={100}>
                                          <Autocomplete
                                            id="country"
                                            name="country"
                                            options={countries_name}
                                            autoHighlight
                                            disableClearable
                                            value={country_label ? { label: country_label } : null}
                                            onChange={this.handleChangeSelectCountry}
                                            getOptionLabel={(option) => option.label || ''}
                                            getOptionSelected={(option, value) =>
                                              option.value === value?.value
                                            }
                                            renderInput={(params) => <TextField {...params} />}
                                            required={true}
                                          />
                                        </Box>
                                      </Box>
                                    }
                                  />
                                  <p className="text-muted">
                                    <small>
                                      <FormattedMessage
                                        id="ModalEventOptions.fieldPhoneInHelp"
                                        defaultMessage="(Optional) Enter a phone number where the candidate can reach out. This number will be sent to him/her."
                                      />
                                    </small>
                                  </p>
                                </Box>
                              </div>
                            </Col>
                          </Row>
                        </TabPanel>
                      </div>
                    </>
                  ) : null}
                </div>
              </Col>
              <Col md={4} style={{ borderLeft: '1px solid silver' }}>
                <div className="panel panel-body">
                  <h4>
                    <FormattedMessage id="ModalCustomTestOptions.title_selectedApplicants" />
                  </h4>
                  {this.state.isBlocked && (
                    <Alert
                      severity="warning"
                      classes={{ icon: classes.iconWarning }}
                      style={{
                        backgroundColor: CONST.APP_COLORS['warning-light'],
                        color: CONST.APP_COLORS['warning']
                      }}
                    >
                      <Typography>
                        <FormattedMessage id="AlertApplicantBlocked.alert_selected" />
                      </Typography>
                    </Alert>
                  )}
                  <List dense style={{ width: '100%' }}>
                    {listApplicants}
                  </List>
                </div>
              </Col>
            </Row>
          </form>
        </Dialog>
      </>
    );
  }
}

ModalEventOptions.propTypes = {
  accountInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(ModalEventOptions)));
