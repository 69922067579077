import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const messages = defineMessages({});

const useStyles = (theme) => ({
  cardRoot: {
    border: '1px solid ' + theme.palette.gray.main
  }
});

class CardFareToJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      directions_job: props.directions_job,
      // Internal State
      currency: '',
      fare: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.directions_job !== this.props.directions_job) {
      this.setState(
        {
          directions_job: this.props.directions_job
        },
        () => {
          this.loadAnalysis();
        }
      );
    }
  }

  componentDidMount() {
    this.loadAnalysis();
  }

  loadAnalysis() {
    const directions_job = this.state.directions_job;
    if (
      directions_job &&
      directions_job.status === 'OK' &&
      directions_job.rows.length > 0 &&
      directions_job.rows[0].elements.length > 0 &&
      directions_job.rows[0].elements[0].fare
    ) {
      const currency = directions_job.rows[0].elements[0].fare.currency;
      const fare = directions_job.rows[0].elements[0].fare.value.toFixed(2);

      this.setState({
        currency: currency,
        fare: fare
      });
    }
  }

  render() {
    const { classes } = this.props;

    const currency = this.state.currency;
    const fare = this.state.fare;

    if (fare !== '') {
      return (
        <Card
          mb={2}
          classes={{
            root: classes.cardRoot
          }}
        >
          <CardHeader
            avatar={<MonetizationOnIcon />}
            title={
              <FormattedMessage
                id="CardFareToJob.title"
                defaultMessage="Approximate commute fare"
              />
            }
            subheader={currency + ' ' + fare}
          />
        </Card>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(CardFareToJob));
