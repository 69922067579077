import React from 'react';

class SearchInstructionsIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <svg
        width="112"
        height="58"
        viewBox="0 0 46 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1564 24.4985C10.1564 24.4985 17.6337 24.1991 19.2495 11.9081C20.6844 0.992493 33.293 5.96087 36.9377 10.3121C41.659 15.9487 39.1422 25.6449 45.2499 26.8698C51.3575 28.0947 47.3691 41.7135 38.0981 40.41C26.5652 38.7884 29.3555 45.5276 25.8934 48.38C23.4087 50.4272 13.2206 48.4566 12.881 41.0251C12.5953 34.7716 9.95745 34.7914 8.08773 34.0823C5.39116 33.0597 3.69202 25.6533 10.1564 24.4985Z"
          fill="#FF5A8A"
          fillOpacity="0.1"
        />
        <path
          d="M35.5747 38.1485L28.2285 30.8895L26.7739 32.3616L34.12 39.6206L35.5747 38.1485Z"
          fill="#979797"
        />
        <path
          d="M38.3809 40.0989L32.6655 34.4514C32.6337 34.42 32.5824 34.4203 32.551 34.4521L30.3878 36.6413C30.3563 36.6731 30.3566 36.7244 30.3884 36.7558L36.1038 42.4034C36.1356 42.4348 36.1869 42.4345 36.2183 42.4027L38.3816 40.2135C38.413 40.1817 38.4127 40.1304 38.3809 40.0989Z"
          fill="#757575"
        />
        <path
          d="M21.7176 34.5402C26.7716 34.5402 30.8686 30.4431 30.8686 25.3892C30.8686 20.3353 26.7716 16.2382 21.7176 16.2382C16.6637 16.2382 12.5667 20.3353 12.5667 25.3892C12.5667 30.4431 16.6637 34.5402 21.7176 34.5402Z"
          fill="#757575"
        />
        <path
          d="M21.7176 33.0471C25.9469 33.0471 29.3755 29.6186 29.3755 25.3893C29.3755 21.16 25.9469 17.7314 21.7176 17.7314C17.4883 17.7314 14.0598 21.16 14.0598 25.3893C14.0598 29.6186 17.4883 33.0471 21.7176 33.0471Z"
          fill="white"
        />
      </svg>
    );
  }
}

export default SearchInstructionsIcon;
