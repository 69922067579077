export const SET_REQUESTS = 'SET_REQUESTS';
export const SET_LOADING_REQUESTS = 'SET_LOADING_REQUESTS';

export function setOpenRequestsCount(payload) {
  return { type: SET_REQUESTS, payload };
}

export function setLoadingRequests() {
  return { type: SET_LOADING_REQUESTS };
}
