/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.6
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import './Vendor';
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss';
// Material UI Theming
import * as CONST from './components/Common/constants';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

// Google Tracking
import ReactGA from 'react-ga';

import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import pt from 'react-intl/locale-data/pt';
import localeData from './server/locales/data.json';

import Moment from 'moment';
import 'moment/locale/pt-br';

import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';

addLocaleData([...en, ...pt]);

export default function App() {
  // TODO: É necessário identificar uma forma de pegar a linguagem do perfil do usuário sem usar useSelector aqui. Ele faz com que cause re-render na aplicação.
  const [userLang, setUserLang] = useState('');
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    function loadUser() {
      setUserLang(user.language);
    }
    loadUser();
  }, [user]);

  const language =
    userLang ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

  const languageWithoutRegionCode = language.split(/[_-]+/)[0];

  // Try full locale, try locale without region code, fallback to 'en'
  const messages = userLang
    ? localeData[userLang.toLowerCase()]
    : localeData[languageWithoutRegionCode.toLowerCase()] ||
      localeData[language.toLowerCase()] ||
      localeData.en;

  //console.log(language);
  Moment.locale(language);

  ReactGA.initialize('UA-134660783-4');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const theme = createTheme(CONST.MATERIAL_THEME);

  return (
    <IntlProvider locale={language} messages={messages}>
      <LocalizationProvider dateAdapter={MomentAdapter} dateLibInstance={Moment} locale={language}>
        <BrowserRouter basename={'/'}>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </IntlProvider>
  );
}
