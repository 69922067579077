// core
import React from 'react';

// libs external'
import { withStyles } from '@material-ui/core/styles';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = (theme) => ({
  eventAvailableIcon: {
    position: 'absolute',
    top: 12,
    left: 14,
    color: theme.palette.success.main,
    fontSize: 18
  },
  errorIcon: {
    position: 'absolute',
    top: 12,
    left: 14,
    color: theme.palette.error.main,
    fontSize: 18
  }
});

class IconSync extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { user, showUserCalendarSync, classes } = this.props;

    if (!showUserCalendarSync) {
      return <> </>;
    }

    if (user.cronofy_token_info !== null && user.cronofy_calendar_id !== null) {
      return <EventAvailableIcon className={classes.eventAvailableIcon} />;
    }
    return <ErrorIcon className={classes.errorIcon} />;
  }
}
export default withStyles(useStyles)(IconSync);
