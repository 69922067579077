import React from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import Api from '../Api/Api';
import UserProfile from '../Common/UserProfile';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import GridConstants from '../Common/GridConstants';
import ApplicantColumns from '../Pipeline/ApplicantColumns';
import Moment from 'moment';
import { applyCustomButtonColor } from '../Layout/PagingContainer';
import LocaleFormatter from '../Common/LocaleFormatter';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ButtonFilterPeriod from '../Reports/JobFilters/ButtonFilterPeriod';
import ButtonFilterSavedForLater from '../Applicant/TalentPool/Filters/ButtonFilterSavedForLater';
import ButtonFilterAllFavorites from '../Applicant/TalentPool/Filters/ButtonFilterAllFavorites';
import ButtonFilterMyFavorites from '../Applicant/TalentPool/Filters/ButtonFilterMyFavorites';
import ButtonFilterApplicantTags from '../Applicant/TalentPool/Filters/ButtonFilterApplicantTags';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonFilterPWD from '../Applicant/TalentPool/Filters/ButtonFilterPWD';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import Divider from '@material-ui/core/Divider';
import UserStorage from '../Common/UserStorage';
import CardSkeleton from '../Applicant2/ApplicantCard/CardSkeleton';
import CVProfileSkeleton from '../Applicant2/CVProfile/CVProfileSkeleton';
import CardBase from '../Applicant2/ApplicantCard/CardBase';
import CardController from '../Applicant/CardController';
import ModalTransferWarning from '../Pipeline/ModalTransferWarning';
import CVProfileInfo from '../Applicant2/CVProfile/CVProfileInfo';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonFilterInternal from '../Applicant/TalentPool/Filters/ButtonFilterInternal';
import SelectJob from './SelectJob';
import * as CONST from '../Common/constants';
import Tooltip from '@material-ui/core/Tooltip';
import Instructions from './Instructions';
import SearchIcon from '@material-ui/icons/Search';
import Sticky from 'react-sticky-el';
import SearchInstructions from './SearchInstructions';
import AddressController from '../Common/AddressController';
import Utils from '../Common/Utils';
import InputAdornment from '@material-ui/core/InputAdornment';
import Fade from '@material-ui/core/Fade';
import Capitalize from 'capitalize';
import ButtonFilterCountries from './ButtonFilterCountries';
import ButtonFilterNotHired from './ButtonFilterNotHired';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ButtonFilterAccountPage from '../Reports/JobFilters/ButtonFilterAccountPage';
import InstructionsResume from './InstructionsResume';

const SCOPE_NAME_EMAIL = 'NAME_EMAIL';

const messages = defineMessages({
  buttonSearch: {
    id: 'ApplicantsSearch.buttonSearch',
    defaultMessage: 'Search'
  },
  placeholder_search: {
    id: 'ApplicantsSearch.placeholder_search',
    defaultMessage: 'Search by skill, education, company, roles or any other relevant keyword'
  },
  field_keywords: {
    id: 'ApplicantsSearch.field_keywords',
    defaultMessage: 'Keywords (in CV)'
  },
  field_education: {
    id: 'ApplicantsSearch.field_education',
    defaultMessage: 'Education'
  },
  field_position: {
    id: 'ApplicantsSearch.field_position',
    defaultMessage: 'Position'
  },
  field_company: {
    id: 'ApplicantsSearch.field_company',
    defaultMessage: 'Company'
  },
  field_languages: {
    id: 'ApplicantsSearch.field_languages',
    defaultMessage: 'Languages'
  },
  field_countries: {
    id: 'ApplicantsSearch.field_countries',
    defaultMessage: 'Countries'
  },
  field_personal: {
    id: 'ApplicantsSearch.field_personal',
    defaultMessage: 'Name, email or Doc ID'
  },
  placeholder_keywords: {
    id: 'ApplicantsSearch.placeholder_keywords',
    defaultMessage: 'Search CV for technical terms, experience, etc...'
  },
  placeholder_company: {
    id: 'ApplicantsSearch.placeholder_company',
    defaultMessage: 'Search Past and current employers'
  },
  placeholder_position: {
    id: 'ApplicantsSearch.placeholder_position',
    defaultMessage: 'Search Past and present job positions'
  },
  placeholder_education: {
    id: 'ApplicantsSearch.placeholder_education',
    defaultMessage: 'Search degree level or course'
  },
  placeholder_location: {
    id: 'ApplicantsSearch.placeholder_location',
    defaultMessage: 'Search city or neighborhood or state (ex: CA)'
  },
  placeholder_languages: {
    id: 'ApplicantsSearch.placeholder_languages',
    defaultMessage: 'Select languages'
  },
  placeholder_countries: {
    id: 'ApplicantsSearch.placeholder_countries',
    defaultMessage: 'Select countries'
  },
  placeholder_personal: {
    id: 'ApplicantsSearch.placeholder_personal',
    defaultMessage: 'Search name, email or Doc ID'
  },
  field_certifications: {
    id: 'ApplicantsSearch.field_certifications',
    defaultMessage: 'Certifications'
  },
  placeholder_certifications: {
    id: 'ApplicantsSearch.placeholder_certifications',
    defaultMessage: 'Search Certifications'
  },
  msg_at: {
    id: 'ApplicantsSearch.msg_at',
    defaultMessage: 'at'
  }
});

const useStyles = (theme) => ({
  select: {
    width: 150
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  mainButtonContainer: {
    width: '356px',
    display: 'flex',
    flexDirection: 'column'
  },
  locationFiltersContainer: {
    border: `1px solid ${theme.palette.gray.darkerLight}`,
    borderRadius: '2rem'
  },
  filterLabel: {
    display: 'flex',
    marginBottom: '0.25rem',
    color: theme.palette.primary.main
  },
  filterLabelActive: {
    color: theme.palette.primary.main
  },
  checkButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: '165px'
  },
  checkButtonLabel: {
    fontSize: '14px',
    color: theme.palette.gray.darker,
    fontWeight: '400'
  },
  noMoreResultsText: {
    fontSize: '14px',
    fontWeight: '400',
    textWrap: 'noWrap',
    color: theme.palette.gray.darkerRegular
  },
  input: {
    width: '1.5rem'
  },
  inputLocation: {
    flexBasis: '25%'
  },
  expandFiltersOpen: {
    transform: 'rotate(180deg)'
  },
  advancedSearchInput: {
    borderRadius: '1.5rem',
    fontSize: '14px',
    flexWrap: 'wrap',
    padding: '0 4px',
    '& .MuiOutlinedInput-root ': {
      width: '0',
      padding: '11px 8px 11px 16px'
    },
    '& .MuiAutocomplete-input': {
      width: '0',
      padding: '11px 8px 11px 16px'
    },
    '& .MuiOutlinedInput-input': {
      width: '0',
      padding: '11px 8px 11px 16px'
    },
    '& input::placeholder': {
      fontSize: '13px',
      color: theme.palette.gray.darker
    },
    '& .MuiAutocomplete-endAdornment': {
      paddingRight: '16px',
      position: 'relative'
    }
  },
  countrySearchInput: {
    padding: '0 8px 0 4px',
    '& .MuiAutocomplete-endAdornment': {
      paddingRight: '0'
    }
  },
  advancedSearchLocation: {
    borderRadius: '1.5rem',
    fontSize: '14px',
    flexWrap: 'wrap',
    padding: '0 4px',
    '& .MuiOutlinedInput-root ': {
      width: '0',
      padding: '11px 0'
    },
    '& .MuiAutocomplete-input': {
      width: '0',
      padding: '11px 0'
    },
    '& .MuiOutlinedInput-input': {
      width: '0',
      padding: '11px 0'
    },
    '& input::placeholder': {
      fontSize: '14px'
    },
    '& .MuiAutocomplete-endAdornment': {
      paddingRight: '16px'
    }
  },
  disabledCheckButton: {
    pointerEvents: 'none'
  },
  whiteContainer: {
    background: theme.palette.common.white,
    borderRadius: '1rem'
  },
  searchContainer: {
    padding: '1.5rem 0',
    marginBottom: '0.5rem'
  },
  resultsContainer: {
    background: 'transparent',
    borderRadius: '1rem'
  },
  countContainer: {
    width: '100%',
    height: '54px',
    marginBottom: '0.5rem',
    display: 'flex',
    alignItems: 'center'
  },
  stickyCountContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 999
  },
  advancedSearchContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
    minWidth: 140
  },
  buttonLabel: {
    width: '100%',
    margin: 0,
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -9,
    marginLeft: -9
  },
  countryName: {
    fontSize: '13px',
    color: theme.palette.gray.darkerMedium
  },
  pinCountryIcon: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium,
    marginRight: '2px'
  },
  helpInstructionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '6.5rem',
    padding: '0 5.25rem'
  },
  helpInstructionsText: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium,
    //color: theme.palette.gray.dark,
    fontWeight: 400,
    textAlign: 'center'
  },
  keywordsInstructionsText: {
    fontSize: '10px',
    color: theme.palette.gray.darkerMedium
    //color: theme.palette.gray.dark,
  },
  helpExamplesTitle: {
    fontSize: '12px',
    color: theme.palette.primary.main,
    fontWeight: 700
  },
  helpSearchIcon: {
    color: theme.palette.common.white,
    fontSize: '16px'
  },
  helpSearchIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.5rem',
    height: '1.5rem',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100%',
    marginRight: '0.5rem'
  },
  checkFiltersContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    margin: '1rem 0',
    padding: '0 11rem',
    columnGap: '2rem'
    //gap: '11rem'
  },
  advancedFiltersContainer: {
    display: 'flex',
    maxWidth: '1128px',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
    margin: '1rem 0 1.5rem 0',
    gap: '1rem'
    //gap: '11rem'
  },
  searchInput: {
    paddingRight: '8px',
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 8px'
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '16px'
    }
  },
  advancedSearchLabel: {
    lineHeight: 'normal'
  },
  searchMoreLabel: {
    lineHeight: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    textWrap: 'noWrap'
  },
  popper: {
    width: '300px !important'
  },
  chipLabel: {
    //padding: '0 2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `3px solid ${theme.palette.common.white}`,
    borderRadius: '2rem',
    height: '28px',
    width: '28px'
  },
  countryChip: {
    width: '32px',
    height: '32px',
    fontSize: '12px',
    border: `1px solid ${theme.palette.gray.darkerLight}`
  },
  loadMoreContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginTop: theme.spacing(1)
  },
  searchMoreButton: {
    background: theme.palette.common.white,
    padding: '8px 14px',
    height: '24px'
  }
});

// MUST BE OUTSIDE OF COMPONENT

const COMPONENT_NAME = 'ApplicantsSearch';

class ApplicantsSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      current_job_id: props.job ? props.job.id : undefined,
      scope: props.scope ? props.scope : SCOPE_NAME_EMAIL,
      job: props.job,
      outsideOfPipeline: props.outsideOfPipeline,
      // Internal State
      // Search
      query: '',
      loading: false,
      results: [],
      currSkip: 0,
      searchLimit: 50,
      total: 0,
      // Grid
      columns: [],
      columnExtensions: [],
      jobSorting: [{ columnName: 'date_created', direction: 'desc' }],
      avatarCols: ['avatar_50_path'],
      scoreCols: ['overall_score'],
      nameCols: ['name'],
      iconCols: [
        'internal',
        'views',
        'favorites',
        'notes',
        'email',
        'phone_mobile',
        'pwd',
        'origin',
        'cv_path',
        'gender'
      ],
      dateCreatedCols: ['date_created'],
      addressCols: ['address'],
      relatedCols: ['job'],
      selectedApplicants: [],
      selectAll: false,
      applicantsToCopy: [],
      tableMessages: GridConstants.getGridMessage(this.props.intl, 'tableMessages'),
      // Applicant Details
      detail_open: false,
      detail_id: null,
      detail_applicant: null,
      // Filters
      filterJobs: [],
      filterJobsSelected: [],
      current_job_code: '',
      current_job_title: '',
      expandedFilters: false,
      edit_location: [],
      edit_position: [],
      edit_company: [],
      edit_keywords: [],
      edit_education: [],
      edit_languages: [],
      edit_personal: [],
      edit_certification: [],
      filters: {
        date_start: undefined,
        date_end: undefined,
        applicant_tags: [],
        account_pages: [],
        my_favorites: false,
        all_favorites: false,
        saved_later: false,
        internal: false,
        pwd: false,
        not_hired: false,
        countries: []
      },
      near_cities: false,
      fullCountryName: '',
      period_option_selected: 0,
      selectedProfile: undefined,
      modalTransferData: [],
      modalTransferOpen: true,
      submiting: false,
      currentApplicantSubmitted: null,
      jobsList: [],
      newJob: '',
      firstLoaded: false,
      idiomsList: [],
      displayIdiomsList: [],
      loadingCountries: false,
      countryList: [],
      selectedCountries: [],
      extractingApplicants: [],
      extracting: false,
      hasMore: false
    };
  }

  async componentDidMount() {
    applyCustomButtonColor();
    this.getCountriesList();
    this.getIdiomsList();
    let displayIdiomsList = [];

    let edit_location = [];
    let edit_keywords = [];
    let edit_company = [];
    let edit_position = [];
    let edit_personal = [];
    let edit_certification = [];
    let edit_education = [];
    let edit_languages = [];
    let query = '';
    let period_option_selected = 0;

    const job = this.state.job;
    const currentJobId = this.state.outsideOfPipeline ? '' : job.id;

    try {
      [
        edit_location = [],
        edit_keywords = [],
        edit_company = [],
        edit_position = [],
        edit_personal = [],
        edit_certification = [],
        edit_education = [],
        edit_languages = [],
        query = '',
        period_option_selected = 0
      ] = await Promise.all([
        UserStorage.getLocalValueAsync(COMPONENT_NAME, '_edit_location' + currentJobId),
        UserStorage.getLocalValueAsync(COMPONENT_NAME, '_edit_keywords' + currentJobId),
        UserStorage.getLocalValueAsync(COMPONENT_NAME, '_edit_company' + currentJobId),
        UserStorage.getLocalValueAsync(COMPONENT_NAME, '_edit_position' + currentJobId),
        UserStorage.getLocalValueAsync(COMPONENT_NAME, '_edit_personal' + currentJobId),
        UserStorage.getLocalValueAsync(COMPONENT_NAME, '_edit_certification' + currentJobId),
        UserStorage.getLocalValueAsync(COMPONENT_NAME, '_edit_education' + currentJobId),
        UserStorage.getLocalValueAsync(COMPONENT_NAME, '_edit_languages' + currentJobId),
        UserStorage.getLocalValueAsync(COMPONENT_NAME, '_query' + currentJobId),
        UserStorage.getLocalValueAsync(COMPONENT_NAME, '_period_option_selected' + currentJobId)
      ]);
    } catch (err) {
      console.log(err);
    }

    let rangeSearchEnabled = false;

    if (!query && job) {
      query = job.title;
    }

    let expandedFilters = false;

    if (
      (edit_position && edit_position.length > 0) ||
      (edit_company && edit_company.length > 0) ||
      (edit_keywords && edit_keywords.length > 0) ||
      (edit_location && edit_location.length > 0) ||
      (edit_personal && edit_personal.length > 0) ||
      (edit_certification && edit_certification.length > 0) ||
      (edit_education && edit_education.length > 0) ||
      (edit_languages && edit_languages.length > 0)
    ) {
      expandedFilters = true;
    }

    this.setState({
      edit_location: edit_location || [],
      edit_keywords: edit_keywords || [],
      edit_company: edit_company || [],
      edit_position: edit_position || [],
      edit_personal: edit_personal || [],
      edit_certification: edit_certification || [],
      edit_education: edit_education || [],
      edit_languages: edit_languages || [],
      query: query,
      expandedFilters: expandedFilters,
      rangeSearchEnabled: rangeSearchEnabled,
      period_option_selected: period_option_selected || 0,
      displayIdiomsList: displayIdiomsList
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open
      });
    }
    if (prevProps.query !== this.props.query) {
      this.setState({
        query: this.props.query
      });
    }
    if (prevProps.job !== this.props.job) {
      let current_job_id = undefined;
      if (this.props.job) {
        current_job_id = this.props.job.id;
      }
      this.setState(
        {
          job: this.props.job,
          current_job_id: current_job_id
        },
        () => this.setInitialQuery()
      );
    }
    if (prevProps.scope !== this.props.scope) {
      this.setState({
        scope: this.props.scope
      });
    }
  }

  getCountriesList = () => {
    let api = new Api();

    let countryCode = this.props.accountInfo.accountInfo.country;

    if (this.state.job) {
      countryCode = this.state.job.country;
    }

    let filter = {
      order: ['suggested DESC', 'name']
    };

    api.get('Countries', filter, (status, data) => {
      if (status === 200) {
        let countryList = [];
        data.forEach((country) => {
          countryList.push({ value: country.code, label: country.name });
        });

        let countryDisplay = undefined;

        const countryIndex = countryList.findIndex((country) => country.value === countryCode);

        countryDisplay = countryIndex > -1 ? countryList[countryIndex].value : undefined;

        this.setState({
          loadingCountries: false,
          countryList: countryList,
          fullCountryName: countryDisplay
        });
      } else {
        console.log('Error on fetching countries');
      }
    });
  };

  getIdiomsList = () => {
    let api = new Api();

    let filter = {
      where: {
        locale: this.props.user.language.slice(0, 2)
      }
    };
    api.get('DicLocalIdioms', filter, (status, data) => {
      if (status === 200) {
        this.setState({
          idiomsList: data
        });
      } else {
        console.log('Error on fetching idioms');
      }
    });
  };

  setInitialQuery = () => {
    const job = this.state.job;
    if (job && !this.state.query) {
      this.setState({
        query: job.title
      });
    }
  };

  onClose = () => {
    this.setState(
      {
        open: false,
        loading: false,
        results: [],
        total: 0,
        current_job_code: '',
        current_job_title: ''
      },
      () => {
        if (this.props.handleClose) {
          this.props.handleClose();
        }
      }
    );
  };

  loadCurrentJob() {
    // Checking
    if (this.state.current_job_id) {
      let api = new Api();

      let filter = {
        fields: ['code', 'title'],
        where: {
          id: this.state.current_job_id
        }
      };

      api.get('Jobs', filter, (statud, data) => {
        if (data.length > 0) {
          this.setState({
            current_job_code: data[0].code,
            current_job_title: data[0].title
          });
        } else {
          this.setState({
            current_job_id: undefined
          });
        }
      });
    }
  }

  loadJobsList() {}

  getSearchResults(limit, skip, loadMore) {
    let query = this.state.query;

    let edit_keywords = [];
    let edit_company = [];
    let edit_position = [];
    let edit_education = [];
    let edit_location = [];
    let edit_languages = [];
    let edit_personal = [];
    let edit_certification = [];

    if (this.state.expandedFilters) {
      query = '';
      edit_keywords = this.state.edit_keywords;
      edit_company = this.state.edit_company;
      edit_education = this.state.edit_education;
      edit_position = this.state.edit_position;
      edit_location = this.state.edit_location;
      edit_languages = this.state.edit_languages;
      edit_personal = this.state.edit_personal;
      edit_certification = this.state.edit_certification;
    }
    const isCheckboxFilterEnabled =
      this.state.filters.all_favorites ||
      this.state.filters.my_favorites ||
      this.state.filters.internal ||
      this.state.filters.saved_later ||
      this.state.filters.pwd ||
      this.state.filters.not_hired;

    const isQueryValid =
      query !== '' ||
      edit_keywords.length > 0 ||
      edit_location.length > 0 ||
      edit_company.length > 0 ||
      edit_position.length > 0 ||
      edit_languages.length > 0 ||
      edit_education.length > 0 ||
      edit_personal.length > 0 ||
      edit_certification.length > 0 ||
      (this.state.filters.date_start && this.state.filters.date_end) ||
      this.state.filters.applicant_tags.length > 0 ||
      this.state.filters.account_pages.length > 0 ||
      this.state.filters.countries.length > 0 ||
      isCheckboxFilterEnabled;

    const job = this.state.job;

    if (!limit) {
      limit = 100;
    }

    if (!skip) {
      skip = 0;
    }
    if (isQueryValid) {
      let api = new Api();
      const languagesCodes = this.state.edit_languages.map((currLanguage) => currLanguage.code);

      let params = {
        query: query,
        filters: {
          tags: this.state.filters.applicant_tags,
          account_pages: this.state.filters.account_pages,
          my_favorites: this.state.filters.my_favorites,
          all_favorites: this.state.filters.all_favorites,
          saved_later: this.state.filters.saved_later,
          date_start: this.state.filters.date_start,
          date_end: this.state.filters.date_end,
          near_cities: this.state.near_cities,
          location: this.state.edit_location,
          position: this.state.edit_position,
          company: this.state.edit_company,
          keywords: this.state.edit_keywords,
          education: this.state.edit_education,
          languages: languagesCodes,
          personal_info: this.state.edit_personal,
          pwd: this.state.filters.pwd,
          internal: this.state.filters.internal,
          countries: this.state.filters.countries,
          not_hired: this.state.filters.not_hired,
          certification: this.state.edit_certification
        },
        limit: limit,
        skip: skip
      };

      if (job) {
        params.job_id = job.id;
      }
      // console.log(params)
      api.post('ReadPersons/search', params, (status, data) => {
        // console.log(data);
        if (status > 200) {
          console.log('Error on fetching data');
          this.setState({
            loading: false
          });
        } else {
          if (data.results) {
            let formattedPeople = [];

            if (Array.isArray(data.results)) {
              formattedPeople = data.results.map((applicant) => {
                if (applicant.tags && applicant.tags.length > 0) {
                  applicant.tags = [...new Set(applicant.tags)];
                }

                if (applicant.favs && applicant.favs.length > 0) {
                  applicant.favs = [...new Set(applicant.favs)];
                }

                if (applicant.hashtags && applicant.hashtags.length > 0) {
                  applicant.hashtags = [...new Set(applicant.hashtags)];
                }

                if (applicant.avatar_50_path) {
                  applicant.avatar_url =
                    'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + applicant.avatar_50_path;
                }

                return applicant;
              });
            }

            //console.log(formattedPeople);

            let location = this.state.edit_location;
            let position = this.state.edit_position;
            let company = this.state.edit_company;
            let keywords = this.state.edit_keywords;
            let education = this.state.edit_education;
            let languages = this.state.edit_languages;
            let personal = this.state.edit_personal;
            let near_cities = this.state.near_cities;
            let filters = this.state.filters;
            let certification = this.state.edit_certification;
            const query_info = data.query_info;

            if (query_info) {
              if (query_info.location && query_info.location.length > 0) {
                location = [...new Set(query_info.location)];
              }
              const hasFilters =
                query_info.location.length > 0 ||
                query_info.position.length > 0 ||
                query_info.company.length > 0 ||
                query_info.keywords.length > 0 ||
                query_info.education.length > 0 ||
                (query_info.languages_codes && query_info.languages_codes.length > 0) ||
                query_info.certification.length > 0 ||
                query_info.name ||
                query_info.email ||
                query_info.documentId ||
                query_info.near_cities ||
                query_info.pwd ||
                query_info.internal;

              if (hasFilters) {
                location = [];
                position = [];
                company = [];
                education = [];
                keywords = [];
                personal = [];
                languages = [];
                certification = [];
                near_cities = false;

                if (query_info.position && query_info.position.length > 0) {
                  position = [...new Set(query_info.position)];
                }

                if (query_info.company && query_info.company.length > 0) {
                  company = [...new Set(query_info.company)];
                }

                if (query_info.education && query_info.education.length > 0) {
                  education = [];
                  query_info.education.forEach((currEducation) => {
                    if (typeof currEducation === 'string') {
                      education.push(currEducation);
                    } else {
                      if (currEducation.degree && currEducation.degree !== '') {
                        education.push(currEducation.degree);
                      }

                      if (currEducation.schoolName && currEducation.schoolName !== '') {
                        education.push(currEducation.schoolName);
                      }
                    }
                  });
                }

                if (query_info.languages_codes && query_info.languages_codes.length > 0) {
                  query_info.languages_codes.forEach((currLanguage) => {
                    const queryLanguage = this.state.idiomsList.find(
                      (currIdiom) => currIdiom.code === currLanguage
                    );

                    if (queryLanguage) {
                      languages.push(queryLanguage);
                    }
                  });
                }

                if (query_info.keywords) {
                  if (Array.isArray(query_info.keywords)) {
                    const formattedKeywords = query_info.keywords.filter(
                      (currKeyword) =>
                        !currKeyword.includes('<->') &&
                        !currKeyword.includes('|') &&
                        !currKeyword.includes('&')
                    );
                    keywords = [...new Set(formattedKeywords)];
                  } else {
                    const formattedKeywords = query_info.keywords
                      .split(' ')
                      .filter(
                        (currKeyword) =>
                          !currKeyword.includes('<->') &&
                          !currKeyword.includes('|') &&
                          !currKeyword.includes('&')
                      );
                    keywords = [...new Set(formattedKeywords)];
                  }
                }

                if (query_info.location && query_info.location.length > 0) {
                  location = [...new Set(query_info.location)];
                }

                if (query_info.certification && query_info.certification.length > 0) {
                  certification = [...new Set(query_info.certification)];
                }

                if (query_info.near_cities) {
                  near_cities = query_info.near_cities;
                }

                if (query_info.pwd) {
                  filters.pwd = query_info.pwd;
                }

                if (query_info.pwd) {
                  filters.pwd = query_info.pwd;
                }

                if (query_info.internal) {
                  filters.internal = query_info.internal;
                }

                if (query_info.name) {
                  personal.push(query_info.name);
                }

                if (query_info.email) {
                  personal.push(query_info.email);
                }

                if (query_info.documentId) {
                  personal.push(query_info.documentId);
                }
              }
            }

            const results = loadMore
              ? [...this.state.results, ...formattedPeople]
              : formattedPeople;

            let hasMore = false;

            if (this.state.firstLoaded) {
              hasMore = data.results.length >= this.state.searchLimit;
            } else {
              hasMore = data.results.length >= this.state.searchLimit;
            }

            this.setState({
              results: results,
              total: results.length,
              edit_location: location,
              edit_position: position,
              edit_company: company,
              edit_keywords: keywords,
              edit_education: education,
              edit_languages: languages,
              edit_certification: certification,
              edit_personal: personal,
              near_cities: near_cities,
              filters: filters,
              loading: false,
              firstLoaded: true,
              tableMessages: GridConstants.getGridMessage(this.props.intl, 'tableMessages'),
              columns: [
                ApplicantColumns(this, 'favorites'),
                ApplicantColumns(this, 'avatar_50_path'),
                ApplicantColumns(this, 'name'),
                ApplicantColumns(this, 'email'),
                ApplicantColumns(this, 'address'),
                ApplicantColumns(this, 'date_created')
              ],
              hasMore: hasMore
            });
          } else {
            this.setState({
              loading: false
            });
          }
        }
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value
      },
      () => {
        if (name === 'query') {
          const job = this.state.job;
          const currentJobId = this.state.outsideOfPipeline ? '' : job.id;
          UserStorage.setLocalValue(COMPONENT_NAME, '_query' + currentJobId, this.state.query);
        }
      }
    );
  };

  onSearch = (e) => {
    e.preventDefault();

    this.setState(
      {
        total: 0,
        currSkip: 0,
        results: [],
        selectedProfile: undefined,
        loading: true,
        tableMessages: {
          noData: this.props.intl.formatMessage({ id: 'ModalSearch.searchingMsg' })
        }
      },
      () => {
        // First search does not have offset
        this.getSearchResults(this.state.searchLimit, 0);

        const newSkip = this.state.currSkip + this.state.searchLimit;

        this.setState({
          currSkip: newSkip
        });
      }
    );
  };

  handleSearchMore = () => {
    this.setState(
      {
        loading: true
      },
      () => {
        this.getSearchResults(this.state.searchLimit, this.state.currSkip, true);
        const newSkip = this.state.currSkip + this.state.searchLimit;

        this.setState({
          currSkip: newSkip
        });
      }
    );
  };

  handleChangePosition = (value) => {
    this.setState(
      {
        edit_position: value
      },
      () => {
        const job = this.state.job;
        const currentJobId = this.state.outsideOfPipeline ? '' : job.id;
        UserStorage.setLocalValue(
          COMPONENT_NAME,
          '_edit_position' + currentJobId,
          this.state.edit_position
        );
      }
    );
  };

  handleChangeCompany = (value) => {
    this.setState(
      {
        edit_company: value
      },
      () => {
        const job = this.state.job;
        const currentJobId = this.state.outsideOfPipeline ? '' : job.id;
        UserStorage.setLocalValue(
          COMPONENT_NAME,
          '_edit_company' + currentJobId,
          this.state.edit_company
        );
      }
    );
  };

  handleChangeKeywords = (value) => {
    this.setState(
      {
        edit_keywords: value
      },
      () => {
        const job = this.state.job;
        const currentJobId = this.state.outsideOfPipeline ? '' : job.id;
        UserStorage.setLocalValue(
          COMPONENT_NAME,
          '_edit_keywords' + currentJobId,
          this.state.edit_keywords
        );
      }
    );
  };

  handleChangePersonalInfo = (value) => {
    this.setState(
      {
        edit_personal: value
      },
      () => {
        const job = this.state.job;
        const currentJobId = this.state.outsideOfPipeline ? '' : job.id;
        UserStorage.setLocalValue(
          COMPONENT_NAME,
          '_edit_personal' + currentJobId,
          this.state.edit_personal
        );
      }
    );
  };

  handleChangeLocation = (e, value) => {
    if (Array.isArray(value)) {
      let newLocationValue = [...value];

      this.setState(
        {
          edit_location: newLocationValue,
          near_cities: value.length === 0 ? false : this.state.near_cities
        },
        () => {
          const job = this.state.job;
          const currentJobId = this.state.outsideOfPipeline ? '' : job.id;
          UserStorage.setLocalValue(
            COMPONENT_NAME,
            '_edit_location' + currentJobId,
            this.state.edit_location
          );
        }
      );
    } else {
      const locationTitle = e.target.value;
      this.setState(
        {
          edit_location: [locationTitle],
          locationNameDisplay: locationTitle
        },
        () => {
          const job = this.state.job;
          const currentJobId = this.state.outsideOfPipeline ? '' : job.id;
          UserStorage.setLocalValue(
            COMPONENT_NAME,
            '_edit_location' + currentJobId,
            this.state.edit_location
          );
        }
      );
    }
  };

  handleChangeEducation = (value) => {
    this.setState(
      {
        edit_education: value
      },
      () => {
        const job = this.state.job;
        const currentJobId = this.state.outsideOfPipeline ? '' : job.id;
        UserStorage.setLocalValue(
          COMPONENT_NAME,
          '_edit_education' + currentJobId,
          this.state.edit_education
        );
      }
    );
  };

  handleChangeLanguages = (value) => {
    this.setState(
      {
        edit_languages: value
      },
      () => {
        const job = this.state.job;
        const currentJobId = this.state.outsideOfPipeline ? '' : job.id;
        UserStorage.setLocalValue(
          COMPONENT_NAME,
          '_edit_languages' + currentJobId,
          this.state.edit_languages
        );
      }
    );
  };

  handleChangeCertification = (value) => {
    this.setState(
      {
        edit_certification: value
      },
      () => {
        const job = this.state.job;
        const currentJobId = this.state.outsideOfPipeline ? '' : job.id;
        UserStorage.setLocalValue(
          COMPONENT_NAME,
          '_edit_certification' + currentJobId,
          this.state.edit_certification
        );
      }
    );
  };

  handleOpenExpandedFilters = () => {
    const expandedFilters = !this.state.expandedFilters;

    this.setState({
      expandedFilters: expandedFilters
    });
  };

  updatePeriod = (option, optionIndex) => {
    //console.log(option)

    const job = this.state.job;
    const currentJobId = this.state.outsideOfPipeline ? '' : job.id;
    if (option) {
      const filters = {
        ...this.state.filters,
        date_start: option.date_start,
        date_end: option.date_end
      };

      const period_option_selected =
        optionIndex !== null && optionIndex !== undefined
          ? optionIndex
          : this.state.period_option_selected;

      this.setState(
        {
          filters: filters,
          period_option_selected: period_option_selected
        },
        () => {
          if (optionIndex !== null && optionIndex !== undefined) {
            UserStorage.setLocalValue(
              COMPONENT_NAME,
              '_period_option_selected' + currentJobId,
              this.state.period_option_selected
            );
          }
        }
      );
    } else {
      const filters = {
        ...this.state.filters,
        date_start: undefined,
        date_end: undefined
      };

      const period_option_selected =
        optionIndex !== null && optionIndex !== undefined
          ? optionIndex
          : this.state.period_option_selected;

      this.setState(
        {
          filters: filters,
          period_option_selected: period_option_selected
        },
        () => {
          if (optionIndex !== null && optionIndex !== undefined) {
            UserStorage.setLocalValue(
              COMPONENT_NAME,
              '_period_option_selected' + currentJobId,
              this.state.period_option_selected
            );
          }
        }
      );
    }
  };

  updateFavorites = (favorite, option) => {
    // console.log(favorite, option);
    if (option === 'USER') {
      const filters = {
        ...this.state.filters,
        my_favorites: favorite
      };

      this.setState({
        filters: filters
      });
    }

    if (option === 'ALL') {
      const filters = {
        ...this.state.filters,
        all_favorites: favorite
      };

      this.setState({
        filters: filters
      });
    }
  };

  updateSavedForLater = (savedForLater) => {
    const filters = {
      ...this.state.filters,
      saved_later: savedForLater
    };

    this.setState({
      filters: filters
    });
  };

  updateInternal = (internal) => {
    const filters = {
      ...this.state.filters,
      internal: internal
    };

    this.setState({
      filters: filters
    });
  };

  updatePWD = (pwd) => {
    const filters = {
      ...this.state.filters,
      pwd: pwd
    };

    this.setState({
      filters: filters
    });
  };

  updateNotHired = (not_hired) => {
    const filters = {
      ...this.state.filters,
      not_hired: not_hired
    };

    this.setState({
      filters: filters
    });
  };

  updateApplicantTags = (applicantTags) => {
    const filters = {
      ...this.state.filters,
      applicant_tags: applicantTags
    };

    this.setState({
      filters: filters
    });
  };

  updateAccountPage = (page) => {
    // const { COMPONENT_NAME } = this.state;
    const accountPageIds = page && page.length > 0 ? page : [];

    const filters = {
      ...this.state.filters,
      account_pages: accountPageIds
    };

    this.setState(
      {
        filters: filters
      }
      // ,
      // () => {
      //   if (this.props.handleChangeFilters) {
      //     this.props.handleChangeFilters(this.state.filters);
      //   }

      //   UserStorage.setLocalValue(
      //     COMPONENT_NAME,
      //     '_filters_account_page',
      //     this.state.filters.account_page
      //   );
      // }
    );
  };

  updateCountries = (countries) => {
    const filters = {
      ...this.state.filters,
      countries: countries
    };

    this.setState({
      filters: filters
    });
  };

  handleEnableRangeSearch = () => {
    const newValue = this.state.near_cities === true ? false : true;

    this.setState({
      near_cities: newValue
    });
  };

  handleCloseCV = () => {
    this.setState({
      selectedProfile: null
    });
  };

  handleSelectAllApplicants = () => {
    let selectedApplicants = this.state.selectedApplicants;
    const results = this.state.results;

    if (selectedApplicants.length > 0) {
      selectedApplicants = [];
    } else {
      selectedApplicants = results.filter((resultObj) => !resultObj.date_included);
    }

    const selectAll = selectedApplicants.length === results.length ? true : false;

    this.setState({
      selectedApplicants: selectedApplicants,
      selectAll: selectAll
    });
  };

  handleSelectApplicant = (applicant) => {
    let selectedApplicants = this.state.selectedApplicants;
    const applicantIndex = selectedApplicants.findIndex((currObj) => currObj.id === applicant.id);

    if (applicantIndex > -1) {
      selectedApplicants = selectedApplicants.filter((currObj) => currObj.id !== applicant.id);
    } else {
      selectedApplicants.push(applicant);
    }

    this.setState({
      selectedApplicants: selectedApplicants
    });
  };

  handleSelectCVProfile = (applicant_id) => {
    const currentSelectedProfile = this.state.selectedProfile;

    if (!(currentSelectedProfile && currentSelectedProfile.id === applicant_id)) {
      const selectedProfile = this.state.results.find((resultObj) => resultObj.id === applicant_id);

      if (selectedProfile) {
        if (selectedProfile.cv_profile) {
          selectedProfile.job_id = this.state.current_job_id ? this.state.current_job_id : null;
          CardController.postPersonView(selectedProfile, (err, newView) => {
            if (err) {
              console.log(err);
            } else {
              const updatedResults = this.state.results.map((currObj) => {
                if (currObj.id === applicant_id) {
                  const applicantViews = currObj.personViews ? currObj.personViews : [];
                  applicantViews.unshift(newView);
                  return {
                    ...currObj,
                    personViews: applicantViews
                  };
                } else {
                  return currObj;
                }
              });

              this.setState({
                results: updatedResults,
                selectedProfile: selectedProfile
              });
            }
          });
        } else {
          //console.log('extracting cv profile');

          let extractingApplicants = this.state.extractingApplicants;
          extractingApplicants.push(selectedProfile);

          this.setState(
            {
              extracting: true,
              extractingApplicants: extractingApplicants
            },
            () => {
              let api = new Api();

              let updatePayload = {
                applicant_id: selectedProfile.applicant_id
              };
              api.post('Applicants/extract-cv-profile', updatePayload, (status, newCVProfile) => {
                if (status < 400) {
                  //console.log(newCVProfile);
                  const updatedResults = this.state.results.map((currObj) => {
                    if (currObj.id === applicant_id) {
                      return {
                        ...currObj,
                        cv_profile: newCVProfile
                      };
                    } else {
                      return currObj;
                    }
                  });

                  selectedProfile.cv_profile = newCVProfile;

                  const extractedApplicantIndex = extractingApplicants.findIndex(
                    (applicant) => applicant.id === applicant_id
                  );

                  if (extractedApplicantIndex > -1) {
                    extractingApplicants.splice(extractedApplicantIndex, 1);
                  }

                  this.setState({
                    results: updatedResults,
                    selectedProfile: selectedProfile,
                    extractingApplicants: extractingApplicants
                  });
                } else {
                  console.log('Error on updating cv profile');
                  const extractedApplicantIndex = extractingApplicants.findIndex(
                    (applicant) => applicant.id === applicant_id
                  );

                  if (extractedApplicantIndex > -1) {
                    extractingApplicants.splice(extractedApplicantIndex, 1);
                  }
                  this.setState({
                    selectedProfile: selectedProfile,
                    extractingApplicants: extractingApplicants
                  });
                }
              });
            }
          );
        }
      } else {
        this.setState({
          selectedProfile: selectedProfile
        });
      }
    }
  };

  handleUpdateApplicantFavorites = (applicant_id, favorites) => {
    const updatedResults = this.state.results.map((currObj) => {
      if (currObj.applicant_id === applicant_id) {
        return {
          ...currObj,
          favs: favorites
        };
      } else {
        return currObj;
      }
    });

    let selectedProfile = this.state.selectedProfile;

    if (selectedProfile && selectedProfile.applicant_id === applicant_id) {
      selectedProfile = {
        ...selectedProfile,
        favs: favorites
      };
    }

    this.setState({
      results: updatedResults,
      selectedProfile: selectedProfile
    });
  };

  handleTransferModalClose = () => {
    this.setState({
      modalTransferOpen: false,
      modalTransferData: []
    });
  };

  handleIncludeApplicant = (applicant_id) => {
    this.setState(
      {
        submiting: true,
        currentApplicantSubmitted: applicant_id
      },
      () => {
        const mode = 'COPY';

        let api = new Api();

        const payload = {
          mode: mode,
          applicants: [applicant_id],
          job_id: this.state.current_job_id,
          pipeline_stage: null,
          user_id: UserProfile.getId(),
          origin: 'SEARCH'
        };

        //console.log(payload);
        api.post('Applicants/transfer', payload, (status, data) => {
          //console.log(data);

          if (status <= 400) {
            let modalTransferOpen = this.state.modalTransferOpen;
            let modalTransferData = this.state.modalTransferData;

            if (data && data.applicantsNotTransferred && data.applicantsNotTransferred.length > 0) {
              modalTransferData = data;
              modalTransferOpen = true;
            }

            let results = this.state.results;
            let selectedApplicants = this.state.selectedApplicants;

            if (data && data.applicantsToReturn && data.applicantsToReturn.length > 0) {
              results = this.state.results.map((appObj) => {
                const newJobInfo = data.applicantsToReturn.find(
                  (jobObj) => jobObj.person_id === appObj.id
                );

                let jobsCount = appObj.jobsCount ? appObj.jobsCount : 0;

                if (newJobInfo) {
                  return {
                    ...appObj,
                    date_included: newJobInfo.date_created,
                    jobsCount: jobsCount + 1
                  };
                } else {
                  return appObj;
                }
              });

              selectedApplicants = selectedApplicants.filter(
                (currObj) => currObj.applicant_id !== applicant_id
              );
            }

            this.setState({
              submiting: false,
              modalTransferData: modalTransferData,
              modalTransferOpen: modalTransferOpen,
              currentApplicantSubmitted: null,
              selectedApplicants: selectedApplicants,
              results: results
            });
          }
        });
      }
    );
  };

  handleIncludeMany = () => {
    //console.log(selected_ids);
    const selected_ids = this.state.selectedApplicants.map((currObj) => currObj.applicant_id);
    if (selected_ids && selected_ids.length > 100) {
      window.swal({
        type: 'warning',
        title: this.props.intl.formatMessage({
          id: 'CrossRank.applicantLimitsTitle'
        }),
        text: this.props.intl.formatMessage({
          id: 'CrossRank.applicantLimitsMessage'
        }),
        closeOnConfirm: true
      });
    } else {
      this.setState(
        {
          submiting: true
        },
        () => {
          const mode = 'COPY';

          let api = new Api();

          const payload = {
            mode: mode,
            applicants: selected_ids,
            job_id: this.state.current_job_id,
            pipeline_stage: null,
            user_id: UserProfile.getId(),
            origin: 'SEARCH'
          };

          //console.log(payload);

          api.post('Applicants/transfer', payload, (status, data) => {
            //console.log(data);

            if (status <= 400) {
              let modalTransferOpen = this.state.modalTransferOpen;
              let modalTransferData = this.state.modalTransferData;

              if (
                data &&
                data.applicantsNotTransferred &&
                data.applicantsNotTransferred.length > 0
              ) {
                modalTransferData = data;
                modalTransferOpen = true;
              }

              let results = this.state.results;
              let selectedApplicants = this.state.selectedApplicants;

              if (data && data.applicantsToReturn && data.applicantsToReturn.length > 0) {
                results = this.state.results.map((appObj) => {
                  const newJobInfo = data.applicantsToReturn.find(
                    (jobObj) => jobObj.person_id === appObj.id
                  );

                  let jobsCount = appObj.jobsCount ? appObj.jobsCount : 0;

                  if (newJobInfo) {
                    return {
                      ...appObj,
                      date_included: newJobInfo.date_created,
                      jobsCount: jobsCount + 1
                    };
                  } else {
                    return appObj;
                  }
                });

                selectedApplicants = selectedApplicants.filter(
                  (currObj) => !selected_ids.includes(currObj.applicant_id)
                );
              }

              //console.log(data);

              this.setState({
                submiting: false,
                modalTransferData: modalTransferData,
                modalTransferOpen: modalTransferOpen,
                selectedApplicants: selectedApplicants,
                results: results
              });
            }
          });
        }
      );
    }
  };

  handleSelectJob = (newJob) => {
    const job_id = newJob ? newJob.id : '';
    this.setState({
      job: newJob,
      newJob: job_id,
      current_job_id: job_id
    });
  };

  onBlurKeywords = (e) => {
    const target = e.target;
    const value = target.value;
    if (value && value.replace(/\s/g, '').length > 0) {
      const blurValue = this.state.edit_keywords.concat(value);
      this.setState({
        edit_keywords: blurValue
      });
    }
  };

  onBlurPersonalInfo = (e) => {
    const target = e.target;
    const value = target.value;
    if (value && value.replace(/\s/g, '').length > 0) {
      const blurValue = this.state.edit_personal.concat(value);
      this.setState({
        edit_personal: blurValue
      });
    }
  };

  onBlurPosition = (e) => {
    const target = e.target;
    const value = target.value;
    if (value && value.replace(/\s/g, '').length > 0) {
      const blurValue = this.state.edit_position.concat(value);
      this.setState({
        edit_position: blurValue
      });
    }
  };

  onBlurCompany = (e) => {
    const target = e.target;
    const value = target.value;
    if (value && value.replace(/\s/g, '').length > 0) {
      const blurValue = this.state.edit_company.concat(value);
      this.setState({
        edit_company: blurValue
      });
    }
  };

  onBlurLocation = (e) => {
    const target = e.target;
    const value = target.value;
    if (value && value.replace(/\s/g, '').length > 0) {
      const blurValue = this.state.edit_location.concat(value);
      this.setState({
        edit_location: blurValue
      });
    }
  };

  onBlurEducation = (e) => {
    const target = e.target;
    const value = target.value;
    if (value && value.replace(/\s/g, '').length > 0) {
      const blurValue = this.state.edit_education.concat(value);
      this.setState({
        edit_education: blurValue
      });
    }
  };

  onBlurCertification = (e) => {
    const target = e.target;
    const value = target.value;
    if (value && value.replace(/\s/g, '').length > 0) {
      const blurValue = this.state.edit_certification.concat(value);
      this.setState({
        edit_certification: blurValue
      });
    }
  };

  handleChangeSelectJob = (selected) => {
    let value = null;
    let pipeline_stages = null;
    let pipeline = null;
    if (selected) {
      value = selected.value;
      pipeline_stages = selected.pipeline_stages;
      pipeline = selected.pipeline;
    }

    this.setState({
      newJob: value,
      stageList: pipeline_stages,
      newPipeline: pipeline
    });
  };

  handleChangeSelectCountry = (event, selectedCountries) => {
    if (selectedCountries) {
      let filterCountries = [];

      selectedCountries.forEach((option) => {
        filterCountries.push(option.value);
      });

      const filters = {
        ...this.state.filters,
        countries: filterCountries
      };

      this.setState({
        filters: filters,
        selectedCountries: selectedCountries
      });
    } else {
      const filters = {
        ...this.state.filters,
        countries: []
      };

      this.setState({
        filters: filters,
        selectedCountries: []
      });
    }
  };

  render() {
    const { classes } = this.props;

    const selectedCountries = this.state.selectedCountries;

    const isCheckboxFilterEnabled =
      this.state.filters.all_favorites ||
      this.state.filters.my_favorites ||
      this.state.filters.internal ||
      this.state.filters.saved_later ||
      this.state.filters.pwd ||
      this.state.filters.not_hired;

    const isAdvancedSearchValid =
      this.state.edit_keywords.length > 0 ||
      this.state.edit_location.length > 0 ||
      this.state.edit_company.length > 0 ||
      this.state.edit_position.length > 0 ||
      this.state.edit_education.length > 0 ||
      this.state.edit_company.length > 0 ||
      this.state.edit_languages.length > 0 ||
      this.state.edit_personal.length > 0 ||
      (this.state.filters.date_start && this.state.filters.date_end) ||
      this.state.filters.applicant_tags.length > 0 ||
      this.state.filters.countries.length > 0 ||
      this.state.filters.account_pages.length > 0 ||
      this.state.edit_certification.length > 0 ||
      isCheckboxFilterEnabled;

    const advancedSearchDisabled = !this.state.expandedFilters || !isAdvancedSearchValid;

    const infoTransfer = this.state.modalTransferData;

    const modalWarningTransferActive =
      infoTransfer &&
      infoTransfer.applicantsNotTransferred &&
      infoTransfer.applicantsNotTransferred.length > 0;

    const includeManyDisabled =
      this.state.selectedApplicants.length === 0 ||
      this.state.submiting ||
      !this.state.current_job_id;

    const includeManyVariant = includeManyDisabled ? 'outlined' : 'contained';

    const displaySkeletons = () => {
      let loopArray = [];
      for (let i = 0; i < 10; i++) {
        loopArray.push(i);
      }

      return (
        <Box
          display="flex"
          style={{
            gap: '0.5rem'
          }}
        >
          <Box width="50%">
            <Box>
              {loopArray.map(() => {
                return (
                  <>
                    <Box
                      style={{
                        marginBottom: '0.5rem'
                      }}
                    >
                      <CardSkeleton />
                    </Box>
                  </>
                );
              })}
            </Box>
          </Box>
        </Box>
      );
    };

    const displayResults = () => {
      const extractingApplicantsIds = this.state.extractingApplicants.map((currObj) => currObj.id);
      const selectedProfile = this.state.selectedProfile;
      const job = this.state.job;

      return (
        <>
          {this.state.submiting ? <LinearProgress color="secondary" /> : null}
          <Box
            display="flex"
            style={{
              gap: '0.5rem'
            }}
          >
            <Box width="50%">
              <Box>
                {this.state.results.map((result) => {
                  let lastJob = undefined;
                  let company = undefined;
                  let dateInCompany = undefined;
                  let title = '';
                  if (result.cv_profile) {
                    const cv_profile = result.cv_profile;
                    title = cv_profile.title;

                    if (cv_profile.experiences && cv_profile.experiences.length > 0) {
                      const currExperience = cv_profile.experiences[0];
                      lastJob = currExperience.jobTitle;
                      company = currExperience.company;

                      let startDateLabel = undefined;
                      let endDateLabel = undefined;

                      if (currExperience.startDate && currExperience.startDate !== '') {
                        if (Utils.matchPresentDate(currExperience.startDate)) {
                          startDateLabel = (
                            <span>
                              {this.props.intl.formatMessage({
                                id: 'CVProfileInfo.present'
                              })}
                            </span>
                          );
                        } else if (Utils.isValidDate(new Date(currExperience.startDate))) {
                          startDateLabel = (
                            <LocaleFormatter
                              dateToFormat={Moment(new Date(currExperience.startDate)).format(
                                'MMM/YYYY'
                              )}
                            />
                          );
                        }
                      }

                      if (currExperience.endDate && currExperience.endDate !== '') {
                        if (Utils.matchPresentDate(currExperience.endDate)) {
                          endDateLabel = (
                            <span>
                              {this.props.intl.formatMessage({
                                id: 'CVProfileInfo.present'
                              })}
                            </span>
                          );
                        } else if (Utils.isValidDate(new Date(currExperience.endDate))) {
                          endDateLabel = (
                            <LocaleFormatter
                              dateToFormat={Moment(new Date(currExperience.endDate)).format(
                                'MMM/YYYY'
                              )}
                            />
                          );
                        }
                      }

                      dateInCompany = startDateLabel ? (
                        <>
                          {'('}
                          {startDateLabel}
                          {' - '}
                          {endDateLabel}
                          {')'}
                        </>
                      ) : undefined;
                    }
                  }

                  const relevantInfo = company ? (
                    <span>
                      {lastJob}&nbsp;
                      {this.props.intl.formatMessage({
                        id: 'ApplicantsSearch.msg_at'
                      })}
                      &nbsp;{company}&nbsp;{dateInCompany}
                    </span>
                  ) : null;

                  let location = '';

                  if (AddressController.addrApplicantShort(result.address)) {
                    location = AddressController.addrApplicantShort(result.address);
                  } else if (result.country) {
                    location = result.country;
                  } else if (result.cv_profile && result.cv_profile.location) {
                    location = result.cv_profile.location;
                  } else if (result.country) {
                    location = result.country;
                  }

                  let viewed = false;

                  if (result.personViews && result.personViews.length > 0) {
                    viewed = this.state.current_job_id
                      ? result.personViews.some(
                          (currView) =>
                            currView.user_id === UserProfile.getId() &&
                            currView.job_id === this.state.current_job_id
                        )
                      : result.personViews.some(
                          (currView) =>
                            currView.user_id === UserProfile.getId() && currView.job_id === null
                        );
                  }

                  const date_included = result.date_included;

                  const isApplicantInJob = date_included !== null && date_included !== undefined;

                  let buttonMessage = (
                    <FormattedMessage id="ButtonApplyApplicant.label_includeApplicant" />
                  );

                  if (date_included) {
                    buttonMessage = (
                      <FormattedMessage
                        id="ButtonApplyApplicant.label_includedApplicant"
                        values={{
                          date: Moment(result.date_included).fromNow()
                        }}
                      />
                    );
                  }
                  const selected = this.state.selectedApplicants.some(
                    (currObj) => currObj.id === result.id
                  );

                  const disabled =
                    (selected && this.state.submiting) ||
                    (result.applicant_id === this.state.currentApplicantSubmitted &&
                      this.state.submiting);

                  const tooltipMsg = (
                    <FormattedMessage
                      id="ApplicantsSearch.msg_tooltipScreeningStage"
                      defaultMessage="Send to Screening stage"
                    />
                  );

                  const buttonDisabled = isApplicantInJob || !this.state.current_job_id;
                  const extracting = extractingApplicantsIds.includes(result.id);
                  const loadingAction = (disabled && !isApplicantInJob) || extracting;

                  const applyButton = buttonDisabled ? (
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => this.handleIncludeApplicant(result.applicant_id)}
                      disabled={buttonDisabled}
                      style={{
                        minHeight: '32px',
                        minWidth: '84px',
                        width: '100%',
                        textWrap: 'nowrap'
                      }}
                      classes={{
                        label: classes.buttonLabel
                      }}
                    >
                      {loadingAction ? (
                        <CircularProgress
                          color="primary"
                          className={classes.buttonProgress}
                          size="1.125rem"
                        />
                      ) : (
                        buttonMessage
                      )}
                    </Button>
                  ) : (
                    <Tooltip title={tooltipMsg}>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => this.handleIncludeApplicant(result.applicant_id)}
                        disabled={buttonDisabled}
                        style={{
                          minHeight: '32px',
                          minWidth: '84px',
                          width: '100%',
                          textWrap: 'nowrap'
                        }}
                        classes={{
                          label: classes.buttonLabel
                        }}
                      >
                        {loadingAction ? (
                          <CircularProgress
                            color="primary"
                            className={classes.buttonProgress}
                            size="1.125rem"
                          />
                        ) : (
                          buttonMessage
                        )}
                      </Button>
                    </Tooltip>
                  );

                  const selectionDisabled = isApplicantInJob || !this.state.current_job_id;

                  return (
                    <>
                      <Box
                        style={{
                          marginBottom: '0.5rem'
                        }}
                      >
                        <CardBase
                          applicant={result}
                          onClick={() => this.handleSelectCVProfile(result.id)}
                          handleSelectApplicant={() => this.handleSelectApplicant(result)}
                          variant="applicantSearch"
                          relevantInfo={relevantInfo}
                          location={location}
                          jobsCount={result.jobsCount}
                          viewed={viewed}
                          title={title}
                          //isApplicantInJob={isApplicantInJob}
                          selectionDisabled={selectionDisabled}
                          disabled={false}
                          active={true}
                          selected={selected}
                          buttonMainAction={applyButton}
                          handleUpdateFavorites={(applicant_id, favorites) =>
                            this.handleUpdateApplicantFavorites(applicant_id, favorites)
                          }
                        />

                        <Box id="union"></Box>
                      </Box>
                    </>
                  );
                })}
              </Box>
            </Box>

            <Box width="50%" pl={0}>
              {this.state.outsideOfPipeline && this.state.results.length > 4 ? (
                <Sticky
                  stickyStyle={{
                    overflowY: selectedProfile ? 'scroll' : 'hidden',
                    top: '118px',
                    zIndex: 100,
                    height: selectedProfile ? 'calc(100vh - 118px)' : ''
                  }}
                >
                  {!selectedProfile ? (
                    <Fade in={!!selectedProfile === false} timeout={300}>
                      <Box>
                        <Box
                          style={{
                            paddingTop: !selectedProfile ? '12rem' : '0'
                          }}
                        >
                          <Instructions
                            icon={<InstructionsResume />}
                            helperText={
                              <FormattedMessage
                                id="ApplicantsSearch.helper_text"
                                defaultMessage="Click the card on the left to view details."
                              />
                            }
                          />{' '}
                        </Box>
                      </Box>
                    </Fade>
                  ) : null}

                  {selectedProfile && selectedProfile.cv_profile ? (
                    <Fade in={!!selectedProfile} timeout={200}>
                      <Box>
                        <CVProfileInfo
                          profile={selectedProfile}
                          handleClose={this.handleCloseCV}
                          job={job}
                          handleUpdateFavorites={(applicant_id, favorites) =>
                            this.handleUpdateApplicantFavorites(applicant_id, favorites)
                          }
                        />
                      </Box>
                    </Fade>
                  ) : null}
                </Sticky>
              ) : (
                <Box
                  style={{
                    overflowY: selectedProfile ? 'scroll' : 'hidden',
                    position: 'sticky',
                    top: '70px',
                    height: selectedProfile ? 'calc(100vh - 62px)' : ''
                  }}
                >
                  {!selectedProfile ? (
                    <Fade in={!!selectedProfile === false} timeout={300}>
                      <Box>
                        <Box
                          style={{
                            paddingTop: !selectedProfile ? '12rem' : '0'
                          }}
                        >
                          <Instructions
                            icon={<InstructionsResume />}
                            helperText={
                              <FormattedMessage
                                id="ApplicantsSearch.helper_text"
                                defaultMessage="Click the card on the left to view details."
                              />
                            }
                          />{' '}
                        </Box>
                      </Box>
                    </Fade>
                  ) : null}

                  {selectedProfile && selectedProfile.cv_profile ? (
                    <Fade in={!!selectedProfile} timeout={200}>
                      <Box>
                        <CVProfileInfo
                          profile={selectedProfile}
                          handleClose={this.handleCloseCV}
                          job={job}
                        />
                      </Box>
                    </Fade>
                  ) : null}
                </Box>
              )}
            </Box>
          </Box>
        </>
      );
    };

    const countContainerElement = (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width={this.state.outsideOfPipeline ? '100%' : '50%'}
        px={2.5}
      >
        <Box display="flex" alignItems="center">
          <Box mr={3}>
            <Checkbox
              checked={this.state.selectAll}
              value={this.state.selectAll}
              onChange={this.handleSelectAllApplicants}
              name="selectAll"
              style={{
                padding: '12px 0'
              }}
              color="primary"
              disabled={this.state.results.length === 0 || !this.state.current_job_id}
              indeterminate={
                this.state.selectedApplicants.length > 0 &&
                this.state.selectedApplicants.length < this.state.results.length
              }
            />
          </Box>
          <Box>
            <Typography
              style={{
                fontWeight: 'bold',
                margin: '0 0.5rem 0 0',
                whiteSpace: 'nowrap'
              }}
            >
              <FormattedMessage
                id="ApplicantsSearch.label_results"
                defaultMessage="Search Results"
              />
            </Typography>
          </Box>
          <Divider
            style={{
              height: '1.25rem'
            }}
            orientation="vertical"
          />
          <Box>
            <Typography
              className="text-secondary"
              style={{
                marginLeft: '0.5rem',
                whiteSpace: 'nowrap'
              }}
            >
              <FormattedMessage
                id="Pipeline.selectedRecords"
                values={{
                  selected: this.state.selectedApplicants.length,
                  total: this.state.total
                }}
              />
            </Typography>
          </Box>
        </Box>

        {this.state.outsideOfPipeline ? (
          <Box zIndex={10} width="100%" mx={1}>
            <SelectJob handleSelectJob={this.handleSelectJob} />
          </Box>
        ) : null}
        <Box minWidth={this.state.outsideOfPipeline ? '190px' : '0px'}>
          <Button
            variant={includeManyVariant}
            disabled={includeManyDisabled}
            color="primary"
            onClick={this.handleIncludeMany}
            classes={{
              label: classes.buttonLabel
            }}
          >
            <FormattedMessage
              id="ApplicantsSearch.label_includeAll"
              defaultMessage="Include in Job"
            />
          </Button>
        </Box>
      </Box>
    );

    return (
      <>
        <Box className={`${classes.whiteContainer} ${classes.searchContainer}`}>
          <form onSubmit={(e) => this.onSearch(e)}>
            <Box width="100%" display="flex" alignItems="center" px="8.75rem">
              <Box width="100%">
                <OutlinedInput
                  id="query"
                  name="query"
                  className={classes.searchInput}
                  value={this.state.query}
                  onChange={this.handleChange}
                  fullWidth
                  disabled={this.state.loading || this.state.expandedFilters === true}
                  placeholder={this.props.intl.formatMessage({
                    id: 'ApplicantsSearch.placeholder_search'
                  })}
                  startAdornment={
                    <InputAdornment position="start" style={{ margin: 0 }}>
                      <SearchIcon
                        color="primary"
                        style={{
                          fontSize: '16px'
                        }}
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{
                        height: '42px',
                        width: '120px'
                      }}
                      disabled={
                        this.state.loading ||
                        this.state.expandedFilters === true ||
                        this.state.query === ''
                      }
                    >
                      <FormattedMessage id="ApplicantsSearch.buttonSearch" />
                    </Button>
                  }
                  style={{
                    borderRadius: 64
                  }}
                />
              </Box>
              <Box className={classes.advancedSearchContainer}>
                <Button
                  variant="text"
                  size="small"
                  onClick={this.handleOpenExpandedFilters}
                  color="primary"
                  style={{
                    padding: 0,
                    fontWeight: 400
                  }}
                  endIcon={
                    <ExpandMoreIcon
                      className={clsx(classes.expandFilters, {
                        [classes.expandFiltersOpen]: this.state.expandedFilters
                      })}
                    />
                  }
                >
                  <FormattedMessage
                    id="ApplicantsSearch.buttonAdvancedSearch"
                    defaultMessage="Advanced Search"
                  />
                </Button>
              </Box>
            </Box>
          </form>

          <Collapse in={this.state.expandedFilters} timeout="auto">
            <Box display="flex" alignItems="center" flexDirection="column" mb={2} mt={2}>
              <Box className={classes.checkFiltersContainer}>
                <Box>
                  <ButtonFilterAllFavorites
                    selected={this.state.filters.all_favorites}
                    handlerUpdate={(favorite, option) => this.updateFavorites(favorite, option)}
                  />
                </Box>
                <Box>
                  <ButtonFilterMyFavorites
                    selected={this.state.filters.my_favorites}
                    handlerUpdate={(favorite, option) => this.updateFavorites(favorite, option)}
                  />
                </Box>
                <Box>
                  <ButtonFilterInternal
                    selected={this.state.filters.internal}
                    handlerUpdate={(internal) => this.updateInternal(internal)}
                  />
                </Box>
                <Box>
                  <ButtonFilterSavedForLater
                    selected={this.state.filters.saved_later}
                    handlerUpdate={(savedOption) => this.updateSavedForLater(savedOption)}
                  />
                </Box>
                <Box>
                  <ButtonFilterPWD
                    selected={this.state.filters.pwd}
                    handlerUpdate={(pwd) => this.updatePWD(pwd)}
                  />
                </Box>
                <Box>
                  <ButtonFilterNotHired
                    selected={this.state.filters.not_hired}
                    handlerUpdate={(not_hired) => this.updateNotHired(not_hired)}
                  />
                </Box>
              </Box>
              <Box className={classes.advancedFiltersContainer}>
                <Box className={classes.mainButtonContainer}>
                  <Typography
                    variant="subtitle1"
                    className={`${
                      this.state.edit_personal.length > 0 ? classes.filterLabelActive : 'text-muted'
                    } ${classes.filterLabel}`}
                  >
                    <FormattedMessage id="ApplicantsSearch.field_personal" />
                  </Typography>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="edit_personal"
                    name="edit_personal"
                    options={[]}
                    value={this.state.edit_personal}
                    required={true}
                    fullWidth
                    //maxRows={8}
                    disabled={this.state.loading}
                    onChange={(e, value) => this.handleChangePersonalInfo(value)}
                    onBlur={this.onBlurPersonalInfo}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            style={{
                              backgroundColor: `${
                                String(option).startsWith('-')
                                  ? CONST.APP_COLORS['danger-dark']
                                  : CONST.APP_COLORS['gray-lighter']
                              }`
                            }}
                            displaySize="small"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          className: classes.advancedSearchInput
                        }}
                        variant="outlined"
                        placeholder={
                          this.state.edit_personal.length > 0
                            ? ''
                            : this.props.intl.formatMessage({
                                id: 'ApplicantsSearch.placeholder_personal'
                              })
                        }
                        //margin="dense"
                      />
                    )}
                    //onChange={this.handleChangeTag}
                  />
                </Box>
                <Box className={classes.mainButtonContainer}>
                  <Typography
                    variant="subtitle1"
                    className={`${
                      this.state.edit_education.length > 0
                        ? classes.filterLabelActive
                        : 'text-muted'
                    } ${classes.filterLabel}`}
                  >
                    <FormattedMessage id="ApplicantsSearch.field_education" />
                  </Typography>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="edit_education"
                    name="edit_education"
                    options={[]}
                    value={this.state.edit_education}
                    required={true}
                    fullWidth
                    maxRows={8}
                    disabled={this.state.loading}
                    onChange={(e, value) => this.handleChangeEducation(value)}
                    onBlur={this.onBlurEducation}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            style={{
                              backgroundColor: `${
                                String(option).startsWith('-')
                                  ? CONST.APP_COLORS['danger-dark']
                                  : CONST.APP_COLORS['gray-lighter']
                              }`,
                              color: CONST.APP_COLORS['gray-darker']
                            }}
                            displaySize="small"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          className: classes.advancedSearchInput
                        }}
                        variant="outlined"
                        placeholder={
                          this.state.edit_education.length > 0
                            ? ''
                            : this.props.intl.formatMessage({
                                id: 'ApplicantsSearch.placeholder_education'
                              })
                        }
                      />
                    )}
                    //onChange={this.handleChangeTag}
                  />
                </Box>
                <Box className={classes.mainButtonContainer}>
                  <Typography
                    variant="subtitle1"
                    className={`${
                      this.state.edit_position.length > 0 ? classes.filterLabelActive : 'text-muted'
                    } ${classes.filterLabel}`}
                  >
                    <FormattedMessage id="ApplicantsSearch.field_position" />
                  </Typography>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="edit_position"
                    name="edit_position"
                    options={[]}
                    value={this.state.edit_position}
                    //defaultValue={this.state.job_title}
                    onChange={(e, value) => this.handleChangePosition(value)}
                    required={true}
                    fullWidth
                    disabled={this.state.loading}
                    onBlur={this.onBlurPosition}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            style={{
                              backgroundColor: `${
                                String(option).startsWith('-')
                                  ? CONST.APP_COLORS['danger-dark']
                                  : CONST.APP_COLORS['gray-lighter']
                              }`,
                              color: CONST.APP_COLORS['gray-darker']
                            }}
                            displaySize="small"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          className: classes.advancedSearchInput
                        }}
                        variant="outlined"
                        placeholder={
                          this.state.edit_position.length > 0
                            ? ''
                            : this.props.intl.formatMessage({
                                id: 'ApplicantsSearch.placeholder_position'
                              })
                        }
                      />
                    )}
                    //onChange={this.handleChangeTag}
                  />
                </Box>
                <Box className={classes.mainButtonContainer}>
                  <Typography
                    variant="subtitle1"
                    className={`${
                      this.state.edit_company.length > 0 ? classes.filterLabelActive : 'text-muted'
                    } ${classes.filterLabel}`}
                  >
                    <FormattedMessage id="ApplicantsSearch.field_company" />
                  </Typography>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="edit_company"
                    name="edit_company"
                    options={[]}
                    value={this.state.edit_company}
                    fullWidth={true}
                    required={true}
                    disabled={this.state.loading}
                    onChange={(e, value) => this.handleChangeCompany(value)}
                    onBlur={this.onBlurCompany}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            style={{
                              backgroundColor: `${
                                String(option).startsWith('-')
                                  ? CONST.APP_COLORS['danger-dark']
                                  : CONST.APP_COLORS['gray-lighter']
                              }`
                            }}
                            displaySize="small"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          className: classes.advancedSearchInput
                        }}
                        variant="outlined"
                        placeholder={
                          this.state.edit_company.length > 0
                            ? ''
                            : this.props.intl.formatMessage({
                                id: 'ApplicantsSearch.placeholder_company'
                              })
                        }
                      />
                    )}
                    //onChange={this.handleChangeTag}
                  />
                </Box>

                <Box className={classes.mainButtonContainer}>
                  <Typography
                    variant="subtitle1"
                    className={`${
                      this.state.edit_certification.length > 0
                        ? classes.filterLabelActive
                        : 'text-muted'
                    } ${classes.filterLabel}`}
                  >
                    <FormattedMessage id="ApplicantsSearch.field_certifications" />
                  </Typography>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="edit_certification"
                    name="edit_certification"
                    options={[]}
                    value={this.state.edit_certification}
                    required={true}
                    fullWidth
                    maxRows={8}
                    disabled={this.state.loading}
                    onChange={(e, value) => this.handleChangeCertification(value)}
                    onBlur={this.onBlurCertification}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            style={{
                              backgroundColor: `${
                                String(option).startsWith('-')
                                  ? CONST.APP_COLORS['danger-dark']
                                  : CONST.APP_COLORS['gray-lighter']
                              }`,
                              color: CONST.APP_COLORS['gray-darker']
                            }}
                            displaySize="small"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          className: classes.advancedSearchInput
                        }}
                        variant="outlined"
                        placeholder={
                          this.state.edit_certification.length > 0
                            ? ''
                            : this.props.intl.formatMessage({
                                id: 'ApplicantsSearch.placeholder_certifications'
                              })
                        }
                      />
                    )}
                    //onChange={this.handleChangeTag}
                  />
                </Box>
                <Box className={classes.mainButtonContainer}>
                  <Typography
                    variant="subtitle1"
                    className={`${
                      this.state.edit_languages.length > 0
                        ? classes.filterLabelActive
                        : 'text-muted'
                    } ${classes.filterLabel}`}
                  >
                    <FormattedMessage id="ApplicantsSearch.field_languages" />
                  </Typography>
                  <Autocomplete
                    id="idioms"
                    fullWidth
                    options={this.state.idiomsList}
                    classes={{
                      option: classes.option
                    }}
                    multiple
                    value={this.state.edit_languages}
                    onChange={(e, value) => this.handleChangeLanguages(value)}
                    getOptionLabel={(idiom) => Capitalize(idiom.lemma)}
                    getOptionSelected={(option, value) => option.code === value.code}
                    popupIcon={
                      <ArrowDropDownIcon
                        style={{
                          fontSize: '20px'
                        }}
                      />
                    }
                    disableCloseOnSelect
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            displaySize="small"
                            size="small"
                            label={Capitalize(option.lemma)}
                            {...getTagProps({ index })}
                            style={{
                              background: CONST.APP_COLORS['gray-lighter'],
                              color: CONST.APP_COLORS['gray-darker']
                            }}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          this.state.edit_languages.length > 0
                            ? ''
                            : this.props.intl.formatMessage({
                                id: 'ApplicantsSearch.placeholder_languages'
                              })
                        }
                        InputProps={{
                          ...params.InputProps,
                          className: `${classes.advancedSearchInput} ${classes.countrySearchInput}`
                        }}
                        variant="outlined"
                      />
                    )}
                    disabled={this.state.idiomsList.length === 0 || this.state.loading}
                  />
                </Box>

                <Box className={classes.mainButtonContainer}>
                  <Typography
                    variant="subtitle1"
                    className={`${
                      this.state.edit_keywords.length > 0 ? classes.filterLabelActive : 'text-muted'
                    } ${classes.filterLabel}`}
                  >
                    <FormattedMessage id="ApplicantsSearch.field_keywords" />
                  </Typography>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="edit_keywords"
                    name="edit_keywords"
                    options={[]}
                    value={this.state.edit_keywords}
                    required={true}
                    fullWidth
                    //maxRows={8}
                    disabled={this.state.loading}
                    onChange={(e, value) => this.handleChangeKeywords(value)}
                    onBlur={this.onBlurKeywords}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            style={{
                              backgroundColor: `${
                                String(option).startsWith('-')
                                  ? CONST.APP_COLORS['danger-dark']
                                  : CONST.APP_COLORS['gray-lighter']
                              }`
                            }}
                            displaySize="small"
                            size="small"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          className: classes.advancedSearchInput
                        }}
                        variant="outlined"
                        placeholder={
                          this.state.edit_keywords.length > 0
                            ? ''
                            : this.props.intl.formatMessage({
                                id: 'ApplicantsSearch.placeholder_keywords'
                              })
                        }
                        //margin="dense"
                      />
                    )}
                    //onChange={this.handleChangeTag}
                  />
                </Box>

                <Box className={classes.mainButtonContainer}>
                  <Typography
                    variant="subtitle1"
                    className={`${
                      this.state.selectedCountries.length > 0
                        ? classes.filterLabelActive
                        : 'text-muted'
                    } ${classes.filterLabel}`}
                  >
                    <FormattedMessage id="ApplicantsSearch.field_countries" />
                  </Typography>
                  <Autocomplete
                    id="country"
                    fullWidth
                    options={this.state.countryList}
                    classes={{
                      option: classes.option
                    }}
                    multiple
                    value={selectedCountries}
                    onChange={this.handleChangeSelectCountry}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderOption={(option) => (
                      <React.Fragment>
                        <span>
                          {AddressController.countryToFlag(option.value) !== option.value
                            ? AddressController.countryToFlag(option.value)
                            : ''}
                        </span>{' '}
                        {option.label} ({option.value})
                      </React.Fragment>
                    )}
                    popupIcon={
                      <ArrowDropDownIcon
                        style={{
                          fontSize: '20px'
                        }}
                      />
                    }
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          selectedCountries.length > 0
                            ? ''
                            : this.props.intl.formatMessage({
                                id: 'ApplicantsSearch.placeholder_countries'
                              })
                        }
                        InputProps={{
                          ...params.InputProps,
                          className: `${classes.advancedSearchInput} ${classes.countrySearchInput}`,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loadingCountries ? (
                                <CircularProgress color="secondary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                        variant="outlined"
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            style={{
                              backgroundColor: `${
                                String(option).startsWith('-')
                                  ? CONST.APP_COLORS['danger-dark']
                                  : CONST.APP_COLORS['gray-lighter']
                              }`,
                              color: CONST.APP_COLORS['gray-darker']
                            }}
                            displaySize="small"
                            size="small"
                            label={
                              <>
                                <span>
                                  {AddressController.countryToFlag(option.value) !== option.value
                                    ? AddressController.countryToFlag(option.value)
                                    : ''}
                                </span>{' '}
                                {option.label}
                              </>
                            }
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    disabled={this.state.loadingCountries}
                  />
                </Box>

                <Box className={classes.mainButtonContainer}>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      className={`${
                        this.state.edit_location.length > 0
                          ? classes.filterLabelActive
                          : 'text-muted'
                      } ${classes.filterLabel}`}
                    >
                      <FormattedMessage
                        id="ApplicantSearch.field_location"
                        defaultMessage="Location"
                      />
                    </Typography>
                    <Autocomplete
                      multiple
                      freeSolo
                      id="edit_location"
                      name="edit_location"
                      options={[]}
                      value={this.state.edit_location}
                      //defaultValue={this.state.job_title}
                      onChange={(e, value) => this.handleChangeLocation(e, value)}
                      onBlur={this.onBlurLocation}
                      required={true}
                      fullWidth
                      style={{
                        padding: 0
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            className: classes.advancedSearchInput
                          }}
                          placeholder={
                            this.state.edit_location.length > 0
                              ? ''
                              : this.props.intl.formatMessage({
                                  id: 'ApplicantsSearch.placeholder_location'
                                })
                          }
                          variant="outlined"
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          return (
                            <Chip
                              displaySize="small"
                              size="small"
                              label={option}
                              {...getTagProps({ index })}
                              style={{
                                background: CONST.APP_COLORS['gray-lighter'],
                                color: CONST.APP_COLORS['gray-darker']
                              }}
                            />
                          );
                        })
                      }
                      //onChange={this.handleChangeTag}
                    />
                  </Box>
                  <Box
                    className={`${classes.checkButton} ${
                      this.state.edit_location.length === 0 ? classes.disabledCheckButton : ''
                    }`}
                    onClick={this.handleEnableRangeSearch}
                  >
                    <Checkbox
                      checked={this.state.near_cities}
                      color="primary"
                      style={{
                        padding: 8
                      }}
                      disabled={this.state.edit_location.length === 0}
                    />
                    <Typography className={classes.checkButtonLabel}>
                      <FormattedMessage
                        id="ApplicantsSearch.label_enableRangeSearch"
                        defaultMessage="Nearby cities"
                      />
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.mainButtonContainer}>
                  <ButtonFilterPeriod
                    size="large"
                    isApplicant={true}
                    selected={this.state.period_option_selected}
                    required={false}
                    label={
                      <FormattedMessage id="ApplicantsFiltersContainer.label_filterApplicants" />
                    }
                    handlerUpdate={(option, optionIndex) => this.updatePeriod(option, optionIndex)}
                  />
                </Box>
                <Box className={classes.mainButtonContainer}>
                  <ButtonFilterApplicantTags
                    size="large"
                    selected={this.state.filters.applicant_tags}
                    handlerUpdate={(applicantTags) => this.updateApplicantTags(applicantTags)}
                  />
                </Box>

                <Box className={classes.mainButtonContainer}>
                  <ButtonFilterAccountPage
                    size="large"
                    component="ApplicantsSearch"
                    selected={this.state.filters.account_pages}
                    handlerUpdate={(pages) => this.updateAccountPage(pages)}
                  />
                </Box>
              </Box>
              <Box px={3} mb={3} width="100%">
                <Divider light />
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onSearch}
                  style={{
                    padding: '8px 14px'
                  }}
                  classes={{
                    label: classes.advancedSearchLabel
                  }}
                  disabled={this.state.loading || advancedSearchDisabled}
                >
                  <FormattedMessage id="ApplicantsSearch.buttonAdvancedSearch" />
                </Button>
              </Box>
            </Box>
          </Collapse>

          {this.state.results.length === 0 && !this.state.loading && !this.state.firstLoaded ? (
            <>
              <Box px={3} mt={this.state.expandedFilters ? 1.5 : 3} mb={4.5}>
                <Divider light />
              </Box>
              <Box px="5.25rem">
                <SearchInstructions variant="applicantsSearch" />
              </Box>
            </>
          ) : null}
        </Box>

        {this.state.results.length > 0 || this.state.firstLoaded ? (
          this.state.outsideOfPipeline ? (
            <Sticky
              stickyStyle={{
                top: 48, // Navbar is 48px
                zIndex: 100,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
              }}
            >
              <Box className={`${classes.whiteContainer} ${classes.countContainer}`}>
                {countContainerElement}
              </Box>
            </Sticky>
          ) : (
            <Box
              className={`${classes.whiteContainer} ${classes.countContainer} ${classes.stickyCountContainer}`}
            >
              {countContainerElement}
            </Box>
          )
        ) : null}
        <Box className={classes.resultsContainer}>
          {this.state.results.length > 0 ? <>{displayResults()}</> : null}
          {this.state.loading ? <>{displaySkeletons()}</> : null}
          {this.state.firstLoaded ? (
            <Box
              className={classes.loadMoreContainer}
              pb={this.state.outsideOfPipeline ? 0 : '1rem'}
            >
              <Divider
                style={{
                  width: '100%'
                }}
                light
              />
              <Box ml={1} mr={1} display="flex" alignItems="center" justifyContent="space-between">
                {this.state.hasMore ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.handleSearchMore}
                    className={classes.searchMoreButton}
                    classes={{
                      label: classes.searchMoreLabel
                    }}
                    disabled={this.state.loading}
                  >
                    <FormattedMessage
                      id="ApplicantSearch.button_loadMore"
                      defaultMessage="Load more..."
                    />
                  </Button>
                ) : (
                  <Typography
                    className={classes.noMoreResultsText}
                    style={{
                      display: this.state.loading ? 'none' : 'block'
                    }}
                  >
                    <FormattedMessage
                      id="ApplicantSearch.msg_noMoreResults"
                      defaultMessage="No more results"
                    />
                  </Typography>
                )}
              </Box>
              <Divider
                style={{
                  width: '100%'
                }}
                light
              />
            </Box>
          ) : null}
        </Box>
        {modalWarningTransferActive && (
          <>
            <ModalTransferWarning
              open={this.state.modalTransferOpen}
              infoApplicants={this.state.modalTransferData}
              handleTransferModalClose={this.handleTransferModalClose}
            />
          </>
        )}
      </>
    );
  }
}

ApplicantsSearch.propTypes = {
  accountInfo: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo,
  user: state.user.user
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(ApplicantsSearch)));
