import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import 'moment/locale/pt-br'; // FIXME locale
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ApplicantTags from '../Tags/ApplicantTags';
import Typography from '@material-ui/core/Typography';
import OtherJobs from '../../../Applicant2/ApplicantCard/OtherJobs/OtherJobs';
import ChipOrigin from '../../../Applicant2/ApplicantCard/ChipOrigin';
import CardAvatar from '../../../Applicant2/ApplicantCard/CardAvatar';
import ChipInternal from '../../../Applicant2/ApplicantCard/ChipInternal';
import ChipHired from '../../../Applicant2/ApplicantCard/ChipHired';
import IconLinkedin from '../../../Applicant2/ApplicantCard/IconLinkedin';
import MoreActionsCV from '../MoreActionsCV/MoreActionsCV';
import SalaryInfoEdit from '../SalaryInfo/SalaryInfoEdit';
import Utils from '../../../Common/Utils';
import ApplicantTagsSelection from '../../Tags/ApplicantTagsSelection';
import Button from '@material-ui/core/Button';
import ChipPwd from '../../../Applicant2/ApplicantCard/ChipPwd';

const useStyles = (theme) => ({
  avatarContainer: {
    marginRight: '1.5rem',
    display: 'flex',
    alignItems: 'center'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  applyContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
      overflow: 'hidden'
    }
  },
  containerNameAndSalary: {
    overflowX: 'hidden',
    maxWidth: '80%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '95%'
    }
  },
  hiddenMobile: {
    '@media (max-width: 800px)': {
      display: 'none',
      overflow: 'hidden'
    }
  },
  wrappedText: {
    margin: 0,
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  darkText: {
    color: theme.palette.gray.darker
  },
  lightText: {
    color: theme.palette.gray.darkerMedium
  },
  lightBoldSmallText: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '14px',
    fontWeight: 700
  },
  smallText: {
    fontSize: '12px',
    lineHeight: 'normal'
  },
  textNoWrap: {
    whiteSpace: 'nowrap'
  },
  jobsListDarkElement: {
    fontSize: '14px',
    color: theme.palette.gray.darker
  },
  jobsListLightElement: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium
  },
  chipsTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  bottomContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  mainContainer: {},
  viewApplicantButton: {
    minHeight: '32px',
    minWidth: '84px',
    width: '100%'
  },
  paper: {
    marginTop: '1rem'
  }
});

const StyledCard = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    position: 'relative',
    borderRadius: '10px',
    width: '100%',
    padding: '1rem 1rem 0 1rem'
  }
}))(Card);

export class CardDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Props from parent
      applicant: props.applicant,
      content: props.content,
      actions: props.actions,
      title: props.title,
      relevantInfo: props.relevantInfo,
      location: props.location,
      possibleEmails: props.possibleEmails ? props.possibleEmails : [],
      selectionDisabled: props.selectionDisabled,
      active: props.active ? props.active : true,
      disabled: props.disabled,
      buttonMainAction: props.buttonMainAction,
      viewed: props.viewed,
      selected: props.selected ? props.selected : false,
      readonly: props.readonly,
      print: props.print,
      shareLinkDetails: props.shareLinkDetails,
      inSharePage: props.inSharePage,
      activity_update_cv: props.activity_update_cv,
      // Internal props
      // Popover
      jobsAnchorEl: null,
      emailsAnchorEl: null,
      notesAnchorEl: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }

    if (prevProps.content !== this.props.content) {
      this.setState({
        content: this.props.content
      });
    }

    if (prevProps.actions !== this.props.actions) {
      this.setState({
        actions: this.props.actions
      });
    }

    if (prevProps.title !== this.props.title) {
      this.setState({
        title: this.props.title
      });
    }
    if (prevProps.activity_update_cv !== this.props.activity_update_cv) {
      this.setState({
        activity_update_cv: this.props.activity_update_cv
      });
    }

    if (prevProps.relevantInfo !== this.props.relevantInfo) {
      this.setState({
        relevantInfo: this.props.relevantInfo
      });
    }

    if (prevProps.location !== this.props.location) {
      this.setState({
        location: this.props.location
      });
    }

    /* if (prevProps.isApplicantInJob !== this.props.isApplicantInJob) {
      this.setState({
        isApplicantInJob: this.props.isApplicantInJob
      });
    } */

    if (prevProps.selectionDisabled !== this.props.selectionDisabled) {
      this.setState({
        selectionDisabled: this.props.selectionDisabled
      });
    }

    if (prevProps.active !== this.props.active) {
      this.setState({
        active: this.props.active
      });
    }

    if (prevProps.possibleEmails !== this.props.possibleEmails) {
      this.setState({
        possibleEmails: this.props.possibleEmails
      });
    }

    if (prevProps.disabled !== this.props.disabled) {
      this.setState({
        disabled: this.props.disabled
      });
    }

    if (prevProps.buttonMainAction !== this.props.buttonMainAction) {
      this.setState({
        buttonMainAction: this.props.buttonMainAction
      });
    }

    if (prevProps.viewed !== this.props.viewed) {
      this.setState({
        viewed: this.props.viewed
      });
    }

    if (prevProps.selected !== this.props.selected) {
      this.setState({
        selected: this.props.selected
      });
    }
  }

  handleSelect = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value
      },
      () => {
        if (this.props.handleSelectApplicant) {
          this.props.handleSelectApplicant();
        }
      }
    );
  };

  handleOpenJobs = (e) => {
    this.setState({
      jobsAnchorEl: e.currentTarget
    });
  };

  handleCloseJobs = () => {
    this.setState({
      jobsAnchorEl: null
    });
  };

  handleOpenEmails = (e) => {
    this.setState({
      emailsAnchorEl: e.currentTarget
    });
  };

  handleCloseEmails = () => {
    this.setState({
      emailsAnchorEl: null
    });
  };

  handleOpenNotes = (e) => {
    this.setState({
      notesAnchorEl: e.currentTarget
    });
  };

  handleCloseNotes = () => {
    this.setState({
      notesAnchorEl: null
    });
  };

  handleUpdateSalaryInfo = (salary_info) => {
    const updatedApplicant = {
      ...this.state.applicant,
      salary_info: salary_info
    };

    this.setState(
      {
        applicant: updatedApplicant
      },
      () => {
        if (this.props.handleUpdateSalaryInfo) {
          this.props.handleUpdateSalaryInfo(salary_info);
        }
        this.handleSetForRefresh();
      }
    );
  };

  handleUpdateInterviews = () => {
    if (this.props.handleUpdateInterviews) {
      this.props.handleUpdateInterviews();
    }
  };

  handleUpdateVideoInterviews = () => {
    if (this.props.handleUpdateVideoInterviews) {
      this.props.handleUpdateVideoInterviews();
    }
  };

  handleUpdateTests = () => {
    if (this.props.handleUpdateTests) {
      this.props.handleUpdateTests();
    }
  };

  handleOpenApplicant = () => {
    if (this.props.handleOpen) {
      this.props.handleOpen();
    }
  };

  handleSetForRefresh = () => {
    if (this.props.handleSetForRefresh) {
      this.props.handleSetForRefresh();
    }
  };

  render() {
    const { classes } = this.props;
    const applicant = this.state.applicant;
    const activity_update_cv = this.state.activity_update_cv;

    const jobsOpen = Boolean(this.state.jobsAnchorEl);

    const jobsCount = this.props.jobsCount;
    const viewed = this.state.viewed;
    const shareLinkDetails = this.state.shareLinkDetails;

    const salary_info = applicant.salary_info;
    let hasSalaryInfo = false;

    if (salary_info && Object.keys(salary_info).length > 0) {
      const isMinValueValid =
        salary_info.min !== undefined && salary_info.min !== null && salary_info.min > 0;
      const isCurrencyValid =
        salary_info.currency !== undefined &&
        salary_info.currency !== null &&
        salary_info.currency !== '';
      const isDurationValid =
        salary_info.duration !== undefined &&
        salary_info.duration !== null &&
        salary_info.duration !== '';

      if (isMinValueValid && isCurrencyValid && isDurationValid) {
        hasSalaryInfo = true;
      }
    }

    let chipCount = 1;

    if (applicant.pwd) {
      chipCount++;
    }

    if (applicant.hired) {
      chipCount++;
    }

    if (applicant.internal) {
      chipCount++;
    }

    const chipsTitle = (
      <Box className={classes.chipsTitleContainer}>
        {applicant.pwd && applicant.pwd != 'no' ? <ChipPwd /> : null}
        {applicant.hired ? <ChipHired hired={true} /> : null}
        <ChipOrigin applicantInfo={applicant} />
        {applicant.internal ? (
          <ChipInternal
            internal_verified={applicant.internal_verified}
            employee_id={applicant.employee_id}
            employee_email={applicant.email}
          />
        ) : null}
      </Box>
    );

    const displayName = () => {
      if (shareLinkDetails) {
        if (shareLinkDetails.privacy) {
          return Utils.hideInfo(applicant.name);
        }
      }
      return applicant.name;
    };

    if (applicant) {
      const otherTags = applicant.person ? applicant.person.otherTags : [];

      return (
        <StyledCard>
          <Box display="flex" justifyContent="space-between" minWidth={0} mb={2}>
            <Box display="flex" alignItems="flex-start" width="100%" minWidth={0}>
              {!shareLinkDetails || !shareLinkDetails.privacy ? (
                <Box className={classes.avatarContainer}>
                  <CardAvatar
                    applicant={applicant}
                    variant="detail"
                    readonly={this.state.readonly || this.state.print}
                  />
                </Box>
              ) : null}
              <Box width="100%" minWidth={0}>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" flexDirection="column" minWidth={0}>
                    <Box display="flex" alignItems="center">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="95%"
                        mb={2}
                        mr={1}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          className={classes.containerNameAndSalary}
                        >
                          <Typography
                            className={classes.darkText}
                            style={{
                              fontWeight: 'bold',
                              fontSize: '22px',
                              lineHeight: 'normal',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              minWidth: '30%',
                              overflowX: 'hidden',
                              textOverflow: 'ellipsis',
                              marginRight: 8
                            }}
                          >
                            {displayName()}
                          </Typography>
                          {(this.state.readonly || this.state.print) && !hasSalaryInfo ? null : (
                            <Box className={classes.hiddenMobile}>
                              <SalaryInfoEdit
                                applicant={applicant}
                                handleUpdateSalaryInfo={(salary_info) =>
                                  this.handleUpdateSalaryInfo(salary_info)
                                }
                                disabled={this.state.readonly || this.state.print}
                              />
                            </Box>
                          )}
                        </Box>

                        <Box className={classes.applyContainer}>
                          {/* Has jobs applications */}
                          {jobsCount > 0 && !this.state.readonly && !this.state.print ? (
                            <>
                              <Box
                                display="flex"
                                alignItems="center"
                                onClick={(e) => this.handleOpenJobs(e)}
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                <Typography
                                  className={`${classes.lightText} ${classes.smallText} ${classes.textNoWrap}`}
                                >
                                  <span>
                                    <FormattedMessage
                                      id="CardDetail.msg_appliedIn"
                                      defaultMessage="Application history:"
                                    />
                                    &nbsp;
                                  </span>
                                  <span className="text-primary">
                                    {jobsCount > 1 ? (
                                      <FormattedMessage
                                        id="CardBase.jobsCount"
                                        values={{
                                          jobsCount: jobsCount
                                        }}
                                      />
                                    ) : (
                                      <FormattedMessage
                                        id="CardBase.singleJobCount"
                                        values={{
                                          jobsCount: jobsCount
                                        }}
                                      />
                                    )}
                                  </span>
                                </Typography>
                                <KeyboardArrowDownIcon fontSize="small" color="primary" />
                              </Box>

                              <Popover
                                open={jobsOpen}
                                anchorEl={this.state.jobsAnchorEl}
                                onClose={this.handleCloseJobs}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                classes={{
                                  paper: classes.paper
                                }}
                              >
                                <Box maxHeight="440px" minWidth="320px">
                                  <OtherJobs
                                    search_parameter={applicant.person_id}
                                    job_id={applicant.job.id}
                                  />
                                </Box>
                              </Popover>
                            </>
                          ) : null}
                          {this.state.readonly && this.state.inSharePage ? (
                            <Button
                              color="primary"
                              variant="outlined"
                              size="small"
                              className={classes.viewApplicantButton}
                              onClick={this.handleOpenApplicant}
                            >
                              <FormattedMessage
                                id="CardDetail.label_buttonViewCandidate"
                                defaultMessage="View Candidate"
                              />
                            </Button>
                          ) : null}
                        </Box>
                        {/* <p>{applicant.email}</p> */}
                      </Box>
                      {this.state.readonly || this.state.print ? null : (
                        <Box mb={2}>
                          <MoreActionsCV
                            applicant={applicant}
                            loading={this.props.loading}
                            job={applicant.job}
                            activity_update_cv={activity_update_cv}
                            handleUpdateInterviews={this.handleUpdateInterviews}
                            handleUpdateVideoInterviews={this.handleUpdateVideoInterviews}
                            handleUpdateTests={this.handleUpdateTests}
                          />
                        </Box>
                      )}
                    </Box>

                    <Box className={classes.bottomContainer}>
                      {chipsTitle}
                      <Box display="flex" alignItems="center">
                        {/* <Typography
                          className="lightText"
                          style={{
                            fontSize: '12px',
                            margin: '0 0.25rem 0 0'
                          }}
                        >
                          <FormattedMessage id="JobApplicantList.table.tags" />
                        </Typography> */}
                        <ApplicantTags
                          canChangeTags={!this.state.readonly && !this.state.print}
                          applicantTags={applicant.tags}
                          applicant_id={applicant.id}
                          otherApplicantsTags={otherTags}
                          displayAll={chipCount > 2}
                          handleSetForRefresh={this.handleSetForRefresh}
                        />
                        {/* <ApplicantTagsSelection
                            canChangeTags={true}
                            applicantTags={applicant.tags}
                            applicant_id={applicant.id}
                          /> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledCard>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(CardDetail));
