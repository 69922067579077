import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as CONST from '../../Common/constants';
CONST.APP_COLORS['danger-light'];

const useStyles = (theme) => ({
  printIcon: {
    '@media print': {
      fill: theme.palette.gray.darkerMedium
    }
  }
});

class AISuggestionsIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes } = this.props;

    const variant = this.props.variant;
    const size = this.props.size ? this.props.size : 22;
    const fill = this.props.inactive
      ? CONST.APP_COLORS['gray-darker-medium']
      : CONST.APP_COLORS['success'];

    if (variant === 'outlined') {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.1795 3.95086C11.5341 3.05186 12.8064 3.05186 13.1608 3.95086L14.109 6.35484C14.2174 6.62931 14.4346 6.84658 14.7091 6.95483L17.113 7.90294C18.012 8.2575 18.012 9.52977 17.113 9.88432L14.7091 10.8325C14.4346 10.9407 14.2174 11.158 14.109 11.4324L13.161 13.8364C12.8064 14.7354 11.5341 14.7354 11.1795 13.8364L10.2315 11.4324C10.1231 11.158 9.9059 10.9407 9.63141 10.8325L7.2275 9.88432C6.32846 9.52977 6.32846 8.2575 7.2275 7.90294L9.63141 6.95483C9.9059 6.84658 10.1231 6.62931 10.2315 6.35484L11.1795 3.95086Z"
            fill="white"
          />
          <path
            d="M5.58771 12.1999C5.8494 11.5362 6.78883 11.5362 7.05053 12.1999L7.30571 12.847C7.38574 13.0497 7.5461 13.2101 7.74876 13.29L8.39575 13.5452C9.0595 13.807 9.0595 14.7463 8.39575 15.008L7.74876 15.2632C7.5461 15.3431 7.38574 15.5035 7.30571 15.7061L7.05053 16.3533C6.78883 17.017 5.8494 17.017 5.58771 16.3533L5.33252 15.7061C5.2525 15.5035 5.09213 15.3431 4.88948 15.2632L4.24232 15.008C3.57873 14.7463 3.57873 13.807 4.24232 13.5452L4.88948 13.29C5.09213 13.2101 5.2525 13.0497 5.33252 12.847L5.58771 12.1999Z"
            fill="white"
          />
          <path
            d="M11.3032 16.6546C11.4459 16.2925 11.9583 16.2925 12.1011 16.6546L12.2403 17.0075C12.2839 17.1181 12.3714 17.2056 12.4819 17.2492L12.8348 17.3884C13.1969 17.5311 13.1969 18.0435 12.8348 18.1862L12.4819 18.3255C12.3714 18.369 12.2839 18.4565 12.2403 18.5671L12.1011 18.92C11.9583 19.2821 11.4459 19.2821 11.3032 18.92L11.1639 18.5671C11.1204 18.4565 11.0329 18.369 10.9223 18.3255L10.5694 18.1862C10.2073 18.0435 10.2073 17.5311 10.5694 17.3884L10.9223 17.2492C11.0329 17.2056 11.1204 17.1181 11.1639 17.0075L11.3032 16.6546Z"
            fill="white"
          />
        </svg>
      );
    }

    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1795 3.95086C11.5341 3.05186 12.8064 3.05186 13.1608 3.95086L14.109 6.35484C14.2174 6.62931 14.4346 6.84658 14.7091 6.95483L17.113 7.90294C18.012 8.2575 18.012 9.52977 17.113 9.88432L14.7091 10.8325C14.4346 10.9407 14.2174 11.158 14.109 11.4324L13.161 13.8364C12.8064 14.7354 11.5341 14.7354 11.1795 13.8364L10.2315 11.4324C10.1231 11.158 9.9059 10.9407 9.63141 10.8325L7.2275 9.88432C6.32846 9.52977 6.32846 8.2575 7.2275 7.90294L9.63141 6.95483C9.9059 6.84658 10.1231 6.62931 10.2315 6.35484L11.1795 3.95086Z"
          fill={fill}
          className={classes.printIcon}
        />
        <path
          d="M5.58771 12.1999C5.8494 11.5362 6.78883 11.5362 7.05053 12.1999L7.30571 12.847C7.38574 13.0497 7.5461 13.2101 7.74876 13.29L8.39575 13.5452C9.0595 13.807 9.0595 14.7463 8.39575 15.008L7.74876 15.2632C7.5461 15.3431 7.38574 15.5035 7.30571 15.7061L7.05053 16.3533C6.78883 17.017 5.8494 17.017 5.58771 16.3533L5.33252 15.7061C5.2525 15.5035 5.09213 15.3431 4.88948 15.2632L4.24232 15.008C3.57873 14.7463 3.57873 13.807 4.24232 13.5452L4.88948 13.29C5.09213 13.2101 5.2525 13.0497 5.33252 12.847L5.58771 12.1999Z"
          fill={fill}
          className={classes.printIcon}
        />
        <path
          d="M11.3032 16.6546C11.4459 16.2925 11.9583 16.2925 12.1011 16.6546L12.2403 17.0075C12.2839 17.1181 12.3714 17.2056 12.4819 17.2492L12.8348 17.3884C13.1969 17.5311 13.1969 18.0435 12.8348 18.1862L12.4819 18.3255C12.3714 18.369 12.2839 18.4565 12.2403 18.5671L12.1011 18.92C11.9583 19.2821 11.4459 19.2821 11.3032 18.92L11.1639 18.5671C11.1204 18.4565 11.0329 18.369 10.9223 18.3255L10.5694 18.1862C10.2073 18.0435 10.2073 17.5311 10.5694 17.3884L10.9223 17.2492C11.0329 17.2056 11.1204 17.1181 11.1639 17.0075L11.3032 16.6546Z"
          fill={fill}
          className={classes.printIcon}
        />
      </svg>
    );
  }
}

export default withStyles(useStyles)(AISuggestionsIcon);
