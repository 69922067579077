import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Api from '../../../Api/Api';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import LoadingData from '../../../Layout/LoadingData';
import { connect } from 'react-redux';
import * as CONST from '../../../Common/constants';
import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css';
import AnalysisItem from './AnalysisItem';
import './Radar.css';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';

const useStyles = (theme) => ({
  fitPersonalityTitle: {
    color: theme.palette.success.main,
    fontWeight: 700,
    fontSize: '22px'
  },
  fitPersonalityText: {
    color: theme.palette.gray.darker,
    fontSize: '14px',
    lineHeight: '24px'
  },
  activityTitle: {
    fontSize: '14px',
    fontWeight: 700,
    color: theme.palette.gray.darkerMedium
  },
  notAnsweredFit: {
    fontSize: '14px',
    fontWeight: 700,
    color: theme.palette.gray.darkerLight
  },
  iconActionContainer: {
    padding: theme.spacing(1)
  },
  avatarContainer: {
    marginRight: '-1.75rem',
    marginTop: '-1.75rem',
    marginLeft: '-1.25rem'
  },
  personalityTypeTitle: {
    fontWeight: 400,
    fontSize: '12px',
    color: theme.palette.gray.darkerMedium
  },
  applicantName: {
    fontSize: '22px',
    fontWeight: 700,
    color: theme.palette.gray.darker,
    lineHeight: 'normal'
  },
  radar: {
    marginBottom: `${theme.spacing(4)}px`,
    '& > svg': {
      width: 600,
      height: 443
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: `${theme.spacing(2)}px`,
      '& > svg': {
        overflow: 'visible',
        [theme.breakpoints.down('sm')]: {
          width: 300,
          height: 300
        }
      }
    }
  }
});

const defaultOptions = {
  axes: true, // show axes?
  scales: 4, // show scale circles?
  captions: true, // show captions?
  captionMargin: 20,
  dots: true, // show dots?
  zoomDistance: 1.2, // where on the axes are the captions?
  wrapCaptionAt: 40,
  captionLineHeight: 14,
  captionProps: () => ({
    className: 'caption',
    textAnchor: 'middle',
    fontSize: 10,
    fontFamily: 'sans-serif'
  }),
  dotProps: () => ({
    className: 'custom-dot'
    //mouseEnter: (dot) => { console.log(dot) },
    //mouseLeave: (dot) => { console.log(dot) }
  })
};

class RecrutAITraits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant: props.applicant,
      //Internal state
      workforce_profiles: [],
      loadingWorkforceProfiles: false,
      labels: null,
      dataset: null,
      scores: null,
      definitions: null,
      size: 470
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState(
        {
          applicant: this.props.applicant
        },
        () => {
          this.loadWorkforceTypes(this.props.user.language);
        }
      );
    }
  }

  componentDidMount() {
    this.loadWorkforceTypes(this.props.user.language);
  }

  loadWorkforceTypes(lang) {
    this.setState(
      {
        loadingWorkforceProfiles: true
      },
      () => {
        let filter = {
          where: {
            lang: lang //FIXME lang!!
          }
        };
        let api = new Api();
        api.get('WorkforceProfiles', filter, (status, data) => {
          this.setState(
            {
              workforce_profiles: data,
              loadingWorkforceProfiles: false
            },
            () => {
              this.mountAnalysis();
            }
          );
        });
      }
    );
  }

  mountAnalysis = () => {
    const workforce_profiles = this.state.workforce_profiles;
    const applicant = this.state.applicant;

    const personality_profile = applicant.personality_profile;
    const applicantWorkforceProfiles = personality_profile.workforce_profiles;

    const applicantProfiles = Object.keys(applicantWorkforceProfiles);
    const workforceProfilesWithScores = workforce_profiles
      .filter((profile) => {
        if (applicantProfiles.includes(profile.code)) {
          return true;
        }
        return false;
      })
      .map((profile) => {
        return {
          ...profile,
          score: applicantWorkforceProfiles[profile.code]
        };
      })
      .sort((a, b) => b.score - a.score);

    let scores = {};
    let labels = {};
    let definitions = {};
    workforceProfilesWithScores.forEach((workforceProfile) => {
      labels[workforceProfile.code] = workforceProfile.profile;
      scores[workforceProfile.code] = applicantWorkforceProfiles[workforceProfile.code];
      definitions[workforceProfile.code] = workforceProfile.description;
    });

    this.setState({
      labels: labels,
      scores: scores,
      definitions: definitions,
      dataset: {
        data: scores,
        meta: {
          color: CONST.APP_COLORS['primary']
        }
      }
    });
  };

  render() {
    const { classes } = this.props;

    const labels = this.state.labels;
    const dataset = this.state.dataset;
    const scores = this.state.scores;
    const definitions = this.state.definitions;

    const displayScores = () => {
      if (scores) {
        const keys = Object.keys(scores);
        return keys.map((key, index) => {
          let score = Math.round(scores[key] * 5);
          return (
            <Box key={index} mb={index === keys.length - 1 ? 0 : 4} maxWidth="373px">
              <AnalysisItem title={labels[key]} score={score} text={definitions[key]} />
            </Box>
          );
        });
      } else {
        return null;
      }
    };

    if (this.state.loadingWorkforceProfiles) {
      return <LoadingData />;
    }

    if (labels && dataset) {
      return (
        <>
          <Box mb={1}>
            <Box mb={1} display="flex" alignItems="center">
              <Box>
                <Typography className={classes.applicantName}>
                  <FormattedMessage
                    id="RecrutAITraits.title_traits"
                    defaultMessage="Behavioral Markers"
                  />
                </Typography>
              </Box>

              <IconButton
                component={Link}
                href="https://meajuda.recrut.ai/hc/pt-br/articles/360042181434-O-que-são-os-perfis-do-trabalho-"
                target="_help"
              >
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>

          <Box>
            <Box display="flex" justifyContent="center" className={classes.radar}>
              <RadarChart
                captions={labels}
                data={[dataset]}
                size={this.state.size}
                options={defaultOptions}
              />
            </Box>

            <Box gridColumnGap={16} display="flex" justifyContent="space-between" flexWrap="wrap">
              {displayScores()}
            </Box>
          </Box>
        </>
      );
    } else {
      return null;
    }
  }
}

RecrutAITraits.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  user: state.user.user
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(RecrutAITraits)));
