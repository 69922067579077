import React from 'react';
import { injectIntl, defineMessages, FormattedHTMLMessage } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import * as CONST from '../../Common/constants';

const messages = defineMessages({
  employee: {
    id: 'IconInternal.employee',
    defaultMessage: 'Verified employee'
  },
  selfDeclaredEmployee: {
    id: 'IconInternal.selfDeclaredEmployee',
    defaultMessage: 'Self declared employee'
  },
  employeeWithID: {
    id: 'IconInternal.employeeWithID',
    defaultMessage: 'Verified employee <br />Employee ID: {employee_id}'
  },
  selfDeclaredEmployeeWithID: {
    id: 'IconInternal.selfDeclaredEmployeeWithID',
    defaultMessage: 'Self declared employee <br />Employee ID: {employee_id}'
  }
});

class IconInternal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // State from props
      internal: props.internal,
      internal_verified: props.internal_verified,
      employee_id: props.employee_id,
      size: props.size ? props.size : 24
      // Internal state
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.internal !== this.props.internal) {
      this.setState({
        internal: this.props.internal
      });
    }

    if (prevProps.internal_verified !== this.props.internal_verified) {
      this.setState({
        internal_verified: this.props.internal_verified
      });
    }
  }

  render() {
    const internal = this.state.internal;
    const internal_verified = this.state.internal_verified;
    const employee_id = this.state.employee_id;

    if (internal || internal_verified) {
      let text = '';

      if (employee_id) {
        text = internal_verified ? (
          <FormattedHTMLMessage
            id="IconInternal.employeeWithID"
            values={{
              employee_id: employee_id
            }}
          />
        ) : (
          <FormattedHTMLMessage
            id="IconInternal.selfDeclaredEmployeeWithID"
            values={{
              employee_id: employee_id
            }}
          />
        );
      } else {
        text = internal_verified ? (
          <FormattedHTMLMessage id="IconInternal.employee" />
        ) : (
          <FormattedHTMLMessage id="IconInternal.selfDeclaredEmployee" />
        );
      }

      const styleColor = internal_verified
        ? {
            color: CONST.APP_COLORS['green-dark']
          }
        : {
            color: CONST.APP_COLORS['gray-darker-regular']
          };

      return (
        <Tooltip title={text} arrow>
          <AssignmentIndOutlinedIcon
            style={{
              fontSize: this.state.size,
              ...styleColor
            }}
          />
        </Tooltip>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(IconInternal);
