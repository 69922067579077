import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Moment from 'moment';
import 'moment/locale/pt-br'; // FIXME lang
import * as CONST from '../../../Common/constants';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import TimerIcon from '@material-ui/icons/Timer';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SubjectIcon from '@material-ui/icons/Subject';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LinkIcon from '@material-ui/icons/Link';
import striptags from 'striptags';
import he from 'he';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListIcon from '@material-ui/icons/List';
import LocaleFormatter from '../../../Common/LocaleFormatter';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import Api from '../../../Api/Api';

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '…' : str;
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const useStyles = (theme) => ({
  scoreLabel: {
    color: theme.palette.success.main,
    fontSize: '1.125rem',
    fontWeight: 500
  },
  scoreContainer: {
    borderRadius: '100%',
    backgroundColor: theme.palette.success.main,
    width: '34px',
    height: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& p': {
      color: theme.palette.common.white,
      fontWeight: 700
    }
  },
  testTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.palette.gray.darker,
    textDecoration: 'underline'
  },
  answeredQuestionsLabel: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.gray.darkerMedium
  },
  answeredQuestion: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.gray.darkerMedium
  },
  statusLabel: {
    color: theme.palette.gray.darkerRegular,
    fontSize: '12px'
  },
  inactiveSeeMore: {
    color: theme.palette.gray.darkerMedium,
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.gray.darkerMedium
    }
  },
  activeSeeMore: {
    color: theme.palette.primary.main,
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  moreInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  expandFilters: {
    color: theme.palette.gray.darkerLight,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandFiltersOpen: {
    transform: 'rotate(180deg)'
  },
  expandQuestionsButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.gray.darkerLight}`,
    padding: '3px 8px'
  }
});

const HtmlEntities = require('html-entities').AllHtmlEntities;
const ents = new HtmlEntities();

class CardCustomForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      test: props.test,
      questions:
        props.test.customTest && props.test.customTest.questions
          ? props.test.customTest.questions
          : [],
      readonly: props.readonly,
      print: props.print,
      // Internal State
      modal: false,
      answers: [],
      firstAnswers: [],
      lastAnswers: [],
      questionsExpanded: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.test !== this.props.test) {
      this.setState(
        {
          test: this.props.test
        },
        () => {
          this.setAnswers();
        }
      );
    }
  }

  componentDidMount() {
    this.setAnswers();
  }

  setAnswers = () => {
    const test = this.state.test;
    const questions = this.state.questions;
    const form_answers = test.form_answers;
    let answers = [];
    if (test.completed === true && form_answers) {
      questions.forEach((question) => {
        let answer = form_answers[question.tag_id];

        let title = he.decode(question.tag_question);
        let icon;
        let label = this.props.intl.formatMessage({
          id: 'CardCustomTestConversational.questionNotAnswered'
        });
        let right_choice;

        if (question.type === CONST.CUSTOMTEST_TYPE.ALTERNATIVES) {
          const right_choices = question.tag_children
            .filter((x) => x.rightChoice === true)
            .map((x) => x.id);
          //console.log(right_choices);

          // Checking if has any answer for this alternative
          if (form_answers.hasOwnProperty(question.tag_id)) {
            if (question.tag_isMultiChoice === true) {
              // Answer not in array format, change it to array
              if (!Array.isArray(answer)) {
                answer = [answer];
              }

              const alternatives = question.tag_children.map((x) => x.id);
              const choices_arr = intersection(alternatives, answer);
              let labels_arr = [];
              choices_arr.forEach((choice) => {
                const idx = question.tag_children.findIndex((x) => x.id === choice);
                labels_arr.push(he.decode(question.tag_children[idx].label));
              });

              label = labels_arr.join(', ');

              // https://stackoverflow.com/questions/6229197/how-to-know-if-two-arrays-have-the-same-values
              if (right_choices.sort().join(',') === answer.sort().join(',')) {
                icon = <CheckCircleIcon style={{ color: CONST.APP_COLORS['green-dark'] }} />;
                right_choice = true;
              } else {
                icon = <CancelIcon style={{ color: CONST.APP_COLORS['danger-dark'] }} />;
                right_choice = false;
              }
            } else if (question.tag_isMultiChoice === false) {
              // If answer came in array format change it to String
              if (Array.isArray(answer)) {
                answer = answer.length > 0 ? answer[0] : null;
              }

              const ansIdx = question.tag_children.findIndex((x) => x.id === answer);
              label = he.decode(question.tag_children?.[ansIdx]?.label ?? '-');
              if (right_choices[0] === answer) {
                icon = <CheckCircleIcon style={{ color: CONST.APP_COLORS['green-dark'] }} />;
                right_choice = true;
              } else {
                icon = <CancelIcon style={{ color: CONST.APP_COLORS['danger-dark'] }} />;
                right_choice = false;
              }
            } else {
              icon = <CancelIcon style={{ color: CONST.APP_COLORS['danger-dark'] }} />;
              right_choice = false;
            }

            // Pushing answer
            answers.push({
              id: question.tag_id,
              type: question.type,
              icon: icon,
              question: title,
              label: label,
              right_choice: right_choice,
              weight: question.tag_weight
            });
          } else {
            answers.push({
              id: question.tag_id,
              type: question.type,
              icon: <ListIcon />,
              question: title,
              label: label
            });
          }
        } else if (question.type === CONST.CUSTOMTEST_TYPE.FILE) {
          if (answer) {
            const url = CONST.S3_BASE_URL + CONST.S3_BUCKET_URL + form_answers[question.tag_id];
            let filename = '';
            try {
              filename = new URL(url).pathname.split('/').pop();
              filename = decodeURIComponent(filename);
            } catch (e) {
              console.error(e);
              filename = form_answers[question.tag_id];
            }
            //const filename = form_answers[question.tag_id];

            //label = <a href="#" onClick={() => this.getDownloadLink(test.id, question.tag_id)} target="_new">{filename}</a>;

            label = (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => this.getDownloadLink(test.id, question.tag_id)}
                startIcon={<GetAppIcon />}
              >
                <FormattedMessage id="CardCustomTestConversational.buttonDownloadLink" />
              </Button>
            );
          }

          answers.push({
            id: question.tag_id,
            type: question.type,
            icon: <AttachFileIcon />,
            question: title,
            label: label
          });
        } else if (question.type === CONST.CUSTOMTEST_TYPE.OPEN_TEXT) {
          if (answer) {
            label = form_answers[question.tag_id];
            label = typeof label === 'string' ? label.replace(/(?:\r\n|\r|\n)/g, '<br />') : null;
          }

          answers.push({
            id: question.tag_id,
            type: question.type,
            icon: <SubjectIcon />,
            question: title,
            label: label
          });
        } else if (question.type === CONST.CUSTOMTEST_TYPE.LINK) {
          if (answer) {
            const url = form_answers[question.tag_id];
            label = (
              <a href={url} target="_new">
                {url}
              </a>
            );
          }

          answers.push({
            id: question.tag_id,
            type: question.type,
            icon: <LinkIcon />,
            question: title,
            label: label
          });
        }
      });
    }
    let firstAnswers = [];
    let lastAnswers = [];

    if (answers.length > 3) {
      firstAnswers = answers.slice(0, 3);
      lastAnswers = answers.slice(3);
    } else {
      firstAnswers = answers;
    }

    //console.log(answers);

    this.setState({
      answers: answers,
      firstAnswers: firstAnswers,
      lastAnswers: lastAnswers
    });
  };

  getDownloadLink(test_id, question_id) {
    let api = new Api();
    let params = {
      test_id: test_id,
      question_id: question_id
    };
    api.getParam('ApplicantCustomTests/download-file', params, (status, data) => {
      if (data) {
        //console.log(data);
        let path = data;
        //this.props.history.push(path);
        const link = document.createElement('a');
        link.href = path;
        //link.target = '_new';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  handleModalClose = () => {
    this.setState({
      modal: false
    });
  };

  handleExpandQuestions = () => {
    this.setState({ questionsExpanded: !this.state.questionsExpanded });
  };

  render() {
    const { classes } = this.props;

    const test = this.state.test;
    const questionsExpanded = this.state.questionsExpanded;

    const firstAnswers = this.state.firstAnswers;
    const lastAnswers = this.state.lastAnswers;

    const displayStatus = () => {
      let css, icon, title;
      if (test && test.completed === false) {
        if (new Date() > new Date(test.date_limit)) {
          css = 'bg-danger';
          icon = <i className="fa fa-check-circle"></i>;
          title = <FormattedMessage id="CardCustomTestTypeform.testExpired" />;
        } else {
          css = 'bg-warning';
          icon = <i className="fa fa-question-circle mr"></i>;
          title = <FormattedMessage id="CardCustomTestTypeform.testNoAnswer" />;
        }
      }

      if (title) {
        return (
          <CardHeader
            className={'pt-sm pb-sm ' + css}
            avatar={icon}
            title={<small>{title}</small>}
          />
        );
      } else {
        return null;
      }
    };

    const questionCount = () => {
      if (test.completed === true) {
        const answers = this.state.answers;
        const questions = this.state.questions;

        const right = answers.filter((x) => x.right_choice === true).length; // Right choices
        const total = questions.filter((x) => x.type === CONST.CUSTOMTEST_TYPE.ALTERNATIVES).length; // Total of Questions of type ALTERNATIVES

        return `${right}/${total}`;
      } else {
        return null;
      }
    };

    const displayIcon = () => {
      if (test.completed === true) {
        return <PlaylistAddCheckIcon />;
      } else if (new Date() > new Date(test.date_limit)) {
        return <AlarmOffIcon color="error" />;
      } else {
        return <NotInterestedIcon />;
      }
    };

    const displayTime = () => {
      let icon,
        time,
        to,
        isAnswered = false,
        answeredDate;
      const from = new Date();

      // Completed succesfully -> Display time to complete the form
      if (test.completed) {
        to = new Date(from.getTime() + parseInt(test.answers_ellapsed));
        time = Moment(from).from(to, true);
        icon = <TimerIcon fontSize="inherit" />;
        isAnswered = !!test.date_answers;
        const parsedAnsweredDate = isAnswered
          ? Moment(test.date_answers).format('lll')
          : 'invalid date';

        if (parsedAnsweredDate.toLowerCase() === 'invalid date') {
          isAnswered = false;
        } else {
          answeredDate = parsedAnsweredDate;
        }
      } else if (new Date() > new Date(test.date_limit)) {
        // Expired -> Display time is has expired
        to = new Date(test.date_limit);
        time = Moment(from).from(to, true);
        icon = <TimerOffIcon fontSize="inherit" />;
      } else {
        // Incomplete -> Display time since expiration
        to = new Date(test.date_limit);
        time = Moment(from).to(to, false);
        icon = <AvTimerIcon fontSize="inherit" />;
      }

      return (
        <>
          {isAnswered ? (
            <LocaleFormatter dateToFormat={answeredDate} />
          ) : (
            <FormattedMessage id="CardCustomTestConversational.testNoAnsweredDate" />
          )}
        </>
      );
    };

    const displayResult = () => {
      return (
        <Box mb={0.5}>
          <Typography className={classes.answeredQuestionsLabel}>
            <FormattedMessage id="CardCustomTestTypeform.rightAnswers" />
            :&nbsp;
            {questionCount()}
            <span
              span
              style={{
                fontWeight: 700,
                margin: '0 4px'
              }}
            >
              &bull;
            </span>
            {displayTime()}
          </Typography>
        </Box>
      );
    };

    const displayFirstAnswers = firstAnswers.map((answer, i) => {
      const que = truncate(answer.question, 200);
      const labelString = answer.label && typeof answer.label === 'string';
      let labelAnswer = '';

      let secondaryContent;
      if (labelString) {
        labelAnswer = ents.decode(striptags(answer.label, [], '<br />'));
        secondaryContent = (
          <span
            style={{
              fontWeight: 700
            }}
            dangerouslySetInnerHTML={{ __html: labelAnswer }}
          />
        );
      } else {
        labelAnswer = answer.label;
        secondaryContent = (
          <span
            style={{
              fontWeight: 700
            }}
          >
            {labelAnswer}
          </span>
        );
      }
      return (
        <ListItem>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.answeredQuestionsLabel}>
              {i + 1}. {que}
            </Typography>
            <Typography className={classes.answeredQuestion}>{secondaryContent}</Typography>
          </Box>
        </ListItem>
      );
    });

    const displayLastAnswers = lastAnswers.map((answer, i) => {
      const que = truncate(answer.question, 200);
      const labelString = answer.label && typeof answer.label === 'string';
      let labelAnswer = '';

      let secondaryContent;
      if (labelString) {
        labelAnswer = ents.decode(striptags(answer.label, [], '<br />'));
        secondaryContent = (
          <span
            style={{
              fontWeight: 700
            }}
            dangerouslySetInnerHTML={{ __html: labelAnswer }}
          />
        );
      } else {
        labelAnswer = answer.label;
        secondaryContent = (
          <span
            style={{
              fontWeight: 700
            }}
          >
            {labelAnswer}
          </span>
        );
      }
      return (
        <ListItem>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.answeredQuestionsLabel}>
              {i + 1 + firstAnswers.length}. {que}
            </Typography>
            <Typography className={classes.answeredQuestion}>{secondaryContent}</Typography>
          </Box>
        </ListItem>
      );
    });

    return (
      <>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="flex-start" mb={1}>
            <Box mr={1}>
              <AssignmentIcon fontSize="small" color="primary" />
            </Box>
            <Box
              style={{
                cursor: 'pointer'
              }}
              mb={0.5}
              onClick={() => {
                if (test.customTest && test.form_answers) {
                  this.setState({ modal: true });
                }
              }}
            >
              <Typography className={classes.testTitle}>{test.customTest.name}</Typography>
            </Box>
          </Box>
          {displayResult()}
          <Box>
            <List>{displayFirstAnswers}</List>
            {lastAnswers.length > 0 ? (
              <Collapse
                in={questionsExpanded || this.state.readonly || this.state.print}
                timeout="auto"
              >
                <List>{displayLastAnswers}</List>
              </Collapse>
            ) : null}

            {this.state.readonly || this.state.print ? null : (
              <Box className={classes.moreInfoContainer} mx={this.props.isLastForm ? -3 : 0}>
                <Divider
                  light={this.props.isLastForm ? false : true}
                  style={{
                    width: '100%'
                  }}
                />
                <Box ml={1} mr={1} minWidth="120px" display="flex" justifyContent="center">
                  <Button
                    className={classes.expandQuestionsButton}
                    size="small"
                    onClick={this.handleExpandQuestions}
                    endIcon={
                      <ExpandMoreIcon
                        className={clsx(classes.expandFilters, {
                          [classes.expandFiltersOpen]: questionsExpanded
                        })}
                      />
                    }
                  >
                    <Typography
                      style={{
                        fontSize: '13px'
                      }}
                    >
                      {questionsExpanded ? (
                        <FormattedMessage id="CVProfileInfo.label_seeLess" />
                      ) : (
                        <FormattedMessage id="CVProfileInfo.label_seeMore" />
                      )}
                    </Typography>
                  </Button>
                </Box>
                <Divider
                  light
                  style={{
                    width: '100%'
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={this.state.modal}
          onClose={this.handleModalClose}
        >
          <DialogContent dividers={true}>
            <Card>
              <CardHeader
                avatar={displayIcon()}
                title={test.customTest.name}
                subheader={displayTime()}
              />

              <Box ml={1}>{displayResult()}</Box>

              <CardContent>
                <Divider light />
                <List>{displayFirstAnswers}</List>
                <List>{displayLastAnswers}</List>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleModalClose()} color="secondary">
              <FormattedMessage id="CardCustomTestTypeform.buttonClose" />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
export default injectIntl(withStyles(useStyles)(CardCustomForm));
