import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import 'moment/locale/pt-br'; // FIXME locale
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Api from '../Api/Api';
import { MobileDateTimePicker } from '@material-ui/pickers';
import Select, { Creatable } from 'react-select';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Moment from 'moment';
import Timezones from '../Common/timezones';
import AddressController from '../Common/AddressController';
import Avatar from 'react-avatar';
import UserProfile from '../Common/UserProfile';
import Alert from '@material-ui/lab/Alert';
import UtilsForm from '../Common/UtilsForm';
import Typography from '@material-ui/core/Typography';
import ModalAlertBlock from './ModalAlertBlock';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SelectUsersMultipleCustom from '../SelectUsersMultipleCustom/SelectUsersMultipleCustom';
import * as CONST from '../Common/constants';

const messages = defineMessages({});

const MEDIUM_PRESENTIAL = 0;
const MEDIUM_DIGITAL = 1;
const MEDIUM_PHONE = 2;

// MUST BE OUTSIDE OF COMPONENT
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '17vw',
    width: '17vw'
  },
  primaryOption: {
    backgroundColor: theme.palette.primary.main
  },
  iconWarning: {
    color: `${theme.palette.warning.main} !important`
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: '100%' }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} alignItems="center" width="100%">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

class ModalChangeEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      selected_id: props.selected_id,
      submiting: props.submiting,
      job_id: props.job_id,
      selected_objs: props.selected_objs ? props.selected_objs : [],
      // Internal state
      atendees: [],
      datetime_interview: null,
      medium: '',
      duration: 60,
      mode: 'MANUAL',
      timezone: '',
      address: '',
      digital_url: '',
      phone_in: '',
      isPhoneMaskCorrect: true,
      phoneMaskFormat: '',
      countries_name: [],
      country_label: '',
      confirmation: false,
      durationList: [
        { value: 30, label: '30min' },
        { value: 60, label: '1h' },
        { value: 90, label: '1h30' },
        { value: 120, label: '2h' },
        { value: 180, label: '3h' },
        { value: 240, label: '4h' },
        { value: 360, label: '6h' },
        { value: 480, label: '8h' }
      ],
      timezoneList: Timezones,
      loadAddress: [],
      accountUsersList: [],
      isBlocked: false,
      modalOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(
        {
          open: this.props.open
        },
        () => {
          this.warningApplicantBlocked();
        }
      );
    }
    if (prevProps.selected_id !== this.props.selected_id) {
      this.setState({
        selected_id: this.props.selected_id
      });
    }
    if (prevProps.submiting !== this.props.submiting) {
      this.setState({
        submiting: this.props.submiting
      });
    }
    if (prevProps.job_id !== this.props.job_id) {
      this.setState({
        job_id: this.props.job_id
      });
    }
    if (prevProps.selected_objs !== this.props.selected_objs) {
      this.setState({
        selected_objs: this.props.selected_objs
      });
    }
  }

  warningApplicantBlocked = () => {
    const isAnyBlocked = this.state.selected_objs.some((applicant) => {
      const { emailBlocklists, docBlocklists } = applicant;
      const activeBlockValidation =
        (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
        (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0);
      return activeBlockValidation === true;
    });

    this.setState({
      isBlocked: isAnyBlocked
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleChangeSelectInterviewUser = (selected) => {
    let int_users = [];
    // let warning = false;
    selected.forEach((option) => {
      int_users.push(option.id);
    });

    this.setState({ atendees: int_users });

    // if (warning === true) {
    //   this.setState({
    //     atendees: int_users,
    //     atendees_warning: true
    //   });
    // } else {
    // }
  };

  handleChangeSelectInterviewDuration = (selected) => {
    let value = '';
    if (selected) {
      value = selected.value;
    }
    this.setState({ duration: value });
  };

  handleInterviewManualDateTime = (date) => {
    this.setState({
      datetime_interview: date
    });
  };

  setInterviewMediumType = (e, value) => {
    this.setState(
      {
        medium: value
      },
      () => {
        if (value === 2) {
          this.getCountries();
        }
      }
    );
  };

  handleTimezoneChange = (timezone) => {
    let value = '';
    if (timezone) {
      value = timezone.value;
    }
    this.setState({ timezone: value });
  };

  handleInterviewAddressChange = (address) => {
    //console.log(address);
    this.setState({
      address: address
    });
  };

  loadEvent() {
    if (this.state.selected_id) {
      let api = new Api();
      let filter = {
        where: {
          id: this.state.selected_id
        }
      };
      api.get('Interviews', filter, (status, data) => {
        // console.log(data);

        let selected_medium;
        switch (data[0].medium) {
          case 'PRESENTIAL':
            selected_medium = MEDIUM_PRESENTIAL;
            break;
          case 'DIGITAL':
            selected_medium = MEDIUM_DIGITAL;
            break;
          case 'PHONE':
            selected_medium = MEDIUM_PHONE;
            break;
        }

        this.setState({
          atendees: data[0].interviewers_user_id,
          datetime_interview: Moment(data[0].datetime_interview),
          medium: selected_medium,
          timezone: UtilsForm.nullToString(data[0].timezone),
          duration: data[0].duration,
          mode: data[0].mode,
          address: {
            value: AddressController.getAddrUrl(data[0].address),
            label: AddressController.getAddrUrl(data[0].address),
            obj: data[0].address
          },
          digital_url: UtilsForm.nullToString(data[0].digital_url),
          phone_in: UtilsForm.nullToString(data[0].phone_in),
          confirmation: data[0].confirmation
        });
      });
    }
  }

  loadAccountUsers() {
    let userList = [];
    let filter = {
      where: {
        realm: UserProfile.getRealm(),
        suspended: false
      },
      include: {
        relation: 'account',
        scope: {
          fields: ['address', 'unique_name', 'abbreviation']
        }
      },
      order: ['username']
    };
    let api = new Api();
    api.get('AccountUsers', filter, (status, data) => {
      this.setState({
        accountUsersList: data
      });
    });
  }

  loadAddress() {
    let api = new Api();

    let params = {
      job_id: this.state.job_id
    };
    let addressList = [];

    api.getParam('Jobs/addresses-info', params, (status, data) => {
      if (status <= 400) {
        if (data[0].job_address) {
          addressList.push(data[0].job_address);
        }
        if (data[0].companyarea_address) {
          addressList.push(data[0].companyarea_address);
        }
        if (data[0].account_address) {
          addressList.push(data[0].account_address);
        }

        let arr = [];
        if (addressList.length > 0) {
          addressList.forEach((addr) => {
            let value = AddressController.getAddrUrl(addr);
            arr.push({
              value: value,
              label: value,
              obj: addr
            });
          });
        }

        this.setState(
          {
            addressList: arr
          },
          () => {
            if (arr.length > 0) {
              this.setState({
                address: arr[0]
              });
            }
          }
        );
      } else {
        console.log('Error on fetching data');
      }
    });
  }

  handleOpen = () => {
    this.loadAccountUsers();
    this.loadAddress();
    this.loadEvent();
  };

  handleClose = () => {
    this.setState(
      {
        open: false,
        isBlocked: false,
        modalOpen: false
      },
      () => {
        if (this.props.handlerClose) {
          this.props.handlerClose();
        }
      }
    );
  };

  handleModalOpen = () => {
    this.setState({
      open: false,
      modalOpen: true
    });
  };

  handleChangePhoneNumber = (event) => {
    const value = event.target.value;
    const expectedLength = this.state.phoneMaskFormat.replace(/\D/g, '').length;
    if (expectedLength === 0) {
      this.setState({
        isPhoneMaskCorrect: true,
        phone_in: value
      });
    } else if (value.replace(/\D/g, '').length === expectedLength) {
      this.setState({
        isPhoneMaskCorrect: true,
        phone_in: value
      });
    } else {
      this.setState({
        isPhoneMaskCorrect: false,
        phone_in: value
      });
    }
  };

  displayPhoneFormat = (selected) => {
    let containerId = 'phone_format';
    if (selected && selected.mobile_mask && selected.mobile_mask !== '') {
      const phoneMask = selected.mobile_mask;
      AddressController.getMaskedID(containerId, phoneMask);
      this.setState({ phoneMaskFormat: phoneMask });
    } else {
      AddressController.unMaskedID(containerId);
      this.setState({ phoneMaskFormat: '' });
    }
  };

  handleChangeSelectCountry = (event, selected) => {
    // console.log(selected)
    if (selected) {
      const selectedCountry = this.state.countries_name.find(
        (country) => country.value === selected.value
      );

      this.setState(
        {
          country: selected.value,
          country_label: selectedCountry ? selectedCountry.label : '',
          phone_in: '',
          phoneMaskFormat: selected.mobile_mask
        },
        () => {
          this.displayPhoneFormat(selected);
        }
      );
    } else {
      this.setState({
        country: null,
        country_label: null,
        phone_in: '',
        phoneMaskFormat: ''
      });
    }
  };

  getCountries = () => {
    const userCountryDefault = this.props.accountInfo.accountInfo.country;
    let api = new Api();
    let filter = {
      order: ['name ASC']
    };
    api.get('Countries', filter, (status, data) => {
      // console.log(data)
      if (data.length > 0) {
        const list = data.map((country) => ({
          value: country.code,
          label: country.name,
          mobile_mask: country.mobile_mask,
          mobile_code: country.mobile_code
        }));
        const selectedCountry = list.find((country) => country.value === userCountryDefault);
        // console.log(selectedCountry)
        this.setState(
          {
            countries_name: list,
            country: userCountryDefault,
            country_label: list.find((country) => country.value === userCountryDefault)?.label || ''
          },
          () => {
            this.displayPhoneFormat(selectedCountry);
          }
        );
      }
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState(
      {
        submiting: true
      },
      () => {
        let medium;
        let addressString = null;
        let addressObj = null;
        let digital_url = null;
        let phone_in = null;

        switch (this.state.medium) {
          case MEDIUM_PRESENTIAL:
            medium = 'PRESENTIAL';

            const address = this.state.address;
            if (address.obj) {
              addressString = AddressController.getAddrUrl(address.obj);
              this.state.addressList.some((addr) => {
                if (addr.value === addressString) {
                  addressObj = addr.obj;
                  return true;
                }
              });
            } else {
              addressObj = {
                street: address.value,
                number: '',
                comp: '',
                city: '',
                state: '',
                zip: ''
              };
            }
            break;
          case MEDIUM_DIGITAL:
            medium = 'DIGITAL';
            if (this.state.digital_url || this.state.digital_url !== '') {
              digital_url = this.state.digital_url;
            }
            break;
          case MEDIUM_PHONE:
            medium = 'PHONE';
            if (this.state.phone_in || this.state.phone_in !== '') {
              const selectedCountry = this.state.countries_name.find(
                (country) => country.value === this.state.country
              );

              if (selectedCountry.mobile_code != '') {
                phone_in = `+${selectedCountry.mobile_code} ${this.state.phone_in}`;
              } else {
                phone_in = this.state.phone_in;
              }
            }
            break;
        }

        let payload = {
          mode: 'MANUAL',
          interviewers_user_id: this.state.atendees,
          datetime_interview: Moment(this.state.datetime_interview).toDate(),
          duration: this.state.duration,
          timezone: this.state.timezone,
          medium: medium,
          address: addressObj,
          digital_url: digital_url,
          phone_in: phone_in
        };

        let api = new Api();
        api.update('Interviews', this.state.selected_id, payload, (status, data) => {
          // console.log(data);

          this.setState(
            {
              submiting: false
            },
            () => {
              if (this.props.handlerUpdate) {
                this.props.handlerUpdate([data]); // Pass as an array for compatibility
              }
            }
          );
        });
      }
    );
    this.handleClose();
  };

  checkBeforeSubmit = (e) => {
    e.preventDefault();
    if (
      (this.state.medium === MEDIUM_DIGITAL && this.state.digital_url === '') ||
      (this.state.medium === MEDIUM_PHONE && this.state.phone_in === '')
    ) {
      window
        .swal({
          type: 'info',
          title: '',
          text: this.props.intl.formatMessage({
            id: 'ModalEventOptions.interview_warningWithoutLink'
          }),
          closeOnConfirm: true,
          buttons: {
            cancel: {
              text: this.props.intl.formatMessage({
                id: 'Base.button.cancel'
              }),
              value: null,
              visible: true,
              className: '',
              closeModal: true
            },
            confirm: {
              text: 'OK',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          }
        })
        .then((isConfirmed) => {
          if (isConfirmed) {
            this.onSubmit(e);
          } else {
            return null;
          }
        });
    } else {
      this.onSubmit(e);
    }
  };

  render() {
    const { classes } = this.props;
    const { countries_name, country_label } = this.state;

    const displayConfirmWarning = () => {
      if (this.state.confirmation === true) {
        return (
          <Alert
            severity="warning"
            classes={{ icon: classes.iconWarning }}
            style={{
              backgroundColor: CONST.APP_COLORS['warning-light'],
              color: CONST.APP_COLORS['warning']
            }}
          >
            <FormattedMessage
              id="ModalChangeEvent.msg_confirmationWarning"
              defaultMessage="Candidate has already confirmed this event. Avoid changing event information too close as it may not be possible to reach him/her in time."
            />
          </Alert>
        );
      } else {
        return null;
      }
    };

    return (
      <>
        {this.state.isBlocked && (
          <ModalAlertBlock
            open={this.state.open}
            handleModalOpen={this.handleModalOpen}
            handlerClose={this.handleClose}
          />
        )}
        <Dialog
          open={this.state.isBlocked ? this.state.modalOpen : this.state.open}
          fullWidth={true}
          maxWidth="md"
          onEnter={this.handleOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <form onSubmit={this.checkBeforeSubmit}>
            <Backdrop open={this.state.submiting} className={classes.backdrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <DialogTitle id="dialog-title">
              <FormattedMessage
                id="ModalChangeEvent.title"
                defaultMessage="Change Interview / Event"
              />
            </DialogTitle>
            <DialogContent>
              <Row>
                <Col md={12}>
                  <label htmlFor="atendees">
                    <FormattedMessage id="ModalEventOptions.fieldUsers" />
                  </label>
                  <SelectUsersMultipleCustom
                    id="atendees"
                    required
                    synchronizeUsersCalendar
                    value={this.state.atendees}
                    options={this.state.accountUsersList}
                    onChange={this.handleChangeSelectInterviewUser}
                  />
                  {this.state.isBlocked && (
                    <Alert severity="warning">
                      <Typography>
                        <FormattedMessage id="AlertApplicantBlocked.alert_selected" />
                      </Typography>
                    </Alert>
                  )}
                </Col>
              </Row>
              <hr />
              {displayConfirmWarning()}
              <Row>
                <Col md={5}>
                  <div className="mt-lg">
                    <label htmlFor="datetime_interview">
                      <FormattedMessage
                        id="ModalChangeEvent.datetime_interview"
                        defaultMessage="Date time"
                      />
                    </label>
                    <br />
                    <MobileDateTimePicker
                      name="datetime_interview"
                      disablePast={true}
                      minutesStep={5}
                      fullWidth
                      ampm={false}
                      autoOk={true}
                      value={this.state.datetime_interview}
                      onChange={this.handleInterviewManualDateTime}
                      required={true}
                      renderInput={(props) => <TextField {...props} />}
                    />
                  </div>
                </Col>
                <Col md={7}>
                  <div className="form-group">
                    <label htmlFor="duration">
                      <FormattedMessage id="ModalEventOptions.fieldDuration" />
                    </label>
                    <br />
                    <Select
                      name="duration"
                      required={true}
                      clearable={false}
                      value={this.state.duration}
                      options={this.state.durationList}
                      onChange={this.handleChangeSelectInterviewDuration}
                      menuContainerStyle={{ zIndex: 999 }}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="timezone">
                      <FormattedMessage id="ModalEventOptions.fieldTimezone" />
                    </label>
                    <br />
                    <Select
                      name="timezone"
                      required={true}
                      clearable={false}
                      value={this.state.timezone}
                      options={this.state.timezoneList}
                      onChange={this.handleTimezoneChange}
                      menuContainerStyle={{ zIndex: 999 }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}></Col>
              </Row>
              <hr />
              <div className={classes.root}>
                <Tabs
                  indicatorColor="primary"
                  orientation="vertical"
                  variant="scrollable"
                  value={this.state.medium}
                  onChange={(e, value) => this.setInterviewMediumType(e, value)}
                  className={classes.tabs}
                >
                  <Tab
                    label={<FormattedMessage id="ModalEventOptions.mediumPresential" />}
                    id={'medium-tab-0'}
                  />
                  <Tab
                    label={<FormattedMessage id="ModalEventOptions.mediumDigital" />}
                    id={'medium-tab-1'}
                  />
                  <Tab
                    label={<FormattedMessage id="ModalEventOptions.mediumPhone" />}
                    id={'medium-tab-3'}
                  />
                </Tabs>
                <TabPanel value={this.state.medium} index={MEDIUM_PRESENTIAL}>
                  <Row>
                    <Col md={12}>
                      <label htmlFor="address">
                        <i className="icon-directions mr"></i>
                        <FormattedMessage id="ModalEventOptions.fieldAddress" />
                      </label>
                      <br />
                      <Creatable
                        name="address"
                        value={this.state.address}
                        options={this.state.addressList}
                        onChange={this.handleInterviewAddressChange}
                        menuContainerStyle={{ zIndex: 10000 }}
                        promptTextCreator={(label) =>
                          this.props.intl.formatMessage({
                            id: 'ModalEventOptions.interview_newAddress'
                          }) +
                          ' ' +
                          label
                        }
                        shouldKeyDownEventCreateNewOption={(event) => {
                          if (event.keyCode === 13) {
                            return true;
                          }
                        }}
                        required={this.state.medium === MEDIUM_PRESENTIAL}
                        disabled={this.state.medium !== MEDIUM_PRESENTIAL}
                      />
                      <p className="text-muted">
                        <small>
                          <FormattedMessage id="ModalEventOptions.fieldAddressHelp" />
                        </small>
                      </p>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value={this.state.medium} index={MEDIUM_DIGITAL}>
                  <Row>
                    <Col md={12}>
                      <label htmlFor="digital_url">
                        <i className="fas fa-video mr"></i>
                        <FormattedMessage id="ModalEventOptions.fieldDigitalUrl" />
                      </label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-link"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          id="digital_url"
                          name="digital_url"
                          type="text"
                          className="form-control"
                          value={this.state.digital_url}
                          onChange={this.handleChange}
                          onBlur={this.handleChange}
                          disabled={this.state.medium !== MEDIUM_DIGITAL}
                        />
                      </InputGroup>
                      <p className="text-muted">
                        <small>
                          <FormattedMessage id="ModalEventOptions.digitalUrlHelp" />
                        </small>
                      </p>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value={this.state.medium} index={MEDIUM_PHONE}>
                  <Row>
                    <Col md={12}>
                      <div className="form-group">
                        <label htmlFor="phone_in">
                          <i className="icon-call-in mr"></i>
                          <FormattedMessage id="ModalEventOptions.fieldPhoneIn" />
                        </label>
                        <Box>
                          <OutlinedInput
                            id="phone_format"
                            name="phone_format"
                            value={this.state.phone_in}
                            onChange={this.handleChangePhoneNumber}
                            fullWidth
                            placeholder={this.props.intl.formatMessage({
                              id: 'ModalEventOptions.write_phoneNumber'
                            })}
                            startAdornment={
                              <Box display="flex" alignItems="center">
                                <Box mr={1}>
                                  <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="fa fa-phone-square"></i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </Box>

                                <Box mr={1} minWidth={100}>
                                  <Autocomplete
                                    id="country"
                                    name="country"
                                    options={countries_name}
                                    autoHighlight
                                    disableClearable
                                    value={country_label ? { label: country_label } : null}
                                    onChange={this.handleChangeSelectCountry}
                                    getOptionLabel={(option) => option.label || ''}
                                    getOptionSelected={(option, value) =>
                                      option.value === value?.value
                                    }
                                    renderInput={(params) => <TextField {...params} />}
                                    required={true}
                                  />
                                </Box>
                              </Box>
                            }
                          />
                          <p className="text-muted">
                            <small>
                              <FormattedMessage id="ModalEventOptions.fieldPhoneInHelp" />
                            </small>
                          </p>
                        </Box>
                      </div>
                    </Col>
                  </Row>
                </TabPanel>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="default">
                <FormattedMessage id="Base.button.cancel" />
              </Button>
              <Button
                disabled={
                  this.state.submiting ||
                  !this.state.atendees ||
                  (this.state.medium === MEDIUM_PRESENTIAL && !this.state.address) ||
                  (this.state.datetime_interview &&
                    this.state.datetime_interview._isValid === false) ||
                  Moment(this.state.datetime_interview) <= Moment() ||
                  (this.state.medium === MEDIUM_PHONE && this.state.isPhoneMaskCorrect === false)
                }
                type="submit"
                color="primary"
              >
                <FormattedMessage id="ModalChangeEvent.button_edit" defaultMessage="Edit Event" />
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
}

ModalChangeEvent.propTypes = {
  accountInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(ModalChangeEvent)));
