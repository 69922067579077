import { SET_COMPANY_TREE, SET_LOADING_COMPANY_TREE } from '../actions/actions';
import produce from 'immer';

const INITIAL_STATE = {
  flatData: [],
  loading: true
};

export default function companyTreeReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_COMPANY_TREE: {
        draft.flatData = action.payload;
        draft.loading = false;
        break;
      }
      case SET_LOADING_COMPANY_TREE: {
        draft.loading = true;
        break;
      }
      default:
        return state;
    }
  });
}
