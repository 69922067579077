import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Popover from '@material-ui/core/Popover';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import NumberScore from './NumberScore';
import Divider from '@material-ui/core/Divider';
import CardController from '../CardController';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const messages = defineMessages({});

const useStyles = (theme) => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(2)
  }
});

const useStylesCustom = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  top: {
    color: theme.palette.primary.main,
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  }
}));

function CircularProgressWithLabel(props) {
  const classes = useStylesCustom();

  return (
    <Box position="relative" display="inline-flex">
      <div className={classes.root}>
        <CircularProgress variant="determinate" className={classes.bottom} {...props} value={100} />
        <CircularProgress
          variant="determinate"
          className={classes.top}
          classes={{
            circle: classes.circle
          }}
          {...props}
        />
      </div>
      <Box
        top={-5}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {`${props.value}%`}
        </Typography>
      </Box>
    </Box>
  );
}

class CircularOverallScore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overall_score: CardController.formatScore(props.overall_score),
      scores: {
        cv_score: CardController.formatScore(props.scores.cv_score), // Comes already normalized
        interview_score: CardController.getInterviewScore(props.scores.interview_score),
        videointerview_score: CardController.getVideoInterviewScore(
          props.scores.videointerview_score
        ),
        customtest_score: CardController.getCustomTestScorePerc(props.scores.customtest_score)
      },
      size: props.size,
      // Internal State
      // Menu
      anchorEl: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.overall_score !== this.props.overall_score) {
      this.setState({
        overall_score: CardController.formatScore(this.props.overall_score)
      });
    }
    if (JSON.stringify(prevProps.scores) !== JSON.stringify(this.props.scores)) {
      this.setState({
        scores: {
          cv_score: CardController.formatScore(this.props.scores.cv_score), // Comes already normalized
          videointerview_score: CardController.getVideoInterviewScore(
            this.props.scores.videointerview_score
          ),
          customtest_score: CardController.getCustomTestScorePerc(
            this.props.scores.customtest_score
          )
        }
      });
    }
  }

  handleOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { classes } = this.props;

    let overall_score = this.state.overall_score;
    const scores = this.state.scores;
    const size = this.state.size;

    let text = '--';
    if (overall_score > -1) {
      text = `${overall_score}%`;
    } else {
      overall_score = 0;
    }

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'overall-score-popover' : undefined;

    const displayScores = Object.keys(scores).map((key) => {
      return (
        <>
          <Box minWidth={64} p={1} className="text-center">
            <NumberScore score={scores[key]} type={key} />
          </Box>
          <Divider orientation="vertical" flexItem />
        </>
      );
    });

    return (
      <div
        style={{ width: size, height: size }}
        onMouseEnter={(e) => this.handleOpen(e)}
        onMouseLeave={() => this.handleClose()}
      >
        <CircularProgressWithLabel size={size} value={overall_score} text={text} />
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          className={classes.popover}
          classes={{
            paper: classes.paper
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          disableRestoreFocus
        >
          <Box display="flex" alignItems="center">
            <Divider orientation="vertical" flexItem />
            {displayScores}
          </Box>
        </Popover>
      </div>
    );
  }
}

export default injectIntl(withStyles(useStyles)(CircularOverallScore));
