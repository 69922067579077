import * as CONST from '../Common/constants';

const K_WIDTH = 55;
const K_HEIGHT = 55;

const jobPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT,
  border: '1px solid ' + CONST.APP_COLORS['primary'],
  borderRadius: K_HEIGHT,
  backgroundColor: 'white',
  textAlign: 'center',
  //color: '#3f51b5',
  fontSize: 16,
  fontWeight: 'bold',
  padding: 4
};

export { jobPlaceStyle };
