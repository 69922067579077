import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import 'moment/locale/pt-br'; // FIXME locale
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Api from '../Api/Api';
import * as CONST from '../Common/constants';
import StageController from './StageController';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import ModalAlertBlock from './ModalAlertBlock';

const messages = defineMessages({});

// MUST BE OUTSIDE OF COMPONENT
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  primaryOption: {
    backgroundColor: theme.palette.primary.main
  },
  customPopup: {
    width: '500px',
    height: '500px'
  },
  iconWarning: {
    color: `${theme.palette.warning.main} !important`
  }
});

const baseFilters = CONST.getApplicantDetailBaseFields();

class ModalCancelEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      selected_ids: props.selected_ids,
      submiting: props.submiting,
      selected_objs: props.selected_objs ? props.selected_objs : [],
      // Internal state
      message: '',
      isBlocked: false,
      modalOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(
        {
          open: this.props.open
        },
        () => {
          this.warningApplicantBlocked();
        }
      );
    }
    if (prevProps.selected_ids !== this.props.selected_ids) {
      this.setState({
        selected_ids: this.props.selected_ids
      });
    }
    if (prevProps.submiting !== this.props.submiting) {
      this.setState({
        submiting: this.props.submiting
      });
    }
    if (prevProps.selected_objs !== this.props.selected_objs) {
      this.setState({
        selected_objs: this.props.selected_objs
      });
    }
  }

  warningApplicantBlocked = () => {
    const isAnyBlocked = this.state.selected_objs.some((applicant) => {
      const { emailBlocklists, docBlocklists } = applicant;
      const activeBlockValidation =
        (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
        (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0);
      return activeBlockValidation === true;
    });

    this.setState({
      isBlocked: isAnyBlocked
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  loadDefaultChoice() {}

  handleOpen = () => {
    this.loadDefaultChoice();
  };

  handleClose = () => {
    this.setState(
      {
        open: false,
        isBlocked: false,
        modalOpen: false
      },
      () => {
        if (this.props.handlerClose) {
          this.props.handlerClose();
        }
      }
    );
  };

  handleModalOpen = () => {
    this.setState({
      open: false,
      modalOpen: true
    });
  };

  onSubmit = () => {
    this.setState(
      {
        submiting: true
      },
      () => {
        const payload = {
          interview_ids: this.state.selected_ids,
          message: this.state.message
        };
        // console.log(payload);

        let api = new Api();
        api.post('Interviews/cancel', payload, (status, data) => {
          // console.log(data);

          this.setState(
            {
              submiting: false
            },
            () => {
              if (this.props.handlerUpdate) {
                this.props.handlerUpdate(data);
              }
            }
          );
        });
      }
    );
    this.handleClose();
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.isBlocked && (
          <ModalAlertBlock
            open={this.state.open}
            handleModalOpen={this.handleModalOpen}
            handlerClose={this.handleClose}
          />
        )}
        <Dialog
          open={this.state.isBlocked ? this.state.modalOpen : this.state.open}
          onEnter={this.handleOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <Backdrop open={this.state.submiting} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitle id="dialog-title">
            <FormattedMessage
              id="ModalCancelEvent.title"
              defaultMessage="Cancel Interview / Event"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage
                id="ModalCancelEvent.titleMsg"
                defaultMessage="Write a message to your candidate/s explaining the reason for this cancelation."
              />
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="message"
              name="message"
              fullWidth
              multiline
              rows={4}
              value={this.state.message}
              onChange={this.handleChange}
            />
            {this.state.isBlocked && (
              <Alert
                severity="warning"
                classes={{ icon: classes.iconWarning }}
                style={{
                  backgroundColor: CONST.APP_COLORS['warning-light'],
                  color: CONST.APP_COLORS['warning']
                }}
              >
                <Typography>
                  <FormattedMessage id="AlertApplicantBlocked.alert_selected" />
                </Typography>
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.onSubmit}
              color="primary"
              disabled={this.state.message === '' ? true : false}
            >
              <FormattedMessage
                id="ModalCancelEvent.button_confirm"
                defaultMessage="Confirm Cancelation"
              />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ModalCancelEvent));
