var UtilsForm = (function () {
  var replaceToNull = function (value) {
    return value === '' ? null : value;
  };

  var nullToString = function (value) {
    return !value ? '' : value;
  };

  var nullToArray = function (value) {
    return !value ? [] : value;
  };

  var emptyToNull = function (value) {
    return value.length === 0 ? null : value;
  };

  var emptyObjectToNull = function (value) {
    return Object.keys(value).length === 0 ? null : value;
  };

  var generateUUID = function () {
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  };

  return {
    replaceToNull: replaceToNull,
    nullToString: nullToString,
    nullToArray: nullToArray,
    emptyObjectToNull: emptyObjectToNull,
    emptyToNull: emptyToNull,
    generateUUID: generateUUID
  };
})();

export default UtilsForm;
