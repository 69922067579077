import React from 'react';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Parser from 'html-react-parser';

const useStyles = (theme) => ({
  title: {
    fontSize: '16px',
    color: theme.palette.gray.darker,
    fontWeight: 700,
    lineHeight: 'normal'
  },
  text: {
    fontSize: '14px',
    color: theme.palette.gray.darker,
    lineHeight: '18px'
  },
  matchCircle: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(0.25)
  },
  unmatchCircle: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: theme.palette.gray.darkerLight,
    paddingTop: theme.spacing(0.25)
  }
});

class AnalysisItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }
  }

  render() {
    const { classes } = this.props;

    const displayScore = () => {
      const fullScore = 5;
      const unmatchScore = fullScore - this.props.score;

      const scoreArray = new Array(this.props.score).fill(0);
      const unmatchScoreArray = new Array(unmatchScore).fill(0);

      return (
        <Box display="flex" alignItems="center">
          {scoreArray.map((e, i) => {
            return <Box key={i} mr={0.5} className={classes.matchCircle} />;
          })}{' '}
          {unmatchScoreArray.map((e, i) => {
            return <Box key={i} mr={0.5} className={classes.unmatchCircle} />;
          })}
        </Box>
      );
    };

    return (
      <Box>
        <Box mb={1.5} display="flex" alignItems="center">
          <Box mr={1}>
            <Typography className={classes.title}>{this.props.title}</Typography>
          </Box>
          {displayScore()}
        </Box>
        <Typography className={classes.text}>{Parser(this.props.text)}</Typography>
      </Box>
    );
  }
}

export default injectIntl(withStyles(useStyles)(AnalysisItem));
