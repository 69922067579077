import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions/actions';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Utils from '../Common/Utils';
import UserProfile from '../Common/UserProfile';
import Typography from '@material-ui/core/Typography';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';

const messages = defineMessages({
  limit_users: {
    id: 'CardAccountLimits.limit_users',
    defaultMessage: 'Active Users'
  },
  limit_jobs: {
    id: 'CardAccountLimits.limit_jobs',
    defaultMessage: 'Concurrent Jobs'
  },
  limit_vi: {
    id: 'CardAccountLimits.limit_vi',
    defaultMessage: 'Recorded Interviews'
  },
  limit_sms: {
    id: 'CardAccountLimits.limit_sms',
    defaultMessage: 'SMS Messages'
  }
});

const useStyles = (theme) => ({
  cardRoot: {
    border: '1px solid ' + theme.palette.gray.main
  }
});

class CardAccountLimits extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // Internal State
      _loading: true,
      plan: {
        plan_type: '',
        plan_sms_type: '',
        plan_vi_type: '',
        plan_users: {
          count: -1,
          limit: -1,
          perc: -1
        },
        plan_jobs: {
          count: -1,
          limit: -1,
          perc: -1
        },
        plan_vi: {
          count: -1,
          limit: -1,
          perc: -1
        },
        plan_sms: {
          count: -1,
          limit: -1,
          perc: -1
        }
      }
    };
  }

  componentDidMount() {
    this.setState({
      _loading: false
    });
  }

  render() {
    const { classes } = this.props;

    const plan = this.props.planInfo;

    const listLimit = (type) => {
      const item = plan[type];

      let color = 'primary';
      if (item.limit === 0) {
        color = 'secondary';
      }

      let icon, tooltip;
      switch (type) {
        case 'plan_users':
          tooltip = this.props.intl.formatMessage({ id: 'CardAccountLimits.limit_users' });
          break;
        case 'plan_jobs':
          tooltip = this.props.intl.formatMessage({ id: 'CardAccountLimits.limit_jobs' });
          break;
        case 'plan_vi':
          tooltip = this.props.intl.formatMessage({ id: 'CardAccountLimits.limit_vi' });
          break;
        case 'plan_sms':
          tooltip = this.props.intl.formatMessage({ id: 'CardAccountLimits.limit_sms' });
          break;
      }

      return (
        <Box display="flex" alignItems="center" width="100%" p={1}>
          <Box width="50%" mr={1}>
            <Typography variant="caption">{tooltip}</Typography>
          </Box>
          <Box width="50%" mr={1}>
            <LinearProgress variant="determinate" color={color} value={item.perc} />
          </Box>
          <Box minWidth={60} justifyContent="flex-end">
            {item.limit === -1 ? (
              <small className="text-muted" style={{ whiteSpace: 'nowrap' }}>
                {Utils.formatKNumber(item.count) + ' / '}
                <AllInclusiveIcon color="inherit" style={{ fontSize: 16 }} />
              </small>
            ) : (
              <small className="text-muted" style={{ whiteSpace: 'nowrap' }}>
                {Utils.formatKNumber(item.count) + ' / ' + Utils.formatKNumber(item.limit)}
              </small>
            )}
          </Box>
        </Box>
      );
    };

    const displayCard = () => {
      if (UserProfile.isAdmin() || UserProfile.isHRManager() || UserProfile.isRecruiter()) {
        return (
          <Card
            classes={{
              root: classes.cardRoot
            }}
          >
            <CardHeader
              avatar={<NetworkCheckIcon />}
              title={
                <FormattedMessage id="CardAccountLimits.title" defaultMessage="Your current plan" />
              }
              subheader={plan.plan_type}
            />
            <Divider light />

            <Box p={1}>
              {this.state._loading ? (
                <Skeleton variant="rect" animation="wave" width="100%" height={20} />
              ) : (
                listLimit('plan_jobs')
              )}
              {this.state._loading ? (
                <Skeleton variant="rect" animation="wave" width="100%" height={20} />
              ) : (
                listLimit('plan_vi')
              )}
              {this.state._loading ? (
                <Skeleton variant="rect" animation="wave" width="100%" height={20} />
              ) : (
                listLimit('plan_sms')
              )}
            </Box>
          </Card>
        );
      } else {
        return null;
      }
    };

    return <>{displayCard()}</>;
  }
}

CardAccountLimits.propTypes = {
  planInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  planInfo: state.planInfo.accountPlan
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(useStyles)(CardAccountLimits)));
