import axios from 'axios';
import uuid from 'react-uuid';
import UserProfile from '../Common/UserProfile';
import * as CONST from '../Common/constants';
import LogRocket from 'logrocket';

var PORT = '';
var URL_REALM = '';
if (window.location.hostname == 'localhost') {
  PORT = ':3000';
  URL_REALM = CONST.LOCALHOST_ACCOUNT;
} else {
  console.log(window.location.hostname);
  var split = window.location.hostname.split('.', 1);
  if (split) {
    URL_REALM = split[0];
  } else {
    // SOMETHING MUST BE DONE!
  }
}
const API_URL = window.location.protocol + '//' + window.location.hostname + PORT + '/api/';

class ApiLogin {
  constructor() {}

  login(credentials, type, cb) {
    let cred = {
      ...credentials,
      realm: URL_REALM
    };
    axios
      .post(API_URL + `AccountUsers/${type}`, cred)
      .then((response) => {
        if (
          response.data.session_audited &&
          (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test')
        ) {
          LogRocket.init('xlvvib/portal-recrutador');

          const UNIQUE_BROWSER_ID = sessionStorage.getItem('UNIQUE_BROWSER_ID') ?? uuid();
          sessionStorage.setItem('UNIQUE_BROWSER_ID', UNIQUE_BROWSER_ID); // identify browser - will not work anonymously or if the user removes cookies.
          sessionStorage.setItem('AUDIT_ERROS', true); // log js erros

          LogRocket.identify(response.data.id.toString(), {
            name: response.data.username,
            email: response.data.email,
            realm: response.data.realm,
            internalUser: response.data.internal_user,
            companyArea: response.data.company_area,
            browserId: response.data.UNIQUE_BROWSER_ID,
            token: response.data.accessToken
          });

          LogRocket.log('UNIQUE_BROWSER_ID', UNIQUE_BROWSER_ID); // visible only lockrocket
        } else {
          sessionStorage.setItem('AUDIT_ERROS', false);
        }

        const accessToken = response.data.accessToken;
        UserProfile.setToken(accessToken, cred.ttl || '');

        return cb(null, response.data);
      })
      .catch((error) => {
        console.log(error);
        //console.log(error.response.data.error)
        try {
          return cb(error.response.data.error);
        } catch (e) {
          return cb(error);
        }
      });
  }

  changePassword(params, cb) {
    let token = UserProfile.getToken();
    let par = {
      oldPassword: params.oldPassword,
      newPassword: params.newPassword
    };

    axios
      .post(API_URL + 'AccountUsers/change-password?access_token=' + token, par)
      .then((response) => {
        //console.log(response.data);
        return cb(null, true);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        return cb(error.response.data.error);
      });
  }

  resetPassword(params, cb) {
    let fullParams = {
      ...params,
      realm: URL_REALM
    };

    axios
      .post(API_URL + 'AccountUsers/request-password-reset', fullParams)
      .then((response) => {
        //console.log(response);
        cb(null, response);
      })
      .catch((error) => {
        console.log(error);
        cb(error);
      });
  }

  setPassword(params, token, cb) {
    axios
      .post(API_URL + 'AccountUsers/reset-password?access_token=' + token, params)
      .then(() => {
        cb();
      })
      .catch((error) => {
        console.log(error);
        cb(error);
      });
  }

  getAccount(cb) {
    const cred = {
      realm: URL_REALM
    };
    axios
      .get(API_URL + 'Accounts/is-active-sso-info', { params: cred })
      .then((response) => {
        return cb(null, response.data);
      })
      .catch((error) => {
        console.log(error);
        return cb(error);
      });
  }

  getSamlSessionId(id, cb) {
    axios
      .get(API_URL + `SamlSessions/get-saml-session-id/${id}`)
      .then((response) => {
        return cb(null, response.data);
      })
      .catch((error) => {
        console.log(error);
        return cb(error);
      });
  }

  /*
    getAccessToken(token, cb) {
        let filter = {
            where: {
                id: token
            }
        }
        axios.get(API_URL + 'AccountUsers/1/accessTokens?access_token=' + token + '&filter=' + encodeURI(JSON.stringify(filter)))
            .then( (response) => {
                console.log(response);
                return cb(null, response.data);
            })
            .catch( (error) => {
                console.log(error);
                return cb(error);
            });

    }
    */

  logout(cb) {
    const token = UserProfile.getToken();
    axios
      .post(API_URL + 'AccountUsers/logout?access_token=' + token)
      .then((response) => {
        // console.log(response);

        //UserProfile.clear();
        //this.redirectTo('/login');

        return cb(null, response.data);
      })
      .catch((error) => {
        console.log(error);

        //UserProfile.clear();
        //this.redirectTo('/login');

        return cb(error);
      });
  }

  revokeUser(user_id, cb) {
    if (user_id && user_id != '') {
      let token = UserProfile.getToken();

      axios
        .delete(API_URL + 'AccountUsers/' + user_id + '/accessTokens?access_token=' + token)
        .then(() => {
          return cb(null, true);
        })
        .catch((error) => {
          UserProfile.clear();

          console.log(error);
          return cb(error);
        });
    } else {
      cb('User ID must be provided');
    }
  }
}

export default ApiLogin;
