import React from 'react';
import { injectIntl, defineMessages, FormattedHTMLMessage } from 'react-intl';
import Moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { withStyles } from '@material-ui/core/styles';
import * as CONST from '../../Common/constants';

const messages = defineMessages({});

function getAbsoluteMonths(momentDate) {
  var months = Number(momentDate.format('MM'));
  var years = Number(momentDate.format('YYYY'));
  return months + years * 12;
}

const BadgeGreen = withStyles((theme) => ({
  badge: {
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: theme.palette.green.dark,
    fontSize: '0.55rem'
  }
}))(Badge);

const BadgeRed = withStyles((theme) => ({
  badge: {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: theme.palette.error.dark,
    fontSize: '0.55rem'
  }
}))(Badge);

class IconLastJobChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date,
      size: props.size ? props.size : 24
    };
  }

  render() {
    if (this.state.date) {
      let months = getAbsoluteMonths(Moment()) - getAbsoluteMonths(Moment(this.state.date));
      const date_format = Moment(this.state.date).format('MMMM YYYY');

      // Too soon
      if (months <= 6) {
        return (
          <Tooltip
            title={
              <FormattedHTMLMessage
                id="IconLastJobChange.jobChangeSoon"
                defaultMessage="Latest job change: {date}.<br /><br />People who recently changed jobs (or received promotion) are less likely to accept an offer."
                values={{
                  date: date_format
                }}
              />
            }
            arrow
          >
            <BadgeRed badgeContent={months} color="primary" max={12}>
              <ApartmentIcon
                style={{
                  fontSize: this.state.size,
                  color: CONST.APP_COLORS['gray-darker-regular']
                }}
              />
            </BadgeRed>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip
            title={
              <FormattedHTMLMessage
                id="IconLastJobChange.jobChange"
                defaultMessage="Latest job change: {date}."
                values={{
                  date: date_format
                }}
              />
            }
            arrow
          >
            <BadgeGreen badgeContent={months} color="primary" max={12}>
              <ApartmentIcon
                style={{
                  fontSize: this.state.size,
                  color: CONST.APP_COLORS['gray-darker-regular']
                }}
              />
            </BadgeGreen>
          </Tooltip>
        );
      }
    } else {
      return null;
    }
  }
}

export default injectIntl(IconLastJobChange);
