import React from 'react';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = (theme) => ({
  matchLabel: {
    color: theme.palette.gray.darkerMedium,
    overflow: 'hidden',
    display: '-webkit-box',
    maxWidth: '-webkit-fill-available',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    fontSize: '10px',
    lineHeight: '10px',
    textAlign: 'center',
    fontWeight: 500
  },
  matchHeading: {
    color: theme.palette.success.main,
    lineHeight: 'normal',
    fontSize: '1.25rem',
    fontWeight: 700,
    textAlign: 'center',
    '@media print': {
      color: `${theme.palette.gray.darkerMedium} `
    }
  },
  matchElement: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '78px',
    height: '78px',
    backgroundColor: theme.palette.success.light,
    padding: `${theme.spacing(1)}px`,
    borderRadius: '1rem',
    '@media print': {
      backgroundColor: `${theme.palette.gray.darkerMedium}10`
    }
  },
  infoElement: {
    background: `${theme.palette.gray.darkerLight}50`
  },
  infoHeading: {
    color: theme.palette.gray.darkerMedium
  },
  secondaryHeading: {
    fontSize: '14px'
  },
  infoScondaryHeading: {
    fontSize: '14px',
    color: theme.palette.gray.darkerRegular
  },
  wrappedText: {
    margin: 0,
    display: 'inline-block',
    width: '100%',
    maxWidth: '64px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

class MoreInfoTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Box
        className={`${classes.matchElement} ${
          this.props.variant === 'info' ? classes.infoElement : ''
        }`}
      >
        <Box mb={0.25}>
          <Typography
            className={`${classes.matchHeading}  ${
              this.props.variant === 'info' ? classes.infoHeading : ''
            }`}
          >
            {this.props.primaryText}
            {this.props.secondaryText ? (
              <span
                className={`${classes.secondaryHeading} ${
                  this.props.variant === 'info' ? classes.infoScondaryHeading : ''
                }`}
              >
                {this.props.secondaryText}
              </span>
            ) : null}
          </Typography>
        </Box>
        <Typography className={classes.matchLabel}>{this.props.label}</Typography>
      </Box>
    );
  }
}

export default injectIntl(withStyles(useStyles)(MoreInfoTabs));
