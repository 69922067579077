import { SET_JOBS, SET_LOADING_JOBS } from '../actions/actions';
import produce from 'immer';

const INITIAL_STATE = {
  openJobsCount: 0,
  loading: true
};

export default function jobsReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_JOBS: {
        draft.openJobsCount = action.payload;
        draft.loading = false;
        break;
      }
      case SET_LOADING_JOBS: {
        draft.loading = true;
        break;
      }
      default:
        return state;
    }
  });
}
