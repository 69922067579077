import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import * as CONST from '../../Common/constants';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import InfoOriginDetail from './InfoOriginDetail';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

const messages = defineMessages({
  origin: {
    id: 'ChipOrigin.origin',
    defaultMessage: 'Origin'
  },
  originForm: {
    id: 'ChipOrigin.originForm',
    defaultMessage: 'via Web Form'
  },
  originMail: {
    id: 'ChipOrigin.originMail',
    defaultMessage: 'via Email'
  },
  originReferral: {
    id: 'ChipOrigin.originReferral',
    defaultMessage: 'via Referral'
  },
  originImport: {
    id: 'ChipOrigin.originImport',
    defaultMessage: 'via Import'
  },
  originHunting: {
    id: 'ChipOrigin.originHunting',
    defaultMessage: 'via Hunting'
  },
  originScrap: {
    id: 'ChipOrigin.originScrap',
    defaultMessage: 'via Hunting'
  },
  originCopy: {
    id: 'ChipOrigin.originCopy',
    defaultMessage: 'Copied'
  },
  originMove: {
    id: 'ChipOrigin.originMove',
    defaultMessage: 'Moved'
  },
  originCrossrank: {
    id: 'ChipOrigin.originCrossrank',
    defaultMessage: 'AI Suggestion '
  },
  originUnknown: {
    id: 'ChipOrigin.originUnknown',
    defaultMessage: 'Unknown'
  },
  originSearch: {
    id: 'ChipOrigin.originSearch',
    defaultMessage: 'Search'
  },
  originRecruiter: {
    id: 'ChipOrigin.originRecruiter',
    defaultMessage: 'Via Recruiter'
  }
});

const useStyles = (theme) => ({
  generalChip: {
    background: theme.palette.gray.lighter,
    color: theme.palette.gray.darker,
    fontSize: '12px',
    fontWeight: 400,
    cursor: 'pointer'
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
    borderRadius: '100px',
    color: theme.palette.gray.darker,
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  labelWithIcon: {
    display: 'flex',
    alignItems: 'center'
    // marginLeft: theme.spacing(1)
  },
  iconButtonStyle: {
    padding: 0,
    borderRadius: '100px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

class ChipOrigin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicantInfo: props.applicantInfo,
      // origin: null,
      // Popover
      originAnchorEl: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicantInfo !== this.props.applicantInfo) {
      this.setState({
        applicantInfo: this.props.applicantInfo
      });
    }
  }

  handleOpenPopover = (e) => {
    this.setState({
      originAnchorEl: e.currentTarget
    });
  };

  handleClosePopover = () => {
    this.setState({
      originAnchorEl: null
    });
  };

  render() {
    const { classes } = this.props;
    const { applicantInfo } = this.state;
    const originDetailsOpen = Boolean(this.state.originAnchorEl);

    let origin_msg;
    let originStatus;

    switch (this.state.applicantInfo?.origin) {
      case CONST.ORIGIN_APPLY:
        originStatus = 'APPLY';
        origin_msg = this.props.intl.formatMessage({
          id: 'ChipOrigin.originForm'
        });
        break;
      case CONST.ORIGIN_MAILIN:
        if (applicantInfo?.referral_id) {
          originStatus = 'REFERRAL';
          origin_msg = this.props.intl.formatMessage({
            id: 'ChipOrigin.originReferral'
          });
        } else {
          originStatus = 'MAILIN';
          origin_msg = this.props.intl.formatMessage({
            id: 'ChipOrigin.originMail'
          });
        }
        break;
      case CONST.ORIGIN_IMPORT:
        originStatus = 'IMPORT';
        origin_msg = this.props.intl.formatMessage({
          id: 'ChipOrigin.originImport'
        });
        break;
      case CONST.ORIGIN_HUNT:
        originStatus = 'HUNTING';
        origin_msg = this.props.intl.formatMessage({
          id: 'ChipOrigin.originHunting'
        });
        break;
      case CONST.ORIGIN_SCRAP:
        originStatus = 'HUNTING';
        origin_msg = this.props.intl.formatMessage({
          id: 'ChipOrigin.originScrap'
        });
        break;
      case CONST.ORIGIN_MOVE:
        originStatus = 'MOVE';
        origin_msg = this.props.intl.formatMessage({
          id: 'ChipOrigin.originMove'
        });
        break;
      case CONST.ORIGIN_COPY:
        originStatus = 'COPY';
        origin_msg = this.props.intl.formatMessage({
          id: 'ChipOrigin.originCopy'
        });
        break;
      case CONST.ORIGIN_CROSSRANK:
        originStatus = 'CROSSRANK';
        origin_msg = this.props.intl.formatMessage({
          id: 'ChipOrigin.originCrossrank'
        });
        break;
      case CONST.ORIGIN_SEARCH:
        originStatus = 'SEARCH';
        origin_msg = this.props.intl.formatMessage({
          id: 'ChipOrigin.originSearch'
        });
        break;
      case CONST.ORIGIN_RECRUITER:
        originStatus = 'RECRUITER';
        origin_msg = this.props.intl.formatMessage({
          id: 'ChipOrigin.originRecruiter'
        });
        break;
      default:
        originStatus = 'UNKNOWN';
        origin_msg = this.props.intl.formatMessage({
          id: 'ChipOrigin.originUnknown'
        });
    }

    return (
      <Box display="flex">
        <IconButton
          className={classes.iconButtonStyle}
          color="default"
          onClick={(e) => this.handleOpenPopover(e)}
        >
          <Chip
            className={classes.generalChip}
            size="small"
            label={
              <span className={classes.labelWithIcon}>
                <ArrowForwardOutlinedIcon className={classes.infoIcon} />
                {origin_msg}
              </span>
            }
          />
        </IconButton>

        <Popover
          open={originDetailsOpen}
          anchorEl={this.state.originAnchorEl}
          onClose={this.handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box maxHeight="440px" minWidth="320px">
            <InfoOriginDetail applicantInfo={this.state.applicantInfo} origin={originStatus} />
          </Box>
        </Popover>
      </Box>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ChipOrigin));
