import UserProfile from '../Common/UserProfile';
import Api, { API_URL } from './Api';
import { store } from '../../store/store';
import {
  setOpenJobsCount,
  setLoadingJobs,
  setOpenRequestsCount,
  setLoadingRequests,
  setUser,
  setPlanInfo,
  setAccountInfo,
  setCompanyTree,
  setLoadingCompanyTree,
  setApplicantTags,
  setJobTags,
  setIdentityDocs,
  setIndustries,
  setLinkedinIndustries,
  setCountries
} from '../../store/actions/actions';

async function getOpenJobsCount() {
  store.dispatch(setLoadingJobs());

  let count = 0;
  let filter = {
    fields: ['id'],
    where: {
      status: 'PUBLISHED',
      deleted: false
    }
  };
  let api = new Api();
  api.get('Jobs', filter, (status, visibleJobs) => {
    //console.log(visibleJobs)
    count = visibleJobs.length;

    store.dispatch(setOpenJobsCount(count));
  });
}

async function getOpenRequestsCount() {
  store.dispatch(setLoadingRequests());

  let count = 0;
  let filter = {
    fields: ['id'],
    where: {
      status: { inq: ['APPROVED', 'WAITING_APPROVAL', 'REQUEST'] }
    }
  };
  let api = new Api();
  api.get('JobRequests', filter, (status, visibleReqs) => {
    // console.log(visibleReqs)
    count = visibleReqs.length;

    store.dispatch(setOpenRequestsCount(count));
  });
}

async function getPlanInfo() {
  let api = new Api();
  const planInfo = await api.getParamAsync('Accounts/plan-info', {});
  store.dispatch(setPlanInfo(planInfo.data));
}

async function getAccountInfo() {
  let api = new Api();

  let filter = {
    fields: [
      'account_id',
      'unique_name',
      'name',
      'industry',
      'abbreviation',
      'country',
      'integration_info',
      'config_params',
      'crossjob_active',
      'webhook_hire_config',
      'blocklist_active',
      'external_integration_info',
      'app_version'
    ],
    where: {
      account_id: UserProfile.getRealm()
    }
  };
  api.get('Accounts', filter, (status, accounts) => {
    //console.log(accounts)
    if (accounts.length > 0) {
      store.dispatch(setAccountInfo(accounts[0]));
    }
  });
}

async function getCompanyTree() {
  store.dispatch(setLoadingCompanyTree());

  let api = new Api();

  let filter = {
    //fields: ['account_id', 'unique_name', 'integration_info'],
    include: 'users',
    order: ['name']
  };
  api.get('CompanyTree', filter, (status, companyTree) => {
    store.dispatch(setCompanyTree(companyTree));
  });
}

async function getCountries() {
  let api = new Api();

  api.get('Countries', {}, (status, countries) => {
    if (countries.length > 0) {
      store.dispatch(setCountries(countries));
    }
  });
}

async function waitRequestStore(count = 0) {
  while (count < 5) {
    if (store.getState().jobs.loading === false) {
      return true;
    }
    await new Promise((resolve) => setTimeout(resolve, 3000));
    //console.log(count)
    count++;
  }
  return false;
}

/*
function syncJobsStreamCreated(job, upadtedJobs) {
  const allJobs = upadtedJobs ? upadtedJobs : store.getState().jobs.allJobs

  let filters = {
    ...JOB_PARAMS,
    where: {
      id: {
        inq: [job.id]
      }
    },
    counts: ['applicants', 'hunteds'],
    order: ['status', 'date_created']
  }
  let api = new Api()
  api.get('Jobs', filters, (status, visibleJobs) => {
    //console.log(visibleJobs)
    let storedJobs = { ...allJobs }
    store.dispatch(setJobs({ ...allJobs }))
    if (upadtedJobs) {
      allJobs[job.status] = [...allJobs[job.status], visibleJobs[0]].sort(
        (a, b) => {
          return new Date(a.date_created) - new Date(b.date_created)
        }
      )
      store.dispatch(setJobs(allJobs))
    } else {
      let jobs = Object.assign(storedJobs, {
        [visibleJobs[0].status]: [
          ...storedJobs[visibleJobs[0].status],
          visibleJobs[0]
        ]
      })
      store.dispatch(setJobs({ ...jobs }))
    }
  })
}

async function syncJobsStreamUpdated(job) {
  let api = new Api()
  const response = await api.getParamAsync('Jobs/status-list', {})
  let allJobs = store.getState().jobs.allJobs
  let updated = false
  response.data.forEach((statuslist) => {
    for (var i = allJobs[statuslist.name].length - 1; i >= 0; i--) {
      if (allJobs[statuslist.name][i].id === job.id) {
        let updateStatusJob = [...allJobs[statuslist.name]]
        updateStatusJob.splice(i, 1)
        syncJobsStreamCreated(job, {
          ...allJobs,
          [statuslist.name]: updateStatusJob
        })
        break
      }
    }
    if (updated) return
  })
}

async function syncJobsStreamWithStore(data) {
  if (await waitRequestStore()) {
    UserProfile.getVisibleJobs(data.data.status, (err, ids) => {
      if (ids.includes(data.data.id)) {
        switch (data.type) {
          case 'create':
            syncJobsStreamCreated(data.data)
            break
          case 'update':
            syncJobsStreamUpdated(data.data)
            break
          default:
        }
      }
    })
  } else {
    console.log('Job not synched after 15 seg') //TODO exibir mensagem pro usuário atualizar a página
  }
}

const registerStream = (model) => {
  const filter = {
    where: { account_id: UserProfile.getRealm() }
  }
  var urlToChangeStream = `${API_URL}${model}/change-stream?_format=event-stream&options=${encodeURI(
    JSON.stringify(filter)
  )}&access_token=${UserProfile.getToken()}`
  var src = new EventSource(urlToChangeStream)
  src.addEventListener('data', (msg) => {
    var data = JSON.parse(msg.data)
    //console.log(data)
    syncJobsStreamWithStore(data)
  })
}
*/

async function getUser() {
  if (!store.getState().user.user.username) {
    let userId = UserProfile.getId();
    let api = new Api();

    api.get('AccountUsers/' + userId, {}, (status, data) => {
      store.dispatch(setUser(data));
    });
  }
}

async function refreshUser() {
  let api = new Api();
  let userId = UserProfile.getId();
  api.get('AccountUsers/' + userId, {}, (status, data) => {
    store.dispatch(setUser(data));
  });
}

async function getApplicantTags() {
  let api = new Api();
  let tagsList = [];

  let filter = {
    where: {
      realm: UserProfile.getRealm(),
      deleted: false
      //active: true
    },
    order: ['name']
  };
  api.get('ApplicantTags', filter, (status, data) => {
    if (status >= 200) {
      tagsList = data;
      store.dispatch(setApplicantTags(tagsList));
    } else {
      console.log('Error on fetching data');
    }
  });
}

async function getJobTags() {
  let api = new Api();
  let tagsList = [];

  let filter = {
    where: {
      realm: UserProfile.getRealm(),
      deleted: false
    },
    order: ['name']
  };
  api.get('JobTags', filter, (status, data) => {
    if (status >= 200) {
      //console.log(data);
      tagsList = data;
      store.dispatch(setJobTags(tagsList));
    } else {
      console.log('Error on fetching data');
    }
  });
}

async function getIdentityDocs() {
  let api = new Api();
  let identityDocsList = [];

  let filter = {
    order: ['code DESC', 'country']
  };

  api.get('IdentityDocuments', filter, (status, data) => {
    if (status >= 200) {
      //console.log(data);
      identityDocsList = data;
      store.dispatch(setIdentityDocs(identityDocsList));
    } else {
      console.log('Error on fetching data');
    }
  });
}

async function getIndustries() {
  let api = new Api();
  let filter = {
    where: {
      language: store.getState().user.user.language.substr(0, 2)
    }
  };

  api.get('Industries', filter, (status, data) => {
    //console.log(data);
    if (status >= 200) {
      store.dispatch(setIndustries(data));
    } else {
      console.log('Error on fetching data');
    }
  });
}

async function getLinkedinIndustries() {
  let api = new Api();

  api.get('IndustryMappingLinkedIn', {}, (status, data) => {
    //console.log(data);
    if (status >= 200) {
      store.dispatch(setLinkedinIndustries(data));
    } else {
      console.log('Error on fetching data');
    }
  });
}

const initialize = async () => {
  //registerStream('Jobs')
  getAccountInfo();
  getPlanInfo();
  getOpenJobsCount();
  getOpenRequestsCount();
  getUser();
  getCompanyTree();
  getApplicantTags();
  getJobTags();
  getIdentityDocs();
  getIndustries();
  getLinkedinIndustries();
  getCountries();
};

const updatePlanInfo = async () => {
  getPlanInfo();
};

const updateAccountInfo = async () => {
  getAccountInfo();
};

const updateCompanyTree = async () => {
  getCompanyTree();
};

const updateApplicantTags = async () => {
  getApplicantTags();
};

const updateJobTags = async () => {
  getJobTags();
};

const updateUser = async () => {
  refreshUser();
};

const updateJobRequests = async () => {
  getOpenRequestsCount();
};

export {
  initialize,
  updatePlanInfo,
  updateAccountInfo,
  updateCompanyTree,
  updateApplicantTags,
  updateJobTags,
  updateUser,
  updateJobRequests
};
