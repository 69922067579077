import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import HumanizeDuration from 'humanize-duration';

const messages = defineMessages({});

const useStyles = (theme) => ({
  cardRoot: {
    border: '1px solid ' + theme.palette.gray.main
  }
});

class CardTimeToJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      directions_job: props.directions_job,
      // Internal State
      duration: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.directions_job !== this.props.directions_job) {
      this.setState(
        {
          directions_job: this.props.directions_job
        },
        () => {
          this.loadAnalysis();
        }
      );
    }
  }

  componentDidMount() {
    this.loadAnalysis();
  }

  loadAnalysis() {
    const directions_job = this.state.directions_job;
    if (
      directions_job &&
      directions_job.status === 'OK' &&
      directions_job.rows.length > 0 &&
      directions_job.rows[0].elements.length > 0
    ) {
      let duration = HumanizeDuration(directions_job.rows[0].elements[0].duration.value * 1000, {
        language: 'pt', // FIXME Lang!
        units: ['h', 'm'],
        spacer: ' ',
        round: true
      });

      this.setState({
        duration: duration
      });
    }
  }

  render() {
    const { classes } = this.props;

    const duration = this.state.duration;

    if (duration !== '') {
      return (
        <Card
          mb={2}
          classes={{
            root: classes.cardRoot
          }}
        >
          <CardHeader
            avatar={<DirectionsBusIcon />}
            title={
              <FormattedMessage id="CardTimeToJob.title" defaultMessage="Daily commute duration" />
            }
            subheader={duration}
          />
        </Card>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(CardTimeToJob));
