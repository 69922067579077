import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Api from '../../../Api/Api';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import FileViewer from 'react-file-viewer-extended';
import Iframe from 'react-iframe';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import ModalShareLink2 from '../../../Pipeline/ModalShareLink2';
import CloseIcon from '@material-ui/icons/Close';
import Moment from 'moment';
import LocaleFormatter from '../../../Common/LocaleFormatter';
import CVDetail from '../CV/CVDetail';
import UserProfile from '../../../Common/UserProfile';
import Utils from '../../../Common/Utils';
import generatePDF, { Margin } from 'react-to-pdf';
import PrintCustomCV from '../CV/PrintCustomCV';
import PersonalityReport from '../Personality/PersonalityReport';
import PrintPersonality from '../Personality/PrintPersonality';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';

const messages = defineMessages({});

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  dialog: {
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(2)}px `
    },

    '&.MuiDialog-paperFullWidth': {
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 16px)'
      }
    }
  },
  itemText: {
    color: theme.palette.gray.darkerMedium,
    lineHeight: 'normal'
  },
  itemIcon: {
    color: theme.palette.gray.darkerMedium
  },
  linkIcon: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  closeIconPrint: {
    display: 'none'
  },
  menuContainer: {
    minWidth: '340px'
  },
  menuItem: {
    cursor: 'default',
    '&: hover': {
      backgroundColor: 'transparent'
    },

    '&:active': {
      background: 'transparent'
    }
  },
  dialogTitle: {
    color: theme.palette.gray.darker,
    lineHeight: 'normal',
    fontWeight: 700,
    fontSize: '16px'
  },
  dialogSubtitle: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '12px',
    lineHeight: '22px'
  },
  dialogContent: {
    padding: `0 ${theme.spacing(5)}px `,
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(2)}px `
    }
  },
  chipUpdateCV: {
    background: `${theme.palette.primary.main}10`,
    color: theme.palette.primary.main,
    fontSize: '10px',
    fontWeight: 400,
    borderRadius: 4,
    height: 'auto',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.25),
    '&.MuiChip-labelSmall': {
      padding: '0 6px'
    }
  }
});

class ModalDisplayContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant: props.applicant,
      open: props.open,
      type: props.type,
      shareLinkDetails: props.shareLinkDetails,
      // Internal state
      cv_url: '',
      cv_ext: '',
      loading: false,
      printing: false,
      accountUsers: []
    };

    this.ref1 = React.createRef();
  }

  componentDidMount() {
    if (this.state.type === 'CV') {
      this.loadCVInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(
        {
          open: this.props.open
        },
        () => {
          if (this.state.type === 'CV') {
            this.loadCVInfo();
          }
        }
      );
    }

    if (prevProps.type !== this.props.type) {
      this.setState(
        {
          type: this.props.type
        },
        () => {
          if (this.state.type === 'CV') {
            this.loadCVInfo();
          }

          if (this.state.type === 'PRINT_DETAIL') {
            this.loadApplicantData();
            this.loadAccountUsers();
          }
        }
      );
    }

    if (prevProps.shareLinkDetails !== this.props.shareLinkDetails) {
      this.setState({
        shareLinkDetails: this.props.shareLinkDetails
      });
    }
  }

  loadAccountUsers = () => {
    let api = new Api();

    let userFilterAll = {
      fields: ['id', 'avatar_50_path', 'username', 'role', 'email'],
      where: {
        realm: UserProfile.getRealm()
      }
    };

    api.get('AccountUsers', userFilterAll, (status, users) => {
      this.setState({
        accountUsers: users
      });
    });
  };

  loadCVInfo = () => {
    let api = new Api();
    let params = {
      applicant_id: this.state.applicant.id
    };
    api.getParam('Applicants/link-cv', params, (status, data) => {
      let cv_url = '',
        cv_ext = '';

      if (data) {
        cv_url = data.url;
        cv_ext = data.ext;
      }
      this.setState({
        cv_url: cv_url,
        cv_ext: cv_ext,
        loading: false
      });
    });
  };

  printCV = () => {
    if (this.state.applicant) {
      this.setState(
        {
          printing: true
        },
        () => {
          const applicant = this.state.applicant;
          const job = applicant.job;
          const jobCode = job ? `[${job.code}] - ` : '';

          generatePDF(this.ref1, {
            filename: `${jobCode}${applicant.name}.pdf`,
            page: {
              // margin is in MM, default is Margin.NONE = 0
              margin: Margin.SMALL
            },

            overrides: {
              canvas: {
                imageTimeout: 10000,
                useCORS: true
              }
            }
          })
            .then(() => {
              this.setState({
                printing: false
              });
            })
            .catch((error) => {
              console.error('Error generating PDF:', error);
              this.setState({
                printing: false
              });
            });
        }
      );
      // window.print()
    }
  };

  getLink = () => {
    if (this.state.applicant) {
      this.setState(
        {
          loading: true
        },
        () => {
          let api = new Api();
          let params = {
            applicant_id: this.state.applicant.id
          };
          api.getParam('Applicants/download-cv', params, (status, data) => {
            if (data) {
              //console.log(data);
              let path = data;
              //this.props.history.push(path);
              const link = document.createElement('a');
              link.href = path;
              //link.target = '_new';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }

            this.setState({
              loading: false
            });
          });
        }
      );
    }
  };

  loadApplicantData = () => {
    const applicant_id = this.state.applicant.id;

    let api = new Api();

    let params = {
      applicant_id: applicant_id
    };

    api.getParam('Applicants/get-detail-info', params, (status, data) => {
      this.setState(
        {
          applicant: data
        },
        () => {
          const applicant = this.state.applicant;

          let jobParams = {
            job_id: applicant.job_id
          };

          api.getParam('Applicants/get-detail-job-info', jobParams, (status, jobData) => {
            const job = jobData;
            this.setState(
              {
                applicant: {
                  ...applicant,
                  job: job
                },
                loading: false
              },
              () => {
                this.loadInfoCounts();
              }
            );
          });
        }
      );
    });
  };

  loadInfoCounts = () => {
    const applicant_id = this.state.applicant.id;

    let api = new Api();

    let params = {
      applicant_id: applicant_id
    };

    api.getParam('Applicants/get-detail-info-counts', params, (status, data) => {
      const applicant = this.state.applicant;
      this.setState(
        {
          applicant: {
            ...applicant,
            ...data
          }
        },
        () => {
          if (data.videoInterviewsCount > 0) {
            this.loadVideoInterviews();
          }

          if (data.interviewsCount > 0) {
            this.loadInterviews();
          }

          if (data.applicantCustomTestsCount > 0) {
            this.loadCustomTests();
          }

          if (data.activeBlocklists > 0) {
            this.loadBlockList();
          }

          if (data.applicantFeedbackCount > 0) {
            this.loadApplicantFeedback();
          }
        }
      );
    });
  };

  loadVideoInterviews = () => {
    const applicant_id = this.state.applicant.id;
    const job_id = this.state.job_id;

    let api = new Api();
    let filter = {
      where: {
        applicant_id: applicant_id,
        job_id: job_id
      },
      include: [
        {
          relation: 'videoAnswers',
          scope: {
            include: {
              relation: 'ratings'
            }
          }
        },
        {
          relation: 'videoQuestions',
          scope: {
            include: {
              relation: 'userCreated',
              scope: {
                fields: ['username', 'avatar_50_path']
              }
            }
          }
        },
        {
          relation: 'job',
          scope: {
            fields: ['id', 'code', 'title']
          }
        }
      ],
      order: ['date_created DESC']
    };
    api.get('VideoInterviews', filter, (status, data) => {
      //console.log(data);
      if (data.length > 0) {
        const videoInterviews = data.filter((vi) => vi.completed === true);
        const pendingVideoInterviews = data.filter((vi) => !vi.completed);

        const updatedApplicant = {
          ...this.state.applicant,
          videoInterviews: videoInterviews,
          pendingVideoInterviews: pendingVideoInterviews
        };
        this.setState({
          applicant: updatedApplicant
        });
      }
    });
  };

  loadInterviews = () => {
    const applicant_id = this.state.applicant.id;

    let api = new Api();

    let filter = {
      where: {
        applicant_id: applicant_id
      },
      include: [
        {
          relation: 'ratings'
        }
      ],
      order: 'date_created DESC'
    };

    api.get('Interviews', filter, (status, data) => {
      if (data.length > 0) {
        const pipelineStages = this.state.applicant.job.pipeline_stages;

        const interviews = data;
        interviews.forEach((currInterview) => {
          const stage = pipelineStages.find((stage) => stage.id === currInterview.pipeline_stage);
          currInterview.title = stage ? stage.name : '';
        });

        interviews.sort(Utils.dynamicSort('-datetime_interview'));

        const updatedApplicant = {
          ...this.state.applicant,
          interviews: interviews
        };

        this.setState({
          applicant: updatedApplicant
        });
      }
    });
  };

  loadCustomTests = () => {
    const applicant_id = this.state.applicant.id;
    const job_id = this.state.applicant.job.id;

    let api = new Api();
    let filter = {
      where: {
        applicant_id: applicant_id,
        job_id: job_id
      },
      include: [
        {
          relation: 'customTest'
        }
      ],
      order: ['date_created DESC']
    };
    api.get('ApplicantCustomTests', filter, (status, data) => {
      //console.log(data);
      if (data.length > 0) {
        const jobPipeline = this.state.applicant.job.pipeline_stages;
        const allTests = data.filter(
          (currTest) =>
            currTest.customTest.calc_score === true &&
            jobPipeline.some((stageStatus) => stageStatus.id === currTest.pipeline_stage)
        );

        const allForms = data.filter(
          (currTest) =>
            currTest.customTest.calc_score === false &&
            currTest.completed === true &&
            jobPipeline.some((stageStatus) => stageStatus.id === currTest.pipeline_stage)
        );

        const customTests = allTests.filter((test) => test.completed === true);
        const pendingCustomTests = allTests.filter((test) => !test.completed);

        const customForms = allForms.filter((test) => test.completed === true);
        const pendingForms = allForms.filter((test) => !test.completed);

        const updatedApplicant = {
          ...this.state.applicant,
          customTests: customTests,
          pendingCustomTests: pendingCustomTests,
          customForms: customForms,
          pendingForms: pendingForms,
          allCustomTests: data
        };
        this.setState({
          applicant: updatedApplicant
        });
      }
    });
  };

  loadApplicantFeedback = () => {
    const applicant_id = this.state.applicant.id;

    let api = new Api();
    let filter = {
      where: {
        applicant_id: applicant_id
      }
    };

    api.get('ApplicantFeedbacks', filter, (status, data) => {
      //console.log(data);
      if (data.length > 0) {
        const updatedApplicant = {
          ...this.state.applicant,
          feedbacks: data
        };
        this.setState({
          applicant: updatedApplicant
        });
      }
    });
  };

  loadBlockList = () => {
    const actualDate = new Date();
    const applicant = this.state.applicant;
    let userFilter = {
      fields: [
        'id',
        'email',
        'doc_number',
        'user_id',
        'motive_id',
        'date_created',
        'date_limit',
        'active'
      ],
      where: {
        active: true,
        deleted: false,
        and: [
          {
            or: [{ date_limit: null }, { date_limit: { gte: actualDate.toISOString() } }]
          },
          {
            or: [{ email: applicant.email }, { doc_number: applicant.doc_number }]
          }
        ]
      },
      order: ['date_created DESC'],
      include: [
        {
          relation: 'accountUser',
          scope: {
            fields: ['id', 'username', 'avatar_50_path']
          }
        },
        {
          relation: 'blockMotives',
          scope: {
            fields: ['id', 'motive']
          }
        }
      ]
    };

    let api = new Api();
    api.get('Blocklists', userFilter, (status, motive) => {
      if (motive && motive.length > 0) {
        const updatedApplicant = {
          ...this.state.applicant,
          blockLists: motive[0]
        };
        this.setState({
          applicant: updatedApplicant
        });
      }
    });
  };

  handleCVModalOpen = () => {
    this.setState({
      open: true
    });
  };

  handleDialogClose = () => {
    this.setState(
      {
        open: false
      },
      () => {
        if (this.props.handleClose) {
          this.props.handleClose();
        }
      }
    );
  };

  handleShare = (type) => {
    this.setState({
      shareLinkModal: true,
      shareType: type
    });
  };

  handleMenuOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  callDialogEdit = (e) => {
    e.preventDefault();
    this.setState({
      editModal: true
    });
  };

  closeDialog = () => {
    this.setState(
      {
        editModal: false,
        anchorEl: null
      },
      () => {
        if (this.props.handlerUpdate) {
          this.props.handlerUpdate({
            ids: this.state.selected_ids
          });
        }
      }
    );
  };
  openShareLink = () => {
    this.setState({
      shareLinkModal: true
    });
  };

  handleModalClose = () => {
    this.setState(
      {
        shareLinkModal: false
      },
      () => {
        this.handleMenuClose();
      }
    );
  };

  handleSetPrinting = (printing) => {
    this.setState({
      printing: printing
    });
  };

  render() {
    const { classes } = this.props;

    const applicant = this.state.applicant;
    const type = this.state.type;

    const activity_update_cv = this.props.activity_update_cv;

    const displayContent = (currType) => {
      const modals = {
        CV: () => {
          if (this.state.cv_ext !== '') {
            if (this.state.cv_ext === 'doc') {
              // https://stackoverflow.com/questions/27957766/how-do-i-render-a-word-document-doc-docx-in-the-browser-using-javascript
              return (
                <Iframe
                  url={
                    'https://view.officeapps.live.com/op/embed.aspx?src=' +
                    encodeURIComponent(this.state.cv_url)
                  }
                  //url={"https://docs.google.com/gview?url=" + encodeURIComponent(this.state.cv_url) }
                  width="100%"
                  height="450px"
                  id="myId"
                  frameBorder={0}
                  className="myClassname"
                  display="initial"
                  position="relative"
                />
              );
            } else {
              return (
                <FileViewer
                  fileType={this.state.cv_ext}
                  filePath={this.state.cv_url}
                  onError={(e) => alert(e)}
                />
              );
            }
          } else {
            return null;
          }
        },
        PRINT_DETAIL: () => {
          const pipeline = applicant.job ? applicant.job.pipeline_stages : [];

          return (
            <>
              <CVDetail
                printing={this.state.printing}
                profile={applicant}
                job={applicant.job}
                pipeline={pipeline}
                readonly={true}
                shareLinkDetails={this.state.shareLinkDetails}
                accountUsers={this.state.accountUsers}
              />
            </>
          );
        },
        PERSONALITY: () => {
          return (
            <>
              <PersonalityReport applicant={applicant} />
            </>
          );
        }
      };

      return modals[currType]();
    };

    const displayTitle = (currType) => {
      const modals = {
        CV: () => {
          return (
            <FormattedMessage
              id="ModalDisplayContent.menu_originalCV"
              defaultMessage="Original CV"
            />
          );
        },
        PRINT_DETAIL: () => {
          return (
            <FormattedMessage
              id="ModalDisplayContent.menu_recrutAICV"
              defaultMessage="Recrut.AI's CV"
            />
          );
        },
        PERSONALITY: () => {
          return (
            <FormattedMessage
              id="ModalDisplayContent.menu_personalityReport"
              defaultMessage="Personality Report"
            />
          );
        }
      };

      return modals[currType]();
    };

    if (type) {
      return (
        <>
          <Dialog
            fullWidth={true}
            maxWidth={type === 'PERSONALITY' ? 'md' : 'lg'}
            open={this.state.open}
            onClose={this.handleDialogClose}
            classes={{
              paper: classes.dialog
            }}
          >
            <DialogTitle
              style={{
                padding: '16px 24px 0 16px'
              }}
            >
              <Box display="flex" alignItems="flex-start" justifyContent="space-between" mb={1}>
                <Box>
                  <Box mb={0.5}>
                    <Typography className={classes.dialogTitle}>{displayTitle(type)}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.dialogSubtitle}>
                      <FormattedMessage id="OtherJobs.msg_appliedIn" />{' '}
                      <LocaleFormatter
                        dateToFormat={Moment(applicant.date_created).format('lll')}
                      />
                    </Typography>{' '}
                    {activity_update_cv && (
                      <Chip
                        className={classes.chipUpdateCV}
                        label={
                          <FormattedMessage
                            id="ModalDisplayContent.update_CV"
                            defaultMessage="Updated on {date}"
                            values={{
                              date: moment(activity_update_cv.date_created).format('lll')
                            }}
                          />
                        }
                      />
                    )}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  {type !== 'PRINT_DETAIL' && type !== 'PERSONALITY' ? (
                    <Box mr={3}>
                      <IconButton
                        onClick={this.getLink}
                        style={{
                          padding: 0,
                          cursor: 'pointer'
                        }}
                      >
                        <SaveAltOutlinedIcon
                          fontSize="small"
                          className={`${classes.itemIcon} ${classes.linkIcon}`}
                        />
                      </IconButton>
                    </Box>
                  ) : null}

                  {type === 'PRINT_DETAIL' ? (
                    <Box mr={3}>
                      <PrintCustomCV
                        applicant={applicant}
                        shareLinkDetails={this.state.shareLinkDetails}
                        accountUsers={this.state.accountUsers}
                        handleSetPrinting={(printing) => this.handleSetPrinting(printing)}
                      />
                    </Box>
                  ) : null}

                  {type === 'PERSONALITY' ? (
                    <Box mr={3}>
                      <PrintPersonality
                        applicant={applicant}
                        handleSetPrinting={(printing) => this.handleSetPrinting(printing)}
                      />
                    </Box>
                  ) : null}

                  <Box mr={3}>
                    <IconButton
                      onClick={() => this.handleShare(type)}
                      style={{
                        padding: 0,
                        cursor: 'pointer'
                      }}
                      //disabled={type === 'PERSONALITY'}
                    >
                      <ShareOutlinedIcon
                        fontSize="small"
                        className={`${classes.itemIcon} ${classes.linkIcon}`}
                      />
                    </IconButton>
                  </Box>

                  <Box>
                    <IconButton
                      size="small"
                      style={{
                        padding: 0
                      }}
                      color="primary"
                    >
                      <CloseIcon onClick={() => this.handleDialogClose()} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Divider
                style={{
                  width: '100%'
                }}
              />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Box
                style={{
                  minHeight: '50vh'
                }}
              >
                {this.state.loading ? (
                  <Skeleton variant="rect" animation="wave" width="100%" height="50vh" />
                ) : (
                  displayContent(type)
                )}
              </Box>
            </DialogContent>
            <Backdrop className={classes.backdrop} open={this.state.printing}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Dialog>

          <ModalShareLink2
            open={this.state.shareLinkModal}
            details={this.state.shareLinkDetails}
            type={type}
            job_id={applicant.job_id}
            selected_ids={[applicant.id]}
            selected_objs={[applicant]}
            handlerClose={this.handleModalClose}
          />
        </>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(ModalDisplayContent));
