import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UserProfile from '../../../Common/UserProfile';
import Api from '../../../Api/Api';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import RecrutAIIcon from './RecrutAIIcon';
import ModalDisplayContent from './ModalDisplayContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import UserStorage from '../../../Common/UserStorage';
import Switch from '@material-ui/core/Switch';
import MobileFriendlyOutlinedIcon from '@material-ui/icons/MobileFriendlyOutlined';
import * as CONST from '../../../Common/constants';
import Utils from '../../../Common/Utils';
import ModalVideoInterviewOptions from '../../../Pipeline/ModalVideoInterviewOptions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import DateRange from '@material-ui/icons/DateRange';
import ModalForm from './ModalForm';
import ModalEvent from './ModalEvent';
import ComingChip from '../Common/ComingChip';
import NewChip from '../Common/NewChip';
import {
  IconOriginalCv,
  IconPersonalityReport,
  IconFormPersonality,
  IconNewInterview,
  IconNewInterviewApp
} from '../../../Icons/Icons';

const messages = defineMessages({
  title_sendDiversityForm: {
    id: 'MoreActionsCV.title_sendDiversityForm',
    defaultMessage: 'Send Diversity Form?'
  },
  msg_sendDiversityFormError: {
    id: 'MoreActionsCV.msg_sendDiversityFormError',
    defaultMessage: 'Error sending Diversity Form'
  },
  title_sendSalaryForm: {
    id: 'MoreActionsCV.title_sendSalaryForm',
    defaultMessage: 'Send Salary Form?'
  },
  msg_sendSalaryFormError: {
    id: 'MoreActionsCV.msg_sendSalaryFormError',
    defaultMessage: 'Error sending Salary Expectation Form'
  },
  title_sendPersonalityForm: {
    id: 'MoreActionsCV.title_sendPersonalityForm',
    defaultMessage: 'Send Personality Form?'
  },
  msg_sendPersonalityFormError: {
    id: 'MoreActionsCV.msg_sendPersonalityFormError',
    defaultMessage: 'Error sending Personality Form'
  },
  title_sendPostApplyForm: {
    id: 'MoreActionsCV.title_sendPostApplyForm',
    defaultMessage: 'Send Post Apply Form?'
  },
  msg_sendPostApplyFormError: {
    id: 'MoreActionsCV.msg_sendPostApplyFormError',
    defaultMessage: 'Error sending Post Apply Form'
  }
});

const useStyles = (theme) => ({
  itemText: {
    color: theme.palette.gray.darkerMedium,
    lineHeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal'
    }
  },
  itemIcon: {
    color: `${theme.palette.gray.darkerMedium} !important`
  },
  linkIcon: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  menuContainer: {
    minWidth: '340px',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'calc(70% - 60px)'
    }
  },
  menuItem: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'transparent'
    },

    '&:active': {
      background: '#transparent'
    }
  },
  containeInfoHidden: {
    flexBasis: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  containerDataChecklist: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  dialogTitle: {
    color: theme.palette.gray.darker,
    lineHeight: 'normal',
    fontWeight: 700,
    fontSize: '16px'
  },
  dialogSubtitle: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '12px',
    lineHeight: '22px'
  },
  sectionsDialogMessage: {
    color: theme.palette.gray.darker,
    lineHeight: '18px',
    fontSize: '14px',
    fontWeight: 500
  },
  dialogPaper: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '680px', // Set your width here
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          maxHeight: 'calc(90% - 64px)'
        }
      }
    }
  }
});

class MoreActionsCV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant: props.applicant,
      activity_update_cv: props.activity_update_cv,
      job: props.job,
      // Modals state
      viewCVModal: false,
      editModal: false,
      fitHistoryModal: false,
      // Menu
      openMenu: false,
      anchorEl: null,
      cv_url: '',
      cv_ext: '',
      downloading: false,
      shareModal: false,
      shareType: '',
      selectDetailsModal: false,
      details: {
        skills: true,
        about: true,
        contact: true,
        age: true,
        interviews: true,
        app_interviews: true,
        forms: true,
        tests: true,
        education: true,
        experiences: true,
        salary_info: true,
        certifications: true,
        languages: true,
        privacy: true,
        hide_block_motive: true,
        personality: false
      },
      formModal: false,
      formType: '',
      interviewsOpen: false,
      modalEvent: false,
      newEventModal: false,
      selectedEvent: undefined,
      interviewStageId: undefined,
      submitingInterview: false,
      modalVideoInterview: false,
      submitingVideoInterview: false
    };
  }

  async componentDidMount() {
    let details;

    try {
      [details] = await Promise.all([UserStorage.getLocalValueAsync('MoreActionsCV', '_details')]);
    } catch (err) {
      console.log(err);
    }

    if (!details) {
      details = {
        skills: true,
        about: true,
        contact: true,
        age: false,
        interviews: true,
        app_interviews: true,
        forms: true,
        tests: true,
        education: true,
        experiences: true,
        salary_info: true,
        certifications: true,
        languages: true,
        privacy: true,
        hide_block_motive: true,
        personality: false
      };
    }

    this.setState({
      details: details
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }
    if (prevProps.activity_update_cv !== this.props.activity_update_cv) {
      this.setState({
        activity_update_cv: this.props.activity_update_cv
      });
    }
  }

  getLink = () => {
    if (this.state.applicant) {
      this.setState(
        {
          downloading: true
        },
        () => {
          let api = new Api();
          let params = {
            applicant_id: this.state.applicant.id
          };
          api.getParam('Applicants/download-cv', params, (status, data) => {
            if (data) {
              //console.log(data);
              let path = data;
              //this.props.history.push(path);
              const link = document.createElement('a');
              link.href = path;
              //link.target = '_new';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }

            this.setState({
              downloading: false
            });
          });
        }
      );
    }
  };

  handleCVModalOpen = () => {
    this.setState(
      {
        shareModal: true
      },
      () => {
        let api = new Api();
        let params = {
          applicant_id: this.state.applicant.id
        };
        api.getParam('Applicants/link-cv', params, (status, data) => {
          let cv_url = '',
            cv_ext = '';

          if (data) {
            cv_url = data.url;
            cv_ext = data.ext;
          }
          this.setState({
            cv_url: cv_url,
            cv_ext: cv_ext,
            _loading: false
          });
        });
      }
    );
  };

  handleCVModalClose = () => {
    this.setState({
      shareModal: false
    });
  };

  handleOpenFitModal = () => {
    this.setState({
      shareModal: true,
      shareType: 'PERSONALITY'
    });
  };

  handleOpenDetailModal = () => {
    this.setState(
      {
        shareModal: true,
        shareType: 'PRINT_DETAIL'
      },
      () => {
        this.handleCloseCVDetailsModal();
      }
    );
  };

  handleOpenCVModal = () => {
    this.setState({
      shareModal: true,
      shareType: 'CV'
    });
  };

  handleOpenCVDetailsModal = () => {
    this.setState({
      selectDetailsModal: true
    });
  };

  handleCloseCVDetailsModal = () => {
    this.setState({
      selectDetailsModal: false
    });
  };

  handleChangeCvDetails = (e, field) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    const details = this.state.details;

    this.setState(
      {
        details: {
          ...details,
          [field]: value
        }
      },
      () => {
        UserStorage.setLocalValue('MoreActionsCV', '_details', this.state.details);
      }
    );
  };

  handleMenuOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  callDialogEdit = (e) => {
    e.preventDefault();
    this.setState({
      shareModal: true
    });
  };

  closeDialog = () => {
    this.setState({
      shareModal: false
    });
  };

  handleCloseModalEvent = () => {
    this.setState({
      modalEvent: false,
      selectedEvent: undefined,
      newEventModal: false
    });
  };

  sendDiversityForm = () => {
    window
      .swal({
        type: 'info',
        title: '',
        text: this.props.intl.formatMessage({
          id: 'MoreActionsCV.title_sendDiversityForm'
        }),
        closeOnConfirm: true,
        buttons: {
          cancel: {
            text: this.props.intl.formatMessage({
              id: 'Base.button.cancel'
            }),
            value: null,
            visible: true,
            className: '',
            closeModal: true
          },
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: '',
            closeModal: true
          }
        }
      })
      .then((isConfirmed) => {
        if (isConfirmed) {
          const applicant_id = this.state.applicant.id;
          let api = new Api();
          let payload = {
            applicant_id: applicant_id
          };
          api.post('Applicants/send-diversity-form', payload, (status, data) => {
            if (status >= 400) {
              window.swal({
                type: 'info',
                title: '',
                text: this.props.intl.formatMessage({
                  id: 'MoreActionsCV.msg_sendDiversityFormError'
                }),
                closeOnConfirm: true
              });
            }
          });
        }
      });
  };

  sendSalaryForm = () => {
    window
      .swal({
        type: 'info',
        title: '',
        text: this.props.intl.formatMessage({
          id: 'MoreActionsCV.title_sendSalaryForm'
        }),
        closeOnConfirm: true,
        buttons: {
          cancel: {
            text: this.props.intl.formatMessage({
              id: 'Base.button.cancel'
            }),
            value: null,
            visible: true,
            className: '',
            closeModal: true
          },
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: '',
            closeModal: true
          }
        }
      })
      .then((isConfirmed) => {
        if (isConfirmed) {
          const applicant_id = this.state.applicant.id;

          let api = new Api();
          let payload = {
            applicant_id: applicant_id
          };
          api.post('Applicants/send-salary-info-form', payload, (status, data) => {
            if (status >= 400) {
              window.swal({
                type: 'info',
                title: '',
                text: this.props.intl.formatMessage({
                  id: ' MoreActionsCV.msg_sendSalaryFormError'
                }),
                closeOnConfirm: true
              });
            }
          });
        }
      });
  };

  sendPersonalityForm = () => {
    window
      .swal({
        type: 'info',
        title: '',
        text: this.props.intl.formatMessage({
          id: 'MoreActionsCV.title_sendPersonalityForm'
        }),
        closeOnConfirm: true,
        buttons: {
          cancel: {
            text: this.props.intl.formatMessage({
              id: 'Base.button.cancel'
            }),
            value: null,
            visible: true,
            className: '',
            closeModal: true
          },
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: '',
            closeModal: true
          }
        }
      })
      .then((isConfirmed) => {
        if (isConfirmed) {
          const applicant_id = this.state.applicant.id;
          let api = new Api();
          let payload = {
            applicant_id: applicant_id
          };
          api.post('Applicants/send-personality-test', payload, (status, data) => {
            if (status >= 400) {
              window.swal({
                type: 'info',
                title: '',
                text: this.props.intl.formatMessage({
                  id: 'MoreActionsCV.msg_sendPersonalityFormError'
                }),
                closeOnConfirm: true
              });
            }
          });
        }
      });
  };

  sendPostApplyForm = () => {
    window
      .swal({
        type: 'info',
        title: '',
        text: this.props.intl.formatMessage({
          id: 'MoreActionsCV.title_sendPersonalityForm'
        }),
        closeOnConfirm: true,
        buttons: {
          cancel: {
            text: this.props.intl.formatMessage({
              id: 'Base.button.cancel'
            }),
            value: null,
            visible: true,
            className: '',
            closeModal: true
          },
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: '',
            closeModal: true
          }
        }
      })
      .then((isConfirmed) => {
        if (isConfirmed) {
          const applicant_id = this.state.applicant.id;
          let api = new Api();
          let payload = {
            applicant_id: applicant_id
          };
          api.post('Applicants/send-post-apply-form', payload, (status, data) => {
            if (status >= 400) {
              window.swal({
                type: 'info',
                title: '',
                text: this.props.intl.formatMessage({
                  id: 'MoreActionsCV.msg_sendPostApplyFormError'
                }),
                closeOnConfirm: true
              });
            }
          });
        }
      });
  };

  openShareLink = () => {
    this.setState({
      shareLinkModal: true
    });
  };

  handleModalClose = () => {
    this.setState(
      {
        shareLinkModal: false
      },
      () => {
        this.handleMenuClose();
      }
    );
  };

  handleOpenNewVideoInterviewModal = () => {
    this.setState({
      modalVideoInterview: true
    });
  };

  handleCloseNewVideoInterviewModal = () => {
    this.setState({
      modalVideoInterview: false
    });
  };

  handleUpdateInterviews = () => {
    if (this.props.handleUpdateInterviews) {
      this.props.handleUpdateInterviews();
    }
  };

  handleModalPostVideoInterview = ({ ids, options }) => {
    const job_id = this.state.job.id;
    const applicant = this.state.applicant;
    let videoInterviewStage;
    if (applicant && applicant.job && applicant.job.pipeline_stages) {
      const pipeline_stages = applicant.job.pipeline_stages;
      videoInterviewStage = pipeline_stages.find((stage) => stage.type === 'VIDEOINTERVIEW')?.id;
    }

    const thisStage = this.state.thisStage;
    const appVersion = this.props.accountInfo.accountInfo.app_version;

    this.setState(
      {
        submitingVideoInterview: true
      },
      () => {
        const payload = ids.map((applicant_id) => {
          return {
            account_id: UserProfile.getRealm(),
            job_id: job_id,
            applicant_id: applicant_id,
            pipeline_stage: videoInterviewStage ? parseInt(videoInterviewStage) : null,
            appVersion,
            ...options
          };
        });

        let api = new Api();
        api.post('VideoInterviews', payload, (status, newVideoInterviews) => {
          //console.log(newVideoInterviews);

          let videoQuestionsArr = [];
          newVideoInterviews.forEach((vi) => {
            options.questions.forEach((question) => {
              videoQuestionsArr.push({
                account_id: UserProfile.getRealm(),
                videointerview_id: vi.id,
                ...question
              });
            });
          });
          //console.log(videoQuestionsArr);

          api.post('VideoInterviewQuestions', videoQuestionsArr, (status, data) => {
            //console.log(data);
            this.setState(
              {
                submitingVideoInterview: false,
                modal: false
              },
              () => {
                if (this.props.handleUpdateVideoInterviews) {
                  this.props.handleUpdateVideoInterviews();
                }
              }
            );
          });
        });
      }
    );
  };

  handleOpenFormModal = (type) => {
    this.setState({
      formModal: true,
      formType: type
    });
  };

  handleCloseFormModal = () => {
    this.setState({
      formModal: false
    });
  };

  handleOpenEventModal = () => {
    this.setState({
      newEventModal: true
    });
  };

  handleCloseEventModal = () => {
    this.setState({
      newEventModal: false
    });
  };

  handleUpdateTests = () => {
    if (this.props.handleUpdateTests) {
      this.props.handleUpdateTests();
    }
  };

  render() {
    const { classes } = this.props;

    const applicant = this.state.applicant;

    const activity_update_cv = this.state.activity_update_cv;

    const salary_info = applicant.salary_info;

    let hasSalaryInfo = false;

    if (salary_info && Object.keys(salary_info).length > 0) {
      const isMinValueValid =
        salary_info.min !== undefined && salary_info.min !== null && salary_info.min > 0;
      const isCurrencyValid =
        salary_info.currency !== undefined &&
        salary_info.currency !== null &&
        salary_info.currency !== '';
      const isDurationValid =
        salary_info.duration !== undefined &&
        salary_info.duration !== null &&
        salary_info.duration !== '';

      if (isMinValueValid && isCurrencyValid && isDurationValid) {
        hasSalaryInfo = true;
      }
    }

    const hasPersonality = applicant.personality_profile && applicant.personality_profile.isProfile;

    const job = this.state.job;
    const pipeline_stages = job.pipeline_stages;
    const interviewStages = pipeline_stages
      ? pipeline_stages.filter((currStage) => currStage.type === CONST.STAGE_INTERVIEW)
      : [];

    const videoInterviewStage = pipeline_stages.find(
      (currStage) => currStage.type === CONST.STAGE_VIDEOINTERVIEW
    );

    let applicantStageObj = {
      name: '',
      type: ''
    };

    if (pipeline_stages && pipeline_stages.length > 0) {
      applicantStageObj = pipeline_stages.find(
        (currStage) => currStage.id === applicant.pipeline_stage
      );
    }

    if (!applicantStageObj) {
      applicantStageObj = {
        name: '',
        type: ''
      };
    }

    const jobHasDiversity = job.has_diversity;
    const jobHasSalaryForm = job.has_salary_form;
    const hasDiversity =
      UserProfile.isAdmin() ||
      (UserProfile.isDiversity() && applicant.pso && applicant.pso !== 'no') ||
      UserProfile.isAdmin() ||
      (UserProfile.isDiversity() && applicant.pwr && applicant.pwr !== 'no');

    const sendFormDisabled = applicantStageObj.type === CONST.STAGE_DISQUALIFIED;

    const displayButton = () => {
      let disabled = this.props.disabled;
      const isCandidateCommunicationEnabled = !applicant.email.startsWith('profile:');
      if (
        UserProfile.isAdmin() ||
        UserProfile.isHRManager() ||
        UserProfile.isRecruiter() ||
        UserProfile.isJobManager()
      ) {
        return (
          <>
            <Tooltip
              title={<FormattedMessage id="MoreActionsCV.tooltip" defaultMessage="CV actions" />}
              arrow
            >
              <IconButton
                disabled={disabled}
                size="small"
                style={{
                  padding: 0
                }}
              >
                <MoreVertIcon
                  onClick={this.handleMenuOpen}
                  style={{
                    fontSize: '1.25rem'
                  }}
                />
              </IconButton>
            </Tooltip>

            <Menu
              id="action-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleMenuClose}
              classes={{
                paper: classes.menuContainer
              }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <>
                {(UserProfile.isAdmin() ||
                  UserProfile.isHRManager() ||
                  UserProfile.isJobManager() ||
                  UserProfile.isRecruiter()) && (
                  <MenuItem onClick={this.handleOpenCVDetailsModal}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box display="flex" alignItems="center">
                        <Box mr={1} px="3px">
                          <RecrutAIIcon fontSize="small" className={classes.itemIcon} />
                        </Box>
                        <Box>
                          <Typography className={classes.itemText}>
                            <FormattedMessage
                              id="MoreActionsCV.menu_recrutaiCV"
                              defaultMessage="Recrut.AI's CV"
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <NewChip />
                    </Box>
                  </MenuItem>
                )}

                {(UserProfile.isAdmin() ||
                  UserProfile.isHRManager() ||
                  UserProfile.isJobManager() ||
                  UserProfile.isRecruiter()) &&
                hasPersonality ? (
                  <MenuItem onClick={this.handleOpenFitModal}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box display="flex" alignItems="center">
                        <Box mr={1}>
                          <IconPersonalityReport fontSize="small" className={classes.itemIcon} />
                        </Box>
                        <Box>
                          <Typography className={classes.itemText}>
                            <FormattedMessage
                              id="MoreActionsCV.menu_personalityReport"
                              defaultMessage="Personality Report"
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <NewChip />
                    </Box>
                  </MenuItem>
                ) : null}

                {applicant &&
                  applicant.cv_path &&
                  applicant.cv_path !== '' &&
                  (UserProfile.isAdmin() ||
                    UserProfile.isHRManager() ||
                    UserProfile.isJobManager() ||
                    UserProfile.isRecruiter()) && (
                    <MenuItem onClick={this.handleOpenCVModal}>
                      <Box display="flex" alignItems="center">
                        <Box mr={1}>
                          <IconOriginalCv
                            variant="read"
                            fontSize="small"
                            className={classes.itemIcon}
                          />
                        </Box>
                        <Box>
                          <Typography className={classes.itemText}>
                            <FormattedMessage
                              id="MoreActionsCV.menu_originalCV"
                              defaultMessage="Original CV"
                            />
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>
                  )}

                <Divider
                  style={{
                    margin: '1rem'
                  }}
                />

                {(UserProfile.isAdmin() ||
                  UserProfile.isHRManager() ||
                  UserProfile.isJobManager() ||
                  UserProfile.isRecruiter()) &&
                  isCandidateCommunicationEnabled && (
                    <>
                      <MenuItem
                        onClick={this.sendPersonalityForm}
                        disabled={!!applicant.personality_profile}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box display="flex" alignItems="center">
                            <Box mr={1}>
                              <IconFormPersonality fontSize="small" className={classes.itemIcon} />
                            </Box>
                            <Box>
                              <Typography className={classes.itemText}>
                                <FormattedMessage
                                  id="MoreActionsCV.forms_personality"
                                  defaultMessage="Personality and Cultural Form"
                                />
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Box>
                              <IconButton
                                style={{
                                  padding: 0,
                                  cursor: 'pointer'
                                }}
                              >
                                <SendOutlinedIcon
                                  fontSize="small"
                                  className={`${classes.itemIcon} ${classes.linkIcon}`}
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      </MenuItem>

                      {Utils.hasDiversity(job.country).canAskDiversity &&
                      (UserProfile.isAdmin() ||
                        UserProfile.isHRManager() ||
                        UserProfile.isJobManager() ||
                        UserProfile.isRecruiter()) ? (
                        <MenuItem
                          onClick={this.sendDiversityForm}
                          disabled={!jobHasDiversity || (jobHasDiversity && !hasDiversity)}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Box display="flex" alignItems="center">
                              <Box mr={1}>
                                <AccessibilityIcon fontSize="small" className={classes.itemIcon} />
                              </Box>
                              <Box>
                                <Typography className={classes.itemText}>
                                  <FormattedMessage
                                    id="MoreActionsCV.forms_diversity"
                                    defaultMessage="DEI Form"
                                  />
                                </Typography>
                              </Box>
                            </Box>

                            <Box display="flex" alignItems="center">
                              <Box>
                                <IconButton
                                  style={{
                                    padding: 0,
                                    cursor: 'pointer'
                                  }}
                                >
                                  <SendOutlinedIcon
                                    fontSize="small"
                                    className={`${classes.itemIcon} ${classes.linkIcon}`}
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>
                        </MenuItem>
                      ) : null}

                      {UserProfile.isAdmin() ||
                      UserProfile.isHRManager() ||
                      UserProfile.isJobManager() ||
                      UserProfile.isRecruiter() ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          pr={2}
                        >
                          <MenuItem onClick={this.sendSalaryForm} disabled={hasSalaryInfo}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Box display="flex" alignItems="center" mr={1}>
                                <Box mr={1}>
                                  <AttachMoneyOutlinedIcon
                                    fontSize="small"
                                    className={classes.itemIcon}
                                  />
                                </Box>
                                <Box>
                                  <Typography className={classes.itemText}>
                                    <FormattedMessage
                                      id="MoreActionsCV.forms_salary"
                                      defaultMessage="Salary Form"
                                    />
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </MenuItem>

                          <NewChip />
                        </Box>
                      ) : null}

                      {UserProfile.isAdmin() ||
                      UserProfile.isHRManager() ||
                      UserProfile.isJobManager() ||
                      UserProfile.isRecruiter() ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          pr={2}
                        >
                          <MenuItem onClick={this.sendPostApplyForm} disabled>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Box display="flex" alignItems="center" mr={1}>
                                <Box mr={1}>
                                  <AssignmentOutlinedIcon
                                    fontSize="small"
                                    className={classes.itemIcon}
                                  />
                                </Box>
                                <Box>
                                  <Typography className={classes.itemText}>
                                    <FormattedMessage
                                      id="MoreActionsCV.forms_postApply"
                                      defaultMessage="Post Apply Form"
                                    />
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </MenuItem>
                          <ComingChip />
                        </Box>
                      ) : null}
                      <Divider
                        style={{
                          margin: '1rem'
                        }}
                      />
                    </>
                  )}

                {(UserProfile.isAdmin() ||
                  UserProfile.isHRManager() ||
                  UserProfile.isJobManager() ||
                  UserProfile.isRecruiter()) &&
                isCandidateCommunicationEnabled &&
                interviewStages &&
                interviewStages.length > 0 ? (
                  <>
                    <MenuItem onClick={this.handleOpenEventModal}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Box display="flex" alignItems="center">
                          <Box mr={1}>
                            <IconNewInterview fontSize="small" className={classes.itemIcon} />
                          </Box>
                          <Box>
                            <Typography className={classes.itemText}>
                              <FormattedMessage
                                id="MoreActionsCV.menu_createNewInterview"
                                defaultMessage="Create new Schedule"
                              />
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </MenuItem>
                  </>
                ) : null}

                {(UserProfile.isAdmin() ||
                  UserProfile.isHRManager() ||
                  UserProfile.isJobManager() ||
                  UserProfile.isRecruiter()) &&
                  isCandidateCommunicationEnabled && (
                    <>
                      <MenuItem
                        onClick={() => this.handleOpenFormModal('TEST')}
                        disabled={sendFormDisabled}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box display="flex" disabled alignItems="center">
                            <Box mr={1}>
                              <AssignmentTurnedInOutlinedIcon
                                fontSize="small"
                                className={classes.itemIcon}
                              />
                            </Box>
                            <Box>
                              <Typography className={classes.itemText}>
                                <FormattedMessage
                                  id="MoreActionsCV.menu_createNewTest"
                                  defaultMessage="Create new Test"
                                />
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </MenuItem>
                    </>
                  )}

                {(UserProfile.isAdmin() ||
                  UserProfile.isHRManager() ||
                  UserProfile.isJobManager() ||
                  UserProfile.isRecruiter()) &&
                isCandidateCommunicationEnabled ? (
                  <MenuItem
                    onClick={() => this.handleOpenFormModal('FORM')}
                    disabled={sendFormDisabled}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box display="flex" alignItems="center">
                        <Box mr={1}>
                          <AssignmentOutlinedIcon fontSize="small" className={classes.itemIcon} />
                        </Box>
                        <Box>
                          <Typography className={classes.itemText}>
                            <FormattedMessage
                              id="MoreActionsCV.forms_data"
                              defaultMessage="Custom Form"
                            />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </MenuItem>
                ) : null}

                {(UserProfile.isAdmin() ||
                  UserProfile.isHRManager() ||
                  UserProfile.isJobManager() ||
                  UserProfile.isRecruiter()) &&
                isCandidateCommunicationEnabled &&
                videoInterviewStage ? (
                  <MenuItem onClick={this.handleOpenNewVideoInterviewModal}>
                    <Box display="flex" alignItems="center">
                      <Box mr={1}>
                        <IconNewInterviewApp fontSize="small" className={classes.itemIcon} />
                      </Box>
                      <Box>
                        <Typography className={classes.itemText}>
                          <FormattedMessage
                            id="MoreActionsCV.menu_createNewAppInterview"
                            defaultMessage="Create new In-App Interview"
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                ) : null}
              </>
            </Menu>

            {videoInterviewStage ? (
              <ModalVideoInterviewOptions
                open={this.state.modalVideoInterview}
                nextStage={videoInterviewStage.id}
                selected_ids={[applicant.id]}
                selected_objs={[applicant]}
                handlerClose={this.handleCloseNewVideoInterviewModal}
                handlerPost={this.handleModalPostVideoInterview}
                submiting={this.state.submitingVideoInterview}
              />
            ) : null}
          </>
        );
      } else {
        return null;
      }
    };

    return (
      <>
        {displayButton()}

        <Dialog
          fullWidth={true}
          open={this.state.selectDetailsModal}
          onClose={this.handleCloseCVDetailsModal}
          className={classes.dialogPaper}
        >
          <DialogTitle style={{ width: '100%' }}>
            <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
              <Typography className={classes.dialogSubtitle}>
                <FormattedMessage
                  id="MoreActionsCV.title_sharingSelectionItems"
                  defaultMessage="Sharing Options"
                />
              </Typography>
            </Box>
            <Divider
              style={{
                width: '100%'
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: '100%' }}>
            <Box mb={2}>
              <Typography className={classes.sectionsDialogMessage}>
                <FormattedMessage
                  id="MoreActionsCV.msg_sharingSelectionItems"
                  defaultMessage="Check what you want to share:"
                />
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" mb={4}>
              <Box mb={2} className={classes.containeInfoHidden}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '10rem',
                    marginLeft: '-0.25rem'
                  }}
                >
                  <Switch
                    color="primary"
                    size="small"
                    //value={this.state.details.about}
                    checked={this.state.details.privacy}
                    //disabled
                    onChange={(e) => this.handleChangeCvDetails(e, 'privacy')}
                  />

                  <Box mr={0.5}>
                    <Typography
                      className={classes.sectionsDialogMessage}
                      style={{
                        fontWeight: 400
                      }}
                    >
                      <FormattedMessage
                        id="MoreActionsCV.opt_hiddenProfile"
                        defaultMessage="Hide Personal Info"
                      />
                    </Typography>
                  </Box>
                  <Tooltip
                    title={
                      <FormattedMessage
                        id="MoreActionsCV.tooltip_hideContact"
                        defaultMessage="Personal information such as name, profile pic and contact details will be hidden"
                      />
                    }
                  >
                    <InfoOutlinedIcon fontSize="small" className={classes.itemText} />
                  </Tooltip>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Switch
                    color="primary"
                    size="small"
                    //value={this.state.details.about}
                    checked={this.state.details.hide_block_motive}
                    //disabled
                    onChange={(e) => this.handleChangeCvDetails(e, 'hide_block_motive')}
                  />

                  <Box mr={0.5}>
                    <Typography
                      className={classes.sectionsDialogMessage}
                      style={{
                        fontWeight: 400
                      }}
                    >
                      <FormattedMessage
                        id="MoreActionsCV.opt_hideBlockMotive"
                        defaultMessage="Don't display Restriction Details"
                      />
                    </Typography>
                  </Box>
                  <Tooltip
                    title={
                      <FormattedMessage
                        id="MoreActionsCV.tooltip_hideBlockMotive"
                        defaultMessage="With this option set, all retriction details will be hidden. Check this if you share information with people outside your organization."
                      />
                    }
                  >
                    <InfoOutlinedIcon fontSize="small" className={classes.itemText} />
                  </Tooltip>
                </Box>
              </Box>

              <Box className={classes.containerDataChecklist} gridColumnGap="1.8rem">
                <Box
                  style={{
                    flexBasis: '33%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography className={classes.dialogSubtitle}>
                    <FormattedMessage id="MoreActionsCV.section_profile" defaultMessage="Profile" />
                  </Typography>
                  <Divider
                    style={{
                      width: '100%',
                      margin: '0.5rem 0'
                    }}
                  />

                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '-0.75rem'
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.skills}
                        checked={this.state.details.skills}
                        onChange={(e) => this.handleChangeCvDetails(e, 'skills')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="CVProfileInfo.label_skills" />
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.education}
                        checked={this.state.details.education}
                        onChange={(e) => this.handleChangeCvDetails(e, 'education')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="CardAcademicLevel2.title" />
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.experiences}
                        checked={this.state.details.experiences}
                        onChange={(e) => this.handleChangeCvDetails(e, 'experiences')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="CardCVExperience.title" />
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.certifications}
                        checked={this.state.details.certifications}
                        onChange={(e) => this.handleChangeCvDetails(e, 'certifications')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="CVProfileInfo.label_certifications" />
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.languages}
                        checked={this.state.details.languages}
                        onChange={(e) => this.handleChangeCvDetails(e, 'languages')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="Hashtags.fit_language" />
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.salary_info}
                        checked={this.state.details.salary_info}
                        onChange={(e) => this.handleChangeCvDetails(e, 'salary_info')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="SalaryInfoEdit.label_salaryExpectation" />
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.personality}
                        checked={this.state.details.personality}
                        onChange={(e) => this.handleChangeCvDetails(e, 'personality')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage
                          id="MoreActionsCV.menu_personalityAnalysis"
                          defaultMessage="Personality Analysis"
                        />
                      </Typography>

                      <Box ml={1}>
                        <ComingChip />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  style={{
                    flexBasis: '33%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography className={classes.dialogSubtitle}>
                    <FormattedMessage
                      id="MoreActionsCV.section_personal"
                      defaultMessage="Personal and Contact Data"
                    />
                  </Typography>
                  <Divider
                    style={{
                      width: '100%',
                      margin: '0.5rem 0'
                    }}
                  />
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '-0.75rem'
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.about}
                        checked={this.state.details.about}
                        onChange={(e) => this.handleChangeCvDetails(e, 'about')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="MoreActionsCV.opt_about" defaultMessage="About" />
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.contact}
                        checked={this.state.details.contact}
                        onChange={(e) => this.handleChangeCvDetails(e, 'contact')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage
                          id="MoreActionsCV.opt_contact"
                          defaultMessage="Contact Info"
                        />
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.age}
                        checked={this.state.details.age}
                        onChange={(e) => this.handleChangeCvDetails(e, 'age')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="MoreActionsCV.title_age" defaultMessage="Age" />
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  style={{
                    flexBasis: '33%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography className={classes.dialogSubtitle}>
                    <FormattedMessage
                      id="MoreActionsCV.section_process"
                      defaultMessage="Selection Process"
                    />
                  </Typography>
                  <Divider
                    style={{
                      width: '100%',
                      margin: '0.5rem 0'
                    }}
                  />
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '-0.75rem'
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.interviews}
                        checked={this.state.details.interviews}
                        onChange={(e) => this.handleChangeCvDetails(e, 'interviews')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="CVDetail.title_interviews" />
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.app_interviews}
                        checked={this.state.details.app_interviews}
                        onChange={(e) => this.handleChangeCvDetails(e, 'app_interviews')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="CVDetail.title_appInterviews" />
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.forms}
                        checked={this.state.details.forms}
                        onChange={(e) => this.handleChangeCvDetails(e, 'forms')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="CVDetail.title_forms" />
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Checkbox
                        color="primary"
                        value={this.state.details.tests}
                        checked={this.state.details.tests}
                        onChange={(e) => this.handleChangeCvDetails(e, 'tests')}
                      />

                      <Typography className={classes.sectionsDialogMessage}>
                        <FormattedMessage id="CVDetail.title_customTests" />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="center" mb={2}>
              <Button variant="outlined" color="primary" onClick={this.handleOpenDetailModal}>
                <FormattedMessage
                  id="MoreActionsCV.label_buttonGenerate"
                  defaultMessage="Generate"
                />
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <ModalEvent
          applicant={applicant}
          job={job}
          open={this.state.newEventModal}
          handleClose={this.handleCloseEventModal}
          handleUpdateInterviews={this.handleUpdateInterviews}
        />

        <ModalDisplayContent
          open={this.state.shareModal}
          handleClose={this.closeDialog}
          type={this.state.shareType}
          applicant={this.state.applicant}
          shareLinkDetails={this.state.details}
          activity_update_cv={this.state.activity_update_cv}
        />

        <ModalForm
          open={this.state.formModal}
          job={job}
          handleClose={this.handleCloseFormModal}
          type={this.state.formType}
          applicant={this.state.applicant}
          handleUpdateTests={this.handleUpdateTests}
        />
      </>
    );
  }
}

MoreActionsCV.propTypes = {
  accountInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(MoreActionsCV)));
