import React from 'react';
import * as CONST from '../../../Common/constants';

class TagIconSvg extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const variant = this.props.variant;
    const size = this.props.size ? this.props.size : 22;

    return (
      <svg
        width="58"
        height="60"
        viewBox="0 0 58 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.28333 27.2261C6.28333 27.2261 16.5299 26.8158 18.7442 9.9726C20.7106 -4.9858 37.989 1.82272 42.9836 7.78549C49.4536 15.5098 46.0046 28.7971 54.3744 30.4757C62.7441 32.1543 57.2785 50.8171 44.5737 49.0308C28.7694 46.8086 32.5931 56.0438 27.8488 59.9527C24.4438 62.7581 10.4824 60.0575 10.0171 49.8737C9.6255 41.3041 6.01067 41.3312 3.44846 40.3596C-0.246841 38.9581 -2.57529 28.8086 6.28333 27.2261Z"
          fill={CONST.APP_COLORS['primary']}
          fillOpacity="0.1"
        />

        <svg
          width="64"
          height="64"
          viewBox="-7 -8 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.4369 26.3393C41.4383 25.1896 40.9945 24.0286 40.1157 23.1522L24.8133 7.84892C24.0249 7.06051 22.9702 6.59217 21.8551 6.53548L10.3933 5.95145L10.4759 5.95605C10.404 5.95229 10.3267 5.94922 10.2433 5.94922C9.05157 5.94922 7.9027 6.42223 7.05466 7.27103C6.20662 8.11838 5.73354 9.26718 5.73438 10.4589C5.73438 10.534 5.73675 10.6104 5.73982 10.687L6.3184 22.0714C6.37663 23.1858 6.8442 24.2406 7.63338 25.0296L22.9367 40.3329C23.8138 41.2108 24.9741 41.6547 26.1237 41.6532C27.2741 41.6547 28.4336 41.21 29.3108 40.3329L40.1166 29.5279L39.2853 28.6966L40.1174 29.5271C40.9945 28.6492 41.4384 27.4888 41.4369 26.3393ZM27.6483 38.6697C27.2244 39.0937 26.6794 39.3004 26.1237 39.302C25.568 39.3004 25.0231 39.0938 24.599 38.6697L9.29578 23.3664C8.91922 22.9899 8.69495 22.4855 8.66657 21.9505L8.08798 10.5683L8.08561 10.4589C8.08561 9.88789 8.31141 9.33978 8.71706 8.93343C9.12423 8.52625 9.67221 8.30045 10.2433 8.30045L10.348 8.30352L21.7348 8.88364C22.2706 8.91126 22.7741 9.13469 23.1508 9.51209L38.454 24.8153C38.8765 25.2385 39.0839 25.7835 39.0855 26.3392C39.0839 26.8949 38.8773 27.4406 38.4532 27.8654H38.454L27.6483 38.6697Z"
            fill={CONST.APP_COLORS['primary']}
            stroke={CONST.APP_COLORS['primary']}
            strokeWidth="0.3"
          />
          <path
            d="M13.1005 13.3154C12.029 14.3877 12.029 16.1236 13.1005 17.1951C14.1721 18.2667 15.908 18.2667 16.9795 17.1951C18.051 16.1236 18.051 14.3877 16.9795 13.3154C15.908 12.2438 14.1721 12.2438 13.1005 13.3154Z"
            fill={CONST.APP_COLORS['primary']}
            stroke={CONST.APP_COLORS['primary']}
            strokeWidth="0.5"
          />
        </svg>
      </svg>
    );
  }
}

export default TagIconSvg;
