import React, { Component } from 'react';
import { injectIntl, defineMessages, FormattedHTMLMessage } from 'react-intl';

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="footer-container" style={{ display: 'flex', justifyContent: 'center' }}>
        &copy; &nbsp;
        <FormattedHTMLMessage
          id="Footer.copySlogan"
          defaultMessage="2024 - <a href='https://recrut.ai' target='_new'>Recrut.AI</a> - Change the World with Every Hire"
        />
      </footer>
    );
  }
}

export default injectIntl(Footer);
