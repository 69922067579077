import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import VideoCallOutlinedIcon from '@material-ui/icons/VideoCallOutlined';
import ModalVideoInterviewOptions from './ModalVideoInterviewOptions';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import UserProfile from '../Common/UserProfile';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Api from '../Api/Api';

const messages = defineMessages({});

const StyledButton = withStyles((theme) => ({
  root: {
    boxShadow: `0px 3px 6px 0px ${theme.palette.black.main}`,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    '&:disabled': {
      background: theme.palette.grey[300]
    }
  }
}))(Button);

class ButtonNewVideoInterview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job_id: props.job_id,
      thisStage: props.thisStage,
      selected_ids: props.selected_ids,
      selected_objs: [], // All selected ids full objects
      applicants: props.applicants ? props.applicants : [], // All applicants from pipeline stage
      // Internal state
      submiting: false,
      // Modals
      modal: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.thisStage !== this.props.thisStage) {
      this.setState({
        thisStage: this.props.thisStage
      });
    }
    if (prevProps.selected_ids !== this.props.selected_ids) {
      let selected_objs = [];
      this.state.applicants.forEach((applicant) => {
        if (this.props.selected_ids.indexOf(applicant.id) > -1) {
          selected_objs.push(applicant);
        }
      });
      this.setState({
        selected_ids: this.props.selected_ids,
        selected_objs: selected_objs
      });
    }
    if (prevProps.applicants !== this.props.applicants) {
      let selected_objs = [];
      this.props.applicants.forEach((applicant) => {
        if (this.state.selected_ids.indexOf(applicant.id) > -1) {
          selected_objs.push(applicant);
        }
      });
      this.setState({
        applicants: this.props.applicants,
        selected_objs: selected_objs
      });
    }
  }

  onTransfer = () => {
    this.setState({
      modal: true
    });
  };

  handleModalClose = () => {
    this.setState({
      modal: false
    });
  };

  handleModalPost = ({ ids, options }) => {
    const job_id = this.state.job_id;
    const thisStage = this.state.thisStage;
    const appVersion = this.props.accountInfo.accountInfo.app_version;

    this.setState(
      {
        submiting: true
      },
      () => {
        const payload = ids.map((applicant_id) => {
          return {
            account_id: UserProfile.getRealm(),
            job_id: job_id,
            applicant_id: applicant_id,
            pipeline_stage: parseInt(thisStage),
            appVersion,
            ...options
          };
        });

        let api = new Api();
        api.post('VideoInterviews', payload, (status, newVideoInterviews) => {
          //console.log(newVideoInterviews);

          let videoQuestionsArr = [];
          newVideoInterviews.forEach((vi) => {
            options.questions.forEach((question) => {
              videoQuestionsArr.push({
                account_id: UserProfile.getRealm(),
                videointerview_id: vi.id,
                ...question
              });
            });
          });
          //console.log(videoQuestionsArr);

          api.post('VideoInterviewQuestions', videoQuestionsArr, (status, data) => {
            //console.log(data);
            this.setState(
              {
                submiting: false,
                modal: false
              },
              () => {
                if (this.props.handlerUpdate) {
                  this.props.handlerUpdate({
                    ids: ids,
                    options: options
                  });
                }
              }
            );
          });
        });
      }
    );
  };

  render() {
    const selected_ids = this.state.selected_ids;

    const displayButton = () => {
      let disabled = true;
      if (selected_ids.length > 0) {
        disabled = false;
      }

      return (
        <>
          {this.props.variant === 'detail' ? (
            <Button
              onClick={() => this.onTransfer()}
              disabled={disabled}
              size="small"
              color="primary"
              variant="outlined"
            >
              <FormattedMessage
                id="ButtonNewVideoInterview.buttonNew"
                defaultMessage="New recorded interview"
              />
            </Button>
          ) : (
            <Tooltip
              arrow
              title={
                <FormattedMessage
                  id="ButtonNewVideoInterview.tooltipNew"
                  defaultMessage="Create a new In-App Interview session"
                />
              }
            >
              <StyledButton
                variant="contained"
                size="small"
                color="primary"
                onClick={() => this.onTransfer()}
                disabled={disabled}
                startIcon={<VideoCallOutlinedIcon />}
              >
                <span className="hidden-xs hidden-sm">
                  <FormattedMessage
                    id="ButtonNewVideoInterview.buttonNew"
                    defaultMessage="New recorded interview"
                  />
                </span>
              </StyledButton>
            </Tooltip>
          )}

          <ModalVideoInterviewOptions
            open={this.state.modal}
            nextStage={this.state.thisStage}
            selected_ids={this.state.selected_ids}
            selected_objs={this.state.selected_objs}
            handlerClose={this.handleModalClose}
            handlerPost={this.handleModalPost}
            submiting={this.state.submiting}
          />
        </>
      );
    };

    return <>{displayButton()}</>;
  }
}

ButtonNewVideoInterview.propTypes = {
  accountInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo
});

export default connect(mapStateToProps, null)(injectIntl(ButtonNewVideoInterview));
