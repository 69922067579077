import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import UserProfile from '../../../Common/UserProfile';
import Api from '../../../Api/Api';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ModalEventOptions from '../../../Pipeline/ModalEventOptions';
import * as CONST from '../../../Common/constants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

const useStyles = (theme) => ({
  dialogTitle: {
    color: theme.palette.gray.darker,
    lineHeight: 'normal',
    fontWeight: 700,
    fontSize: '16px'
  }
});

class ModalEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant: props.applicant,
      job: props.job,
      open: props.open,
      // Modals state
      modalEvent: false,
      newEventModal: false,
      selectedEvent: undefined,
      interviewStageId: undefined,
      submitingInterview: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(
        {
          open: this.props.open
        },
        () => {
          if (this.state.open) {
            this.handleOpenEventModal();
          }
        }
      );
    }

    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }

    if (prevProps.job !== this.props.job) {
      this.setState({
        job: this.props.job
      });
    }
  }

  handleOpenModalEvent = (e, newEvent) => {
    this.setState({
      modalEvent: true,
      selectedEvent: newEvent
    });
  };

  handleCloseModalEvent = () => {
    this.setState(
      {
        modalEvent: false,
        selectedEvent: undefined,
        newEventModal: false
      },
      () => {
        if (this.props.handleClose) {
          this.props.handleClose();
        }
      }
    );
  };

  handleModalPostInterview = ({ ids, options }) => {
    const job_id = this.state.job.id;
    const thisStage = this.state.selectedEvent;

    if (thisStage) {
      this.setState(
        {
          submitingInterview: true
        },
        () => {
          const payload = ids.map((applicant_id) => {
            return {
              account_id: UserProfile.getRealm(),
              job_id: job_id,
              applicant_id: applicant_id,
              pipeline_stage: parseInt(thisStage.id),
              ...options
            };
          });

          let api = new Api();
          api.post('Jobs/create-event', { payload: payload }, (status, data) => {
            if (status === 400) {
              this.setState(
                {
                  submitingInterview: false,
                  interviewsOpen: false
                },
                () => {
                  window.swal({
                    type: 'warning',
                    title: '',
                    text: this.props.intl.formatMessage({
                      id: 'ModalEventOptions.err_' + data.error.name
                    }),
                    closeOnConfirm: true
                  });
                }
              );
            } else {
              this.setState(
                {
                  submitingInterview: false,
                  interviewsOpen: false
                },
                () => {
                  if (this.props.handleUpdateInterviews) {
                    this.props.handleUpdateInterviews();
                  }
                }
              );
            }
          });
        }
      );
    }
  };

  handleOpenEventModal = () => {
    const job = this.state.job;
    const pipeline_stages = job.pipeline_stages;
    const interviewStages = pipeline_stages
      ? pipeline_stages.filter((currStage) => currStage.type === CONST.STAGE_INTERVIEW)
      : [];

    if (interviewStages.length === 1) {
      this.handleOpenModalEvent(null, interviewStages[0]);
    } else {
      this.setState({
        newEventModal: true
      });
    }
  };

  handleCloseEventModal = () => {
    this.setState(
      {
        newEventModal: false
      },
      () => {
        if (this.props.handleClose) {
          this.props.handleClose();
        }
      }
    );
  };

  render() {
    const { classes } = this.props;

    const applicant = this.state.applicant;

    const job = this.state.job;
    const pipeline_stages = job ? job.pipeline_stages : undefined;
    const interviewStages = pipeline_stages
      ? pipeline_stages.filter((currStage) => currStage.type === CONST.STAGE_INTERVIEW)
      : [];

    let thisStageObj = {};

    if (this.state.selectedEvent) {
      thisStageObj = this.state.selectedEvent;
    } else {
      thisStageObj = {
        name: '',
        type: ''
      };
    }

    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={this.state.newEventModal}
          onClose={this.handleCloseEventModal}
        >
          <DialogTitle>
            <Typography className={classes.dialogTitle}>
              <FormattedMessage
                id="ModalEvent.title_createInterview"
                defaultMessage="Select Stage"
              />
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Autocomplete
                id="custom_test_id"
                value={this.state.selectedEvent}
                options={interviewStages}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={this.handleOpenModalEvent}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<FormattedMessage id="JobApplicantList.table.pipeline_stage" />}
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseEventModal} color="disabled">
              <FormattedMessage id="Base.button.cancel" />
            </Button>
          </DialogActions>
        </Dialog>
        {interviewStages && interviewStages.length > 0 ? (
          <ModalEventOptions
            open={this.state.modalEvent}
            nextStage={this.state.interviewStageId}
            nextStageObj={thisStageObj}
            selected_ids={[applicant.id]}
            selected_objs={[applicant]}
            handlerClose={this.handleCloseModalEvent}
            handlerPost={this.handleModalPostInterview}
            submitingInterview={this.state.submitingInterview}
            job_id={job.id}
          />
        ) : null}
      </>
    );
  }
}

ModalEvent.propTypes = {
  accountInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(ModalEvent)));
