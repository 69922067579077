import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import * as CONST from '../../../Common/constants';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AccessAlarmOutlinedIcon from '@material-ui/icons/AccessAlarmOutlined';
import Api from '../../../Api/Api';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import ModalReminder2 from '../ModalReminder2';
import Utils from '../../../Common/Utils';

const baseFilters = CONST.getApplicantDetailBaseFields();

const messages = defineMessages({});

const useStyles = (theme) => ({});

class TestMenuActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      test: props.test,
      applicant: props.applicant,
      thisStage: props.thisStage,
      pipeline: props.pipeline,
      // Internal state
      modal: false,
      extend: false,
      nr_applicant_ids: [],
      nr_model_ids: [],
      selected_model_ids: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.test !== this.props.test) {
      this.setState({
        test: this.props.test
      });
    }

    if (prevProps.pipeline !== this.props.pipeline) {
      this.setState({
        pipeline: this.props.pipeline
      });
    }

    if (prevProps.applicant !== this.props.applicant) {
      this.setState(
        {
          applicant: this.props.applicant
        },
        () => {
          this.loadNRs();
        }
      );
    }

    if (prevProps.thisStage !== this.props.thisStage) {
      this.setState(
        {
          thisStage: this.props.thisStage
        },
        () => {
          this.loadNRs();
        }
      );
    }
  }

  componentDidMount() {
    this.loadNRs();
  }

  loadNRs() {
    const applicant = this.state.applicant;

    let NR_ids = [];
    let NR_model_ids = [];

    const test = this.state.test;

    if (test.completed === false && new Date() <= new Date(test.date_limit)) {
      NR_ids.push(applicant.id);
      NR_model_ids.push({
        applicant_id: applicant.id,
        model_id: test.id
      });
    }
    this.setState({
      nr_applicant_ids: NR_ids,
      nr_model_ids: NR_model_ids
    });
  }

  handleOpenMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleSendReminder = () => {
    this.setState({
      extend: false,
      modal: true
    });
  };

  handleExtendLimit = () => {
    this.setState({
      extend: true,
      modal: true
    });
  };

  handleModalClose = () => {
    this.setState(
      {
        modal: false
      },
      () => {
        this.handleCloseMenu();
      }
    );
  };

  handleModalUpdate = (tests) => {
    this.setState(
      {
        modal: false
      },
      () => {
        if (tests && tests.length > 0) {
          this.setState(
            {
              test: tests[0]
            },
            () => {
              if (this.props.handleUpdateInterview) {
                this.props.handleUpdateInterview(tests[0]);
              }
            }
          );
        }
      }
    );
  };

  handleModalPost = ({ ids, mode }) => {
    this.setState(
      {
        modal: false
      },
      () => {
        if (this.props.handlerUpdate) {
          this.props.handlerUpdate({
            ids: ids,
            mode: mode
          });
        }
      }
    );
  };

  render() {
    const { classes } = this.props;

    const test = this.state.test;

    if (test) {
      const job_id = test.job_id;
      const nr_applicant_ids = this.state.nr_applicant_ids;
      const applicant = this.state.applicant;

      const thisStage = this.state.thisStage;
      let thisStageObj = {};
      const pipeline = this.state.pipeline;
      pipeline.sort(Utils.dynamicSort('priority'));
      pipeline.some((pipe) => {
        if (pipe.id === parseInt(thisStage)) {
          thisStageObj = pipe;
          return true;
        }
      });

      let reminderDisabled = false;
      if (!nr_applicant_ids.includes(applicant.id)) {
        reminderDisabled = true;
      }

      return (
        <>
          <IconButton onClick={this.handleOpenMenu}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="action-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleCloseMenu}
          >
            <MenuItem onClick={this.handleExtendLimit} disabled={reminderDisabled}>
              <ListItemIcon
                style={{
                  minWidth: 0,
                  marginRight: 12
                }}
              >
                <AvTimerIcon fontSize="small" />
              </ListItemIcon>
              <FormattedMessage
                id="TestMenuActions.menu_extendLimit"
                defaultMessage="Extend Limit"
              />
            </MenuItem>
            <MenuItem onClick={this.handleSendReminder} disabled={reminderDisabled}>
              <ListItemIcon
                style={{
                  minWidth: 0,
                  marginRight: 12
                }}
              >
                <AccessAlarmOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <FormattedMessage
                id="TestMenuActions.menu_sendReminder"
                defaultMessage="Send Reminder"
              />
            </MenuItem>
          </Menu>
          <ModalReminder2
            extend={this.state.extend}
            open={this.state.modal}
            thisStage={thisStage}
            thisStageObj={thisStageObj}
            job_id={job_id}
            selected_ids={[this.state.applicant.id]}
            nr_model_ids={this.state.nr_model_ids}
            handlerClose={this.handleModalClose}
            handlerPost={this.handleModalPost}
            selected_objs={[this.state.applicant]}
            model={'ApplicantCustomTest'}
          />
        </>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(TestMenuActions));
