import Cookies from 'cookies-js';
import jwt from 'jsonwebtoken';
import $ from 'jquery';
import Api from '../Api/Api';
import UniqueArr from 'node-unique-array';
import * as CONST from '../Common/constants';

var UserProfile = (function () {
  var getId = function () {
    let id = null;
    let token = this.getToken();
    if (token) {
      let decoded = jwt.decode(token);
      id = decoded.id;
    }
    return id;
  };

  var getToken = function () {
    return Cookies.get('userp_token');
  };

  var setToken = function (n_token, ttl) {
    let sessionCoockie = true;
    if (ttl > 60 * 60 * 24) {
      sessionCoockie = false;
    }
    Cookies.set('userp_token', n_token, {
      expires: sessionCoockie ? 0 : Infinity
    });
    Cookies.set('usera_token', n_token, {
      expires: sessionCoockie ? 0 : Infinity,
      domain: 'auth.recrut.ai'
    });
  };

  var getTokenLimit = function () {
    return null; // $.localStorage.get('userp_tokenLimit');
  };

  var getName = function () {
    let name = null;
    let token = this.getToken();
    if (token) {
      let decoded = jwt.decode(token);
      name = decoded.name;
    }
    return name;
  };

  var getEmail = function () {
    let email = null;
    let token = this.getToken();
    if (token) {
      let decoded = jwt.decode(token);
      email = decoded.email;
    }
    return email;
  };

  var getAvatar200 = function () {
    let avt200 = null;
    let token = this.getToken();
    if (token) {
      let decoded = jwt.decode(token);
      avt200 = decoded.avt200;
    }
    return avt200;
  };

  var getAvatar50 = function () {
    let avt50 = null;
    let token = this.getToken();
    //console.log(token);
    if (token) {
      let decoded = jwt.decode(token);
      avt50 = decoded.avt50;
    }
    return avt50;
  };

  var getProfile = function () {
    let perm = null;
    let token = this.getToken();
    if (token) {
      let decoded = jwt.decode(token);
      perm = decoded.perm;
    }
    return perm;
  };

  var isAdmin = function () {
    let isAdmin = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['ADMIN']) {
          isAdmin = true;
          return true;
        }
      });
    }
    return isAdmin;
  };

  var isPageAdmin = function () {
    let isPageAdmin = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['PAGE_ADMIN']) {
          isPageAdmin = true;
          return true;
        }
      });
    }
    return isPageAdmin;
  };

  var isUserAdmin = function () {
    let isUserAdmin = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['USER_ADMIN']) {
          isUserAdmin = true;
          return true;
        }
      });
    }
    return isUserAdmin;
  };

  var isHRManager = function () {
    let isHRManager = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['HR_MANAGER']) {
          isHRManager = true;
          return true;
        }
      });
    }
    return isHRManager;
  };

  var isProcessManager = function () {
    let isProcessManager = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['PROCESS_MANAGER']) {
          isProcessManager = true;
          return true;
        }
      });
    }
    return isProcessManager;
  };

  var isDiversity = function () {
    let isDiversity = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['DIVERSITY']) {
          isDiversity = true;
          return true;
        }
      });
    }
    return isDiversity;
  };

  var isTagUser = function () {
    let isTagUser = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['TAG_USER']) {
          isTagUser = true;
          return true;
        }
      });
    }
    return isTagUser;
  };

  var isJobPublisher = function () {
    return this.isRecruiter();
  };

  var isRecruiter = function () {
    let isSuper = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['RECRUITER']) {
          // MUST MIGRATE USERS FROM SUPER TO RECRUITER!
          isSuper = true;
          return true;
        }
      });
    }
    return isSuper;
  };

  var isJobManager = function () {
    let isJobManager = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['JOB_MANAGER']) {
          // MUST MIGRATE USERS FROM USER TO JOB_MANAGER!
          isJobManager = true;
          return true;
        }
      });
    }
    return isJobManager;
  };

  var isApprover = function () {
    let isSuper = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['APPROVER']) {
          isSuper = true;
          return true;
        }
      });
    }
    return isSuper;
  };

  var isRequester = function () {
    let isSuper = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['REQUESTER']) {
          isSuper = true;
          return true;
        }
      });
    }
    return isSuper;
  };

  var isReviewer = function () {
    let isSuper = false;
    let profileArr = this.getProfile();
    if (profileArr && Array.isArray(profileArr)) {
      profileArr.some((profile) => {
        if (profile === CONST.ROLE['REVIEWER']) {
          isSuper = true;
          return true;
        }
      });
    }
    return isSuper;
  };

  var isJustReviewer = function () {
    let profileArr = this.getProfile();

    if (profileArr && Array.isArray(profileArr)) {
      if (profileArr.length == 1 && profileArr[0] == CONST.ROLE['REVIEWER']) {
        return true;
      }
    }

    return false;
  };

  var getCompanyTreeDown = function (cb) {
    const token = this.getToken();
    if (token) {
      const decoded = jwt.decode(token);
      let id = decoded.id;

      let api = new Api();
      api.getParam('AccountUsers/company-tree-descendants', {}, (status, data) => {
        return cb(null, data);
      });
    } else {
      return cb(null, []);
    }
  };

  var getCompanyTreeDownAsync = async function (cb) {
    const token = this.getToken();
    if (token) {
      const decoded = jwt.decode(token);
      let id = decoded.id;

      let api = new Api();
      return await api.getParam('AccountUsers/company-tree-descendants', {}).data;
    } else {
      return [];
    }
  };

  var getCompanyTreeUp = function (cb) {
    const token = this.getToken();
    if (token) {
      const decoded = jwt.decode(token);
      let id = decoded.id;

      let api = new Api();
      let params = {
        user_id: id
      };
      api.getParam('AccountUsers/company-tree-ancestors', params, (status, data) => {
        return cb(null, data);
      });
    } else {
      return cb(null, []);
    }
  };

  var getVisibleJobs = function (status, cb) {
    const token = this.getToken();
    if (token) {
      let statusFilter = {};
      if (status) {
        statusFilter = {
          status: status
        };
      }

      let api = new Api();
      let uniqueJobIdsArr = new UniqueArr();

      let filterJob = {
        fields: ['id'],
        where: {
          ...statusFilter
        }
      };
      api.get('Jobs', filterJob, (status, jobTreeIds) => {
        jobTreeIds.forEach((job) => {
          uniqueJobIdsArr.add(job.id);
        });

        //console.log(uniqueJobIdsArr.get());
        return cb(null, uniqueJobIdsArr.get());
      });
    } else {
      return cb(null, []);
    }
  };

  var hasLogged = function () {
    let logged = false;
    if (this.getToken() || this.getToken() != '') {
      logged = true;
    } else {
      this.clear();
    }
    return logged;
  };

  var getRealm = function () {
    let realm = null;
    const token = this.getToken();
    if (token) {
      let decoded = jwt.decode(token);
      realm = decoded.realm;
    }
    return realm;
  };

  var getCurrentJob = function () {
    return Cookies.get('userp_currentJob');
  };

  var setCurrentJob = function (n_currentJob) {
    Cookies.set('userp_currentJob', n_currentJob, { expires: Infinity });
  };

  var getLang = function () {
    return Cookies.get('userp_lang');
  };

  var setLang = function (n_lang) {
    Cookies.set('userp_lang', n_lang, { expires: Infinity });
  };

  var getCronofyToken = function () {
    return $.localStorage.get('userp_cronofy_token');
  };

  var setCronofyToken = function (n_cronofy_token) {
    $.localStorage.set('userp_cronofy_token', n_cronofy_token);
  };

  var clear = function () {
    Cookies.expire('userp_token');
    Cookies.expire('userp_currentJob');
    Cookies.expire('userp_lang');
    $.localStorage && $.localStorage.removeAll();
    window.localStorage.clear(); // Need to do this at window level
  };

  var userRecruiterRestricted = function (userRecruiterId) {
    if (!userRecruiterId) {
      //console.log('Id do recrutador  é obrigátorio');
      return false;
    }

    const userPermission = this.isAdmin() || this.isHRManager();

    if (userPermission) {
      return true;
    }

    if (this.isRecruiter() && this.getId() == userRecruiterId) {
      return true;
    }

    return false;
  };

  return {
    getId: getId,
    getToken: getToken,
    setToken: setToken,
    getTokenLimit: getTokenLimit,
    getName: getName,
    getEmail: getEmail,
    getAvatar200: getAvatar200,
    getAvatar50: getAvatar50,
    getProfile: getProfile,
    isAdmin: isAdmin,
    isPageAdmin: isPageAdmin,
    isUserAdmin: isUserAdmin,
    isHRManager: isHRManager,
    isProcessManager: isProcessManager,
    isDiversity: isDiversity,
    isTagUser: isTagUser,
    isJobPublisher: isJobPublisher,
    isRecruiter: isRecruiter,
    isJobManager: isJobManager,
    isApprover: isApprover,
    isRequester: isRequester,
    isReviewer: isReviewer,
    isJustReviewer: isJustReviewer,
    getCompanyTreeDown: getCompanyTreeDown,
    getCompanyTreeUp: getCompanyTreeUp,
    getVisibleJobs: getVisibleJobs,
    hasLogged: hasLogged,
    getRealm: getRealm,
    getCurrentJob: getCurrentJob,
    setCurrentJob: setCurrentJob,
    getLang: getLang,
    setLang: setLang,
    getCronofyToken: getCronofyToken,
    setCronofyToken: setCronofyToken,
    clear: clear,
    userRecruiterRestricted: userRecruiterRestricted
  };
})();

export default UserProfile;
