import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import * as CONST from '../../Common/constants';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Api from '../../Api/Api';
import Avatar from 'react-avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Slider from '@material-ui/core/Slider';
import Moment from 'moment';
import MomentTZ from 'moment-timezone';
import Timezones from '../../Common/timezones';
import Select from 'react-select';
import { MobileDateTimePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import 'moment/locale/pt-br'; // FIXME locale
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import StageController from '../../Pipeline/StageController';
import Typography from '@material-ui/core/Typography';
import ModalAlertBlock from '../../Pipeline/ModalAlertBlock';

const messages = defineMessages({});

// MUST BE OUTSIDE OF COMPONENT
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  iconWarning: {
    color: `${theme.palette.warning.main} !important`
  }
});

class ModalSingleCustomTestOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      nextStageObj: props.nextStageObj,
      job_id: props.job_id,
      selected_ids: props.selected_ids,
      selected_objs: props.selected_objs,
      submiting: props.submiting,
      form: props.form,
      // Internal state
      limitType: 'DAYS', // default
      dateStart: '',
      dateLimit: Moment().add(2, 'days').set({ hour: 23, minute: 59, second: 0, millisecond: 0 }),
      daysLimit: 2,
      timezone: MomentTZ.tz.guess(), // FIXME locale
      timezoneList: Timezones,
      isBlocked: false,
      modalOpen: false
    };
    this.setLimitType = this.setLimitType.bind(this);
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(
        {
          open: this.props.open
        },
        () => {
          this.warningApplicantBlocked();
        }
      );
    }
    if (prevProps.nextStageObj !== this.props.nextStageObj) {
      this.setState({
        nextStageObj: this.props.nextStageObj
      });
    }
    if (prevProps.selected_ids !== this.props.selected_ids) {
      this.setState({
        selected_ids: this.props.selected_ids
      });
    }
    if (prevProps.selected_objs !== this.props.selected_objs) {
      this.setState({
        selected_objs: this.props.selected_objs
      });
    }
    if (prevProps.submiting !== this.props.submiting) {
      this.setState({
        submiting: this.props.submiting
      });
    }
    if (prevProps.form !== this.props.form) {
      this.setState({
        form: this.props.form
      });
    }
  }

  loadApplicants() {
    if (!this.state.selected_objs) {
      let api = new Api();
      let filter = {
        fields: ['id', 'name', 'email', 'avatar_50_path'],
        where: {
          id: {
            inq: this.state.selected_ids
          }
        },
        order: ['name']
      };
      api.get('Applicants', filter, (status, data) => {
        this.setState({
          selected_objs: data
        });
      });
    }
  }

  warningApplicantBlocked = () => {
    const isAnyBlocked = this.state.selected_objs.some((applicant) => {
      const { emailBlocklists, docBlocklists } = applicant;
      const activeBlockValidation =
        (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
        (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0);
      return activeBlockValidation === true;
    });

    this.setState({
      isBlocked: isAnyBlocked
    });
  };

  handleOpen = () => {
    // Loading default values depending on stage obj
    const nextStageObj = this.state.nextStageObj;

    if (nextStageObj && nextStageObj.date_start) {
      this.setState(
        {
          limitType: 'DATE'
        },
        () => {
          let newDate = Moment()
            .add(1, 'days')
            .set({ hour: 8, minute: 0, second: 0, millisecond: 0 });

          this.handleDateStartChange(newDate);
        }
      );
    }

    this.loadApplicants();
  };

  handleClose = () => {
    this.setState(
      {
        open: false,
        isBlocked: false,
        modalOpen: false
      },
      () => {
        if (this.props.handlerClose) {
          this.props.handlerClose();
        }
      }
    );
  };

  handleUpdate = (options) => {
    if (this.props.handlerPost) {
      this.props.handlerPost({
        ids: this.state.selected_ids,
        options: options
      });
    }
  };

  handleDateStartChange(value) {
    //console.log(value.toDate())
    this.setState({
      dateStart: value.toDate(),
      dateLimit: Moment(value).add(1, 'hours')
    });
  }

  handleDateLimitChange(value) {
    //console.log(value.toDate())
    this.setState({
      dateLimit: value.toDate()
    });
  }

  handleDaysLimitChange(e, days) {
    //console.log(days);
    let newDate = Moment()
      .add(days, 'days')
      .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
      .toDate();

    this.setState({
      daysLimit: days,
      dateLimit: newDate
    });
  }

  handleTimezoneChange = (timezone) => {
    let value = '';
    if (timezone) {
      value = timezone.value;
    }
    this.setState({ timezone: value });
  };

  handleModalOpen = () => {
    this.setState({
      open: false,
      modalOpen: true
    });
  };

  setLimitType(e) {
    //console.log(e.target.value);
    switch (e.target.value) {
      case 'DAYS':
        let newDate = Moment()
          .add(this.state.daysLimit, 'days')
          .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
          .toDate();

        this.setState({
          limitType: e.target.value,
          dateLimit: newDate
        });
        break;
      case 'DATE':
        this.setState({
          limitType: e.target.value
        });
        break;
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    const nextStageObj = this.state.nextStageObj;
    const dateLimit = this.state.dateLimit;
    const dateStart = this.state.dateStart;
    const timezone = this.state.timezone;

    if (nextStageObj && nextStageObj.date_start && dateStart === '') {
      window.swal({
        type: 'warning',
        title: '',
        text: this.props.intl.formatMessage({
          id: 'ModalCustomTestOptions.warningDateStart'
        }),
        closeOnConfirm: true
      });
    } else if (dateLimit === '') {
      window.swal({
        type: 'warning',
        title: '',
        text: this.props.intl.formatMessage({
          id: 'ModalCustomTestOptions.warningDateLimit'
        }),
        closeOnConfirm: true
      });
    } else {
      const options = {
        date_start:
          nextStageObj && nextStageObj.date_start && dateStart !== ''
            ? Moment(dateStart).toDate()
            : undefined,
        date_limit: Moment(dateLimit).toDate(),
        timezone: timezone
      };
      //console.log(options);

      this.handleUpdate(options);
    }
    this.handleClose();
  };

  render() {
    const { classes } = this.props;

    const form = this.state.form;

    const nextStageObj = this.state.nextStageObj;

    const listApplicants = this.state.selected_objs.map((applicant, i) => {
      let fullPath;
      if (applicant.avatar_50_path) {
        fullPath = 'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + applicant.avatar_50_path;
      }

      return (
        <ListItem key={applicant.id}>
          <ListItemAvatar>
            <Avatar
              name={applicant.name}
              email={applicant.email}
              src={fullPath}
              maxInitials={2}
              color={CONST.APP_COLORS['primary-light']}
              size="30px"
              round={true}
            />
          </ListItemAvatar>
          <ListItemText id={i} primary={applicant.name} />
        </ListItem>
      );
    });

    const displayDaysLimitDate = () => {
      if (this.state.daysLimit > 0 && this.state.limitType === 'DAYS') {
        let showDate = Moment(this.state.dateLimit).calendar();

        return (
          <p className="text-center mt0">
            <small>
              <FormattedHTMLMessage
                id="ModalCustomTestOptions.newLimitDate"
                values={{ newDate: showDate }}
              />
            </small>
          </p>
        );
      } else {
        return <p></p>;
      }
    };

    const displayNoCommunicationMsg = () => {
      const nextStageObj = this.state.nextStageObj;

      if (nextStageObj && !nextStageObj.sendEmail) {
        return (
          <Row>
            <Col md={12}>
              <Alert
                severity="warning"
                classes={{ icon: classes.iconWarning }}
                style={{
                  backgroundColor: this.state.isBlocked
                    ? CONST.APP_COLORS['danger-light']
                    : CONST.APP_COLORS['gray-light'],
                  color: this.state.isBlocked
                    ? CONST.APP_COLORS['danger-dark']
                    : CONST.APP_COLORS['gray-darker']
                }}
              >
                <FormattedMessage id="ModalCustomTestOptions.warningNoCommunication" />
              </Alert>
            </Col>
          </Row>
        );
      } else {
        return null;
      }
    };

    if (form) {
      return (
        <>
          {this.state.isBlocked && (
            <ModalAlertBlock
              open={this.state.open}
              handleModalOpen={this.handleModalOpen}
              handlerClose={this.handleClose}
            />
          )}
          <Dialog
            fullScreen
            open={this.state.isBlocked ? this.state.modalOpen : this.state.open}
            onEnter={this.handleOpen}
            onClose={this.handleClose}
            TransitionComponent={Transition}
          >
            <Backdrop open={this.state.submiting} className={classes.backdrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <form onSubmit={this.onSubmit}>
              <AppBar color="default" position="relative">
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={this.handleClose}
                    aria-label="close"
                    disabled={this.state.submiting}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Box flex={1}>
                    <h5 className="text-center text-uppercase">
                      <FormattedHTMLMessage
                        id="ModalCustomTestOptions.title"
                        values={{
                          name: form.label
                        }}
                      />
                    </h5>
                  </Box>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={this.state.submiting}
                    type="submit"
                  >
                    <FormattedMessage id="ModalCustomTestOptions.buttonSend" />
                  </Button>
                </Toolbar>
              </AppBar>
              {displayNoCommunicationMsg()}
              <Row>
                <Col md={8}>
                  <div className="panel panel-body">
                    <h4>
                      <FormattedMessage id="ModalCustomTestOptions.subTitle" />
                    </h4>
                    <p className="text-muted mb">
                      <small>
                        <FormattedMessage id="ModalCustomTestOptions.subTitleMsg" />
                      </small>
                    </p>
                    <fieldset>
                      {nextStageObj && nextStageObj.date_start ? null : (
                        <>
                          <Row className="mb mt">
                            <Col md={5}>
                              <FormControlLabel
                                name="limit_type"
                                value="DAYS"
                                checked={this.state.limitType === 'DAYS'}
                                onChange={(e) => this.setLimitType(e)}
                                control={<Radio color="primary" />}
                                labelPlacement="end"
                                label={
                                  <FormattedMessage id="ModalCustomTestOptions.option_numberDays" />
                                }
                              />
                            </Col>
                            <Col md={7}>
                              <div style={{ width: '100%' }}>
                                <Slider
                                  name="dayslimit"
                                  className="mt"
                                  value={this.state.daysLimit}
                                  step={1}
                                  marks
                                  min={1}
                                  max={5}
                                  valueLabelDisplay="on"
                                  onChange={(e, value) => this.handleDaysLimitChange(e, value)}
                                  disabled={this.state.limitType === 'DATE'}
                                  required={this.state.limitType === 'DAYS'}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={5}>
                              <p className="text-center text-muted">
                                <FormattedMessage id="ModalCustomTestOptions.option_or" />
                              </p>
                            </Col>
                            <Col md={7} className="text-center">
                              {displayDaysLimitDate()}
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row className="mt">
                        <Col md={5}>
                          <FormControlLabel
                            name="limit_type"
                            value="DATE"
                            checked={this.state.limitType === 'DATE'}
                            onChange={(e) => this.setLimitType(e)}
                            control={<Radio color="primary" />}
                            labelPlacement="end"
                            label={
                              <FormattedMessage id="ModalCustomTestOptions.option_SpecificDay" />
                            }
                          />
                        </Col>
                        <Col md={7}>
                          {nextStageObj && nextStageObj.date_start ? (
                            <>
                              <MobileDateTimePicker
                                name="datetime_start"
                                disablePast={true}
                                minutesStep={5}
                                ampm={false}
                                autoOk={true}
                                //format="d MMM yyyy hh:mm"
                                value={this.state.dateStart}
                                onChange={(value) => this.handleDateStartChange(value)}
                                required={
                                  this.state.limitType === 'DATE' &&
                                  this.state.nextStageObj.date_start === true
                                }
                                disabled={this.state.limitType === 'DAYS'}
                                renderInput={(props) => (
                                  <TextField
                                    {...props}
                                    helperText={this.props.intl.formatMessage({
                                      id: 'ModalCustomTestOptions.field_dateStart'
                                    })}
                                  />
                                )}
                              />
                              <br />
                            </>
                          ) : null}
                          <MobileDateTimePicker
                            name="datetime_limit"
                            disablePast={true}
                            minutesStep={5}
                            ampm={false}
                            autoOk={true}
                            //format="d MMM yyyy hh:mm"
                            value={this.state.dateLimit}
                            onChange={(value) => this.handleDateLimitChange(value)}
                            required={this.state.limitType === 'DATE'}
                            disabled={this.state.limitType === 'DAYS'}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                helperText={this.props.intl.formatMessage({
                                  id: 'ModalCustomTestOptions.field_dateLimit'
                                })}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={5}>
                          <label>
                            <FormattedMessage id="ModalCustomTestOptions.timezone" />
                          </label>
                          <p className="text-muted">
                            <small>
                              <FormattedMessage id="ModalCustomTestOptions.timezoneHelp" />
                            </small>
                          </p>
                        </Col>
                        <Col md={7}>
                          <Select
                            name="timezone"
                            required={true}
                            clearable={false}
                            value={this.state.timezone}
                            options={this.state.timezoneList}
                            onChange={this.handleTimezoneChange}
                            menuContainerStyle={{ zIndex: 999 }}
                          />
                        </Col>
                      </Row>
                    </fieldset>
                  </div>
                </Col>
                <Col md={4} style={{ borderLeft: '1px solid silver' }}>
                  <div className="panel panel-body">
                    <h4>
                      <FormattedMessage id="ModalCustomTestOptions.title_selectedApplicants" />
                    </h4>
                    {this.state.isBlocked && (
                      <Alert
                        severity="warning"
                        classes={{ icon: classes.iconWarning }}
                        style={{
                          backgroundColor: this.state.isBlocked
                            ? CONST.APP_COLORS['danger-light']
                            : CONST.APP_COLORS['gray-light'],
                          color: this.state.isBlocked
                            ? CONST.APP_COLORS['danger-dark']
                            : CONST.APP_COLORS['gray-darker']
                        }}
                      >
                        <Typography>
                          <FormattedMessage id="AlertApplicantBlocked.alert_selected" />
                        </Typography>
                      </Alert>
                    )}
                    <List dense style={{ width: '100%' }}>
                      {listApplicants}
                    </List>
                  </div>
                </Col>
              </Row>
            </form>
          </Dialog>
        </>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(ModalSingleCustomTestOptions));
