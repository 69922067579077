import * as CONST from '../Common/constants';
import CardController from '../Applicant/CardController';
import StageController from './StageController';

// Column configurations
const columns = {
  favorites: {
    name: 'favorites',
    title: null,
    getCellValue: (row) => (row.favorites ? row.favorites.length : undefined)
  },
  internal: {
    name: 'internal',
    title: null,
    sortable: true
  },
  overall_score: {
    name: 'overall_score',
    title: 'Pipeline.table.overall_score',
    getCellValue: (row) =>
      parseFloat(row.overall_score_prog) > -1 ? parseFloat(row.overall_score_prog) : -1,
    sortable: true
  },
  cv_score: {
    name: 'cv_score',
    title: 'Pipeline.table.score',
    getCellValue: (row) => (parseFloat(row.cv_score) > -1 ? parseFloat(row.cv_score) : -1),
    sortable: true
  },
  profile_score: {
    name: 'profile_score',
    title: 'Pipeline.table.score',
    getCellValue: (row) =>
      parseFloat(row.profile_score) > -1 ? parseFloat(row.profile_score) : -1,
    sortable: true
  },
  interview_score: {
    name: 'interview_score',
    title: 'Pipeline.table.score',
    getCellValue: (row) =>
      parseFloat(row.interview_score) > -1
        ? parseFloat(CardController.getInterviewScore(row.interview_score))
        : -1,
    sortable: true
  },
  videointerview_score: {
    name: 'videointerview_score',
    title: 'Pipeline.table.score',
    getCellValue: (row) =>
      parseFloat(row.videointerview_score) > -1
        ? parseFloat(CardController.getVideoInterviewScore(row.videointerview_score))
        : -1,
    sortable: true
  },
  customtest_score: {
    name: 'customtest_score',
    title: 'Pipeline.table.score',
    getCellValue: (row) =>
      parseFloat(row.customtest_score) > -1
        ? parseFloat(CardController.getCustomTestScorePerc(row.customtest_score))
        : -1,
    sortable: true
  },
  avatar_50_path: {
    name: 'avatar_50_path',
    title: null
  },
  name: {
    name: 'name',
    title: 'Pipeline.table.name',
    searchable: true,
    searchFields: ['name', 'referer.name', 'referer.host'],
    sortable: true
  },
  views: {
    name: 'views',
    title: null,
    getCellValue: (row) =>
      row.views && row.views.length > 0 ? row.views[0].date_created : undefined
  },
  notes: {
    name: 'notes',
    title: null,
    getCellValue: (row) => row.notesCount
  },
  cv_path: {
    name: 'cv_path',
    title: null,
    getCellValue: (row) =>
      row.cv_path ? CONST.S3_BASE_URL + CONST.S3_BUCKET_URL + row.cv_path : undefined
  },
  origin: {
    name: 'origin',
    title: null,
    sortable: true
  },
  email: {
    name: 'email',
    title: null,
    searchable: true,
    searchFields: ['email'],
    sortable: true
  },
  phone_mobile: {
    name: 'phone_mobile',
    title: null,
    searchable: true,
    searchFields: ['phone_mobile']
  },
  pwd: {
    name: 'pwd',
    title: null,
    sortable: true
  },
  pso: {
    name: 'pso',
    title: null,
    sortable: true
  },
  pwr: {
    name: 'pwr',
    title: null,
    sortable: true
  },
  age: {
    name: 'age',
    title: 'Pipeline.table.age',
    searchable: true,
    searchFields: ['age'],
    sortable: true
  },
  gender: {
    name: 'gender',
    title: null,
    sortable: true
  },
  address: {
    name: 'address',
    title: 'Pipeline.table.address',
    getCellValue: (row) => {
      let addr = [];
      if (row.address) {
        if (row.address.neiborhood) {
          addr.push(row.address.neiborhood);
        }
        if (row.address.city) {
          addr.push(row.address.city);
        }
        if (row.address.state) {
          addr.push(row.address.state);
        }

        return addr.join(' ');
      } else {
        return undefined;
      }
    },
    searchable: true,
    searchFields: ['address.street', 'address.city', 'address.state', 'address.neiborhood'],
    sortable: true,
    sortField: 'address.city'
  },
  distance_job: {
    name: 'distance_job',
    title: 'Pipeline.table.distance_job',
    sortable: true
  },
  date_created: {
    name: 'date_created',
    title: 'Pipeline.table.date_created',
    sortable: true
  },
  mbti: {
    name: 'mbti',
    title: null,
    getCellValue: (row) => {
      if (
        row.personalityAnalysis &&
        row.personalityAnalysis.length > 0 &&
        row.personalityAnalysis[0].mbti
      ) {
        return row.personalityAnalysis[0].mbti;
      } else {
        return undefined;
      }
    }
  },
  personality_test: {
    name: 'personality_test',
    title: 'Pipeline.table.behavior_performance',
    getCellValue: (row) => {
      if (row.personalityTests && row.personalityTests.length > 0) {
        return row.personalityTests[0].date_created;
      } else {
        return undefined;
      }
    },
    sortable: true,
    sortField: 'profile_score'
  },
  interviews: {
    name: 'interviews',
    title: 'Pipeline.table.interviews',
    getCellValue: (row) => {
      if (row.interviews && row.interviews.length > 0) {
        return row.interviews[0].date_created;
      } else {
        return undefined;
      }
    },
    sortable: true,
    sortField: 'datetime_interview'
  },
  videointerviews: {
    name: 'videointerviews',
    title: 'Pipeline.table.videointerviews',
    getCellValue: (row) => {
      if (row.videoInterviews && row.videoInterviews.length > 0) {
        return row.videoInterviews[0].date_created;
      } else {
        return undefined;
      }
    },
    sortable: true,
    sortField: 'completed'
  },
  custom_tests: {
    name: 'custom_tests',
    title: 'Pipeline.table.custom_tests',
    getCellValue: (row) => {
      if (row.customTests && row.customTests.length > 0) {
        return row.customTests[0].test_score;
      } else {
        return undefined;
      }
    },
    sortable: true,
    sortField: 'customtest_score'
  },
  feedback_status: {
    name: 'feedback_status',
    title: 'Pipeline.table.feedback_status',
    getCellValue: (row) => (row.feedbacks && row.feedbacks.length > 0 ? true : false)
  },
  disqualify_stage: {
    name: 'disqualify_stage',
    title: 'Pipeline.table.disqualify_stage',
    getCellValue: (row) => {
      if (row.disqualify_info) {
        return row.disqualify_info.disqualify_stage;
      } else {
        return undefined;
      }
    },
    sortable: true,
    sortField: 'disqualify_info.disqualify_stage'
  },
  disqualify_user: {
    name: 'disqualify_user',
    title: 'Pipeline.table.disqualify_user',
    getCellValue: (row) => {
      if (row.disqualify_info) {
        return row.disqualify_info.user_id;
      } else {
        return undefined;
      }
    },
    sortable: true,
    sortField: 'disqualify_info.user_id'
  },
  disqualify_date: {
    name: 'disqualify_date',
    title: 'Pipeline.table.disqualify_date',
    getCellValue: (row) => {
      if (row.disqualify_info) {
        return row.disqualify_info.disqualify_date;
      } else {
        return undefined;
      }
    },
    sortable: true,
    sortField: 'disqualify_info.disqualify_date'
  },
  date_present_exp: {
    name: 'date_present_exp',
    title: null
  },
  company_present_exp: {
    name: 'company_present_exp',
    title: 'Pipeline.table.company_present_exp',
    getCellValue: (row) => {
      if (row.company_present_exp && row.company_present_exp.name) {
        return row.company_present_exp.name;
      } else {
        return undefined;
      }
    },
    sortable: true,
    sortField: 'company_present_exp.name',
    searchFields: ['company_present_exp.name']
  },
  hire_status: {
    name: 'hire_status',
    title: 'Pipeline.table.hire_status',
    sortable: true
  },
  salary_info: {
    name: 'salary_info',
    title: 'HiredList.table_salary',
    sortable: true
  },
  job: {
    name: 'job',
    title: 'Pipeline.table.job',
    getCellValue: (row) => (row.job ? '[' + row.job.code + '] ' + row.job.title : undefined),
    sortable: true,
    sortField: 'job_code'
  },
  review_score: {
    name: 'review_score',
    title: 'Pipeline.table.score',
    getCellValue: (row) => (parseFloat(row.review_score) > -1 ? parseFloat(row.review_score) : -1),
    sortable: true
  },
  review_completed: {
    name: 'review_completed',
    title: 'Pipeline.table.score',
    getCellValue: (row) =>
      parseFloat(row.review_completed) > -1 ? parseFloat(row.review_completed) : -1,
    sortable: true
  },
  review: {
    name: 'review',
    title: null
  },
  review_count: {
    name: 'review_count',
    title: 'Pipeline.table.score',
    getCellValue: (row) => (parseFloat(row.review_count) > -1 ? parseFloat(row.review_count) : -1),
    sortable: true
  },
  review_total: {
    name: 'review_total',
    title: 'Pipeline.table.score',
    getCellValue: (row) => (parseFloat(row.review_total) > -1 ? parseFloat(row.review_total) : -1),
    sortable: true
  },
  id_document: {
    name: 'id_document',
    title: null,
    searchable: true,
    searchFields: ['doc_number']
  },
  pipeline_stage: {
    name: 'pipeline_stage',
    title: 'JobsList.table.stage_status'
  },
  referral_motive_title: {
    name: 'referral_motive_title',
    title: 'Pipeline.table.referral_motive_title',
    getCellValue: (row) =>
      row.disqualify_info && row.disqualify_info.motive ? row.disqualify_info.motive : undefined
  },
  mbti_match: {
    name: 'mbti_match',
    title: 'Pipeline.table.mbti_match'
  }
};

var getApplicantColumns = function (that, field) {
  let _currentStage;
  if (!that.state) {
    _currentStage = 0; // Fallback if called outside of pipeline
  } else {
    _currentStage = that.state._currentStage;
  }

  let obj = {
    name: columns[field].name,
    title: columns[field].title ? that.props.intl.formatMessage({ id: columns[field].title }) : ' ',
    getCellValue: columns[field].getCellValue,
    searchable: columns[field].searchable ? true : false,
    searchFields: columns[field].searchFields ? columns[field].searchFields : [columns[field].name],
    sortable: columns[field].sortable ? true : false,
    sortField: columns[field].sortField ? columns[field].sortField : columns[field].name
  };

  // Special field sorting
  if (field === 'interviews') {
    obj.sortField = 'interview_obj.stage_' + _currentStage + '.datetime_interview';
  } else if (field === 'videointerviews') {
    obj.sortField = 'videointerview_obj.stage_' + _currentStage + '.completed';
  }

  if (field === 'pipeline_stage') {
    obj.getCellValue = (row) => {
      const priorityWeights = {
        SCREENING: 0,
        PERSONALITY: 1,
        CUSTOMTEST: 2,
        INTERVIEW: 3,
        VIDEOINTERVIEW: 3,
        REVIEW: 4,
        HIRED: 5,
        DISQUALIFIED: 5
      };

      if (that.state.stageList && that.state.stageList.length > 0) {
        let stageList = that.state.stageList;

        let stageNameDisplay = '';
        const jobStageStatus = stageList.find(
          (stageStatus) => stageStatus.id === row.pipeline_stage
        );

        if (jobStageStatus) {
          stageNameDisplay = jobStageStatus.name.toLowerCase();

          if (that.props.intl.formatMessage({ id: 'Pipeline.' + stageNameDisplay }) !== undefined) {
            stageNameDisplay = that.props.intl.formatMessage({
              id: 'Pipeline.' + stageNameDisplay
            });
          }
          if (stageNameDisplay.startsWith('Pipeline.')) {
            stageNameDisplay = jobStageStatus.name;
          }
        }
        return stageNameDisplay;
      } else {
        return null;
      }
    };
  }

  return obj;
};

export default getApplicantColumns;
