import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import ContactsIcon from '@material-ui/icons/Contacts';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddressController from '../../Common/AddressController';
import Box from '@material-ui/core/Box';
import * as CONST from '../../Common/constants';

const messages = defineMessages({
  defaultIDLabel: {
    id: 'IconID.defaultIDLabel',
    defaultMessage: 'Document Number'
  }
});

class IconID extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doc_number: props.doc_number,
      doc_type: props.doc_type,
      size: props.size ? props.size : 20, // Smaller than the standard 24
      // Internal state
      applicantDoc: undefined,
      docName: undefined,
      docMask: undefined,
      containerId: 'displayApplicantID'
    };
  }

  componentDidMount() {
    const identityDocs = this.props.identityDocs.identityDocs;
    let docName = '';
    let maskedDocumentNumber = '';
    if (identityDocs && identityDocs.length > 0) {
      const applicantDocIndex = identityDocs.findIndex((doc) => doc.code === this.state.doc_type);
      if (applicantDocIndex > -1) {
        docName = identityDocs[applicantDocIndex].name;
        const docMask = identityDocs[applicantDocIndex].id_mask;

        this.setState({
          docName: docName,
          docMask: docMask
        });
      } else {
        docName = this.props.intl.formatMessage({ id: 'IconID.defaultIDLabel' });
      }

      this.setState({
        docName: docName
      });
    }
  }

  render() {
    const docName = this.state.docName;
    const doc_number = this.state.doc_number;
    const docTitle = (
      <Box display="flex">
        <span style={{ marginRight: '0.25rem' }}>{docName}:</span>
        <Box id={this.state.containerId}>{this.state.doc_number}</Box>
      </Box>
    );

    if (this.state.docMask) {
      AddressController.getMaskedID(this.state.containerId, this.state.docMask);
    }

    return doc_number ? (
      <Tooltip title={docTitle} arrow>
        <ContactsIcon
          style={{
            fontSize: this.state.size,
            color: CONST.APP_COLORS['gray-darker-regular']
          }}
        />
      </Tooltip>
    ) : null;
  }
}

IconID.propTypes = {
  identityDocs: PropTypes.object
};

const mapStateToProps = (state) => ({
  identityDocs: state.identityDocs
});

export default connect(mapStateToProps, null)(injectIntl(IconID));
