import React, { useEffect, useState } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';

import Header from './Header';
import Sidebar from './Sidebar';
import Offsidebar from './Offsidebar';
import Footer from './Footer';

import BaseRun from './Base.run';
import UserProfile from '../Common/UserProfile';
import { initialize } from '../Api/EventsStream';

const messages = defineMessages({
  button_cancel: {
    id: 'Base.button.cancel',
    defaultMessage: 'Cancel'
  },
  grid_noData: {
    id: 'Base.grid.noData',
    defaultMessage: 'No data'
  },
  grid_applyFilters: {
    id: 'Base.grid.applyFilters',
    defaultMessage: 'Select one or more filters and click on Filter Data to display info.'
  },
  grid_sort: {
    id: 'Base.grid.sort',
    defaultMessage: 'Sort'
  },
  grid_filter: {
    id: 'Base.grid.filter',
    defaultMessage: 'Filter...'
  },
  grid_search: {
    id: 'Base.grid.search',
    defaultMessage: 'Search...'
  },
  grid_groupByCol: {
    id: 'Base.grid.groupByCol',
    defaultMessage: 'Drag a column here to group by'
  },
  grid_paging_showAll: {
    id: 'Base.grid.paging_showAll',
    defaultMessage: 'Show all'
  },
  grid_paging_rowsPerPage: {
    id: 'Base.grid.paging_rowsPerPage',
    defaultMessage: 'Rows per page'
  },
  grid_paging_info: {
    id: 'Base.grid.paging_info',
    defaultMessage: '{from}-{to} of {count}'
  },
  grid_export: {
    id: 'Base.grid.export',
    defaultMessage: 'Export'
  },
  grid_exportAll: {
    id: 'Base.grid.exportAll',
    defaultMessage: 'Export to Spreadsheet'
  },
  grid_exportSelected: {
    id: 'Base.grid.exportSelected',
    defaultMessage: 'Export selected to Spreadsheet'
  },
  grid_average: {
    id: 'Base.grid.average',
    defaultMessage: 'Average'
  },
  grid_sum: {
    id: 'Base.grid.sum',
    defaultMessage: 'Sum'
  },
  grid_count: {
    id: 'Base.grid.count',
    defaultMessage: 'Count'
  }
});

export default function Base({ children }) {
  const [userLang, setUserLang] = useState('');
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    function load() {
      if (UserProfile.hasLogged()) {
        initialize();
        BaseRun(UserProfile.getName(), UserProfile.getEmail());
      }
    }
    load();
  }, []);

  useEffect(() => {
    function loadUser() {
      setUserLang(user.language);
    }
    loadUser();
  }, [user]);

  return (
    <div className="wrapper">
      <Header />

      <Sidebar lang={userLang} />

      <Offsidebar />

      <section className="section-container">{children}</section>

      <Footer />
    </div>
  );
}
