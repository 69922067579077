import React from 'react';
import * as CONST from '../Common/constants';
import Shuffle from '@material-ui/icons/Shuffle';
import LocationSearching from '@material-ui/icons/LocationSearching';
import FindInPageOutlined from '@material-ui/icons/FindInPageOutlined';
import Face from '@material-ui/icons/Face';
import DateRange from '@material-ui/icons/DateRange';
import Videocam from '@material-ui/icons/Videocam';
import ListAlt from '@material-ui/icons/ListAlt';
import AssessmentOutlined from '@material-ui/icons/AssessmentOutlined';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import NotInterested from '@material-ui/icons/NotInterested';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Room from '@material-ui/icons/Room';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import { IconInterview, IconCustomTest } from '../Icons/Icons';

class StageIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.type,
      fontSize: props.fontSize ? props.fontSize : 'inherit',
      color: props.color ? props.color : 'inherit',
      icon: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      this.setState({
        type: this.props.type
      });
    }
  }

  render() {
    const type = this.state.type;
    const fontSize = this.state.fontSize;
    const color = this.state.color;
    let icon;
    let sizeProps = {
      fontSize: fontSize
    };

    if (!isNaN(fontSize)) {
      // Its a number
      sizeProps = {
        style: {
          fontSize: fontSize
        }
      };
    }

    switch (type) {
      case CONST.STAGE_CROSSRANK:
        icon = <Shuffle {...sizeProps} color={color} />;
        break;
      case CONST.STAGE_SCREENING:
        icon = <FindInPageOutlined {...sizeProps} color={color} />;
        break;
      case CONST.STAGE_INTERVIEW:
        icon = <IconInterview {...sizeProps} color={color} />;
        break;
      case CONST.STAGE_VIDEOINTERVIEW:
        icon =
          this.props.variant === 'outlined' ? (
            <VideocamOutlinedIcon {...sizeProps} color={color} />
          ) : (
            <Videocam {...sizeProps} color={color} />
          );
        break;
      case CONST.STAGE_CUSTOMTEST:
        icon = <IconCustomTest {...sizeProps} color={color} />;
        break;
      case CONST.STAGE_REVIEW:
        icon = <AssessmentOutlined {...sizeProps} color={color} />;
        break;
      case CONST.STAGE_HIRED:
        icon =
          this.props.variant === 'outlined' ? (
            <EmojiEventsOutlinedIcon {...sizeProps} color={color} />
          ) : (
            <EmojiEvents {...sizeProps} color={color} />
          );
        break;
      case CONST.STAGE_DISQUALIFIED:
        icon = <NotInterested {...sizeProps} color={color} />;
        break;
      case CONST.STAGE_SAVEDLATER:
        icon = <FavoriteBorder {...sizeProps} color={color} />;
        break;
      case 'LOCATION':
        icon = <Room {...sizeProps} color={color} />;
        break;
      default:
        icon = null;
    }

    return icon;
  }
}

export default StageIcon;
