import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Api from '../../../Api/Api';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Parser from 'html-react-parser';
import HistoryIcon from '@material-ui/icons/History';
import UserProfile from '../../../Common/UserProfile';
import LoadingData from '../../../Layout/LoadingData';
import TimelineIcon from '@material-ui/icons/Timeline';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import GrainIcon from '@material-ui/icons/Grain';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import * as CONST from '../../../Common/constants';
import Big5Analysis from './Big5Analysis';
import RecrutAITraits from './RecrutAITraits';
import ComingChip from '../Common/ComingChip';
import Chip from '@material-ui/core/Chip';

const useStyles = (theme) => ({
  fitPersonalityTitle: {
    color: theme.palette.success.main,
    fontWeight: 700,
    fontSize: '22px'
  },
  fitPersonalityText: {
    color: theme.palette.gray.darker,
    fontSize: '14px',
    lineHeight: '24px'
  },
  activityTitle: {
    fontSize: '14px',
    fontWeight: 700,
    color: theme.palette.gray.darkerMedium
  },
  notAnsweredFit: {
    fontSize: '14px',
    fontWeight: 700,
    color: theme.palette.gray.darkerLight
  },
  iconActionContainer: {
    padding: theme.spacing(1)
  },
  avatarContainer: {
    marginRight: '-1.75rem',
    marginTop: '-4.75rem',
    marginLeft: '-1.25rem'
  },
  personalityTypeTitle: {
    fontWeight: 400,
    fontSize: '12px',
    color: theme.palette.gray.darkerMedium
  },
  applicantName: {
    fontSize: '22px',
    fontWeight: 700,
    color: theme.palette.gray.darker,
    lineHeight: 'normal'
  },
  subtitle: {
    fontSize: '16px',
    color: theme.palette.gray.darker,
    lineHeight: 'normal'
  },
  horizontalMarginElement: {
    margin: `0 ${theme.spacing(3)}px`,
    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing(2)}px`
    }
  },
  rightMarginElement: {
    margin: `0 ${theme.spacing(3)}px 0  ${theme.spacing(1)}px`,
    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing(2)}px 0  ${theme.spacing(1)}px`
    }
  },
  traitsTitle: {
    fontSize: '12px',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileTraitsTitle: {
    fontSize: '12px',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  matchCodeChip: {
    borderRadius: '4px',
    backgroundColor: theme.palette.gray.lighter,
    color: theme.palette.gray.darkerMedium,
    fontWeight: 500
  },
  chip: {
    color: theme.palette.gray.darkerMedium,
    backgroundColor: `${theme.palette.gray.darkerMedium}10`,
    borderColor: theme.palette.gray.darkerMedium
  }
});

const ActivitiesTabs = withStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '48px',
    //padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
      alignItems: 'center',
      columnGap: '1px'
    }
  },
  scroller: {
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflowX: 'scroll !important'
    }
  },
  indicator: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    '& > span': {
      width: '90%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '1rem'
    }
  }
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const ActivitiesTab = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '400',
    color: theme.palette.gray.darkerMedium,
    minWidth: 0,
    maxWidth: '320px',
    padding: 0,
    //margin: `0 ${theme.spacing(1.5)}px`,
    opacity: 1
    //marginRight: theme.spacing(4),
    // '&:hover': {
    //   color: theme.palette.primary.main,
    //   opacity: 1
    // },
    // '&:active': {
    //   color: theme.palette.primary.main,
    //   opacity: 1
    // },
    // '&$selected': {
    //   color: theme.palette.primary.main
    // },
    // '&:focus': {
    //   color: theme.palette.primary.main
    // }
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {/*value === index && */ <Box height="100%">{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const defaultOptions = {
  axes: true, // show axes?
  scales: 2, // show scale circles?
  captions: true, // show captions?
  captionMargin: 15,
  dots: true, // show dots?
  zoomDistance: 1.2, // where on the axes are the captions?
  captionProps: () => ({
    className: 'caption',
    textAnchor: 'middle',
    fontSize: 10,
    fontFamily: 'sans-serif'
  }),
  dotProps: () => ({
    className: 'dot'
    //mouseEnter: (dot) => { console.log(dot) },
    //mouseLeave: (dot) => { console.log(dot) }
  })
};

class PersonalityReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant: props.applicant,
      variant: props.variant,
      applicant_id: props.applicant_id,
      loadInfo: props.loadInfo,
      //Internal state
      mbtiMatch: '',
      avatarSrc: '',
      mbtiSummary: '',
      loadingPersonality: false,
      firstLoadedPersonality: false,
      selectedTab: 0,
      loadingApplicantData: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState(
        {
          applicant: this.props.applicant
        },
        () => {
          if (!this.state.firstLoadedPersonality && !this.state.loadInfo) {
            this.getPersonalityTypes(this.props.user.language);
            //this.loadWorkforceTypes(this.props.user.language);
          }
        }
      );
    }
  }

  componentDidMount() {
    if (this.state.loadInfo) {
      this.loadApplicantInfo();
    } else {
      this.getPersonalityTypes(this.props.user.language);
    }
  }

  loadApplicantInfo() {
    this.setState(
      {
        loadingApplicantData: true
      },
      () => {}
    );
    let api = new Api();

    let applicantFilter = {
      where: {
        id: this.state.applicant_id
      },
      fields: ['avatar_50_path', 'gender', 'name', 'person_id', 'id'],
      include: [
        {
          relation: 'person',
          scope: {
            fields: ['personality_profile', 'id']
          }
        }
      ]
    };

    api.get('Applicants', applicantFilter, (status, data) => {
      if (status < 400) {
        const applicant = {
          ...data[0],
          personality_profile: data[0].person.personality_profile
        };
        this.setState(
          {
            applicant: applicant,
            loadingApplicantData: false
          },
          () => {
            this.getPersonalityTypes(this.props.user.language);
          }
        );
      }
    });
  }

  getPersonalityTypes(lang) {
    const applicant = this.state.applicant;

    if (applicant && applicant.personality_profile) {
      this.setState(
        {
          loadingPersonality: true
        },
        () => {
          let filter = {
            where: {
              lang: lang
            }
          };
          let api = new Api();
          api.get('PersonalityTypes', filter, (status, data) => {
            if (data.length > 0) {
              const mbtiTypes = data.filter((type) => type.type.toUpperCase() === 'MBTI');

              let mbtiMatch = '';
              let mbtiSummary = '';
              let avatarSrc = '';
              const personality_profile = this.state.applicant.personality_profile;
              let applicantGender = 'female';

              if (applicant.gender === 'M') {
                applicantGender = 'male';
              }

              const match = mbtiTypes.find(
                (currType) => currType.code === personality_profile.mbti
              );
              mbtiMatch = match.name.split(' ')[0];
              mbtiSummary = match.definition;
              avatarSrc = `https://dojjag7ntwx81.cloudfront.net/img/personality_avatar_${personality_profile.mbti.toLowerCase()}_${applicantGender}.svg`;

              this.setState({
                mbtiMatch: mbtiMatch,
                mbtiSummary: mbtiSummary,
                loadingPersonality: false,
                avatarSrc: avatarSrc,
                firstLoadedPersonality: true
              });
            } else {
              this.setState({
                loadingPersonality: false
              });
            }
          });
        }
      );
    }
  }

  handleChangeTab = (e, tab) => {
    this.setState({
      selectedTab: tab
    });
  };

  render() {
    const { classes } = this.props;

    const applicant = this.state.applicant;

    if (applicant) {
      const mbtiMatch = this.state.mbtiMatch;
      const personality_profile = applicant.personality_profile;

      let fullPath;
      if (applicant.avatar_50_path) {
        if (applicant.avatar_50_path.startsWith('http')) {
          fullPath = applicant.avatar_50_path;
        } else {
          fullPath = 'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + applicant.avatar_50_path;
        }
      }

      if (this.state.loadingPersonality) {
        return <LoadingData />;
      }

      if (this.state.loadingApplicantData) {
        return <LoadingData />;
      }

      const displayReport = () => {
        if (this.state.variant === 'print' || this.state.variant === 'cv') {
          return (
            <Box>
              <Box
                mb={4}
                style={{
                  height: this.state.variant === 'print' ? '1280px' : 'auto'
                }}
              >
                <Big5Analysis personality_profile={applicant.personality_profile} />
              </Box>

              <RecrutAITraits applicant={applicant} />
            </Box>
          );
        } else {
          return (
            <>
              <Box className={classes.activitiesTabs}>
                <ActivitiesTabs
                  value={this.state.selectedTab}
                  //variant="scrollable"
                  scrollButtons="off"
                  TabIndicatorProps={{ children: <span /> }}
                  onChange={this.handleChangeTab}
                >
                  <ActivitiesTab
                    label={
                      <Box display="flex" alignItems="center">
                        <Box
                          display="flex"
                          alignItems="center"
                          className={classes.rightMarginElement}
                        >
                          <Box mr="6px">
                            <FaceOutlinedIcon
                              style={{
                                fontSize: '1rem'
                              }}
                            />
                          </Box>
                          <Typography
                            style={{
                              fontSize: '12px'
                            }}
                          >
                            <FormattedMessage
                              id="PersonalityReport.tab_bigFive"
                              defaultMessage="Big Five"
                            />
                          </Typography>
                        </Box>

                        <Box>
                          <Divider
                            orientation="vertical"
                            style={{
                              height: '1rem'
                            }}
                          />
                        </Box>
                      </Box>
                    }
                  />
                  <ActivitiesTab
                    label={
                      <Box display="flex" alignItems="center">
                        <Box
                          display="flex"
                          alignItems="center"
                          className={classes.horizontalMarginElement}
                        >
                          <Box mr="6px">
                            <TimelineIcon
                              style={{
                                fontSize: '1rem'
                              }}
                            />
                          </Box>
                          <Typography
                            style={{
                              fontSize: '12px'
                            }}
                            className={classes.traitsTitle}
                          >
                            <FormattedMessage
                              id="PersonalityReport.tab_traits"
                              defaultMessage="Behavioral Markers"
                            />
                          </Typography>
                          <Typography
                            style={{
                              fontSize: '12px'
                            }}
                            className={classes.mobileTraitsTitle}
                          >
                            <FormattedMessage
                              id="PersonalityReport.tab_traitsShort"
                              defaultMessage="Markers"
                            />
                          </Typography>
                        </Box>
                        <Box>
                          <Divider
                            orientation="vertical"
                            style={{
                              height: '1rem'
                            }}
                          />
                        </Box>
                      </Box>
                    }
                  />

                  <ActivitiesTab
                    disabled
                    label={
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classes.horizontalMarginElement}
                      >
                        <Box display="flex" alignItems="center" mr={1}>
                          <Box mr="6px">
                            <GrainIcon
                              style={{
                                fontSize: '1rem'
                              }}
                            />
                          </Box>
                          <Typography
                            style={{
                              fontSize: '12px'
                            }}
                          >
                            <FormattedMessage
                              id="PersonalityReport.tab_culturalFit"
                              defaultMessage="Cultural Fit"
                            />
                          </Typography>
                        </Box>
                        <ComingChip />
                      </Box>
                    }
                  />
                </ActivitiesTabs>
              </Box>

              <Box my={2}>
                <Divider />
              </Box>
              <Box>
                <Box>
                  <TabPanel value={this.state.selectedTab} index={0}>
                    <Big5Analysis personality_profile={applicant.personality_profile} />
                  </TabPanel>

                  <TabPanel value={this.state.selectedTab} index={1}>
                    <RecrutAITraits applicant={applicant} />
                  </TabPanel>

                  {/* <TabPanel value={this.state.selectedTab} index={2}>
              Fit cultural
            </TabPanel> */}
                </Box>
              </Box>
            </>
          );
        }
      };

      if (mbtiMatch) {
        return (
          <Box
            my={3}
            style={{
              padding: this.state.variant === 'print' ? '0 2rem' : '0 0 0 0'
            }}
          >
            {this.state.variant !== 'cv' ? (
              <Box display="flex" alignItems="center" mb={1.5}>
                <Box mr={1}>
                  <Avatar
                    color={CONST.APP_COLORS['gray-lighter']}
                    name={applicant.name}
                    src={fullPath}
                    size="64px"
                    round={true}
                  />
                </Box>
                <Typography className={classes.applicantName}>{applicant.name}</Typography>
              </Box>
            ) : null}
            <Box>
              <Box display="flex">
                <Box>
                  <Typography className={classes.personalityTypeTitle}>
                    <FormattedMessage id="MoreInfoTabs.label_personalityType" />
                  </Typography>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Box mr={1}>
                      <Typography className={classes.fitPersonalityTitle}>
                        {this.state.mbtiMatch}
                      </Typography>
                    </Box>
                    <Box mr={1}>
                      <Chip
                        label={personality_profile.mbti}
                        size="small"
                        className={
                          this.state.variant === 'print' ? classes.chip : classes.matchCodeChip
                        }
                      />
                    </Box>
                  </Box>
                  <Box pr={1}>
                    <Typography className={classes.fitPersonalityText}>
                      {Parser(this.state.mbtiSummary)}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.avatarContainer}>
                  <img
                    src={this.state.avatarSrc}
                    width="157px"
                    height="188px"
                    alt="personality-avatar"
                  />
                </Box>
              </Box>
            </Box>
            {displayReport()}
          </Box>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

PersonalityReport.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  user: state.user.user
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(PersonalityReport)));
