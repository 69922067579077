import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import UserStorage from '../../../Common/UserStorage';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

const useStyles = (theme) => ({
  optionContainer: {
    //minHeight: 120,
    height: 32,
    display: 'flex'
  },
  label: {
    fontSize: '14px'
  }
});

class ButtonFilterInternal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // State from parent
      selected: this.props.selected ? this.props.selected : false,
      // Menu
      openMenu: false,
      anchorEl: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.setState({
        selected: this.props.selected
      });
    }
  }

  handleCheckUncheck = () => {
    const newValue = this.state.selected === true ? false : true;

    this.setState(
      {
        selected: newValue
      },
      () => {
        if (this.props.handlerUpdate) {
          this.props.handlerUpdate(this.state.selected);
        }
      }
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.optionContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.selected}
                onChange={this.handleCheckUncheck}
                name="filterInernal"
                color="primary"
              />
            }
            label={
              <Typography className={classes.label}>
                <FormattedMessage
                  id="ButtonFilterInternal.label_item"
                  defaultMessage="Internal Candidates"
                />
              </Typography>
            }
          />
        </div>
      </>
    );
  }
}
export default injectIntl(withStyles(useStyles)(ButtonFilterInternal));
