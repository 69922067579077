import React from 'react';
//import PropTypes from 'prop-types';
import shouldPureComponentUpdate from 'react-pure-render/function';
import { Col, Row, Popover } from 'react-bootstrap';
import { applicantPlaceStyle } from './ApplicantPlaceStyles.js';
import Tooltip from '@material-ui/core/Tooltip';
import PersonPinIcon from '@material-ui/icons/PersonPin';

export default class ApplicantPlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static defaultProps = {};

  shouldComponentUpdate = shouldPureComponentUpdate;

  render() {
    const applicantDetails = () => {
      return (
        <Popover title={this.state.name} style={{ width: '250px' }}>
          <Row className="mb">
            <Col md={12}>
              <h6>{this.state.zip}</h6>
              <br />
              {this.state.address.street}
              <br />
              {this.state.address.neiborhood}
              <br />
              {this.state.address.city}
              <br />
              {this.state.address.state}
            </Col>
          </Row>
        </Popover>
      );
    };

    return (
      <Tooltip title={''} arrow>
        <div style={applicantPlaceStyle}>
          <PersonPinIcon color="primary" style={{ fontSize: 45 }} />
        </div>
      </Tooltip>
    );
  }
}
