import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import * as CONST from '../../Common/constants';
import Box from '@material-ui/core/Box';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import GoogleMap, { Marker } from 'google-map-react';
import ApplicantPlace from '../../Map/ApplicantPlace';
import JobPlace from '../../Map/JobPlace';
import Typography from '@material-ui/core/Typography';

const messages = defineMessages({});

const useStyles = (theme) => ({
  cardRoot: {
    border: '1px solid ' + theme.palette.gray.main,
    marginBottom: theme.spacing(1)
  }
});

// https://github.com/google-map-react/google-map-react-examples/blob/master/src/examples/Main.js#L69
// https://stackoverflow.com/questions/6048975/google-maps-v3-how-to-calculate-the-zoom-level-for-a-given-bounds
// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();
  places.forEach((place) => {
    bounds.extend(new maps.LatLng(place.lat, place.lng));
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
      //remove one zoom level to ensure no marker is on the edge.
      map.setZoom(map.getZoom() - 1);
      // set a minimum zoom
      // if you got only 1 marker or all markers are on the same address map will be zoomed too much.
      if (map.getZoom() > 15) {
        map.setZoom(15);
      }
    });
  });
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, places) => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places);
  // Fit map to bounds
  map.fitBounds(bounds);
  //remove one zoom level to ensure no marker is on the edge.
  map.setZoom(map.getZoom() - 1);
  // set a minimum zoom
  // if you got only 1 marker or all markers are on the same address map will be zoomed too much.
  if (map.getZoom() > 15) {
    map.setZoom(15);
  }
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);
};

class CardApplicantMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant_point: props.applicant_point,
      applicant_geocode: props.applicant_geocode,
      job_point: props.job_point,
      job_geocode: props.job_geocode,
      // Internal State
      applicant_place: {
        lat: undefined,
        lng: undefined
      },
      job_place: {
        lat: undefined,
        lng: undefined
      },
      places: [],
      applicant_approximate: false,
      job_approximate: false
    };
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {
    this.loadPlaces();
  }

  loadPlaces() {
    const applicant_point = this.state.applicant_point;
    const applicant_geocode = this.state.applicant_geocode;
    const job_point = this.state.job_point;
    const job_geocode = this.state.job_geocode;

    let applicant_approximate = false;
    let job_approximate = false;

    let places = [];
    let applicant_place = {};
    if (applicant_geocode && applicant_geocode.length > 0) {
      applicant_place.lat = applicant_geocode[0].geometry.location.lat;
      applicant_place.lng = applicant_geocode[0].geometry.location.lng;
      places.push(applicant_place);

      if (applicant_geocode[0].geometry.location_type === 'APPROXIMATE') {
        applicant_approximate = true;
      }
    } else if (applicant_point) {
      applicant_place.lat = applicant_point.lat;
      applicant_place.lng = applicant_point.lng;
      places.push(applicant_place);

      applicant_approximate = true;
    }

    let job_place = {};
    if (job_geocode && job_geocode.length > 0) {
      job_place.lat = job_geocode[0].geometry.location.lat;
      job_place.lng = job_geocode[0].geometry.location.lng;
      places.push(job_place);

      if (job_geocode[0].geometry.location_type === 'APPROXIMATE') {
        job_approximate = true;
      }
    } else if (job_point) {
      job_place.lat = job_point.lat;
      job_place.lng = job_point.lng;
      places.push(job_place);

      job_approximate = true;
    }

    this.setState({
      applicant_place: applicant_place,
      job_place: job_place,
      places: places,
      applicant_approximate: applicant_approximate,
      job_approximate: job_approximate
    });
  }

  render() {
    const { classes } = this.props;

    const applicant_place = this.state.applicant_place;
    const job_place = this.state.job_place;
    const places = this.state.places;
    const applicant_approximate = this.state.applicant_approximate;
    const job_approximate = this.state.job_approximate;

    if (applicant_place.lat && applicant_place.lng) {
      return (
        <Card
          mb={2}
          classes={{
            root: classes.cardRoot
          }}
        >
          <CardHeader
            avatar={<PersonPinIcon />}
            title={
              <Typography variant="h5">
                <FormattedMessage
                  id="CardApplicantMap.title"
                  defaultMessage="Candidate's location"
                />
              </Typography>
            }
          />
          <Divider light />

          {/* applicant_approximate === true ? (
            <Alert severity="warning">
              <FormattedMessage
                id="CardApplicantMap.applicant_approximate"
                defaultMessage="Candidate's location precision is low."
              />
            </Alert>
          ) : null}
          {job_place.lat && job_place.lng && job_approximate === true ? (
            <Alert severity="warning">
              <FormattedMessage
                id="CardApplicantMap.job_approximate"
                defaultMessage="Job Position's location precision is low."
              />
            </Alert>
          ) : null */}

          <Box display="flex" alignItems="center" width="100%">
            <Box flex={'auto'} width="100%" p={1}>
              <div style={{ height: '50vh', width: '100%' }}>
                <GoogleMap
                  bootstrapURLKeys={{ key: CONST.GMAPS_APIKEY }}
                  defaultCenter={{ lat: applicant_place.lat, lng: applicant_place.lng }}
                  defaultZoom={12}
                  yesIWantToUseGoogleMapApiInternals
                  onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
                  options={{
                    fullscreenControl: false,
                    styles: CONST.GMAPS_STYLE
                  }}
                >
                  <ApplicantPlace lat={applicant_place.lat} lng={applicant_place.lng} />
                  {job_place.lat && job_place.lng ? (
                    <JobPlace lat={job_place.lat} lng={job_place.lng} />
                  ) : null}
                </GoogleMap>
              </div>
            </Box>
          </Box>
        </Card>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(CardApplicantMap));
