import React from 'react';
import { injectIntl, defineMessages, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import Api from '../Api/Api';
import UserProfile from '../Common/UserProfile';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import { PagingState, CustomPaging, DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, Table, PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import GridConstants from '../Common/GridConstants';
import ApplicantColumns from '../Pipeline/ApplicantColumns';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'moment';
import PagingContainer, { applyCustomButtonColor } from '../Layout/PagingContainer';
import ApplicantAvatar from '../Layout/ApplicantAvatar';
import ButtonNotes from '../Applicant/Card/ButtonNotes';
import ButtonFav from '../Applicant/Card/ButtonFav';
import InfoPwd from '../Applicant/Card/InfoPwd';
import InfoGender from '../Applicant/Card/InfoGender';
import InfoPso from '../Applicant/Card/InfoPso';
import InfoPwr from '../Applicant/Card/InfoPwr';
import InfoOriginalCv from '../Applicant/Card/InfoOriginalCv';
import LabelOrigin from '../Applicant/Card/LabelOrigin';
import CircularOverallScore from '../Applicant/Card/CircularOverallScore';
import Parser from 'html-react-parser';
import IconEmail from '../Applicant/Card/IconEmail';
import IconPhone from '../Applicant/Card/IconPhone';
import IconInternal from '../Applicant/Card/IconInternal';
import AddressController from '../Common/AddressController';
import LocaleFormatter from '../Common/LocaleFormatter';
import * as CONST from '../Common/constants';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ApplicantDetailDialog from './ApplicantDetailDialog';

// https://stackoverflow.com/questions/1199352/smart-way-to-truncate-long-strings
function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '…' : str;
}

const SCOPE_NAME_EMAIL = 'NAME_EMAIL';
const SCOPE_CV_TEXT = 'CV_TEXT';
const SCOPE_DOC_NUMBER = 'DOC_NUMBER';

const useStyles = (theme) => ({
  select: {
    width: 150
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const messages = defineMessages({
  searchPlaceholder: {
    id: 'ModalSearch.searchPlaceholder',
    defaultMessage: 'Search by name, e-mail or any info inside the CVs of your candidates...'
  },
  searchingMsg: {
    id: 'ModalSearch.searchingMsg',
    defaultMessage: 'Searching…'
  },
  linkToPipeline: {
    id: 'ModalSearch.linkToPipeline',
    defaultMessage: 'Go to candidate in pipeline'
  }
});

// MUST BE OUTSIDE OF COMPONENT
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class ModalSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      query: props.query ? decodeURIComponent(props.query) : '',
      current_job_id: props.current_job_id ? props.current_job_id : undefined,
      scope: props.scope,
      // Internal State
      // Search
      loading: false,
      results: [],
      itemsPerPage: 15,
      currentPage: 0,
      total: 0,
      // Grid
      columns: [
        ApplicantColumns(this, 'favorites'),
        ApplicantColumns(this, 'overall_score'),
        ApplicantColumns(this, 'avatar_50_path'),
        ApplicantColumns(this, 'name'),
        ApplicantColumns(this, 'internal'),
        ApplicantColumns(this, 'origin'),
        ApplicantColumns(this, 'email'),
        ApplicantColumns(this, 'pwd'),
        ApplicantColumns(this, 'address'),
        ApplicantColumns(this, 'job'),
        ApplicantColumns(this, 'date_created')
      ],
      columnExtensions: [
        { columnName: 'favorites', width: '50px', align: 'center' },
        { columnName: 'overall_score', width: '64px', align: 'center' },
        { columnName: 'avatar_50_path', width: '64px', align: 'center' },
        { columnName: 'name', width: '33%', wordWrapEnabled: true },
        { columnName: 'internal', width: '32px', align: 'center' },
        { columnName: 'notes', width: '64px', align: 'center' },
        { columnName: 'origin', width: '32px', align: 'center' },
        { columnName: 'email', width: '32px', align: 'center' },
        { columnName: 'pwd', width: '32px', align: 'center' },
        { columnName: 'address', width: 'auto', wordWrapEnabled: true },
        { columnName: 'date_created', width: 'auto' },
        { columnName: 'job', width: 'auto', wordWrapEnabled: true }
      ],
      avatarCols: ['avatar_50_path'],
      scoreCols: ['overall_score'],
      nameCols: ['name'],
      iconCols: [
        'internal',
        'views',
        'favorites',
        'notes',
        'email',
        'phone_mobile',
        'pwd',
        'origin',
        'cv_path',
        'gender'
      ],
      dateCreatedCols: ['date_created'],
      addressCols: ['address'],
      relatedCols: ['job'],
      tableMessages: GridConstants.getGridMessage(this.props.intl, 'tableMessages'),
      // Applicant Details
      detail_open: false,
      detail_id: null,
      detail_applicant: null,
      // Filters
      jobsList: [],
      filterJobs: [],
      filterJobsSelected: [],
      current_job_code: '',
      current_job_title: ''
    };
  }

  componentDidMount() {
    applyCustomButtonColor();
    //document.title = 'Recrut.AI | ' + this.props.intl.formatMessage({ id: 'ModalSearch.title' });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open
      });
    }
    if (prevProps.query !== this.props.query) {
      this.setState({
        query: this.props.query
      });
    }
    if (prevProps.current_job_id !== this.props.current_job_id) {
      this.setState({
        current_job_id: this.props.current_job_id
      });
    }
    if (prevProps.scope !== this.props.scope) {
      this.setState({
        scope: this.props.scope
      });
    }
  }

  onEnter = () => {
    this.loadCurrentJob();

    if (this.state.query && this.state.query !== '') {
      this.setState(
        {
          loading: true,
          tableMessages: {
            noData: this.props.intl.formatMessage({ id: 'ModalSearch.searchingMsg' })
          }
        },
        () => {
          this.getSearchResults(0);
        }
      );
    }
  };

  onClose = () => {
    this.setState(
      {
        open: false,
        loading: false,
        results: [],
        currentPage: 0,
        total: 0,
        current_job_code: '',
        current_job_title: ''
      },
      () => {
        if (this.props.handleClose) {
          this.props.handleClose();
        }
      }
    );
  };

  loadCurrentJob() {
    // Checking
    if (this.state.current_job_id) {
      let api = new Api();

      let filter = {
        fields: ['code', 'title'],
        where: {
          id: this.state.current_job_id
        }
      };

      api.get('Jobs', filter, (statud, data) => {
        if (data.length > 0) {
          this.setState({
            current_job_code: data[0].code,
            current_job_title: data[0].title
          });
        } else {
          this.setState({
            current_job_id: undefined
          });
        }
      });
    }
  }

  loadJobsList() {}

  getSearchCount(next) {
    const query = this.state.query;
    const scope = this.state.scope;
    const current_job_id = this.state.current_job_id;

    if (query !== '') {
      let api = new Api();
      let params = {
        query: query,
        scope: scope,
        job_ids: current_job_id ? [current_job_id] : undefined
      };
      api.post('Applicants/search-count', params, (status, data) => {
        //console.log(data);

        let total = parseInt(data);
        this.setState(
          {
            total: total
          },
          () => {
            next(total);
          }
        );
      });
    }
  }

  getSearchResults(skip) {
    const query = this.state.query;
    const scope = this.state.scope;
    const current_job_id = this.state.current_job_id;
    const actualDate = new Date();

    if (!skip) {
      skip = 0;
    }
    if (query !== '') {
      let api = new Api();
      let params = {
        skip: skip,
        limit: this.state.itemsPerPage,
        query: query,
        scope: scope,
        job_ids: current_job_id ? [current_job_id] : undefined,
        filters: {
          fields: [
            'id',
            'job_code',
            'job_id',
            'name',
            'internal',
            'email',
            'gender',
            'age',
            'distance_job',
            'directions_job',
            'zip',
            'address',
            'address_geocode',
            'avatar_50_path',
            'avatar_score',
            'origin',
            'date_created',
            'verified_email',
            'phone_mobile',
            'cv_match_name',
            'pipeline_stage',
            'pwd',
            'hire_info',
            'favorites',
            // Scores
            'overall_score_prog',
            'overall_score',
            'cv_score',
            'profile_score',
            'interview_score',
            'videointerview_score',
            'customtest_score',
            'internal_verified',
            'employee_id',
            'doc_type',
            'doc_number'
          ],
          where: {
            deleted: false
          },
          include: [
            {
              relation: 'job',
              scope: {
                fields: [
                  'id',
                  'code',
                  'title',
                  'status',
                  'closed',
                  'date_closed',
                  'pipeline_id',
                  'pipeline_stages'
                ]
              }
            },
            {
              relation: 'emailBlocklists',
              scope: {
                fields: ['id', 'email', 'active', 'date_limit'],
                where: {
                  active: true,
                  and: [
                    {
                      or: [{ date_limit: null }, { date_limit: { gte: actualDate.toISOString() } }]
                    }
                  ]
                },
                order: ['date_created DESC']
              }
            },
            {
              relation: 'docBlocklists',
              scope: {
                fields: ['id', 'doc_number', 'active', 'date_limit'],
                where: {
                  active: true, // SOME FILTERS MAY CAUSE A CRASH BECAUSE doc_number IS NULLABLE
                  and: [
                    {
                      or: [{ date_limit: null }, { date_limit: { gte: actualDate.toISOString() } }]
                    }
                  ]
                },
                order: ['date_created DESC']
              }
            }
          ]
        }
      };
      api.post('ReadPersons/search', params, (status, data) => {
        console.log(data);

        this.setState({
          results: data,
          total: data.length,
          loading: false,
          tableMessages: GridConstants.getGridMessage(this.props.intl, 'tableMessages')
        });
      });
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.setState(
      {
        total: 0,
        results: [],
        loading: true,
        tableMessages: {
          noData: this.props.intl.formatMessage({ id: 'ModalSearch.searchingMsg' })
        }
      },
      () => {
        this.getSearchResults(0);
      }
    );
  };

  handleChangePage = (page) => {
    applyCustomButtonColor(page);

    this.setState(
      {
        loading: true,
        currentPage: page,
        results: []
      },
      () => {
        this.getSearchResults(page * this.state.itemsPerPage);
      }
    );
  };

  handleApplicantDetailOpen = (e, id) => {
    e.preventDefault();

    const applicants = this.state.results;
    const idx = applicants.findIndex((x) => x.id === id);

    this.setState({
      detail_open: true,
      detail_id: id,
      detail_applicant: applicants[idx]
    });
  };

  handleApplicantDetailClose = () => {
    this.setState({
      detail_open: false,
      detail_id: null,
      detail_applicant: null,
      _loading: true
    });
  };

  handleChangeSelectFilterJobs = (e, selected) => {
    //console.log(selected);
    let filterJobs = [];

    selected.forEach((option) => {
      filterJobs.push(option.value);
    });

    this.setState({
      filterJobs: filterJobs,
      filterJobsSelected: filterJobs
    });
  };

  handleRemoveCurrentJobFilter = () => {
    this.setState({
      current_job_id: undefined
    });
  };

  render() {
    const { classes } = this.props;

    const getRowId = (row) => row.id;

    const AvatarFormatter = ({ value, row }) => {
      return (
        <a href="#" onClick={(e) => this.handleApplicantDetailOpen(e, row.id)}>
          <ApplicantAvatar
            name={row.name}
            src={value}
            size={50}
            emailBlocklists={row.emailBlocklists}
            docBlocklists={row.docBlocklists}
          />
        </a>
      );
    };

    const AvatarTypeProvider = (props) => (
      <DataTypeProvider formatterComponent={AvatarFormatter} {...props} />
    );

    const CircularScoreFormatter = ({ value, row, column }) => {
      return (
        <CircularOverallScore
          overall_score={value}
          scores={{
            cv_score: row.cv_score,
            interview_score: row.interview_score,
            videointerview_score: row.videointerview_score,
            customtest_score: row.customtest_score
          }}
          size={50}
        />
      );
    };

    const CircularScoreTypeProvider = (props) => (
      <DataTypeProvider formatterComponent={CircularScoreFormatter} {...props} />
    );

    const NameFormatter = ({ value, row }) => {
      const jobLink = '/pipeline/' + row.job.id + '/candidate/' + row.id;

      let search_highlight;
      if (row.search_highlight) {
        search_highlight = Parser(row.search_highlight);
      }

      return (
        <>
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <Link
                onClick={(e) => this.handleApplicantDetailOpen(e, row.id)}
                style={{
                  cursor: 'pointer'
                }}
              >
                <b>{value}</b>
              </Link>
            </Box>
            <Box>
              <Tooltip
                title={this.props.intl.formatMessage({
                  id: 'ModalSearch.linkToPipeline'
                })}
                arrow
              >
                <Link href={jobLink} className="text-gray-darker">
                  <ExitToAppIcon
                    style={{
                      fontSize: 16
                    }}
                  />
                </Link>
              </Tooltip>
            </Box>
          </Box>
          {row.search_highlight ? (
            <Typography variant="caption" display="block" gutterBottom>
              <span className="text-muted">{search_highlight}</span>
            </Typography>
          ) : null}
        </>
      );
    };

    const NameTypeProvider = (props) => (
      <DataTypeProvider formatterComponent={NameFormatter} {...props} />
    );

    const IconFormatter = ({ value, row, column }) => {
      //console.log(column, value);
      const job = row.job.id;
      let icon;
      switch (column.name) {
        case 'views':
          let css, format_date;
          if (row.views.length > 0) {
            css = 'fa fa-eye text-muted';
            format_date = Moment(row.views[0].date_created).format('lll');
          } else {
            css = 'fa fa-eye-slash';
            format_date = this.props.intl.formatMessage({ id: 'Pipeline.applicant_noView' });
          }

          icon = (
            <Tooltip title={<LocaleFormatter dateToFormat={format_date} />} arrow>
              <small>
                <i className={css}></i>
              </small>
            </Tooltip>
          );
          break;
        case 'notes':
          icon = <ButtonNotes applicant={row} />;
          break;
        case 'favorites':
          icon = <ButtonFav favorites={row.favorites} applicant_id={row.id} />;
          break;
        case 'email':
          if (value) {
            icon = <IconEmail email={value} verified={row.verified_email} />;
          } else {
            icon = null;
          }
          break;
        case 'phone_mobile':
          if (value) {
            icon = <IconPhone phone={value} />;
          } else {
            icon = null;
          }
          break;
        case 'pwd':
          icon = <InfoPwd pwd={value} />;
          break;
        case 'pso':
          if (UserProfile.isAdmin() || UserProfile.isDiversity()) {
            icon = <InfoPso pso={value} />;
          } else {
            icon = null;
          }
          break;
        case 'pwr':
          if (UserProfile.isAdmin() || UserProfile.isDiversity()) {
            icon = <InfoPwr pwr={value} />;
          } else {
            icon = null;
          }
          break;
        case 'origin':
          icon = <LabelOrigin origin={value} />;
          break;
        case 'cv_path':
          icon = <InfoOriginalCv id={row.id} cv_path={value} />;
          break;
        case 'gender':
          if (UserProfile.isAdmin() || UserProfile.isDiversity()) {
            icon = <InfoGender gender={value} />;
          } else {
            icon = null;
          }
          break;
        case 'internal':
          icon = (
            <IconInternal
              internal={row.internal}
              internal_verified={row.internal_verified}
              employee_id={row.employee_id}
            />
          );
          break;
        default:
          icon = value;
      }
      return icon;
    };

    const IconTypeProvider = (props) => (
      <DataTypeProvider formatterComponent={IconFormatter} {...props} />
    );

    const DateCreatedFormatter = ({ value }) => {
      return <small className="text-muted">{Moment(value).format('lll')}</small>;
    };

    const DateCreatedTypeProvider = (props) => (
      <DataTypeProvider formatterComponent={DateCreatedFormatter} {...props} />
    );

    const AddressFormatter = ({ value, row }) => {
      return AddressController.JobAddressFormatter(this, value, row);
    };

    const AddressTypeProvider = (props) => (
      <DataTypeProvider formatterComponent={AddressFormatter} {...props} />
    );

    const RelatedFormatter = ({ value }) => {
      return <small className="text-muted">{value}</small>;
    };

    const RelatedTypeProvider = (props) => (
      <DataTypeProvider formatterComponent={RelatedFormatter} {...props} />
    );

    const displayJobFilter = () => {
      if (this.state.current_job_id) {
        return (
          <Box display="flex" alignItems="center" p={1}>
            <Box minWidth={150} mr={1}>
              <FormattedMessage
                id="ModalSearch.filterCurrentJob"
                defaultMessage="Results for current job:"
              />
            </Box>
            <Box width="100%" flex={1} style={{ overflow: 'hidden' }}>
              <Chip
                avatar={<WorkOutlineIcon />}
                style={{
                  background: CONST.APP_COLORS['gray-lighter'],
                  color: CONST.APP_COLORS['gray-darker']
                }}
                label={
                  <>
                    [{this.state.current_job_code}] {this.state.current_job_title}
                  </>
                }
              />
            </Box>
          </Box>
        );
      } else {
        return null;
        /*
        return (
          <Box width="100%">
            <Autocomplete
              id="filterJobs"
              name="filterJobs"
              value={this.state.filterJobsSelected}
              options={this.state.jobsList}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) => option.value === value.value}
              onChange={this.handleChangeSelectFilterJobs}
              disabled={this.state.loadingJobs}
              multiple
              limitTags={2}
              filterSelectedOptions
              disableCloseOnSelect
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <FormattedMessage
                      id="ModalSearch.filterJobs_placeholder"
                      defaultMessage="Filter inside specific jobs..."
                    />
                  }
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <React.Fragment>
                        <WorkOutlineIcon color="disabled" style={{ fontSize: 20 }} />
                        {params.InputProps.startAdornment}
                      </React.Fragment>
                    ),
                    endAdornment: (
                      <React.Fragment>
                        {this.state.loadingJobs ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        )
        */
      }
    };

    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={this.state.open}
          onEnter={this.onEnter}
          onClose={this.onClose}
          TransitionComponent={Transition}
        >
          <DialogTitle>
            <FormattedMessage id="ModalSearch.title" defaultMessage="Search your Candidates" />
            <IconButton className={classes.closeButton} onClick={this.onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent style={{ height: '75vh', overflow: 'auto' }}>
            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                <form onSubmit={this.onSearch}>
                  <Box width="100%" p={1}>
                    <OutlinedInput
                      id="query"
                      name="query"
                      value={this.state.query}
                      onChange={this.handleChange}
                      fullWidth
                      disabled={this.state.loading}
                      placeholder={this.props.intl.formatMessage({
                        id: 'ModalSearch.searchPlaceholder'
                      })}
                      startAdornment={
                        <Box mr={1}>
                          <Select
                            id="scope"
                            name="scope"
                            value={this.state.scope}
                            onChange={this.handleChange}
                            disabled={this.state.loading}
                            className={classes.select}
                          >
                            <MenuItem value={SCOPE_NAME_EMAIL}>
                              <FormattedMessage
                                id="ModalSearch.scopeNameEmail"
                                defaultMessage="Name / E-mail"
                              />
                            </MenuItem>
                            <MenuItem value={SCOPE_DOC_NUMBER}>
                              <FormattedMessage
                                id="ModalSearch.scopeDocNumber"
                                defaultMessage="Document Number"
                              />
                            </MenuItem>
                            <MenuItem value={SCOPE_CV_TEXT}>
                              <FormattedMessage
                                id="ModalSearch.scopeCvText"
                                defaultMessage="CV Text"
                              />
                            </MenuItem>
                          </Select>
                        </Box>
                      }
                      endAdornment={
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={this.state.loading}
                        >
                          <FormattedMessage id="ModalSearch.buttonSearch" defaultMessage="Search" />
                        </Button>
                      }
                    />
                  </Box>
                </form>

                {/* Jobs filter */}
                <Box width="100%" p={0}>
                  {displayJobFilter()}
                </Box>

                <Box width="100%" p={1}>
                  <Typography variant="caption" display="block" className="text-muted">
                    <FormattedHTMLMessage
                      id="ModalSearch.searchHelp"
                      defaultMessage="See how our search works <a href={link} target='_new'>clicking here</a>"
                      values={{
                        link: CONST.HELP_URL.BR.HOW_SEARCH_WORKS
                      }}
                    />
                  </Typography>
                </Box>
              </Col>
              <Col md={2}></Col>
            </Row>
            <hr />
            <Row>
              <Col md={12}>
                <Paper>
                  {this.state.loading ? <LinearProgress color="secondary" /> : null}
                  <Grid
                    rows={this.state.results}
                    columns={this.state.columns}
                    getRowId={getRowId}
                    size="small"
                  >
                    <AvatarTypeProvider for={this.state.avatarCols} />
                    <CircularScoreTypeProvider for={this.state.scoreCols} />
                    <NameTypeProvider for={this.state.nameCols} />
                    <IconTypeProvider for={this.state.iconCols} />
                    <DateCreatedTypeProvider for={this.state.dateCreatedCols} />
                    <AddressTypeProvider for={this.state.addressCols} />
                    <RelatedTypeProvider for={this.state.relatedCols} />
                    <PagingState
                      currentPage={this.state.currentPage}
                      onCurrentPageChange={this.handleChangePage}
                      pageSize={this.state.itemsPerPage}
                    />
                    <CustomPaging totalCount={this.state.total} />
                    <Table
                      columnExtensions={this.state.columnExtensions}
                      messages={this.state.tableMessages}
                    />
                    <PagingPanel
                      containerComponent={PagingContainer}
                      messages={GridConstants.getGridMessage(
                        this.props.intl,
                        'pagingPanelMessages'
                      )}
                    />
                  </Grid>
                </Paper>
              </Col>
            </Row>
          </DialogContent>
        </Dialog>

        {this.state.detail_applicant ? (
          <ApplicantDetailDialog
            open={this.state.detail_open}
            job_id={this.state.detail_applicant.job_id}
            applicants={this.state.results}
            applicantObj={this.state.detail_applicant}
            applicant_id={this.state.detail_applicant.id}
            //handlerUpdate={(applicants) => this.updateApplicants(applicants)}
            handlerClose={this.handleApplicantDetailClose}
            outsideOfPipeline={true}
          />
        ) : null}
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ModalSearch));
