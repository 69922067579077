import React from 'react';
import { injectIntl, FormattedMessage, defineMessages, FormattedHTMLMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import 'moment/locale/pt-br'; // FIXME locale
import Typography from '@material-ui/core/Typography';
import HumanizeDuration from 'humanize-duration';
import Moment from 'moment';
import LocaleFormatter from '../../../Common/LocaleFormatter';
import * as CONST from '../../../Common/constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import IconLinkedin from '../../../Applicant2/ApplicantCard/IconLinkedin';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import AddressController from '../../../Common/AddressController';
import ChipHired from '../../../Applicant2/ApplicantCard/ChipHired';
import ChipOrigin from '../../../Applicant2/ApplicantCard/ChipOrigin';
import ChipInternal from '../../../Applicant2/ApplicantCard/ChipInternal';
import Utils from '../../../Common/Utils';
import UserProfile from '../../../Common/UserProfile';
import Popover from '@material-ui/core/Popover';
import Api from '../../../Api/Api';
import CardApplicantMap from '../../Card/CardApplicantMap';
import AISuggestionsIcon from '../../../Jobs/CrossRank/AISuggestionsIcon';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ListVideoAnswers2 from '../VideoInterview/ListVideoAnswers2';
import ListCustomTests from '../CustomTest/ListCustomTests';
import CardCustomForm from '../CustomForm/CardCustomForm';
import ButtonNewVideoInterview from '../../../Pipeline/ButtonNewVideoInterview';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ButtonNewEvent from '../../../Pipeline/ButtonNewEvent';
import ListInterview from '../Interview/ListInterview';
import AlertApplicantBlocked from '../../Detail/AlertApplicantBlocked';
import CardDetail from './CardDetail';
import CardSkeleton from '../../../Applicant2/ApplicantCard/CardSkeleton';
import IconButton from '@material-ui/core/IconButton';
import PhoneActions from '../../../Applicant2/CVProfile/PhoneActions';
import EmailActions from '../../../Applicant2/CVProfile/EmailActions';
import CurrencyController from '../../../Common/CurrencyController';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CopyToClipboard from 'react-copy-to-clipboard';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Link from '@material-ui/core/Link';
import CVMenu from '../CVMenu';
import Parser from 'html-react-parser';
import ModalEvent from '../MoreActionsCV/ModalEvent';
import ModalForm from '../MoreActionsCV/ModalForm';
import IconPhone from '../../Card/IconPhone';
import IconEmail from '../../Card/IconEmail';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ComingChip from '../Common/ComingChip';
import NewChip from '../Common/NewChip';
import ChipPwd from '../../../Applicant2/ApplicantCard/ChipPwd';
import PersonalityReport from '../Personality/PersonalityReport';
import Snackbar from '@material-ui/core/Snackbar';
import IconGithub from '../../../Applicant2/ApplicantCard/IconGithub';
import IconBehance from '../../../Applicant2/ApplicantCard/IconBehance';
import { IconOriginalCv } from '../../../Icons/Icons';
import validator from 'validator';

const messages = defineMessages({
  msg_sendForm: {
    id: 'CVDetail.msg_sendForm',
    defaultMessage: 'Hi {applicantName},'
  },
  msg_sendForm2: {
    id: 'CVDetail.msg_sendForm2',
    defaultMessage: 'To proceed in the selection process for [{jobCode}] {jobTitle}'
  },
  msg_sendForm3: {
    id: 'CVDetail.msg_sendForm3',
    defaultMessage: 'at {companyName}, '
  },
  msg_sendForm4: {
    id: 'CVDetail.msg_sendForm4',
    defaultMessage: 'we need you to answer the {formName}.'
  },
  msg_sendForm5: {
    id: 'CVDetail.msg_sendForm5',
    defaultMessage: 'It is quick and simple to answer. Just click on the link below:'
  },
  title_personalityForm: {
    id: 'CVDetail.title_personalityForm',
    defaultMessage: 'Personality and Cultural Form'
  },
  title_diversityForm: {
    id: 'CVDetail.title_diversityForm',
    defaultMessage: 'DEI Form'
  },
  title_salaryForm: {
    id: 'CVDetail.title_salaryForm',
    defaultMessage: 'Salary Form'
  }
});

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

const HtmlEntities = require('html-entities').AllHtmlEntities;
const ents = new HtmlEntities();

const useStyles = (theme) => ({
  printContainer: {
    filter: 'grayscale(1)'
  },
  mainContainer: {
    position: 'relative'
  },
  mainContainerDetail: {
    background: theme.palette.common.white,
    borderRadius: '10px',
    padding: `${theme.spacing(3)}px ${theme.spacing(7)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`
  },
  optionList: {
    //height: 200,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  heading: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  aIheading: {
    color: theme.palette.success.main,

    '@media print': {
      color: theme.palette.gray.darkerMedium
    }
  },
  matchHeading: {
    color: theme.palette.success.main,
    lineHeight: '1',

    '@media print': {
      color: theme.palette.gray.darkerMedium
    }
  },
  scoreMatch: {
    fontSize: '2rem',
    fontWeight: 700
  },
  subHeading: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  stagesSectionSubheading: {
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.gray.darkerMedium
  },
  cvElement: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium,
    fontWeight: 400
  },
  inactiveSeeMore: {
    color: theme.palette.gray.darkerMedium,
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.gray.darkerMedium
    }
  },
  activeSeeMore: {
    color: theme.palette.primary.main,
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  aICvElement: {
    fontSize: '14px',
    color: theme.palette.success.main,
    fontWeight: 400
  },
  summaryTitle: {
    fontSize: '16px'
  },
  aiSummary: {
    color: theme.palette.success.main,
    fontWeight: 400
  },
  normalSummary: {
    color: theme.palette.gray.darker,
    fontWeight: 400
  },
  aiSummaryContainer: {
    backgroundColor: theme.palette.success.light,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    borderRadius: '10px'
  },
  darkCvElement: {
    fontSize: '14px',
    color: theme.palette.gray.darker,
    fontWeight: 400
  },
  expandFilters: {
    color: theme.palette.gray.darkerLight,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandFiltersOpen: {
    transform: 'rotate(180deg)'
  },
  diversityText: {
    color: theme.palette.purple.dark
  },
  expandAboutButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.gray.darkerLight}`,
    padding: '3px 8px'
  },
  moreInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  diversityElementsContainer: {
    display: 'flex',
    margin: '0 0 16px 20px',
    flexWrap: 'wrap',
    gap: '16px 24px'
  },
  interviewsContainer: {
    display: 'flex',
    padding: '0 1.25rem 1.25rem 1.25rem',
    gap: '16px',
    overflowX: 'auto'
  },
  menuPaper: {
    background: 'transparent',
    boxShadow: 'none'
  },
  menuContainer: {
    position: 'absolute',
    top: 80,
    right: 20,
    zIndex: 400,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  topContainer: {
    borderRadius: '10px',
    position: 'relative'
  },
  menuItemChip: {
    position: 'absolute'
  },
  elementWithScrollMargin: {
    scrollMargin: '13.5rem'
  },
  cardContainer: {
    background: theme.palette.gray.lighter,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1)
  },
  iconActionContainer: {
    padding: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  printChip: {
    '@media print': {
      color: theme.palette.gray.darkerMedium,
      backgroundColor: `${theme.palette.gray.darkerMedium}10`,
      borderColor: theme.palette.gray.darkerMedium
    }
  }
});

const StyledChip = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.purple.dark,
    border: `1px solid ${theme.palette.purple.dark}`,
    fontSize: '14px',
    padding: '0.25rem'
  }
}))(Chip);

const IndustryChip = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main,

    '@media print': {
      color: theme.palette.gray.darkerMedium,

      backgroundColor: `${theme.palette.gray.darkerMedium}10`
    }
  }
}))(Chip);

var linkedInProfileURL = function (cv_urls) {
  let linkedinUrl;
  let cv_urls_temp = cv_urls;

  try {
    if (cv_urls_temp) {
      cv_urls_temp = cv_urls_temp.replace('{', '[');
      cv_urls_temp = cv_urls_temp.replace('}', ']');
      cv_urls_temp = JSON.parse(cv_urls_temp);

      if (cv_urls_temp.length > 0) {
        cv_urls_temp.forEach((link) => {
          if (validator.isURL(link)) {
            const decoded_url = decodeURI(link);
            if (Utils.isLinkedInProfile(decoded_url)) {
              linkedinUrl = Utils.getLinkedInProfileFromUrl(decoded_url);
            }
          }
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
  return linkedinUrl;
};

class CVDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Props from parent
      avatar_url: props.profile ? props.profile.avatar_url : undefined,
      name: props.profile ? props.profile.name : undefined,
      pipeline: props.pipeline,
      //company: props.profile ? props.profile.company : undefined,
      headline: props.profile ? props.profile.title_min : undefined,
      summary: undefined,
      cv_profile: props.profile ? props.profile.cv_profile : undefined,
      profile: props.profile,
      job: props.job,
      readonly: props.readonly ? props.readonly : false,
      print: props.print ? props.print : false,
      shareLinkDetails: props.shareLinkDetails,
      accountUsers: props.accountUsers ? props.accountUsers : [],
      // Internal state
      educations: [],
      experiences: [],
      languages: [],
      skills: [],
      certifications: [],
      company: '',
      endDateCompany: '',
      startDateCompany: '',
      currentEmail: '',
      currentPhone: '',
      expandAbout: false,
      industries: [],
      linkedinIndustries: [],
      expandSkills: false,
      firstJobInfo: {},
      // Popover
      anchorEl: null,
      emailAnchorEl: null,
      addressAnchorEl: null,
      interviewsAnchorEl: null,
      // Menu
      cvMenuOpen: null,
      mbtiMatch: '',
      newEventModal: false,
      formModal: false,
      formType: '',
      linkedin_url: null,
      salaryLinkCopied: false,
      formSentSalaryInfo: false,
      diversityLinkCopied: false,
      diversityFormSent: false,
      activity_update_cv: null
    };

    this.cardRef = React.createRef();
    this.blockRef = React.createRef();

    this.ref1 = React.createRef();
    this.ref2 = React.createRef();
    this.ref3 = React.createRef();
    this.ref4 = React.createRef();
    this.ref5 = React.createRef();
    this.ref6 = React.createRef();
    this.ref7 = React.createRef();
    this.ref8 = React.createRef();
    this.ref9 = React.createRef();
    this.ref10 = React.createRef();
    this.ref11 = React.createRef();
    this.ref12 = React.createRef();
  }

  componentDidMount() {
    this.setIndustries();
    this.getFirstJobInfo();
    this.loadJobHistory();
    //this.mountProfile();

    const shareLinkDetails = this.state.shareLinkDetails;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile !== this.props.profile) {
      if (this.props.profile) {
        this.setState(
          {
            cv_profile: this.props.profile.cv_profile,
            avatar_url: this.props.profile.avatar_url,
            name: this.props.profile.name,
            headline: this.props.profile.title_min,
            profile: this.props.profile
          },
          () => {
            this.getFirstJobInfo();
          }
        );
      }
    }
    if (prevProps.job !== this.props.job) {
      if (this.props.job) {
        this.setState(
          {
            job: this.props.job
          },
          () => {
            this.loadJobHistory();
          }
        );
      }
    }

    if (prevProps.pipeline !== this.props.pipeline) {
      if (this.props.pipeline) {
        this.setState({
          pipeline: this.props.pipeline
        });
      }
    }

    if (prevProps.shareLinkDetails !== this.props.shareLinkDetails) {
      this.setState({
        shareLinkDetails: this.props.shareLinkDetails
      });
    }

    if (prevProps.accountUsers !== this.props.accountUsers) {
      this.setState({
        accountUsers: this.props.accountUsers
      });
    }
  }

  setIndustries = () => {
    const industries = this.props.industries.industries;
    const linkedinIndustries = this.props.linkedinIndustries.linkedinIndustries;

    this.setState(
      {
        industries: industries,
        linkedinIndustries: linkedinIndustries
      },
      () => {
        this.mountProfile();
        this.mountContactInfo();
      }
    );
  };

  mountProfile = () => {
    if (this.state.cv_profile) {
      const profile = this.state.profile;
      const cv_profile = this.state.cv_profile;
      const extract_industry = profile.extract_industry;

      const company =
        cv_profile.experiences && cv_profile.experiences.length > 0
          ? cv_profile.experiences[0].company
          : '';

      let endDateCompany = '';
      let startDateCompany = '';

      if (cv_profile.experiences && cv_profile.experiences.length > 0) {
        if (cv_profile.experiences[0].endDate) {
          if (Utils.matchPresentDate(cv_profile.experiences[0].endDate)) {
            endDateCompany = (
              <span>
                {this.props.intl.formatMessage({
                  id: 'CVProfileInfo.present'
                })}
              </span>
            );
          } else if (Utils.isValidDate(new Date(cv_profile.experiences[0].endDate))) {
            endDateCompany = (
              <LocaleFormatter
                dateToFormat={Moment(new Date(cv_profile.experiences[0].endDate))
                  .utc()
                  .format('MMM/YYYY')}
              />
            );
          }
        }

        if (cv_profile.experiences[0].startDate) {
          if (Utils.matchPresentDate(cv_profile.experiences[0].startDate)) {
            startDateCompany = (
              <span>
                {this.props.intl.formatMessage({
                  id: 'CVProfileInfo.present'
                })}
              </span>
            );
          } else if (Utils.isValidDate(new Date(cv_profile.experiences[0].startDate))) {
            startDateCompany = (
              <LocaleFormatter
                dateToFormat={Moment(new Date(cv_profile.experiences[0].startDate))
                  .utc()
                  .format('MMM/YYYY')}
              />
            );
          }
        }

        cv_profile.experiences.forEach((currExperience) => {
          const companyName = currExperience.company;

          const industry = extract_industry?.find(
            (currIndustry) => currIndustry.company === companyName
          );

          if (industry) {
            const linkedinIndustry = this.state.linkedinIndustries.find(
              (currLinkedInIndustry) => currLinkedInIndustry.code_full === industry.parents[0]
            );

            if (linkedinIndustry) {
              let convertedIndustry = this.state.industries.find(
                (currIndustry) => currIndustry.code === linkedinIndustry.industry_code
              );

              currExperience.industryTitle = convertedIndustry ? convertedIndustry.name : '';
            }
          }

          currExperience.textExpanded = false;
        });
      }

      let summary = cv_profile.summary ? cv_profile.summary : cv_profile.title;

      if (
        summary &&
        summary.length >= 270 &&
        this.state.expandAbout === false &&
        !this.state.readonly &&
        !this.state.print
      ) {
        summary = summary.slice(0, 269) + '...';
      }

      let cvProfileSkills = [];

      if (cv_profile.skills && cv_profile.skills.length > 0) {
        cvProfileSkills = cv_profile.skills.map((skill) => {
          if (typeof skill === 'object' && Object.keys(skill).length > 0) {
            return skill.name;
          }

          return skill;
        });
      }

      this.setState({
        educations: cv_profile.educations,
        experiences: cv_profile.experiences,
        languages: cv_profile.languages,
        skills: cvProfileSkills,
        certifications: cv_profile.certifications,
        company: company,
        startDateCompany: startDateCompany,
        endDateCompany: endDateCompany,
        summary: summary
      });
    }
  };

  mountContactInfo = () => {
    const profile = this.state.profile;

    if (profile) {
      let currentEmail = '';
      let currentPhone = '';

      if (profile.linkedinApplicants && profile.linkedinApplicants.length > 0) {
        currentEmail = profile.linkedinApplicants[0].email;
        currentPhone = profile.linkedinApplicants[0].phone;
      } else if (profile.email || profile.phone_mobile) {
        if (profile.email) {
          currentEmail = profile.email;
        }
        if (profile.phone_mobile) {
          currentPhone = profile.phone_mobile;
        }
      }

      this.setState({
        currentEmail: currentEmail,
        currentPhone: currentPhone
      });
    }
  };

  getFirstJobInfo = () => {
    let api = new Api();
    if (this.props.profile && this.props.profile.firstjobid) {
      let filter = {
        fields: ['title', 'type', 'account_page_id'],
        where: {
          id: this.props.profile.firstjobid
        },
        include: {
          relation: 'accountPage',
          scope: {
            fields: ['id', 'path']
          }
        }
      };

      api.get('Jobs', filter, (status, data) => {
        if (data && data.length > 0) {
          // console.log(data)
          this.setState({
            firstJobInfo: data[0]
          });
        } else {
          this.setState({
            firstJobInfo: {}
          });
        }
      });
    }
  };

  handleOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleClose = () => {
    if (this.props.handleClose) {
      this.props.handleClose();
    }
  };

  handleOpenEmail = (e) => {
    if (!this.state.readonly && !this.state.print) {
      this.setState({
        emailAnchorEl: e.currentTarget
      });
    }
  };

  handleCloseEmail = () => {
    this.setState({
      emailAnchorEl: null
    });
  };

  handleOpenAddress = (e) => {
    if (!this.state.readonly && !this.state.print) {
      this.setState({
        addressAnchorEl: e.currentTarget
      });
    }
  };

  handleCloseAddress = () => {
    this.setState({
      addressAnchorEl: null
    });
  };

  handleOpenPhone = (e) => {
    if (!this.state.readonly && !this.state.print) {
      this.setState({
        phoneAnchorEl: e.currentTarget
      });
    }
  };

  handleClosePhone = () => {
    this.setState({
      phoneAnchorEl: null
    });
  };

  handleExpandAbout = () => {
    const cv_profile = this.state.cv_profile;
    let summary = '';

    if (cv_profile) {
      summary = cv_profile.summary ? cv_profile.summary : cv_profile.title;
    }

    // Currently open and it's gonna be closed
    if (this.state.expandAbout === true && summary && summary.length >= 270) {
      summary = summary.slice(0, 269) + '...';
    }

    this.setState({ expandAbout: !this.state.expandAbout, summary: summary });
  };

  handleExpandCurrentExperience = (experienceIndex) => {
    const experiences = this.state.experiences;

    const editedExperience = experiences[experienceIndex];
    editedExperience.textExpanded = !editedExperience.textExpanded;

    experiences.splice(experienceIndex, 1, editedExperience);

    this.setState({
      experiences: experiences
    });
  };

  handleExpandSkills = () => {
    this.setState({
      expandSkills: !this.state.expandSkills
    });
  };

  handleOpenNewInterviewMenu = (e) => {
    this.setState({
      interviewsAnchorEl: e.currentTarget
    });
  };

  handleCloseNewInterviewMenu = () => {
    this.setState({
      interviewsAnchorEl: null
    });
  };

  handleChangeSection = (currRef) => {
    this['ref' + currRef].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  loadJobHistory = () => {
    let api = new Api();
    const applicant = this.state.profile;
    const job = this.state.job;

    if (job) {
      const filterJobActivity = {
        where: {
          applicant_id: applicant.id,
          job: job.id,
          deleted: false,
          type: 'APPLICANT'
        },
        order: 'date_created DESC'
      };

      api.get('JobActivities', filterJobActivity, (status, data) => {
        if (status <= 400) {
          if (data.length > 0) {
            const activityUpdateCV = data.find((item) => item.activity?.action === 'UPDATE_CV');

            if (activityUpdateCV) {
              this.setState({
                activity_update_cv: activityUpdateCV
              });
            }
          }
        } else {
          console.log('Error on fetching data');
        }
      });
    }
  };

  handleUpdateSalaryInfo = (salary_info) => {
    const updatedApplicant = {
      ...this.state.profile,
      salary_info: salary_info
    };

    this.setState({
      profile: updatedApplicant
    });
  };

  sendDiversityForm = () => {
    window
      .swal({
        type: 'info',
        title: '',
        text: 'Enviar formulário de diversidade?',
        closeOnConfirm: true,
        buttons: {
          cancel: {
            text: this.props.intl.formatMessage({
              id: 'Base.button.cancel'
            }),
            value: null,
            visible: true,
            className: '',
            closeModal: true
          },
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: '',
            closeModal: true
          }
        }
      })
      .then((isConfirmed) => {
        if (isConfirmed) {
          const applicant_id = this.state.profile.id;
          let api = new Api();
          let payload = {
            applicant_id: applicant_id
          };
          api.post('Applicants/send-diversity-form', payload, (status, data) => {
            if (status >= 400) {
              window.swal({
                type: 'info',
                title: '',
                text: 'Erro ao enviar formulário de diversidade',
                closeOnConfirm: true
              });
            } else {
              this.setState({
                diversityFormSent: true
              });
            }
          });
        }
      });
  };

  sendSalaryForm = () => {
    window
      .swal({
        type: 'info',
        title: '',
        text: this.props.intl.formatMessage({
          id: 'MoreActionsCV.title_sendSalaryForm'
        }),
        closeOnConfirm: true,
        buttons: {
          cancel: {
            text: this.props.intl.formatMessage({
              id: 'Base.button.cancel'
            }),
            value: null,
            visible: true,
            className: '',
            closeModal: true
          },
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: '',
            closeModal: true
          }
        }
      })
      .then((isConfirmed) => {
        if (isConfirmed) {
          const applicant_id = this.state.profile.id;
          let api = new Api();
          let payload = {
            applicant_id: applicant_id
          };
          api.post('Applicants/send-salary-info-form', payload, (status, data) => {
            if (status >= 400) {
              window.swal({
                type: 'info',
                title: '',
                text: this.props.intl.formatMessage({
                  id: ' MoreActionsCV.msg_sendSalaryFormError'
                }),
                closeOnConfirm: true
              });
            } else {
              this.setState({
                formSentSalaryInfo: true
              });
            }
          });
        }
      });
  };

  refreshInterviews = () => {
    if (this.props.handleUpdateInterviews) {
      this.props.handleUpdateInterviews(true);
    }
  };

  refreshVideoInterviews = () => {
    if (this.props.handleUpdateVideoInterviews) {
      this.props.handleUpdateVideoInterviews(true);
    }
  };

  refreshTests = () => {
    if (this.props.handleUpdateTests) {
      this.props.handleUpdateTests(true);
    }
  };

  handleOpenEventModal = () => {
    this.setState({
      newEventModal: true
    });
  };

  handleCloseEventModal = () => {
    this.setState({
      newEventModal: false
    });
  };

  handleOpenFormModal = (type) => {
    this.setState({
      formModal: true,
      formType: type
    });
  };

  handleCloseFormModal = () => {
    this.setState({
      formModal: false
    });
  };

  handleSetForRefresh = () => {
    if (this.props.handleSetForRefresh) {
      this.props.handleSetForRefresh();
    }
  };

  render() {
    const { classes } = this.props;

    const profile = this.state.profile;
    const pipeline_stages = this.state.pipeline;
    const activity_update_cv = this.state.activity_update_cv;

    let applicantStageObj = {
      name: '',
      type: ''
    };

    if (pipeline_stages && pipeline_stages.length > 0 && profile.pipeline_stage) {
      applicantStageObj = pipeline_stages.find(
        (currStage) => currStage.id === profile.pipeline_stage
      );
    }

    if (!applicantStageObj) {
      applicantStageObj = {
        name: '',
        type: ''
      };
    }

    const sendFormDisabled =
      applicantStageObj.type === CONST.STAGE_DISQUALIFIED || !applicantStageObj.type;

    const shareLinkDetails = this.state.shareLinkDetails;

    const publishedJobsCount = profile.person ? profile.person.publishedJobsCount : 0;

    //console.log(profile);

    const addressOpen = Boolean(this.state.addressAnchorEl);
    const emailOpen = Boolean(this.state.emailAnchorEl);
    const phoneOpen = Boolean(this.state.phoneAnchorEl);

    const job = this.state.job;
    const accountPagePath = job.path;
    const jobHasSalaryForm = job.has_salary_form;

    const cv_score = profile.cv_score;

    const experiences = this.state.experiences;
    const hasExperiences = experiences && experiences.length > 0;

    const educations = this.state.educations;
    const hasEducations = educations && educations.length > 0;

    const certifications = this.state.certifications;
    const hasCertifications = certifications && certifications.length > 0;

    const salary_info = profile.salary_info;
    let hasSalaryInfo = false;

    if (salary_info && Object.keys(salary_info).length > 0) {
      const isMinValueValid =
        salary_info.min !== undefined && salary_info.min !== null && salary_info.min > 0;
      const isCurrencyValid =
        salary_info.currency !== undefined &&
        salary_info.currency !== null &&
        salary_info.currency !== '';
      const isDurationValid =
        salary_info.duration !== undefined &&
        salary_info.duration !== null &&
        salary_info.duration !== '';

      if (isMinValueValid && isCurrencyValid && isDurationValid) {
        hasSalaryInfo = true;
      }
    }

    const languages = this.state.languages;
    const hasLanguages = languages && languages.length > 0;

    const videoInterviews = this.state.profile.videoInterviews;
    const videoInterviewStage = this.state.pipeline.find(
      (currStage) => currStage.type === CONST.STAGE_VIDEOINTERVIEW
    );
    const hasVideoInterviews = videoInterviews && videoInterviews.length > 0;

    const customTests = this.state.profile.customTests;
    const hasCustomTests = customTests && customTests.length > 0;

    const customForms = this.state.profile.customForms;
    const hasForms = customForms && customForms.length > 0;

    const interviews = this.state.profile.interviews;
    const interviewStages = this.state.pipeline.filter(
      (currStage) => currStage.type === CONST.STAGE_INTERVIEW
    );
    const hasInterviews = interviews && interviews.length > 0;

    let applicantOrigin = profile.origin;

    if (applicantOrigin === 'SCRAP') {
      applicantOrigin = 'HUNTING';
    }

    if (applicantOrigin === 'APPLY') {
      applicantOrigin = 'FORM';
    }

    if (applicantOrigin === 'MAILIN') {
      applicantOrigin = 'MAIL';
    }

    const formatttedOriginString = applicantOrigin
      ? applicantOrigin.slice(0, 1).toUpperCase() + applicantOrigin.slice(1).toLowerCase()
      : null;

    let pso = '';
    let pwr = '';
    let gender = '';

    let address = undefined;
    if (profile.address && Object.keys(profile.address).length > 0) {
      address = profile.address;
    }

    const title = profile.cv_profile ? profile.cv_profile.title : '';

    const unique_name = this.props.accountInfo
      ? this.props.accountInfo.accountInfo.unique_name
      : '';

    const abbreviation = this.props.accountInfo
      ? this.props.accountInfo.accountInfo.abbreviation
      : '';

    const phone = profile.phone_mobile;
    let whatsapp;
    if (phone) {
      whatsapp = phone.replace(/\D/g, ''); // https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/?lang=kk
    }

    if (
      (UserProfile.isAdmin() || UserProfile.isDiversity()) &&
      profile.pso &&
      profile.pso !== 'no'
    ) {
      switch (profile.pso) {
        case 'A':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.heterosexual'
          });
          break;
        case 'B':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.lesbian'
          });
          break;
        case 'C':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.gay'
          });
          break;
        case 'D':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.bisexual'
          });
          break;
        case 'E':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.transgender'
          });
          break;
        case 'F':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.queer'
          });
          break;
        case 'G':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.intersexuals'
          });
          break;
        case 'H':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.asexuals'
          });
          break;
        case 'I':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.pansexual'
          });
          break;
        case 'J':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.other'
          });
          break;
        case 'OPTOUT':
          pso = this.props.intl.formatMessage({
            id: 'InfoPso.opt_out'
          });
          break;
      }
    }

    if (
      (UserProfile.isAdmin() || UserProfile.isDiversity()) &&
      profile.pwr &&
      profile.pwr !== 'no'
    ) {
      switch (profile.pwr) {
        case 'A':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.white'
          });
          break;
        case 'B':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.indigenous'
          });
          break;
        case 'C':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.yellow'
          });
          break;
        case 'D':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.black'
          });
          break;
        case 'E':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.brown'
          });
          break;
        case 'F':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.other'
          });
          break;
        case 'OPTOUT':
          pwr = this.props.intl.formatMessage({
            id: 'InfoPwr.opt_out'
          });
          break;
      }
    }

    if (profile.gender && profile.gender !== '') {
      switch (profile.gender) {
        case 'M':
          gender = this.props.intl.formatMessage({ id: 'InfoGender.male' });
          break;
        case 'F':
          gender = this.props.intl.formatMessage({ id: 'InfoGender.female' });
          break;
        case 'NB':
          gender = this.props.intl.formatMessage({ id: 'InfoGender.nonBinary' });
          break;
        case 'O':
          gender = this.props.intl.formatMessage({ id: 'InfoGender.other' });
          break;
        case 'OPTOUT':
          gender = this.props.intl.formatMessage({ id: 'InfoGender.optOut' });
          break;
      }
    }

    const displayPwd = () => {
      if (profile.pwd && profile.pwd !== 'no') {
        let pwd = profile.pwd;
        if (pwd === 'OPTOUT') {
          pwd = 'opt_out';
        }
        return (
          <Box display="flex" alignItems="center">
            <Box mb={2}>
              <Box mb={1}>
                <Typography className={classes.subHeading}>
                  <FormattedMessage id="CVProfileInfo.label_pwd" />
                </Typography>
              </Box>

              <Typography className={classes.cvElement}>
                {this.props.intl.formatMessage({
                  id: 'InfoPwd.' + pwd
                })}
              </Typography>
            </Box>
          </Box>
        );
      } else {
        return null;
      }
    };

    const hasDiversity = pso || pwr || gender;

    const hasEmail = this.state.currentEmail && !this.state.currentEmail.startsWith('profile:');
    const hasDocNumber = this.state.profile && this.state.profile.doc_number;

    const displayEmail = () => {
      if (shareLinkDetails) {
        if (shareLinkDetails.privacy) {
          return Utils.hideInfo(this.state.currentEmail);
        }
        if (shareLinkDetails.contact === false) {
          return '';
        }
      }
      return (
        <a
          style={{
            cursor: !this.state.readonly && !this.state.print ? 'pointer ' : 'text',
            color: !this.state.readonly && !this.state.print ? 'primary ' : 'inherit'
          }}
          onClick={(e) => this.handleOpenEmail(e)}
        >
          {this.state.currentEmail}
        </a>
      );
    };

    const displayPhone = () => {
      if (shareLinkDetails) {
        if (shareLinkDetails.privacy) {
          return (
            <Typography className={classes.cvElement}>
              +{Utils.hideInfo(this.state.currentPhone)}
            </Typography>
          );
        }
        if (shareLinkDetails.contact === false) {
          return null;
        }
      }
      return (
        <Box display="flex" alignItems="center">
          <span
            className={classes.cvElement}
            style={{
              margin: '0 4px',
              fontWeight: 800
            }}
          >
            &bull;
          </span>
          <Typography className={classes.cvElement}>
            <a
              style={{
                cursor: !this.state.readonly && !this.state.print ? 'pointer ' : 'text',
                color: !this.state.readonly && !this.state.print ? 'primary ' : 'inherit'
              }}
              onClick={(e) => this.handleOpenPhone(e)}
            >
              +{this.state.currentPhone}
            </a>
          </Typography>
        </Box>
      );
    };

    const displayLinkedin = () => {
      if (shareLinkDetails) {
        if (shareLinkDetails.privacy || shareLinkDetails.contact === false) {
          return null;
        }
      }

      let linkedin_url;

      linkedin_url = profile.profile_linkedin;

      if (linkedin_url) {
        return (
          <Box display="flex" alignItems="center">
            <span
              className={classes.cvElement}
              style={{
                margin: '0 4px',
                fontWeight: 800
              }}
            >
              &bull;
            </span>
            <IconLinkedin textLink={true} size={20} url={linkedin_url} />
          </Box>
        );
      } else {
        return null;
      }
    };

    const displayGithub = () => {
      if (shareLinkDetails) {
        if (shareLinkDetails.privacy || shareLinkDetails.contact === false) {
          return null;
        }
      }

      let profile_github;

      profile_github = profile.profile_github;

      if (profile_github) {
        return (
          <Box display="flex" alignItems="center">
            <span
              className={classes.cvElement}
              style={{
                margin: '0 4px',
                fontWeight: 800
              }}
            >
              &bull;
            </span>
            <IconGithub textLink={true} size={20} url={profile_github} />
          </Box>
        );
      } else {
        return null;
      }
    };

    const displayBehance = () => {
      if (shareLinkDetails) {
        if (shareLinkDetails.privacy || shareLinkDetails.contact === false) {
          return null;
        }
      }

      let profile_behance;

      profile_behance = profile.profile_behance;

      if (profile_behance) {
        return (
          <Box display="flex" alignItems="center">
            <span
              className={classes.cvElement}
              style={{
                margin: '0 4px',
                fontWeight: 800
              }}
            >
              &bull;
            </span>
            <IconBehance textLink={true} size={20} url={profile_behance} />
          </Box>
        );
      } else {
        return null;
      }
    };

    const displayAddress = () => {
      if (shareLinkDetails) {
        if (shareLinkDetails.privacy) {
          return (
            <Typography className={classes.cvElement}>
              {Utils.hideInfo(AddressController.addrApplicantFull(address))}
            </Typography>
          );
        }
        if (shareLinkDetails.contact === false) {
          return null;
        }
      }
      return (
        <Box display="flex" alignItems="center">
          <Typography className={classes.cvElement}>
            {AddressController.addrApplicantFull(address)}
          </Typography>
          {profile.address_point &&
          profile.address_geocode &&
          !this.state.readonly &&
          !this.state.print ? (
            <>
              <span
                className={classes.cvElement}
                style={{
                  margin: '0 4px',
                  fontWeight: 800
                }}
              >
                &bull;
              </span>
              <a
                style={{
                  cursor: !this.state.readonly && !this.state.print ? 'pointer ' : 'text',
                  color: !this.state.readonly && !this.state.print ? 'primary ' : 'inherit'
                }}
                onClick={(e) => this.handleOpenAddress(e)}
              >
                {' '}
                <FormattedMessage id="CVProfileInfo.button_viewInMap" />
              </a>
            </>
          ) : null}
        </Box>
      );
    };

    const displayContactIcons = () => {
      if (shareLinkDetails) {
        if (shareLinkDetails.contact === false || shareLinkDetails.privacy) {
          return null;
        }
      }

      return (
        <Box display="flex" alignItems="center">
          {hasEmail ? <IconEmail email={this.state.currentEmail} size={20} variant="cv" /> : null}
          {this.state.currentPhone ? (
            <IconPhone phone={this.state.currentPhone} size={20} variant="cv" />
          ) : null}
          <IconOriginalCv applicant={profile} size={20} activity_update_cv={activity_update_cv} />
        </Box>
      );
    };

    const displayExperiences = () => {
      const showExperiences = hasExperiences && (!shareLinkDetails || shareLinkDetails.experiences);

      if (showExperiences) {
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                marginTop: '1rem'
              }}
              ref={this.ref8}
            >
              <Box mb={3}>
                <Typography className={classes.heading}>
                  <FormattedMessage id="CardCVExperience.title" />
                </Typography>
              </Box>
              <Box>
                <Box display="flex" flexDirection="column" ml={2.5}>
                  {experiences.map((currExperience, i) => {
                    const endDate =
                      currExperience.endDate && !Utils.matchPresentDate(currExperience.endDate)
                        ? Moment(currExperience.endDate)
                        : Moment();

                    const toe_humanize = currExperience.startDate
                      ? HumanizeDuration(
                          Moment(endDate).diff(Moment(currExperience.startDate), 'days'),
                          {
                            language: this.props.user.language.substr(0, 2),
                            units: ['y', 'mo'],
                            unitMeasures: {
                              y: 365,
                              mo: 30,
                              w: 7,
                              d: 1
                            },
                            spacer: ' ',
                            round: true
                          }
                        )
                      : undefined;

                    let startDateLabel = undefined;
                    let endDateLabel = undefined;

                    if (currExperience.startDate && currExperience.startDate !== '') {
                      if (Utils.isValidDate(new Date(currExperience.startDate))) {
                        startDateLabel = (
                          <LocaleFormatter
                            dateToFormat={Moment(new Date(currExperience.startDate))
                              .utc()
                              .format('MMM/YYYY')}
                          />
                        );
                      }
                    }

                    if (currExperience.endDate && currExperience.endDate !== '') {
                      if (Utils.matchPresentDate(currExperience.endDate)) {
                        endDateLabel = (
                          <span>
                            {this.props.intl.formatMessage({
                              id: 'CVProfileInfo.present'
                            })}
                          </span>
                        );
                      } else if (Utils.isValidDate(new Date(currExperience.endDate))) {
                        endDateLabel = (
                          <LocaleFormatter
                            dateToFormat={Moment(new Date(currExperience.endDate))
                              .utc()
                              .format('MMM/YYYY')}
                          />
                        );
                      }
                    }

                    let experienceSummary = currExperience.text ? currExperience.text : '';

                    let hasMoreThan270Characters =
                      experienceSummary && experienceSummary.length >= 270 ? true : false;

                    if (currExperience.textExpanded === false) {
                      if (
                        experienceSummary &&
                        experienceSummary.length >= 270 &&
                        !this.state.readonly &&
                        !this.state.print
                      ) {
                        experienceSummary = experienceSummary.slice(0, 269) + '...';
                      }
                    }

                    return (
                      <Box mb={2}>
                        <Box display="flex" flexDirection="column" mb={2}>
                          <Box mb={1}>
                            <Typography className={classes.subHeading}>
                              {currExperience.jobTitle}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              className={classes.darkCvElement}
                              style={{
                                margin: 0
                              }}
                            >
                              {currExperience.company}
                            </Typography>

                            {currExperience.industryTitle ? (
                              <>
                                <span
                                  className={classes.cvElement}
                                  style={{
                                    margin: '0 4px',
                                    fontWeight: 800
                                  }}
                                >
                                  &bull;
                                </span>
                                <Box>
                                  <IndustryChip
                                    size="small"
                                    label={currExperience.industryTitle}
                                    className={classes.printChip}
                                  />
                                </Box>
                              </>
                            ) : null}
                          </Box>
                          {startDateLabel ? (
                            <Box>
                              <Typography
                                className={classes.cvElement}
                                style={{
                                  margin: '4px 0 0 0'
                                }}
                              >
                                {startDateLabel}
                                {' - '}
                                {endDateLabel}
                                <span
                                  className={classes.cvElement}
                                  style={{
                                    margin: '0 4px',
                                    fontWeight: 800
                                  }}
                                >
                                  &bull;
                                </span>
                                {toe_humanize}
                              </Typography>
                            </Box>
                          ) : null}
                          {experienceSummary ? (
                            <Box>
                              <Typography
                                className={classes.darkCvElement}
                                style={{
                                  margin: '8px 0 0 0'
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: ents.decode(
                                      experienceSummary.replace(/(?:\r\n|\r|\n)/g, '<br />')
                                    )
                                  }}
                                />
                                {hasMoreThan270Characters &&
                                !this.state.readonly &&
                                !this.state.print ? (
                                  <>
                                    &nbsp;
                                    <a
                                      className={
                                        currExperience.textExpanded
                                          ? classes.inactiveSeeMore
                                          : classes.activeSeeMore
                                      }
                                      onClick={(e) => this.handleExpandCurrentExperience(i)}
                                    >
                                      {currExperience.textExpanded ? (
                                        <FormattedMessage id="CVProfileInfo.label_seeLess" />
                                      ) : (
                                        <FormattedMessage id="CVProfileInfo.label_seeMore" />
                                      )}

                                      {currExperience.textExpanded ? (
                                        <KeyboardArrowUpIcon fontSize="small" color="secondary" />
                                      ) : (
                                        <KeyboardArrowDownIcon fontSize="small" color="primary" />
                                      )}
                                    </a>
                                  </>
                                ) : null}
                              </Typography>
                            </Box>
                          ) : null}
                        </Box>

                        {i !== experiences.length - 1 ? <Divider light /> : null}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </div>
            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displayEducations = () => {
      const showEducations = hasEducations && (!shareLinkDetails || shareLinkDetails.education);

      if (showEducations) {
        educations.sort(dynamicSort('-startYear'));
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                marginTop: '1.5rem'
              }}
              ref={this.ref7}
            >
              <Box mb={3}>
                <Typography className={classes.heading}>
                  <FormattedMessage id="CardAcademicLevel2.title" />
                </Typography>
              </Box>
              <Box>
                {
                  <Box ml={2.5}>
                    {educations.map((currEducation, i) => {
                      let startYearLabel = undefined;
                      let endYearLabel = undefined;

                      if (currEducation.startYear && currEducation.startYear !== '') {
                        const startYear =
                          typeof currEducation.startYear === 'string'
                            ? currEducation.startYear
                            : currEducation.startYear.toString();
                        if (Utils.matchPresentDate(startYear)) {
                          startYearLabel = (
                            <span>
                              {this.props.intl.formatMessage({
                                id: 'CVProfileInfo.present'
                              })}
                            </span>
                          );
                        } else if (Utils.isValidDate(new Date(startYear))) {
                          startYearLabel = (
                            <LocaleFormatter
                              dateToFormat={Moment(new Date(startYear)).utc().format('YYYY')}
                            />
                          );
                        }
                      }

                      if (currEducation.endYear && currEducation.endYear !== '') {
                        const endYear =
                          typeof currEducation.endYear === 'string'
                            ? currEducation.endYear
                            : currEducation.endYear.toString();
                        if (Utils.matchPresentDate(endYear)) {
                          endYearLabel = (
                            <span>
                              {this.props.intl.formatMessage({
                                id: 'CVProfileInfo.present'
                              })}
                            </span>
                          );
                        } else if (Utils.isValidDate(new Date(endYear))) {
                          endYearLabel = (
                            <LocaleFormatter
                              dateToFormat={Moment(new Date(endYear)).utc().format('YYYY')}
                            />
                          );
                        }
                      }

                      return (
                        <Box mb={2}>
                          <Box display="flex" flexDirection="column" mb={2}>
                            <Box mb={1}>
                              <Typography className={classes.subHeading}>
                                {currEducation.degree}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                className={classes.darkCvElement}
                                style={{
                                  margin: 0
                                }}
                              >
                                {currEducation.schoolName}
                              </Typography>
                            </Box>
                            {startYearLabel ? (
                              <Box>
                                <Typography
                                  className={classes.cvElement}
                                  style={{
                                    margin: 0
                                  }}
                                >
                                  {startYearLabel}
                                  {' - '}
                                  {endYearLabel}
                                </Typography>
                              </Box>
                            ) : null}
                          </Box>

                          {i !== educations.length - 1 ? <Divider light /> : null}
                        </Box>
                      );
                    })}
                  </Box>
                }
              </Box>
            </div>
            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displayDiversity = () => {
      if (hasDiversity && (!shareLinkDetails || !shareLinkDetails.privacy)) {
        const pwrContainer = pwr ? (
          <Box display="flex" alignItems="center">
            <Typography
              className={classes.cvElement}
              style={{
                fontWeight: 700
              }}
            >
              {this.props.intl.formatMessage({ id: 'JobDiversity.table.pwr' })}

              <span
                className={classes.cvElement}
                style={{
                  margin: '0 4px',
                  fontWeight: 800
                }}
              >
                &bull;
              </span>
            </Typography>
            <StyledChip size="small" label={pwr} className={classes.printChip} />
          </Box>
        ) : null;

        const genderContainer = gender ? (
          <Box display="flex" alignItems="center">
            <Typography
              className={classes.cvElement}
              style={{
                fontWeight: 700
              }}
            >
              {this.props.intl.formatMessage({ id: 'JobDiversity.table.gender' })}
              <span
                className={classes.cvElement}
                style={{
                  margin: '0 4px',
                  fontWeight: 800
                }}
              >
                &bull;
              </span>
            </Typography>
            <StyledChip size="small" label={gender} className={classes.printChip} />
          </Box>
        ) : null;

        const psoContainer = pso ? (
          <Box display="flex" alignItems="center">
            <Typography
              className={classes.cvElement}
              style={{
                fontWeight: 700
              }}
            >
              {this.props.intl.formatMessage({ id: 'DetailSummary.pso' })}

              <span
                className={classes.cvElement}
                style={{
                  margin: '0 4px',
                  fontWeight: 800
                }}
              >
                &bull;
              </span>
            </Typography>
            <StyledChip size="small" label={pso} className={classes.printChip} />
          </Box>
        ) : null;
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                marginTop: '1.25rem'
              }}
              ref={this.ref2}
            >
              <Box mb={2}>
                <Typography className={`${classes.heading} ${classes.diversityText}`}>
                  <FormattedMessage id="JobAllReport.table.diversity_field" />
                </Typography>
              </Box>
              <Box className={classes.diversityElementsContainer}>
                {pwrContainer}
                {genderContainer}
                {psoContainer}
              </Box>
            </div>

            <Divider />
          </>
        );
      } else if (
        job.has_diversity &&
        !this.state.readonly &&
        Utils.hasDiversity(job.country).canAskDiversity &&
        !this.state.print
      ) {
        const sendFormMessage =
          this.props.intl.formatMessage(
            { id: 'CVDetail.msg_sendForm' },
            {
              applicantName: profile.name
            }
          ) +
          ' \r\n' +
          ' \r\n' +
          this.props.intl.formatMessage(
            { id: 'CVDetail.msg_sendForm2' },
            {
              jobCode: job.code,
              jobTitle: job.title
            }
          ) +
          this.props.intl.formatMessage(
            { id: 'CVDetail.msg_sendForm3' },
            {
              companyName: abbreviation
            }
          ) +
          this.props.intl.formatMessage(
            { id: 'CVDetail.msg_sendForm4' },
            {
              formName: this.props.intl.formatMessage({
                id: 'CVDetail.title_diversityForm'
              })
            }
          ) +
          ' \r\n' +
          ' \r\n' +
          this.props.intl.formatMessage({ id: 'CVDetail.msg_sendForm5' }) +
          ' \r\n' +
          ' \r\n' +
          `https://${unique_name}.jobs.recrut.ai${
            accountPagePath === '/' ? '' : accountPagePath
          }/diversity/${profile.id}`;
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                margin: '1.25rem 0'
              }}
              ref={this.ref2}
            >
              <Box mb={3}>
                <Typography className={`${classes.heading} ${classes.diversityText}`}>
                  <FormattedMessage id="JobAllReport.table.diversity_field" />
                </Typography>
              </Box>
              <Box ml={2} mb={2}>
                <Box mb={1}>
                  <Typography className={classes.cvElement}>
                    <FormattedHTMLMessage
                      id="CVDetail.msg_diversityFormNotAnswered"
                      defaultMessage="The candidate hasn't completed the DEI form. <br />Click to ask him/her to complete the form."
                    />
                  </Typography>
                </Box>
                {UserProfile.isAdmin() || UserProfile.isHRManager() || UserProfile.isRecruiter() ? (
                  <Box mt={1} display="flex" alignItems="center">
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<AssignmentIcon color="primary" fontSize="small" />}
                      onClick={this.sendDiversityForm}
                    >
                      {this.state.diversityFormSent ? (
                        <FormattedMessage
                          id="CVDetail.label_sentDiversityForm"
                          defaultMessage="DEI form sent"
                        />
                      ) : (
                        <FormattedMessage
                          id="CVDetail.label_sendDiversityButton"
                          defaultMessage="Request DEI information"
                        />
                      )}
                    </Button>
                    <Box ml={1}>
                      <CopyToClipboard
                        text={`https://${unique_name}.jobs.recrut.ai${
                          accountPagePath === '/' ? '' : accountPagePath
                        }/diversity/${profile.id}`}
                        onCopy={() => this.setState({ diversityLinkCopied: true })}
                      >
                        <IconButton className={classes.iconActionContainer}>
                          <FileCopyOutlinedIcon />
                        </IconButton>
                      </CopyToClipboard>
                      <Snackbar
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center'
                        }}
                        open={this.state.diversityLinkCopied}
                        autoHideDuration={3000}
                        onClose={() => this.setState({ diversityLinkCopied: false })}
                        message={<FormattedMessage id="ModalShareLink.copy_confirmed" />}
                      />
                    </Box>
                    {whatsapp ? (
                      <Box>
                        <IconButton
                          className={classes.iconWhatsappActionContainer}
                          component={Link}
                          href={'https://wa.me/' + whatsapp + `?text=${encodeURI(sendFormMessage)}`}
                          target="_whatsapp"
                        >
                          <WhatsAppIcon />
                        </IconButton>
                      </Box>
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            </div>

            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displayCertifications = () => {
      const showCertifications =
        hasCertifications && (!shareLinkDetails || shareLinkDetails.certifications);

      if (showCertifications) {
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                marginTop: '1.5rem'
              }}
              ref={this.ref9}
            >
              <Box mb={3}>
                <Typography className={classes.heading}>
                  <FormattedMessage id="CVProfileInfo.label_certifications" />
                </Typography>
              </Box>
              <Box>
                {' '}
                <Box display="flex" flexDirection="column" ml={2.5}>
                  {certifications.map((currExperience, i) => {
                    const endDate =
                      currExperience.endDate && currExperience.endDate !== 'present'
                        ? Moment(currExperience.endDate)
                        : Moment();

                    return (
                      <Box mb={2}>
                        <Box display="flex" flexDirection="column" mb={2}>
                          <Box mb={1}>
                            <Typography className={classes.subHeading}>
                              {currExperience.name}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              className={classes.darkCvElement}
                              style={{
                                margin: 0
                              }}
                            >
                              {currExperience.issuer}
                            </Typography>
                            {currExperience.year ? (
                              <Box>
                                <Typography
                                  className={classes.cvElement}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <span
                                    className={classes.cvElement}
                                    style={{
                                      margin: '0 4px',
                                      fontWeight: 800
                                    }}
                                  >
                                    &bull;
                                  </span>
                                  {currExperience.year}
                                </Typography>
                              </Box>
                            ) : null}
                          </Box>
                        </Box>

                        {i !== certifications.length - 1 ? <Divider light /> : null}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </div>
            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displaySalaryInfo = () => {
      const showSalaryInfo = hasSalaryInfo && (!shareLinkDetails || shareLinkDetails.salary_info);

      if (showSalaryInfo) {
        const hasMax =
          salary_info.max !== null && salary_info.max !== undefined && salary_info.max > 0;
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                margin: '1.5rem 0'
              }}
              ref={this.ref10}
            >
              <Box mb={3}>
                <Typography className={classes.heading}>
                  <FormattedMessage id="SalaryInfoEdit.label_salaryExpectation" />
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" ml={2.5}>
                <Box mr={0.5}>
                  <Typography className={classes.subHeading}>
                    {hasMax ? (
                      <FormattedMessage
                        id="CVDetail.label_salaryRange"
                        defaultMessage="{min} - {max}"
                        values={{
                          currency: salary_info.currency,
                          min: CurrencyController.displayWithCurrency(
                            this,
                            salary_info.min,
                            salary_info.currency
                          ),
                          max: CurrencyController.displayWithCurrency(
                            this,
                            salary_info.max,
                            salary_info.currency
                          ).replace(/[^\d,.-]/g, '')
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id="CVDetail.label_type"
                        defaultMessage="{min}"
                        values={{
                          currency: salary_info.currency,
                          min: CurrencyController.displayWithCurrency(
                            this,
                            salary_info.min,
                            salary_info.currency
                          )
                        }}
                      />
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    className={classes.cvElement}
                    style={{
                      lineHeight: 'normal'
                    }}
                  >
                    <FormattedMessage
                      id="CVDetail.label_salaryValue"
                      defaultMessage="by {duration}"
                      values={{
                        duration: this.props.intl.formatMessage({
                          id: 'SalaryInfoEdit.opt_' + salary_info.duration.toLowerCase()
                        })
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
            </div>
            <Divider />
          </>
        );
      } else if (!this.state.readonly && !this.state.print) {
        const sendFormMessage =
          this.props.intl.formatMessage(
            { id: 'CVDetail.msg_sendForm' },
            {
              applicantName: profile.name
            }
          ) +
          ' \r\n' +
          ' \r\n' +
          this.props.intl.formatMessage(
            { id: 'CVDetail.msg_sendForm2' },
            {
              jobCode: job.code,
              jobTitle: job.title
            }
          ) +
          this.props.intl.formatMessage(
            { id: 'CVDetail.msg_sendForm3' },
            {
              companyName: abbreviation
            }
          ) +
          this.props.intl.formatMessage(
            { id: 'CVDetail.msg_sendForm4' },
            {
              formName: this.props.intl
                .formatMessage({ id: 'CVDetail.title_salaryForm' })
                .toLowerCase()
            }
          ) +
          ' \r\n' +
          ' \r\n' +
          this.props.intl.formatMessage({ id: 'CVDetail.msg_sendForm5' }) +
          ' \r\n' +
          ' \r\n' +
          `https://${unique_name}.jobs.recrut.ai${
            accountPagePath === '/' ? '' : accountPagePath
          }/salary/${profile.id}`;
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                margin: '1.25rem 0'
              }}
              ref={this.ref10}
            >
              <Box mb={3}>
                <Typography className={classes.heading}>
                  <FormattedMessage id="SalaryInfoEdit.label_salaryExpectation" />
                </Typography>
              </Box>
              <Box ml={2.5} mb={2}>
                {/* <Box mb={1}>
                  <Typography className={classes.cvElement}>
                    <FormattedHTMLMessage
                      id="CVDetail.msg_salaryFormNotAnswered"
                      defaultMessage="The candidate hasn't answered. <br />To get more information on candidate salary expectation click the button below and submit the form."
                    />
                  </Typography>
                </Box> */}
                {UserProfile.isAdmin() || UserProfile.isHRManager() || UserProfile.isRecruiter() ? (
                  <Box mt={1} display="flex" alignItems="center">
                    <Box mr={1}>
                      <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<AssignmentIcon fontSize="small" />}
                        onClick={this.sendSalaryForm}
                        disabled={hasSalaryInfo}
                      >
                        {this.state.formSentSalaryInfo ? (
                          <FormattedMessage
                            id="CVDetail.label_sentSalaryButton"
                            defaultMessage="Salary form sent"
                          />
                        ) : (
                          <FormattedMessage
                            id="CVDetail.label_sendSalaryButton"
                            defaultMessage="Send Salary Form"
                          />
                        )}
                      </Button>
                    </Box>
                    <NewChip />
                    <Box ml={1}>
                      <CopyToClipboard
                        text={`https://${unique_name}.jobs.recrut.ai${
                          accountPagePath === '/' ? '' : accountPagePath
                        }/salary/${profile.id}`}
                        onCopy={() => this.setState({ salaryLinkCopied: true })}
                      >
                        <IconButton className={classes.iconActionContainer}>
                          <FileCopyOutlinedIcon />
                        </IconButton>
                      </CopyToClipboard>
                      <Snackbar
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center'
                        }}
                        open={this.state.salaryLinkCopied}
                        autoHideDuration={3000}
                        onClose={() => this.setState({ fitLinkCopied: false })}
                        message={<FormattedMessage id="ModalShareLink.copy_confirmed" />}
                      />
                    </Box>
                    {whatsapp ? (
                      <Box>
                        <IconButton
                          className={classes.iconActionContainer}
                          component={Link}
                          href={'https://wa.me/' + whatsapp + `?text=${encodeURI(sendFormMessage)}`}
                          target="_whatsapp"
                        >
                          <WhatsAppIcon />
                        </IconButton>
                      </Box>
                    ) : null}
                  </Box>
                ) : null}
              </Box>
            </div>
            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displayLanguages = () => {
      const showLanguages = hasLanguages && (!shareLinkDetails || shareLinkDetails.languages);

      if (showLanguages) {
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                marginTop: '1.5rem'
              }}
              ref={this.ref11}
            >
              <Box mb={3}>
                <Typography className={classes.heading}>
                  <FormattedMessage id="IdiomList.title" />
                </Typography>
              </Box>
              <Box>
                <Box display="flex" flexDirection="column" ml={2.5}>
                  {languages.map((currLanguage) => {
                    let proficiency = currLanguage.proficiency;
                    if (currLanguage.proficiency && currLanguage.proficiency !== '') {
                      switch (currLanguage.proficiency) {
                        case 'ELEMENTARY':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_basic'
                          });
                          break;
                        case 'LIMITED_WORKING':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_basic'
                          });
                          break;
                        case 'MINIMUM_PROFESSIONAL':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_intermediate'
                          });
                          break;
                        case 'PROFESSIONAL_WORKING':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_intermediate'
                          });
                          break;
                        case 'FULL_PROFESSIONAL':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_advanced'
                          });
                          break;
                        case 'NATIVE_OR_BILINGUAL':
                          proficiency = this.props.intl.formatMessage({
                            id: 'CVProfileInfo.proficiency_native'
                          });
                          break;
                      }
                    }
                    return (
                      <Box mb={1}>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <Typography className={classes.subHeading}>
                              {currLanguage.name}
                              <span
                                className={classes.cvElement}
                                style={{
                                  margin: '0 4px',
                                  fontWeight: 800
                                }}
                              >
                                &bull;
                              </span>
                            </Typography>
                          </Box>
                          {currLanguage.proficiency ? (
                            <Box pt={0.25}>
                              <Typography
                                className={classes.darkCvElement}
                                style={{
                                  lineHeight: 'normal'
                                }}
                              >
                                {proficiency}{' '}
                                {this.props.intl.formatMessage({
                                  id:
                                    'CVProfileInfo.proficiency_linkedin_' + currLanguage.proficiency
                                }) !== undefined &&
                                !this.props.intl
                                  .formatMessage({
                                    id:
                                      'CVProfileInfo.proficiency_linkedin_' +
                                      currLanguage.proficiency
                                  })
                                  .startsWith('CVProfileInfo.')
                                  ? `(${this.props.intl.formatMessage({
                                      id:
                                        'CVProfileInfo.proficiency_linkedin_' +
                                        currLanguage.proficiency
                                    })})`
                                  : ''}
                              </Typography>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </div>
          </>
        );
      } else {
        return null;
      }
    };

    const displayVideoInterviews = () => {
      const showVideoInterviews =
        hasVideoInterviews && (!shareLinkDetails || shareLinkDetails.app_interviews);

      if (showVideoInterviews) {
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                margin: '1.5rem 0'
              }}
              ref={this.ref3}
            >
              <Box mb={3} display="flex" justifyContent="space-between">
                <Typography className={classes.heading}>
                  <FormattedMessage
                    id="CVDetail.title_appInterviews"
                    defaultMessage="In-App Interviews"
                  />
                </Typography>
                <Box display="flex" alignItems="flex-end">
                  {this.props.hasVideoInterviewStage &&
                  videoInterviewStage &&
                  !this.state.readonly &&
                  !this.state.print ? (
                    <ButtonNewVideoInterview
                      variant="detail"
                      job_id={job.id}
                      thisStage={videoInterviewStage.id}
                      selected_ids={[profile.id]}
                      applicants={[profile]}
                      handlerUpdate={this.refreshVideoInterviews}
                    />
                  ) : null}
                </Box>
              </Box>
              <Box
                px={2.5}
                display="flex"
                gridColumnGap="36px"
                overflow="auto"
                style={{
                  margin: '0 -24px'
                }}
              >
                {videoInterviews.map((vi, index) => {
                  const title = job.title;
                  const code = job.code;
                  const videoInterviewAllInfo = { ...vi, code, title };
                  return (
                    <ListVideoAnswers2
                      key={index}
                      videointerview={videoInterviewAllInfo}
                      readonly={this.state.readonly}
                      print={this.state.print}
                    />
                  );
                })}
              </Box>
            </div>
            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displayCustomTests = () => {
      const showCustomTests = hasCustomTests && (!shareLinkDetails || shareLinkDetails.tests);

      if (showCustomTests) {
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                margin: '1.5rem 0'
              }}
              ref={this.ref4}
            >
              <Box display="flex" justifyContent="space-between">
                <Typography className={classes.heading}>
                  <FormattedMessage id="CVDetail.title_customTests" defaultMessage="Online Tests" />
                </Typography>
                {!this.state.readonly && !this.state.print ? (
                  <Box display="flex" alignItems="flex-end">
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={() => this.handleOpenFormModal('TEST')}
                      disabled={sendFormDisabled}
                    >
                      <FormattedMessage
                        id="CVDetail.button_newCustomTest"
                        defaultMessage="New Test"
                      />
                    </Button>
                  </Box>
                ) : null}
              </Box>
              {/* {customTests && customTests.length > 0 ? (
                <Box px={2.5}>
                  <ListCustomTest customTests={customTests} />
                </Box>
              ) : (
                <Skeleton variant="rect" width={210} height={118} />
              )} */}
              {customTests && customTests.length > 0 ? (
                <Box
                  overflow="auto"
                  style={{
                    margin: '0 -24px'
                  }}
                >
                  <ListCustomTests
                    tests={customTests}
                    accountUsers={this.state.accountUsers}
                    pipeline_stages={this.state.pipeline}
                    applicant={profile}
                    job={job}
                    readonly={this.state.readonly}
                    print={this.state.print}
                  />
                </Box>
              ) : null}
            </div>
            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displayForms = () => {
      const showForms = hasForms && (!shareLinkDetails || shareLinkDetails.forms);

      if (showForms) {
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                margin: '1.5rem 0',
                scrollMargin: '15.125rem'
              }}
              ref={this.ref5}
            >
              <Box mb={3} display="flex" justifyContent="space-between">
                <Typography className={classes.heading}>
                  <FormattedMessage id="CVDetail.title_forms" defaultMessage="Custom Forms" />
                </Typography>
                <Box display="flex" alignItems="flex-end">
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={() => this.handleOpenFormModal('FORM')}
                    disabled={sendFormDisabled}
                  >
                    <FormattedMessage id="CVDetail.button_newForm" defaultMessage="New Form" />
                  </Button>
                  {/* {profile.person && profile.person.otherCustomFormsCount > 0 ? (
                    <Box>
                      <ButtonHistory count={profile.person.otherCustomFormsCount} />{' '}
                    </Box>
                  ) : null} */}
                </Box>
              </Box>
              <Box px={2.5}>
                {customForms && customForms.length > 0
                  ? customForms.map((currForm, i) => {
                      return (
                        <>
                          <CardCustomForm
                            test={currForm}
                            isLastForm={i === customForms.length - 1}
                            readonly={this.state.readonly}
                            print={this.state.print}
                          />
                          {i !== customForms.length - 1 &&
                          (this.state.readonly || this.state.print) ? (
                            <Divider
                              light
                              style={{
                                marginBottom: '16px'
                              }}
                            />
                          ) : null}
                        </>
                      );
                    })
                  : null}
              </Box>
            </div>
            {this.state.readonly || this.state.print ? <Divider /> : null}
          </>
        );
      } else {
        return null;
      }
    };

    const displayIDDocument = (info) => {
      let docName = '';
      let containerId = 'displayApplicantID';
      const identityDocs = this.props.identityDocs.identityDocs;

      if (identityDocs && identityDocs.length > 0) {
        const applicantDoc = identityDocs.findIndex((doc) => doc.code === info.doc_type);
        if (applicantDoc > -1) {
          docName = identityDocs[applicantDoc].name;
          const docMask = identityDocs[applicantDoc].id_mask;
          AddressController.getMaskedID(containerId, docMask);
        } else {
          docName = this.props.intl.formatMessage({ id: 'IconID.defaultIDLabel' });
        }
      }

      if (info.doc_number) {
        if (shareLinkDetails) {
          if (shareLinkDetails.privacy) {
            return (
              <Box mb={3}>
                <Box mb={1}>
                  <Typography className={classes.subHeading}>{docName}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography id={containerId} className={classes.cvElement}>
                    {Utils.hideInfo(info.doc_number)}
                  </Typography>
                </Box>
              </Box>
            );
          }
        }
        return (
          <Box mb={3}>
            <Box mb={1}>
              <Typography className={classes.subHeading}>{docName}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography id={containerId} className={classes.cvElement}>
                {info.doc_number}
              </Typography>
            </Box>
          </Box>
        );
      } else {
        return null;
      }
    };

    const displayAge = (age) => {
      return (
        <Box mb={3}>
          <Box mb={1}>
            <Typography className={classes.subHeading}>
              <FormattedMessage id="CVProfileInfo.label_age" />
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography className={classes.cvElement}>
              {`${
                shareLinkDetails && shareLinkDetails.privacy
                  ? Utils.hideAllInfo(
                      age +
                        this.props.intl.formatMessage({
                          id: 'InfoAge.years'
                        })
                    )
                  : age +
                    this.props.intl.formatMessage({
                      id: 'InfoAge.years'
                    })
              }`}
            </Typography>
            {profile.date_birth ? (
              <>
                <span
                  className={classes.cvElement}
                  style={{
                    margin: '0 4px',
                    fontWeight: 800
                  }}
                >
                  &bull;
                </span>
                <Typography className={classes.cvElement}>
                  <LocaleFormatter dateToFormat={Moment(profile.date_birth).format('ll')} />
                </Typography>
              </>
            ) : null}
          </Box>
        </Box>
      );
    };

    const displayInterviews = () => {
      const showInterviews = hasInterviews && (!shareLinkDetails || shareLinkDetails.interviews);

      if (showInterviews) {
        return (
          <>
            <div
              className={classes.elementWithScrollMargin}
              style={{
                margin: '1.5rem 0'
              }}
              ref={this.ref6}
            >
              <Box display="flex" justifyContent="space-between">
                <Typography className={classes.heading}>
                  <FormattedMessage id="CVDetail.title_interviews" defaultMessage="Schedules" />
                </Typography>
                {!this.state.readonly && !this.state.print ? (
                  <Box display="flex" alignItems="flex-end">
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={this.handleOpenEventModal}
                    >
                      <FormattedMessage
                        id="CVDetail.button_newInterview"
                        defaultMessage="New Schedule"
                      />
                    </Button>
                  </Box>
                ) : null}
              </Box>
              <Box
                overflow="auto"
                style={{
                  margin: '0 -24px'
                }}
              >
                {/*  {interviews.map((interview, i) => {
                  return (
                    <Box minWidth="384px">
                      <CardInterview
                        key={i}
                        interview={interview}
                        accountUsers={this.state.accountUsers}
                      />
                    </Box>
                  );
                })} */}
                <ListInterview
                  interviews={interviews}
                  accountUsers={this.state.accountUsers}
                  pipeline_stages={this.state.pipeline}
                  applicant={profile}
                  job={job}
                  readonly={this.state.readonly}
                  print={this.state.print}
                />
              </Box>
            </div>
            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    const displayPersonality = () => {
      let displayPersonality = false;

      if (shareLinkDetails && shareLinkDetails.personality) {
        displayPersonality = true;
      }

      if (displayPersonality) {
        return (
          <>
            <div
              style={{
                margin: '1.5rem 0'
              }}
            >
              <Box mb={3}>
                <Typography className={classes.heading}>
                  <FormattedMessage id="ModalDisplayContent.menu_personalityReport" />
                </Typography>
              </Box>
              <Box>
                {/*  {interviews.map((interview, i) => {
                  return (
                    <Box minWidth="384px">
                      <CardInterview
                        key={i}
                        interview={interview}
                        accountUsers={this.state.accountUsers}
                      />
                    </Box>
                  );
                })} */}
                <PersonalityReport applicant={profile} variant="cv" />
              </Box>
            </div>
            <Divider />
          </>
        );
      } else {
        return null;
      }
    };

    if (profile) {
      let profileSkills =
        this.state.skills && this.state.skills.length > 0
          ? this.state.skills.join(' • ')
          : undefined;
      const expandSkills = this.state.expandSkills;

      let hasMoreThan270Characters = profileSkills && profileSkills.length >= 270 ? true : false;

      if (
        profileSkills &&
        profileSkills.length >= 270 &&
        expandSkills === false &&
        !this.state.readonly &&
        !this.state.print
      ) {
        profileSkills = profileSkills.slice(0, 269) + '...';
      }

      const jobsCount = profile.person ? profile.person.jobsCount : 0;

      let cvOffset = 125;

      let cardHeight = 0;
      let blockHeight = 0;

      if (this.cardRef.current) {
        cardHeight = this.cardRef.current.clientHeight;
      }

      if (this.blockRef.current) {
        // Sum with its margin bottom
        blockHeight = this.blockRef.current.clientHeight + 8;
      }

      return (
        <>
          <Box width="100%">
            <Box className={classes.topContainer}>
              <div className={classes.cardContainer} ref={this.cardRef}>
                {this.state._loadingBaseInfo ? (
                  <CardSkeleton />
                ) : (
                  <CardDetail
                    readonly={this.state.readonly}
                    print={this.state.print}
                    applicant={profile}
                    location={location}
                    jobsCount={jobsCount}
                    title={title}
                    activity_update_cv={activity_update_cv}
                    disabled={false}
                    active={true}
                    loading={this.state._loadingBaseInfo}
                    handleUpdateSalaryInfo={this.handleUpdateSalaryInfo}
                    handleUpdateVideoInterviews={this.refreshVideoInterviews}
                    handleUpdateInterviews={this.refreshInterviews}
                    handleUpdateTests={this.refreshTests}
                    shareLinkDetails={shareLinkDetails}
                    handleSetForRefresh={this.handleSetForRefresh}
                  />
                )}
                {/* {profile.pastBlockLists > 0 || profile.activeBlocklists > 0 ? null : <Divider />} */}
              </div>
              {profile.pastBlockLists > 0 || profile.activeBlocklists > 0 ? (
                <div
                  style={{
                    marginBottom: '0.5rem'
                  }}
                  ref={this.blockRef}
                >
                  <AlertApplicantBlocked
                    baseInfo={{ email: profile.email, docNumber: profile.doc_number }}
                    emailBlocklists={profile.emailBlocklists}
                    docBlocklists={profile.docBlocklists}
                    blockLists={profile.blockLists}
                    pastBlock={profile.pastBlockLists > 0}
                    readonly={this.state.readonly}
                    hideMotive={shareLinkDetails ? shareLinkDetails.hide_block_motive : false}
                  />
                </div>
              ) : null}

              {this.state.readonly || this.state.print ? null : (
                <Box className={classes.menuContainer}>
                  <CVMenu profile={profile} handleChangeSection={this.handleChangeSection} />
                </Box>
              )}
            </Box>

            <Box
              className={classes.mainContainerDetail}
              style={{
                overflowY: this.state.readonly ? 'hidden' : 'scroll',
                height: this.state.readonly
                  ? '100%'
                  : `calc(100vh - ${cvOffset}px  - ${cardHeight}px - ${blockHeight}px)`
              }}
            >
              <div className={classes.elementWithScrollMargin} ref={this.ref1}>
                {!shareLinkDetails || shareLinkDetails.about ? (
                  <Box mb={1} display="flex" alignItems="center">
                    <Typography
                      className={`${classes.summaryTitle} ${
                        applicantOrigin === 'HUNTING' || applicantOrigin === 'SCRAP'
                          ? classes.normalSummary
                          : classes.aiSummary
                      }`}
                    >
                      <FormattedMessage id="DetailSummary.about" />
                    </Typography>
                    {applicantOrigin === 'HUNTING' ? null : (
                      <Box ml={0.5}>
                        <AISuggestionsIcon />
                      </Box>
                    )}
                  </Box>
                ) : null}
                <Box display="flex" flexDirection="column" ml={2.5}>
                  {this.state.summary && (!shareLinkDetails || shareLinkDetails.about) ? (
                    <Box
                      mb={5}
                      className={`${
                        applicantOrigin === 'HUNTING' || applicantOrigin === 'SCRAP'
                          ? ''
                          : classes.aiSummaryContainer
                      }`}
                    >
                      <Typography
                        className={`${classes.summaryTitle} ${
                          applicantOrigin === 'HUNTING' || applicantOrigin === 'SCRAP'
                            ? classes.normalSummary
                            : classes.aiSummary
                        }`}
                        style={{
                          margin: 0
                        }}
                      >
                        {this.state.summary}
                      </Typography>
                    </Box>
                  ) : null}

                  <Collapse
                    in={!this.state.readonly && !this.state.print ? this.state.expandAbout : true}
                    timeout="auto"
                  >
                    {!shareLinkDetails || shareLinkDetails.contact ? (
                      <Box mb={3}>
                        <Box mb={1}>
                          <Typography className={classes.subHeading}>
                            <FormattedMessage id="CVProfileInfo.label_contacts" />
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                          {hasEmail ? (
                            <>
                              <Typography className={classes.cvElement}>
                                {displayEmail()}
                              </Typography>
                              <Popover
                                open={emailOpen}
                                anchorEl={this.state.emailAnchorEl}
                                onClose={this.handleCloseEmail}
                              >
                                <EmailActions email={this.state.currentEmail} />
                              </Popover>
                            </>
                          ) : null}

                          {this.state.currentPhone ? (
                            <>
                              {displayPhone()}

                              {/* <span
                                className={classes.cvElement}
                                style={{
                                  margin: '0 4px',
                                  fontWeight: 800
                                }}
                              >
                                &bull;
                              </span> */}
                              <Popover
                                open={phoneOpen}
                                anchorEl={this.state.phoneAnchorEl}
                                onClose={this.handleClosePhone}
                              >
                                <PhoneActions phone={this.state.currentPhone} />
                              </Popover>
                            </>
                          ) : null}
                          {displayLinkedin()}
                          {displayGithub()}
                          {displayBehance()}
                        </Box>
                      </Box>
                    ) : null}

                    {profile.age && (!shareLinkDetails || shareLinkDetails.age)
                      ? displayAge(profile.age)
                      : null}
                    {hasDocNumber ? displayIDDocument(this.state.profile) : null}
                    {address ? (
                      <>
                        <Box mb={3}>
                          <Box mb={1}>
                            <Typography className={classes.subHeading}>
                              <FormattedMessage id="CVProfileInfo.label_address" />
                            </Typography>
                          </Box>
                          {displayAddress()}
                        </Box>
                        <Popover
                          open={addressOpen}
                          anchorEl={this.state.addressAnchorEl}
                          onClose={this.handleCloseAddress}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                          transformOrigin={{ vertical: -20, horizontal: -20 }}
                        >
                          <Box minWidth="480px">
                            <CardApplicantMap
                              applicant_point={profile.address_point}
                              applicant_geocode={profile.address_geocode}
                              job_point={job && job.address_point ? job.address_point : null}
                              job_geocode={job && job.address_geocode ? job.address_geocode : null}
                            />
                          </Box>
                        </Popover>
                      </>
                    ) : null}

                    {formatttedOriginString ? (
                      <Box mb={3}>
                        <Box mb={1}>
                          <Typography className={classes.subHeading}>
                            <FormattedMessage id="CVProfileInfo.label_origin" />
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <ChipOrigin applicantInfo={profile} />
                          <Typography className={classes.cvElement}>
                            <span
                              className={classes.cvElement}
                              style={{
                                margin: '0 4px',
                                fontWeight: 800
                              }}
                            >
                              &bull;
                            </span>
                            {this.props.intl.formatMessage({
                              id: 'CardBase.msg_appliedIn'
                            })}{' '}
                            <LocaleFormatter
                              dateToFormat={Moment(profile.date_created).format('lll')}
                            />
                            {this.state.profile &&
                              this.state.firstJobInfo &&
                              Object.keys(this.state.firstJobInfo).length > 0 && (
                                <>
                                  {' '}
                                  {this.state.firstJobInfo.type === 'PUBLIC_POOL' ? (
                                    <FormattedHTMLMessage
                                      id="CVProfileInfo.public_pool_info"
                                      values={{
                                        careerPage:
                                          this.state.firstJobInfo.accountPage &&
                                          this.state.firstJobInfo.accountPage.path
                                            ? this.state.firstJobInfo.accountPage.path
                                            : ''
                                      }}
                                    />
                                  ) : (
                                    <FormattedHTMLMessage
                                      id="CVProfileInfo.others_jobs_info"
                                      values={{
                                        title: this.state.firstJobInfo.title
                                      }}
                                    />
                                  )}
                                </>
                              )}
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}
                    {displayPwd()}
                  </Collapse>
                </Box>
                {!this.state.readonly && !this.state.print ? (
                  <Box className={classes.moreInfoContainer}>
                    <Divider
                      style={{
                        width: '100%'
                      }}
                      light
                    />
                    <Box
                      ml={1}
                      mr={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box mr={1}>
                        <Button
                          className={classes.expandAboutButton}
                          size="small"
                          fullWidth
                          onClick={this.handleExpandAbout}
                          endIcon={
                            <ExpandMoreIcon
                              className={clsx(classes.expandFilters, {
                                [classes.expandFiltersOpen]: this.state.expandAbout
                              })}
                            />
                          }
                        >
                          <Typography
                            style={{
                              fontSize: '13px',
                              textWrap: 'noWrap'
                            }}
                          >
                            <FormattedMessage
                              id="CVDetail.button_personalData"
                              defaultMessage="
                            Show personal info"
                            />
                          </Typography>
                        </Button>
                      </Box>
                      {displayContactIcons()}
                    </Box>
                    <Divider
                      style={{
                        width: '100%'
                      }}
                      light
                    />
                  </Box>
                ) : null}
              </div>
              {profileSkills && (!shareLinkDetails || shareLinkDetails.skills) ? (
                <>
                  <Box mt={3}>
                    <Box mb={2}>
                      <Typography className={classes.heading}>
                        <FormattedMessage id="CVProfileInfo.label_skills" />
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        marginLeft: '20px',
                        marginBottom: '24px'
                      }}
                    >
                      <Typography className={classes.cvElement}>
                        {profileSkills}
                        {hasMoreThan270Characters && !this.state.readonly && !this.state.print ? (
                          <>
                            &nbsp;
                            <a
                              className={
                                expandSkills ? classes.inactiveSeeMore : classes.activeSeeMore
                              }
                              onClick={(e) => this.handleExpandSkills()}
                            >
                              {expandSkills ? (
                                <FormattedMessage id="CVProfileInfo.label_seeLess" />
                              ) : (
                                <FormattedMessage id="CVProfileInfo.label_seeMore" />
                              )}

                              {expandSkills ? (
                                <KeyboardArrowUpIcon fontSize="small" color="secondary" />
                              ) : (
                                <KeyboardArrowDownIcon fontSize="small" color="primary" />
                              )}
                            </a>
                          </>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                </>
              ) : null}

              {displayDiversity()}

              {displayInterviews()}

              {displayVideoInterviews()}

              {displayForms()}

              {displayCustomTests()}

              {displayPersonality()}

              {displayEducations()}

              {displayExperiences()}

              {displaySalaryInfo()}

              {displayCertifications()}

              {displayLanguages()}
            </Box>
          </Box>

          <ModalEvent
            applicant={profile}
            job={job}
            open={this.state.newEventModal}
            handleClose={this.handleCloseEventModal}
            handleUpdateInterviews={this.refreshInterviews}
          />

          <ModalForm
            open={this.state.formModal}
            job={job}
            handleClose={this.handleCloseFormModal}
            type={this.state.formType}
            applicant={profile}
            handleUpdateTests={this.refreshTests}
          />
        </>
      );
    } else {
      return null;
    }
  }
}

CVDetail.propTypes = {
  user: PropTypes.object,
  industries: PropTypes.object,
  linkedinIndustries: PropTypes.object,
  accountInfo: PropTypes.object,
  identityDocs: PropTypes.object
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  industries: state.industries,
  linkedinIndustries: state.linkedinIndustries,
  accountInfo: state.accountInfo,
  identityDocs: state.identityDocs
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(CVDetail)));
