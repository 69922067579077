import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';
import Moment from 'moment';
import UserProfile from '../../Common/UserProfile';
import StageController from '../../Pipeline/StageController';
import Api from '../../Api/Api';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Replay10Icon from '@material-ui/icons/Replay10';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Forward10Icon from '@material-ui/icons/Forward10';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Chip from '@material-ui/core/Chip';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import './CardVideoPlayer.css';
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined';
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';
import * as CONST from '../../Common/constants';

const useStyles = (theme) => ({
  cardRoot: {
    border: '1px solid ' + theme.palette.gray.main,
    marginBottom: theme.spacing(1),
    // width: 360
    width: 300
  },
  containerCardContent: {
    borderBottom: '1px solid ' + theme.palette.gray.main
  },
  chipRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    },
    overflow: 'auto',
    maxHeight: 150
  },
  listRoot: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 150
  },
  listItem: {
    marginTop: 0,
    marginBottom: 0
  },
  player: {
    // width: 360,
    width: 300,
    height: 'auto',
    borderRadius: 6,
    backgroundColor: theme.palette.gray.lighter,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  noplayer: {
    // width: 360,
    width: 300,
    height: 480
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  grayLightSubtitle: {
    color: theme.palette.gray.darkerLight,
    fontSize: '12px',
    fontWeight: '400'
  },
  cardBorder: {
    '.MuiPaper-rounded': {
      borderRadius: 0
    },
    '& .MuiPaper-rounded': {
      borderRadius: 0
    }
  }
});

class CardVideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video_question: props.video_question,
      video_answer: props.video_answer,
      video_url: props.video_url,
      thumbnail_url: props.thumbnail_url,
      // Internal state
      user_rating: 0,
      keywords: [],
      transcript: [],
      // Video controls
      ready: false,
      playing: false,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      playedSeconds: 0,
      duration: 0,
      playbackRate: 1,
      loop: false,
      active_seek: -1,
      playbackRateOpts: [1, 1.25, 1.5],
      expanded: false
    };
  }

  componentDidMount() {
    //this.getKeywords();
    //this.getTranscript();
    this.getUserRating();
  }

  getKeywords() {
    const video_answer = this.state.video_answer;

    let entitiesArr = [];
    if (video_answer && video_answer.engine_response) {
      if (
        video_answer.engine_response.summarizedInsights &&
        video_answer.engine_response.summarizedInsights.keywords.length > 0
      ) {
        video_answer.engine_response.summarizedInsights.keywords.forEach((keyword) => {
          let obj = {
            entity: keyword.name,
            appearances: keyword.appearances
          };
          entitiesArr.push(obj);
        });
      }
      if (
        video_answer.engine_response.summarizedInsights &&
        video_answer.engine_response.summarizedInsights.brands.length > 0
      ) {
        video_answer.engine_response.summarizedInsights.brands.forEach((brand) => {
          let obj = {
            entity: brand.name,
            appearances: brand.appearances
          };
          entitiesArr.push(obj);
        });
      }
    }
    this.setState({
      keywords: entitiesArr
    });
  }

  getTranscript() {
    const video_answer = this.state.video_answer;

    let transcript = [];
    if (video_answer && video_answer.engine_response) {
      if (
        video_answer.engine_response.videos &&
        video_answer.engine_response.videos.length > 0 &&
        video_answer.engine_response.videos[0].insights.transcript &&
        video_answer.engine_response.videos[0].insights.transcript.length > 0
      ) {
        video_answer.engine_response.videos[0].insights.transcript.forEach((phrase) => {
          transcript.push({
            order: phrase.id,
            text: phrase.text,
            start: phrase.instances[0].start,
            end: phrase.instances[0].end
          });
        });
      }

      this.setState({
        transcript: transcript
      });
    }
  }

  getUserRating() {
    const video_answer = this.state.video_answer;

    let user_rating = 0;
    if (video_answer && video_answer.ratings) {
      if (video_answer.ratings.length > 0) {
        video_answer.ratings.forEach((rating) => {
          if (rating.user_id == UserProfile.getId()) {
            user_rating = rating.rating;
          }
        });
      }
    }
    this.setState({
      user_rating: user_rating
    });
  }

  handleError = (e) => {
    alert(e);
  };

  handleStart = () => {
    const video = this.state.video_answer;

    let api = new Api();
    let payload = {
      account_id: UserProfile.getRealm(),
      videointerview_id: video.videointerview_id,
      videoanswer_id: video.id,
      user_id: UserProfile.getId(),
      action: 'PLAY'
    };
    api.post('VideoInterviewViews', payload, (status, data) => {
      //console.log('video play');
    });
  };

  handleReady = () => {
    //console.log("video ready")
    this.setState({
      ready: true
    });
  };

  handlePlayPause = () => {
    //console.log("play / pause");
    this.setState({ playing: !this.state.playing });
  };

  handleEnded = () => {
    const video = this.state.video_answer;
    let api = new Api();
    let payload = {
      account_id: UserProfile.getRealm(),
      videointerview_id: video.videointerview_id,
      videoanswer_id: video.id,
      user_id: UserProfile.getId(),
      action: 'END'
    };
    api.post('VideoInterviewViews', payload, (status, data) => {
      //console.log('video ended');
    });
  };

  handleProgress = (state) => {
    //console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleDuration = (duration) => {
    //console.log('onDuration', duration)
    this.setState({ duration });
  };

  handleSetPlaybackRate = () => {
    const current = this.state.playbackRate;
    let pos = this.state.playbackRateOpts.indexOf(current);
    if (pos === 2) {
      pos = -1;
    }
    let next = this.state.playbackRateOpts[pos + 1];

    //console.log(next);
    this.setState({ playbackRate: parseFloat(next) });
  };

  seekTo = (sec, index) => {
    this.player.seekTo(sec, 'seconds');
    if (index !== null || index !== undefined) {
      this.setState({
        active_seek: index
      });
    }
  };

  handlePrev10 = () => {
    let sec = this.state.playedSeconds - 10;
    if (sec < 0) {
      sec = 0;
    }
    this.player.seekTo(sec, 'seconds');
  };

  handleNext10 = () => {
    let sec = this.state.playedSeconds + 10;
    if (sec > this.state.duration) {
      sec = this.state.duration;
    }
    this.player.seekTo(sec, 'seconds');
  };

  changeRating = (event, newRating) => {
    if (newRating) {
      this.setState(
        {
          user_rating: newRating
        },
        () => {
          const video = this.state.video_answer;

          StageController.rateVideoAnswer(
            video.id,
            video.videointerview_id,
            video.job_id,
            video.applicant_id,
            newRating,
            (err, user_avg, avg_rating) => {
              if (err) {
                //console.log(err);
                window.swal({
                  title: 'Error',
                  text: err,
                  closeOnConfirm: true
                });
              } else {
                if (this.props.handlerUpdate && video.applicant_id) {
                  this.props.handlerUpdate(
                    {
                      id: video.applicant_id
                    },
                    {
                      user_rating: user_avg,
                      avg_rating: avg_rating
                    }
                  );
                }
              }
            }
          );
        }
      );
    }
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const { classes } = this.props;

    const video_question = this.state.video_question;
    const video_answer = this.state.video_answer;
    //console.log(video_answer);

    const listKeywords = this.state.keywords.map((entity, i) => {
      let color = 'secondary';
      let variant = 'outlined';
      let count = 1;
      if (entity.appearances && entity.appearances.length > 1) {
        count = entity.appearances.length;
      }
      if (this.state.active_seek === i) {
        color = 'primary';
        variant = 'default';
      }
      return (
        <Chip
          key={i}
          variant={variant}
          color={color}
          size="small"
          onClick={() => this.seekTo(entity.appearances[0].startSeconds, i)}
          label={
            <span>
              {entity.entity} <b>{count}</b>
            </span>
          }
          deleteIcon={<ArrowDropDownIcon />}
          //onDelete={() => alert('OK')}
        />
      );
    });

    const listTranscript = this.state.transcript.map((phrase, i) => {
      // if (this.state.active_seek === i) {
      // }
      return (
        <>
          <ListItem
            key={i}
            className={classes.listItem}
            //onClick={() => this.seekTo(phrase.start, i)}
          >
            <ListItemText
              className={classes.listItem}
              primary={<small>{phrase.text}</small>}
              secondary={
                <small>
                  {phrase.start}-{phrase.end}
                </small>
              }
            />
          </ListItem>
          <Divider variant="middle" />
        </>
      );
    });

    const displayTime = () => {
      if (this.state.video_answer) {
        return (
          <FormattedMessage
            id="CardVideoPlayer.dateAnswer"
            defaultMessage="Answered {date}"
            values={{
              date: Moment(video_answer.date_created).format('lll')
            }}
          />
        );
      } else {
        return <FormattedMessage id="CardVideoPlayer.noAnswer" defaultMessage="No answer" />;
      }
    };

    const displayInsights = () => {
      if (
        this.state.video_answer &&
        (this.state.keywords.length === 0 || this.state.transcript.length === 0)
      ) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            p={2}
            css={{ width: '100%', height: 150 }}
          >
            <Box className="text-center">
              <BlurOffIcon color="secondary" style={{ fontSize: 40 }} />
              <Typography component="h6" variant="h6" color="textSecondary">
                <FormattedMessage
                  id="CardVideoPlayer.noInsightsMsg"
                  defaultMessage="No insights available for this video"
                />
              </Typography>
            </Box>
          </Box>
        );
      } else {
        return (
          <Box display="flex" alignContent="center" css={{ width: '100%', height: 150 }}>
            <Box
              width="40%"
              p={1}
              className="text-center text-sm"
              style={{ borderRight: `1px solid ${CONST.APP_COLORS['black']}14` }}
            >
              <div className={classes.chipRoot}>{listKeywords}</div>
            </Box>
            <Box width="60%" p={1} className="text-center text-sm">
              <div>
                <List dense={true} className={classes.listRoot}>
                  {listTranscript}
                </List>
              </div>
            </Box>
          </Box>
        );
      }
    };

    const displayPlayer = () => {
      // console.log(this.state.video_answer)
      if (this.state.video_question.interviewType === 'VIDEO') {
        if (this.state.video_answer) {
          return (
            <div className="player-img">
              <ReactPlayer
                ref={this.ref}
                url={this.state.video_url}
                playing={this.state.playing}
                controls={false}
                playbackRate={this.state.playbackRate}
                // width="360px"
                width="300px"
                height="auto"
                className={classes.player}
                onReady={this.handleReady}
                onStart={this.handleStart}
                onEnded={this.handleEnded}
                onError={this.handleError}
                onProgress={this.handleProgress}
                onDuration={this.handleDuration}
                config={{
                  file: {
                    attributes: {
                      autoplay: false,
                      poster: this.state.thumbnail_url,
                      controlsList: 'nodownload'
                    }
                  }
                }}
              />
            </div>
          );
        } else {
          return (
            <div>
              <CardMedia component="div" className={classes.player} style={{ height: '404px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.98 17.14L15.98 13.14L7.67999 4.86L4.85999 7.68L10.32 13.14H8.85999C7.75999 13.14 6.85999 14.04 6.85999 15.14V35.14C6.85999 36.24 7.75999 37.14 8.85999 37.14H32.86C33.28 37.14 33.64 36.98 33.96 36.78L40.32 43.14L43.14 40.32L25.42 22.6L19.98 17.14ZM10.86 33.14V17.14H14.32L30.32 33.14H10.86ZM30.86 22.36V17.14H25.64L21.64 13.14H32.86C33.96 13.14 34.86 14.04 34.86 15.14V22.14L42.86 14.14V34.36L30.86 22.36Z"
                    fill="black"
                    fillOpacity="0.54"
                  />
                </svg>
              </CardMedia>
            </div>
          );
        }
      } else {
        if (this.state.video_answer) {
          return (
            <div className="player-img">
              <ReactPlayer
                ref={this.ref}
                url={this.state.video_url}
                playing={this.state.playing}
                controls={false}
                playbackRate={this.state.playbackRate}
                // width="360px"
                width="300px"
                height="auto"
                className={classes.player}
                onReady={this.handleReady}
                onStart={this.handleStart}
                onEnded={this.handleEnded}
                onError={this.handleError}
                onProgress={this.handleProgress}
                onDuration={this.handleDuration}
                config={{
                  file: {
                    attributes: {
                      autoplay: false,
                      poster: this.state.thumbnail_url,
                      controlsList: 'nodownload'
                    }
                  }
                }}
              />
            </div>
          );
        } else {
          return (
            <div>
              <CardMedia component="div" className={classes.player}>
                <MicOffOutlinedIcon
                  style={{
                    fontSize: '48px',
                    padding: '10px',
                    color: `${CONST.APP_COLORS['black']}8A`
                  }}
                />
              </CardMedia>
            </div>
          );
        }
      }
    };

    const displayControls = () => {
      const hasVideoAnswer = !!this.state.video_answer;

      return (
        <>
          <div>
            <Box
              display="flex"
              alignItems="center"
              style={{ justifyContent: hasVideoAnswer ? 'none' : 'flex-end' }}
            >
              {hasVideoAnswer && (
                <Box width="100%" mr={1}>
                  <LinearProgress
                    variant="buffer"
                    color="secondary"
                    value={this.state.played * 100}
                    valueBuffer={this.state.loaded * 100}
                  />
                </Box>
              )}
              <Box minWidth={100}>
                <Typography variant="caption" color="textSecondary">
                  {Moment.utc(this.state.playedSeconds * 1000).format('mm:ss')} /{' '}
                  {Moment.utc(this.state.duration * 1000).format('mm:ss')}
                </Typography>
              </Box>
            </Box>
          </div>
          <div className={classes.controls}>
            <Tooltip
              title={
                <FormattedMessage
                  id="CardVideoPlayer.player_back10s"
                  defaultMessage="Back 10 seconds"
                />
              }
              arrow
            >
              <IconButton
                aria-label="previous10s"
                disabled={!hasVideoAnswer || !this.state.ready}
                onClick={() => this.handlePrev10()}
                size="small"
              >
                <Replay10Icon />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={
                <FormattedMessage
                  id="CardVideoPlayer.player_playPause"
                  defaultMessage="Play / Pause"
                />
              }
              arrow
            >
              <IconButton
                aria-label="play/pause"
                disabled={!hasVideoAnswer || !this.state.ready}
                onClick={() => this.handlePlayPause()}
                size="small"
              >
                {this.state.playing ? (
                  <PauseIcon className={classes.playIcon} />
                ) : (
                  <PlayArrowIcon className={classes.playIcon} />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip
              title={
                <FormattedMessage
                  id="CardVideoPlayer.player_forward10s"
                  defaultMessage="Forward 10 seconds"
                />
              }
              arrow
            >
              <IconButton
                aria-label="next10s"
                disabled={!hasVideoAnswer || !this.state.ready}
                onClick={() => this.handleNext10()}
                size="small"
              >
                <Forward10Icon />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={
                <FormattedMessage
                  id="CardVideoPlayer.player_speed"
                  defaultMessage="Playback speed"
                />
              }
              arrow
            >
              <Button
                color="inherit"
                disabled={!hasVideoAnswer || !this.state.ready}
                onClick={() => this.handleSetPlaybackRate()}
                size="small"
              >
                {this.state.playbackRate}x
              </Button>
            </Tooltip>

            <Tooltip
              title={
                <FormattedMessage
                  id="CardVideoPlayer.player_userRating"
                  defaultMessage="Rate this answer"
                />
              }
              arrow
            >
              <Rating
                name={video_question.id}
                value={this.state.user_rating}
                precision={0.5}
                size="small"
                disabled={!hasVideoAnswer}
                className="mt-sm"
                onChange={this.changeRating}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
            </Tooltip>
          </div>
        </>
      );
    };

    return (
      <Card
        classes={{
          root: classes.cardRoot
        }}
      >
        <div className="player-container">
          {displayPlayer()}
          <Box className={classes.containerCardContent}>
            <CardContent
              style={{
                height: '10rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
              className={classes.cardBorder}
            >
              <Typography variant="body2" color="textSecondary" component="p">
                {video_question.priority + '. ' + video_question.question}
              </Typography>
              {this.state.video_answer && this.state.video_answer.date_created ? (
                <Typography variant="body2" color="textSecondary" component="footer">
                  {this.props.intl.formatMessage({
                    id: 'DetailVideoInterview.dateAnswers'
                  })}
                  <span
                    span
                    style={{
                      fontWeight: 700,
                      margin: '0 4px'
                    }}
                  >
                    &bull;
                  </span>
                  <span className={classes.grayLightSubtitle}>
                    {Moment(this.state.video_answer.date_created).format('lll')}
                  </span>
                </Typography>
              ) : (
                <Typography variant="body2" color="textSecondary" component="footer">
                  {this.props.intl.formatMessage({
                    id: 'DetailVideoInterview.NotAnswer'
                  })}
                  <span
                    span
                    style={{
                      fontWeight: 700,
                      margin: '0 4px'
                    }}
                  >
                    &bull;
                  </span>
                  <span className={classes.grayLightSubtitle}>
                    {this.state.video_question.date_created
                      ? Moment(this.state.video_question.date_created).format('ll')
                      : null}
                  </span>
                </Typography>
              )}
            </CardContent>
          </Box>
        </div>

        <CardContent>
          <div>{displayControls()}</div>
        </CardContent>
      </Card>
    );
  }
}

export default injectIntl(withStyles(useStyles)(CardVideoPlayer));
