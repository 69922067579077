// core
import React from 'react';

// libs external
import Chip from '@material-ui/core/Chip';
import Avatar from 'react-avatar';
import IconSync from './IconSync';

class RenderUserTags extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { tagValue, getTagProps, showUserCalendarSync } = this.props;

    const url = 'https://s3-sa-east-1.amazonaws.com/recrutai-dev/';

    return (
      <>
        {tagValue.map((option, index) => (
          <Chip
            variant="outlined"
            avatar={
              <div style={{ position: 'relative', top: 2, left: 1 }}>
                <Avatar
                  name={option.username}
                  src={url + option.avatar_50_path}
                  size="20px"
                  round={true}
                  // className="mr-2"
                />
                <IconSync user={option} showUserCalendarSync={showUserCalendarSync} />
              </div>
            }
            label={option.username}
            {...getTagProps({ index })}
          />
        ))}
      </>
    );
  }
}
export default RenderUserTags;
