import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import * as CONST from '../../Common/constants';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Moment from 'moment';
import LocaleFormatter from '../../Common/LocaleFormatter';
import Api from '../../Api/Api';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

const messages = defineMessages({
  infoTitle: {
    id: 'InfoEmployeeDetail.infoTitle',
    defaultMessage: 'Employee information'
  },
  verifiedEmployee: {
    id: 'InfoEmployeeDetail.verifiedEmployee',
    defaultMessage: 'Verified Employee'
  },
  notVerifiedEmployee: {
    id: 'InfoEmployeeDetail.notVerifiedEmployee',
    defaultMessage: 'Self declared employee'
  },
  employeeId: {
    id: 'InfoEmployeeDetail.employeeId',
    defaultMessage: 'Employee ID:'
  },
  email: {
    id: 'InfoEmployeeDetail.email',
    defaultMessage: 'E-mail:'
  }
});

const useStyles = (theme) => ({
  infoIcon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.gray.darkerMedium,
    width: '18px',
    height: '18px'
  },
  styleText: {
    color: theme.palette.gray.darkerMedium
  },
  styleTextSubTitle: {
    color: theme.palette.gray.darker,
    fontSize: '14px',
    fontWeight: 500
  },
  styleSubText: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '12px',
    fontWeight: 400
  },
  generalChip: {
    background: theme.palette.gray.lighter,
    color: theme.palette.gray.darker,
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: theme.spacing(1)
  }
});

class InfoEmployeeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Props
      employee_id: props.employee_id,
      employee_email: props.employee_email,
      internal_verified: props.internal_verified
    };
  }

  createMainInfoEmployee = () => {
    const { classes } = this.props;
    const { employee_email, employee_id, internal_verified } = this.state;

    return (
      <>
        <Box>
          <Typography className={classes.styleSubText} style={{ fontWeight: '500' }}>
            {internal_verified ? (
              <FormattedMessage id="InfoEmployeeDetail.verifiedEmployee" />
            ) : (
              <FormattedMessage id="InfoEmployeeDetail.notVerifiedEmployee" />
            )}
          </Typography>
        </Box>

        <Box>
          <Box display="flex" alignItems="center">
            <Box display="flex">
              <Box>
                <Box mt={2}>
                  <Typography className={classes.styleSubText}>
                    <FormattedMessage id="InfoEmployeeDetail.email" />
                  </Typography>
                </Box>
                <Box mt={0.5}>
                  <Typography className={classes.styleSubText}>{employee_email}</Typography>
                </Box>
                <>
                  {employee_id && (
                    <>
                      <Box mt={2}>
                        <Typography className={classes.styleSubText}>
                          <FormattedMessage id="InfoEmployeeDetail.employeeId" />
                        </Typography>
                      </Box>

                      <Box mt={0.5}>
                        <Typography className={classes.styleSubText}>{employee_id}</Typography>
                      </Box>
                    </>
                  )}
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Box
        style={{
          width: '363px',
          maxHeight: '230px',
          borderRadius: '10px',
          boxShadow: `0px 4px 15px 0px ${CONST.APP_COLORS['black']}33`
        }}
      >
        <Box
          style={{
            position: 'sticky',
            top: '0px',
            zIndex: '999',
            background: 'white'
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px'
            }}
          >
            <Box width="275px" display="flex" justifyContent="center">
              <Typography
                className={classes.styleSubText}
                style={{ display: 'flex', alignItems: 'center', fontWeight: '400' }}
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
                <FormattedMessage id="InfoEmployeeDetail.infoTitle" />
              </Typography>
            </Box>
          </Box>
          <Divider style={{ margin: '0 1rem' }} />
        </Box>
        <Box
          display="flex"
          style={{
            padding: '16px 24px'
          }}
        >
          <Box>
            <Box>{this.createMainInfoEmployee()}</Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default injectIntl(withStyles(useStyles)(InfoEmployeeDetail));
