import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import 'moment/locale/pt-br'; // FIXME locale
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = (theme) => ({
  mainContainer: {
    background: theme.palette.common.white,
    padding: '2rem 1.5rem',
    borderRadius: '1rem',
    overflowX: 'hidden'
  },
  optionList: {
    //height: 200,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  heading: {
    fontSize: '1.25rem',
    fontWeight: 'bold'
  }
});

class CVProfileSkeleton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.mainContainer}>
        <Box width="100%" display="flex" alignItems="center" mb={1}>
          <Skeleton
            animation="wave"
            variant="circle"
            width={50}
            height={50}
            style={{ marginRight: '0.5rem' }}
          />
          <Skeleton animation="wave" width={280} height={12} />
        </Box>

        <Divider light />
        <Box mt={2}>
          <Box>
            <Skeleton
              animation="wave"
              width={90}
              height={12}
              style={{
                marginBottom: '0.5rem'
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" ml={2.5} mt={3} minWidth={0}>
            <Box mb={2} minWidth={0}>
              <Skeleton
                animation="wave"
                width={614}
                height={12}
                style={{
                  marginBottom: '0.5rem'
                }}
              />
              <Skeleton
                animation="wave"
                height={12}
                style={{
                  marginBottom: '0.5rem',
                  maxWidth: '614px'
                }}
              />
              <Skeleton
                animation="wave"
                height={12}
                style={{
                  marginBottom: '0.5rem',
                  maxWidth: '614px'
                }}
              />
            </Box>

            <Box mb={2} minWidth={0}>
              <Box>
                <Skeleton
                  animation="wave"
                  width={90}
                  height={12}
                  style={{
                    marginBottom: '0.5rem'
                  }}
                />
              </Box>
              <Box>
                <Skeleton
                  animation="wave"
                  height={12}
                  style={{
                    marginBottom: '0.5rem',
                    maxWidth: '439px'
                  }}
                />
              </Box>
            </Box>

            <Box mb={2} minWidth={0}>
              <Box>
                <Skeleton
                  animation="wave"
                  width={90}
                  height={12}
                  style={{
                    marginBottom: '0.5rem'
                  }}
                />
              </Box>
              <Box>
                <Skeleton
                  animation="wave"
                  height={12}
                  style={{
                    marginBottom: '0.5rem',
                    maxWidth: '439px'
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider light />
        <Box mt={3}>
          <Box>
            <Skeleton animation="wave" width={90} height={12} />
          </Box>
          <Box mt={3} minWidth={0}>
            <Box ml={2.5}>
              <Box mb={2} minWidth={0}>
                <Box display="flex" flexDirection="column" mb={2}>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={337} height={12} />
                  </Box>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={283} height={12} />
                  </Box>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={283} height={12} />
                  </Box>
                </Box>

                <Divider light />
              </Box>

              <Box mb={2}>
                <Box display="flex" flexDirection="column" mb={2}>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={337} height={12} />
                  </Box>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={283} height={12} />
                  </Box>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={283} height={12} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider light />
        <Box mt={3}>
          <Box>
            <Skeleton animation="wave" width={90} height={12} />
          </Box>
          <Box mt={3}>
            <Box ml={2.5}>
              <Box mb={2}>
                <Box display="flex" flexDirection="column" mb={2}>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={337} height={12} />
                  </Box>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={283} height={12} />
                  </Box>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={283} height={12} />
                  </Box>
                </Box>

                <Divider light />
              </Box>

              <Box mb={2}>
                <Box display="flex" flexDirection="column" mb={2}>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={337} height={12} />
                  </Box>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={283} height={12} />
                  </Box>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={283} height={12} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider light />

        <Box mt={3}>
          <Box ml={2.5}>
            <Box mb={2}>
              <Box display="flex" flexDirection="column" mb={2}>
                <Box mb={2}>
                  <Skeleton animation="wave" width={337} height={12} />
                </Box>
                <Box mb={2}>
                  <Skeleton animation="wave" width={283} height={12} />
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  <Skeleton
                    animation="wave"
                    width={97}
                    height={32}
                    style={{ marginRight: '0.5rem' }}
                  />
                  <Skeleton
                    animation="wave"
                    width={97}
                    height={32}
                    style={{ marginRight: '0.5rem' }}
                  />
                  <Skeleton
                    animation="wave"
                    width={97}
                    height={32}
                    style={{ marginRight: '0.5rem' }}
                  />
                </Box>
                <Box mb={2}>
                  <Skeleton animation="wave" width={283} height={12} />
                </Box>

                <Box display="flex" alignItems="center" mb={2}>
                  <Skeleton
                    animation="wave"
                    width={97}
                    height={32}
                    style={{ marginRight: '0.5rem' }}
                  />
                  <Skeleton animation="wave" width={97} height={32} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider light />
        <Box mt={3}>
          <Box>
            <Skeleton animation="wave" width={90} height={12} />
          </Box>
          <Box mt={3}>
            <Box ml={2.5}>
              <Box mb={2}>
                <Box display="flex" flexDirection="column" mb={2}>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={337} height={12} />
                  </Box>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={283} height={12} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider light />
        <Box mt={3}>
          <Box>
            <Skeleton animation="wave" width={90} height={12} />
          </Box>
          <Box mt={3}>
            <Box ml={2.5}>
              <Box mb={2}>
                <Box display="flex" flexDirection="column" mb={2}>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={283} height={12} />
                  </Box>
                  <Box mb={2}>
                    <Skeleton animation="wave" width={283} height={12} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider light />
      </Box>
    );
  }
}

export default withStyles(useStyles)(CVProfileSkeleton);
