import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import PortraitIcon from '@material-ui/icons/Portrait';
import UserProfile from '../../Common/UserProfile';

const messages = defineMessages({
  white: {
    id: 'InfoPwr.white',
    defaultMessage: 'White'
  },
  indigenous: {
    id: 'InfoPwr.indigenous',
    defaultMessage: 'Indigenous'
  },
  yellow: {
    id: 'InfoPwr.yellow',
    defaultMessage: 'Yellow'
  },
  black: {
    id: 'InfoPwr.black',
    defaultMessage: 'Black'
  },
  brown: {
    id: 'InfoPwr.brown',
    defaultMessage: 'Brown'
  },
  other: {
    id: 'InfoPwr.other',
    defaultMessage: 'Other'
  },
  opt_out: {
    id: 'InfoPwr.opt_out',
    defaultMessage: 'Opt Out'
  }
});

class InfoPwr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pwr: props.pwr,
      size: props.size ? props.size : 24
    };
  }

  render() {
    const pwd = this.state.pwr;
    // const pwd = 'indigenous';

    if ((UserProfile.isAdmin() || UserProfile.isDiversity()) && pwd && pwd !== 'no') {
      let text = '';
      switch (pwd) {
        case 'A':
          text = this.props.intl.formatMessage({
            id: 'InfoPwr.white'
          });
          break;
        case 'B':
          text = this.props.intl.formatMessage({
            id: 'InfoPwr.indigenous'
          });
          break;
        case 'C':
          text = this.props.intl.formatMessage({
            id: 'InfoPwr.yellow'
          });
          break;
        case 'D':
          text = this.props.intl.formatMessage({
            id: 'InfoPwr.black'
          });
          break;
        case 'E':
          text = this.props.intl.formatMessage({
            id: 'InfoPwr.brown'
          });
          break;
        case 'F':
          text = this.props.intl.formatMessage({
            id: 'InfoPwr.other'
          });
          break;
        case 'OPTOUT':
          text = this.props.intl.formatMessage({
            id: 'InfoPwr.opt_out'
          });
          break;
      }
      return (
        <Tooltip title={text} arrow>
          <span
            className="material-icons-two-tone"
            style={{
              fontSize: this.state.size
            }}
          >
            handshake
          </span>
        </Tooltip>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(InfoPwr);
