import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import * as CONST from '../../../Common/constants';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import UserProfile from '../../../Common/UserProfile';
import StageController from '../../../Pipeline/StageController';
import Typography from '@material-ui/core/Typography';

const baseFilters = CONST.getApplicantDetailBaseFields();

const messages = defineMessages({});

const useStyles = (theme) => ({
  label: {
    color: theme.palette.gray.darker,
    fontSize: '12px'
  }
});

const StyledRating = withStyles((theme) => ({
  iconFilled: {
    color: theme.palette.primary.main
  },
  iconHover: {
    color: theme.palette.primary.main
  }
}))(Rating);

class InterviewRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interview: props.interview,
      readonly: props.readonly,
      // Internal state

      avg_rating: 0,
      user_rating: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.interview !== this.props.interview) {
      this.setState({
        interview: this.props.interview
      });
    }
  }

  componentDidMount() {
    this.calcRatings();
  }

  calcRatings() {
    const interview = this.state.interview;

    let user_rating = 0;
    let avg_rating = 0;

    let arrAll = [];
    let arrUser = [];
    if (interview.ratings.length > 0) {
      interview.ratings.forEach((rating) => {
        if (parseInt(rating.user_id) === UserProfile.getId()) {
          //userRating = rating.rating;
          arrUser.push(parseFloat(rating.rating));
        }
        arrAll.push(parseFloat(rating.rating));
      });
      const sumAll = arrAll.reduce(function (a, b) {
        return a + b;
      }, 0);
      const sumUser = arrUser.reduce(function (a, b) {
        return a + b;
      }, 0);

      avg_rating = sumAll / arrAll.length;
      user_rating = sumUser / arrUser.length;
    }

    this.setState({
      avg_rating: avg_rating,
      user_rating: user_rating
    });
  }

  changeRating = (event, newRating) => {
    // console.log(newRating);
    if (newRating) {
      this.setState(
        {
          user_rating: newRating
        },
        () => {
          const interview = this.state.interview;

          StageController.rateInterview(
            interview.id,
            interview.job_id,
            interview.applicant_id,
            newRating,
            (err, newAvg) => {
              if (err) {
                //console.log(err);
                window.swal({
                  title: 'Error',
                  text: err,
                  closeOnConfirm: true
                });
              } else {
                this.setState(
                  {
                    avg_rating: newAvg
                  },
                  () => {
                    if (this.props.handlerUpdate && interview.applicant_id) {
                      this.props.handlerUpdate({
                        id: interview.applicant_id
                      });
                    }
                  }
                );
              }
            }
          );
        }
      );
    }
  };

  render() {
    const { classes } = this.props;

    const interview = this.state.interview;

    return (
      <Box display="flex" flexDirection="column" gridRowGap={16} textAlign="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={classes.label}>
            <FormattedMessage id="InterviewRating.label_average" defaultMessage="Avg Rating" />
          </Typography>
          <StyledRating
            value={this.state.avg_rating}
            precision={0.5}
            size="small"
            className="mt-sm"
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
            readOnly
          />
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={classes.label}>
            <FormattedMessage id="InterviewRating.label_myRating" defaultMessage="My Rating" />
          </Typography>
          <Rating
            name={'interview_user_rating_' + interview.id}
            value={this.state.user_rating}
            precision={0.5}
            size="small"
            className="mt-sm"
            onChange={this.changeRating}
            onRatingChange
            readOnly={this.state.readonly}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
          />
        </Box>
      </Box>
    );
  }
}

export default injectIntl(withStyles(useStyles)(InterviewRating));
