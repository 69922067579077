import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Col } from 'reactstrap';

const messags = defineMessages({
  years: {
    id: 'InfoAge.years',
    defaultMessage: 'years'
  }
});

class InfoAge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      age: props.age
    };
  }

  render() {
    let age;
    if (this.state.age) {
      age = this.state.age + ' ' + this.props.intl.formatMessage({ id: 'InfoAge.years' });
      return (
        <span
          style={{
            whiteSpace: 'nowrap'
          }}
        >
          <i className="fa fa-birthday-cake"></i> {age}
        </span>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(InfoAge);
