import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import * as CONST from '../../Common/constants';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Moment from 'moment';
import LocaleFormatter from '../../Common/LocaleFormatter';
import Api from '../../Api/Api';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import InfoOriginDetailSkeleton from './InfoOriginDetailSkeleton';

const messages = defineMessages({
  APPLY: {
    id: 'InfoOriginDetails.APPLY',
    defaultMessage: 'Apply:'
  },
  MAILIN: {
    id: 'InfoOriginDetails.MAILIN',
    defaultMessage: 'Apply by e-mail:'
  },
  REFERRAL: {
    id: 'InfoOriginDetails.REFERRAL',
    defaultMessage: 'Referred by:'
  },
  RECRUITER: {
    id: 'InfoOriginDetails.RECRUITER',
    defaultMessage: 'Applied by:'
  },
  IMPORT: {
    id: 'InfoOriginDetails.IMPORT',
    defaultMessage: 'Imported:'
  },
  HUNTING: {
    id: 'InfoOriginDetails.HUNTING',
    defaultMessage: 'Hunting done by:'
  },
  HuntingWithoutAvatar: {
    id: 'InfoOriginDetails.HuntingWithoutAvatar',
    defaultMessage: 'Hunting:'
  },
  COPY: {
    id: 'InfoOriginDetails.COPY',
    defaultMessage: 'Copied by:'
  },
  MOVE: {
    id: 'InfoOriginDetails.MOVE',
    defaultMessage: 'Moved by:'
  },
  CROSSRANK: {
    id: 'InfoOriginDetails.CROSSRANK',
    defaultMessage: 'AI Suggestion made by: '
  },
  UNKNOWN: {
    id: 'InfoOriginDetails.UNKNOWN',
    defaultMessage: 'Unknown'
  },
  SEARCH: {
    id: 'InfoOriginDetails.SEARCH',
    defaultMessage: 'Searched by:'
  },
  origin: {
    id: 'InfoOriginDetails.origin',
    defaultMessage: 'Origin / Channel:'
  },
  jobOrigin: {
    id: 'InfoOriginDetails.jobOrigin',
    defaultMessage: 'Original job application:'
  }
});

const useStyles = (theme) => ({
  infoIcon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.gray.darkerMedium,
    width: '18px',
    height: '18px'
  },
  styleText: {
    color: theme.palette.gray.darkerMedium
  },
  styleTextSubTitle: {
    color: theme.palette.gray.darker,
    fontSize: '14px',
    fontWeight: 500
  },
  styleSubText: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '12px',
    fontWeight: 400
  },
  generalChip: {
    background: theme.palette.gray.lighter,
    color: theme.palette.gray.darker,
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: theme.spacing(1)
  }
});

class InfoOriginDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Props
      applicantInfo: props.applicantInfo,
      origin: props.origin,
      // Internal
      mainInfoFromKeyPerson: null,
      originObj: {},
      loading: false
    };
  }

  getProfileTypeChip(userProfile) {
    const jobUser = userProfile.job;
    const referralUser = userProfile?.referral?.user_id || null;
    const internalReferralUser = userProfile?.referral?.internal || false;

    if (!jobUser) return null;

    if (referralUser) {
      if (jobUser.userCreated?.id === referralUser) {
        return this.props.intl.formatMessage({ id: 'ReferralList.referral_REQUESTER_USER_CHIP' });
      }
      if (jobUser.userManager?.id === referralUser) {
        return this.props.intl.formatMessage({ id: 'ReferralList.referral_MANAGER_USER_CHIP' });
      }
      return this.props.intl.formatMessage({ id: 'ReferralList.referral_INTERNAL_USER_CHIP' });
    }

    return internalReferralUser
      ? this.props.intl.formatMessage({ id: 'ReferralList.referral_OUTSIDE_USER_CHIP' })
      : this.props.intl.formatMessage({ id: 'ReferralList.referral_OUTSIDE' });
  }

  componentDidMount() {
    if (this.state.applicantInfo && this.state.origin) {
      this.getTransferAndUserInfo();
    }
  }

  getTransferAndUserInfo() {
    const { transfer_info, referer, job_id, referral } = this.state.applicantInfo;
    const { origin } = this.state;

    let api = new Api();
    this.setState(
      {
        loading: true
      },
      async () => {
        const isMoveOrCopy = origin === 'MOVE' || origin === 'COPY';
        const transferOrReferral =
          origin === 'MOVE' ||
          origin === 'COPY' ||
          origin === 'SEARCH' ||
          origin === 'CROSSRANK' ||
          origin === 'REFERRAL' ||
          origin === 'RECRUITER';

        const getAccountUserAndJob = async (userId, jobId) => {
          try {
            if (userId) {
              let userFilter = {
                fields: ['id', 'username', 'email', 'avatar_50_path'],
                where: { id: userId }
              };
              api.get('AccountUsers', userFilter, (status, dataUser) => {
                if (dataUser && dataUser.length > 0) {
                  this.setState({
                    mainInfoFromKeyPerson: {
                      username: dataUser[0].username,
                      email: dataUser[0].email,
                      avatar_50_path: dataUser[0].avatar_50_path
                    }
                  });
                } else {
                  this.setState({
                    mainInfoFromKeyPerson: {}
                  });
                }
              });
            } else {
              this.setState({
                mainInfoFromKeyPerson: {}
              });
            }

            if (isMoveOrCopy) {
              const jobTitleInfo = transfer_info?.job_title || null;
              const jobCode = transfer_info?.job_code || null;
              if (jobId && !jobTitleInfo) {
                let jobFilter = {
                  fields: ['id', 'title', 'code'],
                  where: { id: jobId }
                };

                api.get('Jobs', jobFilter, (status, dataJob) => {
                  if (dataJob && dataJob.length > 0) {
                    this.setState({
                      originObj: {
                        job_code: dataJob[0].code,
                        job_title: dataJob[0].title
                      }
                    });
                  }
                });
              } else {
                this.setState({
                  originObj: {
                    job_code: jobCode,
                    job_title: jobTitleInfo
                  }
                });
              }
            }
          } catch (error) {
            console.log(error);
          }
        };

        try {
          if (transferOrReferral) {
            let userId = transfer_info && transfer_info.user_id ? transfer_info.user_id : null;
            let jobId = transfer_info && transfer_info.job_id ? transfer_info.job_id : null;
            if (origin === 'REFERRAL' || origin === 'RECRUITER') {
              userId = referral && referral.user_id ? referral.user_id : null;
              jobId = null;
            }
            await getAccountUserAndJob(userId, jobId);
          }

          if (
            origin === 'APPLY' ||
            origin === 'MAILIN' ||
            origin === 'IMPORT' ||
            origin === 'UNKNOWN' ||
            origin === 'HUNTING' ||
            origin === 'SCRAP'
          ) {
            if (referer && referer.name) {
              this.setState({ mainInfoFromKeyPerson: {}, originObj: { origin: referer.name } });
            } else {
              this.setState({ mainInfoFromKeyPerson: {} });
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.setState({ loading: false });
        }
      }
    );
  }

  createMainInfoOriginWithAvatar = (avatar, name, date, email) => {
    const { classes } = this.props;
    const { applicantInfo, origin, originObj } = this.state;
    const referralChipLabel = this.getProfileTypeChip(applicantInfo);

    const avatarSrc = avatar
      ? `https://s3-sa-east-1.amazonaws.com/recrutai-dev/${avatar}`
      : 'https://s3-sa-east-1.amazonaws.com/recrutai-media/img/avatar-no-image.png';

    const keyOriginDate = date ? (
      <LocaleFormatter dateToFormat={Moment(date).format('LLLL')} />
    ) : null;
    return (
      <>
        <Box>
          <Typography className={classes.styleSubText} style={{ fontWeight: '500' }}>
            <FormattedMessage id={`InfoOriginDetails.${this.state.origin}`} />
          </Typography>
        </Box>

        <Box>
          <Box display="flex" alignItems="center">
            <Box mt={1} display="flex">
              <Avatar
                name={name}
                src={avatarSrc}
                maxInitials={2}
                color={CONST.APP_COLORS['primary-light']}
                size="32px"
                round={true}
              />
              <Box>
                <Box mt={1} display="flex">
                  <Box ml={1}>
                    <Typography className={classes.styleTextSubTitle}>{name}</Typography>
                  </Box>
                  {this.state.origin === 'REFERRAL' && referralChipLabel && (
                    <Box ml={1}>
                      <Chip
                        className={classes.generalChip}
                        size="small"
                        label={referralChipLabel}
                      />
                    </Box>
                  )}
                </Box>
                {this.state.origin === 'REFERRAL' && (
                  <Box ml={1} mt={0.5}>
                    <Typography className={classes.styleSubText}>{email}</Typography>
                  </Box>
                )}
                <Box ml={1} mt={0.5}>
                  <Typography className={classes.styleSubText}>{keyOriginDate}</Typography>
                </Box>

                {origin === 'HUNTING' && (
                  <>
                    <Box ml={1} mt={2}>
                      <Typography
                        style={{
                          color: CONST.APP_COLORS['black'],
                          fontSize: '12px',
                          fontWeight: '400'
                        }}
                      >
                        <FormattedMessage id="InfoOriginDetails.origin" />
                      </Typography>
                    </Box>

                    <Box ml={1} mt={0.5}>
                      <Typography className={classes.styleSubText}>{originObj.origin}</Typography>
                    </Box>
                  </>
                )}

                <>
                  {(this.state.origin === 'MOVE' || this.state.origin === 'COPY') && (
                    <>
                      {originObj?.job_code && originObj?.job_title && (
                        <>
                          <Box mt={2} ml={1}>
                            <Typography
                              style={{
                                color: CONST.APP_COLORS['black'],
                                fontSize: '12px',
                                fontWeight: '400'
                              }}
                            >
                              <FormattedMessage id="InfoOriginDetails.jobOrigin" />
                            </Typography>
                          </Box>

                          <Box mt={0.5} ml={1}>
                            <Typography className={classes.styleSubText}>
                              {`[${originObj.job_code}] ${originObj.job_title}`}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  createMainInfoOrigin = (date, originObj, title) => {
    const { classes } = this.props;
    const { applicantInfo } = this.state;

    const keyOriginDate = date ? (
      <LocaleFormatter dateToFormat={Moment(date).format('LLLL')} />
    ) : null;
    return (
      <>
        <Box>
          <Typography className={classes.styleSubText} style={{ fontWeight: '500' }}>
            <FormattedMessage id={`InfoOriginDetails.${title}`} />
          </Typography>
        </Box>

        <Box>
          <Box display="flex" alignItems="center">
            <Box display="flex">
              <Box>
                <Box mt={0.5}>
                  <Typography className={classes.styleSubText}>{keyOriginDate}</Typography>
                </Box>
                <>
                  {this.state.origin === 'MOVE' || this.state.origin === 'COPY'
                    ? originObj?.job_code &&
                      originObj?.job_title && (
                        <>
                          <Box mt={2}>
                            <Typography
                              style={{
                                color: CONST.APP_COLORS['black'],
                                fontSize: '12px',
                                fontWeight: '400'
                              }}
                            >
                              <FormattedMessage id="InfoOriginDetails.jobOrigin" />
                            </Typography>
                          </Box>

                          <Box mt={0.5}>
                            <Typography className={classes.styleSubText}>
                              {`[${originObj.job_code}] ${originObj.job_title}`}
                            </Typography>
                          </Box>
                        </>
                      )
                    : originObj?.origin && (
                        <>
                          <Box mt={2}>
                            <Typography
                              style={{
                                color: CONST.APP_COLORS['black'],
                                fontSize: '12px',
                                fontWeight: '400'
                              }}
                            >
                              <FormattedMessage id="InfoOriginDetails.origin" />
                            </Typography>
                          </Box>
                          <Box mt={0.5}>
                            <Typography className={classes.styleSubText}>
                              {originObj.origin}
                            </Typography>
                          </Box>
                        </>
                      )}
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  renderOriginDetails = () => {
    const { origin, applicantInfo, originObj, mainInfoFromKeyPerson } = this.state;
    // console.log(applicantInfo);

    switch (origin) {
      case 'HUNTING':
        const originDate =
          originObj && originObj.date ? originObj.date : applicantInfo.date_created;
        if (mainInfoFromKeyPerson && Object.keys(mainInfoFromKeyPerson).length > 0) {
          const { avatar_50_path, username } = mainInfoFromKeyPerson;
          return this.createMainInfoOriginWithAvatar(avatar_50_path, username, originDate);
        } else {
          return this.createMainInfoOrigin(originDate, originObj, 'HuntingWithoutAvatar');
        }
      case 'CROSSRANK':
      case 'SEARCH':
      case 'MOVE':
      case 'COPY':
        if (mainInfoFromKeyPerson && Object.keys(mainInfoFromKeyPerson).length > 0) {
          const { avatar_50_path, username } = mainInfoFromKeyPerson;
          return this.createMainInfoOriginWithAvatar(
            avatar_50_path,
            username,
            applicantInfo?.date_created
          );
        } else {
          return this.createMainInfoOrigin(applicantInfo?.date_created, originObj, 'APPLY');
        }
      case 'REFERRAL':
      case 'RECRUITER':
        if (mainInfoFromKeyPerson && Object.keys(mainInfoFromKeyPerson).length > 0) {
          const { avatar_50_path, email, username } = mainInfoFromKeyPerson;
          return this.createMainInfoOriginWithAvatar(
            avatar_50_path,
            username,
            applicantInfo.date_created,
            email
          );
        } else {
          const { referral } = this.state.applicantInfo;
          if (referral) {
            return this.createMainInfoOriginWithAvatar(
              null,
              referral.name,
              referral.date_created,
              referral.email
            );
          } else {
            return this.createMainInfoOrigin(applicantInfo?.date_created, originObj, 'APPLY');
          }
        }
      case 'IMPORT':
      case 'MAILIN':
      case 'APPLY':
        const defaultOrigin = (
          <FormattedMessage id="InfoOriginDetails.defaultOrigin" defaultMessage="Direct" />
        );
        const obj = originObj && originObj.origin ? originObj : { origin: defaultOrigin };
        return this.createMainInfoOrigin(applicantInfo?.date_created, obj, origin);
      default:
        return this.createMainInfoOrigin(applicantInfo?.date_created, originObj, 'APPLY');
    }
  };

  render() {
    const { classes } = this.props;
    const { loading, mainInfoFromKeyPerson } = this.state;

    if (loading || !mainInfoFromKeyPerson) {
      return (
        <Box>
          <InfoOriginDetailSkeleton />
        </Box>
      );
    } else {
      return (
        <Box
          style={{
            width: '363px',
            maxHeight: '230px',
            borderRadius: '10px',
            boxShadow: `0px 4px 15px 0px ${CONST.APP_COLORS['black']}33`
          }}
        >
          <Box
            style={{
              position: 'sticky',
              top: '0px',
              zIndex: '999',
              background: 'white'
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px'
              }}
            >
              <Box width="275px" display="flex" justifyContent="center">
                <Typography
                  className={classes.styleSubText}
                  style={{ display: 'flex', alignItems: 'center', fontWeight: '400' }}
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                  <FormattedMessage
                    id="InfoOriginDetails.title"
                    defaultMessage="Candidate's background information"
                  />
                </Typography>
              </Box>
            </Box>
            <Divider style={{ margin: '0 1rem' }} />
          </Box>
          <Box
            display="flex"
            style={{
              padding: '16px 24px'
            }}
          >
            <Box>
              <Box>{this.renderOriginDetails()}</Box>
            </Box>
          </Box>
        </Box>
      );
    }
  }
}

export default injectIntl(withStyles(useStyles)(InfoOriginDetails));
