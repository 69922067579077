import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import CVDetail from '../CV/CVDetail';
import generatePDF, { Margin } from 'react-to-pdf';
import Button from '@material-ui/core/Button';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';

const messages = defineMessages({});

const useStyles = (theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '0px !important',
      width: 30,
      height: 30
    }
  },
  hiddenLabel: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  iconButton: {
    display: 'none',
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }
});

class PrintCustomCV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant: props.applicant,
      shareLinkDetails: props.shareLinkDetails,
      // Internal state
      loading: false,
      printing: false,
      accountUsers: []
    };

    this.ref1 = React.createRef();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }

    if (prevProps.accountUsers !== this.props.accountUsers) {
      this.setState({
        accountUsers: this.props.accountUsers
      });
    }

    if (prevProps.shareLinkDetails !== this.props.shareLinkDetails) {
      this.setState({
        shareLinkDetails: this.props.shareLinkDetails
      });
    }
  }

  printCV = () => {
    if (this.state.applicant) {
      this.setState(
        {
          printing: true
        },
        () => {
          if (this.props.handleSetPrinting) {
            this.props.handleSetPrinting(this.state.printing);
          }
          const applicant = this.state.applicant;
          const job = applicant.job;
          const jobCode = job ? `[${job.code}] - ` : '';

          generatePDF(this.ref1, {
            filename: `${jobCode}${applicant.name}.pdf`,
            page: {
              // margin is in MM, default is Margin.NONE = 0
              margin: Margin.SMALL
            },

            overrides: {
              canvas: {
                imageTimeout: 10000,
                useCORS: true
              }
            }
          })
            .then(() => {
              this.setState(
                {
                  printing: false
                },
                () => {
                  if (this.props.handleSetPrinting) {
                    this.props.handleSetPrinting(this.state.printing);
                  }
                }
              );
            })
            .catch((error) => {
              console.error('Error generating PDF:', error);
              this.setState(
                {
                  printing: false
                },
                () => {
                  if (this.props.handleSetPrinting) {
                    this.props.handleSetPrinting(this.state.printing);
                  }
                }
              );
            });
        }
      );
      // window.print()
    }
  };

  render() {
    const { classes } = this.props;

    const applicant = this.state.applicant;

    const printDetail = () => {
      const pipeline = applicant.job ? applicant.job.pipeline_stages : [];

      return (
        <>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={this.printCV}
            startIcon={
              <PictureAsPdfOutlinedIcon
                className={classes.iconButton}
                fontSize="small"
                color="primary"
              />
            }
            classes={{
              root: classes.root
            }}
          >
            <span className={classes.hiddenLabel}>
              <FormattedMessage id="PrintCustomCV.saveCV" defaultMessage="Save PDF" />
            </span>
          </Button>
          <div
            ref={this.ref1}
            style={{
              position: 'fixed',
              left: '-1000rem',
              maxWidth: '1052px'
            }}
          >
            <CVDetail
              profile={applicant}
              job={applicant.job}
              pipeline={pipeline}
              readonly={true}
              print={true}
              shareLinkDetails={this.state.shareLinkDetails}
              accountUsers={this.state.accountUsers}
            />
          </div>
        </>
      );
    };

    if (applicant) {
      return <>{printDetail()}</>;
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(PrintCustomCV));
