import Api from '../Api/Api';
import UserProfile from './UserProfile';
import $ from 'jquery';
import React, { Component } from 'react';
import * as CONST from './constants';
import 'jquery-mask-plugin';
import countryToCurrency from 'country-to-currency';

export async function getCurrenciesByCountry() {
  let api = new Api();
  let filter = {
    order: ['suggested DESC', 'name']
  };
  try {
    let countries = [];
    let currenciesList = [];
    await api.get('Countries', filter, (status, data) => {
      if (status === 200 && data.length > 0) {
        data.forEach((country) => {
          countries.push({ value: country.code, label: country.name });
        });

        countries.forEach((country) => {
          if (countryToCurrency[country.value]) {
            currenciesList.push(countryToCurrency[country.value]);
          }
        });
      } else {
        throw new Error('No data was returned');
      }
    });

    return currenciesList;
  } catch (err) {
    console.log(err);
  }
}

var CurrencyController = (function () {
  var getMaskedCurrency = function (that, value) {
    if (!value) {
      return {
        maskedResult: '',
        integerResult: 0
      };
    }

    value = value.toString();
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

    const locale = that.props.user.language;

    const result = new Intl.NumberFormat(locale).format(parseFloat(value));

    const unformattedValue = parseInt(result.replace('.', '').replace(',', '').replace(/\D/g, ''));

    return {
      maskedResult: result,
      integerResult: unformattedValue
    };
  };

  var removeDecimals = function (that, value) {
    value = value.toString();
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '');
    const locale = that.props.user.language;
    let splitChar = '.';

    if (locale === 'pt-br') {
      splitChar = ',';
    }

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat(locale, options).format(parseFloat(value));

    return result.split(splitChar)[0];
  };

  var displayWithCurrency = function (that, value, thisCurrency) {
    if (!value || !thisCurrency) {
      return '';
    }

    const locale = that.props.user.language;

    const valueWithCurrency = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 0,
      style: 'currency',
      currency: thisCurrency
    }).format(value);

    return valueWithCurrency;
  };

  var formatDurationUppercase = (that, duration) => {
    switch (duration) {
      case 'HOUR':
        return that.props.intl.formatMessage({ id: 'JobSalaryInfo.duration_opt_hour' });
      case 'MONTH':
        return that.props.intl.formatMessage({ id: 'JobSalaryInfo.duration_opt_month' });
      case 'YEAR':
        return that.props.intl.formatMessage({ id: 'JobSalaryInfo.duration_opt_year' });
    }
  };

  var formatDurationLowerCase = (that, duration) => {
    switch (duration) {
      case 'HOUR':
        return that.props.intl.formatMessage({ id: 'JobSalaryInfo.opt_hour' });
      case 'MONTH':
        return that.props.intl.formatMessage({ id: 'JobSalaryInfo.opt_month' });
      case 'YEAR':
        return that.props.intl.formatMessage({ id: 'JobSalaryInfo.opt_year' });
    }
  };

  return {
    getMaskedCurrency: getMaskedCurrency,
    removeDecimals: removeDecimals,
    displayWithCurrency: displayWithCurrency,
    formatDurationUppercase: formatDurationUppercase,
    formatDurationLowerCase: formatDurationLowerCase
  };
})();

export default CurrencyController;
