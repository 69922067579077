import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import * as CONST from '../Common/constants';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ModalEventOptions from './ModalEventOptions';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import UserProfile from '../Common/UserProfile';
import Api from '../Api/Api';

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

const messages = defineMessages({});

class ButtonNewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job_id: props.job_id,
      thisStage: props.thisStage,
      pipeline: props.pipeline,
      selected_ids: props.selected_ids,
      selected_objs: [], // All selected ids full objects
      applicants: props.applicants ? props.applicants : [], // All applicants from pipeline stage
      // Internal state
      submiting: false,
      // Modals
      modal: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.thisStage !== this.props.thisStage) {
      this.setState({
        thisStage: this.props.thisStage
      });
    }
    if (prevProps.pipeline !== this.props.pipeline) {
      this.setState({
        pipeline: this.props.pipeline
      });
    }
    if (prevProps.selected_ids !== this.props.selected_ids) {
      let selected_objs = [];
      this.state.applicants.forEach((applicant) => {
        if (this.props.selected_ids.indexOf(applicant.id) > -1) {
          selected_objs.push(applicant);
        }
      });
      this.setState({
        selected_ids: this.props.selected_ids,
        selected_objs: selected_objs
      });
    }
    if (prevProps.applicants !== this.props.applicants) {
      let selected_objs = [];
      this.props.applicants.forEach((applicant) => {
        if (this.state.selected_ids.indexOf(applicant.id) > -1) {
          selected_objs.push(applicant);
        }
      });
      this.setState({
        applicants: this.props.applicants,
        selected_objs: selected_objs
      });
    }
  }

  onTransfer = () => {
    this.setState({
      modal: true
    });
  };

  handleModalClose = () => {
    this.setState({
      modal: false
    });
  };

  handleModalPost = ({ ids, options }) => {
    const job_id = this.state.job_id;
    const thisStage = this.state.thisStage;

    this.setState(
      {
        submiting: true
      },
      () => {
        const payload = ids.map((applicant_id) => {
          return {
            account_id: UserProfile.getRealm(),
            job_id: job_id,
            applicant_id: applicant_id,
            pipeline_stage: parseInt(thisStage),
            ...options
          };
        });

        let api = new Api();
        api.post('Jobs/create-event', { payload: payload }, (status, data) => {
          if (status === 400) {
            this.setState(
              {
                submiting: false,
                modal: false
              },
              () => {
                window.swal({
                  type: 'warning',
                  title: '',
                  text: this.props.intl.formatMessage({
                    id: 'ModalEventOptions.err_' + data.error.name
                  }),
                  closeOnConfirm: true
                });
              }
            );
          } else {
            this.setState(
              {
                submiting: false,
                modal: false
              },
              () => {
                if (this.props.handlerUpdate) {
                  this.props.handlerUpdate({
                    ids: ids,
                    options: options
                  });
                }
              }
            );
          }
        });
      }
    );
  };

  render() {
    const thisStage = this.state.thisStage;
    const pipeline = this.state.pipeline;
    const selected_objs = this.state.selected_objs;

    let thisStageObj = {};
    pipeline.sort(dynamicSort('priority'));
    pipeline.some((pipe) => {
      if (pipe.id === parseInt(thisStage)) {
        thisStageObj = pipe;
        return true;
      }
    });

    const displayButton = () => {
      let disabled = true;
      if (selected_objs.length > 0) {
        disabled = false;
      }

      return (
        <>
          {this.props.variant === 'detail' ? (
            <Button size="small" onClick={() => this.onTransfer()} disabled={disabled}>
              <span className="hidden-xs hidden-sm">{this.props.label}</span>
            </Button>
          ) : (
            <Tooltip
              arrow
              title={
                <FormattedMessage
                  id="ButtonNewEvent.tooltipNew"
                  defaultMessage="Create a new event or interview"
                />
              }
            >
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => this.onTransfer()}
                disabled={disabled}
                startIcon={<AddCircleOutlineIcon />}
              >
                <span className="hidden-xs hidden-sm">
                  <FormattedMessage
                    id="ButtonNewEvent.buttonNew"
                    defaultMessage="Event / Interview"
                  />
                </span>
              </Button>
            </Tooltip>
          )}

          <ModalEventOptions
            open={this.state.modal}
            nextStage={this.state.thisStage}
            nextStageObj={thisStageObj}
            selected_ids={this.state.selected_ids}
            selected_objs={this.state.selected_objs}
            handlerClose={this.handleModalClose}
            handlerPost={this.handleModalPost}
            submiting={this.state.submiting}
            job_id={this.state.job_id}
          />
        </>
      );
    };

    return <>{displayButton()}</>;
  }
}

export default injectIntl(ButtonNewEvent);
