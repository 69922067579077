import React from 'react';
import { Col } from 'react-bootstrap';
import * as CONST from '../../Common/constants';

class InfoDistanceToJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      distance_job: props.distance_job
    };
  }

  render() {
    const distance = () => {
      let dist;
      if (this.state.distance_job > 0) {
        let kms = parseInt(this.state.distance_job) / 1000;
        let meters = kms / 1000;
        if (kms >= 1) {
          dist = kms.toFixed(0) + 'km';
        } else if (kms < 1) {
          dist = meters.toFixed(0) + 'm';
        }
      } else {
        dist = CONST.NOT_AVAILABLE;
      }
      return dist;
    };

    return (
      <span
        style={{
          whiteSpace: 'nowrap'
        }}
      >
        <i className="icon-location-pin"></i> {distance()}
      </span>
    );
  }
}

export default InfoDistanceToJob;
