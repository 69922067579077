import React from 'react';
import { withRouter } from 'react-router-dom';
import Api from '../../Api/Api';
import * as CONST from '../../Common/constants';
import { IconOriginalCv } from '../../Icons/Icons';

class InfoOriginalCV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cv_path: props.cv_path,
      id: props.id,
      size: props.size ? props.size : 20 // Smaller than the standard 24
    };
  }

  getLink() {
    let api = new Api();
    let params = {
      applicant_id: this.state.id
    };
    api.getParam('Applicants/download-cv', params, (status, data) => {
      if (data) {
        //console.log(data);
        let path = data;
        //this.props.history.push(path);
        const link = document.createElement('a');
        link.href = path;
        //link.target = '_new';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  render() {
    const PATH = this.state.cv_path;
    if (PATH) {
      return <IconOriginalCv variant="read" onClick={() => this.getLink()} />;
    } else {
      return <IconOriginalCv variant="read" />;
    }
  }
}

export default withRouter(InfoOriginalCV);
