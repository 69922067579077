import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers/reducers';
import middlewares from './middlewares/middlewares';
import createSagaMiddleware from 'redux-saga';

import { updateTheme } from './middlewares/themes.middleware.js';

import { persistedState, saveState } from './persisted.store.js';
import rootSaga from './rootSaga';

// const sagaMonitor =
//   process.env.NODE_ENV === 'development'
//     ? // ? console.tron
//       console.tron.createSagaMonitor()
//     : // : null
//       null
// const sagaMiddleware = createSagaMiddleware()
// {
// sagaMonitor
// }
const store = createStore(
  reducers,
  persistedState, // second argument overrides the initial state
  // process.env.NODE_ENV === 'development'
  //   ? compose(
  //       console.tron.createEnhancer(),
  //       applyMiddleware(...middlewares, sagaMiddleware)
  //     )
  //   : applyMiddleware(...middlewares, sagaMiddleware)
  applyMiddleware(...middlewares)
);

// sagaMiddleware.run(rootSaga)

// add a listener that will be invoked on any state change
store.subscribe(() => {
  saveState(store.getState());
});

// Update the initial theme
updateTheme(store.getState());

window.store = store;
export { store };
