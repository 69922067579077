import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import * as CONST from '../../../Common/constants';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ModalCancelEvent from '../../../Pipeline/ModalCancelEvent';
import ModalChangeEvent from '../../../Pipeline/ModalChangeEvent';
import AccessAlarmOutlinedIcon from '@material-ui/icons/AccessAlarmOutlined';
import ModalReminder from '../../../Pipeline/ModalReminder';
import Api from '../../../Api/Api';
import ModalReminder2 from '../ModalReminder2';
import Utils from '../../../Common/Utils';

const baseFilters = CONST.getApplicantDetailBaseFields();

const messages = defineMessages({
  msg_confirmReminder: {
    id: 'InterviewMenuActions.msg_confirmReminder',
    defaultMessage: 'Send reminder?'
  }
});

const useStyles = (theme) => ({});

class InterviewMenuActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interview: props.interview,
      applicant: props.applicant,
      thisStage: props.thisStage,
      pipeline: props.pipeline,
      // Internal state
      modalChange: false,
      modalCancel: false,
      modalReminder: false,
      nr_applicant_ids: [],
      nr_model_ids: [],
      selected_model_ids: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.interview !== this.props.interview) {
      this.setState({
        interview: this.props.interview
      });
    }

    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }

    if (prevProps.pipeline !== this.props.pipeline) {
      this.setState({
        pipeline: this.props.pipeline
      });
    }
    if (prevProps.thisStage !== this.props.thisStage) {
      this.setState(
        {
          thisStage: this.props.thisStage
        },
        () => {
          this.loadNRs();
        }
      );
    }
  }

  componentDidMount() {
    this.loadNRs();
  }

  loadNRs() {
    const applicant = this.state.applicant;

    let NR_ids = [];
    let NR_model_ids = [];

    const lastInterview = this.state.interview;

    if (
      !lastInterview.cancelled &&
      !lastInterview.noshow &&
      new Date(lastInterview.datetime_interview) > new Date()
    ) {
      NR_ids.push(applicant.id);
      NR_model_ids.push({
        applicant_id: applicant.id,
        model_id: lastInterview.id
      });
    }

    this.setState({
      nr_applicant_ids: NR_ids,
      nr_model_ids: NR_model_ids
    });
  }

  handleOpenMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleModalCancelClose = () => {
    this.setState({
      modalCancel: false
    });
  };

  handleModalChangeClose = () => {
    this.setState({
      modalChange: false
    });
  };

  handleModalUpdate = (interviews) => {
    this.setState(
      {
        modalCancel: false,
        modalChange: false
      },
      () => {
        if (interviews && interviews.length > 0) {
          this.setState(
            {
              interview: interviews[0]
            },
            () => {
              if (this.props.handleUpdateInterview) {
                this.props.handleUpdateInterview(interviews[0]);
              }
            }
          );
        }
      }
    );
  };

  onChangeEvent = () => {
    this.setState({
      modalChange: true,
      anchorEl: null
    });
  };

  onCancelEvent = () => {
    this.setState({
      modalCancel: true,
      anchorEl: null
    });
  };

  handleSendReminder = () => {
    this.setState({
      extend: false,
      modalReminder: true
    });
  };

  handleModalClose = () => {
    this.setState(
      {
        modalReminder: false
      },
      () => {
        this.handleCloseMenu();
      }
    );
  };

  render() {
    const { classes } = this.props;

    const interview = this.state.interview;

    const job_id = interview.job_id;
    const nr_applicant_ids = this.state.nr_applicant_ids;
    const applicant = this.state.applicant;

    const thisStage = this.state.thisStage;
    let thisStageObj = {};
    const pipeline = this.state.pipeline;
    pipeline.sort(Utils.dynamicSort('priority'));
    pipeline.some((pipe) => {
      if (pipe.id === parseInt(thisStage)) {
        thisStageObj = pipe;
        return true;
      }
    });

    let reminderDisabled = false;
    if (!nr_applicant_ids.includes(applicant.id)) {
      reminderDisabled = true;
    }

    return (
      <>
        <IconButton onClick={this.handleOpenMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleCloseMenu}
        >
          <MenuItem
            onClick={this.onChangeEvent}
            disabled={interview.mode !== 'AUTO' && interview.cancelled === false ? false : true}
          >
            <ListItemIcon
              style={{
                minWidth: 0,
                marginRight: 12
              }}
            >
              <EditOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <FormattedMessage id="CardInterview.menu_editEvent" />
          </MenuItem>

          <MenuItem onClick={this.handleSendReminder} disabled={reminderDisabled}>
            <ListItemIcon
              style={{
                minWidth: 0,
                marginRight: 12
              }}
            >
              <AccessAlarmOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <FormattedMessage
              id="InterviewMenuActions.menu_sendReminder"
              defaultMessage="Send Reminder"
            />
          </MenuItem>
          <MenuItem
            onClick={this.onCancelEvent}
            disabled={interview.cancelled === true ? true : false}
          >
            <ListItemIcon
              style={{
                minWidth: 0,
                marginRight: 12
              }}
            >
              <EventBusyIcon fontSize="small" />
            </ListItemIcon>
            <FormattedMessage id="CardInterview.menu_cancelEvent" />
          </MenuItem>
        </Menu>
        <ModalCancelEvent
          open={this.state.modalCancel}
          selected_ids={[this.state.interview.id]}
          submiting={false}
          handlerClose={this.handleModalCancelClose}
          handlerUpdate={this.handleModalUpdate}
          selected_objs={[this.state.applicant]}
        />

        <ModalChangeEvent
          open={this.state.modalChange}
          selected_id={this.state.interview.id}
          submiting={false}
          handlerClose={this.handleModalChangeClose}
          handlerUpdate={this.handleModalUpdate}
          job_id={this.state.interview.job_id}
          selected_objs={[this.state.applicant]}
        />

        <ModalReminder2
          open={this.state.modalReminder}
          thisStage={thisStage}
          thisStageObj={thisStageObj}
          job_id={job_id}
          selected_ids={[this.state.applicant.id]}
          nr_model_ids={this.state.nr_model_ids}
          handlerClose={this.handleModalClose}
          handlerPost={this.handleModalPost}
          selected_objs={[this.state.applicant]}
          model={'Interview'}
        />
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(InterviewMenuActions));
