//core
import React from 'react';

//import external
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

// import internal
import * as CONST from '../Common/constants';
import ModalDisplayContent from '../Applicant/Detail2/MoreActionsCV/ModalDisplayContent';
import Api from '../Api/Api';

const messages = defineMessages({
  attach_CV: {
    id: 'IconOriginalCv.attach_CV',
    defaultMessage: 'CV attached on {date}'
  },
  update_CV: {
    id: 'IconOriginalCv.update_CV',
    defaultMessage: 'CV updated on {date}'
  }
});

const useStyles = (theme) => ({
  iconButtonContainer: {
    padding: theme.spacing(1),
    position: 'relative',
    color: theme.palette.gray.darkerRegular,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  iconCheck: {
    fontSize: 14,
    position: 'absolute',
    top: theme.spacing(0.4),
    right: theme.spacing(0.6),
    padding: theme.spacing(0.1),
    borderRadius: '50px',
    backgroundColor: theme.palette.white.main
    //color: theme.palette.success.main
  }
});

class IconOriginalCv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: props.size ? props.size : 24,
      details: {
        skills: true,
        about: true,
        contact: true,
        age: true,
        interviews: true,
        app_interviews: true,
        forms: true,
        tests: true,
        education: true,
        experiences: true,
        salary_info: true,
        certifications: true,
        languages: true,
        privacy: true,
        hide_block_motive: true,
        personality: false
      },
      applicant: this.props.applicant,
      shareModal: false,
      variant: this.props.variant,
      activity_update_cv: this.props.activity_update_cv
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }
    if (prevProps.activity_update_cv !== this.props.activity_update_cv) {
      this.setState({
        activity_update_cv: this.props.activity_update_cv
      });
    }
  }

  handleOpenCVModal = () => {
    this.setState({
      shareModal: true
    });
  };

  closeDialog = () => {
    this.setState({
      shareModal: false
    });
  };

  render() {
    const { variant, applicant, details, activity_update_cv, size } = this.state;
    const { classes } = this.props;
    if (variant !== 'read' && (!applicant.cv_path || applicant.cv_path == '')) {
      return <></>;
    }

    return (
      <>
        {variant === 'read' ? (
          <DescriptionOutlinedIcon
            style={{
              cursor: 'pointer',
              fontSize: this.props.fontSize ? '' : size,
              color: CONST.APP_COLORS['gray-darker-regular']
            }}
            {...this.props}
          />
        ) : (
          <Tooltip
            title={
              <FormattedMessage
                id={activity_update_cv ? 'IconOriginalCv.update_CV' : 'IconOriginalCv.attach_CV'}
                values={{
                  date: moment(
                    activity_update_cv ? activity_update_cv.date_created : applicant.date_created
                  ).format('lll')
                }}
              />
            }
          >
            <IconButton onClick={this.handleOpenCVModal} className={classes.iconButtonContainer}>
              <DescriptionOutlinedIcon
                style={{ cursor: 'pointer', fontSize: this.props.fontSize ? '' : size }}
                {...this.props}
              />
              {activity_update_cv && <AccessTimeIcon className={classes.iconCheck} />}
            </IconButton>
          </Tooltip>
        )}
        {variant !== 'read' && (
          <ModalDisplayContent
            open={this.state.shareModal}
            handleClose={this.closeDialog}
            type={'CV'}
            applicant={applicant}
            shareLinkDetails={details}
            activity_update_cv={this.state.activity_update_cv}
          />
        )}
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(IconOriginalCv));
