import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Moment from 'moment';
import 'moment/locale/pt-br'; // FIXME lang
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardController from '../../CardController';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import TimerIcon from '@material-ui/icons/Timer';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SubjectIcon from '@material-ui/icons/Subject';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import LocaleFormatter from '../../../Common/LocaleFormatter';
import he from 'he';
import striptags from 'striptags';
import * as CONST from '../../../Common/constants';
import ListIcon from '@material-ui/icons/List';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Chip from '@material-ui/core/Chip';
import LinkIcon from '@material-ui/icons/Link';
import GetAppIcon from '@material-ui/icons/GetApp';
import Api from '../../../Api/Api';

// Moment.locale('pt-br'); // FIXME: set moment locale from intl.locale
// https://stackoverflow.com/questions/1199352/smart-way-to-truncate-long-strings
function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '…' : str;
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const useStyles = (theme) => ({
  cardRoot: {
    border: '1px solid ' + theme.palette.gray.main
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
});

const HtmlEntities = require('html-entities').AllHtmlEntities;
const ents = new HtmlEntities();

class DialogAnswers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      test: props.test,
      open: props.open ? props.open : false,
      variant: props.variant,
      // Internal State
      questions: [],
      answers: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.test !== this.props.test) {
      this.setState(
        {
          test: this.props.test
        },
        () => {
          this.setAnswers();
        }
      );
    }

    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open
      });
    }

    if (prevProps.variant !== this.props.variant) {
      this.setState({
        variant: this.props.variant
      });
    }
  }

  componentDidMount() {
    this.setAnswers();
  }

  getDownloadLink(test_id, question_id) {
    let api = new Api();
    let params = {
      test_id: test_id,
      question_id: question_id
    };
    api.getParam('ApplicantCustomTests/download-file', params, (status, data) => {
      if (data) {
        //console.log(data);
        let path = data;
        //this.props.history.push(path);
        const link = document.createElement('a');
        link.href = path;
        //link.target = '_new';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }

  setAnswers = () => {
    const test = this.state.test;

    if (test) {
      const questions =
        test.customTest && test.customTest.questions ? test.customTest.questions : [];

      const form_answers = test.form_answers;
      let answers = [];

      if (test.completed === true && form_answers) {
        questions.forEach((question) => {
          let answer = form_answers[question.tag_id];

          let title = he.decode(question.tag_question);
          let icon;
          let label = (
            <i>
              (
              {this.props.intl.formatMessage({
                id: 'CardCustomTestConversational.questionNotAnswered'
              })}
              )
            </i>
          );
          let right_choice;

          if (question.type === CONST.CUSTOMTEST_TYPE.ALTERNATIVES) {
            const right_choices = question.tag_children
              .filter((x) => x.rightChoice === true)
              .map((x) => x.id);
            //console.log(right_choices);

            // Checking if has any answer for this alternative
            if (form_answers.hasOwnProperty(question.tag_id)) {
              if (question.tag_isMultiChoice === true) {
                // Answer not in array format, change it to array
                if (!Array.isArray(answer)) {
                  answer = [answer];
                }

                const alternatives = question.tag_children.map((x) => x.id);
                const choices_arr = intersection(alternatives, answer);
                let labels_arr = [];
                choices_arr.forEach((choice) => {
                  const idx = question.tag_children.findIndex((x) => x.id === choice);
                  labels_arr.push(he.decode(question.tag_children[idx].label));
                });

                label = labels_arr.join(', ');

                // https://stackoverflow.com/questions/6229197/how-to-know-if-two-arrays-have-the-same-values
                if (right_choices.sort().join(',') === answer.sort().join(',')) {
                  icon = <CheckCircleIcon style={{ color: CONST.APP_COLORS['green-dark'] }} />;
                  right_choice = true;
                } else {
                  icon = <CancelIcon style={{ color: CONST.APP_COLORS['danger-dark'] }} />;
                  right_choice = false;
                }
              } else if (question.tag_isMultiChoice === false) {
                // If answer came in array format change it to String
                if (Array.isArray(answer)) {
                  answer = answer.length > 0 ? answer[0] : null;
                }

                const ansIdx = question.tag_children.findIndex((x) => x.id === answer);
                label = he.decode(question.tag_children?.[ansIdx]?.label ?? '-');
                if (right_choices[0] === answer) {
                  icon = <CheckCircleIcon style={{ color: CONST.APP_COLORS['green-dark'] }} />;
                  right_choice = true;
                } else {
                  icon = <CancelIcon style={{ color: CONST.APP_COLORS['danger-dark'] }} />;
                  right_choice = false;
                }
              } else {
                icon = <CancelIcon style={{ color: CONST.APP_COLORS['danger-dark'] }} />;
                right_choice = false;
              }

              // Pushing answer
              answers.push({
                id: question.tag_id,
                type: question.type,
                icon: icon,
                question: title,
                label: label,
                right_choice: right_choice,
                weight: question.tag_weight
              });
            } else {
              answers.push({
                id: question.tag_id,
                type: question.type,
                icon: <ListIcon />,
                question: title,
                label: label
              });
            }
          } else if (question.type === CONST.CUSTOMTEST_TYPE.FILE) {
            if (answer) {
              const url = CONST.S3_BASE_URL + CONST.S3_BUCKET_URL + form_answers[question.tag_id];
              let filename = '';
              try {
                filename = new URL(url).pathname.split('/').pop();
                filename = decodeURIComponent(filename);
              } catch (e) {
                console.error(e);
                filename = form_answers[question.tag_id];
              }
              //const filename = form_answers[question.tag_id];

              //label = <a href="#" onClick={() => this.getDownloadLink(test.id, question.tag_id)} target="_new">{filename}</a>;

              label = (
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => this.getDownloadLink(test.id, question.tag_id)}
                  startIcon={<GetAppIcon />}
                >
                  <FormattedMessage id="CardCustomTestConversational.buttonDownloadLink" />
                </Button>
              );
            }

            answers.push({
              id: question.tag_id,
              type: question.type,
              icon: <AttachFileIcon />,
              question: title,
              label: label
            });
          } else if (question.type === CONST.CUSTOMTEST_TYPE.OPEN_TEXT) {
            if (answer) {
              label = form_answers[question.tag_id];
              label = typeof label === 'string' ? label.replace(/(?:\r\n|\r|\n)/g, '<br />') : null;
            }

            answers.push({
              id: question.tag_id,
              type: question.type,
              icon: <SubjectIcon />,
              question: title,
              label: label
            });
          } else if (question.type === CONST.CUSTOMTEST_TYPE.LINK) {
            if (answer) {
              const url = form_answers[question.tag_id];
              label = (
                <a href={url} target="_new">
                  {url}
                </a>
              );
            }

            answers.push({
              id: question.tag_id,
              type: question.type,
              icon: <LinkIcon />,
              question: title,
              label: label
            });
          }
        });
      }

      //console.log(answers);

      this.setState({
        answers: answers
      });
    }
  };

  handleModalClose = () => {
    this.setState(
      {
        open: false
      },
      () => {
        if (this.props.handleClose) {
          this.props.handleClose();
        }
      }
    );
  };

  render() {
    const { classes } = this.props;

    const test = this.state.test;

    const open = this.state.open;

    if (test) {
      const test = this.state.test;
      const score = CardController.getCustomTestScore(test.test_score);
      const scorePerc = CardController.getCustomTestScorePerc(test.test_score);
      const answers = this.state.answers;

      const questions =
        test.customTest && test.customTest.questions ? test.customTest.questions : [];

      const questionCount = () => {
        if (test.completed === true) {
          const answers = this.state.answers;
          const questions = this.state.questions;

          const right = answers.filter((x) => x.right_choice === true).length; // Right choices
          const total = questions.filter(
            (x) => x.type === CONST.CUSTOMTEST_TYPE.ALTERNATIVES
          ).length; // Total of Questions of type ALTERNATIVES

          return `${right} / ${total}`;
        } else {
          return null;
        }
      };

      const displayIcon = () => {
        if (test.completed === true) {
          return <PlaylistAddCheckIcon />;
        } else if (new Date() > new Date(test.date_limit)) {
          return <AlarmOffIcon color="error" />;
        } else {
          return <NotInterestedIcon />;
        }
      };

      const displayTime = () => {
        let icon,
          time,
          to,
          isAnswered = false,
          answeredDate;
        const from = new Date();

        // Completed succesfully -> Display time to complete the form
        if (test.completed) {
          to = new Date(from.getTime() + parseInt(test.answers_ellapsed));
          time = Moment(from).from(to, true);
          icon = <TimerIcon fontSize="inherit" />;
          isAnswered = !!test.date_answers;
          const parsedAnsweredDate = isAnswered
            ? Moment(test.date_answers).format('lll')
            : 'invalid date';

          if (parsedAnsweredDate.toLowerCase() === 'invalid date') {
            isAnswered = false;
          } else {
            answeredDate = parsedAnsweredDate;
          }
        } else if (new Date() > new Date(test.date_limit)) {
          // Expired -> Display time is has expired
          to = new Date(test.date_limit);
          time = Moment(from).from(to, true);
          icon = <TimerOffIcon fontSize="inherit" />;
        } else {
          // Incomplete -> Display time since expiration
          to = new Date(test.date_limit);
          time = Moment(from).to(to, false);
          icon = <AvTimerIcon fontSize="inherit" />;
        }

        return (
          <Box display="flex" flexDirection="column" alignItems="start">
            <Box>
              {isAnswered ? (
                <CheckCircleOutlinedIcon fontSize="inherit" />
              ) : (
                <CancelOutlinedIcon fontSize="inherit" />
              )}
              <small className="ml-sm pt-sm">
                {isAnswered ? (
                  <LocaleFormatter dateToFormat={answeredDate} />
                ) : (
                  <FormattedMessage id="CardCustomTestConversational.testNoAnsweredDate" />
                )}
              </small>
            </Box>
            <Box>
              {icon}
              <small className="ml-sm pt-sm">
                <LocaleFormatter dateToFormat={time} />
              </small>
            </Box>
          </Box>
        );
      };

      const displayResult = () => {
        return (
          <Box display={'flex'}>
            <Box
              p={2}
              flex={'auto'}
              width="30%"
              className="text-center text-sm"
              style={{ borderRight: `1px solid  ${CONST.APP_COLORS['black']}14` }}
            >
              <p className="text-muted text-uppercase" style={{ fontSize: '75%' }}>
                <small>
                  <FormattedMessage id="CardCustomTestTypeform.score" />
                </small>
              </p>
              <h4 className="pt-sm">{score === -1 ? '' : score}</h4>
            </Box>
            <Box p={2} flex={'auto'} width="70%" className="text-center text-sm">
              <p className="text-muted text-uppercase" style={{ fontSize: '75%' }}>
                <small>
                  <FormattedMessage id="CardCustomTestTypeform.rightAnswers" />
                </small>
              </p>
              <p className="ml-auto mr-auto">
                <Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" value={scorePerc} color="primary" />
                  </Box>
                  <Box minWidth={50} p={1}>
                    <small>{questionCount()}</small>
                  </Box>
                </Box>
              </p>
            </Box>
          </Box>
        );
      };

      const displayAnswers = answers.map((answer, i) => {
        const que = truncate(answer.question, 200);
        const labelString = answer.label && typeof answer.label === 'string';
        let labelAnswer = '';

        let secondaryContent;
        if (labelString) {
          labelAnswer = ents.decode(striptags(answer.label, [], '<br />'));
          secondaryContent = (
            <div className="p-lg" dangerouslySetInnerHTML={{ __html: labelAnswer }} />
          );
        } else {
          labelAnswer = answer.label;
          secondaryContent = <div className="p-lg">{labelAnswer}</div>;
        }
        return (
          <ListItem>
            <ListItemIcon>{answer.icon}</ListItemIcon>
            <ListItemText
              primary={
                <>
                  <small>
                    {i + 1}. {que}
                  </small>
                  {answer.weight !== null && answer.weight !== undefined ? (
                    <>
                      <br />
                      <Chip
                        style={{
                          background: CONST.APP_COLORS['gray-light'],
                          color: CONST.APP_COLORS['gray-darker']
                        }}
                        size="small"
                        label={
                          <FormattedMessage
                            id="CardCustomTestConversational.questionWeight"
                            values={{
                              weight: answer.weight
                            }}
                          />
                        }
                      />
                    </>
                  ) : null}
                </>
              }
              secondary={secondaryContent}
            />
          </ListItem>
        );
      });

      const displayQuestions = questions.map((question, i) => {
        let title = he.decode(question.tag_question);

        return (
          <ListItem>
            <ListItemText
              primary={
                <>
                  <small>
                    {i + 1}. {title}
                  </small>
                </>
              }
            />
          </ListItem>
        );
      });

      // console.log(this.props.variant);

      return (
        <>
          <Dialog fullWidth={true} maxWidth="md" open={open} onClose={this.handleModalClose}>
            <DialogContent>
              <Card>
                <CardHeader
                  avatar={displayIcon()}
                  title={test.customTest.name}
                  subheader={displayTime()}
                />

                {displayResult()}

                <CardContent>
                  <Divider light />
                  <List>
                    {this.state.variant === 'questions' ? displayQuestions : displayAnswers}
                  </List>
                </CardContent>
              </Card>
            </DialogContent>
            <DialogActions>
              <Divider />

              <Button onClick={() => this.handleModalClose()} color="secondary">
                <FormattedMessage id="DialogAnswers.buttonClose" defaultMessage="Close" />
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(DialogAnswers));
