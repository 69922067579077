import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import LoadingData from '../../../Layout/LoadingData';
import TextField from '@material-ui/core/TextField';
import UserStorage from '../../../Common/UserStorage';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';

const messages = defineMessages({
  search: {
    id: 'ButtonFilterApplicantTags.search',
    defaultMessage: 'Search tags'
  }
});

const useStyles = (theme) => ({
  menuRoot: {
    minWidth: 480,
    maxWidth: 640
  },
  list: {
    minWidth: 440,
    minHeight: 80,
    maxHeight: '50vh',
    flexGrow: 1,
    overflowY: 'auto',
    padding: '1.5rem 1rem 0 1rem'
  },
  mainButtonLabelContainer: {
    display: 'flex',
    width: '80%'
  },
  mainButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mainButton: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& .MuiButton-startIcon': {
      paddingLeft: '13px'
    }
  },
  mainButtonLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    marginBottom: '0'
  },
  tagsLabel: {
    margin: '.25rem 0 .25rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textLabel: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '0.8125rem',
    fontWeight: '500'
  },
  filterLabel: {
    display: 'flex',
    marginBottom: '0.25rem'
  },
  filterLabelActive: {
    color: theme.palette.primary.main
  }
});

class ButtonFilterApplicantTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Props from store
      allTags: this.props.tags,
      tags: this.props.tags,
      selected: props.selected && props.selected.length > 0 ? props.selected : [],
      // Internal State
      currentSelected: [],
      selectedTags: [],
      search: '',
      // Menu
      openMenu: false,
      anchorEl: null
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.tags !== this.props.tags) {
      const selectedTags = this.props.tags.filter((tag) => this.state.selected.includes(tag.id));

      this.setState({
        allTags: this.props.tags,
        selectedTags
      });
    }

    if (prevProps.selected !== this.props.selected) {
      const selectedTags = this.props.tags.filter((tag) => this.props.selected.includes(tag.id));

      this.setState({
        selected: this.props.selected,
        selectedTags
      });
    }
  }

  handleMenuOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState(
      {
        anchorEl: null
      },
      () => {
        if (this.props.handlerUpdate) {
          this.props.handlerUpdate(this.state.selected ? this.state.selected : []);
        }
      }
    );
  };

  handleSelect = (event, selectedTags) => {
    // console.log(nodeId);
    let filterTags = [];

    selectedTags.forEach((option) => {
      filterTags.push(option.id);
    });

    this.setState({
      selected: filterTags,
      selectedTags: selectedTags
    });
  };

  textFieldKeyDown = (e) => {
    e.stopPropagation();
  };

  handleSearch = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const search = value.toLowerCase().trim();

    this.setState((state) => {
      const tags = this.state.allTags.filter((tag) => tag.name.toLowerCase().includes(search));
      return {
        tags: tags,
        [name]: value
      };
    });
  };

  textFieldKeyDown = (e) => {
    e.stopPropagation();
  };

  render() {
    const { classes } = this.props;
    const selectedTags = this.state.selectedTags;
    const selected = this.state.selected;

    const displayLabel = () => {
      if (selected.length === 0) {
        return '';
      } else {
        if (selectedTags.length === 0) {
          // Before loading users
          return '';
        }

        const tagsName =
          selectedTags.length <= 5
            ? selectedTags.map((tag) => tag.name).join(', ')
            : selectedTags
                .slice(0, 5)
                .map((tag) => tag.name)
                .join(', ') + '...';

        return (
          <span className={classes.mainButtonLabelContainer}>
            <p className={classes.mainButtonLabel}>{tagsName}</p>
          </span>
        );
      }
    };

    return (
      <>
        <div className={classes.mainButtonContainer}>
          <Typography
            variant="subtitle1"
            className={`${
              this.state.selected.length > 0 ? classes.filterLabelActive : 'text-muted'
            } ${classes.filterLabel}`}
          >
            <FormattedMessage id="ApplicantTagsList.page_title" />
          </Typography>
          <Button
            variant="outlined"
            size="small"
            color={this.state.selected.length > 0 ? 'primary' : 'secondary'}
            onClick={this.handleMenuOpen}
            className={classes.mainButton}
            startIcon={<LocalOfferIcon />}
            endIcon={<ArrowDropDownIcon />}
            style={{
              height: this.props.size === 'large' ? 38 : 32
            }}
          >
            {displayLabel()}
          </Button>
        </div>

        <Menu
          id="action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
          disableEnforceFocus={true} // https://github.com/mui-org/material-ui-pickers/issues/1852
          className={classes.menuRoot}
        >
          <div className={classes.tagsLabel}>
            <InputLabel id="duration-label" className={classes.textLabel}>
              <FormattedMessage id="ApplicantTagsList.page_title" />
            </InputLabel>
          </div>
          <Divider />

          <div className={classes.list}>
            {this.state.loading ? (
              <ListItem>
                <LoadingData />
              </ListItem>
            ) : (
              <Autocomplete
                id="filterTags"
                name="filterTags"
                value={selectedTags}
                options={this.state.allTags}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={this.handleSelect}
                disabled={this.state.loading}
                multiple
                limitTags={6}
                filterSelectedOptions
                disableCloseOnSelect
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<FormattedMessage id="ApplicantTagsList.page_title" />}
                    inputRef={(input) => input && input.focus()}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <React.Fragment>
                          <PersonIcon color="disabled" style={{ fontSize: 20 }} />
                          {params.InputProps.startAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            )}
          </div>
        </Menu>
      </>
    );
  }
}

ButtonFilterApplicantTags.propTypes = {
  tags: PropTypes.array
};

const mapStateToProps = (state) => ({
  tags: state.applicantTags.tags
});

export default connect(
  mapStateToProps,
  null
)(injectIntl(withStyles(useStyles)(ButtonFilterApplicantTags)));
