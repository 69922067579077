// core
import React from 'react';

//import external
import { injectIntl, defineMessages } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';

//import internal
import JobStatusIcon from './JobStatusIcon';
import AddressController from '../Common/AddressController';
import * as CONST from '../Common/constants';

const useStyles = (theme) => ({
  listbox: {
    maxWidth: '100%',
    overflowX: 'hidden',
    maxHeight: 'calc(65vh - 100px)'
  },
  popper: {
    maxWidth: '100%'
  },

  mainButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  filterLabel: {
    display: 'flex',
    marginBottom: '0.25rem'
  },
  inputRoot: {
    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: '10.5px 14px 10.5px'
    },
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment': {
      paddingRight: '0.5rem' // probably the width of your search IconButton or more if needed
    },
    fontSize: 14,
    color: theme.palette.gray.darkerMedium,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
    marginLeft: theme.spacing(0.5),
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    '& input': {
      overflow: 'hidden !important',
      maxWidth: '90% !important',
      textOverflow: 'ellipsis !important',
      whiteSpace: 'nowrap !important',
      fontSize: 14,
      color: theme.palette.gray.darkerMedium,
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      marginTop: '0px !important',
      marginBottom: '0px !important',
      marginLeft: theme.spacing(0.5),
      fontFamily: 'Roboto !important',
      fontWeight: '400 !important'
    }
  },
  containerOption: {
    width: '100% !important',
    maxWidth: '100% !important'
  },
  headerOption: {
    display: 'flex',
    alignItems: 'center',
    width: '100% !important',
    maxWidth: '100% !important'
  },
  bodyOption: {
    display: 'flex',
    alignItems: 'center',
    width: '100% !important',
    maxWidth: '100% !important',
    marginLeft: theme.spacing(0.5)
  },
  option: {
    borderTop: `1px solid ${theme.palette.gray.main}`,
    width: '100% !important',
    paddingTop: `${theme.spacing(0.5)}px !important`,
    paddingBottom: `${theme.spacing(0.3)}px !important`,
    textTransform: 'none' // Remove o texto maiúsculo das opções
  },
  jobCode: {
    fontSize: 14,
    color: theme.palette.gray.darkerMedium,
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  divider: {
    marginLeft: theme.spacing(0.5)
  },
  jobTitle: {
    fontSize: 14,
    color: theme.palette.gray.darkerMedium,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
    marginLeft: theme.spacing(0.5),
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  jobStatusIcon: {
    fontSize: 20,
    marginRight: theme.spacing(0.5)
  },
  containerUserRecruiter: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  textCompanyAreaAndLocation: {
    color: theme.palette.gray.darkerRegular,
    fontSize: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
    fontFamily: 'Roboto'
  },
  circle: {
    fontSize: '14px',
    color: theme.palette.gray.darkerRegular,
    fontWeight: 400
  }
});

class AutoCompleteJobSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobsList: this.props.jobsList,
      selectedJob: undefined,
      loading: this.props.loading
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jobsList !== this.props.jobsList) {
      this.setState({
        jobsList: this.props.jobsList
      });
    }
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading
      });
    }
  }

  handleChangeSelectJob = (selected) => {
    this.setState(
      {
        newJob: selected
      },
      () => {
        if (this.props.handleSelectJob) {
          this.props.handleSelectJob(selected);
        }
      }
    );
  };

  render() {
    const { classes, placeholder } = this.props;

    const RenderOption = ({ option }) => {
      const userRecruiter = option?.userRecruiter;

      const avatarSrc =
        userRecruiter && userRecruiter?.avatar_50_path
          ? `https://s3-sa-east-1.amazonaws.com/recrutai-dev/${userRecruiter?.avatar_50_path}`
          : '';

      return (
        <div className={classes.containerOption}>
          <div className={classes.headerOption}>
            <div className={classes.jobStatusIcon}>
              <JobStatusIcon item={option} />
            </div>

            <p className={classes.jobCode}>[{option.code}]</p>
            <span className={classes.divider}>-</span>
            <p className={classes.jobTitle}>{option.title}</p>
          </div>
          <div className={classes.bodyOption}>
            {userRecruiter && (
              <div className={classes.containerUserRecruiter}>
                <Avatar
                  name={userRecruiter && userRecruiter?.username ? userRecruiter?.username : ''}
                  email={userRecruiter && userRecruiter?.email ? userRecruiter?.userRecruiter : ''}
                  src={avatarSrc}
                  maxInitials={2}
                  color={CONST.APP_COLORS['primary-light']}
                  className={classes.avatar}
                  size="1rem"
                  round={true}
                />
                <div className={classes.textCompanyAreaAndLocation}>
                  {userRecruiter && userRecruiter?.username ? userRecruiter?.username : ''}
                </div>
              </div>
            )}
            {option?.userRecruiter && option?.companyArea && (
              <span
                className={classes.circle}
                style={{
                  margin: '0 4px',
                  fontWeight: 800
                }}
              >
                &bull;
              </span>
            )}
            {option?.companyArea && (
              <div style={{ maxWidth: option.address ? '50%' : '100%' }}>
                <p className={classes.textCompanyAreaAndLocation}>{option?.companyArea?.name}</p>
              </div>
            )}
            {option?.address && option?.companyArea && (
              <span
                className={classes.circle}
                style={{
                  margin: '0 4px',
                  fontWeight: 800
                }}
              >
                &bull;
              </span>
            )}
            {option?.address && (
              <div style={{ maxWidth: option.address ? '50%' : '100%' }}>
                <p className={classes.textCompanyAreaAndLocation}>
                  {AddressController.JobAddressFormatterNew(this, option)}
                </p>
              </div>
            )}
          </div>
        </div>
      );
    };

    return (
      <div>
        <Autocomplete
          id={this.props.id || 'filterJobs'}
          name={this.props.id || 'filterJobs'}
          value={this.state.selectedJob}
          options={this.state.jobsList}
          getOptionLabel={(option) =>
            `[${option.code}] - ${option.title} ${
              option.companyArea ? ` ${option.companyArea?.name} •` : ''
            }  ${option.userRecruiter ? `${option?.userRecruiter.username} •` : ''} ${
              option?.address ? ` ${AddressController.JobAddressFormatterNew(this, option)}` : ''
            }`
          }
          renderOption={(option) => <RenderOption option={option} />}
          classes={{
            listbox: classes.listbox,
            popper: classes.popper,
            option: classes.option
          }}
          onChange={(e, newValue) => this.handleChangeSelectJob(newValue)}
          fullWidth
          filterSelectedOptions
          popupIcon={<ExpandMoreIcon fontSize="small" />}
          {...this.props}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                placeholder ||
                this.props.intl.formatMessage({
                  id: 'SelectJob.placeholder_selectJob'
                })
              }
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                style: { height: '36px', padding: '0.5rem 1rem', borderRadius: '10rem' },
                className: classes.inputRoot,
                startAdornment: (
                  <React.Fragment>
                    {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.startAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
      </div>
    );
  }
}

AutoCompleteJobSelect.propTypes = {
  countries: []
};

const mapStateToProps = (state) => ({
  countries: state.countries.countries
});

export default connect(
  mapStateToProps,
  null
)(injectIntl(withStyles(useStyles)(AutoCompleteJobSelect)));
