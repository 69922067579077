import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Collapse } from 'reactstrap';
import SidebarRun from './Sidebar.run';
import UserProfile from '../Common/UserProfile';
import Api from '../Api/Api';
import ApiLogin from '../Api/ApiLogin';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import LogoffRun from './Logoff.run';
import * as CONST from '../Common/constants';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import JobReviewController from '../Jobs/JobReviewController';
import { makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const messages = defineMessages({
  logoutMsg: {
    id: 'Sidebar.logoutMsg',
    defaultMessage: 'Are you sure do you want to logout?'
  },
  jobRequests_noPlan: {
    id: 'Sidebar.jobRequests_noPlan',
    defaultMessage: 'Not available on your current plan. Click to know more...'
  },
  blockListUser: {
    id: 'Sidebar.blockListUser',
    defaultMessage: 'Restrictions list'
  }
});

const reviewIconStyles = makeStyles((theme) => ({
  root: {
    color: (props) =>
      props.active ? CONST.APP_COLORS['primary'] : CONST.APP_COLORS['gray-darker-medium'],
    fontSize: 'inherit',
    marginTop: '0.2rem',

    '&:hover': {
      color: CONST.APP_COLORS['primary']
    }
  }
}));

const StyledRateReviewOutlinedIcon = (props) => {
  const { color, displaySize, ...other } = props;
  const classes = reviewIconStyles(props);

  return <RateReviewOutlinedIcon className={classes.root} {...other} />;
};

/** Component to display headings on sidebar */
const SidebarItemHeader = ({ children }) => <li className="nav-heading">{children}</li>;

/** Normal items for the sidebar */
const SidebarItem = ({ children, isActive }) => (
  <li className={isActive ? 'active' : ''}>{children}</li>
);

class Sidebar extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      collapse: {
        home: this.routeActive(['home']),
        accountDashboard: this.routeActive(['account-dashboard']),
        jobs: this.routeActive(['jobs']),
        jobReview: this.routeActive(['job-review']),
        jobRequests: this.routeActive(['job-requests']),
        jobProfiles: this.routeActive(['job-profiles']),
        favorites: this.routeActive(['favorites']),
        reports: this.routeActive(['reports', 'diversity-reports']),
        applicants: this.routeActive(['hired', 'referral', 'internals']),
        interviews: this.routeActive(['interview-list']),

        // tags: this.routeActive(['tag-page']),
        settings: this.routeActive([
          'company-tree',
          'job-pipeline',
          'custom-test',
          'company-page',
          'job-tag-page',
          'applicant-tag-page',
          'talent-pool',
          'Blocklist',
          'settings'
        ]),
        help: false,
        logout: this.routeActive(['logout'])
      },
      plan_type: '',
      use_approval_flow: false,
      jobsReviewCount: 0
    };
  }

  componentDidMount() {
    // pass navigator to access router api
    SidebarRun(this.navigator.bind(this), this.closeSidebar);
    this.getAccountInfo();

    if (UserProfile.isReviewer()) {
      this.getJobsReviewCount();
    }
    // Listen for routes changes in order to hide the sidebar on mobile
    this.props.history.listen(this.closeSidebarClose);
  }

  closeSidebar = () => {
    this.props.actions.toggleSetting('asideToggled');
  };

  closeSidebarClose = () => {
    this.props.actions.closeSidebarSetting('asideToggled');
  };

  componentWillReceiveProps(newProps) {
    /*
    if (newProps.showUserBlock !== this.props.showUserBlock) {
      this.setState({ showUserBlock: newProps.showUserBlock })
    }
    */
  }

  navigator(route) {
    this.props.history.push(route);
  }
  componentWillUnmount() {
    // this.props.history.unlisten();
  }

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    const currPath = this.props.location.pathname;
    /*
        if (paths.indexOf(this.props.location.pathname.replace('/','')) > -1)
            return true;
        */
    if (
      paths.findIndex((el) => {
        if (el.includes(currPath.replace('/', ''))) {
          return true;
        }
      }) > -1
    )
      return true;

    return false;
  }

  toggleItemCollapse(stateName) {
    var newCollapseState = {};
    for (let c in this.state.collapse) {
      if (this.state.collapse[c] === true && c !== stateName) this.state.collapse[c] = false;
    }
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName]
      }
    });
  }

  getAccountInfo() {
    let api = new Api();
    let filter = {
      fields: ['account_id', 'plan_type'],
      where: {
        account_id: UserProfile.getRealm()
      }
    };
    api.get('Accounts', filter, (status, data) => {
      //console.log(data[0]);

      api.getParam('Accounts/use-approval-flow', {}, (status, use_approval_flow) => {
        this.setState({
          plan_type: data[0].plan_type,
          use_approval_flow: use_approval_flow
        });
      });
    });
  }

  getJobsReviewCount() {
    JobReviewController.getJobsToReview((err, jobsCount) => {
      if (err) {
        alert(err);
      } else {
        const jobsReviewCount = jobsCount;

        this.setState({
          jobsReviewCount: jobsReviewCount
        });
      }
    });
  }

  doLogout(e) {
    e.preventDefault();

    swal({
      icon: 'info',
      title: '',
      text: this.props.intl.formatMessage({ id: 'Sidebar.logoutMsg' }),
      buttons: {
        cancel: {
          text: this.props.intl.formatMessage({
            id: 'Base.button.cancel'
          }),
          value: null,
          visible: true,
          className: '',
          closeModal: true
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: '',
          closeModal: true
        }
      }
    }).then((isConfirmed) => {
      if (isConfirmed) {
        let apiLogin = new ApiLogin();
        apiLogin.logout((err, data) => {
          if (err) {
            console.log(err);
          } else {
            LogoffRun();
            UserProfile.clear();
            this.props.history.push('/login');
          }
        });
      }
    });
  }

  render() {
    const planInfo = this.props.planInfo;
    const openRequestsCount = this.props.openRequestsCount;
    const jobsReviewCount = this.state.jobsReviewCount;

    const renderSettingsCompanyTree = () => {
      if (UserProfile.isAdmin()) {
        return (
          <li className={this.routeActive('company-tree') ? 'active' : ''}>
            <Link
              to="/company-tree"
              title={this.props.intl.formatMessage({
                id: 'Sidebar.settingsCompanyTree'
              })}
            >
              <small>
                <i className="fa fa-sitemap mr-2"></i>
                <FormattedMessage id="Sidebar.settingsCompanyTree" defaultMessage="Company Tree" />
              </small>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderSettingsJobTagPage = () => {
      if (UserProfile.isAdmin() || UserProfile.isTagUser()) {
        return (
          <li className={this.routeActive('job-tag-page') ? 'active' : ''}>
            <Link
              to="/job-tag-page"
              title={this.props.intl.formatMessage({
                id: 'Sidebar.settingsJobTagPage'
              })}
            >
              <small>
                <i className="fa fa-tag mr-2"></i>
                <FormattedMessage id="Sidebar.settingsJobTagPage" defaultMessage="Job Tags" />
              </small>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderSettingsApplicantTagPage = () => {
      if (UserProfile.isAdmin() || UserProfile.isTagUser()) {
        return (
          <li className={this.routeActive('applicant-tag-page') ? 'active' : ''}>
            <Link
              to="/applicant-tag-page"
              title={this.props.intl.formatMessage({
                id: 'Sidebar.applicantTagPage'
              })}
            >
              <small>
                <i className="fa fa-tag mr-2"></i>
                <FormattedMessage id="Sidebar.applicantTagPage" defaultMessage="Candidate Tags" />
              </small>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderSettingsCustomTest = () => {
      if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
        return (
          <li className={this.routeActive('custom-test') ? 'active' : ''}>
            <Link
              to="/custom-test"
              title={this.props.intl.formatMessage({
                id: 'Sidebar.settingsCustomTest'
              })}
            >
              <small>
                <i className="fa icon-calculator mr-2"></i>
                <FormattedMessage id="Sidebar.settingsCustomTest" defaultMessage="Forms" />
              </small>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderSettingsCareerPage = () => {
      if (UserProfile.isAdmin() || UserProfile.isPageAdmin()) {
        return (
          <li className={this.routeActive('company-page') ? 'active' : ''}>
            <Link
              to="/company-page"
              title={this.props.intl.formatMessage({
                id: 'Sidebar.settingsCareerPage'
              })}
            >
              <small>
                <i className="fa fa-newspaper mr-2"></i>
                <FormattedMessage id="Sidebar.settingsCareerPage" defaultMessage="Career Page" />
              </small>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderBlocklistUserAccount = () => {
      const { accountInfo } = this.props;
      if (
        accountInfo.accountInfo.blocklist_active &&
        (UserProfile.isAdmin() || UserProfile.isHRManager() || UserProfile.isRecruiter())
      ) {
        return (
          <li className={this.routeActive('Blocklist') ? 'active' : ''}>
            <Link
              to="/blocklist"
              title={this.props.intl.formatMessage({
                id: 'Sidebar.blockListUser'
              })}
            >
              <small>
                <i className="fa fa-lock mr-2"></i>
                <FormattedMessage id="Sidebar.blockListUser" />
              </small>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderSettingsUserAccount = () => {
      return (
        <li className={this.routeActive('settings') ? 'active' : ''}>
          <Link
            to="/settings"
            title={this.props.intl.formatMessage({
              id: 'Sidebar.settingsAccount'
            })}
          >
            <small>
              <i className="fa fa-cogs mr-2"></i>
              <FormattedMessage id="Sidebar.settingsAccount" defaultMessage="Account Settings" />
            </small>
          </Link>
        </li>
      );
    };

    const renderSettingsPipeline = () => {
      if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
        return (
          <li className={this.routeActive('job-pipeline') ? 'active' : ''}>
            <Link
              to="/job-pipeline"
              title={this.props.intl.formatMessage({ id: 'Sidebar.pipeline' })}
            >
              <small>
                <i className="fa icon-layers mr"></i>
                <FormattedMessage id="Sidebar.pipeline" defaultMessage="Manage Pipelines" />
              </small>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderSettings = () => {
      return (
        <li
          className={
            this.routeActive([
              'company-tree',
              'job-pipeline',
              'custom-test',
              'company-page',
              // 'tag-page',
              'settings'
            ])
              ? 'active'
              : ''
          }
        >
          <div
            className="nav-item"
            title={this.props.intl.formatMessage({ id: 'Sidebar.settings' })}
            onClick={this.toggleItemCollapse.bind(this, 'settings')}
          >
            <em className="icon-settings"></em>
            <span>
              <FormattedMessage id="Sidebar.settings" defaultMessage="Settings" />
            </span>
          </div>
          <Collapse isOpen={this.state.collapse.settings}>
            <ul id="#" className="sidebar-nav sidebar-subnav">
              <li className="sidebar-subnav-header">
                <FormattedMessage id="Sidebar.settings" defaultMessage="Settings" />
              </li>
              {renderSettingsCustomTest()}
              {renderSettingsPipeline()}
              {renderSettingsCareerPage()}
              {renderSettingsCompanyTree()}
              {renderSettingsJobTagPage()}
              {renderSettingsApplicantTagPage()}
              {renderBlocklistUserAccount()}
              {renderSettingsUserAccount()}
            </ul>
          </Collapse>
        </li>
      );
    };

    const renderJobPositions = () => {
      if (
        UserProfile.isAdmin() ||
        UserProfile.isHRManager() ||
        UserProfile.isRecruiter() ||
        UserProfile.isJobManager()
      ) {
        let count = 0;
        if (planInfo.plan_jobs && planInfo.plan_jobs.count) {
          count = planInfo.plan_jobs.count;
        }

        return (
          <li className={this.routeActive(['jobs', 'job/add', 'closed-jobs']) ? 'active' : ''}>
            <Link to="/jobs" title={this.props.intl.formatMessage({ id: 'Sidebar.jobs' })}>
              <div className="float-right badge badge-primary">{count}</div>
              <em className="fa icon-bag"></em>
              <span>
                <FormattedMessage id="Sidebar.jobs" defaultMessage="Job Positions" />
              </span>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderJobReviews = () => {
      if (UserProfile.isAdmin() || UserProfile.isReviewer()) {
        return (
          <li className={this.routeActive('job-review') ? 'active' : ''}>
            <Link
              to="/job-review"
              title={this.props.intl.formatMessage({
                id: 'Sidebar.jobsReviewPage'
              })}
            >
              {jobsReviewCount ? (
                <div className="float-right badge badge-primary">{jobsReviewCount}</div>
              ) : null}
              <em className="fa">
                <StyledRateReviewOutlinedIcon active={this.routeActive('job-review')} />
              </em>

              <span>
                <FormattedMessage id="Sidebar.jobsReviewPage" defaultMessage="Jobs Review" />
              </span>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderTalentSearch = () => {
      if (UserProfile.isAdmin() || UserProfile.isRecruiter() || UserProfile.isHRManager()) {
        return (
          <li className={this.routeActive('search') ? 'active' : ''}>
            <Link
              to="/search"
              title={this.props.intl.formatMessage({
                id: 'ModalSourcing.title'
              })}
              style={{
                padding: '12px 20px'
              }}
            >
              <em className="fa">
                <SearchIcon active={this.routeActive('search')} />
              </em>

              <span
                style={{
                  marginLeft: '4px'
                }}
              >
                <FormattedMessage id="ModalSourcing.title" />
              </span>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderJobRequests = () => {
      if (this.state.plan_type.startsWith(CONST.PLAN.ENTERPRISE)) {
        if (this.state.use_approval_flow === true) {
          if (
            UserProfile.isAdmin() ||
            UserProfile.isHRManager() ||
            UserProfile.isApprover() ||
            UserProfile.isRequester()
          ) {
            return (
              <li className={this.routeActive(['job-requests', 'job-request/add']) ? 'active' : ''}>
                <Link
                  to="/job-requests"
                  title={this.props.intl.formatMessage({
                    id: 'Sidebar.jobRequests'
                  })}
                >
                  <div className="float-right badge badge-primary">{openRequestsCount}</div>
                  <em className="fa icon-note"></em>
                  <span>
                    <FormattedMessage id="Sidebar.jobRequests" defaultMessage="Job Requests" />
                  </span>
                </Link>
              </li>
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return (
          <li className={this.routeActive(['job-requests', 'job-request/add']) ? 'active' : ''}>
            <a
              href="http://recrut.rds.land/fluxo-de-aprovacao"
              target="_blank"
              title={this.props.intl.formatMessage({
                id: 'Sidebar.jobRequests_noPlan'
              })}
              rel="noreferrer"
            >
              {/* <div className="float-right label label-success">
                <i className="fa fa-lock"></i>
              </div> */}
              <div className="float-right badge badge-success" style={{ marginLeft: '10px' }}>
                <i className="fa fa-lock"></i>
              </div>
              <em className="fa icon-note"></em>

              <span>
                <FormattedMessage id="Sidebar.jobRequests" defaultMessage="Job Requests" />
              </span>
            </a>
          </li>
        );
      }
    };

    const renderJobProfiles = () => {
      if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
        return (
          <li
            className={
              this.routeActive(['job-profiles', 'job-profiles/add', 'job-profiles/edit'])
                ? 'active'
                : ''
            }
          >
            <Link
              to="/job-profiles"
              title={this.props.intl.formatMessage({
                id: 'Sidebar.jobProfiles'
              })}
            >
              <em className="fa icon-book-open"></em>
              <span>
                <FormattedMessage id="Sidebar.jobProfiles" defaultMessage="Job Profiles" />
              </span>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderFavorites = () => {
      if (UserProfile.isAdmin() || UserProfile.isHRManager() || UserProfile.isRecruiter()) {
        return (
          <li className={this.routeActive(['favorites']) ? 'active' : ''}>
            <Link
              to="/favorites"
              title={this.props.intl.formatMessage({ id: 'Sidebar.favorites' })}
            >
              <em className="fa icon-star"></em>
              <span>
                <FormattedMessage id="Sidebar.favorites" defaultMessage="Favorites" />
              </span>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderAccountDashboard = () => {
      if (UserProfile.isAdmin() || UserProfile.isHRManager() || UserProfile.isRecruiter()) {
        return (
          <li className={this.routeActive(['account-dashboard']) ? 'active' : ''}>
            <Link
              to="/account-dashboard"
              title={this.props.intl.formatMessage({ id: 'Sidebar.accountDashboard' })}
            >
              <em className="fa icon-graph"></em>
              <span>
                <FormattedMessage
                  id="Sidebar.accountDashboard"
                  defaultMessage="Account Dashboard"
                />
              </span>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderJobReports = () => {
      return (
        <li className={this.routeActive(['reports']) ? 'active' : ''}>
          <Link to="/job-reports" title={this.props.intl.formatMessage({ id: 'Sidebar.reports' })}>
            <small>
              <i className="fa icon-chart mr-2"></i>
              <FormattedMessage id="Sidebar.jobReports" defaultMessage="Job Reports" />
            </small>
          </Link>
        </li>
      );
    };

    const renderDiversityReports = () => {
      if (UserProfile.isAdmin() || UserProfile.isDiversity()) {
        return (
          <li className={this.routeActive(['']) ? 'active' : ''}>
            <Link
              to="/diversity-reports"
              title={this.props.intl.formatMessage({ id: 'Sidebar.diversityReports' })}
            >
              <small>
                <i className="fa icon-chart mr-2"></i>
                <FormattedMessage
                  id="Sidebar.diversityReports"
                  defaultMessage="Diversity Reports"
                />
              </small>
            </Link>
          </li>
        );
      } else {
        return (
          <li className={this.routeActive(['']) ? 'active' : ''}>
            <a className="disabled-link">
              <small>
                <i className="fa icon-chart mr-2"></i>
                <FormattedMessage
                  id="Sidebar.diversityReports"
                  defaultMessage="Diversity Reports"
                />
              </small>
            </a>
          </li>
        );
      }
    };

    const renderAccountReports = () => {
      if (
        UserProfile.isAdmin() ||
        UserProfile.isHRManager() ||
        UserProfile.isRecruiter() ||
        UserProfile.isDiversity()
      ) {
        return (
          <li className={this.routeActive(['']) ? 'active' : ''}>
            <div
              className="nav-item"
              title={this.props.intl.formatMessage({ id: 'Sidebar.reports' })}
              onClick={this.toggleItemCollapse.bind(this, 'reports')}
            >
              <em className="fa icon-chart"></em>
              <span>
                <FormattedMessage id="Sidebar.reports" defaultMessage="Reports" />
              </span>
            </div>
            <Collapse isOpen={this.state.collapse.reports}>
              <ul id="#" className="sidebar-nav sidebar-subnav">
                <li className="sidebar-subnav-header">
                  <FormattedMessage id="Sidebar.reports" defaultMessage="Reports" />
                </li>
                {renderJobReports()}
                {renderDiversityReports()}
              </ul>
            </Collapse>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderHiredList = () => {
      if (
        UserProfile.isAdmin() ||
        UserProfile.isHRManager() ||
        UserProfile.isRecruiter() ||
        UserProfile.isJobManager()
      ) {
        return (
          <li className={this.routeActive(['']) ? 'active' : ''}>
            <Link to="/hired-list" title={this.props.intl.formatMessage({ id: 'Sidebar.Hired' })}>
              <small>
                <em className="fa fa icon-trophy mr-2"></em>
                <FormattedMessage id="Sidebar.Hired" defaultMessage="Hired" />
              </small>
            </Link>
          </li>
        );
      } else {
        return (
          <li className={this.routeActive(['']) ? 'active' : ''}>
            <a className="disabled-link">
              <small>
                <i className="fa icon-chart mr-2"></i>
                <FormattedMessage id="Sidebar.Hired" />
              </small>
            </a>
          </li>
        );
      }
    };

    const renderReferralList = () => {
      if (
        UserProfile.isAdmin() ||
        UserProfile.isHRManager() ||
        UserProfile.isRecruiter() ||
        UserProfile.isJobManager()
      ) {
        return (
          <li className={this.routeActive(['']) ? 'active' : ''}>
            <Link
              to="/referral-list"
              title={this.props.intl.formatMessage({ id: 'Sidebar.Referral' })}
            >
              <small>
                <i className="far fa-address-book mr-2"></i>
                <FormattedMessage id="Sidebar.Referral" defaultMessage="Referrals" />
              </small>
            </Link>
          </li>
        );
      } else {
        return (
          <li className={this.routeActive(['']) ? 'active' : ''}>
            <a className="disabled-link">
              <small>
                <i className="fa icon-chart mr-2"></i>
                <FormattedMessage id="Sidebar.Referral" />
              </small>
            </a>
          </li>
        );
      }
    };

    const renderApplicantInternalList = () => {
      if (
        UserProfile.isAdmin() ||
        UserProfile.isHRManager() ||
        UserProfile.isRecruiter() ||
        UserProfile.isJobManager()
      ) {
        return (
          <li className={this.routeActive(['']) ? 'active' : ''}>
            <Link
              to="/internals-list"
              title={this.props.intl.formatMessage({ id: 'Sidebar.ApplicantInternalList' })}
            >
              <small>
                <i className="far fa-address-card mr-2"></i>
                <FormattedMessage id="Sidebar.ApplicantInternalList" defaultMessage="Internal" />
              </small>
            </Link>
          </li>
        );
      } else {
        return (
          <li className={this.routeActive(['']) ? 'active' : ''}>
            <a className="disabled-link">
              <small>
                <em className="far fa-user  mr-2"></em>
                <FormattedMessage id="Sidebar.ApplicantInternalList" />
              </small>
            </a>
          </li>
        );
      }
    };

    const renderAccountApplicants = () => {
      if (
        UserProfile.isAdmin() ||
        UserProfile.isHRManager() ||
        UserProfile.isRecruiter() ||
        UserProfile.isJobManager()
      ) {
        return (
          <li className={this.routeActive(['']) ? 'active' : ''}>
            <div
              className="nav-item"
              title={this.props.intl.formatMessage({ id: 'Sidebar.Candidates' })}
              onClick={this.toggleItemCollapse.bind(this, 'applicants')}
            >
              <em className="far fa-user"></em>
              <span>
                <FormattedMessage id="Sidebar.Candidates" defaultMessage="Candidates" />
              </span>
            </div>
            <Collapse isOpen={this.state.collapse.applicants}>
              <ul id="#" className="sidebar-nav sidebar-subnav">
                <li className="sidebar-subnav-header">
                  <FormattedMessage id="Sidebar.Candidates" />
                </li>
                {renderHiredList()}
                {renderReferralList()}
                {renderApplicantInternalList()}
              </ul>
            </Collapse>
          </li>
        );
      } else {
        return null;
      }
    };

    const renderInterviewsList = () => {
      if (
        UserProfile.isAdmin() ||
        UserProfile.isHRManager() ||
        UserProfile.isRecruiter() ||
        UserProfile.isJobManager()
      ) {
        return (
          <li className={this.routeActive(['interview-list']) ? 'active' : ''}>
            <Link
              to="/interview-list"
              title={this.props.intl.formatMessage({ id: 'Sidebar.ApplicantsInListInterview' })}
            >
              <em className="far fa-calendar"></em>
              <span>
                <FormattedMessage
                  id="Sidebar.ApplicantsInListInterview"
                  defaultMessage="Appointments"
                />
              </span>
            </Link>
          </li>
        );
      } else {
        return null;
      }
    };

    //multiple Tags pages
    // const renderJobTags = () => {
    //   return (
    //     <li className={this.routeActive(['tag-page']) ? 'active' : ''}>
    //       <Link to="/tag-page" title={this.props.intl.formatMessage({ id: 'Sidebar.JobTags' })}>
    //         <small>
    //           <i className="fa fa-tag mr-2"></i>
    //           <FormattedMessage id="Sidebar.JobTags" defaultMessage="Job Tags" />
    //         </small>
    //       </Link>
    //     </li>
    //   );
    // };

    // const renderAccountTags = () => {
    //   if (UserProfile.isAdmin() || UserProfile.isTagUser()) {
    //     return (
    //       <li className={this.routeActive(['tag-page']) ? 'active' : ''}>
    //         <div
    //           className="nav-item"
    //           title={this.props.intl.formatMessage({ id: 'Sidebar.TagPageManageTags' })}
    //           onClick={this.toggleItemCollapse.bind(this, 'tags')}
    //         >
    //           <em className="fa fa-tag"></em>
    //           <span>
    //             <FormattedMessage id="Sidebar.TagPageManageTags" defaultMessage="Manage Tags" />
    //           </span>
    //         </div>
    //         <Collapse isOpen={this.state.collapse.tags}>
    //           <ul id="#" className="sidebar-nav sidebar-subnav">
    //             <li className="sidebar-subnav-header">
    //               <FormattedMessage id="Sidebar.TagPageManageTags" defaultMessage="Manage Tags" />
    //             </li>
    //             {renderJobTags()}
    //           </ul>
    //         </Collapse>
    //       </li>
    //     );
    //   } else {
    //     return null;
    //   }
    // };

    return (
      <aside className="aside-container">
        {/* START Sidebar (left) */}
        <div className="aside-inner">
          <nav data-sidebar-anyclick-close="" className="sidebar">
            {/* START sidebar nav */}
            <ul className="sidebar-nav">
              {/* Iterates over all sidebar items */}
              <SidebarItemHeader>
                <span>
                  <FormattedMessage id="Sidebar.mainNavigation" defaultMessage="Main Navigation" />
                </span>
              </SidebarItemHeader>
              <SidebarItem isActive={this.routeActive('home')}>
                <Link
                  to="/home"
                  title={this.props.intl.formatMessage({
                    id: 'Sidebar.home'
                  })}
                >
                  <em className="icon-home"></em>
                  <span>
                    <FormattedMessage id="Sidebar.home" defaultMessage="Home" />
                  </span>
                </Link>
              </SidebarItem>

              {renderJobPositions()}

              {renderJobReviews()}

              {renderJobRequests()}

              {renderJobProfiles()}

              {/* {renderFavorites()} */}

              {renderTalentSearch()}

              {renderAccountReports()}
              {renderAccountApplicants()}
              {renderInterviewsList()}
              {renderAccountDashboard()}

              {/*}
                            <li className={ this.routeActive(['saved']) ? 'active' : '' }>
                                <Link to="/saved" title={this.props.intl.formatMessage({id: 'Sidebar.saved'})}>
                                    <em className="fa fa-heart-o"></em>
                                    <span>
                                        <FormattedMessage 
                                            id="Sidebar.saved"
                                            defaultMessage="Saved for Later"
                                        />
                                    </span>
                                </Link>
                            </li>
                            {*/}

              {renderSettings()}

              <li className={this.routeActive([]) ? 'active' : ''}>
                <a
                  href="https://meajuda.recrut.ai/hc/"
                  target="_kb"
                  title={this.props.intl.formatMessage({
                    id: 'Sidebar.help'
                  })}
                >
                  <em className="icon-question"></em>
                  <span>
                    <FormattedMessage id="Sidebar.help" defaultMessage="Help Center" />
                  </span>
                </a>
              </li>
              <li className={this.routeActive('logout') ? 'active' : ''}>
                <a
                  onClick={(e) => this.doLogout(e)}
                  title={this.props.intl.formatMessage({
                    id: 'Sidebar.logout'
                  })}
                >
                  <em className="icon-logout"></em>
                  <span>
                    <FormattedMessage id="Sidebar.logout" defaultMessage="Logout" />
                  </span>
                </a>
              </li>
            </ul>
            {/* END sidebar nav */}
          </nav>
        </div>
        {/* END Sidebar (left) */}
      </aside>
    );
  }
}

Sidebar.propTypes = {
  //showUserBlock: PropTypes.bool,
  actions: PropTypes.object,
  settings: PropTypes.object,
  planInfo: PropTypes.object,
  accountInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  openRequestsCount: state.requests.openRequestsCount,
  planInfo: state.planInfo.accountPlan,
  accountInfo: state.accountInfo
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Sidebar)));
