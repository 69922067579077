import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import * as CONST from '../../Common/constants';
import GitHubIcon from '@material-ui/icons/GitHub';

const messages = defineMessages({});

const useStyles = (theme) => ({
  displayTextLink: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium,
    fontWeight: 400
  }
});

class IconLinkedin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: props.url,
      size: props.size ? props.size : 24,
      textLink: props.textLink,
      // Internal State
      link: null,
      text: null,
      icon: null,
      displayTextLink: null,
      color: CONST.APP_COLORS['black']
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.setState(
        {
          url: this.props.url
        },
        () => this.setScrapURL()
      );
    }
  }

  componentWillMount() {
    this.setScrapURL();
  }

  setScrapURL = () => {
    const url = this.state.url;

    if (url) {
      let link = 'https://www.github.com/' + url;
      let icon = <GitHubIcon fontSize="small" />;
      let text = this.props.intl.formatMessage({ id: 'Pipeline.link_viewOnLinkedin' });
      let color = '#0072b1';
      let displayTextLink = 'git/' + url;

      this.setState({
        link: link,
        icon: icon,
        text: text,
        color: color,
        displayTextLink: displayTextLink
      });
    }
  };

  render() {
    const { classes } = this.props;

    const url = this.state.url;
    const text = this.state.text;
    const link = this.state.link;
    const icon = this.state.icon;
    const displayTextLink = this.state.displayTextLink;

    if (url) {
      if (this.state.textLink) {
        return (
          <a href={link} target={url}>
            <Typography className={`${classes.displayTextLink} text-primary`}>
              {displayTextLink}
            </Typography>
          </a>
        );
      }

      return (
        <Tooltip title={text} arrow>
          <a href={link} target={url}>
            {icon}
          </a>
        </Tooltip>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(IconLinkedin));
