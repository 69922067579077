import React from 'react';
import { injectIntl } from 'react-intl';
import * as CONST from '../../Common/constants';
import StageIcon from '../../Pipeline/StageIcon';
import Tooltip from '@material-ui/core/Tooltip';
import CardController from '../CardController';

class NumberScore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      score: CardController.formatScore(props.score),
      type: props.type,
      // Internal State
      color: CONST.APP_COLORS['info'],
      weight: 200
    };
  }

  componentWillMount() {
    const score = this.state.score;

    let color = CONST.APP_COLORS['info'];
    let weight = 200;

    if (score > -1 && score < 25) {
      color = CONST.APP_COLORS['danger'];
    } else if (score >= 25 && score < 50) {
      color = CONST.APP_COLORS['warning'];
    } else if (score >= 50 && score < 75) {
      color = CONST.APP_COLORS['info'];
    } else if (score >= 75) {
      color = CONST.APP_COLORS['success'];
      weight = 700;
    }

    this.setState({
      color: color,
      weight: weight
    });
  }

  render() {
    const score = this.state.score;
    const type = this.state.type;
    const color = this.state.color;
    const weight = this.state.weight;

    let stage_type;
    switch (type) {
      case 'cv_score':
        stage_type = CONST.STAGE_SCREENING;
        break;
      case 'interview_score':
        stage_type = CONST.STAGE_INTERVIEW;
        break;
      case 'videointerview_score':
        stage_type = CONST.STAGE_VIDEOINTERVIEW;
        break;
      case 'customtest_score':
        stage_type = CONST.STAGE_CUSTOMTEST;
        break;
      case 'review_score':
        stage_type = CONST.STAGE_REVIEW;
        break;
      case 'review_completed':
        stage_type = CONST.STAGE_REVIEW;
        break;
    }

    let text = '--';
    if (score > -1) {
      text = `${score}%`;
    }

    return (
      <>
        <div
          style={{
            fontSize: '110%',
            letterSpacing: 0,
            fontWeight: weight,
            color: color
          }}
        >
          {text}
        </div>
        {stage_type ? (
          <Tooltip
            placement="bottom"
            title={this.props.intl.formatMessage({
              id: 'ApplicantDetail.title_' + stage_type.toLowerCase()
            })}
            arrow
          >
            <span>
              <StageIcon type={stage_type} fontSize={16} color="disabled" />
            </span>
          </Tooltip>
        ) : null}
      </>
    );
  }
}

export default injectIntl(NumberScore);
