import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import * as CONST from '../../Common/constants';

const messages = defineMessages({});

class IconScrapProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.type,
      url: props.url,
      size: props.size ? props.size : 24,
      // Internal State
      link: null,
      text: null,
      icon: null,
      displayTextLink: null,
      color: CONST.APP_COLORS['black']
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.setState(
        {
          url: this.props.url
        },
        () => this.setScrapURL()
      );
    }
  }

  componentWillMount() {
    this.setScrapURL();
  }

  setScrapURL = () => {
    const type = this.state.type;
    const url = this.state.url;

    let link;
    let icon = 'fab fa-2x ';
    let text;
    let color;
    let displayTextLink;
    switch (type) {
      case 'GITHUB':
        link = 'https://github.com/' + url;
        icon = icon + 'fa-github';
        text = this.props.intl.formatMessage({ id: 'Pipeline.link_viewOnGithub' });
        displayTextLink = 'git/' + url;
        color = CONST.APP_COLORS[' gray-darker-medium '];
        break;
      case 'BEHANCE':
        link = 'https://www.behance.net/' + url;
        icon = icon + 'fa-behance';
        text = this.props.intl.formatMessage({ id: 'Pipeline.link_viewOnBehance' });
        displayTextLink = 'be/' + url;
        color = '#053eff'; // BEHANCE official color
        break;
    }

    this.setState({
      link: link,
      icon: icon,
      text: text,
      color: color,
      displayTextLink: displayTextLink
    });
  };

  render() {
    const type = this.state.type;
    const url = this.state.url;
    const text = this.state.text;
    const link = this.state.link;
    const icon = this.state.icon;
    const displayTextLink = this.state.displayTextLink;

    if (type && url) {
      return (
        <Tooltip title={text} arrow>
          <a href={link} target={url}>
            <i className={icon} style={{ fontSize: this.state.size, color: this.state.color }}></i>
          </a>
        </Tooltip>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(IconScrapProfile);
