import React, { useEffect } from 'react';
import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';
import * as CONST from '../Common/constants';

const useStyles = (theme) => ({
  paging: {
    '& div:nth-of-type(2)': {
      background: theme.palette.secondary.light,
      color: 'white'
    }
  }
});

export const applyCustomButtonColor = (buttonIndex = 0) => {
  const buttons = document.querySelectorAll('.custom-pager button');
  Array.prototype.slice.call(buttons, 1, buttons.length - 1).forEach((button) => {
    if (button.textContent - 1 === buttonIndex) {
      button.style.backgroundColor = CONST.APP_COLORS['primary'];
      button.style.color = CONST.APP_COLORS['white'];
      button.style.width = '22px';
      button.style.height = '22px';
    } else {
      // button.style = null;
      button.style.backgroundColor = '';
      button.style.color = '';
    }
  });
};

const PagingContainer = (props) => {
  const { currentPage } = props;

  useEffect(() => {
    applyCustomButtonColor(currentPage);
  }, [currentPage]);

  return <PagingPanel.Container {...props} className={`custom-pager`} />;
};

export default withStyles(useStyles)(PagingContainer);
