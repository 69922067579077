import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Api from '../Api/Api';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PublicIcon from '@material-ui/icons/Public';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import LoadingData from '../Layout/LoadingData';
import TextField from '@material-ui/core/TextField';
import UserStorage from '../Common/UserStorage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import AddressController from '../Common/AddressController';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = (theme) => ({
  menuRoot: {
    minWidth: 480,
    maxWidth: 640
  },
  list: {
    minWidth: 440,
    minHeight: 80,
    maxHeight: '50vh',
    flexGrow: 1,
    overflowY: 'auto',
    padding: '1.5rem 1rem 0 1rem'
  },
  mainButtonLabelContainer: {
    display: 'flex',
    width: '80%'
  },
  mainButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mainButton: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& .MuiButton-startIcon': {
      paddingLeft: '13px'
    }
  },
  mainButtonLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    marginBottom: '0'
  },
  recruitersLabel: {
    margin: '.25rem 0 .25rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textLabel: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '0.8125rem',
    fontWeight: '500'
  },
  filterLabel: {
    display: 'flex',
    marginBottom: '0.25rem'
  },
  filterLabelActive: {
    color: theme.palette.primary.main
  }
});

class ButtonFilterCountries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // State from parent
      selected: this.props.selected ? this.props.selected : [],
      // Internal State
      loadingCountries: false,
      countryList: [],
      users: [],
      currentSelected: [],
      selectedCountries: [],
      search: '',
      selectedCountry: undefined,
      // Menu
      openMenu: false,
      anchorEl: null
    };
  }

  componentDidMount() {
    if (this.state.selected && this.state.selected.length > 0) {
      this.loadCountries();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected && this.props.selected !== this.state.selected) {
      this.setState(
        {
          selected: this.props.selected
        },
        () => {
          if (this.state.selected && this.state.selected.length > 0) {
            this.loadCountries();
          }
        }
      );
    }
  }

  loadCountries() {
    this.setState(
      {
        loadingCountries: true
      },
      () => {
        let initialCountry = this.props.accountInfo.accountInfo.country;
        let api = new Api();
        let filter = {
          order: ['suggested DESC', 'name']
        };
        api.get('Countries', filter, (status, data) => {
          let countryList = [];
          data.forEach((country) => {
            countryList.push({ value: country.code, label: country.name });
          });

          let countryDisplay = undefined;
          let selectedCountry = this.state.selectedCountry;

          if (selectedCountry) {
            countryDisplay = countryList.find((country) => selectedCountry === country.value);
          } else {
            const countryIndex = countryList.findIndex(
              (country) => country.value === initialCountry
            );
            countryDisplay = countryIndex > -1 ? countryList[countryIndex] : undefined;
            selectedCountry = countryIndex > -1 ? countryList[countryIndex].value : undefined;
          }

          this.setState({
            loadingCountries: false,
            countryList: countryList,
            countryDisplay: countryDisplay,
            selectedCountry: selectedCountry
          });
        });
      }
    );
  }

  handleMenuOpen = (e) => {
    if (this.state.countryList.length === 0) {
      this.loadCountries();
    }

    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState(
      {
        anchorEl: null
      },
      () => {
        if (this.props.handlerUpdate) {
          this.props.handlerUpdate(this.state.selected ? this.state.selected : []);
        }
      }
    );
  };

  handleChangeSelectCountry = (event, selectedCountries) => {
    let filterCountries = [];

    selectedCountries.forEach((option) => {
      filterCountries.push(option.value);
    });

    this.setState({
      selected: filterCountries,
      selectedCountries: selectedCountries
    });
  };

  textFieldKeyDown = (e) => {
    e.stopPropagation();
  };

  render() {
    const { classes } = this.props;
    const selectedCountries = this.state.selectedCountries;
    const selected = this.state.selected;

    const displayLabel = () => {
      if (selected.length === 0) {
        return '';
      } else {
        if (selectedCountries.length === 0) {
          // Before loading users
          return '';
        }

        const countriesName =
          selectedCountries.length <= 5
            ? selectedCountries.map((country) => country.label).join(', ')
            : selectedCountries
                .slice(0, 5)
                .map((country) => country.label)
                .join(', ') + '...';

        return (
          <span className={classes.mainButtonLabelContainer}>
            <p className={classes.mainButtonLabel}>{countriesName}</p>
          </span>
        );
      }
    };

    return (
      <>
        <div className={classes.mainButtonContainer}>
          <Typography
            variant="subtitle1"
            className={`${
              this.state.selected.length > 0 ? classes.filterLabelActive : 'text-muted'
            } ${classes.filterLabel}`}
          >
            <FormattedMessage id="ButtonFilterCountries.label_filter" defaultMessage="Countries" />
          </Typography>
          <Button
            variant="outlined"
            size="small"
            color={this.state.selected.length > 0 ? 'primary' : 'secondary'}
            onClick={this.handleMenuOpen}
            className={classes.mainButton}
            startIcon={<PublicIcon />}
            endIcon={<ArrowDropDownIcon />}
            style={{
              height: this.props.size === 'large' ? 38 : 32
            }}
          >
            {displayLabel()}
          </Button>
        </div>

        <Menu
          id="action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
          disableEnforceFocus={true} // https://github.com/mui-org/material-ui-pickers/issues/1852
          className={classes.menuRoot}
        >
          <div className={classes.recruitersLabel}>
            <InputLabel id="duration-label" className={classes.textLabel}>
              <FormattedMessage id="ButtonFilterCountries.label_filter" />
            </InputLabel>
          </div>
          <Divider />

          <div className={classes.list}>
            {this.state.loading ? (
              <ListItem>
                <LoadingData />
              </ListItem>
            ) : (
              <Autocomplete
                id="country"
                fullWidth
                options={this.state.countryList}
                classes={{
                  option: classes.option
                }}
                multiple
                autoHighlight
                value={selectedCountries}
                onChange={this.handleChangeSelectCountry}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                renderOption={(option) => (
                  <React.Fragment>
                    <span>{AddressController.countryToFlag(option.value)}</span>
                    {option.label} ({option.value})
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={this.props.intl.formatMessage({
                      id: 'ButtonFilterRegion.placeholder_country'
                    })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loadingCountries ? (
                            <CircularProgress color="secondary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
                disabled={this.state.loadingCountries}
              />
            )}
          </div>
        </Menu>
      </>
    );
  }
}

ButtonFilterCountries.propTypes = {
  accountInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo
});

export default connect(
  mapStateToProps,
  null
)(injectIntl(withStyles(useStyles)(ButtonFilterCountries)));
