import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Hashtags from '../Hashtags';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
  heading: {
    fontSize: '1.25rem',
    fontWeight: 700
  },
  matchHeading: {
    color: theme.palette.success.main,
    lineHeight: '1'
  },
  scoreMatch: {
    fontSize: '2rem',
    fontWeight: 700
  },
  subHeading: {
    fontSize: '1.125rem',
    fontWeight: 500
  },
  cvElement: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium,
    fontWeight: 400
  }
});

class MatchInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      applicant: props.applicant
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }
  }

  render() {
    const { classes } = this.props;
    const applicant = this.state.applicant;
    return (
      <Box display="flex" alignItems="center">
        <Box display="flex">
          {applicant.job_distance ? (
            <>
              <Box mr={2}>
                <Typography className={`${classes.heading} ${classes.matchHeading}`}>
                  {`${parseFloat(applicant.job_distance).toFixed(1)}km`}
                </Typography>
                <Typography className={classes.cvElement}>
                  <FormattedMessage
                    id="MatchInfo.label_jobDistance"
                    defaultMessage="Job Distance"
                  />
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                style={{
                  alignSelf: 'center',
                  height: '2rem',
                  marginRight: '1rem'
                }}
              />
            </>
          ) : null}

          {applicant.cv_score ? (
            <Box mr={2}>
              <Typography className={`${classes.scoreMatch} ${classes.matchHeading}`}>
                {`${parseFloat(applicant.cv_score).toFixed(1)}%`}
              </Typography>

              <Typography className={classes.cvElement}>
                <FormattedMessage
                  id="CVProfileInfo.label_matchSection"
                  defaultMessage="Job Match"
                />
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box width="50%">
          <Hashtags hashtags={applicant.hashtags} />
        </Box>
      </Box>
    );
  }
}

export default injectIntl(withStyles(useStyles)(MatchInfo));
