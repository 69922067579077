import React from 'react';

class InstructionsResume extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const variant = this.props.variant;
    const size = this.props.size ? this.props.size : 22;

    return (
      <svg
        width="95"
        height="92"
        viewBox="0 0 95 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.0184 41.1945C21.0184 41.1945 33.4805 40.6954 36.1735 20.2104C38.565 2.0178 59.5794 10.2984 65.6538 17.5505C73.5228 26.9448 69.3281 43.1051 79.5075 45.1467C89.6869 47.1882 83.0396 69.8862 67.5878 67.7136C48.3663 65.011 53.0168 76.243 47.2466 80.997C43.1055 84.409 26.1253 81.1246 25.5594 68.7388C25.0832 58.3163 20.6868 58.3493 17.5706 57.1676C13.0763 55.4631 10.2444 43.1191 21.0184 41.1945Z"
          fill="#FF5A8A"
          fillOpacity="0.1"
        />
        <path
          d="M66.0719 28.8914C62.4944 27.7318 58.6282 29.693 57.4639 33.2852C56.3043 36.8627 58.2655 40.7289 61.8578 41.8932C65.45 43.0575 69.3061 41.077 70.466 37.4993C71.6258 33.9217 69.6496 30.0509 66.0719 28.8914ZM64.9924 32.2218C65.3929 32.3516 65.7457 32.5974 66.0063 32.9281C66.2668 33.2587 66.4233 33.6593 66.4559 34.079C66.4884 34.4987 66.3956 34.9186 66.1892 35.2854C65.9828 35.6523 65.6721 35.9496 65.2964 36.1396C64.9208 36.3296 64.4972 36.4038 64.0794 36.3528C63.6615 36.3017 63.2683 36.1277 62.9495 35.8528C62.6307 35.5779 62.4007 35.2145 62.2887 34.8088C62.1767 34.403 62.1877 33.9731 62.3204 33.5736C62.6786 32.4683 63.887 31.8636 64.9924 32.2218ZM62.2914 40.5552C61.1029 40.1719 60.0845 39.3874 59.4108 38.3359C60.6715 37.6195 61.9898 37.42 63.1823 37.8065C64.3749 38.1931 65.3447 39.118 65.9263 40.4476C64.7638 40.904 63.4789 40.942 62.2914 40.5552Z"
          fill="#979797"
        />
        <path
          d="M36.2952 61.4918C35.6738 61.809 35.1604 62.3033 34.82 62.9122C34.4795 63.5212 34.3273 64.2174 34.3825 64.9129C34.4378 65.6084 34.698 66.2719 35.1303 66.8194C35.5627 67.367 36.1476 67.7741 36.8113 67.9892C37.475 68.2043 38.1875 68.2178 38.8589 68.0279C39.5302 67.8381 40.1302 67.4534 40.5829 66.9225C41.0356 66.3917 41.3207 65.7385 41.4022 65.0456C41.4837 64.3528 41.3579 63.6513 41.0407 63.0299C40.6147 62.1973 39.8757 61.5678 38.986 61.2795C38.0964 60.9911 37.1287 61.0675 36.2952 61.4918ZM37.1168 63.1013C37.3104 63.0025 37.5289 62.9634 37.7447 62.989C37.9605 63.0146 38.1638 63.1037 38.3289 63.245C38.494 63.3863 38.6133 63.5734 38.6719 63.7827C38.7305 63.9919 38.7256 64.2139 38.6578 64.4203C38.5901 64.6268 38.4625 64.8085 38.2913 64.9424C38.1202 65.0762 37.9131 65.1562 37.6964 65.1722C37.4797 65.1882 37.2631 65.1395 37.0741 65.0323C36.8851 64.925 36.7323 64.764 36.6349 64.5698C36.5059 64.3108 36.4841 64.0114 36.5743 63.7365C36.6645 63.4616 36.8594 63.2333 37.1168 63.1012V63.1013ZM39.1726 67.1288C38.5989 67.4228 37.9408 67.5081 37.3111 67.3702C37.5098 66.6485 37.9181 66.0946 38.4946 65.8004C39.0711 65.5062 39.7626 65.4899 40.4601 65.7629C40.2023 66.3537 39.7472 66.8365 39.1726 67.1286V67.1288Z"
          fill="#757575"
        />
        <path
          d="M22.0075 39.1535C21.1499 35.9527 23.0493 32.6626 26.2501 31.805L46.1906 26.462C49.3914 25.6043 52.6815 27.5038 53.5391 30.7046L62.3019 63.4079C63.1596 66.6087 61.2601 69.8987 58.0593 70.7564L38.1188 76.0994C34.918 76.957 31.628 75.0576 30.7703 71.8568L22.0075 39.1535Z"
          fill="#BDBDBD"
        />
        <circle
          cx="30.1749"
          cy="39.6046"
          r="3.10894"
          transform="rotate(-15 30.1749 39.6046)"
          fill="white"
        />
        <rect
          x="28.5801"
          y="45.6646"
          width="23.3171"
          height="2.33171"
          rx="1"
          transform="rotate(-15 28.5801 45.6646)"
          fill="white"
        />
        <rect
          x="29.787"
          y="50.1689"
          width="23.3171"
          height="2.33171"
          rx="1"
          transform="rotate(-15 29.787 50.1689)"
          fill="white"
        />
        <rect
          x="30.994"
          y="54.6736"
          width="16.322"
          height="2.33171"
          rx="1"
          transform="rotate(-15 30.994 54.6736)"
          fill="white"
        />
        <rect
          x="33.408"
          y="63.6826"
          width="10.8813"
          height="2.33171"
          rx="1"
          transform="rotate(-15 33.408 63.6826)"
          fill="white"
        />
        <rect
          x="34.4784"
          y="37.647"
          width="15.5447"
          height="2.33171"
          rx="1"
          transform="rotate(-15 34.4784 37.647)"
          fill="white"
        />
        <rect
          x="34.615"
          y="68.1873"
          width="23.3171"
          height="2.33171"
          rx="1"
          transform="rotate(-15 34.615 68.1873)"
          fill="white"
        />
      </svg>
    );
  }
}

export default InstructionsResume;
