import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import generatePDF, { Margin } from 'react-to-pdf';
import Button from '@material-ui/core/Button';
import PersonalityReport from './PersonalityReport';
import Api from '../../../Api/Api';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';

const messages = defineMessages({});

const useStyles = (theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '0px !important',
      width: 30,
      height: 30
    }
  },
  hiddenLabel: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  iconButton: {
    display: 'none',
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }
});

class PrintCustomCV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant: props.applicant,
      applicant_id: props.applicant_id,
      loadInfo: props.loadInfo,
      // Internal state
      printing: false,
      loadingApplicantData: false
    };

    this.ref1 = React.createRef();
  }

  componentDidMount() {
    if (this.state.loadInfo) {
      this.loadApplicantInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }

    if (prevProps.applicant_id !== this.props.applicant_id) {
      this.setState({
        applicant_id: this.props.applicant_id
      });
    }
  }

  loadApplicantInfo() {
    this.setState(
      {
        loadingApplicantData: true
      },
      () => {}
    );
    let api = new Api();

    let applicantFilter = {
      where: {
        id: this.state.applicant_id
      },
      fields: ['name', 'id', 'job_id', 'person_id', 'avatar_50_path', 'gender'],
      include: [
        {
          relation: 'job',
          scope: {
            fields: ['code', 'id']
          }
        },
        {
          relation: 'person',
          scope: {
            fields: ['personality_profile', 'id']
          }
        }
      ]
    };

    api.get('Applicants', applicantFilter, (status, data) => {
      if (status < 400) {
        const applicant = this.state.applicant
          ? {
              ...data[0],
              personality_profile: data[0].person.personality_profile,
              ...this.state.applicant
            }
          : {
              ...data[0],
              personality_profile: data[0].person.personality_profile
            };
        this.setState({
          applicant: applicant,
          loadingApplicantData: false
        });
      }
    });
  }

  printCV = () => {
    if (this.state.applicant) {
      this.setState(
        {
          printing: true
        },
        () => {
          if (this.props.handleSetPrinting) {
            this.props.handleSetPrinting(this.state.printing);
          }
          const applicant = this.state.applicant;
          const job = applicant.job;
          const jobCode = job ? `[${job.code}] - ` : '';

          generatePDF(this.ref1, {
            filename: `${jobCode}${applicant.name}.pdf`,
            page: {
              // margin is in MM, default is Margin.NONE = 0
              margin: Margin.SMALL
            },

            overrides: {
              canvas: {
                imageTimeout: 10000,
                useCORS: true
              }
            }
          })
            .then(
              this.setState(
                {
                  printing: false
                },
                () => {
                  if (this.props.handleSetPrinting) {
                    setTimeout(() => {
                      this.props.handleSetPrinting(this.state.printing);
                    }, 300);
                  }
                }
              )
            )
            .catch((error) => {
              console.log('Error generating PDF:', error);
              this.setState(
                {
                  printing: false
                },
                () => {
                  if (this.props.handleSetPrinting) {
                    setTimeout(() => {
                      this.props.handleSetPrinting(this.state.printing);
                    }, 300);
                  }
                }
              );
            });
        }
      );
      // window.print()
    }
  };

  render() {
    const { classes } = this.props;
    const applicant = this.state.applicant;

    const printDetail = () => {
      return (
        <>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={this.printCV}
            disabled={this.state.printing || this.state.loadingApplicantData}
            startIcon={
              <PictureAsPdfOutlinedIcon
                className={classes.iconButton}
                fontSize="small"
                color="primary"
              />
            }
            classes={{
              root: classes.root
            }}
          >
            <span className={classes.hiddenLabel}>
              <FormattedMessage id="PrintCustomCV.saveCV" />{' '}
            </span>
          </Button>
          <div
            ref={this.ref1}
            style={{
              position: 'fixed',
              left: '-1000rem',
              maxWidth: '1052px'
            }}
          >
            <PersonalityReport applicant={applicant} variant="print" />
          </div>
        </>
      );
    };

    if (applicant) {
      return <>{printDetail()}</>;
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(PrintCustomCV));
