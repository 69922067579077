import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import InfoEmployeeDetail from './InfoEmployeeDetail';

const messages = defineMessages({
  label_internal: {
    id: 'ChipInternal.label_internal',
    defaultMessage: 'Employee'
  }
});

const useStyles = (theme) => ({
  generalChip: {
    background: theme.palette.gray.lighter,
    color: theme.palette.gray.darker,
    fontSize: '14px',
    fontWeight: 400
    // marginLeft: theme.spacing(1)
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
    borderRadius: '100px',
    color: theme.palette.gray.darker,
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  iconButtonStyle: {
    padding: 0,
    borderRadius: '100px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

class ChipInternal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Popover
      originAnchorEl: null
    };
  }

  handleOpenPopover = (e) => {
    this.setState({
      originAnchorEl: e.currentTarget
    });
  };

  handleClosePopover = () => {
    this.setState({
      originAnchorEl: null
    });
  };

  render() {
    const { classes } = this.props;
    const originDetailsOpen = Boolean(this.state.originAnchorEl);
    const employee_id = this.props.employee_id;
    const employee_email = this.props.employee_email;
    const internal_verified = this.props.internal_verified;

    const origin_msg = (
      <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
        {internal_verified ? (
          <CheckIcon
            style={{
              fontSize: '0.875rem',
              marginRight: '0.25rem'
            }}
          />
        ) : null}
        {this.props.intl.formatMessage({ id: 'ChipInternal.label_internal' })}
      </Box>
    );

    return (
      <Box display="flex">
        <IconButton
          className={classes.iconButtonStyle}
          color="default"
          onClick={(e) => this.handleOpenPopover(e)}
        >
          <Chip
            className={classes.generalChip}
            size="small"
            label={<span className={classes.labelWithIcon}>{origin_msg}</span>}
          />
        </IconButton>

        <Popover
          open={originDetailsOpen}
          anchorEl={this.state.originAnchorEl}
          onClose={this.handleClosePopover}
          PaperProps={{
            style: {
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }
          }}
        >
          <Box maxHeight="440px" minWidth="320px">
            <InfoEmployeeDetail
              internal_verified={internal_verified}
              employee_id={employee_id}
              employee_email={employee_email}
            />
          </Box>
        </Popover>
      </Box>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ChipInternal));
