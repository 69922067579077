// import icons
import IconOriginalCv from './IconOriginalCv';
import IconPersonalityReport from './IconPersonalityReport';
import IconFormPersonality from './IconFormPersonality';
import IconNewInterview from './IconNewInterview';
import IconNewInterviewApp from './IconNewInterviewApp';
import IconInterview from './IconInterview';
import IconExperience from './IconExperience';
import IconCustomTest from './IconCustomTest';
import IconEducation from './IconEducation';
import IconIdiom from './IconIdiom';

export {
  IconOriginalCv,
  IconPersonalityReport,
  IconFormPersonality,
  IconNewInterview,
  IconNewInterviewApp,
  IconInterview,
  IconExperience,
  IconCustomTest,
  IconEducation,
  IconIdiom
};
