import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import AccessibilityNewOutlinedIcon from '@material-ui/icons/AccessibilityNewOutlined';
import MobileFriendlyOutlinedIcon from '@material-ui/icons/MobileFriendlyOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import {
  IconInterview,
  IconExperience,
  IconCustomTest,
  IconEducation,
  IconIdiom
} from '../../Icons/Icons';

const messages = defineMessages({
  experiencete: {
    id: 'CVMenu.experience',
    defaultMessage: 'Experience'
  }
});

const useStyles = (theme) => ({
  menuButton: {
    background: theme.palette.gray.bgLighter,
    boxShadow: `0px 5px 10px ${theme.palette.gray.main}`,

    '&:hover': {
      background: theme.palette.gray.bgLighter
    },
    '&:active': {
      background: theme.palette.gray.bgLighter
    }
  },
  menuItemButton: {
    margin: theme.spacing(0.5),
    background: theme.palette.gray.lighter,
    color: `${theme.palette.gray.darkerMedium} !important`,
    boxShadow: `0px 5px 10px ${theme.palette.gray.main}`,
    '&:hover': {
      background: theme.palette.gray.bgLighter,
      color: `${theme.palette.primary.main} !important`
    },
    '&:active': {
      background: theme.palette.gray.bgLighter
    }
  },
  iconCustom: {
    color: `${theme.palette.gray.darkerMedium} !important`,
    '&:hover': {
      color: `${theme.palette.primary.main} !important`
    }
  },
  menu: {
    height: '100%',
    transition: 'all 1s ease-in-out'
  },
  closeMenu: {
    height: 0
  }
});

class CVMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: props.profile,
      // Internal State
      // Menu
      cvMenuOpen: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile !== this.props.profile) {
      this.setState({
        profile: this.props.profile
      });
    }
  }

  componentDidMount() {
    //console.log("mounted " + this.state.profile_id);
  }

  handleOpenMenu = () => {
    this.setState({
      cvMenuOpen: true
    });
  };

  handleCloseMenu = () => {
    this.setState({
      cvMenuOpen: false
    });
  };

  handleChangeSection = (currRef) => {
    if (this.props.handleChangeSection) {
      this.props.handleChangeSection(currRef);
    }
  };

  render() {
    const { classes } = this.props;

    const cvMenuOpen = this.state.cvMenuOpen;

    const profile = this.state.profile;
    const cv_profile = profile.cv_profile;

    let experiences = [];
    let educations = [];
    let certifications = [];
    let languages = [];
    let hasExperiences = false;
    let hasEducations = false;
    let hasCertifications = false;
    let hasLanguages = false;

    if (cv_profile) {
      experiences = cv_profile.experiences;
      educations = cv_profile.educations;
      certifications = cv_profile.certifications;
      languages = cv_profile.languages;

      hasExperiences = experiences && experiences.length > 0;

      hasEducations = educations && educations.length > 0;

      hasCertifications = certifications && certifications.length > 0;
      hasLanguages = languages && languages.length > 0;
    }

    const salary_info = profile.salary_info;
    const hasSalaryInfo = salary_info && Object.keys(salary_info).length > 0;

    const videoInterviews = profile.videoInterviews;
    const hasVideoInterviews = videoInterviews && videoInterviews.length > 0;

    const customTests = profile.tests;
    const hasCustomTests = customTests && customTests.length > 0;

    const forms = profile.forms;
    const hasForms = forms && forms.length > 0;

    const interviews = profile.interviews;
    const hasInterviews = interviews && interviews.length > 0;

    const hasDiversity =
      (profile.gender && profile.gender !== '') ||
      (profile.pwr && profile.pwr !== 'no') ||
      (profile.pso && profile.pso !== 'no');

    return (
      <SpeedDial
        id="mainButton"
        name="mainButton"
        classes={{
          fab: classes.menuButton,
          actions: `${classes.menu} ${cvMenuOpen ? '' : classes.closeMenu}`
        }}
        icon={cvMenuOpen ? <ExpandMoreIcon color="primary" /> : <DeviceHubIcon color="primary" />}
        ariaLabel="Menu speedial"
        open={cvMenuOpen}
        onOpen={this.handleOpenMenu}
        onClose={this.handleCloseMenu}
        hidden={false}
        direction="down"
      >
        <SpeedDialAction
          name="about"
          classes={{
            fab: classes.menuItemButton
          }}
          icon={<AccountBoxOutlinedIcon size="small" />}
          tooltipTitle={<FormattedMessage id="DetailSummary.about" />}
          onClick={() => this.handleChangeSection(1)}
        />
        {hasDiversity ? (
          <SpeedDialAction
            classes={{
              fab: classes.menuItemButton
            }}
            icon={<AccessibilityNewOutlinedIcon size="small" />}
            tooltipTitle={<FormattedMessage id="JobAllReport.table.diversity_field" />}
            onClick={() => this.handleChangeSection(2)}
          />
        ) : null}
        {hasInterviews ? (
          <SpeedDialAction
            classes={{
              fab: classes.menuItemButton
            }}
            icon={<IconInterview fontSize="small" className={classes.iconCustom} />}
            tooltipTitle={<FormattedMessage id="ApplicantDetail2.title_interview" />}
            onClick={() => this.handleChangeSection(6)}
          />
        ) : null}
        {hasVideoInterviews ? (
          <SpeedDialAction
            classes={{
              fab: classes.menuItemButton
            }}
            icon={<MobileFriendlyOutlinedIcon size="small" />}
            tooltipTitle={<FormattedMessage id="CVDetail.title_appInterviews" />}
            onClick={() => this.handleChangeSection(3)}
          />
        ) : null}
        {hasForms ? (
          <SpeedDialAction
            classes={{
              fab: classes.menuItemButton
            }}
            icon={<IconCustomTest fontSize="small" className={classes.iconCustom} />}
            tooltipTitle={<FormattedMessage id="CVDetail.title_forms" />}
            onClick={() => this.handleChangeSection(5)}
          />
        ) : null}
        {hasCustomTests ? (
          <SpeedDialAction
            classes={{
              fab: classes.menuItemButton
            }}
            icon={<DescriptionOutlinedIcon size="small" />}
            tooltipTitle={<FormattedMessage id="CVDetail.title_customTests" />}
            onClick={() => this.handleChangeSection(4)}
          />
        ) : null}
        {hasEducations ? (
          <SpeedDialAction
            classes={{
              fab: classes.menuItemButton
            }}
            icon={<IconEducation fontSize="small" className={classes.iconCustom} />}
            tooltipTitle={<FormattedMessage id="CardAcademicLevel2.title" />}
            onClick={() => this.handleChangeSection(7)}
          />
        ) : null}

        {hasExperiences ? (
          <SpeedDialAction
            classes={{
              fab: classes.menuItemButton
            }}
            icon={<IconExperience fontSize="small" className={classes.iconCustom} />}
            tooltipTitle={<FormattedMessage id="CVMenu.experience" />}
            onClick={() => this.handleChangeSection(8)}
          />
        ) : null}

        <SpeedDialAction
          classes={{
            fab: classes.menuItemButton
          }}
          icon={<AttachMoneyIcon size="small" />}
          tooltipTitle={<FormattedMessage id="SalaryInfoEdit.label_salaryExpectation" />}
          onClick={() => this.handleChangeSection(10)}
        />

        {hasCertifications ? (
          <SpeedDialAction
            classes={{
              fab: classes.menuItemButton
            }}
            icon={<BookOutlinedIcon fontSize="small" />}
            tooltipTitle={<FormattedMessage id="CVProfileInfo.label_certifications" />}
            onClick={() => this.handleChangeSection(9)}
          />
        ) : null}

        {hasLanguages ? (
          <SpeedDialAction
            classes={{
              fab: classes.menuItemButton
            }}
            icon={<IconIdiom fontSize="small" className={classes.iconCustom} />}
            tooltipTitle={<FormattedMessage id="ApplicantsSearch.field_languages" />}
            onClick={() => this.handleChangeSection(11)}
          />
        ) : null}
      </SpeedDial>
    );
  }
}

export default injectIntl(withStyles(useStyles)(CVMenu));
