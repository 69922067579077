import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

// See more loading icons here:
// https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons
const PageLoader = () => (
  <div className="page-loader">
    <CircularProgress color="secondary" />
  </div>
);

export default PageLoader;
