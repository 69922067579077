import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import UserProfile from '../../Common/UserProfile';
import Tooltip from '@material-ui/core/Tooltip';
import Api from '../../Api/Api';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import LoopIcon from '@material-ui/icons/Loop';
import * as CONST from '../../Common/constants';

const messages = defineMessages({
  firstFavoriteMsg: {
    id: 'ButtonFav.firstFavoriteMsg',
    defaultMessage: 'Star this candidate'
  },
  othersFavoriteMsg: {
    id: 'ButtonFav.othersFavoriteMsg',
    defaultMessage: 'Other users have starred this candidate.'
  },
  removeFavoriteMsg: {
    id: 'ButtonFav.removeFavoriteMsg',
    defaultMessage: 'Remove your star'
  }
});

class ButtonFav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      favorites: props.favorites,
      applicant_id: props.applicant_id,
      size: props.size ? props.size : 24,
      // Internal state
      _loading: false,
      userFav: false,
      otherFav: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.favorites !== this.props.favorites) {
      this.setState(
        {
          favorites: this.props.favorites
        },
        () => {
          this.loadFavs();
        }
      );
    }
  }

  componentWillMount() {
    this.loadFavs();
  }

  loadFavs() {
    const favorites = this.state.favorites;

    let userFav = false;
    let otherFav = false;

    // If not null or not empty
    if (favorites && favorites.length > 0) {
      const user_id = UserProfile.getId().toString();

      // User has favorited
      if (favorites.indexOf(user_id) > -1) {
        userFav = true;
      } else {
        // Others have favorited
        otherFav = true;
      }

      this.setState({
        userFav: userFav,
        otherFav: otherFav
      });
    } else {
      this.setState({
        userFav: userFav,
        otherFav: otherFav
      });
    }
  }

  onAddFav = () => {
    const applicant_id = this.state.applicant_id;
    this.setState(
      {
        _loading: true
      },
      () => {
        let api = new Api();
        const payload = {
          applicant_id: applicant_id
        };
        api.post('Applicants/add-favorite', payload, (status, data) => {
          if (status < 400) {
            let favorites = null;

            if (data.rows && data.rows.length > 0) {
              favorites = data.rows[0].favorites;
            }

            this.setState(
              {
                _loading: false,
                userFav: true,
                favorites: favorites
              },
              () => {
                if (this.props.handleUpdateFavorites) {
                  this.props.handleUpdateFavorites(this.state.favorites);
                }
              }
            );
          } else {
            console.log('Error on add favorite');
            this.setState({
              _loading: false
            });
          }
        });
      }
    );
  };

  onDelFav = () => {
    const applicant_id = this.state.applicant_id;
    this.setState(
      {
        _loading: true
      },
      () => {
        let api = new Api();
        const payload = {
          applicant_id: applicant_id
        };
        api.post('Applicants/remove-favorite', payload, (status, data) => {
          if (status < 400) {
            let favorites = null;

            if (data.rows && data.rows.length > 0) {
              favorites = data.rows[0].favorites;
            }
            this.setState(
              {
                _loading: false,
                userFav: false,
                favorites: favorites
              },
              () => {
                if (this.props.handleUpdateFavorites) {
                  this.props.handleUpdateFavorites(this.state.favorites);
                }
              }
            );
          } else {
            console.log('Error on remove favorite');
            this.setState({
              _loading: false
            });
          }
        });
      }
    );
  };

  render() {
    if (this.state._loading) {
      return (
        <LoopIcon style={{ fontSize: this.state.size }} color="disabled" className="fa-spin" />
      );
    } else if (
      UserProfile.isAdmin() ||
      UserProfile.isHRManager() ||
      UserProfile.isRecruiter() ||
      UserProfile.isJobManager()
    ) {
      const userFav = this.state.userFav;
      const otherFav = this.state.otherFav;

      let msg, icon;

      if (userFav === true) {
        msg = this.props.intl.formatMessage({
          id: 'ButtonFav.removeFavoriteMsg'
        });
        icon = (
          <StarIcon
            fontSize="small"
            style={{
              color: CONST.APP_COLORS['warning-dark'],
              cursor: 'pointer',
              fontSize: this.state.size
            }}
            onClick={() => this.onDelFav()}
          />
        );
      } else if (otherFav === true) {
        msg = this.props.intl.formatMessage(messages.othersFavoriteMsg);
        icon = (
          <StarHalfIcon
            fontSize="small"
            style={{
              color: CONST.APP_COLORS['warning-dark'],
              cursor: 'pointer',
              fontSize: this.state.size
            }}
            onClick={() => this.onAddFav()}
          />
        );
      } else {
        msg = this.props.intl.formatMessage({
          id: 'ButtonFav.firstFavoriteMsg'
        });
        icon = (
          <StarBorderIcon
            fontSize="small"
            style={{
              cursor: 'pointer',
              fontSize: this.state.size
            }}
            onClick={() => this.onAddFav()}
          />
        );
      }

      return (
        <Tooltip title={msg} arrow>
          {icon}
        </Tooltip>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(ButtonFav);
