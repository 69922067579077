import React from 'react';

class RecrutAIIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <svg
        width="14"
        height="17"
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.83787 15.2643C8.62235 15.2643 7.63427 12.2622 7.63427 8.55964C7.63427 4.85703 8.62235 1.85492 9.83787 1.85492C10.2613 1.85492 10.6613 2.22441 10.9985 2.85562C10.3868 1.2468 9.51635 0.246094 8.54393 0.246094C6.68538 0.246094 5.17188 3.9718 5.17188 8.56734C5.17188 13.1629 6.67753 16.8886 8.54393 16.8886C9.50851 16.8886 10.379 15.8802 10.9985 14.2791C10.6613 14.9103 10.2692 15.2797 9.83787 15.2797V15.2643Z"
          fill="black"
          fillOpacity="0.54"
        />
        <path
          d="M1.13709 8.55834C1.13709 5.80256 2.51728 3.36238 4.63464 1.86133C1.92129 2.91592 0 5.51774 0 8.55834C0 11.599 1.92129 14.2008 4.63464 15.2554C2.51728 13.7543 1.13709 11.3141 1.13709 8.55834Z"
          fill="black"
          fillOpacity="0.54"
        />
        <path
          d="M13.1296 12.7014C12.4552 12.7014 11.8984 10.8463 11.8984 8.56006C11.8984 6.27384 12.4474 4.41869 13.1296 4.41869C13.4668 4.41869 13.7805 4.88825 14.0001 5.64262C13.6237 3.69511 12.8943 2.37109 12.0474 2.37109C10.8241 2.37109 9.82812 5.14227 9.82812 8.56776C9.82812 11.9933 10.8241 14.7644 12.0474 14.7644C12.8943 14.7644 13.6315 13.4404 14.0001 11.4929C13.7805 12.2473 13.4668 12.7168 13.1296 12.7168V12.7014Z"
          fill="black"
          fillOpacity="0.54"
        />
      </svg>
    );
  }
}

export default RecrutAIIcon;
