import React from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CallIcon from '@material-ui/icons/Call';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import Link from '@material-ui/core/Link';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Typography from '@material-ui/core/Typography';
import * as CONST from '../../Common/constants';

const messages = defineMessages({});

const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(2)
  },
  margin: {
    margin: theme.spacing(1)
  },
  mainContainer: {
    padding: `0 ${theme.spacing(1.5)}px`
  },
  iconActionContainer: {
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}10`
    }
  },
  applicantPhone: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px'
  },
  actionsLabel: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    textAlign: 'center'
  },
  phoneIcon: {
    color: theme.palette.gray.darkerMedium
  },
  actionContainer: {
    padding: `0 ${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconButtonContainer: {
    padding: theme.spacing(1),
    color: theme.palette.gray.darkerRegular,
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
});

class IconPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: props.phone,
      size: props.size ? props.size : 24,
      variant: props.variant,
      // Internal State
      copied: false,
      // Popover
      anchorEl: null
    };
  }

  handleOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { classes } = this.props;

    const variant = this.state.variant;

    const phone = this.state.phone;
    let whatsapp;
    if (phone) {
      whatsapp = phone.replace(/\D/g, ''); // https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/?lang=kk
    }

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'phone-popover' : undefined;

    return phone ? (
      <>
        {variant === 'cv' ? (
          <IconButton onClick={(e) => this.handleOpen(e)} className={classes.iconButtonContainer}>
            <CallIcon
              style={{
                cursor: 'pointer',
                fontSize: this.state.size
              }}
            />
          </IconButton>
        ) : (
          <CallIcon
            onClick={(e) => this.handleOpen(e)}
            style={{
              cursor: 'pointer',
              fontSize: this.state.size,
              color: CONST.APP_COLORS['gray-darker-regular']
            }}
          />
        )}

        <Popover id={id} open={open} anchorEl={this.state.anchorEl} onClose={this.handleClose}>
          <Box className={classes.mainContainer}>
            <>
              <Box width="100%" py={1.5} px={2} className="text-center">
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Box mr={0.5}>
                    <CallIcon className={classes.phoneIcon} fontSize="small" />
                  </Box>

                  <Box>
                    <Typography className={classes.applicantPhone}>{phone}</Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="center" py={3}>
                <Box className={classes.actionContainer}>
                  <IconButton
                    className={classes.iconActionContainer}
                    component={Link}
                    href={'tel:' + phone}
                    target="_tel"
                  >
                    <CallIcon color="primary" fontSize="large" />
                  </IconButton>
                  <Box maxWidth={50}>
                    <Typography className={classes.actionsLabel}>
                      <FormattedMessage id="IconPhone.callPhone" defaultMessage="Call now" />
                    </Typography>
                  </Box>
                </Box>

                <Divider orientation="vertical" flexItem />

                <Box className={classes.actionContainer}>
                  <IconButton
                    className={classes.iconActionContainer}
                    component={Link}
                    href={'https://wa.me/' + whatsapp}
                    target="_whatsapp"
                  >
                    <WhatsAppIcon color="primary" fontSize="large" />
                  </IconButton>
                  <Box maxWidth={50}>
                    <Typography className={classes.actionsLabel}>
                      <FormattedMessage
                        id="IconPhone.whatsappPhone"
                        defaultMessage="Open in WhatsApp"
                      />
                    </Typography>
                  </Box>
                </Box>

                <Divider orientation="vertical" flexItem />

                <Box className={classes.actionContainer}>
                  <CopyToClipboard text={phone} onCopy={() => this.setState({ copied: true })}>
                    <IconButton className={classes.iconActionContainer}>
                      <FileCopyOutlinedIcon color="primary" fontSize="large" />
                    </IconButton>
                  </CopyToClipboard>

                  <Box maxWidth={50}>
                    <Typography className={classes.actionsLabel}>
                      <FormattedMessage id="IconPhone.copyPhone" defaultMessage="Copy number" />
                    </Typography>
                  </Box>

                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    open={this.state.copied}
                    autoHideDuration={3000}
                    onClose={() => this.setState({ copied: false })}
                    message={
                      <FormattedMessage
                        id="IconPhone.copy_confirmed"
                        defaultMessage="Number successfully copied"
                      />
                    }
                  />
                </Box>
              </Box>
            </>
          </Box>
        </Popover>
      </>
    ) : null;
  }
}

export default injectIntl(withStyles(useStyles)(IconPhone));
