import $ from 'jquery';

export default () => {
  const MAX = 25;

  $('p.note-text').linkify({
    target: '_blank',
    format: function (value, type) {
      if (type === 'url' && value.length > MAX) {
        value = value.slice(0, MAX) + '…';
      }
      return value;
    }
  });
};
