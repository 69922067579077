import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import Api from '../Api/Api';
import * as CONST from '../Common/constants';
import ApplicantAvatar from '../Layout/ApplicantAvatar';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InfoDateCreated from '../Applicant/Card/InfoDateCreated';
import InfoDateHunted from '../Applicant/Card/InfoDateHunted';
import InfoAddress from '../Applicant/Card/InfoAddress';
import InfoAge from '../Applicant/Card/InfoAge';
import ChipOrigin from '../Applicant2/ApplicantCard/ChipOrigin';
import DetailLocation from '../Applicant/Detail/DetailLocation';
import IconEmail from '../Applicant/Card/IconEmail';
import IconPhone from '../Applicant/Card/IconPhone';
import InfoGender from '../Applicant/Card/InfoGender';
import InfoPwd from '../Applicant/Card/InfoPwd';
import InfoPso from '../Applicant/Card/InfoPso';
import InfoPwr from '../Applicant/Card/InfoPwr';
import IconScrapProfile from '../Applicant/Card/IconScrapProfile';
import IconLastJobChange from '../Applicant/Card/IconLastJobChange';
import IconInternal from '../Applicant/Card/IconInternal';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import InfoNameWarning from '../Applicant/Card/InfoNameWarning';
import DetailSummary from '../Applicant/Detail/DetailSummary';
import LoadingData from '../Layout/LoadingData';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import StageController from '../Pipeline/StageController';

const useStyles = (theme) => ({
  divIcons: {
    display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const baseFilters = CONST.getApplicantDetailBaseFields();

class ApplicantDetailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      // Internal state
      job_id: props.job_id,
      pipeline: props.pipeline,
      applicant_id: props.applicant_id,
      applicantObj: props.applicantObj ? props.applicantObj : null,
      // Internal State
      _loadingBaseInfo: false,
      baseInfo: {
        id: '',
        job_id: '',
        name: '',
        internal: '',
        internal_verified: '',
        employee_id: '',
        avatar_50_path: '',
        email: '',
        verified_email: '',
        age: 0,
        gender: '',
        phone_mobile: '',
        origin: '',
        // Diversity
        pwd: '',
        pso: '',
        pwr: '',
        zip: '',
        address: {},
        distance_job: 0,
        pipeline_stage: '',
        date_created: '',
        job: {
          address: {},
          address_point: null,
          status: '',
          review_config: null,
          has_review_stage: false,
          reviewers: [],
          academic_formation: [],
          experience: [],
          idiom: [],
          skill: [],
          industry: [],
          rank_version: 'v2'
        },
        favorites: [],
        notes: [],
        views: [],
        notesCount: 0,
        cv_match_name: 1,
        referer: null,
        hire_status: -1,
        cv_text_areas: null
      },
      distance_perc: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pipeline !== this.props.pipeline) {
      this.setState({
        pipeline: this.props.pipeline
      });
    }

    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open
      });
    }
  }

  componentDidMount() {
    const applicantObj = this.state.applicantObj;
    if (applicantObj) {
      // Make sure that baseInfo has all fields accessed later
      const baseInfo = {
        ...this.state.baseInfo,
        applicantObj
      };
      this.setState(
        {
          baseInfo: baseInfo
        },
        () => {
          this.loadBaseInfo(); // Reload anyway
        }
      );
    } else {
      this.loadBaseInfo();
    }
  }

  loadBaseInfo() {
    this.setState(
      {
        _loadingBaseInfo: true
      },
      () => {
        const applicant_id = this.state.applicant_id;

        let api = new Api();

        let filter = {
          ...baseFilters,
          where: {
            id: applicant_id
          }
        };
        api.get('Applicants', filter, (status, data) => {
          const { emailBlocklists, docBlocklists } = data[0];
          let validateBlock = false;
          const activeBlockValidation =
            (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
            (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0);

          if (activeBlockValidation) {
            validateBlock = true;
          }
          // Counting one more view
          this.setState(
            {
              baseInfo: {
                ...data[0]
              },
              applicantObj: {
                ...this.state.applicantObj,
                isBlocked: validateBlock
              },
              _loadingBaseInfo: false
            },
            () => {
              if (this.props.handlerUpdate) {
                this.props.handlerUpdate(this.state.baseInfo);
              }
            }
          );
        });
      }
    );
  }

  closeDialog = () => {
    this.setState(
      {
        open: false
      },
      () => {
        if (this.props.handlerClose) {
          this.props.handlerClose();
        }
      }
    );
  };

  render() {
    const { classes } = this.props;

    const baseInfo = this.state.baseInfo;

    return (
      <>
        <Dialog fullWidth maxWidth="md" open={this.state.open} onClose={this.closeDialog}>
          <DialogTitle>
            <FormattedMessage
              id="ApplicantDetailDialog.dialog_title"
              defaultMessage="Candidate info"
            />
            <IconButton className={classes.closeButton} onClick={this.closeDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Row>
              <Col md={12}>
                <Paper
                  elevation={2}
                  style={{
                    marginBottom: '20px'
                  }}
                >
                  <Box display="flex" alignItems="center" p={1}>
                    <Box minWidth={75} mr={1}>
                      {this.state._loadingBaseInfo ? (
                        <Skeleton variant="circle" animation="wave" width={70} height={70} />
                      ) : (
                        <ApplicantAvatar
                          name={baseInfo.name}
                          src={baseInfo.avatar_50_path}
                          size={65}
                          emailBlocklists={baseInfo.emailBlocklists}
                          docBlocklists={baseInfo.docBlocklists}
                        />
                      )}
                    </Box>

                    <Box width="60%" mr={2}>
                      <Box display="flex" alignItems="center" width="100%">
                        <Box maxWidth={250} mr={2}>
                          <Typography
                            variant="h5"
                            component="div"
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                          >
                            {this.state._loadingBaseInfo ? (
                              <Skeleton variant="text" animation="wave" width={150} />
                            ) : (
                              baseInfo.name
                            )}
                          </Typography>
                        </Box>
                      </Box>

                      <p className="text-muted">
                        <small>
                          <Box display="flex" alignItems="center" width="100%" mt={1}>
                            <Box mr={1}>
                              {this.state._loadingBaseInfo ? (
                                <Skeleton variant="rect" animation="wave" width={100} height={20} />
                              ) : baseInfo.origin === CONST.ORIGIN_HUNT ||
                                baseInfo.origin === CONST.ORIGIN_SCRAP ? (
                                <InfoDateHunted date={baseInfo.date_created} />
                              ) : (
                                <InfoDateCreated date={baseInfo.date_created} />
                              )}
                            </Box>
                            <Box mr={1}>
                              {this.state._loadingBaseInfo ? (
                                <Skeleton variant="rect" animation="wave" width={100} height={20} />
                              ) : (
                                <InfoAddress
                                  applicant_address={baseInfo.address}
                                  job_address={baseInfo.job ? baseInfo.job.address : {}}
                                />
                              )}
                            </Box>
                            <Box mr={1}>
                              {this.state._loadingBaseInfo ? (
                                <Skeleton variant="rect" animation="wave" width={50} height={20} />
                              ) : (
                                <InfoAge age={baseInfo.age} />
                              )}
                            </Box>
                          </Box>
                        </small>
                      </p>
                    </Box>

                    <Divider orientation="vertical" flexItem />

                    <Box width="40%" ml={2} mr={2}>
                      <div className={classes.divIcons}>
                        {this.state._loadingBaseInfo ? (
                          <>
                            <Skeleton variant="rect" animation="wave" width={40} height={40} />
                            <Skeleton variant="rect" animation="wave" width={40} height={40} />
                            <Skeleton variant="rect" animation="wave" width={40} height={40} />
                            <Skeleton variant="rect" animation="wave" width={40} height={40} />
                            <Skeleton variant="rect" animation="wave" width={40} height={40} />
                          </>
                        ) : (
                          <>
                            <IconEmail
                              email={baseInfo.email}
                              verified={baseInfo.verified_email}
                              size={40}
                            />
                            <IconPhone phone={baseInfo.phone_mobile} size={40} />
                            <ChipOrigin applicantInfo={baseInfo} size={40} />
                            <IconInternal
                              internal={baseInfo.internal}
                              internal_verified={baseInfo.internal_verified}
                              employee_id={baseInfo.employee_id}
                              size={40}
                            />
                            <InfoPwd pwd={baseInfo.pwd} size={40} />
                            <InfoGender gender={baseInfo.gender} size={40} />
                            <InfoPso pso={baseInfo.pso} size={38} />
                            <InfoPwr pwr={baseInfo.pwr} size={38} />
                          </>
                        )}
                      </div>
                    </Box>
                  </Box>
                </Paper>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div>
                  {this.state._loadingBaseInfo ? (
                    <LoadingData />
                  ) : (
                    <div>
                      <div
                        style={{
                          marginBottom: '10px'
                        }}
                      >
                        <InfoNameWarning
                          cv_match_name={baseInfo.cv_match_name}
                          origin={baseInfo.origin}
                        />
                      </div>
                      <DetailSummary info={baseInfo} />

                      {this.state._loadingBaseInfo ? null : (
                        <DetailLocation applicant_id={this.state.applicant_id} />
                      )}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ApplicantDetailDialog));
