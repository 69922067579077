import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import * as CONST from '../../Common/constants';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const messages = defineMessages({
  originPool: {
    id: 'ChipSource.originPool',
    defaultMessage: 'Pool'
  },
  originHunting: {
    id: 'ChipSource.originHunting',
    defaultMessage: 'Hunting'
  }
});

const useStyles = (theme) => ({
  generalChip: {
    background: theme.palette.gray.lighter,
    color: theme.palette.gray.darker,
    fontSize: '14px',
    fontWeight: 400
  }
});

class ChipSource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      origin: props.origin,
      referral: props.referral
    };
  }

  render() {
    const { classes } = this.props;
    let origin_msg;
    switch (this.state.origin) {
      case CONST.ORIGIN_APPLY:
        origin_msg = this.props.intl.formatMessage({ id: 'ChipSource.originPool' });
        break;
      case CONST.ORIGIN_MAILIN:
        if (this.state.referral) {
          origin_msg = this.props.intl.formatMessage({
            id: 'ChipOrigin.originReferral'
          });
        } else {
          origin_msg = this.props.intl.formatMessage({ id: 'ChipSource.originPool' });
        }
        break;
      case CONST.ORIGIN_RECRUITER:
        origin_msg = this.props.intl.formatMessage({ id: 'ChipSource.originPool' });
        break;
      case CONST.ORIGIN_IMPORT:
        origin_msg = this.props.intl.formatMessage({ id: 'ChipSource.originPool' });
        break;
      case CONST.ORIGIN_SEARCH:
        origin_msg = this.props.intl.formatMessage({ id: 'ChipSource.originPool' });
        break;
      case CONST.ORIGIN_HUNT:
        origin_msg = this.props.intl.formatMessage({ id: 'ChipSource.originHunting' });
        break;
      case CONST.ORIGIN_SCRAP:
        origin_msg = this.props.intl.formatMessage({ id: 'ChipSource.originHunting' });
        break;
      case CONST.ORIGIN_MOVE:
        origin_msg = this.props.intl.formatMessage({ id: 'ChipSource.originPool' });
        break;
      case CONST.ORIGIN_COPY:
        origin_msg = this.props.intl.formatMessage({
          id: 'ChipSource.originPool'
        });
        break;
      case CONST.ORIGIN_CROSSRANK:
        origin_msg = this.props.intl.formatMessage({ id: 'ChipSource.originPool' });
        break;
      default:
        origin_msg = this.props.intl.formatMessage({ id: 'LabelOrigin.originUnknown' });
    }

    return (
      <div>
        <Chip className={classes.generalChip} size="small" label={origin_msg} />
      </div>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ChipSource));
