import Api from '../Api/Api';
import UserProfile from '../Common/UserProfile';

function add(a, b) {
  return parseFloat(a) + parseFloat(b);
}

var CardController = (function () {
  var formatScore = function (value) {
    const score = parseFloat(value);

    let formatScore = -1; // -1 is treated as a No score
    if (score === null || score === undefined || score === -1) {
      formatScore = -1;
    } else {
      formatScore = parseFloat(score.toFixed(1)); // 1 Decimal as standard
    }
    return formatScore;
  };

  var getScreeningScore = function (screeningScore) {
    let score = parseFloat(screeningScore);
    if (score > -1) {
      if (score > 100) {
        score = 100;
      }
      return formatScore(score);
    } else {
      return -1;
    }
  };

  var getPredictiveScore = function (predictiveScore) {
    const score = parseFloat(predictiveScore);
    if (score > -1) {
      return formatScore(score);
    } else {
      return -1;
    }
  };

  // Forms Tests score (0-10 scale)
  var getCustomTestScore = function (testScore) {
    const score = parseFloat(testScore);
    if (score > -1) {
      return formatScore(score * 10);
    } else {
      return -1;
    }
  };

  // Forms Tests score (0-100% scale)
  var getCustomTestScorePerc = function (testScore) {
    const score = parseFloat(testScore);
    if (score > -1) {
      return this.getCustomTestScore(score * 10);
    } else {
      return -1;
    }
  };

  // Review score (0-10 scale)
  var getReviewScore = function (reviewScore) {
    const score = parseFloat(reviewScore) / 10;
    if (score > -1) {
      return formatScore(score);
    } else {
      return -1;
    }
  };

  var getOverallScore = function (screeningScore, interviewScore, predictiveScore, normMultiplier) {
    let overallScore = 0,
      scores = [],
      weights = [],
      scScore = this.getScreeningScore(screeningScore, normMultiplier),
      intScore = this.getInterviewScore(interviewScore),
      predScore = this.getPredictiveScore(predictiveScore);

    if (scScore > -1) {
      weights.push(1);
      scores.push(scScore);
    }
    if (intScore > -1) {
      weights.push(3);
      scores.push(intScore * 3);
    }
    if (predScore > -1) {
      //weights.push(2)
      //scores.push(predScore*2);
    }

    if (scores.length > 0) {
      let sum = scores.reduce(add, 0);
      //console.log(sum);
      let wei = weights.reduce(add, 0);
      //console.log(wei);
      overallScore = sum / wei;
      //console.log(overallScore);
      return formatScore(overallScore);
    } else {
      return -1;
    }
  };

  var getInterviewScore = function (interviewScore) {
    const score = parseFloat(interviewScore);
    if (score > -1) {
      let calc = score * 2 * 10;
      return formatScore(calc);
    } else {
      return -1;
    }
  };

  var getVideoInterviewScore = function (videoInterviewScore) {
    const score = parseFloat(videoInterviewScore);
    if (score > -1) {
      let calc = score * 2 * 10;
      return formatScore(calc);
    } else {
      return -1;
    }
  };

  var postApplicantView = function (applicant, cb) {
    if (applicant) {
      let api = new Api();
      let payload = {
        account_id: UserProfile.getRealm(),
        job_id: applicant.job_id,
        applicant_id: applicant.id,
        user_id: UserProfile.getId()
      };
      api.post('ApplicantViews', payload, (status, newView) => {
        return cb(null, newView);
      });
    } else {
      return cb('Applicant must be provided');
    }
  };

  var postPersonView = function (person, cb) {
    if (person) {
      let api = new Api();
      let payload = {
        account_id: UserProfile.getRealm(),
        job_id: person.job_id,
        person_id: person.id,
        user_id: UserProfile.getId()
      };
      api.post('PersonSearchViews', payload, (status, newView) => {
        return cb(null, newView);
      });
    } else {
      return cb('Person must be provided');
    }
  };

  var getLocationBaseInfo = function (job, cb) {
    let api = new Api();
    let distFilter = {
      fields: ['pipeline_stage', 'distance_job'],
      where: {
        job_id: job.id,
        distance_job: {
          gt: 0
        }
      },
      order: 'pipeline_stage'
    };
    api.get('Applicants', distFilter, (status, dist) => {
      //console.log(dist);
      return cb(null, dist);
    });
  };

  var getCustomTestBaseInfo = function (test_id, cb) {
    let api = new Api();
    let id = {
      where: {
        id: test_id
      }
    };
    api.get('CustomTests', id, (status, data) => {
      //console.log(data[0]);
      if (data.length > 0) {
        return cb(null, data[0]);
      } else {
        return cb('No custom test found');
      }
    });
  };

  return {
    formatScore: formatScore,
    getScreeningScore: getScreeningScore,
    getPredictiveScore: getPredictiveScore,
    getOverallScore: getOverallScore,
    getCustomTestScore: getCustomTestScore,
    getCustomTestScorePerc: getCustomTestScorePerc,
    getReviewScore: getReviewScore,
    getInterviewScore: getInterviewScore,
    getVideoInterviewScore: getVideoInterviewScore,
    getLocationBaseInfo: getLocationBaseInfo,
    getCustomTestBaseInfo: getCustomTestBaseInfo,
    postApplicantView: postApplicantView,
    postPersonView: postPersonView
  };
})();

export default CardController;
