import StorageProvider from '../Services/StorageProvider';

var UserStorage = (function () {
  var setValue = function (item, value) {
    StorageProvider.setItem(item, value, function (err) {
      if (err) {
        console.log(err);
      }
    });
  };

  var getValue = function (item, cb) {
    StorageProvider.getItem(item, function (err, value) {
      if (err) {
        return cb(err);
      } else {
        return cb(null, value);
      }
    });
  };

  var removeValue = function (item) {
    StorageProvider.removeItem(item, function (err) {
      if (err) {
        console.log(err);
      }
    });
  };

  var setLocalValue = function (component, item, value) {
    const local_item = component + '_' + item;
    StorageProvider.setItem(local_item, value, function (err) {
      if (err) {
        console.log(err);
      }
    });
  };

  var getLocalValue = function (component, item, cb) {
    const local_item = component + '_' + item;
    StorageProvider.getItem(local_item, function (err, value) {
      if (err) {
        return cb(err);
      } else {
        return cb(null, value);
      }
    });
  };

  var setLocalValueKey = function (component, item, key, value) {
    const local_item = component + '_' + item;

    getLocalValue(component, item, function (err, obj) {
      if (err) {
        console.log(err);
      } else {
        let newObj = {};

        if (obj && obj[key]) {
          newObj = {
            ...obj,
            [key]: {
              ...obj[key],
              ...value
            }
          };
        } else {
          newObj = {
            ...obj,
            [key]: {
              ...value
            }
          };
        }

        StorageProvider.setItem(local_item, newObj, function (err) {
          if (err) {
            console.log(err);
          }
        });
      }
    });
  };

  var getLocalValueKey = function (component, item, key, cb) {
    const local_item = component + '_' + item;
    StorageProvider.getItem(local_item, function (err, value) {
      if (err) {
        return cb(err);
      } else if (value && value[key]) {
        return cb(null, value[key]);
      } else {
        return cb(null, null);
      }
    });
  };

  var getLocalValueAsync = async function (component, item, cb) {
    // const componente_name = component.constructor.name;
    const local_item = component + '_' + item;
    return await StorageProvider.getItem(local_item).then((v) => {
      return v;
    });
    // return await Promise.resolve((resolve) => resolve(retorno()));
  };

  var removeLocalValue = function (component, item) {
    //const componente_name = component._reactInternalInstance.getName();
    const local_item = component + '_' + item;
    StorageProvider.removeItem(local_item, function (err) {
      if (err) {
        console.log(err);
      }
    });
  };

  var clear = function () {
    StorageProvider.clear(function (err) {
      if (err) {
        console.log(err);
      }
    });
  };

  var getInterviewScheduleDefaults = function (job_id) {
    let returnObj = {};

    StorageProvider.getItem('interview_schedule', function (err, value) {
      // if (err) {
      // } else {
      // }
    });

    return returnObj;
  };

  return {
    setValue: setValue,
    getValue: getValue,
    removeValue: removeValue,
    setLocalValue: setLocalValue,
    getLocalValue: getLocalValue,
    setLocalValueKey: setLocalValueKey,
    getLocalValueKey: getLocalValueKey,
    getLocalValueAsync: getLocalValueAsync,
    removeLocalValue: removeLocalValue,
    clear: clear,
    getInterviewScheduleDefaults: getInterviewScheduleDefaults
  };
})();

export default UserStorage;
