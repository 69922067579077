import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import 'moment/locale/pt-br'; // FIXME locale
import Avatar from 'react-avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonFav from '../../Applicant/Card/ButtonFav';
import * as CONST from '../../Common/constants';

const messages = defineMessages({});

const useStyles = (theme) => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(2)
  },
  scoreLabel: {
    fontSize: '12px',
    color: theme.palette.gray.darker
  },
  scoreNum: {
    fontSize: '16px',
    color: theme.palette.success.main
  },
  backgroundCircle: {
    position: 'absolute',
    top: '8px',
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
    borderRadius: '100%'
  },
  favContainer: {
    position: 'absolute',
    zIndex: 10,
    borderRadius: '100%',
    right: '-8px',
    bottom: '-8px',
    backgroundColor: theme.palette.common.white,
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatarContainer: {
    position: 'relative'
  },
  avatarPicture: {
    position: 'absolute',
    top: 5.5
  }
});

const useStylesCustom = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  bottom: {
    color: 'transparent'
  },
  top: {
    color: theme.palette.success.main,
    position: 'absolute',
    left: 0
  }
}));

function CircularProgressWithLabel(props) {
  const classes = useStylesCustom();

  return (
    <Box position="relative" display="inline-flex">
      <div className={classes.root}>
        <CircularProgress variant="determinate" className={classes.bottom} {...props} value={100} />
        <CircularProgress
          variant="determinate"
          className={classes.top}
          classes={{
            circle: classes.circle
          }}
          {...props}
        />
      </div>
      <Box
        top={-4}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.children}
      </Box>
    </Box>
  );
}

export class CardAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Props from parent
      applicant: props.applicant,
      orientation: props.orientation,
      readonly: props.readonly
      // Internal props
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }
  }

  handleUpdateFavorites = (favorites) => {
    const applicant = this.state.applicant;

    if (applicant && this.props.handleUpdateFavorites) {
      const applicant_id = applicant.applicant_id;
      this.props.handleUpdateFavorites(applicant_id, favorites);
    }
  };

  render() {
    const { classes } = this.props;
    const applicant = this.state.applicant;

    const size = 64;

    if (applicant) {
      const cv_score = applicant.cv_score ? parseFloat(applicant.cv_score) : undefined;
      const emailBlocklists = applicant.emailBlocklists ?? {};
      const docBlocklists = applicant.docBlocklists ?? {};

      const applicant_id = applicant.applicant_id ? applicant.applicant_id : applicant.id;

      // Coming from person or applicant
      const favorites = applicant.favs ? applicant.favs : applicant.favorites;

      const hasActiveBlock =
        (applicant.activeBlocklists && applicant.activeBlocklists > 0) ||
        (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
        (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0);

      let orientation = 'column';

      if (this.state.orientation === 'horizontal') {
        orientation = 'row';
      }

      let fullPath;
      if (applicant.avatar_50_path) {
        if (applicant.avatar_50_path.startsWith('http')) {
          fullPath = applicant.avatar_50_path;
        } else {
          fullPath = 'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + applicant.avatar_50_path;
        }
      }

      const blockedAvatar = (
        <Tooltip
          title={
            <FormattedMessage
              id="CardAvatar.label_activeBlock"
              defaultMessage="Applicant has an active restriction"
            />
          }
        >
          <Box position="relative">
            <Avatar
              color={CONST.APP_COLORS['gray-lighter']}
              name={applicant.name}
              src={fullPath}
              size={`${size}px`}
              round={true}
            />
            <Box
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                background: CONST.APP_COLORS['danger-dark'],
                borderRadius: '100%',
                width: size,
                height: size,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <ReportProblemOutlinedIcon
                style={{
                  color: CONST.APP_COLORS['yellow']
                }}
              />
            </Box>
          </Box>
        </Tooltip>
      );

      if (cv_score !== undefined && cv_score !== null && this.props.variant !== 'detail') {
        return (
          <Box display="flex" flexDirection={orientation} alignItems="center" mr={2}>
            <CircularProgressWithLabel value={cv_score} size={size + 14}>
              <Box
                className={classes.backgroundCircle}
                style={{
                  width: size + 6,
                  height: size + 6
                }}
              ></Box>
              <Avatar
                color={CONST.APP_COLORS['gray-light']}
                fgColor={CONST.APP_COLORS['gray-darker-medium']}
                name={applicant.name}
                src={fullPath}
                size={`${size}px`}
                style={{
                  zIndex: 10
                }}
                round={true}
                className={classes.avatarPicture}
              />
              {this.state.readonly ? null : (
                <Box className={classes.favContainer}>
                  <ButtonFav
                    favorites={favorites}
                    applicant_id={applicant.id}
                    handleUpdateFavorites={(editedFavorites) =>
                      this.handleUpdateFavorites(editedFavorites)
                    }
                  />
                </Box>
              )}
            </CircularProgressWithLabel>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              ml={this.state.orientation === 'horizontal' ? 1 : 0}
            >
              <Typography
                className={classes.scoreNum}
                style={{
                  fontWeight: 600
                }}
              >
                {`${cv_score.toFixed(1)}%`}
              </Typography>
            </Box>
          </Box>
        );
      } else {
        return hasActiveBlock ? (
          blockedAvatar
        ) : (
          <Box className={classes.avatarContainer}>
            <Avatar
              color={CONST.APP_COLORS['gray-lighter']}
              name={applicant.name}
              src={fullPath}
              size={`${size}px`}
              round={true}
            />
            {this.state.readonly ? null : (
              <Box className={classes.favContainer}>
                <ButtonFav
                  favorites={favorites}
                  applicant_id={applicant_id}
                  handleUpdateFavorites={(editedFavorites) =>
                    this.handleUpdateFavorites(editedFavorites)
                  }
                />
              </Box>
            )}
          </Box>
        );
      }
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(CardAvatar));
