import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const messages = defineMessages({});

const useStyles = (theme) => ({
  hiredChip: {
    background: `${theme.palette.primary.main}10`,
    color: theme.palette.primary.main,
    fontSize: '10px',
    fontWeight: 400,
    borderRadius: 4,

    '&.MuiChip-labelSmall': {
      padding: '0 6px'
    }
    // marginLeft: theme.spacing(1)
  }
});

class ComingChip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    const origin_msg = <FormattedMessage id="ComingChip.label_comingSoon" defaultMessage="Soon" />;

    return <Chip className={classes.hiredChip} size="small" label={origin_msg} />;
  }
}

export default injectIntl(withStyles(useStyles)(ComingChip));
