import { SET_USER } from '../actions/actions';
import produce from 'immer';

const INITIAL_STATE = {
  user: {
    language: navigator.language && navigator.language.includes('pt') ? 'pt-br' : 'en'
  }
};

export default function userReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_USER: {
        draft.user = action.payload;
        break;
      }
      default:
        return state;
    }
  });
}
