import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { MentionsInput, Mention } from 'react-mentions';
import Api from '../../Api/Api';
import UserProfile from '../../Common/UserProfile';
import defaultStyle from './defaultStyle';
import defaultMentionStyle from './defaultMentionStyle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const messages = defineMessages({
  notePlaceholder: {
    id: 'NoteAdd.notePlaceholder',
    defaultMessage: 'Write notes about this candidate. Type `@` to mention users'
  }
});

class NoteAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant_id: props.applicant_id,
      // INTERNAL STATE
      _notePost: true,
      accountUsersList: [],
      note: '',
      noteClean: '',
      mentions: []
    };
    this.mentionsRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange(e, newValue, newPlainTextValue, mentions) {
    //console.log(newValue);
    if (newValue === '@') {
      this.mentionsRef.current.blur();
      this.mentionsRef.current.focus();
    }
    this.setState(
      {
        note: newValue,
        mentions: mentions,
        noteClean: newPlainTextValue
      },
      () => {
        if (newValue != '') {
          this.setState({
            _notePost: false
          });
        } else {
          this.setState({
            _notePost: true
          });
        }
      }
    );
  }

  componentWillMount() {
    this.getAccountUsers();
  }

  getAccountUsers() {
    const applicant_id = this.state.applicant_id;

    let api = new Api();

    // Getting users that are involved in this request
    let user_ids = [];
    let filterUsers = {
      fields: ['id', 'job_id'],
      where: {
        id: applicant_id
      },
      include: {
        relation: 'job',
        scope: {
          fields: [
            'id',
            'job_request_id',
            'user_created',
            'user_recruiter',
            'flow_user_id',
            'viewers',
            'user_manager'
          ],
          include: [
            {
              relation: 'jobRequests',
              scope: {
                fields: ['id', 'user_created', 'user_approver', 'user_recruiter', 'job_id']
              }
            }
          ]
        }
      }
    };
    api.get('Applicants', filterUsers, (status, applicants) => {
      //console.log(applicants);

      if (applicants.length > 0 && applicants[0].job) {
        const job = applicants[0].job;
        user_ids.push(job.user_created);
        user_ids.push(job.user_recruiter);
        user_ids.push(job.flow_user_id);

        if (job.jobRequests && job.jobRequests.length > 0) {
          const request_job = job.jobRequests[0];
          user_ids.push(request_job.user_created);
          user_ids.push(request_job.user_approver);
          user_ids.push(request_job.user_recruiter);
        }

        if (job.viewers && job.viewers.length > 0) {
          job.viewers.forEach((viewer) => {
            const viewerId = parseInt(viewer);
            user_ids.push(viewerId);
          });
        }

        if (job.user_manager) {
          user_ids.push(job.user_manager);
        }
      }
      //console.log(user_ids);

      let userList = [];
      let filter = {
        where: {
          realm: UserProfile.getRealm(),
          suspended: false,
          internal_user: false,
          and: [
            {
              or: [
                {
                  id: { inq: user_ids }
                },
                {
                  user_profile: { like: '%ADMIN%' }
                },
                {
                  user_profile: { like: '%HR_MANAGER%' }
                },
                {
                  user_profile: { like: '%REVIEWER%' }
                }
              ]
            }
          ]
        },
        order: ['username']
      };

      api.get('AccountUsers', filter, (status, data) => {
        //console.log(data);
        data.forEach((user) => {
          let name = user.username;
          userList.push({ id: user.id, display: name });
        });

        this.setState({
          accountUsersList: userList
        });
      });
    });
  }

  onSubmit(e) {
    this.setState({ _notePost: true });
    e.preventDefault();

    let payload = {
      account_id: UserProfile.getRealm(),
      applicant_id: this.state.applicant_id,
      user_id: UserProfile.getId(),
      note: this.state.noteClean,
      mentions: this.state.mentions
    };

    let api = new Api();
    api.post('ApplicantNotes', payload, (status, newNote) => {
      //console.log(newNote);
      this.setState(
        {
          _notePost: false,
          note: '',
          noteClean: '',
          mentions: []
        },
        () => {
          if (this.props.handlerUpdate) {
            this.props.handlerUpdate(newNote);
          }
        }
      );
    });
  }

  render() {
    return (
      <form id="formAddNote" onSubmit={this.onSubmit}>
        <Row>
          <Col sm={12}>
            <MentionsInput
              id="title"
              name="title"
              style={defaultStyle}
              value={this.state.note}
              onChange={this.handleChange}
              allowSpaceInQuery={true}
              placeholder={this.props.intl.formatMessage({
                id: 'NoteAdd.notePlaceholder'
              })}
              inputRef={this.mentionsRef}
            >
              <Mention trigger="@" data={this.state.accountUsersList} style={defaultMentionStyle} />
            </MentionsInput>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Box display="flex" alignItems="center" p={2}>
              <Box mr={1}>
                <Typography color="textSecondary" variant="body2">
                  <FormattedMessage
                    id="NoteAdd.notePlaceholderHelp"
                    defaultMessage="Mention other users by typing `@`. Every user mentioned will be notified by e-mail."
                  />
                </Typography>
              </Box>
              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="mt-sm"
                  disabled={this.state._notePost}
                >
                  <FormattedMessage id="NoteAdd.button_AddNote" defaultMessage="Add" />
                </Button>
              </Box>
            </Box>
          </Col>
        </Row>
      </form>
    );
  }
}

export default injectIntl(NoteAdd);
