//core
import React from 'react';

//import external
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';

// import internal
import * as CONST from '../Common/constants';

class IconFormPersonality extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: props.size ? props.size : 24,
      color: props.color
    };
  }

  render() {
    const { color, size } = this.state;

    return (
      <FaceOutlinedIcon
        style={{
          cursor: 'pointer',
          fontSize: this.props.fontSize ? '' : size,
          color: color || CONST.APP_COLORS['gray-darker-regular']
        }}
        {...this.props}
      />
    );
  }
}

export default IconFormPersonality;
