import * as CONST from './constants';

const url = require('url');

var Utils = (function () {
  var formatKNumber = function (num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
      : Math.sign(num) * Math.abs(num);
  };

  var getCoverImgPath = function (extract_knowledgearea, job_cover_path) {
    if (job_cover_path && job_cover_path !== '') {
      const path = job_cover_path;
      return path;
    } else if (extract_knowledgearea && extract_knowledgearea !== '') {
      const path = CONST.DEFAULT_JOBCOVER_PATH + extract_knowledgearea + '.jpg';
      return path;
    } else {
      const path = 'img/dummy.png';
      return path;
    }
  };

  // https://stackoverflow.com/questions/16547946/generate-random-not-in-array/16548006
  var getRandonStageId = function (restricted) {
    var rand = Math.floor(Math.random() * 100); // From 0 - 100
    if (restricted.indexOf(rand) === -1) {
      return rand;
    } else {
      return getRandonStageId(restricted);
    }
  };

  var isValidDate = function (d) {
    return d instanceof Date && !isNaN(d);
  };

  var matchPresentDate = function (text, lang) {
    let match = false;
    if (text && text !== '') {
      // FIXME: Maybe move this to an external DIC
      const arrWordsEN = ['present', 'current', 'currently', 'now', 'day'];
      const arrWordsPT = ['presente', 'atual', 'momento', 'atualmente', 'hoje', 'dias atuais'];
      const arrWordsES = ['actualmente', 'momento', 'actual', 'presente'];

      const search_words = {
        english: arrWordsEN,
        portuguese: arrWordsEN.concat(arrWordsPT),
        spanish: arrWordsEN.concat(arrWordsES)
      };

      //const words = search_words[lang];
      const words = arrWordsEN.concat(arrWordsES).concat(arrWordsPT);

      match = words.includes(text.toLowerCase());
    }

    return match;
  };

  var getFirstLetters = function (str) {
    const firstLetters = str
      .split(' ')
      .map((word) => word[0])
      .join('');

    return firstLetters;
  };

  var dynamicSort = function (property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  };

  var hideInfo = function (info) {
    if (typeof info === 'string') {
      if (info.length <= 2) {
        // If the string has 2 or fewer characters, return it as it is
        return info;
      }

      // Keep the first and last characters, replace the middle part
      let firstChar = info[0];
      let lastChar = info[info.length - 1];
      let middlePart = '*'.repeat(info.length - 2); // Replace middle with asterisks

      return firstChar + middlePart + lastChar;
    } else {
      return info;
    }
  };

  var hideAllInfo = function (info) {
    if (typeof info === 'string') {
      return '*'.repeat(info.length);
    } else {
      return info;
    }
  };

  var hasDiversity = function (country) {
    let canAskBirthday = country === 'US' || country === 'CA' ? false : true;
    let canAskDiversity = country === 'BR' ? true : false;

    return {
      canAskBirthday: canAskBirthday,
      canAskDiversity: canAskDiversity
    };
  };

  var hasDiversityMatch = function (applicant) {
    let hasDiversity = false;

    const pwrMatch = ['B', 'C', 'D', 'E', 'F'];
    const psoMatch = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    const genderMatch = ['NB', 'O'];

    if (
      pwrMatch.includes(applicant.pwr) ||
      psoMatch.includes(applicant.pso) ||
      genderMatch.includes(applicant.gender)
    ) {
      hasDiversity = true;
    }

    return hasDiversity;
  };

  var hasPwdMatch = function (applicant) {
    let hasPwd = false;

    const pwdMatch = [
      'physically_impaired',
      'hearing_impaired',
      'visually_impaired',
      'mentally_impaired',
      'multiple_disabilities'
    ];

    if (pwdMatch.includes(applicant.pwd)) {
      hasPwd = true;
    }

    return hasPwd;
  };

  var isLinkedInProfile = function (profile_url) {
    let isLinkedInProfile = false;
    const decoded_url = decodeURI(profile_url);
    if (decoded_url.includes('linkedin') && decoded_url.includes('/in/')) {
      isLinkedInProfile = true;
    }
    return isLinkedInProfile;
  };

  var getLinkedInProfileFromUrl = function (profile_url) {
    if (isLinkedInProfile(profile_url)) {
      const decurl = url.parse(profile_url);
      const pathurl = decurl.path.split('/')[2];
      const prof = pathurl.split('?')[0];
      return prof;
    } else {
      return null;
    }
  };

  var applicantSalaryMatch = function (applicantSalaryInfo, jobSalaryInfo) {
    const SALARY_FIXED = 'fixed';
    const SALARY_RANGE = 'range';
    let match = false;

    const salaryType = applicantSalaryInfo.max ? SALARY_RANGE : SALARY_FIXED;

    if (jobSalaryInfo) {
      const jobSalaryType = jobSalaryInfo.max ? SALARY_RANGE : SALARY_FIXED;
      const jobSalaryMax = jobSalaryType === SALARY_FIXED ? jobSalaryInfo.min : jobSalaryInfo.max;

      const matchCurrencyAndDuration =
        applicantSalaryInfo.currency === jobSalaryInfo.currency &&
        applicantSalaryInfo.duration === jobSalaryInfo.duration;

      let matchSalary = false;
      if (salaryType === SALARY_FIXED) {
        matchSalary = parseInt(applicantSalaryInfo.min) <= parseInt(jobSalaryMax);
      } else {
        matchSalary = parseInt(applicantSalaryInfo.max) <= parseInt(jobSalaryMax);
      }

      match = matchCurrencyAndDuration && matchSalary;
    }

    return match;
  };

  var removeAccents = function (str) {
    let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    let accentsOut = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
    str = str.split('');
    str.forEach((letter, index) => {
      let i = accents.indexOf(letter);
      if (i != -1) {
        str[index] = accentsOut[i];
      }
    });
    return str.join('');
  };

  return {
    formatKNumber: formatKNumber,
    getCoverImgPath: getCoverImgPath,
    getRandonStageId: getRandonStageId,
    isValidDate: isValidDate,
    matchPresentDate: matchPresentDate,
    getFirstLetters: getFirstLetters,
    dynamicSort: dynamicSort,
    hideInfo: hideInfo,
    hideAllInfo: hideAllInfo,
    hasDiversity: hasDiversity,
    hasDiversityMatch: hasDiversityMatch,
    hasPwdMatch: hasPwdMatch,
    isLinkedInProfile: isLinkedInProfile,
    getLinkedInProfileFromUrl: getLinkedInProfileFromUrl,
    applicantSalaryMatch: applicantSalaryMatch,
    removeAccents: removeAccents
  };
})();

export default Utils;
