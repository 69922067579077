/* Timezones are borrowed from Google Calendar */

// eslint-disable-next-line max-len
// [...$0.children].map(el => ({ label: (el.getAttribute('aria-label')|| '').replace(/\(.*?\)(.+)/, '$1').trim(), value: el.getAttribute('data-value'), offset: +(el.getAttribute('aria-label')|| '').replace(/\(.*?(-?[0-9]{2}):([0-9]{2})\).*/, (all, one, two) => +one + (two / 60) * (one > 0 ? 1 : -1)) }))

export default [
  {
    label: '(GMT-11:00) Niue',
    value: 'Pacific/Niue',
    offset: -11
  },
  {
    label: '(GMT-11:00) Pago Pago',
    value: 'Pacific/Pago_Pago',
    offset: -11
  },
  {
    label: '(GMT-10:00) Hawaii Time',
    value: 'Pacific/Honolulu',
    offset: -10
  },
  {
    label: '(GMT-10:00) Rarotonga',
    value: 'Pacific/Rarotonga',
    offset: -10
  },
  {
    label: '(GMT-10:00) Tahiti',
    value: 'Pacific/Tahiti',
    offset: -10
  },
  {
    label: '(GMT-09:30) Marquesas',
    value: 'Pacific/Marquesas',
    offset: -9.5
  },
  {
    label: '(GMT-09:00) Gambier',
    value: 'Pacific/Gambier',
    offset: -9
  },
  {
    label: '(GMT-08:00) Alaska Time',
    value: 'America/Anchorage',
    offset: -8
  },
  {
    label: '(GMT-08:00) Pitcairn',
    value: 'Pacific/Pitcairn',
    offset: -8
  },
  {
    label: '(GMT-07:00) Dawson',
    value: 'America/Dawson',
    offset: -7
  },
  {
    label: '(GMT-07:00) Mountain Time - Arizona',
    value: 'America/Phoenix',
    offset: -7
  },
  {
    label: '(GMT-07:00) Mountain Time - Dawson Creek',
    value: 'America/Dawson_Creek',
    offset: -7
  },
  {
    label: '(GMT-07:00) Mountain Time - Hermosillo',
    value: 'America/Hermosillo',
    offset: -7
  },
  {
    label: '(GMT-07:00) Pacific Time',
    value: 'America/Los_Angeles',
    offset: -7
  },
  {
    label: '(GMT-07:00) Pacific Time - Tijuana',
    value: 'America/Tijuana',
    offset: -7
  },
  {
    label: '(GMT-07:00) Pacific Time - Vancouver',
    value: 'America/Vancouver',
    offset: -7
  },
  {
    label: '(GMT-07:00) Pacific Time - Whitehorse',
    value: 'America/Whitehorse',
    offset: -7
  },
  {
    label: '(GMT-06:00) Belize',
    value: 'America/Belize',
    offset: -6
  },
  {
    label: '(GMT-06:00) Boise',
    value: 'America/Boise',
    offset: -6
  },
  {
    label: '(GMT-06:00) Central Time - Regina',
    value: 'America/Regina',
    offset: -6
  },
  {
    label: '(GMT-06:00) Central Time - Tegucigalpa',
    value: 'America/Tegucigalpa',
    offset: -6
  },
  {
    label: '(GMT-06:00) Costa Rica',
    value: 'America/Costa_Rica',
    offset: -6
  },
  {
    label: '(GMT-06:00) Easter Island',
    value: 'Pacific/Easter',
    offset: -6
  },
  {
    label: '(GMT-06:00) El Salvador',
    value: 'America/El_Salvador',
    offset: -6
  },
  {
    label: '(GMT-06:00) Galapagos',
    value: 'Pacific/Galapagos',
    offset: -6
  },
  {
    label: '(GMT-06:00) Guatemala',
    value: 'America/Guatemala',
    offset: -6
  },
  {
    label: '(GMT-06:00) Managua',
    value: 'America/Managua',
    offset: -6
  },
  {
    label: '(GMT-06:00) Mountain Time',
    value: 'America/Denver',
    offset: -6
  },
  {
    label: '(GMT-06:00) Mountain Time - Chihuahua, Mazatlan',
    value: 'America/Mazatlan',
    offset: -6
  },
  {
    label: '(GMT-06:00) Mountain Time - Edmonton',
    value: 'America/Edmonton',
    offset: -6
  },
  {
    label: '(GMT-06:00) Mountain Time - Yellowknife',
    value: 'America/Yellowknife',
    offset: -6
  },
  {
    label: '(GMT-05:00) America Cancun',
    value: 'America/Cancun',
    offset: -5
  },
  {
    label: '(GMT-05:00) Bogota',
    value: 'America/Bogota',
    offset: -5
  },
  {
    label: '(GMT-05:00) Central Time',
    value: 'America/Chicago',
    offset: -5
  },
  {
    label: '(GMT-05:00) Central Time - Mexico City',
    value: 'America/Mexico_City',
    offset: -5
  },
  {
    label: '(GMT-05:00) Central Time - Winnipeg',
    value: 'America/Winnipeg',
    offset: -5
  },
  {
    label: '(GMT-05:00) Guayaquil',
    value: 'America/Guayaquil',
    offset: -5
  },
  {
    label: '(GMT-05:00) Jamaica',
    value: 'America/Jamaica',
    offset: -5
  },
  {
    label: '(GMT-05:00) Lima',
    value: 'America/Lima',
    offset: -5
  },
  {
    label: '(GMT-05:00) Panama',
    value: 'America/Panama',
    offset: -5
  },
  {
    label: '(GMT-05:00) Rio Branco',
    value: 'America/Rio_Branco',
    offset: -5
  },
  {
    label: '(GMT-04:00) Asuncion',
    value: 'America/Asuncion',
    offset: -4
  },
  {
    label: '(GMT-04:00) Barbados',
    value: 'America/Barbados',
    offset: -4
  },
  {
    label: '(GMT-04:00) Boa Vista',
    value: 'America/Boa_Vista',
    offset: -4
  },
  {
    label: '(GMT-04:00) Campo Grande',
    value: 'America/Campo_Grande',
    offset: -4
  },
  {
    label: '(GMT-04:00) Caracas',
    value: 'America/Caracas',
    offset: -4
  },
  {
    label: '(GMT-04:00) Cuiaba',
    value: 'America/Cuiaba',
    offset: -4
  },
  {
    label: '(GMT-04:00) Curacao',
    value: 'America/Curacao',
    offset: -4
  },
  {
    label: '(GMT-04:00) Detroit',
    value: 'America/Detroit',
    offset: -4
  },
  {
    label: '(GMT-04:00) Eastern Time',
    value: 'America/New_York',
    offset: -4
  },
  {
    label: '(GMT-04:00) Eastern Time - Iqaluit',
    value: 'America/Iqaluit',
    offset: -4
  },
  {
    label: '(GMT-04:00) Eastern Time - Toronto',
    value: 'America/Toronto',
    offset: -4
  },
  {
    label: '(GMT-04:00) Grand Turk',
    value: 'America/Grand_Turk',
    offset: -4
  },
  {
    label: '(GMT-04:00) Guyana',
    value: 'America/Guyana',
    offset: -4
  },
  {
    label: '(GMT-04:00) Havana',
    value: 'America/Havana',
    offset: -4
  },
  {
    label: '(GMT-04:00) La Paz',
    value: 'America/La_Paz',
    offset: -4
  },
  {
    label: '(GMT-04:00) Manaus',
    value: 'America/Manaus',
    offset: -4
  },
  {
    label: '(GMT-04:00) Martinique',
    value: 'America/Martinique',
    offset: -4
  },
  {
    label: '(GMT-04:00) Nassau',
    value: 'America/Nassau',
    offset: -4
  },
  {
    label: '(GMT-04:00) Port of Spain',
    value: 'America/Port_of_Spain',
    offset: -4
  },
  {
    label: '(GMT-04:00) Port-au-Prince',
    value: 'America/Port-au-Prince',
    offset: -4
  },
  {
    label: '(GMT-04:00) Porto Velho',
    value: 'America/Porto_Velho',
    offset: -4
  },
  {
    label: '(GMT-04:00) Puerto Rico',
    value: 'America/Puerto_Rico',
    offset: -4
  },
  {
    label: '(GMT-04:00) Santiago',
    value: 'America/Santiago',
    offset: -4
  },
  {
    label: '(GMT-04:00) Santo Domingo',
    value: 'America/Santo_Domingo',
    offset: -4
  },
  {
    label: '(GMT-03:00) Araguaina',
    value: 'America/Araguaina',
    offset: -3
  },
  {
    label: '(GMT-03:00) Atlantic Time - Halifax',
    value: 'America/Halifax',
    offset: -3
  },
  {
    label: '(GMT-03:00) Belem',
    value: 'America/Belem',
    offset: -3
  },
  {
    label: '(GMT-03:00) Bermuda',
    value: 'Atlantic/Bermuda',
    offset: -3
  },
  {
    label: '(GMT-03:00) Buenos Aires',
    value: 'America/Argentina/Buenos_Aires',
    offset: -3
  },
  {
    label: '(GMT-03:00) Cayenne',
    value: 'America/Cayenne',
    offset: -3
  },
  {
    label: '(GMT-03:00) Cordoba',
    value: 'America/Argentina/Cordoba',
    offset: -3
  },
  {
    label: '(GMT-03:00) Fortaleza',
    value: 'America/Fortaleza',
    offset: -3
  },
  {
    label: '(GMT-03:00) Maceio',
    value: 'America/Maceio',
    offset: -3
  },
  {
    label: '(GMT-03:00) Montevideo',
    value: 'America/Montevideo',
    offset: -3
  },
  {
    label: '(GMT-03:00) Palmer',
    value: 'Antarctica/Palmer',
    offset: -3
  },
  {
    label: '(GMT-03:00) Paramaribo',
    value: 'America/Paramaribo',
    offset: -3
  },
  {
    label: '(GMT-03:00) Punta Arenas',
    value: 'America/Punta_Arenas',
    offset: -3
  },
  {
    label: '(GMT-03:00) Recife',
    value: 'America/Recife',
    offset: -3
  },
  {
    label: '(GMT-03:00) Rothera',
    value: 'Antarctica/Rothera',
    offset: -3
  },
  {
    label: '(GMT-03:00) Salvador',
    value: 'America/Bahia',
    offset: -3
  },
  {
    label: '(GMT-03:00) Sao Paulo',
    value: 'America/Sao_Paulo',
    offset: -3
  },
  {
    label: '(GMT-03:00) Stanley',
    value: 'Atlantic/Stanley',
    offset: -3
  },
  {
    label: '(GMT-03:00) Thule',
    value: 'America/Thule',
    offset: -3
  },
  {
    label: '(GMT-02:30) Newfoundland Time - St. Johns',
    value: 'America/St_Johns',
    offset: -2.5
  },
  {
    label: '(GMT-02:00) Godthab',
    value: 'America/Godthab',
    offset: -2
  },
  {
    label: '(GMT-02:00) Miquelon',
    value: 'America/Miquelon',
    offset: -2
  },
  {
    label: '(GMT-02:00) Noronha',
    value: 'America/Noronha',
    offset: -2
  },
  {
    label: '(GMT-02:00) South Georgia',
    value: 'Atlantic/South_Georgia',
    offset: -2
  },
  {
    label: '(GMT-01:00) Cape Verde',
    value: 'Atlantic/Cape_Verde',
    offset: -1
  },
  {
    label: '(GMT+00:00) Abidjan',
    value: 'Africa/Abidjan',
    offset: 0
  },
  {
    label: '(GMT+00:00) Accra',
    value: 'Africa/Accra',
    offset: 0
  },
  {
    label: '(GMT+00:00) Azores',
    value: 'Atlantic/Azores',
    offset: 0
  },
  {
    label: '(GMT+00:00) Bissau',
    value: 'Africa/Bissau',
    offset: 0
  },
  {
    label: '(GMT+00:00) Casablanca',
    value: 'Africa/Casablanca',
    offset: 0
  },
  {
    label: '(GMT+00:00) Danmarkshavn',
    value: 'America/Danmarkshavn',
    offset: 0
  },
  {
    label: '(GMT+00:00) El Aaiun',
    value: 'Africa/El_Aaiun',
    offset: 0
  },
  {
    label: 'GMT (no daylight saving)',
    value: 'Etc/GMT',
    offset: 0
  },
  {
    label: '(GMT+00:00) Monrovia',
    value: 'Africa/Monrovia',
    offset: 0
  },
  {
    label: '(GMT+00:00) Reykjavik',
    value: 'Atlantic/Reykjavik',
    offset: 0
  },
  {
    label: '(GMT+00:00) Scoresbysund',
    value: 'America/Scoresbysund',
    offset: 0
  },
  {
    label: 'UTC',
    value: 'UTC',
    offset: null
  },
  {
    label: '(GMT+01:00) Algiers',
    value: 'Africa/Algiers',
    offset: 1
  },
  {
    label: '(GMT+01:00) Canary Islands',
    value: 'Atlantic/Canary',
    offset: 1
  },
  {
    label: '(GMT+01:00) Dublin',
    value: 'Europe/Dublin',
    offset: 1
  },
  {
    label: '(GMT+01:00) Faeroe',
    value: 'Atlantic/Faroe',
    offset: 1
  },
  {
    label: '(GMT+01:00) Lagos',
    value: 'Africa/Lagos',
    offset: 1
  },
  {
    label: '(GMT+01:00) Lisbon',
    value: 'Europe/Lisbon',
    offset: 1
  },
  {
    label: '(GMT+01:00) London',
    value: 'Europe/London',
    offset: 1
  },
  {
    label: '(GMT+01:00) Ndjamena',
    value: 'Africa/Ndjamena',
    offset: 1
  },
  {
    label: '(GMT+01:00) Sao Tome',
    value: 'Africa/Sao_Tome',
    offset: 1
  },
  {
    label: '(GMT+01:00) Tunis',
    value: 'Africa/Tunis',
    offset: 1
  },
  {
    label: '(GMT+02:00) Amsterdam',
    value: 'Europe/Amsterdam',
    offset: 2
  },
  {
    label: '(GMT+02:00) Andorra',
    value: 'Europe/Andorra',
    offset: 2
  },
  {
    label: '(GMT+02:00) Berlin',
    value: 'Europe/Berlin',
    offset: 2
  },
  {
    label: '(GMT+02:00) Brussels',
    value: 'Europe/Brussels',
    offset: 2
  },
  {
    label: '(GMT+02:00) Budapest',
    value: 'Europe/Budapest',
    offset: 2
  },
  {
    label: '(GMT+02:00) Cairo',
    value: 'Africa/Cairo',
    offset: 2
  },
  {
    label: '(GMT+02:00) Central European Time - Belgrade',
    value: 'Europe/Belgrade',
    offset: 2
  },
  {
    label: '(GMT+02:00) Central European Time - Prague',
    value: 'Europe/Prague',
    offset: 2
  },
  {
    label: '(GMT+02:00) Ceuta',
    value: 'Africa/Ceuta',
    offset: 2
  },
  {
    label: '(GMT+02:00) Copenhagen',
    value: 'Europe/Copenhagen',
    offset: 2
  },
  {
    label: '(GMT+02:00) Gibraltar',
    value: 'Europe/Gibraltar',
    offset: 2
  },
  {
    label: '(GMT+02:00) Johannesburg',
    value: 'Africa/Johannesburg',
    offset: 2
  },
  {
    label: '(GMT+02:00) Khartoum',
    value: 'Africa/Khartoum',
    offset: 2
  },
  {
    label: '(GMT+02:00) Luxembourg',
    value: 'Europe/Luxembourg',
    offset: 2
  },
  {
    label: '(GMT+02:00) Madrid',
    value: 'Europe/Madrid',
    offset: 2
  },
  {
    label: '(GMT+02:00) Malta',
    value: 'Europe/Malta',
    offset: 2
  },
  {
    label: '(GMT+02:00) Maputo',
    value: 'Africa/Maputo',
    offset: 2
  },
  {
    label: '(GMT+02:00) Monaco',
    value: 'Europe/Monaco',
    offset: 2
  },
  {
    label: '(GMT+02:00) Moscow-01 - Kaliningrad',
    value: 'Europe/Kaliningrad',
    offset: 2
  },
  {
    label: '(GMT+02:00) Oslo',
    value: 'Europe/Oslo',
    offset: 2
  },
  {
    label: '(GMT+02:00) Paris',
    value: 'Europe/Paris',
    offset: 2
  },
  {
    label: '(GMT+02:00) Rome',
    value: 'Europe/Rome',
    offset: 2
  },
  {
    label: '(GMT+02:00) Stockholm',
    value: 'Europe/Stockholm',
    offset: 2
  },
  {
    label: '(GMT+02:00) Tirane',
    value: 'Europe/Tirane',
    offset: 2
  },
  {
    label: '(GMT+02:00) Tripoli',
    value: 'Africa/Tripoli',
    offset: 2
  },
  {
    label: '(GMT+02:00) Vienna',
    value: 'Europe/Vienna',
    offset: 2
  },
  {
    label: '(GMT+02:00) Warsaw',
    value: 'Europe/Warsaw',
    offset: 2
  },
  {
    label: '(GMT+02:00) Windhoek',
    value: 'Africa/Windhoek',
    offset: 2
  },
  {
    label: '(GMT+02:00) Zurich',
    value: 'Europe/Zurich',
    offset: 2
  },
  {
    label: '(GMT+03:00) Amman',
    value: 'Asia/Amman',
    offset: 3
  },
  {
    label: '(GMT+03:00) Athens',
    value: 'Europe/Athens',
    offset: 3
  },
  {
    label: '(GMT+03:00) Baghdad',
    value: 'Asia/Baghdad',
    offset: 3
  },
  {
    label: '(GMT+03:00) Beirut',
    value: 'Asia/Beirut',
    offset: 3
  },
  {
    label: '(GMT+03:00) Bucharest',
    value: 'Europe/Bucharest',
    offset: 3
  },
  {
    label: '(GMT+03:00) Chisinau',
    value: 'Europe/Chisinau',
    offset: 3
  },
  {
    label: '(GMT+03:00) Damascus',
    value: 'Asia/Damascus',
    offset: 3
  },
  {
    label: '(GMT+03:00) Gaza',
    value: 'Asia/Gaza',
    offset: 3
  },
  {
    label: '(GMT+03:00) Helsinki',
    value: 'Europe/Helsinki',
    offset: 3
  },
  {
    label: '(GMT+03:00) Istanbul',
    value: 'Europe/Istanbul',
    offset: 3
  },
  {
    label: '(GMT+03:00) Jerusalem',
    value: 'Asia/Jerusalem',
    offset: 3
  },
  {
    label: '(GMT+03:00) Kiev',
    value: 'Europe/Kiev',
    offset: 3
  },
  {
    label: '(GMT+03:00) Minsk',
    value: 'Europe/Minsk',
    offset: 3
  },
  {
    label: 'Moscow+00 - Moscow',
    value: 'Europe/Moscow',
    offset: 3
  },
  {
    label: '(GMT+03:00) Nairobi',
    value: 'Africa/Nairobi',
    offset: 3
  },
  {
    label: '(GMT+03:00) Nicosia',
    value: 'Asia/Nicosia',
    offset: 3
  },
  {
    label: '(GMT+03:00) Qatar',
    value: 'Asia/Qatar',
    offset: 3
  },
  {
    label: '(GMT+03:00) Riga',
    value: 'Europe/Riga',
    offset: 3
  },
  {
    label: '(GMT+03:00) Riyadh',
    value: 'Asia/Riyadh',
    offset: 3
  },
  {
    label: '(GMT+03:00) Sofia',
    value: 'Europe/Sofia',
    offset: 3
  },
  {
    label: '(GMT+03:00) Syowa',
    value: 'Antarctica/Syowa',
    offset: 3
  },
  {
    label: '(GMT+03:00) Tallinn',
    value: 'Europe/Tallinn',
    offset: 3
  },
  {
    label: '(GMT+03:00) Vilnius',
    value: 'Europe/Vilnius',
    offset: 3
  },
  {
    label: '(GMT+04:00) Baku',
    value: 'Asia/Baku',
    offset: 4
  },
  {
    label: '(GMT+04:00) Dubai',
    value: 'Asia/Dubai',
    offset: 4
  },
  {
    label: '(GMT+04:00) Mahe',
    value: 'Indian/Mahe',
    offset: 4
  },
  {
    label: '(GMT+04:00) Mauritius',
    value: 'Indian/Mauritius',
    offset: 4
  },
  {
    label: '(GMT+04:00) Moscow+01 - Samara',
    value: 'Europe/Samara',
    offset: 4
  },
  {
    label: '(GMT+04:00) Reunion',
    value: 'Indian/Reunion',
    offset: 4
  },
  {
    label: '(GMT+04:00) Tbilisi',
    value: 'Asia/Tbilisi',
    offset: 4
  },
  {
    label: '(GMT+04:00) Yerevan',
    value: 'Asia/Yerevan',
    offset: 4
  },
  {
    label: '(GMT+04:30) Kabul',
    value: 'Asia/Kabul',
    offset: 4.5
  },
  {
    label: '(GMT+04:30) Tehran',
    value: 'Asia/Tehran',
    offset: 4.5
  },
  {
    label: '(GMT+05:00) Aqtau',
    value: 'Asia/Aqtau',
    offset: 5
  },
  {
    label: '(GMT+05:00) Aqtobe',
    value: 'Asia/Aqtobe',
    offset: 5
  },
  {
    label: '(GMT+05:00) Ashgabat',
    value: 'Asia/Ashgabat',
    offset: 5
  },
  {
    label: '(GMT+05:00) Dushanbe',
    value: 'Asia/Dushanbe',
    offset: 5
  },
  {
    label: '(GMT+05:00) Karachi',
    value: 'Asia/Karachi',
    offset: 5
  },
  {
    label: '(GMT+05:00) Kerguelen',
    value: 'Indian/Kerguelen',
    offset: 5
  },
  {
    label: '(GMT+05:00) Maldives',
    value: 'Indian/Maldives',
    offset: 5
  },
  {
    label: '(GMT+05:00) Mawson',
    value: 'Antarctica/Mawson',
    offset: 5
  },
  {
    label: '(GMT+05:00) Moscow+02 - Yekaterinburg',
    value: 'Asia/Yekaterinburg',
    offset: 5
  },
  {
    label: '(GMT+05:00) Tashkent',
    value: 'Asia/Tashkent',
    offset: 5
  },
  {
    label: '(GMT+05:30) Colombo',
    value: 'Asia/Colombo',
    offset: 5.5
  },
  {
    label: '(GMT+05:30) India Standard Time',
    value: 'Asia/Calcutta',
    offset: 5.5
  },
  {
    label: '(GMT+05:45) Katmandu',
    value: 'Asia/Katmandu',
    offset: 5.75
  },
  {
    label: '(GMT+06:00) Almaty',
    value: 'Asia/Almaty',
    offset: 6
  },
  {
    label: '(GMT+06:00) Bishkek',
    value: 'Asia/Bishkek',
    offset: 6
  },
  {
    label: '(GMT+06:00) Chagos',
    value: 'Indian/Chagos',
    offset: 6
  },
  {
    label: '(GMT+06:00) Dhaka',
    value: 'Asia/Dhaka',
    offset: 6
  },
  {
    label: '(GMT+06:00) Moscow+03 - Omsk',
    value: 'Asia/Omsk',
    offset: 6
  },
  {
    label: '(GMT+06:00) Thimphu',
    value: 'Asia/Thimphu',
    offset: 6
  },
  {
    label: '(GMT+06:00) Vostok',
    value: 'Antarctica/Vostok',
    offset: 6
  },
  {
    label: '(GMT+06:30) Cocos',
    value: 'Indian/Cocos',
    offset: 6.5
  },
  {
    label: '(GMT+06:30) Rangoon',
    value: 'Asia/Yangon',
    offset: 6.5
  },
  {
    label: '(GMT+07:00) Bangkok',
    value: 'Asia/Bangkok',
    offset: 7
  },
  {
    label: '(GMT+07:00) Christmas',
    value: 'Indian/Christmas',
    offset: 7
  },
  {
    label: '(GMT+07:00) Davis',
    value: 'Antarctica/Davis',
    offset: 7
  },
  {
    label: '(GMT+07:00) Hanoi',
    value: 'Asia/Saigon',
    offset: 7
  },
  {
    label: '(GMT+07:00) Hovd',
    value: 'Asia/Hovd',
    offset: 7
  },
  {
    label: '(GMT+07:00) Jakarta',
    value: 'Asia/Jakarta',
    offset: 7
  },
  {
    label: '(GMT+07:00) Moscow+04 - Krasnoyarsk',
    value: 'Asia/Krasnoyarsk',
    offset: 7
  },
  {
    label: '(GMT+08:00) Brunei',
    value: 'Asia/Brunei',
    offset: 8
  },
  {
    label: '(GMT+08:00) Casey',
    value: 'Antarctica/Casey',
    offset: 8
  },
  {
    label: '(GMT+08:00) China Time - Beijing',
    value: 'Asia/Shanghai',
    offset: 8
  },
  {
    label: '(GMT+08:00) Choibalsan',
    value: 'Asia/Choibalsan',
    offset: 8
  },
  {
    label: '(GMT+08:00) Hong Kong',
    value: 'Asia/Hong_Kong',
    offset: 8
  },
  {
    label: '(GMT+08:00) Kuala Lumpur',
    value: 'Asia/Kuala_Lumpur',
    offset: 8
  },
  {
    label: '(GMT+08:00) Macau',
    value: 'Asia/Macau',
    offset: 8
  },
  {
    label: '(GMT+08:00) Makassar',
    value: 'Asia/Makassar',
    offset: 8
  },
  {
    label: '(GMT+08:00) Manila',
    value: 'Asia/Manila',
    offset: 8
  },
  {
    label: '(GMT+08:00) Moscow+05 - Irkutsk',
    value: 'Asia/Irkutsk',
    offset: 8
  },
  {
    label: '(GMT+08:00) Singapore',
    value: 'Asia/Singapore',
    offset: 8
  },
  {
    label: '(GMT+08:00) Taipei',
    value: 'Asia/Taipei',
    offset: 8
  },
  {
    label: '(GMT+08:00) Ulaanbaatar',
    value: 'Asia/Ulaanbaatar',
    offset: 8
  },
  {
    label: '(GMT+08:00) Western Time - Perth',
    value: 'Australia/Perth',
    offset: 8
  },
  {
    label: '(GMT+09:00) Dili',
    value: 'Asia/Dili',
    offset: 9
  },
  {
    label: '(GMT+09:00) Jayapura',
    value: 'Asia/Jayapura',
    offset: 9
  },
  {
    label: '(GMT+09:00) Moscow+06 - Yakutsk',
    value: 'Asia/Yakutsk',
    offset: 9
  },
  {
    label: '(GMT+09:00) Palau',
    value: 'Pacific/Palau',
    offset: 9
  },
  {
    label: '(GMT+09:00) Pyongyang',
    value: 'Asia/Pyongyang',
    offset: 9
  },
  {
    label: '(GMT+09:00) Seoul',
    value: 'Asia/Seoul',
    offset: 9
  },
  {
    label: '(GMT+09:00) Tokyo',
    value: 'Asia/Tokyo',
    offset: 9
  },
  {
    label: '(GMT+09:30) Central Time - Adelaide',
    value: 'Australia/Adelaide',
    offset: 9.5
  },
  {
    label: '(GMT+09:30) Central Time - Darwin',
    value: 'Australia/Darwin',
    offset: 9.5
  },
  {
    label: "(GMT+10:00) Dumont D'Urville",
    value: 'Antarctica/DumontDUrville',
    offset: 10
  },
  {
    label: '(GMT+10:00) Eastern Time - Brisbane',
    value: 'Australia/Brisbane',
    offset: 10
  },
  {
    label: '(GMT+10:00) Eastern Time - Hobart',
    value: 'Australia/Hobart',
    offset: 10
  },
  {
    label: '(GMT+10:00) Eastern Time - Melbourne',
    value: 'Australia/Melbourne',
    offset: 10
  },
  {
    label: '(GMT+10:00) Eastern Time - Melbourne, Sydney',
    value: 'Australia/Sydney',
    offset: 10
  },
  {
    label: '(GMT+10:00) Guam',
    value: 'Pacific/Guam',
    offset: 10
  },
  {
    label: '(GMT+10:00) Moscow+07 - Vladivostok',
    value: 'Asia/Vladivostok',
    offset: 10
  },
  {
    label: '(GMT+10:00) Port Moresby',
    value: 'Pacific/Port_Moresby',
    offset: 10
  },
  {
    label: '(GMT+10:00) Truk',
    value: 'Pacific/Chuuk',
    offset: 10
  },
  {
    label: '(GMT+11:00) Efate',
    value: 'Pacific/Efate',
    offset: 11
  },
  {
    label: '(GMT+11:00) Guadalcanal',
    value: 'Pacific/Guadalcanal',
    offset: 11
  },
  {
    label: '(GMT+11:00) Kosrae',
    value: 'Pacific/Kosrae',
    offset: 11
  },
  {
    label: '(GMT+11:00) Moscow+08 - Magadan',
    value: 'Asia/Magadan',
    offset: 11
  },
  {
    label: '(GMT+11:00) Norfolk',
    value: 'Pacific/Norfolk',
    offset: 11
  },
  {
    label: '(GMT+11:00) Noumea',
    value: 'Pacific/Noumea',
    offset: 11
  },
  {
    label: '(GMT+11:00) Ponape',
    value: 'Pacific/Pohnpei',
    offset: 11
  },
  {
    label: '(GMT+12:00) Auckland',
    value: 'Pacific/Auckland',
    offset: 12
  },
  {
    label: '(GMT+12:00) Fiji',
    value: 'Pacific/Fiji',
    offset: 12
  },
  {
    label: '(GMT+12:00) Funafuti',
    value: 'Pacific/Funafuti',
    offset: 12
  },
  {
    label: '(GMT+12:00) Kwajalein',
    value: '(GMT+12:00) Pacific/Kwajalein',
    offset: 12
  },
  {
    label: '(GMT+12:00) Majuro',
    value: 'Pacific/Majuro',
    offset: 12
  },
  {
    label: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy',
    value: 'Asia/Kamchatka',
    offset: 12
  },
  {
    label: '(GMT+12:00) Nauru',
    value: 'Pacific/Nauru',
    offset: 12
  },
  {
    label: '(GMT+12:00) Tarawa',
    value: 'Pacific/Tarawa',
    offset: 12
  },
  {
    label: '(GMT+12:00) Wake',
    value: 'Pacific/Wake',
    offset: 12
  },
  {
    label: '(GMT+12:00) Wallis',
    value: 'Pacific/Wallis',
    offset: 12
  },
  {
    label: '(GMT+13:00) Apia',
    value: 'Pacific/Apia',
    offset: 13
  },
  {
    label: '(GMT+13:00) Enderbury',
    value: 'Pacific/Enderbury',
    offset: 13
  },
  {
    label: '(GMT+13:00) Fakaofo',
    value: 'Pacific/Fakaofo',
    offset: 13
  },
  {
    label: '(GMT+13:00) Tongatapu',
    value: 'Pacific/Tongatapu',
    offset: 13
  },
  {
    label: '(GMT+14:00) Kiritimati',
    value: 'Pacific/Kiritimati',
    offset: 14
  }
];
