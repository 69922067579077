import { connect } from 'react-redux';
import Moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

class DateFormatter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dateToFormat: props.dateToFormat
    };
  }

  componentDidMount() {
    if (this.props.user.language) {
      Moment.locale(this.props.user.language);
    }
  }

  render() {
    return this.props.dateToFormat;
  }
}

DateFormatter.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  user: state.user.user
});

export default connect(mapStateToProps, null)(DateFormatter);
