import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import * as CONST from '../../Common/constants';
import { withStyles } from '@material-ui/core/styles';

const messages = defineMessages({
  nameWarningHelp: {
    id: 'InfoNameWarning.nameWarningHelp',
    defaultMessage:
      "The name or e-mail appears to be different from the one found on the CV used in the application. Please check the person's data."
  }
});

const useStyles = (theme) => ({
  iconWarning: {
    color: `${theme.palette.warning.main}  !important`
  }
});

class InfoNameWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cv_match_name: props.cv_match_name,
      origin: props.origin
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cv_match_name !== this.props.cv_match_name) {
      this.setState({
        cv_match_name: this.props.cv_match_name
      });
    }
    if (prevProps.origin !== this.props.origin) {
      this.setState({
        origin: this.props.origin
      });
    }
  }

  render() {
    const cv_match_name = this.state.cv_match_name;
    const origin = this.state.origin;
    const { classes } = this.props;

    if (
      (origin === CONST.ORIGIN_APPLY ||
        origin === CONST.ORIGIN_MAILIN ||
        origin === CONST.ORIGIN_RECRUITER) &&
      cv_match_name &&
      cv_match_name < 0.5
    ) {
      let help = this.props.intl.formatMessage({
        id: 'InfoNameWarning.nameWarningHelp'
      });
      return (
        <Tooltip title={help} arrow>
          <Alert
            severity="warning"
            classes={{ icon: classes.iconWarning }}
            style={{
              backgroundColor: CONST.APP_COLORS['warning-light'],
              color: CONST.APP_COLORS['warning']
            }}
          >
            <FormattedMessage
              id="InfoNameWarning.nameWarning"
              defaultMessage="Name or e-mail appears to be different from the CV"
            />
          </Alert>
        </Tooltip>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(InfoNameWarning));
