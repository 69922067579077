import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import Box from '@material-ui/core/Box';

const messages = defineMessages({});

const useStyles = (theme) => ({
  tagContainer: {
    position: 'relative'
  },
  addIconContainer: {
    position: 'absolute',
    top: 3,
    left: 10,
    height: 15,
    width: 15,
    backgroundColor: 'white',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addIcon: {
    height: 14,
    width: 14
  },
  icon: {
    color: theme.palette.primary.main
  },
  secondaryIcon: {
    color: `${theme.palette.black.main}8A`
  },
  disabledIcon: {
    color: theme.palette.grey.darkerLight
  }
});

class AddTagIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: props.phone,
      size: props.size ? props.size : 24,
      disabled: props.disabled ? props.disabled : false,
      variant: props.variant,
      // Internal State
      copied: false,
      // Popover
      anchorEl: null
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({
        disabled: this.props.disabled
      });
    }
  };

  handleOpen = (e) => {
    if (this.props.openTagSelection) {
      this.props.openTagSelection();
    }
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { classes } = this.props;

    let className = this.state.variant === 'secondary' ? classes.secondaryIcon : classes.icon;

    if (this.state.disabled) {
      className = classes.disabledIcon;
    }

    return (
      <Box className={classes.tagContainer}>
        <LocalOfferOutlinedIcon className={className} />
        <Box className={classes.addIconContainer}>
          <AddCircleIcon className={`${classes.addIcon} ${className}`} />
        </Box>
      </Box>
    );
  }
}

export default injectIntl(withStyles(useStyles)(AddTagIcon));
