import React from 'react';
//import PropTypes from 'prop-types';
import shouldPureComponentUpdate from 'react-pure-render/function';
import { Col, Row, Popover } from 'react-bootstrap';
import { jobPlaceStyle } from './JobPlaceStyles.js';
import Tooltip from '@material-ui/core/Tooltip';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

export default class JobPlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static defaultProps = {};

  shouldComponentUpdate = shouldPureComponentUpdate;

  render() {
    const jobDetails = () => {
      return (
        <Popover title={this.state.job_code} style={{ width: '250px' }}>
          <Row className="mb">
            <Col md={12}>
              <h5>{this.state.job_title}</h5>
            </Col>
          </Row>
        </Popover>
      );
    };

    return (
      <Tooltip title={''} arrow>
        <div style={jobPlaceStyle}>
          <WorkOutlineIcon color="primary" style={{ fontSize: 40 }} />
        </div>
      </Tooltip>
    );
  }
}
