import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Api from '../../../Api/Api';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import Avatar from 'react-avatar';
import LocaleFormatter from '../../../Common/LocaleFormatter';
import OtherJobsSkeleton from './OtherJobsSkeleton';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ChipHired from '../ChipHired';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AISuggestionsIcon from '../../../Jobs/CrossRank/AISuggestionsIcon';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';

const messages = defineMessages({
  msg_through: {
    id: 'OtherJobs.msg_through',
    defaultMessage: 'through'
  }
});

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  optionList: {
    //height: 200,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    padding: 0
  },
  darkText: {
    color: theme.palette.gray.darker
  },
  lightText: {
    color: theme.palette.gray.darkerMedium
  },
  greenText: {
    color: theme.palette.success.main
  },
  lighterText: {
    color: theme.palette.gray.darkerLight
  },
  smallText: {
    fontSize: '14px',
    lineHeight: 'normal'
  },
  smallerText: {
    fontSize: '12px'
  },
  jobsListDarkElement: {
    fontSize: '14px',
    color: theme.palette.gray.darker
  },
  jobsListLightElement: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium
  },
  notAccessibleIcon: {
    fontSize: '18px',
    color: theme.palette.error.main
  },
  notAccessibleMsg: {
    color: theme.palette.gray.darker,
    fontSize: '12px',
    lineHeight: '18px'
  },
  summaryContainer: {
    background: theme.palette.success.light,
    padding: '16px',
    borderRadius: '1rem',
    width: '455px',
    display: 'flex',
    height: 'fit-content'
  },
  notesSummaryText: {
    fontSize: '12px',
    lineHeight: '20px'
  },
  noNotesText: {
    color: theme.palette.gray.darkerRegular,
    fontStyle: 'italic'
  }
});

class OtherJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Props from parent
      search_parameter: props.search_parameter,
      job_id: props.job_id,
      // Internal state
      applicantData: [],
      loading: false,
      jobs: []
    };
  }

  componentDidMount() {
    if (this.state.search_parameter) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search_parameter !== this.props.search_parameter) {
      this.setState(
        {
          search_parameter: this.props.search_parameter
        },
        () => {
          if (this.state.search_parameter) {
            this.loadData();
          }
        }
      );
    }

    if (prevProps.job_id !== this.props.job_id) {
      this.setState({
        job_id: this.props.job_id
      });
    }
  }

  loadData() {
    this.setState(
      {
        loading: true
      },
      () => {
        let api = new Api();

        let params = {
          search_parameter: this.state.search_parameter,
          job_id: this.state.job_id,
          job_status: this.props.variant === 'active' ? 'PUBLISHED' : undefined
        };

        // console.log(params);

        api.getParam('ReadPersons/job-history', params, (status, applicantData) => {
          if (status < 400) {
            this.setState({
              applicantData: applicantData,
              loading: false
            });
          } else {
            console.log('Error on fetching data');
          }
        });
      }
    );
  }

  render() {
    const { classes } = this.props;
    const applicantData =
      this.state.variant === 'active'
        ? this.state.applicantData.filter(
            (currApp) => currApp.job && currApp.job.status === 'PUBLISHED'
          )
        : this.state.applicantData;

    const displayChip = (applicant) => {
      if (applicant.hire_status === 50) {
        return <ChipHired hired={true} />;
      }

      if (applicant.pipeline_stage === 900) {
        return (
          <Chip
            size="small"
            icon={<CheckIcon />}
            hired={true}
            label={<FormattedMessage id="Pipeline.type_hired" />}
          />
        );
      }

      return null;
    };

    if (this.state.loading) {
      return <OtherJobsSkeleton />;
    } else {
      if (applicantData && applicantData.length > 0) {
        const hasNotesSummary = applicantData.some(
          (currApp) => currApp.notes_summary !== undefined && currApp.notes_summary !== null
        );
        return (
          <Box>
            <Box
              style={{
                position: 'sticky',
                top: '0px',
                zIndex: '999',
                background: 'white'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: hasNotesSummary ? '13px 0 13px 24px' : '13px 24px'
                }}
              >
                <Box width="275px">
                  <Typography
                    className={`${classes.darkText} ${classes.smallText}`}
                    style={{
                      fontWeight: 400
                    }}
                  >
                    <FormattedMessage id="OtherJobs.title" defaultMessage="Other Jobs:" />
                  </Typography>
                </Box>
                {hasNotesSummary ? (
                  <Box width="455px" pl={2}>
                    <Typography
                      className={`${classes.greenText} ${classes.smallText}`}
                      style={{
                        fontWeight: 700
                      }}
                    >
                      <FormattedMessage
                        id="OtherJobs.titleNotesSummary"
                        defaultMessage="Notes summary by AI"
                      />
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              <Divider />
            </Box>
            <Box
              display="flex"
              style={{
                padding: '0 24px'
              }}
            >
              <Box>
                <List className={classes.optionList}>
                  {applicantData.map((appObj, currIndex) => {
                    const job = appObj.job;
                    if (job.accessible) {
                      let notesSummary = '';

                      if (appObj.notes_summary) {
                        notesSummary = appObj.notes_summary['portuguese'];
                        if (this.props.user.language === 'en') {
                          notesSummary = appObj.notes_summary['english'];
                        }
                      }

                      let statusIcon = (
                        <PlayCircleOutlineIcon
                          style={{
                            fontSize: '18px'
                          }}
                        />
                      );

                      if (job.status === 'COMPLETED') {
                        statusIcon = (
                          <PauseCircleOutlineOutlinedIcon
                            style={{
                              fontSize: '18px'
                            }}
                          />
                        );
                      }

                      if (job.status === 'CANCELED') {
                        statusIcon = (
                          <HighlightOffOutlinedIcon
                            style={{
                              fontSize: '18px'
                            }}
                          />
                        );
                      }

                      let applicantOrigin = appObj.origin;

                      if (applicantOrigin === 'SCRAP') {
                        applicantOrigin = 'HUNTING';
                      }

                      if (applicantOrigin === 'APPLY') {
                        applicantOrigin = 'FORM';
                      }

                      if (applicantOrigin === 'MAILIN') {
                        applicantOrigin = 'MAIL';
                      }

                      const formatttedOriginString =
                        applicantOrigin.slice(0, 1).toUpperCase() +
                        applicantOrigin.slice(1).toLowerCase();

                      let jobAddress = '';
                      let addr = [];
                      if (job.workplace_mode === 'REMOTE') {
                        jobAddress = job.country;
                      } else if (job.address) {
                        if (job.address.city) {
                          addr.push(job.address.city);
                        }
                        if (job.address.state) {
                          addr.push(job.address.state);
                        }

                        jobAddress = addr.join(', ');
                      } else {
                        jobAddress = undefined;
                      }

                      let stageStatusName = '';

                      if (job.pipeline_stages && job.pipeline_stages.length > 0) {
                        let stageNameDisplay = '';
                        const jobStageStatus = job.pipeline_stages.find(
                          (stageStatus) => stageStatus.id === appObj.pipeline_stage
                        );

                        if (jobStageStatus) {
                          stageNameDisplay = jobStageStatus.name.toLowerCase();

                          if (
                            this.props.intl.formatMessage({
                              id: 'Pipeline.' + stageNameDisplay
                            }) !== undefined
                          ) {
                            stageNameDisplay = this.props.intl.formatMessage({
                              id: 'Pipeline.' + stageNameDisplay
                            });
                          }
                          if (stageNameDisplay.startsWith('Pipeline.')) {
                            stageNameDisplay = jobStageStatus.name;
                          }
                        }
                        stageStatusName = stageNameDisplay;
                      }

                      const path_avatar =
                        'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' +
                        job.userRecruiter.avatar_50_path;

                      return (
                        <>
                          <ListItem
                            role="listitem"
                            ContainerComponent="li"
                            style={{
                              padding: 0,
                              margin: '16px 0'
                            }}
                          >
                            <Box display="flex">
                              <Box
                                display="flex"
                                flexDirection="column"
                                width="275px"
                                marginRight="16px"
                              >
                                <Box mb={0.5}>
                                  <Typography
                                    className={`${classes.darkText} ${classes.smallText}`}
                                    style={{
                                      fontWeight: 500
                                    }}
                                  >
                                    <a
                                      href={'/pipeline/' + job.id + '/candidate/' + appObj.id}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >{`[${job.code}] ${job.title}`}</a>
                                  </Typography>
                                </Box>

                                <Box display="flex" alignItems="center" mb={2}>
                                  <Box display="flex" alignItems="center">
                                    <Box mr={0.5}>{statusIcon}</Box>
                                    <Typography
                                      className={`${classes.darkText} ${classes.smallerText}`}
                                    >
                                      {this.props.intl.formatMessage({
                                        id: 'JobsList.status_' + job.status
                                      })}
                                      <span
                                        style={{
                                          fontWeight: 500,
                                          margin: '0 4px'
                                        }}
                                      >
                                        &bull;
                                      </span>
                                    </Typography>
                                  </Box>
                                  <Box display="flex" alignItems="center">
                                    <Box mr={0.5} mb={job.userRecruiter.avatar_50_path ? 1 : 0}>
                                      <Avatar
                                        name={job.userRecruiter.username}
                                        src={path_avatar}
                                        size="16px"
                                        round={true}
                                      />
                                    </Box>
                                    <Typography
                                      className={`${classes.lightText} ${classes.smallerText}`}
                                    >
                                      {job.userRecruiter.username}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box display="flex" alignItems="center" mb={0.5}>
                                  <Typography
                                    className={`${classes.darkText} ${classes.smallText}`}
                                  >
                                    {this.props.intl.formatMessage({
                                      id: 'JobsList.table.companyArea'
                                    })}

                                    <span
                                      style={{
                                        fontWeight: 500,
                                        margin: '0 4px'
                                      }}
                                    >
                                      &bull;
                                    </span>
                                    <span
                                      style={{
                                        fontWeight: 500
                                      }}
                                    >
                                      {job &&
                                        job.companyArea &&
                                        job.companyArea.name &&
                                        job.companyArea.name}
                                    </span>
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mb={1.5}>
                                  <Typography
                                    className={`${classes.darkText} ${classes.smallText}`}
                                  >
                                    {this.props.intl.formatMessage({
                                      id: 'JobEdit.header_location'
                                    })}
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        margin: '0 4px'
                                      }}
                                    >
                                      &bull;
                                    </span>
                                    <span
                                      style={{
                                        fontWeight: 500
                                      }}
                                    >
                                      {jobAddress}
                                    </span>
                                  </Typography>
                                </Box>
                                <Divider
                                  style={{
                                    width: '60px'
                                  }}
                                />
                                <Box display="flex" alignItems="center" mb={0.5} mt={1.5}>
                                  <Typography
                                    className={`${classes.darkText} ${classes.smallText}`}
                                  >
                                    <FormattedMessage
                                      id="OtherJobs.msg_candidateStage"
                                      defaultMessage="Candidate Stage"
                                    />
                                    <span
                                      style={{
                                        fontWeight: 500,
                                        margin: '0 4px'
                                      }}
                                    >
                                      &bull;
                                    </span>

                                    <span
                                      style={{
                                        fontWeight: 500
                                      }}
                                    >
                                      {stageStatusName}
                                    </span>
                                  </Typography>
                                </Box>
                                <Box display="flex" mb={0.5}>
                                  <Typography
                                    className={`${classes.darkText} ${classes.smallText}`}
                                  >
                                    <FormattedMessage
                                      id="OtherJobs.msg_appliedIn"
                                      defaultMessage="Applied in"
                                    />
                                    <span
                                      style={{
                                        fontWeight: 500
                                      }}
                                    >
                                      {' '}
                                      <LocaleFormatter
                                        dateToFormat={Moment(appObj.date_created).format('l')}
                                      />
                                    </span>
                                    <span
                                      style={{
                                        fontWeight: 500,
                                        margin: '0 4px'
                                      }}
                                    >
                                      &bull;
                                    </span>
                                  </Typography>
                                  <Typography
                                    className={`${classes.lighterText} ${classes.smallerText}`}
                                  >
                                    <span>
                                      {this.props.intl.formatMessage({
                                        id: 'OtherJobs.msg_through'
                                      })}
                                    </span>{' '}
                                    {this.props.intl.formatMessage({
                                      id: 'LabelOrigin.origin' + formatttedOriginString
                                    })}
                                  </Typography>
                                </Box>
                                <Box display="flex" my={0.5}>
                                  {displayChip(appObj)}
                                </Box>
                              </Box>
                              {hasNotesSummary ? (
                                notesSummary ? (
                                  <Box className={classes.summaryContainer}>
                                    <Box mr={0.75}>
                                      <AISuggestionsIcon />
                                    </Box>
                                    <Box>
                                      <Typography
                                        className={`${classes.greenText} ${classes.notesSummaryText}`}
                                      >
                                        {notesSummary}
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box pt="20px">
                                    <Typography
                                      className={`${classes.noNotesText} ${classes.notesSummaryText}`}
                                    >
                                      <FormattedMessage
                                        id="OtherJobs.msg_noComments"
                                        defaultMessage="No notes in this opportunity"
                                      />
                                    </Typography>
                                  </Box>
                                )
                              ) : null}
                            </Box>
                          </ListItem>

                          {currIndex < applicantData.length - 1 && (
                            <Box>
                              <Divider />
                            </Box>
                          )}
                        </>
                      );
                    } else {
                      let statusIcon = (
                        <PlayCircleOutlineIcon
                          style={{
                            fontSize: '18px'
                          }}
                        />
                      );

                      if (job.status === 'COMPLETED') {
                        statusIcon = (
                          <PauseCircleOutlineOutlinedIcon
                            style={{
                              fontSize: '18px'
                            }}
                          />
                        );
                      }

                      if (job.status === 'CANCELED') {
                        statusIcon = (
                          <HighlightOffOutlinedIcon
                            style={{
                              fontSize: '18px'
                            }}
                          />
                        );
                      }

                      const path_avatar =
                        'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' +
                        job.userRecruiter.avatar_50_path;

                      return (
                        <>
                          <ListItem
                            role="listitem"
                            ContainerComponent="li"
                            style={{
                              padding: 0,
                              margin: '16px 0'
                            }}
                          >
                            <Box display="flex" flexDirection="column">
                              <Box mb={0.5}>
                                <Typography
                                  className={`${classes.darkText} ${classes.smallText}`}
                                  style={{
                                    fontWeight: 500
                                  }}
                                >
                                  {`[${job.code}] ${job.title}`}
                                </Typography>
                              </Box>

                              <Box display="flex" alignItems="center" mb={2}>
                                <Box display="flex" alignItems="center">
                                  <Box mr={0.5}>{statusIcon}</Box>
                                  <Typography
                                    className={`${classes.darkText} ${classes.smallerText}`}
                                  >
                                    {this.props.intl.formatMessage({
                                      id: 'JobsList.status_' + job.status
                                    })}
                                    <span
                                      style={{
                                        fontWeight: 500,
                                        margin: '0 4px'
                                      }}
                                    >
                                      &bull;
                                    </span>
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                  <Box mr={0.5} mb={job.userRecruiter.avatar_50_path ? 1 : 0}>
                                    <Avatar
                                      name={job.userRecruiter.username}
                                      src={path_avatar}
                                      size="16px"
                                      round={true}
                                    />
                                  </Box>
                                  <Typography
                                    className={`${classes.lightText} ${classes.smallerText}`}
                                  >
                                    {job.userRecruiter.username}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Divider
                                  style={{
                                    width: '60px'
                                  }}
                                />
                              </Box>
                              <Box display="flex" mt={2} maxWidth="275px">
                                <Box mr={0.55}>
                                  <ErrorOutlineIcon className={classes.notAccessibleIcon} />
                                </Box>
                                <Box pt={0.25}>
                                  <Typography className={classes.notAccessibleMsg}>
                                    <FormattedMessage
                                      id="OtherJobs.msg_notAccessibleJob"
                                      defaultMessage="You do not have permission to access information about this job."
                                    />
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </ListItem>

                          {currIndex < applicantData.length - 1 && (
                            <Box>
                              <Divider />
                            </Box>
                          )}
                        </>
                      );
                    }
                  })}
                </List>
              </Box>
            </Box>
          </Box>
        );
      } else {
        return null;
      }
    }
  }
}

OtherJobs.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  user: state.user.user
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(OtherJobs)));
