import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NoteAdd from '../Notes/NoteAdd';
import NoteList from '../Notes/NoteList';
import Tooltip from '@material-ui/core/Tooltip';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import UserProfile from '../../Common/UserProfile';

const messages = defineMessages({
  view: {
    id: 'ButtonNotes.view',
    defaultMessage: 'View note/s'
  }
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 8,
    right: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))(Badge);

class ButtonNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: props.applicant.notesCount !== null ? props.applicant.notesCount : props.count,
      applicant: props.applicant,
      readonly: props.readonly ? props.readonly : false,
      // Internal state
      open: false,
      notes: []
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({
      open: open
    });
  };

  handleUpdateNotes = (notes) => {
    this.setState(
      {
        count: notes.length
      },
      () => {
        if (this.props.handlerUpdate) {
          this.props.handlerUpdate(notes);
        }
      }
    );
  };

  handleAddNotes = (newNote) => {
    let notes = this.state.notes.map((a) => ({ ...a }));
    notes.unshift(newNote);

    this.setState(
      {
        notes: notes,
        count: notes.length
      },
      () => {
        if (this.props.handlerUpdate) {
          this.props.handlerUpdate(notes);
        }
      }
    );
  };

  render() {
    const applicant_id = this.state.applicant.id;
    const readonly = this.state.readonly;
    const count = this.state.count;

    const tooltip = this.props.intl.formatMessage({ id: 'ButtonNotes.view' });

    if (
      UserProfile.isAdmin() ||
      UserProfile.isHRManager() ||
      UserProfile.isRecruiter() ||
      UserProfile.isJobManager()
    ) {
      return (
        <span>
          <Tooltip title={tooltip} arrow>
            <StyledBadge color="secondary" badgeContent={count}>
              <IconButton color="default" onClick={this.toggleDrawer(true)}>
                <CommentOutlinedIcon />
              </IconButton>
            </StyledBadge>
          </Tooltip>

          <Drawer anchor="right" open={this.state.open} onClose={this.toggleDrawer(false)}>
            <div style={{ minWidth: '300px', width: '40vw' }}>
              <AppBar color="default" position="relative">
                <Toolbar variant="dense">
                  <IconButton edge="start" color="inherit" onClick={this.toggleDrawer(false)}>
                    <CloseIcon />
                  </IconButton>
                  <h6>
                    <FormattedMessage
                      id="ButtonNotes.modalTitle"
                      defaultMessage="Candidate notes"
                    />
                  </h6>
                </Toolbar>
              </AppBar>
              <div className="p-lg">
                {readonly === true ? null : (
                  <NoteAdd
                    applicant_id={applicant_id}
                    handlerUpdate={(newNote) => this.handleAddNotes(newNote)}
                  />
                )}

                <hr />
                <Row>
                  <Col sm={12}>
                    <NoteList
                      applicant_id={applicant_id}
                      updatedNotes={this.state.notes}
                      handlerUpdate={(notes) => this.handleUpdateNotes(notes)}
                      readonly={readonly}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Drawer>
        </span>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(ButtonNotes);
