import { SET_JOB_TAGS } from '../actions/actions';
import produce from 'immer';

const INITIAL_STATE = {
  tags: []
};

export default function jobTagsReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_JOB_TAGS: {
        draft.tags = action.payload;
        break;
      }

      default:
        return state;
    }
  });
}
