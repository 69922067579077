import Moment from 'moment';

// Period options
var getOptions = function (that) {
  return [
    {
      label: that.props.intl.formatMessage({ id: 'ButtonFilterPeriod.period_today' }),
      date_start: Moment().startOf('day').toDate(),
      date_end: Moment().endOf('day').toDate()
    },
    {
      label: that.props.intl.formatMessage({ id: 'ButtonFilterPeriod.period_yesterday' }),
      date_start: Moment().startOf('day').subtract(1, 'days').toDate(),
      date_end: Moment().endOf('day').subtract(1, 'days').toDate()
    },
    {
      label: that.props.intl.formatMessage({ id: 'ButtonFilterPeriod.period_thisWeek' }),
      date_start: Moment().startOf('isoWeek').startOf('day').toDate(),
      date_end: Moment().endOf('day').toDate()
    },
    {
      label: that.props.intl.formatMessage({ id: 'ButtonFilterPeriod.period_lastWeek' }),
      date_start: Moment().startOf('day').subtract(6, 'days').toDate(),
      date_end: Moment().endOf('day').toDate()
    },
    {
      label: that.props.intl.formatMessage({ id: 'ButtonFilterPeriod.period_last2Weeks' }),
      date_start: Moment().startOf('day').subtract(13, 'days').toDate(),
      date_end: Moment().endOf('day').toDate()
    },
    {
      label: that.props.intl.formatMessage({ id: 'ButtonFilterPeriod.period_last30Days' }),
      date_start: Moment().startOf('day').subtract(30, 'days').toDate(),
      date_end: Moment().endOf('day').toDate()
    },
    {
      label: that.props.intl.formatMessage({ id: 'ButtonFilterPeriod.period_thisMonth' }),
      date_start: Moment().startOf('month').startOf('day').toDate(),
      date_end: Moment().endOf('day').toDate()
    },
    {
      label: that.props.intl.formatMessage({ id: 'ButtonFilterPeriod.period_lastMonth' }),
      date_start: Moment().startOf('month').subtract(1, 'month').startOf('day').toDate(),
      date_end: Moment().endOf('month').subtract(1, 'month').endOf('month').toDate()
    },
    {
      label: that.props.intl.formatMessage({ id: 'ButtonFilterPeriod.period_thisTrimester' }),
      date_start: Moment().startOf('quarter').startOf('day').toDate(),
      date_end: Moment().endOf('day').toDate()
    },
    {
      label: that.props.intl.formatMessage({ id: 'ButtonFilterPeriod.period_lastTrimester' }),
      date_start: Moment().startOf('quarter').subtract(1, 'quarter').startOf('day').toDate(),
      date_end: Moment().endOf('quarter').subtract(1, 'quarter').endOf('month').toDate()
    }
  ];
};

export default getOptions;
