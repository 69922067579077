import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

const messages = defineMessages({
  label_hired: {
    id: 'ChipHired.label_hired',
    defaultMessage: 'Hired'
  }
});

const useStyles = (theme) => ({
  hiredChip: {
    background: theme.palette.success.light,
    color: theme.palette.success.main,
    fontSize: '12px',
    fontWeight: 400
    // marginLeft: theme.spacing(1)
  },
  emojiHiredChip: {
    color: theme.palette.success.main,
    width: '16px',
    height: '16px',
    marginLeft: theme.spacing(1)
  }
});

class ChipHired extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    const hired = this.props.hired;
    const origin_msg = (
      <Box display="flex" alignItems="center">
        {this.props.intl.formatMessage({ id: 'ChipHired.label_hired' })}
        <EmojiEventsIcon className={classes.emojiHiredChip} />
      </Box>
    );

    if (hired) {
      return <Chip className={classes.hiredChip} size="small" label={origin_msg} />;
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(ChipHired));
