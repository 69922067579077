import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Api from '../../../Api/Api';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import UserProfile from '../../../Common/UserProfile';
import ModalSingleCustomTestOptions from '../ModalSingleCustomTestOptions';

const messages = defineMessages({
  msg_formSent: {
    id: 'ModalForm.msg_formSent',
    defaultMessage: 'Form successfully sent'
  },
  msg_testSent: {
    id: 'ModalForm.msg_testSent',
    defaultMessage: 'Test successfully sent'
  },
  msg_formSentError: {
    id: 'ModalForm.msg_formSentError',
    defaultMessage: 'Error sending form'
  },
  msg_testSentError: {
    id: 'ModalForm.msg_testSentError',
    defaultMessage: 'Error sending test'
  }
});

const useStyles = (theme) => ({
  dialogTitle: {
    color: theme.palette.gray.darker,
    lineHeight: 'normal',
    fontWeight: 700,
    fontSize: '16px'
  }
});

class ModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.type,
      open: props.open,
      applicant: props.applicant,
      job: props.job,
      // Internal State
      customTestList: [],
      customTestSelected: null,
      name: '',
      custom_test_id: '',
      loading: true,
      modalOpen: false,
      submitingCustomTest: false
    };
  }

  componentDidMount() {
    this.loadForms();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open,
        customTestSelected: null,
        custom_test_id: ''
      });
    }

    if (prevProps.type !== this.props.type) {
      this.setState(
        {
          type: this.props.type
        },
        () => {
          this.loadForms();
        }
      );
    }

    if (prevProps.job !== this.props.job) {
      this.setState({
        job: this.props.job
      });
    }
  }

  loadForms = () => {
    if (this.state.type) {
      let api = new Api();

      this.setState(
        {
          loading: true
        },
        () => {
          let calcScoreFilter = {};

          if (this.state.type === 'TEST') {
            calcScoreFilter = {
              calc_score: true
            };
          }

          if (this.state.type === 'FORM') {
            calcScoreFilter = {
              calc_score: false
            };
          }

          let filter = {
            fields: ['id', 'name', 'active'],
            order: ['active DESC', 'name'],
            where: {
              type: { neq: 'TYPEFORM' },
              ...calcScoreFilter
            }
          };

          api.get('CustomTests', filter, (status, data) => {
            //console.log(data);

            let customTestList = [];
            let customTestSelected = null;
            data.forEach((test) => {
              customTestList.push({
                value: test.id,
                label: test.name,
                active: test.active
              });
              if (this.state.custom_test_id === test.id) {
                customTestSelected = {
                  value: test.id,
                  label: test.name,
                  active: test.active
                };
              }
            });

            //console.log(customTestSelected);

            this.setState({
              customTestList: customTestList,
              customTestSelected: customTestSelected,
              loading: false
            });
          });
        }
      );
    }
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleChangeCustomTest = (e, selected) => {
    let value = null;
    if (selected) {
      value = selected.value;
    }
    this.setState(
      {
        customTestSelected: selected,
        custom_test_id: value,
        modalOpen: true
      },
      () => {
        if (selected) {
          this.setState({
            modalOpen: true
          });
        }
      }
    );
  };

  handleCloseModal = () => {
    this.setState(
      {
        open: false,
        custom_test_id: ''
      },
      () => {
        if (this.props.handleClose) {
          this.props.handleClose();
        }
      }
    );
  };

  handleFormModalClose = () => {
    this.setState({
      modalOpen: false
    });
  };

  handleModalPost = ({ ids, options }) => {
    this.setState(
      {
        submitingCustomTest: true
      },
      () => {
        const job = this.state.job;
        const applicant = this.state.applicant;
        const pipeline_stages = job ? job.pipeline_stages : undefined;

        let thisStageObj = {};

        if (pipeline_stages && pipeline_stages.length > 0) {
          thisStageObj = pipeline_stages.find(
            (currStage) => currStage.id === applicant.pipeline_stage
          );
        }
        const payload = ids.map((applicant_id) => {
          // console.log(applicant_id);
          return {
            account_id: UserProfile.getRealm(),
            job_id: job.id,
            applicant_id: applicant.id,
            pipeline_stage:
              thisStageObj && thisStageObj.id ? thisStageObj.id : applicant.pipeline_stage,
            custom_test_id: this.state.custom_test_id,
            ...options
          };
        });

        let api = new Api();

        api.post('ApplicantCustomTests', payload, (status, data) => {
          //console.log(data);
          this.setState(
            {
              submitingCustomTest: false,
              modal: false
            },
            () => {
              if (this.props.handleUpdateTests) {
                this.props.handleUpdateTests();
                this.handleCloseModal();
              }
            }
          );
        });
      }
    );
  };

  render() {
    const { classes } = this.props;
    const applicant = this.state.applicant;
    const job = this.state.job;

    const pipeline_stages = job ? job.pipeline_stages : undefined;

    let thisStageObj = {};

    if (pipeline_stages && pipeline_stages.length > 0) {
      thisStageObj = pipeline_stages.find((currStage) => currStage.id === applicant.pipeline_stage);
    } else {
      thisStageObj = {
        name: '',
        type: ''
      };
    }

    const label =
      this.state.type === 'TEST' ? (
        <FormattedMessage id="ModalForm.label_test" defaultMessage="Online Test" />
      ) : (
        <FormattedMessage id="ModalForm.label_form" defaultMessage="Online Form" />
      );

    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={this.state.open}
          onClose={this.handleCloseModal}
        >
          <DialogTitle>
            <Typography className={classes.dialogTitle}>
              {this.state.type === 'TEST' ? (
                <FormattedMessage id="ModalForm.title_selectTest" defaultMessage="Select Test" />
              ) : (
                <FormattedMessage
                  id="ModalForm.title_selectForm"
                  defaultMessage="Select Custom Form"
                />
              )}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Autocomplete
                id="custom_test_id"
                value={this.state.customTestSelected}
                options={this.state.customTestList}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                getOptionDisabled={(option) => option.active == false}
                groupBy={(option) => option.active}
                onChange={this.handleChangeCustomTest}
                loading={this.state.loading}
                disabled={this.state.loading}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={this.state.formRequired}
                    label={label}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal} color="disabled">
              <FormattedMessage id="Base.button.cancel" />
            </Button>
            {/* <Button
              type="submit"
              color="primary"
              disabled={this.state.loading || !this.state.custom_test_id}
              onClick={this.sendNewForm}
            >
              {this.state.type === 'TEST' ? (
                <FormattedMessage id="ModalForm.button_sendTest" defaultMessage="Send Test" />
              ) : (
                <FormattedMessage id="ModalForm.button_sendForm" defaultMessage="Send Form" />
              )}
            </Button> */}
          </DialogActions>
          <ModalSingleCustomTestOptions
            open={this.state.modalOpen}
            form={this.state.customTestSelected}
            nextStageObj={thisStageObj}
            job_id={applicant.job_id}
            selected_ids={[applicant.id]}
            selected_objs={[applicant]}
            handlerClose={this.handleFormModalClose}
            handlerPost={this.handleModalPost}
            submiting={this.state.submiting}
          />
        </Dialog>
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ModalForm));
