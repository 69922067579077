import Api from '../Api/Api';
import UserProfile from '../Common/UserProfile';
import * as CONST from '../Common/constants';

var StageController = (function () {
  var moveStage = function (
    that,
    old_stage,
    new_stage,
    job_id,
    applicants,
    execute_actions,
    options,
    cb
  ) {
    let arr_applicants = [];

    if (applicants instanceof Array) {
      arr_applicants = applicants;
    } else if (applicants instanceof Object) {
      arr_applicants.push(applicants.id);
    } else {
      return cb('ERROR: No applicant/s found');
    }

    let api = new Api();
    const payload = {
      job_id: job_id,
      old_stage: old_stage,
      new_stage: new_stage,
      applicants: arr_applicants,
      execute_actions: execute_actions,
      options: options
    };
    api.post('Jobs/move-stage', payload, (status, data) => {
      if (status > 200) {
        if (data.error.name === 'DISPATCH_INTEGRATION_ERROR') {
          window.swal({
            type: 'error',
            title: that.props.intl.formatMessage(
              { id: 'IntegrationCheckboxes.error_dispatch_integration' },
              {
                integration: data.error && data.error.integration ? data.error.integration : ''
              }
            ),
            text: that.props.intl.formatMessage(
              { id: 'IntegrationCheckboxes.error_integration_msg' },
              {
                error: data.error && data.error.message ? data.error.message : 'Bad Request'
              }
            ),
            closeOnConfirm: true
          });
        } else {
          window.swal({
            type: 'error',
            title: '',
            text: data.error,
            closeOnConfirm: true
          });
        }
      } else {
        return cb(null, data);
      }
    });
  };

  var rateVideoAnswer = function (
    videoanswer_id,
    videointerview_id,
    job_id,
    applicant_id,
    rating,
    cb
  ) {
    let api = new Api();

    let where = {
      account_id: UserProfile.getRealm(),
      job_id: job_id,
      applicant_id: applicant_id,
      videointerview_id: videointerview_id,
      videoanswer_id: videoanswer_id,
      user_id: UserProfile.getId()
    };
    const payload = {
      ...where,
      rating: rating,
      date_created: new Date()
    };

    api.upsertWhere('VideoInterviewRatings', payload, where, () => {
      delete where.user_id;
      delete where.videoanswer_id;
      let filter = {
        where: where
      };

      api.get('VideoInterviewRatings', filter, (status, ratings) => {
        //console.log(ratings);

        const new_avg =
          ratings.reduce((a, { rating }) => a + parseFloat(rating), 0) / ratings.length;
        //console.log(new_avg);

        const user_avg =
          ratings.reduce(
            (a, { rating, user_id }) =>
              parseInt(user_id) === UserProfile.getId() ? a + parseFloat(rating) : a + 0,
            0
          ) / ratings.length;
        //console.log(user_avg);

        return cb(null, user_avg, new_avg);
      });
    });
  };

  var rateInterview = function (interview_id, job_id, applicant_id, rating, cb) {
    let api = new Api();

    let where = {
      account_id: UserProfile.getRealm(),
      job_id: job_id,
      applicant_id: applicant_id,
      interview_id: interview_id,
      user_id: UserProfile.getId(),
      deleted: false
    };
    const payload = {
      ...where,
      rating: rating,
      date_created: new Date()
    };

    api.upsertWhere('InterviewRatings', payload, where, () => {
      delete where.user_id;
      let filter = {
        where: where
      };

      api.get('InterviewRatings', filter, (status, ratings) => {
        //console.log(ratings);

        const new_avg =
          ratings.reduce((a, { rating }) => a + parseFloat(rating), 0) / ratings.length;
        //console.log(new_avg);

        return cb(null, new_avg);
      });
    });
  };

  var jobStageView = function (job_id, pipeline_stage, cb) {
    let api = new Api();
    let payload = {
      account_id: UserProfile.getRealm(),
      job_id: job_id,
      pipeline_stage: pipeline_stage,
      user_id: UserProfile.getId()
    };
    api.post('JobStageUserViews', payload, () => {
      return cb(null);
    });
  };

  var stageInfo = function (that, pipeline_stage, stages) {
    let stageObj = {
      name: undefined,
      type: undefined,
      priority: undefined
    };
    let stageName;

    if (
      pipeline_stage !== null &&
      pipeline_stage !== undefined &&
      stages &&
      stages instanceof Array
    ) {
      let stageList = [];

      stageList.push(CONST.STAGE_SAVEDLATER_OBJ);

      stageList = stageList.concat(stages);

      stageList.some((stage) => {
        if (parseInt(pipeline_stage) === parseInt(stage.id)) {
          stageObj = stage;
          stageName = stage.name.toLowerCase();
          return true;
        }
      });

      if (that.props.intl.formatMessage({ id: 'Pipeline.' + stageName }) !== undefined) {
        stageName = that.props.intl.formatMessage({ id: 'Pipeline.' + stageName });
      }
      if (stageName.startsWith('Pipeline.')) {
        stageName = stageObj.name;
      }

      stageObj.name = stageName;
    }

    return stageObj;
  };

  var isPipelineValid = function (pipeline_stages) {
    let valid = false;
    if (pipeline_stages && pipeline_stages.length > 0) {
      valid = true;

      pipeline_stages.some((stage) => {
        // Checking no custom_test_id defined
        if (stage.type === CONST.STAGE_CUSTOMTEST && !stage.custom_test_id) {
          valid = false;
          return true;
        }
      });
    }
    return valid;
  };

  return {
    moveStage: moveStage,
    rateVideoAnswer: rateVideoAnswer,
    rateInterview: rateInterview,
    jobStageView: jobStageView,
    stageInfo: stageInfo,
    isPipelineValid: isPipelineValid
  };
})();

export default StageController;
