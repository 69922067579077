import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';
import Moment from 'moment';
import UserProfile from '../../../Common/UserProfile';
import StageController from '../../../Pipeline/StageController';
import Api from '../../../Api/Api';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Replay10Icon from '@material-ui/icons/Replay10';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Forward10Icon from '@material-ui/icons/Forward10';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Chip from '@material-ui/core/Chip';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import '../../Card/CardVideoPlayer.css';
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined';
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import JobInfo from './JobInfo';
import TimerIcon from '@material-ui/icons/Timer';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import * as CONST from '../../../Common/constants';

const useStyles = (theme) => ({
  cardRoot: {
    //border: '1px solid ' + theme.palette.gray.main,
    boxShadow: 'none',
    border: 'none',
    maxWidth: 360,
    minWidth: 230
    // width: 360
    //width: 300
  },
  cardContent: {
    padding: 0
  },
  chipRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    },
    overflow: 'auto',
    maxHeight: 150
  },
  listRoot: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 150
  },
  listItem: {
    marginTop: 0,
    marginBottom: 0
  },
  player: {
    borderRadius: '20px',
    overflow: 'hidden',
    backgroundColor: theme.palette.gray.lighter
  },
  audioPlayer: {
    // width: 360,
    width: '230px !important',
    height: 'auto',
    borderRadius: 6,

    '> video': {
      height: 'auto',
      width: '-webkit-fill-available'
    }
  },
  noplayer: {
    // width: 360,
    width: 300,
    height: 'auto',
    borderRadius: 6
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: 0
  },
  playIcon: {
    color: theme.palette.common.white,
    width: '24px',
    height: '24px'
  },
  grayLightSubtitle: {
    color: theme.palette.gray.darkerLight,
    fontSize: '14px',
    fontWeight: '400',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '16px' /* 114.286% */
  },
  cardBorder: {
    '.MuiPaper-rounded': {
      borderRadius: 0
    },
    '& .MuiPaper-rounded': {
      borderRadius: 0
    }
  },
  playerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '50px',
    overflowY: 'hidden',
    textAlign: 'left',
    zIndex: 10,
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.common.white} !important`,
    padding: '10px'
  },
  playerContainer: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    marginTop: theme.spacing(0.5)
  },
  playerWrapper: {
    position: 'relative',
    borderRadius: '1rem',
    height: '360px',
    backgroundColor: theme.palette.gray.lighter
  },
  overlay: {
    bottom: '0',
    left: '0',
    color: theme.palette.common.white,
    width: '100%',
    position: 'absolute',
    fontSize: '24px',
    background: `linear-gradient(180deg, ${theme.palette.black.main}00 0%,  ${theme.palette.black.main}BF 33.64%)`,
    borderRadius: '0 0 20px 20px'
  },
  audioOverlay: {
    background: 'none'
  },
  questionText: {
    color: theme.palette.common.white,
    textOverflow: 'hidden',
    overflow: 'hidden',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px' /* 157.143% */,
    maxHeight: 'calc(22px * 2)',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    transition: 'all 0.3s ease-in-out'
  },
  audioText: {
    color: theme.palette.gray.darker
  },
  expandedQuestionText: {
    maxHeight: '10rem',
    WebkitLineClamp: '10'
  },
  userCreatedName: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px' /* 150% */,
    position: 'relative'
  },
  bufferColor: {
    backgroundColor: theme.palette.gray.darkerMedium
  },
  backgroundBarcolorPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  playButton: {
    position: 'absolute',
    top: 'calc(50% - 24px)',
    right: 'calc(50% - 24px)'
  },
  infoButton: {
    position: 'absolute',
    top: '10px',
    right: '10px'
  },
  infoIcon: {
    color: theme.palette.common.white
  },
  questionBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  expandQuestionIcon: {
    color: theme.palette.common.white
  },
  durationText: {
    color: theme.palette.gray.darker,
    fontSize: '12px',
    lineHeight: 'normal'
  },
  durationIcon: {
    color: theme.palette.gray.darker
  },
  grayIcon: {
    color: theme.palette.gray.darkerRegular
  },
  expandIconButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    padding: 0
  }
});

class CardVideoPlayer2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video_question: props.video_question,
      video_answer: props.video_answer,
      video_url: props.video_url,
      thumbnail_url: props.thumbnail_url,
      job_id: props.job_id,
      answer_status: props.answer_status,
      readonly: props.readonly,
      pause: props.pause,
      // Internal state
      user_rating: 0,
      keywords: [],
      transcript: [],
      // Video controls
      ready: false,
      playing: false,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      playedSeconds: 0,
      duration: 0,
      playbackRate: 1,
      loop: false,
      active_seek: -1,
      playbackRateOpts: [1, 1.25, 1.5],
      expanded: false,
      started: false,
      question: undefined,
      expandQuestion: false,
      //Info

      infoAnchorEl: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.pause !== prevProps.pause) {
      this.setState(
        {
          pause: this.props.pause
        },
        () => {
          if (this.state.pause) {
            this.setState({
              playing: false
            });
          }
        }
      );
    }
  }

  componentDidMount() {
    //this.getKeywords();
    //this.getTranscript();
    this.getUserRating();

    let question = this.state.video_question.question;

    if (this.state.video_question && !this.state.readonly) {
      if (question && question.length >= 60 && this.state.expandQuestion === false) {
        question = question.slice(0, 59) + '...';
      }
    }

    this.setState({
      question: question
    });
  }

  getKeywords() {
    const video_answer = this.state.video_answer;

    let entitiesArr = [];
    if (video_answer && video_answer.engine_response) {
      if (
        video_answer.engine_response.summarizedInsights &&
        video_answer.engine_response.summarizedInsights.keywords.length > 0
      ) {
        video_answer.engine_response.summarizedInsights.keywords.forEach((keyword) => {
          let obj = {
            entity: keyword.name,
            appearances: keyword.appearances
          };
          entitiesArr.push(obj);
        });
      }
      if (
        video_answer.engine_response.summarizedInsights &&
        video_answer.engine_response.summarizedInsights.brands.length > 0
      ) {
        video_answer.engine_response.summarizedInsights.brands.forEach((brand) => {
          let obj = {
            entity: brand.name,
            appearances: brand.appearances
          };
          entitiesArr.push(obj);
        });
      }
    }
    this.setState({
      keywords: entitiesArr
    });
  }

  getTranscript() {
    const video_answer = this.state.video_answer;

    let transcript = [];
    if (video_answer && video_answer.engine_response) {
      if (
        video_answer.engine_response.videos &&
        video_answer.engine_response.videos.length > 0 &&
        video_answer.engine_response.videos[0].insights.transcript &&
        video_answer.engine_response.videos[0].insights.transcript.length > 0
      ) {
        video_answer.engine_response.videos[0].insights.transcript.forEach((phrase) => {
          transcript.push({
            order: phrase.id,
            text: phrase.text,
            start: phrase.instances[0].start,
            end: phrase.instances[0].end
          });
        });
      }

      this.setState({
        transcript: transcript
      });
    }
  }

  getUserRating() {
    const video_answer = this.state.video_answer;

    let user_rating = 0;
    if (video_answer && video_answer.ratings) {
      if (video_answer.ratings.length > 0) {
        video_answer.ratings.forEach((rating) => {
          if (rating.user_id == UserProfile.getId()) {
            user_rating = rating.rating;
          }
        });
      }
    }
    this.setState({
      user_rating: user_rating
    });
  }

  handleError = (e) => {
    alert(e);
  };

  handleStart = () => {
    const video = this.state.video_answer;

    let api = new Api();
    let payload = {
      account_id: UserProfile.getRealm(),
      videointerview_id: video.videointerview_id,
      videoanswer_id: video.id,
      user_id: UserProfile.getId(),
      action: 'PLAY'
    };
    api.post('VideoInterviewViews', payload, (status, data) => {
      //console.log('video play');

      this.setState({
        started: true
      });
    });
  };

  handleReady = () => {
    //console.log("video ready")
    this.setState({
      ready: true
    });
  };

  handlePlayPause = () => {
    //console.log("play / pause");
    this.setState({ playing: !this.state.playing }, () => {
      if (this.state.playing === true && this.props.handlePauseOtherVideos) {
        this.props.handlePauseOtherVideos(this.state.video_answer.id);
      }
    });
  };

  handleEnded = () => {
    const video = this.state.video_answer;
    let api = new Api();
    let payload = {
      account_id: UserProfile.getRealm(),
      videointerview_id: video.videointerview_id,
      videoanswer_id: video.id,
      user_id: UserProfile.getId(),
      action: 'END'
    };
    api.post('VideoInterviewViews', payload, (status, data) => {
      //console.log('video ended');
    });
  };

  handleProgress = (state) => {
    //console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleDuration = (duration) => {
    //console.log('onDuration', duration)
    this.setState({ duration });
  };

  handleSetPlaybackRate = () => {
    const current = this.state.playbackRate;
    let pos = this.state.playbackRateOpts.indexOf(current);
    if (pos === 2) {
      pos = -1;
    }
    let next = this.state.playbackRateOpts[pos + 1];

    //console.log(next);
    this.setState({ playbackRate: parseFloat(next) });
  };

  seekTo = (sec, index) => {
    this.player.seekTo(sec, 'seconds');
    if (index !== null || index !== undefined) {
      this.setState({
        active_seek: index
      });
    }
  };

  handlePrev10 = () => {
    let sec = this.state.playedSeconds - 10;
    if (sec < 0) {
      sec = 0;
    }
    this.player.seekTo(sec, 'seconds');
  };

  handleNext10 = () => {
    let sec = this.state.playedSeconds + 10;
    if (sec > this.state.duration) {
      sec = this.state.duration;
    }
    this.player.seekTo(sec, 'seconds');
  };

  changeRating = (event, newRating) => {
    if (newRating) {
      this.setState(
        {
          user_rating: newRating
        },
        () => {
          const video = this.state.video_answer;

          StageController.rateVideoAnswer(
            video.id,
            video.videointerview_id,
            video.job_id,
            video.applicant_id,
            newRating,
            (err, user_avg, avg_rating) => {
              if (err) {
                //console.log(err);
                window.swal({
                  title: 'Error',
                  text: err,
                  closeOnConfirm: true
                });
              } else {
                if (this.props.handlerUpdate && video.applicant_id) {
                  this.props.handlerUpdate(
                    {
                      id: video.applicant_id
                    },
                    {
                      user_rating: user_avg,
                      avg_rating: avg_rating
                    }
                  );
                }
              }
            }
          );
        }
      );
    }
  };

  ref = (player) => {
    this.player = player;
  };

  handleExpandQuestion = () => {
    // Currently open and it's gonna be closed
    let question = this.state.video_question.question;
    if (question && question.length >= 60 && this.state.expandQuestion === false) {
      question = question.slice(0, 59) + '...';
    }

    this.setState({ expandQuestion: !this.state.expandQuestion, question: question });
  };

  handleOpenInfo = (e) => {
    this.setState({
      infoAnchorEl: e.currentTarget
    });
  };

  handleCloseInfo = () => {
    this.setState({
      infoAnchorEl: null
    });
  };

  render() {
    const { classes } = this.props;

    const video_question = this.state.video_question;
    const video_answer = this.state.video_answer;
    //console.log(video_answer);

    const hasVideoAnswer = !!this.state.video_answer;

    const isLongQuestion = video_question.question && video_question.question.length >= 60;

    const infoOpen = Boolean(this.state.infoAnchorEl);

    const listKeywords = this.state.keywords.map((entity, i) => {
      let color = 'secondary';
      let variant = 'outlined';
      let count = 1;
      if (entity.appearances && entity.appearances.length > 1) {
        count = entity.appearances.length;
      }
      if (this.state.active_seek === i) {
        color = 'primary';
        variant = 'default';
      }
      return (
        <Chip
          key={i}
          variant={variant}
          color={color}
          size="small"
          onClick={() => this.seekTo(entity.appearances[0].startSeconds, i)}
          label={
            <span>
              {entity.entity} <b>{count}</b>
            </span>
          }
          deleteIcon={<ArrowDropDownIcon />}
          //onDelete={() => alert('OK')}
        />
      );
    });

    const listTranscript = this.state.transcript.map((phrase, i) => {
      // if (this.state.active_seek === i) {
      // }
      return (
        <>
          <ListItem
            key={i}
            className={classes.listItem}
            //onClick={() => this.seekTo(phrase.start, i)}
          >
            <ListItemText
              className={classes.listItem}
              primary={<small>{phrase.text}</small>}
              secondary={
                <small>
                  {phrase.start}-{phrase.end}
                </small>
              }
            />
          </ListItem>
          <Divider variant="middle" />
        </>
      );
    });

    const displayTime = () => {
      if (this.state.video_answer) {
        return (
          <FormattedMessage
            id="CardVideoPlayer.dateAnswer"
            values={{
              date: Moment(video_answer.date_created).format('lll')
            }}
          />
        );
      } else {
        return <FormattedMessage id="CardVideoPlayer.noAnswer" />;
      }
    };

    const displayInsights = () => {
      if (
        this.state.video_answer &&
        (this.state.keywords.length === 0 || this.state.transcript.length === 0)
      ) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            p={2}
            css={{ width: '100%', height: 150 }}
          >
            <Box className="text-center">
              <BlurOffIcon color="secondary" style={{ fontSize: 40 }} />
              <Typography component="h6" variant="h6" color="textSecondary">
                <FormattedMessage id="CardVideoPlayer.noInsightsMsg" />
              </Typography>
            </Box>
          </Box>
        );
      } else {
        return (
          <Box display="flex" alignContent="center" css={{ width: '100%', height: 150 }}>
            <Box
              width="40%"
              p={1}
              className="text-center text-sm"
              style={{ borderRight: `1px solid ${CONST.APP_COLORS['black']}14` }}
            >
              <div className={classes.chipRoot}>{listKeywords}</div>
            </Box>
            <Box width="60%" p={1} className="text-center text-sm">
              <div>
                <List dense={true} className={classes.listRoot}>
                  {listTranscript}
                </List>
              </div>
            </Box>
          </Box>
        );
      }
    };

    const displayControls = () => {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            style={{
              justifyContent: hasVideoAnswer ? 'none' : 'flex-end',
              padding: '8px 4px'
            }}
          >
            {hasVideoAnswer && (
              <>
                <Box mr={0.5}>
                  <IconButton
                    aria-label="play/pause"
                    disabled={!hasVideoAnswer || !this.state.ready}
                    onClick={() => this.handlePlayPause()}
                    style={{
                      padding: '4px'
                    }}
                  >
                    {this.state.playing ? (
                      <PauseIcon
                        className={`${classes.playIcon} ${
                          this.state.video_question.interviewType === 'AUDIO'
                            ? classes.grayIcon
                            : ''
                        }`}
                      />
                    ) : (
                      <PlayArrowIcon
                        className={`${classes.playIcon} ${
                          this.state.video_question.interviewType === 'AUDIO'
                            ? classes.grayIcon
                            : ''
                        }`}
                      />
                    )}
                  </IconButton>
                </Box>
                <Box width="100%" mr={1}>
                  <LinearProgress
                    variant="buffer"
                    color="primary"
                    value={this.state.played * 100}
                    valueBuffer={this.state.loaded * 100}
                    classes={{
                      colorPrimary: classes.bufferColor,
                      buffer: classes.bufferColor,
                      bar1Buffer: classes.backgroundBarcolorPrimary
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      );
    };

    const displayPlayer = () => {
      // console.log(this.state.video_answer)
      if (this.state.video_question.interviewType === 'VIDEO') {
        if (this.state.video_answer) {
          return (
            <Box className={classes.playerWrapper}>
              <ReactPlayer
                ref={this.ref}
                url={this.state.video_url}
                playing={this.state.playing}
                controls={false}
                playbackRate={this.state.playbackRate}
                width="auto"
                height="360px"
                className={classes.player}
                onReady={this.handleReady}
                onStart={this.handleStart}
                onEnded={this.handleEnded}
                onError={this.handleError}
                onProgress={this.handleProgress}
                onDuration={this.handleDuration}
                config={{
                  file: {
                    attributes: {
                      autoplay: false,
                      poster: this.state.thumbnail_url,
                      controlsList: 'nodownload'
                    }
                  }
                }}
              />
              <Box className={classes.overlay}>
                {this.state.started ? (
                  displayControls()
                ) : (
                  <Box
                    className={classes.questionBox}
                    style={{
                      padding:
                        isLongQuestion && !this.state.readonly
                          ? '16px 8px 8px 8px'
                          : '16px 8px 24px 8px'
                    }}
                  >
                    <Typography
                      className={clsx(classes.questionText, {
                        [classes.expandedQuestionText]:
                          this.state.expandQuestion || this.state.readonly
                      })}
                    >
                      {video_question.question}
                    </Typography>
                    {isLongQuestion && !this.state.readonly ? (
                      <IconButton
                        className={classes.expandIconButton}
                        onClick={this.handleExpandQuestion}
                      >
                        {this.state.expandQuestion ? (
                          <KeyboardArrowDownIcon className={classes.expandQuestionIcon} />
                        ) : (
                          <KeyboardArrowUpIcon className={classes.expandQuestionIcon} />
                        )}
                      </IconButton>
                    ) : null}
                  </Box>
                )}
              </Box>
              {this.state.started ? null : (
                <Box className={classes.playButton}>
                  <IconButton
                    onClick={() => this.handlePlayPause()}
                    style={{
                      padding: 0
                    }}
                  >
                    <PlayCircleOutlineIcon
                      color="primary"
                      style={{
                        width: '48px',
                        height: '48px'
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              <Box className={classes.infoButton}>
                <IconButton
                  onClick={(e) => this.handleOpenInfo(e)}
                  style={{
                    padding: 0
                  }}
                >
                  <InfoOutlinedIcon fontSize="small" className={classes.infoIcon} />
                </IconButton>
              </Box>

              <Popover
                open={infoOpen}
                anchorEl={this.state.infoAnchorEl}
                onClose={this.handleCloseInfo}
              >
                <Box maxHeight="440px" width="300px">
                  <JobInfo
                    answer_status={this.state.answer_status}
                    video_answer={this.state.video_answer}
                    job_id={this.state.job_id}
                    user_id={this.state.video_question.user_id}
                  />
                </Box>
              </Popover>
            </Box>
          );
        } else {
          return (
            <Box className={classes.playerWrapper}>
              <CardMedia
                component="div"
                style={{
                  height: '360px',
                  width: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                className={classes.player}
              >
                <VideocamOffOutlinedIcon
                  style={{ fontSize: '48px', padding: '10px' }}
                  className={classes.grayIcon}
                />
              </CardMedia>
              <Box className={`${classes.overlay} ${classes.audioOverlay}`}>
                <Box
                  className={classes.questionBox}
                  style={{
                    padding:
                      isLongQuestion && !this.state.readonly
                        ? '16px 8px 8px 8px'
                        : '16px 8px 24px 8px'
                  }}
                >
                  <Typography
                    className={clsx([classes.questionText, classes.audioText], {
                      [classes.expandedQuestionText]:
                        this.state.expandQuestion || this.state.readonly
                    })}
                  >
                    {video_question.question}
                  </Typography>
                  {isLongQuestion && !this.state.readonly ? (
                    <IconButton
                      className={classes.expandIconButton}
                      onClick={this.handleExpandQuestion}
                    >
                      {this.state.expandQuestion ? (
                        <KeyboardArrowDownIcon
                          className={`${classes.expandQuestionIcon} ${classes.grayIcon}`}
                        />
                      ) : (
                        <KeyboardArrowUpIcon
                          className={`${classes.expandQuestionIcon} ${classes.grayIcon}`}
                        />
                      )}
                    </IconButton>
                  ) : null}
                </Box>
              </Box>

              <Box className={classes.infoButton}>
                <IconButton
                  onClick={(e) => this.handleOpenInfo(e)}
                  style={{
                    padding: 0
                  }}
                >
                  <InfoOutlinedIcon fontSize="small" className={classes.grayIcon} />
                </IconButton>
              </Box>

              <Popover
                open={infoOpen}
                anchorEl={this.state.infoAnchorEl}
                onClose={this.handleCloseInfo}
              >
                <Box maxHeight="440px" width="300px">
                  <JobInfo
                    answer_status={this.state.answer_status}
                    video_answer={this.state.video_answer}
                    job_id={this.state.job_id}
                    user_id={this.state.video_question.user_id}
                  />
                </Box>
              </Popover>
            </Box>
          );
        }
      } else {
        if (this.state.video_answer) {
          return (
            <Box className={classes.playerWrapper}>
              <ReactPlayer
                ref={this.ref}
                url={this.state.video_url}
                playing={this.state.playing}
                controls={false}
                playbackRate={this.state.playbackRate}
                // width="360px"
                //width="auto"
                //height="360px"
                className={classes.audioPlayer}
                onReady={this.handleReady}
                onStart={this.handleStart}
                onEnded={this.handleEnded}
                onError={this.handleError}
                onProgress={this.handleProgress}
                onDuration={this.handleDuration}
                config={{
                  file: {
                    attributes: {
                      autoplay: false,
                      poster: this.state.thumbnail_url,
                      controlsList: 'nodownload'
                    }
                  }
                }}
              />
              <Box className={`${classes.overlay} ${classes.audioOverlay}`}>
                {this.state.started ? (
                  displayControls()
                ) : (
                  <Box
                    className={classes.questionBox}
                    style={{
                      padding:
                        isLongQuestion && !this.state.readonly
                          ? '16px 8px 8px 8px'
                          : '16px 8px 24px 8px'
                    }}
                  >
                    <Typography
                      className={clsx([classes.questionText, classes.audioText], {
                        [classes.expandedQuestionText]:
                          this.state.expandQuestion || this.state.readonly
                      })}
                    >
                      {video_question.question}
                    </Typography>
                    {isLongQuestion && !this.state.readonly ? (
                      <IconButton
                        className={classes.expandIconButton}
                        onClick={this.handleExpandQuestion}
                      >
                        {this.state.expandQuestion ? (
                          <KeyboardArrowDownIcon
                            className={`${classes.expandQuestionIcon} ${classes.grayIcon}`}
                          />
                        ) : (
                          <KeyboardArrowUpIcon
                            className={`${classes.expandQuestionIcon} ${classes.grayIcon}`}
                          />
                        )}
                      </IconButton>
                    ) : null}
                  </Box>
                )}
              </Box>
              {this.state.started ? null : (
                <Box className={classes.playButton}>
                  <IconButton
                    onClick={() => this.handlePlayPause()}
                    style={{
                      padding: 0
                    }}
                  >
                    <PlayCircleOutlineIcon
                      color="primary"
                      style={{
                        width: '48px',
                        height: '48px'
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              <Box className={classes.infoButton}>
                <IconButton
                  onClick={(e) => this.handleOpenInfo(e)}
                  style={{
                    padding: 0
                  }}
                >
                  <InfoOutlinedIcon fontSize="small" className={classes.grayIcon} />
                </IconButton>
              </Box>

              <Popover
                open={infoOpen}
                anchorEl={this.state.infoAnchorEl}
                onClose={this.handleCloseInfo}
              >
                <Box maxHeight="440px" width="300px">
                  <JobInfo
                    answer_status={this.state.answer_status}
                    video_answer={this.state.video_answer}
                    job_id={this.state.job_id}
                    user_id={this.state.video_question.user_id}
                  />
                </Box>
              </Popover>
            </Box>
          );
        } else {
          return (
            <Box className={classes.playerWrapper}>
              <CardMedia
                component="div"
                style={{
                  // height: '200px',
                  height: '360px',
                  width: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                className={classes.player}
              >
                <MicOffOutlinedIcon
                  style={{ fontSize: '48px', padding: '10px' }}
                  className={classes.grayIcon}
                />
              </CardMedia>
              <Box className={`${classes.overlay} ${classes.audioOverlay}`}>
                <Box
                  className={classes.questionBox}
                  style={{
                    padding:
                      isLongQuestion && !this.state.readonly
                        ? '16px 8px 8px 8px'
                        : '16px 8px 24px 8px'
                  }}
                >
                  <Typography
                    className={clsx([classes.questionText, classes.audioText], {
                      [classes.expandedQuestionText]:
                        this.state.expandQuestion || this.state.readonly
                    })}
                  >
                    {video_question.question}
                  </Typography>
                  {isLongQuestion && !this.state.readonly ? (
                    <IconButton
                      className={classes.expandIconButton}
                      onClick={this.handleExpandQuestion}
                    >
                      {this.state.expandQuestion ? (
                        <KeyboardArrowDownIcon
                          className={`${classes.expandQuestionIcon} ${classes.grayIcon}`}
                        />
                      ) : (
                        <KeyboardArrowUpIcon
                          className={`${classes.expandQuestionIcon} ${classes.grayIcon}`}
                        />
                      )}
                    </IconButton>
                  ) : null}
                </Box>
              </Box>

              <Box className={classes.infoButton}>
                <IconButton
                  onClick={(e) => this.handleOpenInfo(e)}
                  style={{
                    padding: 0
                  }}
                >
                  <InfoOutlinedIcon fontSize="small" className={classes.grayIcon} />
                </IconButton>
              </Box>

              <Popover
                open={infoOpen}
                anchorEl={this.state.infoAnchorEl}
                onClose={this.handleCloseInfo}
              >
                <Box maxHeight="440px" width="300px">
                  <JobInfo
                    answer_status={this.state.answer_status}
                    video_answer={this.state.video_answer}
                    job_id={this.state.job_id}
                    user_id={this.state.video_question.user_id}
                  />
                </Box>
              </Popover>
            </Box>
          );
        }
      }
    };

    return (
      <Card
        classes={{
          root: classes.cardRoot
        }}
      >
        <CardContent className={classes.cardContent}>
          {displayPlayer()}

          <Box className={classes.playerContainer}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Tooltip title={<FormattedMessage id="CardVideoPlayer.player_userRating" />} arrow>
                <Rating
                  name={video_question.id}
                  value={this.state.user_rating}
                  precision={0.5}
                  size="small"
                  disabled={!hasVideoAnswer || this.state.readonly}
                  className="mt-sm"
                  onChange={this.changeRating}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
              </Tooltip>
              <Box display="flex" alignItems="center">
                <Box mr={0.5}>
                  <TimerIcon
                    className={classes.durationIcon}
                    style={{
                      width: '18px',
                      heihgt: '18px'
                    }}
                  />
                </Box>
                <Typography className={classes.durationText}>
                  {' '}
                  {Moment.utc(this.state.duration * 1000).format('mm:ss')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }
}

export default injectIntl(withStyles(useStyles)(CardVideoPlayer2));
