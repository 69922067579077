import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import 'moment/locale/pt-br'; // FIXME locale
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Row } from 'react-bootstrap';

const messages = defineMessages({});

const useStyles = (theme) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    width: '50%',
    margin: 'auto'
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 0 0',
    paddingBottom: '0'
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  dialogMessage: {
    marginTop: '1.5rem',
    paddingLeft: '7rem',
    paddingRight: '7rem',
    marginBottom: '1.5rem'
  },
  dialogIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '4rem',
    height: '4rem'
  }
});

class ModalAlertBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      dialogOpen: props.dialogOpen
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open
      });
    }
  }

  handleOpen = () => {
    this.props.handleModalOpen();
  };

  handleClose = () => {
    this.setState(
      {
        open: false
      },
      () => {
        if (this.props.handlerClose) {
          this.props.handlerClose();
        }
      }
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        open={this.state.open}
        onClose={this.handleClose}
        className={classes.dialog}
      >
        <DialogTitle className={classes.dialogTitle}>
          <FormattedMessage
            id="ModalAlertBlock.msg_confirm_title"
            defaultMessage="Active restriction"
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <ErrorOutlineIcon className={classes.dialogIcon} />
          <Row className={classes.dialogMessage}>
            <FormattedMessage
              id="ModalAlertBlock.msg_confirm_action"
              defaultMessage="Are you sure you wish to continue? There are candidates with ACTIVE restrictions in this selection."
            />
          </Row>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" variant="contained" onClick={this.handleClose}>
            <FormattedMessage id="Base.button.cancel" />
          </Button>
          <Button color="secondary" variant="contained" onClick={this.handleOpen}>
            <FormattedMessage id="ModalAlertBlock.msg_continue" defaultMessage="Continue" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ModalAlertBlock));
