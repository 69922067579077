import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Api from '../../../Api/Api';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import UserProfile from '../../../Common/UserProfile';
import { UtilsMaterial } from '../../../../helpers/utilsMaterial';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { CirclePicker } from 'react-color';
import UtilsForm from '../../../Common/UtilsForm';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateApplicantTags as updateStoreApplicantTags } from '../../../Api/EventsStream';
import * as materialColors from 'material-colors';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AddTagIcon from './AddTagIcon';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Instructions from '../../../Search2/Instructions';
import TagIconSvg from './TagIconSvg';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const messages = defineMessages({});

const useStyles = (theme) => ({
  root: {
    minWidth: 280,
    //maxWidth: 360,
    display: 'flex',
    textAlign: 'left',
    paddingBottom: 8,
    fontWeight: 600,
    alignItems: 'center',
    '& svg': {
      marginLeft: '.5rem',
      width: '1rem',
      height: '1rem'
    }
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  inputBase: {
    minWidth: 220,
    //maxWidth: 420,

    '& input::placeholder': {
      fontSize: '12px',
      color: theme.palette.gray.darkerRegular
    },

    '& .MuiAutocomplete-input': {
      padding: '0 4px 9.2px 4px !important'
    }
  },
  inputBaseRead: {
    width: '100%',

    '& input::placeholder': {
      fontSize: '12px',
      color: theme.palette.gray.darkerRegular
    },
    '& .MuiAutocomplete-input': {
      padding: '0 !important'
    }
  },
  paper: {
    margin: 0,
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    paddingRight: 0,
    boxShadow: 'none',
    borderRadius: 0
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'

    /*  '&:hover': {
       background: 'transparent'
     },
 
     '&:focus': {
       background: 'transparent'
     },
 
     '&:active': {
       background: 'transparent'
     },
 
     '&[data-focus="true"]': {
       backgroundColor: 'transparent'
     } */
  },
  tagIcon: {
    fontSize: '1.25rem'
  },
  autocompleteList: {
    boxShadow: 'none',
    maxHeight: 250,
    padding: 0
  },
  tagsContainerTitle: {
    color: theme.palette.gray.darker,
    lineHeight: '20px'
  },
  popoverContainer: {
    height: '390px',
    width: '780px',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      justifyContent: 'center'
    }
  },
  contentLeft: {
    marginRight: theme.spacing(4.5),
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      justifyContent: 'center',
      marginRight: 0
    }
  },
  hiddenMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tagsDisplayContainar: {
    marginTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0.5rem',
    maxHeight: '122px',
    overflowY: 'auto'
  },
  dialogTitle: {
    color: theme.palette.gray.darker,
    lineHeight: '20px',
    fontSize: '14px'
  },
  tagTitleInput: {
    borderRadius: '1.5rem',
    fontSize: '14px',
    flexWrap: 'wrap',
    padding: '0 4px',
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px'
    },
    '& input::placeholder': {
      fontSize: '13px',
      color: theme.palette.gray.darker
    },
    '& .MuiAutocomplete-endAdornment': {
      paddingRight: '16px',
      position: 'relative'
    }
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogPaper: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '480px'
      }
    }
  },
  activeLabel: {
    marginBottom: 0
  },
  buttonLabel: {
    color: theme.palette.gray.darkerRegular,
    fontSize: '12px',
    lineHeight: 'normal'
  },
  msgEmptyOtherTags: {
    color: theme.palette.gray.darkerRegular,
    fontSize: '12px',
    lineHeight: '20px'
  }
});

const chipStyles = makeStyles((theme) => ({
  root: {
    color: (props) => materialColors[UtilsMaterial.getMaterialColorByHex(props.color).color2][900],
    fontSize: (props) => (props.displaySize === 'small' ? '0.6rem' : '0.75rem'),
    padding: (props) => props.displaySize === 'small' && '0 4px'
  }
}));

const CssChip = (props) => {
  const { color, displaySize, clickable, ...other } = props;
  const classes = chipStyles(props);
  return (
    <Chip
      style={{
        backgroundColor: materialColors[UtilsMaterial.getMaterialColorByHex(color).color2][100],
        maxWidth: `${displaySize === 'small' ? '10rem' : '15rem'}`,
        maxHeight: `${displaySize === 'small' && '1.25rem'}`,
        borderRadius: '4px',
        borderColor: materialColors[UtilsMaterial.getMaterialColorByHex(color).color2][500]
      }}
      classes={{ label: classes.root }}
      {...other}
    />
  );
};

const textFieldStyles = (theme) => ({
  root: {
    '& label.Mui-focused': {
      //border: '1px solid #ccc'
      borderBottom: `1px solid ${theme.palette.gray.main}`
    },
    '& .MuiInput-underline:after': {
      border: 'none'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none'
      },
      '&:hover fieldset': {
        border: 'none'
      },
      '&.Mui-focused fieldset': {
        border: 'none'
      }
    }
  }
});

const CssTextField = withStyles(textFieldStyles)(TextField);

const EllipsisText = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '6rem'
      }}
    >
      {children}
    </div>
  );
};

const EDIT_ACTION = {
  ADD: 'ADD',
  REMOVE: 'REMOVE'
};

class ApplicantTags extends Component {
  constructor(props) {
    super(props);

    this._searchTimeout = 0;

    this.state = {
      // Props from parent
      canChangeTags: props.canChangeTags,
      applicant_id: props.applicant_id,
      applicantTags: props.applicantTags ? props.applicantTags : [],
      otherApplicantsTags: props.otherApplicantsTags ? props.otherApplicantsTags : [],
      displayAll: props.displayAll,
      // Props from store
      tagsList: props.tags ? props.tags : [],
      // Internal state
      user_id: UserProfile.getId(),
      activeTagsList: [],
      //tagsList: [],
      anchorEl: null,
      value: [],
      id: null,
      active: true,
      edit_name: '',
      edit_color: '',
      fullColor: {},
      editingApplicantTags: [],
      editingOtherApplicantsTags: [],
      isOpen: false,
      editModal: false,
      _loading: false,
      _submiting: false
    };
  }

  componentDidMount() {
    if (this.state.applicantTags) {
      this.getInitialApplicantTags();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tags !== this.props.tags) {
      this.setState(
        {
          tagsList: this.props.tags
        },
        () => this.getApplicantTags()
      );
    }

    if (prevProps.applicantTags !== this.props.applicantTags) {
      this.setState(
        {
          applicantTags: this.props.applicantTags
        },
        () => this.getInitialApplicantTags()
      );
    }

    if (prevProps.otherApplicantsTags !== this.props.otherApplicantsTags) {
      this.setState(
        {
          otherApplicantsTags: this.props.otherApplicantsTags
        },
        () => this.getInitialApplicantTags()
      );
    }
  }

  getInitialApplicantTags = () => {
    const applicantTags = this.state.applicantTags;
    const otherApplicantsTags = this.state.otherApplicantsTags;
    const tagsList = this.state.tagsList;
    let editingApplicantTags = [];
    let editingOtherApplicantsTags = [];

    if (applicantTags && applicantTags.length > 0 && tagsList.length > 0) {
      applicantTags.forEach((tag) => {
        tagsList.forEach((tagObj) => {
          if (tagObj.id === tag) {
            editingApplicantTags.push(tagObj);
          }
        });
      });

      if (editingApplicantTags.length < applicantTags.length) {
        updateStoreApplicantTags();
      }
    }

    if (otherApplicantsTags && otherApplicantsTags.length > 0 && otherApplicantsTags.length > 0) {
      otherApplicantsTags.forEach((tag) => {
        tagsList.forEach((tagObj) => {
          if (tagObj.id === tag) {
            editingOtherApplicantsTags.push(tagObj);
          }
        });
      });
    }

    this.setState(
      {
        editingApplicantTags: editingApplicantTags,
        editingOtherApplicantsTags: editingOtherApplicantsTags
      },
      () => this.getActiveTags()
    );
  };

  getApplicantTags = () => {
    const editingApplicantTags = this.state.editingApplicantTags;
    const tagsList = this.state.tagsList;
    let newEditingApplicantTags = [];

    if (editingApplicantTags.length > 0 && tagsList.length > 0) {
      editingApplicantTags.forEach((tag) => {
        tagsList.forEach((tagObj) => {
          if (tagObj.id === tag.id) {
            newEditingApplicantTags.push(tagObj);
          }
        });
      });

      this.setState(
        {
          editingApplicantTags: newEditingApplicantTags
        },
        () => this.getActiveTags()
      );
    }
  };

  getActiveTags = () => {
    const tagsList = this.state.tagsList;
    let activeTagsList = [];

    if (tagsList.length > 0) {
      activeTagsList = tagsList.filter((tag) => tag.active);
    }

    this.setState({
      activeTagsList: activeTagsList
    });
  };

  updateApplicantTags = (action, tagId) => {
    let api = new Api();
    const applicant_id = this.state.applicant_id;
    let param = {
      applicant_id: applicant_id,
      tag_id: tagId,
      action: action
    };

    //console.log(param);

    api.post('Applicants/update-tag', param, (status, data) => {
      //console.log(data);
      if (status >= 400) {
        console.log('Error on update');
      } else {
        if (this.props.handleSetForRefresh) {
          this.props.handleSetForRefresh();
        }
      }
    });
    //console.log(action, tagId);
  };

  handleChange = (newValue) => {
    const editingApplicantTags = this.state.editingApplicantTags;
    let updatedTag;

    if (newValue.length > editingApplicantTags.length) {
      updatedTag = newValue.filter((tag) => !editingApplicantTags.includes(tag));

      if (typeof updatedTag[0] === 'object') {
        this.setState(
          {
            editingApplicantTags: newValue
          },
          () => {
            if (updatedTag) {
              this.updateApplicantTags(EDIT_ACTION.ADD, updatedTag[0].id);
            }
          }
        );
      }
    } else {
      updatedTag = editingApplicantTags.filter((tag) => !newValue.includes(tag));

      if (typeof updatedTag[0] === 'object') {
        this.setState(
          {
            editingApplicantTags: newValue
          },
          () => {
            if (updatedTag) {
              this.updateApplicantTags(EDIT_ACTION.REMOVE, updatedTag[0].id);
            }
          }
        );
      }
    }
  };

  removeTag = (tagId) => {
    const editingApplicantTags = this.state.editingApplicantTags;
    const updatedTags = editingApplicantTags.filter((tag) => tag.id !== tagId);

    this.setState(
      {
        editingApplicantTags: updatedTags
      },
      () => {
        this.updateApplicantTags(EDIT_ACTION.REMOVE, tagId);
      }
    );
  };

  handleChangeTag = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  /* handleOpenAutocomplete = (value) => {
        this.setState({
            isOpen: value
        });
    };
 */
  handleAddTag = () => {
    this.setState({
      editModal: true
    });
  };

  handleAddTagClose = () => {
    this.setState({
      editModal: false
    });
  };

  handleChangeColorPicker = (color) => {
    // console.log(color);
    this.setState({ edit_color: color.hex });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.edit_color === null || this.state.edit_color === '') {
      window.swal({
        type: 'info',
        title: '',
        text: this.props.intl.formatMessage({ id: 'ApplicantTagsSelection.msg_chooseTagColor' }),
        closeOnConfirm: true
      });
    } else {
      const tag = {
        account_id: this.props.accountInfo.accountInfo.account_id,
        user_id: UtilsForm.replaceToNull(this.state.user_id),
        active: UtilsForm.replaceToNull(this.state.active),
        name: UtilsForm.replaceToNull(this.state.edit_name),
        color: UtilsForm.replaceToNull(this.state.edit_color)
      };

      this.addTag(tag);
    }
  };

  addTag = (tag) => {
    this.setState(
      {
        _submiting: true
      },
      () => {
        let api = new Api();
        let editingApplicantTags = [...this.state.editingApplicantTags];
        api.post('ApplicantTags', tag, (status, data) => {
          if (status >= 200) {
            const newTag = data;
            //console.log(newTag);
            editingApplicantTags.push(newTag);
            this.setState(
              {
                _submiting: false,
                editModal: false
                // editingApplicantTags: editingApplicantTags
              },
              () => {
                updateStoreApplicantTags()
                  .then((res) => {
                    if (newTag && newTag.id) {
                      this.handleChange(editingApplicantTags);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            );
          } else {
            console.log('Error on post');
          }
        });
      }
    );
  };

  handleOpenAutocomplete = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleCloseAutocomplete = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { classes } = this.props;
    const activeTagsList = this.state.activeTagsList;
    const editingApplicantTags = this.state.editingApplicantTags;
    const editingOtherApplicantsTags = this.state.editingOtherApplicantsTags;
    const canChangeTags = this.state.canChangeTags;
    const isOpen = this.state.isOpen;

    const popoverOpen = this.state.anchorEl;

    const displayTags = true;
    const displayAll = this.state.displayAll;
    const canSubmitModal = this.state.edit_name && this.state.edit_color;

    const displayTagsSelection = () => {
      if (!canChangeTags && editingApplicantTags.length === 0) {
        return null;
      }

      if (
        UserProfile.isAdmin() ||
        UserProfile.isHRManager() ||
        UserProfile.isRecruiter() ||
        UserProfile.isJobManager()
      ) {
        return (
          <>
            <Box className={classes.tagContainer}>
              {canChangeTags ? (
                <Box
                  onClick={this.handleOpenAutocomplete}
                  display="flex"
                  alignItems="center"
                  style={{
                    cursor: canChangeTags ? 'pointer' : 'default'
                  }}
                >
                  <Box mr={1} pt={0.25}>
                    <AddTagIcon />
                  </Box>
                  {editingApplicantTags.length === 0 ? (
                    <Typography className={classes.buttonLabel}>
                      <FormattedMessage
                        id="ApplicantTag.label_addTag"
                        defaultMessage="Click to add tags"
                      />
                    </Typography>
                  ) : null}
                </Box>
              ) : null}
              <Box>
                <Autocomplete
                  disabled={this.state._loading}
                  disableClearable
                  open={false}
                  id="tags"
                  label="tags"
                  name="tags"
                  fullWidth
                  freeSolo
                  multiple
                  selectOnFocus
                  clearOnBlur
                  filterSelectedOptions
                  limitTags={displayAll ? -1 : 6}
                  //forcePopupIcon={!displayTags} // Force dropdown arrow icon at the end of text field
                  /* onOpen={() => {
                                        canChangeTags && this.handleOpenAutocomplete(true);
                                    }}
                                    onClose={() => {
                                        this.handleOpenAutocomplete(false);
                                    }} */
                  classes={{
                    paper: classes.paper,
                    inputBase: classes.inputBase
                  }}
                  value={editingApplicantTags}
                  options={activeTagsList}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.name === value.name}
                  onChange={(event, newValue) => {
                    this.handleChange(newValue);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      if (displayTags) {
                        return (
                          <Tooltip title={option.name}>
                            <div>
                              <CssChip
                                color={option.color}
                                displaySize="small"
                                size="small"
                                variant="outlined"
                                label={<EllipsisText>{option.name}</EllipsisText>}
                                {...getTagProps({ index })}
                                deleteIcon={
                                  <IconButton hidden disabled>
                                    {!displayTags && <CloseIcon style={{ fontSize: '1rem' }} />}
                                  </IconButton>
                                }
                              />
                            </div>
                          </Tooltip>
                        );
                      } else {
                        return (
                          <Tooltip title={option.name}>
                            <div>
                              <CssChip
                                color={option.color}
                                displaySize="small"
                                size="small"
                                variant="outlined"
                                label={<EllipsisText>{option.name}</EllipsisText>}
                                {...getTagProps({ index })}
                                deleteIcon={
                                  <IconButton
                                    size="small"
                                    style={{
                                      color:
                                        materialColors[
                                          UtilsMaterial.getMaterialColorByHex(option.color).color2
                                        ][500]
                                    }}
                                  >
                                    {!displayTags && <CloseIcon style={{ fontSize: '1rem' }} />}
                                  </IconButton>
                                }
                              />
                            </div>
                          </Tooltip>
                        );
                      }
                    })
                  }
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <CssTextField
                      variant="outlined"
                      className={!displayTags ? classes.inputBase : classes.inputBaseRead}
                      placeholder={
                        !displayTags
                          ? this.props.intl.formatMessage({
                              id: 'ApplicantTagsSelection.tagPlaceholder'
                            })
                          : undefined
                      }
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        readOnly: displayTags,
                        disableUnderline: true,
                        style: {
                          display: 'flex',
                          alignItems: 'center',
                          //height: '2.5rem',
                          padding: '0',
                          fontSize: '1rem'
                        }
                      }}
                    />
                  )}
                />
              </Box>

              <Popover
                open={popoverOpen}
                anchorEl={this.state.anchorEl}
                onClose={this.handleCloseAutocomplete}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 30
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <Box className={classes.popoverContainer}>
                  <Box mr={4.5} maxHeight="328px" className={classes.contentLeft}>
                    <Box
                      style={{
                        height: 'calc(100% - 14px)'
                      }}
                    >
                      <Autocomplete
                        disablePortal
                        disabled={this.state._loading}
                        disableClearable
                        open={canChangeTags}
                        id="tags"
                        label="tags"
                        name="tags"
                        fullWidth
                        freeSolo
                        multiple
                        selectOnFocus
                        clearOnBlur
                        filterSelectedOptions
                        limitTags={canChangeTags ? 10 : 6}
                        forcePopupIcon={false} // Force dropdown arrow icon at the end of text field
                        /* onOpen={() => {
                                              canChangeTags && this.handleOpenAutocomplete(true);
                                          }}
                                          onClose={() => {
                                              this.handleOpenAutocomplete(false);
                                          }} */
                        classes={{
                          paper: classes.paper,
                          inputBase: classes.inputBase,
                          listbox: classes.autocompleteList,
                          option: classes.option
                        }}
                        value={editingApplicantTags}
                        options={activeTagsList}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.name === value.name}
                        onChange={(event, newValue) => {
                          this.handleChange(newValue);
                        }}
                        renderTags={() => null}
                        disableCloseOnSelect
                        renderOption={(option) => (
                          <>
                            <div className={classes.option}>
                              <span
                                style={{
                                  color: `${option.color}`,
                                  fontSize: '.75rem',
                                  marginRight: '1rem'
                                }}
                              >
                                <LocalOfferIcon />
                              </span>
                              <span>{option.name}</span>
                            </div>
                          </>
                        )}
                        renderInput={(params) => (
                          <Box>
                            <CssTextField
                              variant="outlined"
                              className={canChangeTags ? classes.inputBase : classes.inputBaseRead}
                              placeholder={
                                canChangeTags
                                  ? this.props.intl.formatMessage({
                                      id: 'ApplicantTagsSelection.tagPlaceholder'
                                    })
                                  : undefined
                              }
                              {...params}
                              InputProps={{
                                ...params.InputProps,

                                onKeyDown: (e) => {
                                  if (e.key === 'Backspace') {
                                    e.stopPropagation();
                                  }
                                },
                                autoFocus: true,
                                readOnly: !canChangeTags,
                                disableUnderline: true,
                                style: {
                                  display: 'flex',
                                  alignItems: 'center',
                                  //height: '2.5rem',
                                  padding: 0,
                                  fontSize: '1rem'
                                }
                              }}
                            />
                            <Divider
                              style={{
                                marginBottom: '1rem'
                              }}
                            />
                          </Box>
                        )}
                      />
                    </Box>
                    <Divider />

                    {UserProfile.isAdmin() || UserProfile.isTagUser() ? (
                      <Box mt={1}>
                        <Button
                          color="primary"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onClick={this.handleAddTag}
                        >
                          <FormattedMessage
                            id="ApplicantTagsSelection.button_createNewTag"
                            defaultMessage="Create a new tag"
                          />
                        </Button>
                      </Box>
                    ) : null}
                  </Box>
                  <Box width="100%" className={classes.hiddenMobile}>
                    <Box mb={3} height="173px">
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                        <Typography className={classes.tagsContainerTitle}>
                          <FormattedMessage
                            id="ApplicantTags.label_applicantTags"
                            defaultMessage="Tags in current job"
                          />
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        className={classes.tagsDisplayContainar}
                        style={{
                          justifyContent: editingApplicantTags.length > 0 ? 'flex-start' : 'center'
                        }}
                      >
                        {editingApplicantTags.length === 0 ? (
                          <Box>
                            <Instructions
                              icon={<TagIconSvg />}
                              helperText={
                                <FormattedMessage
                                  id="ApplicantTags.msg_noApplicantTags"
                                  defaultMessage="This candidate has no tags yet. Select a tag on the left."
                                />
                              }
                            />
                          </Box>
                        ) : (
                          <>
                            {editingApplicantTags.map((tag, index) => {
                              return (
                                <Tooltip title={tag.name} key={index}>
                                  <span>
                                    <CssChip
                                      color={tag.color}
                                      displaySize="small"
                                      size="small"
                                      variant="outlined"
                                      label={<EllipsisText>{tag.name}</EllipsisText>}
                                      onDelete={() => this.removeTag(tag.id)}
                                      deleteIcon={
                                        <IconButton
                                          size="small"
                                          style={{
                                            color:
                                              materialColors[
                                                UtilsMaterial.getMaterialColorByHex(tag.color)
                                                  .color2
                                              ][500]
                                          }}
                                        >
                                          {canChangeTags && (
                                            <CloseIcon style={{ fontSize: '1rem' }} />
                                          )}
                                        </IconButton>
                                      }
                                    />
                                  </span>
                                </Tooltip>
                              );
                            })}
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                        <Typography className={classes.tagsContainerTitle}>
                          <FormattedMessage
                            id="ApplicantTags.label_otherApplicantsTags"
                            defaultMessage="Tags in other jobs"
                          />
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        className={classes.tagsDisplayContainar}
                        style={{
                          maxHeight: '122px'
                        }}
                      >
                        {editingOtherApplicantsTags.length > 0 ? (
                          editingOtherApplicantsTags.map((tag, index) => {
                            return (
                              <Tooltip title={tag.name} key={index}>
                                <span>
                                  <CssChip
                                    color={tag.color}
                                    displaySize="small"
                                    size="small"
                                    variant="outlined"
                                    label={<EllipsisText>{tag.name}</EllipsisText>}
                                  />
                                </span>
                              </Tooltip>
                            );
                          })
                        ) : (
                          <Typography className={classes.msgEmptyOtherTags}>
                            <FormattedMessage
                              id="ApplicantTags.msg_noOtherApplicantTags"
                              defaultMessage="No tags assigned"
                            />
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Popover>
            </Box>
            <Dialog
              open={this.state.editModal}
              fullWidth={true}
              maxWidth="sm"
              onClose={this.handleAddTagClose}
              className={classes.dialogPaper}
            >
              <form id="formEditTag" onSubmit={(e) => this.onSubmit(e)}>
                <DialogTitle
                  style={{
                    padding: '16px 24px 0 24px'
                  }}
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={0.5}>
                    <Typography className={classes.dialogTitle}>
                      <FormattedMessage
                        id="ApplicantTagsSelection.title_createTag"
                        defaultMessage="Create a new tag"
                      />
                    </Typography>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={this.handleAddTagClose}
                      aria-label="close"
                      disabled={this.state._loading}
                      style={{
                        padding: 0
                      }}
                    >
                      <CloseIcon color="primary" />
                    </IconButton>
                  </Box>

                  <Divider
                    style={{
                      width: '100%'
                    }}
                  />
                </DialogTitle>
                <DialogContent>
                  <Box mb={3}>
                    <Box className={classes.titleRow}>
                      <Box>
                        <Typography className={classes.dialogTitle}>
                          <FormattedMessage
                            id="ApplicantTagsSelection.label_tagTitle"
                            defaultMessage="Tag name"
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.active}
                              //onChange={this.handleChangeTag}
                              name="active"
                              color="primary"
                              //disabled
                            />
                          }
                          className={classes.activeLabel}
                          label={
                            <InputLabel id="active-label" className={classes.activeLabel}>
                              <FormattedMessage id="ApplicantTagsSelection.field_active" />
                            </InputLabel>
                          }
                        />
                      </Box>
                    </Box>
                    <Box>
                      <TextField
                        id="edit_name"
                        name="edit_name"
                        type="text"
                        value={this.state.edit_name}
                        className={classes.tagTitleInput}
                        required={true}
                        fullWidth
                        onChange={this.handleChangeTag}
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                  <Box mb={3}>
                    <Box mb={1}>
                      <Typography className={classes.dialogTitle}>
                        <FormattedMessage id="ApplicantTagsSelection.field_color" />
                      </Typography>
                    </Box>
                    <CirclePicker
                      width={'100%'}
                      maxWidth="100%"
                      color={this.state.edit_color}
                      colors={UtilsMaterial.getThemeColorsByHue(500)}
                      onChangeComplete={this.handleChangeColorPicker}
                    />
                  </Box>

                  <Divider />
                </DialogContent>
                <DialogActions
                  style={{
                    padding: '16px 24px 24px 24px'
                  }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={this.state._submiting || this.state._loading || !canSubmitModal}
                  >
                    <FormattedMessage id="ApplicantTagsSelection.button_saveTag" />
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          </>
        );
      } else {
        return null;
      }
    };

    return <>{displayTagsSelection()}</>;
  }
}

ApplicantTags.propTypes = {
  tags: PropTypes.array,
  accountInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  tags: state.applicantTags.tags,
  accountInfo: state.accountInfo
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(ApplicantTags)));
