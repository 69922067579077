import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';

function getFirstLetters(str) {
  const firstLetters = str
    .split(' ')
    .map((word) => word[0])
    .join('');

  return firstLetters;
}

const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light
  },
  rootBlock: {
    backgroundColor: theme.palette.error.main
  }
});

class ApplicantAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: props.size,
      name: props.name,
      src: props.src,
      emailBlocklists: props.emailBlocklists ? props.emailBlocklists : {},
      docBlocklists: props.docBlocklists ? props.docBlocklists : {}
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.emailBlocklists !== this.props.emailBlocklists) {
      this.setState({
        emailBlocklists: this.props.emailBlocklists
      });
    }
    if (prevProps.docBlocklists !== this.props.docBlocklists) {
      this.setState({
        docBlocklists: this.props.docBlocklists
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { size, name, src, emailBlocklists, docBlocklists } = this.state;

    let validateBlock = false;

    if (
      (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
      (typeof emailBlocklists === 'object' && Object.keys(docBlocklists).length > 0)
    ) {
      validateBlock = true;
    }

    let fullPath;
    if (src) {
      if (src.startsWith('http')) {
        fullPath = src;
      } else {
        fullPath = 'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + src;
      }
    }

    return (
      <Avatar
        className={validateBlock ? classes.rootBlock : classes.root}
        style={{
          width: size,
          height: size
        }}
        src={validateBlock ? <LockIcon /> : fullPath}
      >
        {validateBlock ? <LockIcon /> : getFirstLetters(name.toUpperCase())}
      </Avatar>
    );
  }
}

ApplicantAvatar.propTypes = {
  emailBlocklists: PropTypes.object.isRequired,
  docBlocklists: PropTypes.object.isRequired
};

export default withStyles(useStyles)(ApplicantAvatar);
