import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import * as CONST from '../../Common/constants';

const useStyles = (theme) => ({});

class InfoOriginDetailSkeleton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Box
          style={{
            width: '363px',
            maxHeight: '230px',
            borderRadius: '10px',
            boxShadow: `0px 4px 15px 0px ${CONST.APP_COLORS['black']}33`
          }}
        >
          <Box
            style={{
              position: 'sticky',
              top: '0px',
              zIndex: '999',
              background: 'white'
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px'
              }}
            >
              <Box width="275px" display="flex" justifyContent="center">
                <Skeleton variant="text" width={150} height={30} />
              </Box>
            </Box>
            <Divider style={{ margin: '0 1rem' }} />
          </Box>

          <Box
            display="flex"
            style={{
              padding: '16px 24px '
            }}
          >
            <Box>
              <Box>
                <Box>
                  <Skeleton variant="text" width={100} height={30} />
                </Box>

                <Box>
                  <Box display="flex" alignItems="center">
                    <Box mt={1} display="flex">
                      <Skeleton
                        variant="circular"
                        style={{ borderRadius: '100%' }}
                        width={32}
                        height={32}
                      />
                      <Box>
                        <Box mt={1} display="flex">
                          <Box ml={1}>
                            <Skeleton variant="text" width={80} height={20} />
                          </Box>
                        </Box>

                        <Box ml={1} mt={0.5}>
                          <Skeleton variant="text" width={120} height={20} />
                        </Box>

                        <Box ml={1} mt={0.5}>
                          <Skeleton variant="text" width={100} height={20} />
                        </Box>

                        <Box ml={1} mt={2}>
                          <Skeleton variant="text" width={150} height={20} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

export default withStyles(useStyles)(InfoOriginDetailSkeleton);
