import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import * as CONST from '../../Common/constants';
import Tooltip from '@material-ui/core/Tooltip';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';

const messages = defineMessages({
  origin: {
    id: 'LabelOrigin.origin',
    defaultMessage: 'Origin'
  },
  originForm: {
    id: 'LabelOrigin.originForm',
    defaultMessage: 'Web Form'
  },
  originMail: {
    id: 'LabelOrigin.originMail',
    defaultMessage: 'Mail In'
  },
  originImport: {
    id: 'LabelOrigin.originImport',
    defaultMessage: 'Imported'
  },
  originHunting: {
    id: 'LabelOrigin.originHunting',
    defaultMessage: 'User Hunt'
  },
  originScrap: {
    id: 'LabelOrigin.originScrap',
    defaultMessage: 'Auto Hunting'
  },
  originCopy: {
    id: 'LabelOrigin.originCopy',
    defaultMessage: 'Copy'
  },
  originMove: {
    id: 'LabelOrigin.originMove',
    defaultMessage: 'Move'
  },
  originCrossrank: {
    id: 'LabelOrigin.originCrossrank',
    defaultMessage: 'Suggested by AI'
  },
  originUnknown: {
    id: 'LabelOrigin.originUnknown',
    defaultMessage: 'Unknown'
  },
  originSearch: {
    id: 'LabelOrigin.originSearch',
    defaultMessage: 'Search'
  },
  originRecruiter: {
    id: 'LabelOrigin.originRecruiter',
    defaultMessage: 'Recruiter'
  }
});

class LabelOrigin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      origin: props.origin,
      size: props.size ? props.size : 20 // Smaller than the standard 24
    };
  }

  render() {
    let origin_msg, origin_tooltip;
    switch (this.state.origin) {
      case CONST.ORIGIN_APPLY:
        origin_msg = this.props.intl.formatMessage({
          id: 'LabelOrigin.originForm'
        });
        origin_tooltip =
          this.props.intl.formatMessage({ id: 'LabelOrigin.origin' }) +
          ': ' +
          this.props.intl.formatMessage({ id: 'LabelOrigin.originForm' });
        break;
      case CONST.ORIGIN_MAILIN:
        origin_msg = this.props.intl.formatMessage({
          id: 'LabelOrigin.originMail'
        });
        origin_tooltip =
          this.props.intl.formatMessage({ id: 'LabelOrigin.origin' }) +
          ': ' +
          this.props.intl.formatMessage({ id: 'LabelOrigin.originMail' });
        break;
      case CONST.ORIGIN_RECRUITER:
        origin_msg = this.props.intl.formatMessage({
          id: 'LabelOrigin.originRecruiter'
        });
        origin_tooltip =
          this.props.intl.formatMessage({ id: 'LabelOrigin.origin' }) +
          ': ' +
          this.props.intl.formatMessage({ id: 'LabelOrigin.originRecruiter' });
        break;
      case CONST.ORIGIN_IMPORT:
        origin_msg = this.props.intl.formatMessage({
          id: 'LabelOrigin.originImport'
        });
        origin_tooltip =
          this.props.intl.formatMessage({ id: 'LabelOrigin.origin' }) +
          ': ' +
          this.props.intl.formatMessage({ id: 'LabelOrigin.originImport' });
        break;
      case CONST.ORIGIN_HUNT:
        origin_msg = this.props.intl.formatMessage({
          id: 'LabelOrigin.originHunting'
        });
        origin_tooltip =
          this.props.intl.formatMessage({ id: 'LabelOrigin.origin' }) +
          ': ' +
          this.props.intl.formatMessage({ id: 'LabelOrigin.originHunting' });
        break;
      case CONST.ORIGIN_SCRAP:
        origin_msg = this.props.intl.formatMessage({
          id: 'LabelOrigin.originScrap'
        });
        origin_tooltip =
          this.props.intl.formatMessage({ id: 'LabelOrigin.origin' }) +
          ': ' +
          this.props.intl.formatMessage({ id: 'LabelOrigin.originScrap' });
        break;
      case CONST.ORIGIN_MOVE:
        origin_msg = this.props.intl.formatMessage({
          id: 'LabelOrigin.originMove'
        });
        origin_tooltip =
          this.props.intl.formatMessage({ id: 'LabelOrigin.origin' }) +
          ': ' +
          this.props.intl.formatMessage({ id: 'LabelOrigin.originMove' });
        break;
      case CONST.ORIGIN_COPY:
        origin_msg = this.props.intl.formatMessage({
          id: 'LabelOrigin.originCopy'
        });
        origin_tooltip =
          this.props.intl.formatMessage({ id: 'LabelOrigin.origin' }) +
          ': ' +
          this.props.intl.formatMessage({ id: 'LabelOrigin.originCopy' });
        break;
      case CONST.ORIGIN_CROSSRANK:
        origin_msg = this.props.intl.formatMessage({
          id: 'LabelOrigin.originCrossrank'
        });
        origin_tooltip =
          this.props.intl.formatMessage({ id: 'LabelOrigin.origin' }) +
          ': ' +
          this.props.intl.formatMessage({ id: 'LabelOrigin.originCrossrank' });
        break;
      default:
        origin_msg = this.props.intl.formatMessage({
          id: 'LabelOrigin.originUnknown'
        });
        origin_tooltip =
          this.props.intl.formatMessage({ id: 'LabelOrigin.origin' }) +
          ': ' +
          this.props.intl.formatMessage({ id: 'LabelOrigin.originUnknown' });
    }

    return (
      <Tooltip title={origin_tooltip} arrow>
        <AddToHomeScreenIcon
          style={{
            fontSize: this.state.size,
            color: CONST.APP_COLORS['gray-darker-regular']
          }}
        />
      </Tooltip>
    );
  }
}

export default injectIntl(LabelOrigin);
