import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = (theme) => ({
  optionList: {
    //height: 200,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    padding: 0
  }
});

class OtherJobsSkeleton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Box
          display="flex"
          style={{
            padding: '14px 24px'
          }}
        >
          <Skeleton animation="wave" height={12} width={128} />
        </Box>
        <Divider />
        <Box
          display="flex"
          style={{
            padding: '0 24px'
          }}
        >
          <List className={classes.optionList}>
            <ListItem
              role="listitem"
              ContainerComponent="li"
              style={{
                padding: 0,
                margin: '16px 0'
              }}
            >
              <Box width="100%" minWidth={0}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  height={12}
                  width={258}
                  style={{ marginBottom: 8 }}
                />
                <Box display="flex" mb={3}>
                  <Box mr={2}>
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      width={16}
                      height={16}
                      style={{ marginRight: 2 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      height={14}
                      width={53}
                      style={{ marginBottom: 8 }}
                    />
                  </Box>

                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      width={16}
                      height={16}
                      style={{ marginRight: 2 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      height={14}
                      width={53}
                      style={{ marginBottom: 8 }}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" mb={3}>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    height={12}
                    width={155}
                    style={{ marginBottom: 8 }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    height={12}
                    width={155}
                    style={{ marginBottom: 24 }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    height={12}
                    width={155}
                    style={{ marginBottom: 8 }}
                  />
                  <Skeleton animation="wave" variant="text" height={12} width={155} />
                </Box>

                <Divider />
              </Box>
            </ListItem>

            <Box my={1}>
              <Divider />
            </Box>

            <ListItem
              role="listitem"
              ContainerComponent="li"
              style={{
                padding: 0,
                margin: '16px 0'
              }}
            >
              <Box width="100%" minWidth={0}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  height={12}
                  width={258}
                  style={{ marginBottom: 8 }}
                />
                <Box display="flex" mb={3}>
                  <Box mr={2}>
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      width={16}
                      height={16}
                      style={{ marginRight: 2 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      height={14}
                      width={53}
                      style={{ marginBottom: 8 }}
                    />
                  </Box>

                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      width={16}
                      height={16}
                      style={{ marginRight: 2 }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      height={14}
                      width={53}
                      style={{ marginBottom: 8 }}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" mb={3}>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    height={12}
                    width={155}
                    style={{ marginBottom: 8 }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    height={12}
                    width={155}
                    style={{ marginBottom: 24 }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    height={12}
                    width={155}
                    style={{ marginBottom: 8 }}
                  />
                  <Skeleton animation="wave" variant="text" height={12} width={155} />
                </Box>

                <Divider />
              </Box>
            </ListItem>
          </List>
        </Box>
      </>
    );
  }
}

export default withStyles(useStyles)(OtherJobsSkeleton);
