import * as CONST from '../../Common/constants';

export default {
  control: {
    backgroundColor: CONST.APP_COLORS['white'],
    fontSize: 14,
    fontWeight: 'normal'
  },

  highlighter: {
    overflow: 'hidden'
  },

  input: {
    margin: 0,
    border: 0,
    overflow: 'auto',
    height: 100
  },

  '&singleLine': {
    control: {
      display: 'inline-block',
      width: 130
    },

    highlighter: {
      padding: 1,
      border: '2px inset transparent'
    },

    input: {
      padding: 1,
      border: '2px inset'
    }
  },

  '&multiLine': {
    control: {
      border: '1px solid silver'
    },

    highlighter: {
      padding: 9
    },

    input: {
      padding: 9,
      minHeight: 63,
      outline: 0,
      border: 0
    }
  },

  suggestions: {
    list: {
      backgroundColor: CONST.APP_COLORS['white'],
      border: `1px solid ${CONST.APP_COLORS['black']}26`,
      fontSize: 14
    },

    item: {
      padding: '5px 15px',

      '&focused': {
        backgroundColor: CONST.APP_COLORS['pink'],
        color: CONST.APP_COLORS['white']
      }
    }
  }
};
