export default () => {
  window.zE('webWidget', 'prefill', {
    name: {
      value: ' ',
      readOnly: false
    },
    email: {
      value: ' ',
      readOnly: false
    }
  });
};
