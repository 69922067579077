import React from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Api from '../../../Api/Api';
import CurrencyController, { getCurrenciesByCountry } from '../../../Common/CurrencyController';
import CurrencyTextField from './CurrencyTextField';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import countryToCurrency from 'country-to-currency';
import Utils from '../../../Common/Utils';

const messages = defineMessages({
  opt_hour: {
    id: 'SalaryInfoEdit.opt_hour',
    defaultMessage: 'hour'
  },
  opt_month: {
    id: 'SalaryInfoEdit.opt_month',
    defaultMessage: 'month'
  },
  opt_year: {
    id: 'SalaryInfoEdit.opt_year',
    defaultMessage: 'year'
  },
  placeholder_fixedSalary: {
    id: 'SalaryInfoEdit.placeholder_fixedSalary',
    defaultMessage: 'Ex.: 2400'
  },
  title_resetSalaryExpectation: {
    id: 'SalaryInfoEdit.title_resetSalaryExpectation',
    defaultMessage: 'Reset all salary information?'
  }
});

const useStyles = (theme) => ({
  mainContainer: {
    width: 210,
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem'
  },
  mainButtonLabelContainer: {
    display: 'flex',
    width: '100%',
    whiteSpace: 'nowrap'
  },
  mainButton: {
    height: 24,
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: theme.spacing(1),

    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5)
    }

    //width: '100%'
  },
  ageLabel: {
    margin: '.25rem 0 .25rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  checkButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputLabel: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '14px'
  },
  rangeSalaryStartAdornment: {
    fontSize: '12px'
  },
  filterLabel: {
    lineHeight: '14px'
  },
  filterLabelActive: {
    color: theme.palette.primary.main
  },
  salaryInput: {
    paddingRight: '8px',
    fontSize: '14px',
    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      textAlign: 'right'
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '16px'
    }
  },
  radioLabel: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '14px'
  },
  formControlLabel: {
    marginRight: 0,
    marginLeft: 0
  },
  selectInput: {
    minWidth: '65px',
    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      color: theme.palette.gray.darkerMedium,
      fontSize: '12px'
    }
  },
  radioElement: {
    '& .MuiSvgIcon-root': {
      height: 15,
      width: 15
    },
    padding: theme.spacing(0.5)
  },
  labelNotInformed: {
    color: theme.palette.gray.darkerLight,
    fontSize: '12px',
    whiteSpace: 'nowrap'
  },
  matchIcon: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: '100%'
  },
  notMatchIcon: {
    color: theme.palette.gray.darkerMedium
  }
});

const SALARY_FIXED = 'fixed';
const SALARY_RANGE = 'range';

class SalaryInfoEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant: props.applicant,
      disabled: props.disabled,
      // Internal State
      salary_info: {
        currency: '',
        min: 0,
        max: 0,
        duration: ''
      },
      displayMin: '',
      displayMax: '',
      // Menu
      anchorEl: null,
      currenciesList: [],
      durationsList: [
        {
          value: 'MONTH',
          label: this.props.intl.formatMessage({ id: 'JobSalaryInfo.duration_opt_month' })
        },
        {
          value: 'HOUR',
          label: this.props.intl.formatMessage({ id: 'JobSalaryInfo.duration_opt_hour' })
        },
        {
          value: 'YEAR',
          label: this.props.intl.formatMessage({ id: 'JobSalaryInfo.duration_opt_year' })
        }
      ],
      salaryType: SALARY_FIXED,
      _submiting: false,
      match: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }
  }

  componentWillMount() {}

  componentDidMount() {
    //this.setSalaryOptions();
    this.setSalaryInfo();
    const applicant = this.state.applicant;
  }

  handleChangeMinSalary = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const salary_info = {
      ...this.state.salary_info,
      min: value
    };

    this.setState({
      salary_info: salary_info
    });
  };

  handleChangeMaxSalary = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const salary_info = {
      ...this.state.salary_info,
      max: value ? value : 0
    };

    this.setState({
      salary_info: salary_info
    });
  };

  setSalaryOptions = async () => {
    const currenciesList = await getCurrenciesByCountry();

    this.setState(
      {
        currenciesList: currenciesList
      },
      () => this.setSalaryInfo()
    );
  };

  setSalaryInfo = () => {
    const applicant = this.state.applicant;
    const salary_info = applicant.salary_info;
    let hasSalaryInfo = false;

    if (salary_info && Object.keys(salary_info).length > 0) {
      const isMinValueValid =
        salary_info.min !== undefined && salary_info.min !== null && salary_info.min > 0;
      const isCurrencyValid =
        salary_info.currency !== undefined &&
        salary_info.currency !== null &&
        salary_info.currency !== '';
      const isDurationValid =
        salary_info.duration !== undefined &&
        salary_info.duration !== null &&
        salary_info.duration !== '';

      if (isMinValueValid && isCurrencyValid && isDurationValid) {
        hasSalaryInfo = true;
      }
    }

    if (hasSalaryInfo) {
      const salary_info = applicant.salary_info;
      const salaryType = salary_info.max ? SALARY_RANGE : SALARY_FIXED;

      let match = false;

      const jobSalaryInfo = applicant.job.salary_info;

      if (jobSalaryInfo) {
        match = Utils.applicantSalaryMatch(salary_info, jobSalaryInfo);
      }

      this.setState({
        salary_info: {
          ...salary_info,
          max: salary_info.max !== undefined && salary_info !== null ? salary_info.max : 0
        },
        displayMin: CurrencyController.getMaskedCurrency(this, salary_info.min).maskedResult,
        displayMax:
          salary_info.max !== undefined && salary_info !== null
            ? CurrencyController.getMaskedCurrency(this, salary_info.max).maskedResult
            : 0,
        salaryType: salaryType,
        match: match
      });
    } else {
      const jobSalaryInfo = applicant.job.salary_info;

      let currency = '';
      let duration = '';

      if (jobSalaryInfo) {
        currency = jobSalaryInfo.currency;
        duration = jobSalaryInfo.duration;
      } else {
        if (applicant.job && applicant.job.country) {
          currency = countryToCurrency[applicant.job.country];
          duration =
            applicant.job.country === 'BR'
              ? this.state.durationsList[1].value
              : this.state.durationsList[2].value;
        }
      }

      this.setState({
        salary_info: {
          currency: currency,
          min: 0,
          max: 0,
          duration: duration
        }
      });
    }
  };

  handleMenuOpen = async (e) => {
    this.setState(
      {
        anchorEl: e.currentTarget
      },
      () => {
        const currenciesList = this.state.currenciesList;

        if (currenciesList.length === 0) {
          this.setSalaryOptions();
        } else {
          this.setSalaryInfo();
        }
      }
    );
  };

  handleMenuClose = () => {
    const applicant = this.state.applicant;
    const salary_info = applicant.salary_info
      ? applicant.salary_info
      : {
          currency: '',
          min: 0,
          max: 0,
          duration: ''
        };

    this.setState({
      anchorEl: null,
      salary_info: salary_info
    });
  };

  handleChangeSalaryInfo = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let displayMin = this.state.displayMin;
    let displayMax = this.state.displayMax;

    let salary_info = {
      ...this.state.salary_info,
      [name]: value
    };

    if (name === 'min') {
      const { maskedResult, integerResult } = CurrencyController.getMaskedCurrency(
        this,
        value,
        salary_info.currency
      );
      displayMin = maskedResult;

      salary_info = {
        ...this.state.salary_info,
        min: integerResult ? integerResult : 0
      };
    }

    if (name === 'max') {
      const { maskedResult, integerResult } = CurrencyController.getMaskedCurrency(
        this,
        value,
        salary_info.currency
      );
      displayMax = maskedResult;

      salary_info = {
        ...this.state.salary_info,
        max: integerResult ? integerResult : 0
      };
    }

    this.setState({
      salary_info: salary_info,
      displayMin: displayMin,
      displayMax: displayMax
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      }
      // () => {
      //   this.setSalaryMask();
      // }
    );
  };

  handleUpdateSalaryInfo = () => {
    this.setState(
      {
        _submiting: true
      },
      () => {
        const salary_info = this.state.salary_info;
        const formattedSalaryInfo = {
          currency: salary_info.currency,
          min: salary_info.min,
          max: this.state.salaryType === SALARY_RANGE ? salary_info.max : undefined,
          duration: salary_info.duration
        };
        const applicantId = this.state.applicant.id;

        const params = {
          applicant_id: applicantId,
          salary_info: formattedSalaryInfo
        };

        let api = new Api();

        api.post('Applicants/update-salary-info', params, (status, data) => {
          //console.log(applicant.review);
          if (status <= 400) {
            this.setState(
              {
                _submiting: false,
                anchorEl: null,
                salary_info: formattedSalaryInfo
              },
              () => {
                if (this.props.handleUpdateSalaryInfo) {
                  this.props.handleUpdateSalaryInfo(formattedSalaryInfo);
                }
              }
            );
          } else {
            window.swal({
              icon: 'error',
              title: '',
              text: this.props.intl.formatMessage({
                id: 'ModalReviewEdit.msg_deleteError'
              }),
              closeModal: true
            });

            this.setState({
              _submiting: false,
              anchorEl: null
            });
          }
        });
      }
    );
  };

  handleResetSalaryInfo = () => {
    window
      .swal({
        type: 'info',
        title: '',
        text: this.props.intl.formatMessage({
          id: 'SalaryInfoEdit.title_resetSalaryExpectation'
        }),
        closeOnConfirm: true,
        buttons: {
          cancel: {
            text: this.props.intl.formatMessage({
              id: 'Base.button.cancel'
            }),
            value: null,
            visible: true,
            className: '',
            closeModal: true
          },
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: '',
            closeModal: true
          }
        }
      })
      .then((isConfirmed) => {
        if (isConfirmed) {
          this.setState(
            {
              _submiting: true
            },
            () => {
              const applicantId = this.state.applicant.id;
              const params = {
                applicant_id: applicantId
              };

              let api = new Api();

              api.post('Applicants/reset-salary-info', params, (status, data) => {
                //console.log(applicant.review);
                if (status <= 400) {
                  this.setState(
                    {
                      _submiting: false,
                      anchorEl: null,
                      salary_info: {
                        currency: '',
                        min: 0,
                        max: 0,
                        duration: ''
                      },
                      displayMin: '',
                      displayMax: ''
                    },
                    () => {
                      const formattedSalaryInfo = {
                        currency: '',
                        min: 0,
                        max: 0,
                        duration: ''
                      };

                      if (this.props.handleUpdateSalaryInfo) {
                        this.props.handleUpdateSalaryInfo(formattedSalaryInfo);
                      }
                    }
                  );
                } else {
                  window.swal({
                    icon: 'error',
                    title: '',
                    text: this.props.intl.formatMessage({
                      id: 'ModalReviewEdit.msg_deleteError'
                    }),
                    closeModal: true
                  });

                  this.setState({
                    _submiting: false,
                    anchorEl: null
                  });
                }
              });
            }
          );
        }
      });
  };

  render() {
    const { classes } = this.props;

    let saveInfoDisabled = false;
    const salary_info = this.state.salary_info;

    const disabled = this.state.disabled;

    if (this.state.salaryType === SALARY_FIXED) {
      const isMinValueValid =
        salary_info.min !== undefined && salary_info.min !== null && salary_info.min > 0;
      const isCurrencyValid =
        salary_info.currency !== undefined &&
        salary_info.currency !== null &&
        salary_info.currency !== '';
      const isDurationValid =
        salary_info.duration !== undefined &&
        salary_info.duration !== null &&
        salary_info.duration !== '';

      if (!isMinValueValid || !isCurrencyValid || !isDurationValid) {
        saveInfoDisabled = true;
      }
    } else {
      const isMinValueValid =
        salary_info.min !== undefined && salary_info.min !== null && salary_info.min > 0;
      const isMaxValueValid =
        salary_info.max !== undefined && salary_info.max !== null && salary_info.max > 0;
      const isCurrencyValid =
        salary_info.currency !== undefined &&
        salary_info.currency !== null &&
        salary_info.currency !== '';
      const isDurationValid =
        salary_info.duration !== undefined &&
        salary_info.duration !== null &&
        salary_info.duration !== '';

      if (!isMinValueValid || !isMaxValueValid || !isCurrencyValid || !isDurationValid) {
        saveInfoDisabled = true;
      } else if (salary_info.max <= salary_info.min) {
        saveInfoDisabled = true;
      }
    }

    const displayLabel = () => {
      if (salary_info.min !== undefined && salary_info.max !== null && salary_info.min > 0) {
        const minValue = salary_info.min;
        const maxValue = salary_info.max;
        const currency = salary_info.currency;
        const duration = salary_info.duration;

        const hasMax = maxValue !== null && maxValue !== undefined && maxValue > 0;
        const durationObj = this.state.durationsList.find(
          (currDuration) => currDuration.value === duration
        );

        const durationMessage = durationObj ? durationObj.label : '';

        return (
          <span className={classes.mainButtonLabelContainer}>
            {hasMax ? (
              <FormattedMessage
                id="SalaryInfoEdit.label_range"
                defaultMessage="{min} - {max} by {duration}"
                values={{
                  currency: currency,
                  min: CurrencyController.displayWithCurrency(this, minValue, currency),
                  max: CurrencyController.displayWithCurrency(this, maxValue, currency).replace(
                    /[^\d,.-]/g,
                    ''
                  ),
                  duration: durationMessage
                }}
              />
            ) : (
              <FormattedMessage
                id="SalaryInfoEdit.label_value"
                defaultMessage="{min} by {duration}"
                values={{
                  currency: currency,
                  min: CurrencyController.displayWithCurrency(this, minValue, currency),
                  duration: durationMessage
                }}
              />
            )}
          </span>
        );
      } else {
        return (
          <Typography className={classes.labelNotInformed}>
            <FormattedMessage
              id="SalaryInfoEdit.label_nonInformedSalary"
              defaultMessage="No salary info"
            />
          </Typography>
        );
      }
    };

    const moneyIcon = () => {
      if (this.state.match) {
        return (
          <Tooltip
            title={
              <FormattedMessage
                id="SalaryInfoEdit.tooltip_salaryMatch"
                defaultMessage="Salary match according to this Job's salary expectancy"
              />
            }
            arrow
          >
            <AttachMoneyIcon fontSize="small" className={classes.matchIcon} />
          </Tooltip>
        );
      } else {
        return <AttachMoneyIcon fontSize="small" className={classes.notMatchIcon} />;
      }
    };

    return (
      <>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          disabled={disabled}
          onClick={this.handleMenuOpen}
          className={classes.mainButton}
          startIcon={moneyIcon()}
          endIcon={disabled ? null : <EditOutlinedIcon color="primary" fontSize="small" />}
        >
          {displayLabel()}
        </Button>

        <Popover
          id="action-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
          disableEnforceFocus={true} // https://github.com/mui-org/material-ui-pickers/issues/1852
        >
          <Box className={classes.mainContainer}>
            <Box mb={2} display="flex" alignItems="center" justifyContent="center">
              <Box>{moneyIcon()}</Box>
              <Typography className={`${classes.inputLabel} ${classes.filterLabel}`}>
                <FormattedMessage
                  id="SalaryInfoEdit.label_salaryExpectation"
                  defaultMessage="Salary Expectation"
                />
              </Typography>
            </Box>
            <Divider />
            <Box mt={2}>
              <FormControl
                component="fieldset"
                style={{
                  display: 'flex'
                }}
              >
                <RadioGroup
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                  name="salaryType"
                  value={this.state.salaryType}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    className={classes.formControlLabel}
                    style={{
                      marginLeft: '-4px'
                    }}
                    value={SALARY_FIXED}
                    control={<Radio color="primary" className={classes.radioElement} />}
                    label={
                      <Typography className={classes.radioLabel}>
                        <FormattedMessage
                          id="SalaryInfoEdit.label_fixedSalary"
                          defaultMessage="Salary"
                        />
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    className={classes.formControlLabel}
                    value={SALARY_RANGE}
                    control={<Radio color="primary" className={classes.radioElement} />}
                    label={
                      <Typography className={classes.radioLabel}>
                        <FormattedMessage
                          id="SalaryInfoEdit.label_rangeSalary"
                          defaultMessage="Range"
                        />
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              {this.state.salaryType === SALARY_FIXED ? (
                <>
                  <OutlinedInput
                    id="min"
                    name="min"
                    className={classes.salaryInput}
                    value={this.state.displayMin}
                    onChange={this.handleChangeSalaryInfo}
                    fullWidth
                    //disabled={this.state.loading || this.state.expandedFilters === true}
                    placeholder={this.props.intl.formatMessage({
                      id: 'SalaryInfoEdit.placeholder_fixedSalary'
                    })}
                    style={{
                      borderRadius: 64
                    }}
                  />
                </>
              ) : (
                <>
                  <Box>
                    <OutlinedInput
                      id="min"
                      name="min"
                      className={classes.salaryInput}
                      startAdornment={
                        <Typography className={classes.rangeSalaryStartAdornment}>
                          <FormattedMessage id="SalaryInfoEdit.label_min" defaultMessage="Min" />
                        </Typography>
                      }
                      value={this.state.displayMin}
                      onChange={this.handleChangeSalaryInfo}
                      fullWidth
                      //disabled={this.state.loading || this.state.expandedFilters === true}
                      placeholder="5.000,00"
                      style={{
                        borderRadius: 64
                      }}
                    />
                  </Box>
                  <Box mt={1}>
                    <OutlinedInput
                      id="max"
                      name="max"
                      className={classes.salaryInput}
                      startAdornment={
                        <Typography className={classes.rangeSalaryStartAdornment}>
                          <FormattedMessage id="SalaryInfoEdit.label_max" defaultMessage="Max" />
                        </Typography>
                      }
                      value={this.state.displayMax}
                      onChange={this.handleChangeSalaryInfo}
                      fullWidth
                      //disabled={this.state.loading || this.state.expandedFilters === true}
                      placeholder="8.000,00"
                      style={{
                        borderRadius: 64
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box display="flex" alignItems="center" my={2} justifyContent="space-between">
              <Box display="flex" flexDirection="column">
                <InputLabel id="duration-label" className={classes.inputLabel}>
                  <FormattedMessage id="SalaryInfoEdit.label_currency" defaultMessage="Currency" />
                </InputLabel>
                <Select
                  value={salary_info.currency}
                  id="currency"
                  name="currency"
                  variant="outlined"
                  onChange={this.handleChangeSalaryInfo}
                  className={classes.selectInput}
                  IconComponent={ExpandMoreIcon}
                >
                  {this.state.currenciesList.map((currCurrency) => {
                    return (
                      <MenuItem key={currCurrency} value={currCurrency}>
                        {currCurrency}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box display="flex" flexDirection="column">
                <InputLabel id="duration-label" className={classes.inputLabel}>
                  <FormattedMessage id="SalaryInfoEdit.label_type" defaultMessage="Per" />
                </InputLabel>
                <Select
                  value={salary_info.duration}
                  id="duration"
                  name="duration"
                  variant="outlined"
                  onChange={this.handleChangeSalaryInfo}
                  className={classes.selectInput}
                  IconComponent={ExpandMoreIcon}
                >
                  {this.state.durationsList.map((currDuration) => {
                    return (
                      <MenuItem key={currDuration.value} value={currDuration.value}>
                        {currDuration.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gridColumnGap="16px"
            >
              <Box>
                <Button
                  disabled={saveInfoDisabled || this.state._submiting}
                  variant="outlined"
                  color={saveInfoDisabled || this.state._submiting ? 'primary' : 'secondary'}
                  size="small"
                  onClick={this.handleResetSalaryInfo}
                >
                  <FormattedMessage id="SalaryInfoEdit.button_reset" defaultMessage="Reset" />
                </Button>
              </Box>

              <Box>
                <Button
                  disabled={saveInfoDisabled || this.state._submiting}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={this.handleUpdateSalaryInfo}
                >
                  <FormattedMessage id="BlockList.block_save" />
                </Button>
              </Box>
            </Box>
          </Box>
        </Popover>
      </>
    );
  }
}

SalaryInfoEdit.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  user: state.user.user
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(SalaryInfoEdit)));
