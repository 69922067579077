import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Api from '../../Api/Api';
import { Chip, InputAdornment, makeStyles, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import UserProfile from '../../Common/UserProfile';
import { UtilsMaterial } from '../../../helpers/utilsMaterial';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Row, Col } from 'react-bootstrap';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { CirclePicker } from 'react-color';
import UtilsForm from '../../Common/UtilsForm';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateApplicantTags as updateStoreApplicantTags } from '../../Api/EventsStream';
import * as materialColors from 'material-colors';
import AddTagIcon from '../Detail2/Tags/AddTagIcon';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const messages = defineMessages({
  button_createLabel: {
    id: 'ApplicantTagsSelection.button_createLabel',
    defaultMessage: 'Create Tag'
  },
  tagPlaceholder: {
    id: 'ApplicantTagsSelection.tagPlaceholder',
    defaultMessage: 'Select Tag'
  },
  add_modalTitle: {
    id: 'ApplicantTagsSelection.add_modalTitle',
    defaultMessage: 'New Candidate Tag'
  },
  msg_chooseTagColor: {
    id: 'ApplicantTagsSelection.msg_chooseTagColor',
    defaultMessage: 'Please, choose the tag color'
  }
});

const useStyles = (theme) => ({
  root: {
    minWidth: 280,
    //maxWidth: 360,
    display: 'flex',
    textAlign: 'left',
    paddingBottom: 8,
    fontWeight: 600,
    alignItems: 'center',
    '& svg': {
      marginLeft: '.5rem',
      width: '1rem',
      height: '1rem'
    }
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  inputBase: {
    minWidth: 360,
    //maxWidth: 420,

    '& input::placeholder': {
      fontSize: '.9rem',
      color: theme.palette.common.black
    }
  },
  inputBaseRead: {
    width: '100%',

    '& input::placeholder': {
      fontSize: '.9rem',
      color: theme.palette.common.black
    }
  },
  paper: {
    margin: 0,
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    paddingRight: 0
  },
  option: {
    display: 'flex',
    alignItems: 'center'
  },
  tagIcon: {
    fontSize: '1.25rem'
  },
  printChip: {
    '@media print': {
      color: theme.palette.gray.darkerMedium,
      backgroundColor: `${theme.palette.gray.darkerMedium}10`,
      borderColor: theme.palette.gray.darkerMedium,

      '& .MuiChip-icon': {
        color: theme.palette.gray.darkerMedium
      }
    }
  }
});

const chipStyles = makeStyles((theme) => ({
  root: {
    color: (props) => materialColors[UtilsMaterial.getMaterialColorByHex(props.color).color2][900],
    fontSize: (props) => (props.displaySize === 'small' ? '0.6rem' : '0.75rem'),
    fontWeight: 400,
    padding: (props) => props.displaySize === 'small' && '0 4px'
  }
}));

const CssChip = (props) => {
  const { color, displaySize, ...other } = props;
  const classes = chipStyles(props);

  return (
    <Chip
      style={{
        backgroundColor: materialColors[UtilsMaterial.getMaterialColorByHex(color).color2][100],
        maxWidth: `${displaySize === 'small' && '5rem'}`,
        //minHeight: `${!(displaySize === 'small') && '1.5rem'}`,
        maxHeight: `${displaySize === 'small' ? '1.25rem' : '1.5rem'}`,
        borderRadius: '4px',
        borderColor: materialColors[UtilsMaterial.getMaterialColorByHex(color).color2][500]
      }}
      classes={{ label: classes.root }}
      className={classes.printChip}
      {...other}
    />
  );
};

const textFieldStyles = (theme) => ({
  root: {
    '& label.Mui-focused': {
      //border: '1px solid #ccc'
      borderBottom: `1px solid ${theme.palette.gray.main}`
    },
    '& .MuiInput-underline:after': {
      border: 'none'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none'
      },
      '&:hover fieldset': {
        border: 'none'
      },
      '&.Mui-focused fieldset': {
        border: 'none'
      }
    }
  }
});

const CssTextField = withStyles(textFieldStyles)(TextField);

const EllipsisText = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '6rem'
      }}
    >
      {children}
    </div>
  );
};

const EDIT_ACTION = {
  ADD: 'ADD',
  REMOVE: 'REMOVE'
};

class ApplicantTagsSelection extends Component {
  constructor(props) {
    super(props);

    this._searchTimeout = 0;

    this.state = {
      // Props from parent
      canChangeTags: this.props.canChangeTags,
      applicant_id: this.props.applicant_id,
      applicantTags: this.props.applicantTags ? this.props.applicantTags : [],
      // Props from store
      tagsList: this.props.tags ? this.props.tags : [],
      // Internal state
      user_id: UserProfile.getId(),
      activeTagsList: [],
      //tagsList: [],
      anchorEl: null,
      value: [],
      id: null,
      active: true,
      edit_name: '',
      edit_color: '',
      fullColor: {},
      editingApplicantTags: [],
      isOpen: false,
      editModal: false,
      _loading: false,
      _submiting: false
    };
  }

  componentDidMount() {
    if (this.state.applicantTags) {
      this.getInitialApplicantTags();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tags !== this.props.tags) {
      this.setState(
        {
          tagsList: this.props.tags
        },
        () => this.getApplicantTags()
      );
    }

    if (prevProps.applicantTags !== this.props.applicantTags) {
      this.setState(
        {
          applicantTags: this.props.applicantTags
        },
        () => this.getInitialApplicantTags()
      );
    }
  }

  getInitialApplicantTags = () => {
    const applicantTags = this.state.applicantTags;
    const tagsList = this.state.tagsList;
    let editingApplicantTags = [];

    if (applicantTags && applicantTags.length > 0 && tagsList.length > 0) {
      applicantTags.forEach((tag) => {
        tagsList.forEach((tagObj) => {
          if (tagObj.id === tag) {
            editingApplicantTags.push(tagObj);
          }
        });
      });

      if (editingApplicantTags.length < applicantTags.length) {
        updateStoreApplicantTags();
      }
    }

    this.setState(
      {
        editingApplicantTags: editingApplicantTags
      },
      () => this.getActiveTags()
    );
  };

  getApplicantTags = () => {
    const editingApplicantTags = this.state.editingApplicantTags;
    const tagsList = this.state.tagsList;
    let newEditingApplicantTags = [];

    if (editingApplicantTags.length > 0 && tagsList.length > 0) {
      editingApplicantTags.forEach((tag) => {
        tagsList.forEach((tagObj) => {
          if (tagObj.id === tag.id) {
            newEditingApplicantTags.push(tagObj);
          }
        });
      });

      this.setState(
        {
          editingApplicantTags: newEditingApplicantTags
        },
        () => this.getActiveTags()
      );
    }
  };

  getActiveTags = () => {
    const tagsList = this.state.tagsList;
    let activeTagsList = [];

    if (tagsList.length > 0) {
      activeTagsList = tagsList.filter((tag) => tag.active);
    }

    this.setState({
      activeTagsList: activeTagsList
    });
  };

  updateApplicantTags = (action, tagId) => {
    let api = new Api();
    const applicant_id = this.state.applicant_id;
    let param = {
      applicant_id: applicant_id,
      tag_id: tagId,
      action: action
    };

    //console.log(param);

    api.post('Applicants/update-tag', param, (status, data) => {
      //console.log(data);
      if (status >= 400) {
        console.log('Error on update');
      }
    });
    //console.log(action, tagId);
  };

  handleChange = (newValue) => {
    const editingApplicantTags = this.state.editingApplicantTags;
    let updatedTag;

    if (newValue.length > editingApplicantTags.length) {
      updatedTag = newValue.filter((tag) => !editingApplicantTags.includes(tag));

      this.setState(
        {
          editingApplicantTags: newValue
        },
        () => {
          if (updatedTag) {
            this.updateApplicantTags(EDIT_ACTION.ADD, updatedTag[0].id);
          }
        }
      );
    } else {
      updatedTag = editingApplicantTags.filter((tag) => !newValue.includes(tag));
      this.setState(
        {
          editingApplicantTags: newValue
        },
        () => {
          if (updatedTag) {
            this.updateApplicantTags(EDIT_ACTION.REMOVE, updatedTag[0].id);
          }
        }
      );
    }
  };

  handleChangeTag = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleOpenAutocomplete = (value) => {
    this.setState({
      isOpen: value
    });
  };

  handleAddTag = () => {
    this.setState({
      editModal: true
    });
  };

  handleAddTagClose = () => {
    this.setState({
      editModal: false
    });
  };

  handleChangeColorPicker = (color) => {
    // console.log(color);
    this.setState({ edit_color: color.hex });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.edit_color === null || this.state.edit_color === '') {
      window.swal({
        type: 'info',
        title: '',
        text: this.props.intl.formatMessage({ id: 'ApplicantTagsSelection.msg_chooseTagColor' }),
        closeOnConfirm: true
      });
    } else {
      const tag = {
        account_id: this.props.accountInfo.accountInfo.account_id,
        user_id: UtilsForm.replaceToNull(this.state.user_id),
        active: UtilsForm.replaceToNull(this.state.active),
        name: UtilsForm.replaceToNull(this.state.edit_name),
        color: UtilsForm.replaceToNull(this.state.edit_color)
      };

      this.addTag(tag);
    }
  };

  addTag = (tag) => {
    this.setState(
      {
        _submiting: true
      },
      () => {
        let api = new Api();
        let editingApplicantTags = [...this.state.editingApplicantTags];
        api.post('ApplicantTags', tag, (status, data) => {
          if (status >= 200) {
            const newTag = data;
            //console.log(newTag);
            editingApplicantTags.push(newTag);
            this.setState(
              {
                _submiting: false,
                editModal: false
                // editingApplicantTags: editingApplicantTags
              },
              () => {
                updateStoreApplicantTags()
                  .then((res) => {
                    if (newTag && newTag.id) {
                      this.handleChange(editingApplicantTags);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            );
          } else {
            console.log('Error on post');
          }
        });
      }
    );
  };

  render() {
    const { classes } = this.props;
    const activeTagsList = this.state.activeTagsList;
    const editingApplicantTags = this.state.editingApplicantTags;
    const canChangeTags = this.state.canChangeTags;
    const isOpen = this.state.isOpen;

    const displayTagsSelection = () => {
      if (!canChangeTags && editingApplicantTags.length === 0) {
        return null;
      }

      if (
        UserProfile.isAdmin() ||
        UserProfile.isHRManager() ||
        UserProfile.isRecruiter() ||
        UserProfile.isJobManager()
      ) {
        return (
          <>
            <Box className={classes.tagContainer}>
              {canChangeTags && (
                <>
                  <Box style={{ marginRight: '1.25rem' }}>
                    <AddTagIcon />
                  </Box>
                  {(UserProfile.isAdmin() || UserProfile.isTagUser()) && (
                    <Box style={{ marginRight: '0.5rem' }}>
                      <Tooltip
                        title={this.props.intl.formatMessage({
                          id: 'ApplicantTagsSelection.button_createLabel'
                        })}
                      >
                        <IconButton
                          className={classes.tagIcon}
                          style={{ padding: 0 }}
                          edge="start"
                          color="inherit"
                          onClick={this.handleAddTag}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </>
              )}

              <Autocomplete
                disabled={this.state._loading}
                disableClearable
                open={canChangeTags && isOpen}
                id="tags"
                label="tags"
                name="tags"
                fullWidth
                freeSolo
                multiple
                selectOnFocus
                clearOnBlur
                filterSelectedOptions
                limitTags={canChangeTags ? 10 : 5}
                forcePopupIcon={canChangeTags} // Force dropdown arrow icon at the end of text field
                onOpen={() => {
                  canChangeTags && this.handleOpenAutocomplete(true);
                }}
                onClose={() => {
                  this.handleOpenAutocomplete(false);
                }}
                classes={{
                  paper: classes.paper,
                  inputBase: classes.inputBase
                }}
                value={editingApplicantTags}
                options={activeTagsList}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={(event, newValue) => {
                  this.handleChange(newValue);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    if (!canChangeTags) {
                      return (
                        <Tooltip title={option.name}>
                          <div>
                            <CssChip
                              color={option.color}
                              displaySize="small"
                              size="small"
                              variant="outlined"
                              label={<EllipsisText>{option.name}</EllipsisText>}
                              {...getTagProps({ index })}
                              deleteIcon={
                                <IconButton hidden disabled>
                                  {canChangeTags && <CancelIcon style={{ fontSize: '1rem' }} />}
                                </IconButton>
                              }
                            />
                          </div>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip title={option.name}>
                          <div>
                            <CssChip
                              color={option.color}
                              displaySize="small"
                              size="small"
                              variant="outlined"
                              label={<EllipsisText>{option.name}</EllipsisText>}
                              {...getTagProps({ index })}
                              deleteIcon={
                                <IconButton
                                  size="small"
                                  style={{
                                    color:
                                      materialColors[
                                        UtilsMaterial.getMaterialColorByHex(option.color).color2
                                      ][500]
                                  }}
                                >
                                  {canChangeTags && <CancelIcon style={{ fontSize: '1rem' }} />}
                                </IconButton>
                              }
                            />
                          </div>
                        </Tooltip>
                      );
                    }
                  })
                }
                disableCloseOnSelect
                renderOption={(option) => (
                  <>
                    <div className={classes.option}>
                      <span
                        style={{
                          color: `${option.color}`,
                          fontSize: '.75rem',
                          marginRight: '1rem'
                        }}
                      >
                        <LocalOfferIcon />
                      </span>
                      <span>{option.name}</span>
                    </div>
                  </>
                )}
                renderInput={(params) => (
                  <CssTextField
                    variant="outlined"
                    className={canChangeTags ? classes.inputBase : classes.inputBaseRead}
                    placeholder={
                      canChangeTags
                        ? this.props.intl.formatMessage({
                            id: 'ApplicantTagsSelection.tagPlaceholder'
                          })
                        : undefined
                    }
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: !canChangeTags,
                      disableUnderline: true,
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        //height: '2.5rem',
                        padding: '0 1rem 0 0',
                        fontSize: '1rem'
                      }
                    }}
                  />
                )}
              />
            </Box>
            <Dialog
              open={this.state.editModal}
              fullWidth={true}
              maxWidth="sm"
              onClose={this.onEditClose}
              className={classes.modal}
            >
              <form id="formEditTag" onSubmit={(e) => this.onSubmit(e)}>
                <DialogTitle>
                  {this.props.intl.formatMessage({
                    id: 'ApplicantTagsSelection.add_modalTitle'
                  })}
                </DialogTitle>
                <DialogContent>
                  <Row className={classes.titleRow}>
                    <Col md={8}>
                      <div className="form-group">
                        <TextField
                          id="edit_name"
                          name="edit_name"
                          type="text"
                          value={this.state.edit_name}
                          required={true}
                          fullWidth
                          onChange={this.handleChangeTag}
                          label={
                            <FormattedMessage
                              id="ApplicantTagsSelection.field_name"
                              defaultMessage="Tag name"
                            />
                          }
                        />
                      </div>
                    </Col>
                    <Col md={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.active}
                            onChange={this.handleChangeTag}
                            name="active"
                            color="primary"
                          />
                        }
                        label={
                          <InputLabel className={classes.activeLabel} id="active-label">
                            <FormattedMessage
                              id="ApplicantTagsSelection.field_active"
                              defaultMessage="Active?"
                            />
                          </InputLabel>
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="form-group">
                        <Row>
                          <Col md={12}>
                            <InputLabel className={classes.inputLabel} id="select-label">
                              <FormattedMessage
                                id="ApplicantTagsSelection.field_color"
                                defaultMessage="Tag color"
                              />
                            </InputLabel>

                            <CirclePicker
                              width={'100%'}
                              maxWidth="100%"
                              color={this.state.edit_color}
                              colors={UtilsMaterial.getThemeColorsByHue(500)}
                              onChangeComplete={this.handleChangeColorPicker}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </DialogContent>
                <DialogActions>
                  <Button type="button" color="secondary" onClick={this.handleAddTagClose}>
                    <FormattedMessage id="Base.button.cancel" />
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={this.state._submiting || this.state._loading}
                  >
                    <FormattedMessage
                      id="ApplicantTagsSelection.button_saveTag"
                      defaultMessage="Save Tag"
                    />
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          </>
        );
      } else {
        return null;
      }
    };

    return <>{displayTagsSelection()}</>;
  }
}

ApplicantTagsSelection.propTypes = {
  tags: PropTypes.array,
  accountInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  tags: state.applicantTags.tags,
  accountInfo: state.accountInfo
});

export default connect(
  mapStateToProps,
  null
)(injectIntl(withStyles(useStyles)(ApplicantTagsSelection)));
