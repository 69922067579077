import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import UserProfile from '../../Common/UserProfile';

const messages = defineMessages({
  heterosexual: {
    id: 'InfoPso.heterosexual',
    defaultMessage: 'Heterosexual'
  },
  lesbian: {
    id: 'InfoPso.lesbian',
    defaultMessage: 'Lesbian'
  },
  gay: {
    id: 'InfoPso.gay',
    defaultMessage: 'Gay'
  },
  bisexual: {
    id: 'InfoPso.bisexual',
    defaultMessage: 'Bisexual'
  },
  transgender: {
    id: 'InfoPso.transgender',
    defaultMessage: 'Transgender'
  },
  queer: {
    id: 'InfoPso.queer',
    defaultMessage: 'Queer'
  },
  intersexuals: {
    id: 'InfoPso.intersexuals',
    defaultMessage: 'Intersexuals'
  },
  asexuals: {
    id: 'InfoPso.asexuals',
    defaultMessage: 'Asexuals'
  },
  pan: {
    id: 'InfoPso.pansexual',
    defaultMessage: 'Pansexual'
  },
  other: {
    id: 'InfoPso.other',
    defaultMessage: 'Other'
  },
  opt_out: {
    id: 'InfoPso.opt_out',
    defaultMessage: 'Opt Out'
  }
});

class InfoPso extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pso: props.pso,
      size: props.size ? props.size : 24
    };
  }

  render() {
    const pso = this.state.pso;
    // const pso = 'heterosexual';

    if ((UserProfile.isAdmin() || UserProfile.isDiversity()) && pso && pso !== 'no') {
      let text = '';
      switch (pso) {
        case 'A':
          text = this.props.intl.formatMessage({
            id: 'InfoPso.heterosexual'
          });
          break;
        case 'B':
          text = this.props.intl.formatMessage({
            id: 'InfoPso.lesbian'
          });
          break;
        case 'C':
          text = this.props.intl.formatMessage({
            id: 'InfoPso.gay'
          });
          break;
        case 'D':
          text = this.props.intl.formatMessage({
            id: 'InfoPso.bisexual'
          });
          break;
        case 'E':
          text = this.props.intl.formatMessage({
            id: 'InfoPso.transgender'
          });
          break;
        case 'F':
          text = this.props.intl.formatMessage({
            id: 'InfoPso.queer'
          });
          break;
        case 'G':
          text = this.props.intl.formatMessage({
            id: 'InfoPso.intersexuals'
          });
          break;
        case 'H':
          text = this.props.intl.formatMessage({
            id: 'InfoPso.asexuals'
          });
          break;
        case 'I':
          text = this.props.intl.formatMessage({
            id: 'InfoPso.pansexual'
          });
          break;
        case 'J':
          text = this.props.intl.formatMessage({
            id: 'InfoPso.other'
          });
          break;
        case 'OPTOUT':
          text = this.props.intl.formatMessage({
            id: 'InfoPso.opt_out'
          });
          break;
      }
      return (
        <Tooltip title={text} arrow>
          <span
            className="material-icons md-pso "
            style={{
              fontSize: this.state.size
            }}
          >
            diversity_2
          </span>
        </Tooltip>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(InfoPso);
