import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import Badge from '@material-ui/core/Badge';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import * as CONST from '../Common/constants';

const useStyles = (theme) => ({
  avatar: {
    width: 40,
    height: 40,
    boxShadow: `0px 6px 9px 0px ${theme.palette.gray.darker}`
  }
});

const PlayAvatar = withStyles((theme) => ({
  root: {
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.background.paper}`,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.green.dark
  }
}))(Avatar);

const PauseAvatar = withStyles((theme) => ({
  root: {
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.background.paper}`,
    color: theme.palette.white.main,
    backgroundColor: theme.palette.error.dark
  }
}))(Avatar);

const StopAvatar = withStyles((theme) => ({
  root: {
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.background.paper}`,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.black.main
  }
}))(Avatar);

const FreezeAvatar = withStyles((theme) => ({
  root: {
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.background.paper}`,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main
  }
}))(Avatar);

const messages = defineMessages({});

class JobStatusIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.item) !== JSON.stringify(this.props.item)) {
      this.setState({
        item: this.props.item
      });
    }
  }

  render() {
    const item = this.state.item;
    const closed = item.closed;
    const date_closed = item.date_closed;
    const status = item.status;

    let apply;

    if (status === 'PUBLISHED') {
      if (closed === false || date_closed >= new Date()) {
        apply = (
          <PlayAvatar>
            <PlayArrowIcon style={{ fontSize: 14 }} />
          </PlayAvatar>
        );
      } else {
        apply = (
          <PauseAvatar>
            <PauseIcon style={{ fontSize: 14 }} />
          </PauseAvatar>
        );
      }
    } else if (status === 'FREEZING') {
      apply = (
        <FreezeAvatar>
          <AcUnitIcon style={{ fontSize: 14 }} />
        </FreezeAvatar>
      );
    } else {
      apply = (
        <StopAvatar>
          <StopIcon style={{ fontSize: 14 }} />
        </StopAvatar>
      );
    }

    return apply;
  }
}

export default injectIntl(withStyles(useStyles)(JobStatusIcon));
