import { takeLatest, call, put, all } from 'redux-saga/effects';
import Api from '../../components/Api/Api';
// import { jobsSuccess, jobsFailure } from '../actions/jobs.actions'

export function* getJobs({ payload }) {
  // try {
  //   let today = new Date()
  //   let tenDays = new Date()
  //   tenDays.setDate(new Date().getDate() - 10)
  //   let filter = {
  //     fields: ['id', 'name', 'job_code', 'avatar_50_path', 'job_id'],
  //     where: {
  //       job_id: {
  //         inq: payload
  //       },
  //       date_created: {
  //         between: [tenDays, today]
  //       }
  //     },
  //     limit: 50,
  //     order: ['date_created DESC'],
  //     include: {
  //       relation: 'job',
  //       scope: {
  //         fields: ['id', 'title']
  //       }
  //     }
  //   }
  //   const api = new Api()
  //   const response = yield call(api.getJobs, 'Jobs', filter)
  //   console.log(response.data)
  //   yield put(jobsSuccess(response.data))
  // } catch (err) {
  //   yield put(jobsFailure())
  // }
}

export default all([takeLatest('JOBS_REQUEST', getJobs)]);
