import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

class LoadingData extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row className="data-loading" style={{ width: '100%', margin: 0 }}>
        <Col sm={12}>
          <div className="loader-demo d-flex align-items-center justify-content-center">
            <CircularProgress color="secondary" />
          </div>
        </Col>
      </Row>
    );
  }
}

export default LoadingData;
