import Api from '../Api/Api';
import UserProfile from '../Common/UserProfile';

var JobReviewController = (function () {
  var getJobReviews = function (cb) {
    let api = new Api();

    const userId = UserProfile.getId();

    let reviewsToDo = 0;
    let reviewsDone = 0;

    const reviewFilter = UserProfile.isRecruiter()
      ? {
          user_recruiter: userId
        }
      : UserProfile.isReviewer()
      ? {
          reviewers: {
            contains: [userId.toString()]
          }
        }
      : {};

    const filter = {
      fields: ['id', 'review_config', 'reviewers'],
      where: {
        status: 'PUBLISHED',
        ...reviewFilter,
        has_review_stage: true
      },
      include: [
        {
          relation: 'applicants',
          scope: {
            fields: ['id', 'pipeline_stage', 'review']
          }
        }
      ]
    };

    api.get('Jobs', filter, (status, visibleJobs) => {
      if (status <= 400) {
        if (UserProfile.isReviewer()) {
          reviewsToDo = 0;
          reviewsDone = 0;

          visibleJobs.forEach((job) => {
            job.applicants.forEach((applicant) => {
              if (job.review_config.stage_id === applicant.pipeline_stage) {
                reviewsToDo += 1;

                const applicantReview = applicant.review;
                const hasReviewed =
                  applicantReview !== null &&
                  !(applicantReview[userId] === null || applicantReview[userId] === undefined);

                if (hasReviewed) {
                  reviewsDone += 1;
                }
              }
            });
          });
        }

        if (UserProfile.isRecruiter()) {
          reviewsToDo = 0;
          reviewsDone = 0;
          visibleJobs.forEach((job) => {
            const reviewers = job.reviewers;

            let applicantsCount = 0;
            job.applicants.forEach((applicant) => {
              if (job.review_config.stage_id === applicant.pipeline_stage) {
                applicantsCount += 1;
                const applicantReview = applicant.review;

                if (applicantReview) {
                  const applicantReviewKeys = Object.keys(applicantReview);
                  const intersection = applicantReviewKeys.filter((x) => reviewers.includes(x));
                  //console.log(intersection)
                  reviewsDone += intersection.length;
                }
              }
            });

            reviewsToDo = applicantsCount * reviewers.length;
          });
        }

        const reviewsInfo = {
          reviewsToDo,
          reviewsDone
        };

        return cb(null, reviewsInfo);
      } else {
        return cb('Error on fetching data');
      }
    });
  };

  var getJobsToReview = function (cb) {
    let api = new Api();
    const reviewerId = UserProfile.getId();

    const filter = {
      fields: ['id'],
      where: {
        status: 'PUBLISHED',
        reviewers: {
          contains: [reviewerId.toString()]
        }
      }
    };

    api.get('Jobs', filter, (status, data) => {
      if (status <= 400) {
        const jobsCount = data.length;

        return cb(null, jobsCount);
      } else {
        return cb('Error on fetching data');
      }
    });
  };

  var getJobReviewsByReviewer = function (applicants, review_config) {
    let reviewsToDo = 0;
    let reviewsDone = 0;
    const reviewerId = UserProfile.getId();

    applicants.forEach((applicant) => {
      if (review_config.stage_id === applicant.pipeline_stage) {
        reviewsToDo += 1;

        const applicantReview = applicant.review;
        const hasReviewed =
          applicantReview !== null &&
          !(applicantReview[reviewerId] === null || applicantReview[reviewerId] === undefined);

        if (hasReviewed) {
          reviewsDone += 1;
        }
      }
    });

    const reviewsInfo = {
      reviewsToDo,
      reviewsDone
    };
    return reviewsInfo;
  };

  var getJobReviewsByRecruiter = function (applicants, reviewers) {
    const reviewsToDo = reviewers.length * applicants.length;
    let reviewsDone = 0;

    applicants.forEach((applicant) => {
      const applicantReview = applicant.review;

      if (applicantReview) {
        const applicantReviewKeys = Object.keys(applicantReview);
        const intersection = applicantReviewKeys.filter((x) => reviewers.includes(x));
        //console.log(intersection)
        reviewsDone += intersection.length;
      }
    });

    const reviewsInfo = {
      reviewsToDo,
      reviewsDone
    };
    return reviewsInfo;
  };

  var getJobReviewsByApplicant = function (applicant, reviewers) {
    const reviewsToDo = reviewers.length;
    let reviewsDone = 0;

    const applicantReview = applicant.review;

    if (applicantReview) {
      const applicantReviewKeys = Object.keys(applicantReview);
      const intersection = applicantReviewKeys.filter((x) => reviewers.includes(x));
      //console.log(intersection)
      reviewsDone += intersection.length;
    }

    const reviewsInfo = {
      reviewsToDo,
      reviewsDone
    };
    return reviewsInfo;
  };

  var getJobReviewsByJob = function (applicants, reviewers) {
    const applicantToBeReviewed = applicants.filter((applicant) => applicant.review_completed >= 0);
    const reviewsToDo = applicantToBeReviewed.length;
    let reviewsDone = 0;

    applicants.forEach((applicant) => {
      const applicantReview = applicant.review;

      if (applicantReview) {
        const applicantReviewKeys = Object.keys(applicantReview);
        const intersection = applicantReviewKeys.filter((x) => reviewers.includes(x));

        if (intersection.length === reviewers.length) {
          reviewsDone += 1;
        }
      }
    });

    const reviewsInfo = {
      reviewsToDo,
      reviewsDone
    };

    return reviewsInfo;
  };

  var getIncompleteReviewsInfo = function (applicants, reviewers) {
    let incompleteReviews = false;
    applicants.forEach((applicant) => {
      const applicantReview = applicant.review;

      //console.log(applicant.name);
      if (applicantReview) {
        const applicantReviewKeys = Object.keys(applicantReview);
        const intersection = applicantReviewKeys.filter((x) => reviewers.includes(x));

        if (intersection.length < reviewers.length) {
          incompleteReviews = true;
        }
      } else {
        incompleteReviews = true;
      }

      //console.log('incomplete review ' + incompleteReviews);
    });

    return incompleteReviews;
  };

  return {
    getJobReviews: getJobReviews,
    getJobReviewsByReviewer: getJobReviewsByReviewer,
    getJobReviewsByRecruiter: getJobReviewsByRecruiter,
    getJobReviewsByApplicant: getJobReviewsByApplicant,
    getJobReviewsByJob: getJobReviewsByJob,
    getIncompleteReviewsInfo: getIncompleteReviewsInfo,
    getJobsToReview: getJobsToReview
  };
})();

export default JobReviewController;
