import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import * as CONST from '../Common/constants';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Avatar from 'react-avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const messages = defineMessages({});

// MUST BE OUTSIDE OF COMPONENT
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
  iconInfo: {
    color: `${theme.palette.gray.darker} !important`
  }
});
class ModalTransferWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      infoApplicants: props.infoApplicants
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open
      });
    }
    if (prevProps.infoApplicants !== this.props.infoApplicants) {
      this.setState({
        infoApplicants: this.props.infoApplicants
      });
    }
  }

  handleClose = () => {
    this.props.handleTransferModalClose();
  };

  render() {
    const { applicantsNotTransferred = [], applicantsTransferred } = this.state.infoApplicants;
    const transferredCount = applicantsTransferred.length;
    const { classes } = this.props;

    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar color="default" position="relative">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Box flex={1}>
                <h5 className="text-center text-uppercase">
                  <FormattedHTMLMessage
                    id="ModalTransferWarning.title"
                    defaultMessage="Candidate Transfer"
                  />
                </h5>
              </Box>
            </Toolbar>
          </AppBar>
          {transferredCount > 0 && (
            <div className="panel panel-body" style={{ marginBottom: '0px', paddingBottom: '0px' }}>
              <Typography variant="h6">
                <FormattedMessage
                  id="ModalTransferWarning.applicant_transferred"
                  defaultMessage="Transferred candidates: {number}"
                  values={{
                    number: transferredCount
                  }}
                />
              </Typography>
              <hr style={{ margin: '0.6rem 0' }} />
            </div>
          )}
          <div className="panel panel-body">
            <Box mb={2}>
              <Alert
                severity="info"
                classes={{ icon: classes.iconInfo }}
                style={{
                  backgroundColor: CONST.APP_COLORS['gray-light'],
                  color: CONST.APP_COLORS['gray-darker']
                }}
              >
                <FormattedMessage
                  id="ModalTransferWarning.warning"
                  defaultMessage="Candidates below were not transferred as they were already included in the target opportunity."
                />
              </Alert>
            </Box>
            <Typography variant="h6" gutterBottom>
              <FormattedMessage
                id="ModalTransferWarning.applicant_not_transferred"
                defaultMessage="Non-transferred candidates:"
              />
            </Typography>
            <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
              {applicantsNotTransferred.map((applicant, i) => (
                <div key={i} style={{ margin: '0 0.6rem 0.6rem 0' }}>
                  <ListItem style={{ width: '15rem' }}>
                    <ListItemAvatar>
                      <Avatar
                        name={applicant.name}
                        email={applicant.email}
                        src={
                          applicant.avatar_50_path
                            ? `https://s3-sa-east-1.amazonaws.com/recrutai-dev/${applicant.avatar_50_path}`
                            : ''
                        }
                        maxInitials={2}
                        color={CONST.APP_COLORS['primary-light']}
                        size="30px"
                        round={true}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={applicant.name} />
                  </ListItem>
                </div>
              ))}
            </Box>
          </div>
        </Dialog>
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ModalTransferWarning));
