import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Api from '../Api/Api';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withStyles } from '@material-ui/core/styles';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import Avatar from '@material-ui/core/Avatar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as CONST from '../Common/constants';
import Collapse from '@material-ui/core/Collapse';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Row, Col } from 'react-bootstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { MobileDateTimePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Moment from 'moment';
import SendIcon from '@material-ui/icons/Send';
import Typography from '@material-ui/core/Typography';
import StageController from './StageController';
import Alert from '@material-ui/lab/Alert';
import ModalAlertBlock from './ModalAlertBlock';

import 'moment/locale/pt-br'; // FIXME locale

const messages = defineMessages({});

// MUST BE OUTSIDE OF COMPONENT
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: theme.palette.common.white
  },
  primaryOption: {
    backgroundColor: theme.palette.primary.main
  },
  button: {
    margin: theme.spacing(1)
  },
  iconWarning: {
    color: `${theme.palette.warning.main} !important`
  }
});

class ModalReminder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      thisStageObj: props.thisStageObj,
      job_id: props.job_id,
      selected_ids: props.selected_ids,
      nr_model_ids: props.nr_model_ids,
      selected_objs: props.selected_objs,
      // Internal State
      submiting: false,
      extend: false,
      limitType: 'DAYS',
      daysLimit: 0,
      dateLimit: null,
      isBlocked: false,
      modalOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(
        {
          open: this.props.open
        },
        () => {
          this.warningApplicantBlocked();
        }
      );
    }
    if (JSON.stringify(prevProps.thisStageObj) !== JSON.stringify(this.props.thisStageObj)) {
      this.setState({
        thisStageObj: this.props.thisStageObj,
        daysLimit: 0, // Reseting if changed
        dateLimit: null // Reseting if changed
      });
    }
    if (prevProps.selected_ids !== this.props.selected_ids) {
      this.setState({
        selected_ids: this.props.selected_ids
      });
    }
    if (JSON.stringify(prevProps.nr_model_ids) !== JSON.stringify(this.props.nr_model_ids)) {
      this.setState({
        nr_model_ids: this.props.nr_model_ids
      });
    }
    if (prevProps.selected_objs !== this.props.selected_objs) {
      this.setState({
        selected_objs: this.props.selected_objs
      });
    }
  }

  warningApplicantBlocked = () => {
    const isAnyBlocked = this.state.selected_objs.some((applicant) => {
      const { emailBlocklists, docBlocklists } = applicant;
      const activeBlockValidation =
        (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
        (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0);
      return activeBlockValidation === true;
    });

    this.setState({ isBlocked: isAnyBlocked });
  };

  loadDefaultDays() {
    // If already set before
    if (this.state.daysLimit === 0 && !this.state.dateLimit) {
      const payload = {
        stage: this.state.thisStageObj.type
      };

      let api = new Api();
      api.getParam('Applicants/guess-extend-limit', payload, (status, data) => {
        // console.log(data);
        let days = data.defaultDateLimit;
        if (days > 30) {
          days = 30;
        }

        let newDate = Moment()
          .add(days, 'days')
          .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
          .toDate();

        //console.log(newDate);

        this.setState({
          daysLimit: days,
          dateLimit: newDate
        });
      });
    }
  }

  handleOpen = () => {
    this.setState(
      {
        extend: false
      },
      () => {
        this.loadDefaultDays();
      }
    );
  };

  handleClose = () => {
    this.setState(
      {
        open: false,
        isBlocked: false,
        modalOpen: false
      },
      () => {
        if (this.props.handlerClose) {
          this.props.handlerClose();
        }
      }
    );
  };

  handleModalOpen = () => {
    this.setState({
      open: false,
      modalOpen: true
    });
  };

  handleUpdate = (options) => {
    if (this.props.handlerPost) {
      this.props.handlerPost({
        ids: this.state.selected_ids,
        options: options
      });
    }
  };

  sendReminder = () => {
    const extend = this.state.extend;
    const dateLimit = this.state.dateLimit;
    const selected_ids = this.state.selected_ids;
    const nr_model_ids = this.state.nr_model_ids;

    const models = selected_ids.map((id) => {
      const idx = nr_model_ids.findIndex((x) => x.applicant_id === id);
      if (idx > -1) {
        return nr_model_ids[idx].model_id;
      }
    });
    //console.log(models);

    if (models.length > 0) {
      this.setState(
        {
          submiting: true
        },
        () => {
          const thisStageObj = this.state.thisStageObj;

          let model;
          switch (thisStageObj.type) {
            case CONST.STAGE_CUSTOMTEST:
              model = 'ApplicantCustomTest';
              break;
            case CONST.STAGE_INTERVIEW:
              model = 'Interview';
              break;
            case CONST.STAGE_VIDEOINTERVIEW:
              model = 'VideoInterview';
              break;
          }

          // Model is valid
          if (model) {
            // Extending limit will also notify and remind applicants
            if (extend) {
              const options = {
                model: model,
                model_ids: models,
                date_limit: dateLimit
              };
              // console.log(options);

              let api = new Api();
              api.post('Applicants/extend-limit', options, (status, data) => {
                this.setState(
                  {
                    submiting: false,
                    open: false
                  },
                  () => {
                    this.handleUpdate(options);
                  }
                );
              });
            } else {
              // Just remind
              const options = {
                model: model,
                model_ids: models
              };

              let api = new Api();
              api.post('Applicants/reminder', options, (status, data) => {
                this.setState(
                  {
                    submiting: false,
                    open: false
                  },
                  () => {
                    this.handleUpdate(options);
                  }
                );
              });
            }
          }
        }
      );
    }
    this.handleClose();
  };

  toggleExtendOpts = () => {
    this.setState({
      extend: !this.state.extend
    });
  };

  handleDaysLimitChange(e, days) {
    //console.log(days);
    let newDate = Moment()
      .add(days, 'days')
      .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
      .toDate();

    this.setState({
      daysLimit: days,
      dateLimit: newDate
    });
  }

  handleDateLimitChange(value) {
    //console.log(value.toDate())
    this.setState({
      dateLimit: value.toDate()
    });
  }

  setLimitType = (e) => {
    //console.log(e.target.value);
    switch (e.target.value) {
      case 'DAYS':
        let newDate = Moment()
          .add(this.state.daysLimit, 'days')
          .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
          .toDate();

        this.setState({
          limitType: e.target.value,
          dateLimit: newDate
        });
        break;
      case 'DATE':
        this.setState({
          limitType: e.target.value
        });
        break;
    }
  };

  render() {
    const { classes } = this.props;

    const displayDaysLimitDate = () => {
      if (this.state.daysLimit > 0 && this.state.limitType === 'DAYS') {
        let showDate = Moment(this.state.dateLimit).calendar();

        return (
          <p className="text-center mt0">
            <small>
              <FormattedHTMLMessage
                id="ModalCustomTestOptions.newLimitDate"
                values={{ newDate: showDate }}
              />
            </small>
          </p>
        );
      } else {
        return <p></p>;
      }
    };

    return (
      <>
        {this.state.isBlocked && (
          <ModalAlertBlock
            open={this.state.open}
            handleModalOpen={this.handleModalOpen}
            handlerClose={this.handleClose}
          />
        )}
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={this.state.isBlocked ? this.state.modalOpen : this.state.open}
          onEnter={this.handleOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <Backdrop open={this.state.submiting} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitle id="dialog-title">
            <FormattedMessage id="ModalReminder.title" defaultMessage="Send reminder/s" />
            <br />
            <small className="text-muted">
              <FormattedMessage
                id="ModalReminder.subtitle"
                defaultMessage="Send e-mail and/or SMS reminders to selected candidate/s"
              />
            </small>
            <br />
            {this.state.isBlocked && (
              <Alert
                severity="warning"
                classes={{ icon: classes.iconWarning }}
                style={{
                  backgroundColor: CONST.APP_COLORS['warning-light'],
                  color: CONST.APP_COLORS['warning']
                }}
              >
                <Typography>
                  <FormattedMessage
                    id="ModalReminder.alert_block"
                    defaultMessage="There are candidates with active restrictions in this selection."
                  />
                </Typography>
              </Alert>
            )}
          </DialogTitle>
          {this.state.thisStageObj.type === CONST.STAGE_INTERVIEW ? null : (
            <List>
              <ListItem autoFocus button onClick={() => this.toggleExtendOpts()}>
                <ListItemAvatar>
                  <Avatar className={classes.primaryOption}>
                    <AvTimerIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="ModalReminder.buttonExtendLimit"
                      defaultMessage="Click to extend deadline"
                    />
                  }
                  secondary={
                    <FormattedMessage
                      id="ModalReminder.buttonExtendLimitHelp"
                      defaultMessage="Provide a new deadline for selected candidate/s. Otherwise, all overdues will be ignored."
                    />
                  }
                />
              </ListItem>
              <Collapse in={this.state.extend} timeout="auto">
                <ListItem>
                  <div style={{ width: '100%' }}>
                    <Row className="mb mt">
                      <Col md={5}>
                        <FormControlLabel
                          name="limit_type"
                          value="DAYS"
                          checked={this.state.limitType === 'DAYS'}
                          onChange={(e) => this.setLimitType(e)}
                          control={<Radio color="primary" />}
                          labelPlacement="end"
                          label={
                            <small>
                              <FormattedMessage id="ModalCustomTestOptions.option_numberDays" />
                            </small>
                          }
                        />
                      </Col>
                      <Col md={7}>
                        <div style={{ width: '100%' }}>
                          <Slider
                            name="dayslimit"
                            className="mt"
                            value={this.state.daysLimit}
                            step={1}
                            marks
                            min={1}
                            max={5}
                            valueLabelDisplay="on"
                            onChange={(e, value) => this.handleDaysLimitChange(e, value)}
                            disabled={this.state.limitType === 'DATE'}
                            required={this.state.limitType === 'DAYS'}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}>
                        <p className="text-center text-muted">
                          <FormattedMessage id="ModalCustomTestOptions.option_or" />
                        </p>
                      </Col>
                      <Col md={7} className="text-center">
                        {displayDaysLimitDate()}
                      </Col>
                    </Row>
                    <Row className="mt">
                      <Col md={5}>
                        <FormControlLabel
                          name="limit_type"
                          value="DATE"
                          checked={this.state.limitType === 'DATE'}
                          onChange={(e) => this.setLimitType(e)}
                          control={<Radio color="primary" />}
                          labelPlacement="end"
                          label={
                            <small>
                              <FormattedMessage id="ModalCustomTestOptions.option_SpecificDay" />
                            </small>
                          }
                        />
                      </Col>
                      <Col md={7}>
                        <MobileDateTimePicker
                          name="datetime_limit"
                          disablePast={true}
                          minutesStep={5}
                          ampm={false}
                          autoOk={true}
                          value={this.state.dateLimit}
                          onChange={(value) => this.handleDateLimitChange(value)}
                          disabled={this.state.limitType === 'DAYS'}
                          required={this.state.limitType === 'DATE'}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </Col>
                    </Row>
                  </div>
                </ListItem>
                <Divider />
              </Collapse>
            </List>
          )}
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="default">
              <FormattedMessage id="Base.button.cancel" />
            </Button>
            <Button onClick={() => this.sendReminder()} color="primary" endIcon={<SendIcon />}>
              <FormattedMessage id="ModalReminder.buttonSend" defaultMessage="Send reminder/s" />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ModalReminder));
