export const SET_COMPANY_TREE = 'SET_COMPANY_TREE';
export const SET_LOADING_COMPANY_TREE = 'SET_LOADING_COMPANY_TREE';

export function setCompanyTree(payload) {
  return { type: SET_COMPANY_TREE, payload };
}

export function setLoadingCompanyTree(payload) {
  return { type: SET_LOADING_COMPANY_TREE, payload };
}
