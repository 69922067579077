// core
import React from 'react';

// libs external
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// imports internal
import RenderUserOption from './RenderUserOption';
import RenderUserTags from './RenderUserTags';

const TextFieldCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: '4px 8px !important',
      borderRadius: 32
    }
  }
})(TextField);

class SelectUsersMultipleCustom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueLocal: []
    };
  }

  componentDidUpdate(prevProps) {
    const userSelecteds = [];

    if (prevProps.options !== this.props.options || prevProps.value !== this.props.value) {
      this.props.options.forEach((option) => {
        this.props.value?.forEach((value) => {
          if (option?.id == value) {
            userSelecteds.push(option);
          }
        });
      });

      this.setState({
        valueLocal: userSelecteds.length > 0 ? userSelecteds : []
      });
    }
  }

  onChangeLocal = (event, select) => {
    this.props.onChange(select);
    this.setState({
      valueLocal: select
    });
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter' && this.state.valueLocal.length == 0) {
      event.preventDefault();
    }
  };

  render() {
    const { valueLocal } = this.state;

    const { id, options, disabled, showUserCalendarSync } = this.props;

    const idTextlabel = this.props.idTextlabel;
    const labelComponent = this.props.label;
    const placeholder = this.props.placeholder;
    const required = this.props.required;

    return (
      <div>
        {labelComponent && <label>{labelComponent}</label>}
        {idTextlabel && (
          <label>
            <FormattedMessage id={idTextlabel} />*
          </label>
        )}
        <Autocomplete
          id={id}
          name={id}
          value={valueLocal}
          options={options}
          size="small"
          getOptionLabel={(option) => (option.username ? option.username : '')}
          onChange={this.onChangeLocal}
          renderOption={(user) => (
            <RenderUserOption user={user} showUserCalendarSync={showUserCalendarSync} />
          )}
          disableCloseOnSelect
          fullWidth
          filterSelectedOptions
          disabled={disabled}
          clearOnBlur={false}
          multiple
          renderTags={(tagValue, getTagProps) => (
            <RenderUserTags
              tagValue={tagValue}
              getTagProps={getTagProps}
              showUserCalendarSync={showUserCalendarSync}
            />
          )}
          renderInput={(params) => (
            <TextFieldCustom
              {...params}
              variant="outlined"
              color="secondary"
              size="small"
              required={required && valueLocal.length == 0}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                onKeyDown: this.onKeyDown
              }}
            />
          )}
        />
      </div>
    );
  }
}
SelectUsersMultipleCustom.propTypes = {
  id: PropTypes.number,
  value: PropTypes.array,
  idTextlabel: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  labelComponent: PropTypes.any,
  placeholder: PropTypes.string
};

export default injectIntl(SelectUsersMultipleCustom);
