import React from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import UserProfile from '../Common/UserProfile';
import UtilsForm from '../Common/UtilsForm';
import { Row, Col } from 'react-bootstrap';
import Api from '../Api/Api';
import AddressController from '../Common/AddressController';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as CONST from '../Common/constants';

import Moment from 'moment';
import 'moment/locale/pt-br'; // FIXME locale

require('react-datepicker/dist/react-datepicker.css');
Moment.locale('pt-br'); // FIXME locale

const useStyles = (theme) => ({
  titleRow: {
    display: 'flex',
    alignItems: 'center'
  },
  inputLabel: {
    marginBottom: '1rem'
  },
  activeLabel: {
    marginBottom: '0'
  },
  iconInfo: {
    color: `${theme.palette.gray.darker} !important`
  }
});

const messages = defineMessages({
  chooseOptionRequired: {
    id: 'DialogBlocklistEdit.chooseOptionRequired',
    defaultMessage: 'You must choose at least one form of identification (e-mail or document ID)'
  },
  invalidEmail: {
    id: 'DialogBlocklistEdit.invalidEmail',
    defaultMessage: 'Invalid e-mail'
  },
  applicantAlreadyExist: {
    id: 'DialogBlocklistEdit.applicantAlreadyExist',
    defaultMessage: 'E-mail or document restriction already exists'
  },
  insertDocumentNumber: {
    id: 'DialogBlocklistEdit.insertDocumentNumber',
    defaultMessage: 'Document ID'
  }
});

class DialogBlocklistEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Props
      identityDocs: this.props.identityDocs.identityDocs,
      editModal: this.props.editModal,
      row: this.props.row,
      closeDialog: this.props.closeDialog,
      //BlockEditList: [],
      user_id: UserProfile.getId(),
      id: this.props.row && this.props.row.id ? this.props.row.id : null,
      name: this.props.row && this.props.row.name ? this.props.row.name : '',
      email: this.props.row && this.props.row.email ? this.props.row.email : '',
      doc_number: this.props.row && this.props.row.doc_number ? this.props.row.doc_number : '',
      doc_type: this.props.row && this.props.row.doc_type ? this.props.row.doc_type : '',
      motive_id: this.props.row && this.props.row.motive_id ? this.props.row.motive_id : null,
      comments: this.props.row ? this.props.row.comments : null,
      country: this.props.row && this.props.row.country ? this.props.row.country : null,
      has_date_block: this.props.row && this.props.row.date_limit ? false : true,
      date_limit:
        this.props.row && this.props.row.date_limit ? Moment(this.props.row.date_limit) : null,
      countries_name: [],
      country_label: '',
      motiveList: [],
      motive_label: '',
      selectedMotive: null,
      _submiting: false,
      date_created: new Date(),
      _readonly: false
    };
  }

  componentDidMount() {
    this.getCountries();
    this.getMotiveList();
  }

  getCountries = () => {
    const { identityDocs } = this.state;
    const userCountryDefault = this.props.accountInfo.accountInfo.country;
    let api = new Api();
    let filter = {
      order: ['suggested DESC', 'name']
    };
    api.get('Countries', filter, (status, data) => {
      if (data.length > 0) {
        const list = data.map((country) => ({
          value: country.code,
          label: country.name
        }));

        let countryList = [];
        let country = this.state.country;
        if (this.state.doc_type !== '') {
          data.forEach((country) => {
            identityDocs.forEach((identityDoc) => {
              if (country.code === identityDoc.country) {
                if (this.state.doc_type === identityDoc.code)
                  countryList.push({ value: country.code, label: country.name });
              }
            });
          });
        }
        countryList.length > 0 ? (country = countryList[0].value) : country;
        const selectedCountry = this.selectCountry(country, list);

        const filterDoc = identityDocs.filter((doc) => doc.country === userCountryDefault);
        let firstDocTypeForCountry = filterDoc.length > 0 ? filterDoc[0].code : '';

        if (selectedCountry) {
          const docExists = identityDocs.find((doc) => doc.country === selectedCountry.value);
          firstDocTypeForCountry = docExists ? docExists.code : '';
        }

        this.setState({
          countries_name: list,
          country: selectedCountry ? selectedCountry.value : userCountryDefault,
          country_label: selectedCountry
            ? selectedCountry.label
            : list.find((country) => country.value === userCountryDefault)?.label || '',
          doc_type: firstDocTypeForCountry
        });
      }
    });
  };

  getMotiveList() {
    let api = new Api();
    let userFilter = {
      fields: ['motive', 'id'],
      where: {
        account_id: UserProfile.getRealm()
      }
    };

    api.get('BlockMotives', userFilter, (status, motiveList) => {
      const selectedMotive = this.selectMotive(this.state.motive_id, motiveList);
      this.setState(
        {
          motiveList,
          selectedMotive: {
            id: selectedMotive ? selectedMotive.id : undefined,
            motive: selectedMotive ? selectedMotive.motive : undefined
          }
        },
        () => {
          this.displayIDDocument();
        }
      );
    });
  }

  selectMotive = (id, motiveList) => {
    return motiveList.find((selected) => selected.id === id) || null;
  };

  checkIfApplicantExists = (email, docNumber) => {
    return new Promise((resolve, reject) => {
      let api = new Api();
      let now = new Date();
      const validEmail = email !== '' && email !== null && email;
      const validDocnumber = docNumber !== '' && docNumber !== null && docNumber;
      const numberWithoutMask = docNumber
        ? docNumber.replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '')
        : docNumber;
      let filter = {
        where: {
          deleted: false,
          or: [
            {
              and: [
                { active: true },
                { or: [{ date_limit: null }, { date_limit: { gt: now } }] },
                {
                  or: [{ email: validEmail }, { doc_number: numberWithoutMask }]
                }
              ]
            }
          ]
        }
      };
      api.get('Blocklists', filter, (status, block) => {
        const applicantExists = block.length > 0;
        resolve(applicantExists);
      });
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  handleDateLimitChange = (date) => {
    this.setState({
      date_limit: Moment(date).endOf('day').toDate()
    });
  };

  handleChangeSelectDocType = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  displayIDDocument = () => {
    const { doc_type, identityDocs } = this.state;
    let containerId = 'doc_number';

    if (identityDocs && identityDocs.length > 0) {
      const applicantDocIndex = identityDocs.findIndex((doc) => doc.code === doc_type);
      if (applicantDocIndex > -1) {
        const docMask = identityDocs[applicantDocIndex].id_mask;
        AddressController.getMaskedID(containerId, docMask);
      }
    }
  };

  handleChangeSelectCountry = (event, selected) => {
    const { identityDocs } = this.state;
    if (selected) {
      const selectedCountry = this.state.countries_name.find(
        (country) => country.value === selected.value
      );

      const filterDoc = identityDocs.filter((doc) => doc.country === selected.value);
      const firstDocTypeForCountry = filterDoc.length > 0 ? filterDoc[0].code : '';

      this.setState(
        {
          country: selected.value,
          country_label: selectedCountry ? selectedCountry.label : '',
          doc_type: firstDocTypeForCountry,
          doc_number: ''
        },
        () => {
          this.displayIDDocument();
        }
      );
    } else {
      this.setState({
        country: null,
        country_label: null,
        doc_type: '',
        doc_number: ''
      });
    }
  };

  selectCountry = (country, countryList) => {
    return countryList.find((selected) => selected.value === country) || null;
  };

  handleChangeSelectMotive = (event, selected) => {
    const { motiveList } = this.state;
    const findMotive = motiveList.find((m) => m.motive === selected.motive);
    if (selected) {
      this.setState({
        selectedMotive: { id: findMotive.id, motive: findMotive.motive },
        motive_id: findMotive ? findMotive.id : undefined,
        motive_label: findMotive ? findMotive.motive : undefined
      });
    } else {
      this.setState({
        motive_id: null,
        motive_label: null
      });
    }
  };

  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email) || email === '';
    return isEmailValid;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      user_id,
      name,
      email,
      doc_number,
      doc_type,
      motive_id,
      comments,
      date_limit,
      country,
      row
    } = this.state;

    try {
      const applicantExists = await this.checkIfApplicantExists(email, doc_number);

      if (!email && !doc_number) {
        window.swal({
          type: 'info',
          title: '',
          text: this.props.intl.formatMessage({ id: 'DialogBlocklistEdit.chooseOptionRequired' }),
          closeOnConfirm: true
        });
        this.setState({
          _submiting: false
        });
        return;
      } else if (!this.validateEmail(email)) {
        window.swal({
          type: 'info',
          title: '',
          text: this.props.intl.formatMessage({ id: 'DialogBlocklistEdit.invalidEmail' }),
          closeOnConfirm: true
        });
        this.setState({
          _submiting: false
        });
        return;
      } else if (!row && applicantExists) {
        window.swal({
          type: 'info',
          title: '',
          text: this.props.intl.formatMessage({ id: 'DialogBlocklistEdit.applicantAlreadyExist' }),
          closeOnConfirm: true
        });
        this.setState({
          _submiting: false
        });
        return;
      }

      this.setState(
        {
          _submiting: true
        },
        () => {
          const newList = {
            account_id: UserProfile.getRealm(),
            user_id: UtilsForm.replaceToNull(user_id),
            name: UtilsForm.replaceToNull(name),
            email: UtilsForm.replaceToNull(email),
            country: UtilsForm.replaceToNull(country),
            doc_number: UtilsForm.replaceToNull(doc_number),
            doc_type: UtilsForm.replaceToNull(doc_type),
            motive_id: UtilsForm.replaceToNull(motive_id),
            date_limit: UtilsForm.replaceToNull(date_limit),
            comments: UtilsForm.replaceToNull(comments)
          };

          const editedList = {
            account_id: UserProfile.getRealm(),
            name: UtilsForm.replaceToNull(name),
            email: UtilsForm.replaceToNull(email),
            country: UtilsForm.replaceToNull(country),
            doc_number: UtilsForm.replaceToNull(doc_number),
            doc_type: UtilsForm.replaceToNull(doc_type),
            motive_id: UtilsForm.replaceToNull(motive_id),
            date_limit: UtilsForm.replaceToNull(date_limit),
            comments: UtilsForm.replaceToNull(comments)
          };

          if (row && row.id) {
            this.editApplicantBlocked(editedList);
          } else {
            this.addNewBlocker(newList);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  addNewBlocker = (newList) => {
    const docNumber = newList.doc_number;
    let country = newList.country;
    let docType = newList.doc_type;
    if (!docNumber) {
      docType = null;
      country = null;
    }
    const numberWithoutMask = docNumber
      ? docNumber.replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '')
      : docNumber;
    let api = new Api();
    api.post(
      'Blocklists',
      { ...newList, doc_number: numberWithoutMask, country, doc_type: docType },
      (status, data) => {
        this.setState(
          {
            _submiting: false
          },
          () => {
            this.state.closeDialog(true);
          }
        );
      }
    );
  };

  editApplicantBlocked = (editedList) => {
    const docNumber = editedList.doc_number;
    let country = editedList.country;
    let docType = editedList.doc_type;
    if (!docNumber) {
      docType = null;
      country = null;
    }
    const numberWithoutMask = docNumber
      ? docNumber.replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '')
      : docNumber;
    const edit_id = this.state.id;
    let api = new Api();
    api.update(
      'Blocklists',
      edit_id,
      { ...editedList, doc_number: numberWithoutMask, country, doc_type: docType },
      (status, data) => {
        this.setState(
          {
            _submiting: false,
            id: null
          },
          () => {
            this.state.closeDialog(true);
            this.setState({
              name: '',
              email: '',
              doc_number: '',
              doc_type: '',
              motive_id: '',
              comments: ''
            });
          }
        );
      }
    );
  };

  render() {
    const {
      identityDocs,
      editModal,
      name,
      email,
      doc_number,
      doc_type,
      motive_id,
      country,
      has_date_block,
      _readonly,
      date_created,
      date_limit,
      countries_name,
      country_label,
      row
    } = this.state;
    const { classes } = this.props;
    const onlyRead = row?.onlyRead ? true : false;

    return (
      <Dialog
        open={editModal}
        fullWidth={true}
        maxWidth="sm"
        onClose={editModal}
        className={classes.modal}
        disableEnforceFocus
      >
        <form id="formEditTag" onSubmit={(e) => this.onSubmit(e)}>
          <DialogTitle>
            <FormattedMessage id="DialogBlocklistEdit.title" defaultMessage="Edit restriction" />
          </DialogTitle>
          <DialogContent>
            <Row className={classes.titleRow}>
              <Col md={12}>
                <div className="form-group">
                  <TextField
                    id="name"
                    name="name"
                    type="text"
                    value={name}
                    required={true}
                    fullWidth
                    onChange={this.handleChange}
                    label={
                      <FormattedMessage
                        id="DialogBlocklistEdit.name"
                        defaultMessage="Candidate name"
                      />
                    }
                    disabled={onlyRead}
                  />
                </div>
              </Col>
            </Row>
            <Row className={classes.titleRow}>
              <Col md={12}>
                <div className="form-group">
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    fullWidth
                    onChange={this.handleChange}
                    label={
                      <FormattedMessage id="DialogBlocklistEdit.email" defaultMessage="E-mail" />
                    }
                    disabled={onlyRead}
                  />
                </div>
                <Alert
                  severity="info"
                  classes={{ icon: classes.iconInfo }}
                  style={{
                    backgroundColor: CONST.APP_COLORS['gray-light'],
                    color: CONST.APP_COLORS['gray-darker']
                  }}
                >
                  <FormattedMessage
                    id="DialogBlocklistEdit.infoBlockRequired"
                    defaultMessage="You must provide at least an e-mail address or a document ID"
                  />
                </Alert>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group"></div>
              </Col>
            </Row>
            <Row className={classes.titleRow}>
              <Col md={12}>
                <div className="form-group">
                  <Box>
                    <OutlinedInput
                      id="doc_number"
                      name="doc_number"
                      value={doc_number}
                      onChange={this.handleChange}
                      fullWidth
                      placeholder={this.props.intl.formatMessage({
                        id: 'DialogBlocklistEdit.insertDocumentNumber'
                      })}
                      startAdornment={
                        <Box display="flex" alignItems="center">
                          <Box mr={1} minWidth={100}>
                            <Autocomplete
                              id="country"
                              name="country"
                              options={countries_name}
                              autoHighlight
                              disableClearable
                              value={country_label ? { label: country_label } : null}
                              defaultValue={country ? country : null}
                              onChange={this.handleChangeSelectCountry}
                              getOptionLabel={(option) => option.label || ''}
                              getOptionSelected={(option, value) => option.value === value?.value}
                              renderInput={(params) => <TextField {...params} />}
                              required={true}
                              disabled={onlyRead}
                            />
                          </Box>
                          <Box mr={1} minWidth={100}>
                            <Select
                              id="doc_type"
                              name="doc_type"
                              onChange={this.handleChangeSelectDocType}
                              value={doc_type}
                              fullWidth
                              displayEmpty
                              disabled={onlyRead}
                              renderValue={(selected) => {
                                const selectedOption = identityDocs.find(
                                  (type) => type.code === selected
                                );
                                return selectedOption ? selectedOption.name : '';
                              }}
                            ></Select>
                          </Box>
                        </Box>
                      }
                      disabled={!country_label || onlyRead}
                    />
                  </Box>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Box display="flex" alignItems="center">
                  <Box mr={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="has_date_block"
                          color="primary"
                          checked={this.state.has_date_block}
                          onChange={this.handleChange}
                          disabled={onlyRead}
                        />
                      }
                      label={
                        <FormattedMessage
                          id="DialogBlocklistEdit.indefiniteDate"
                          defaultMessage="Definitive restriction"
                        />
                      }
                    />
                  </Box>

                  <Box>
                    {!this.state.has_date_block && (
                      <div className="form-group">
                        <DatePicker
                          id="date_limit"
                          name="date_limit"
                          label={
                            <FormattedMessage
                              id="DialogBlocklistEdit.dateLimit"
                              defaultMessage="Restriction limit"
                            />
                          }
                          value={date_limit}
                          onChange={this.handleDateLimitChange}
                          renderInput={(params) => <TextField {...params} />}
                          timeIntervals={60}
                          minDate={date_created}
                          clearable
                          ref={(el) => this.onDatepickerRef(el)}
                          required={has_date_block}
                          readOnly={_readonly}
                          disabled={onlyRead}
                        />
                      </div>
                    )}
                  </Box>
                </Box>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <Autocomplete
                    id="motive_id"
                    name="motive_id"
                    fullWidth
                    options={this.state.motiveList}
                    classes={{
                      option: classes.option
                    }}
                    disableClearable
                    value={this.state.selectedMotive}
                    onChange={this.handleChangeSelectMotive}
                    getOptionLabel={(option) => option.motive}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <FormattedMessage
                            id="DialogBlocklistEdit.motive"
                            defaultMessage="Reason"
                          />
                        }
                      />
                    )}
                    required={true}
                    disabled={onlyRead}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <Box display="flex" flexDirection="column">
                    <label htmlFor="required">
                      <FormattedMessage
                        id="DialogBlocklistEdit.comments"
                        defaultMessage="User comments"
                      />
                    </label>
                    <textarea
                      rows="4"
                      id="comments"
                      name="comments"
                      className="form-control"
                      value={this.state.comments}
                      onChange={this.handleChange}
                      disabled={onlyRead}
                    />
                  </Box>
                </div>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
            <Button type="button" color="secondary" onClick={() => this.state.closeDialog()}>
              <FormattedMessage id="Base.button.cancel" />
            </Button>
            <Button type="submit" color="primary" disabled={!name || !motive_id || onlyRead}>
              <FormattedMessage id="DialogBlockListEdit.button_save" defaultMessage="Save" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

DialogBlocklistEdit.propTypes = {
  identityDocs: PropTypes.object,
  accountInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  identityDocs: state.identityDocs,
  accountInfo: state.accountInfo
});

export default connect(
  mapStateToProps,
  null
)(injectIntl(withStyles(useStyles)(DialogBlocklistEdit)));
