// export const JOBS_REQUEST = 'JOBS_REQUEST'
// export const JOBS_SUCCESS = 'JOBS_SUCCESS'
// export const JOBS_FAILURE = 'JOBS_FAILURE'

// export function jobsRequest(payload) {
//   return { type: JOBS_REQUEST, payload }
// }

// export function jobsSuccess(data) {
//   return { type: JOBS_SUCCESS, data }
// }

// export function jobsFailure() {
//   return { type: JOBS_FAILURE }
// }
export const SET_USER = 'SET_USER';

export function setUser(payload) {
  return { type: SET_USER, payload };
}
