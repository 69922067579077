import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import LocaleFormatter from '../../Common/LocaleFormatter';

const messages = defineMessages({});

class InfoDateCreated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date
    };
  }

  render() {
    if (this.state.date) {
      let date_created = Moment(this.state.date).format('ll');
      let date_format = Moment(this.state.date).format('llll');

      return (
        <Tooltip
          title={
            <FormattedMessage
              id="InfoDateCreated.createdAt"
              defaultMessage="Applied at: {date}"
              values={{
                date: date_format
              }}
            />
          }
          arrow
        >
          <span
            style={{
              whiteSpace: 'nowrap'
            }}
          >
            <i className="far fa-clock"></i> <LocaleFormatter dateToFormat={date_created} />
          </span>
        </Tooltip>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(InfoDateCreated);
