import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Api from '../../../Api/Api';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import LoadingData from '../../../Layout/LoadingData';
import { connect } from 'react-redux';
import * as CONST from '../../../Common/constants';
import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css';
import AnalysisItem from './AnalysisItem';
import './Radar.css';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';

const useStyles = (theme) => ({
  applicantName: {
    fontSize: '22px',
    fontWeight: 700,
    color: theme.palette.gray.darker,
    lineHeight: 'normal'
  },
  subtitle: {
    fontSize: '16px',
    color: theme.palette.gray.darker,
    lineHeight: 'normal'
  },
  radar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    },
    '& > svg': {
      overflow: 'visible',
      [theme.breakpoints.down('sm')]: {
        width: 300,
        height: 300
      }
    }
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  containerElement: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {/*value === index && */ <Box height="100%">{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const defaultOptions = {
  axes: true, // show axes?
  scales: 4, // show scale circles?
  captions: true, // show captions?
  captionMargin: 20,
  dots: true, // show dots?
  zoomDistance: 1.25, // where on the axes are the captions?
  wrapCaptionAt: 40,
  captionLineHeight: 14,
  captionProps: () => ({
    className: 'caption',
    textAnchor: 'middle',
    fontSize: 10,
    fontFamily: 'sans-serif'
  }),
  dotProps: () => ({
    className: 'custom-dot'
    //mouseEnter: (dot) => { console.log(dot) },
    //mouseLeave: (dot) => { console.log(dot) }
  })
};

class Big5Analysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personality_profile: props.personality_profile,
      //Internal state
      big5Types: [],
      loadingBig5: false,
      labels: null,
      dataset: null,
      scores: null,
      definitions: null,
      size: 400
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.personality_profile !== this.props.personality_profile) {
      this.setState({
        personality_profile: this.props.personality_profile
      });
    }
  }

  componentDidMount() {
    this.loadBig5Types();
  }

  loadBig5Types() {
    this.setState(
      {
        loadingBig5: true
      },
      () => {
        let lang = {
          where: {
            lang: this.props.user.language
          }
        };
        let api = new Api();
        api.get('Big5Types', lang, (status, data) => {
          //console.log(data);
          let labels = {};
          data.forEach((big5) => {
            labels[big5.code] = big5.name;
          });

          this.setState(
            {
              big5Types: data,
              labels: labels,
              loadingBig5: false
            },
            () => {
              this.mountAnalysis();
            }
          );
        });
      }
    );
  }

  mountAnalysis = () => {
    const big5Types = this.state.big5Types;

    const personality_profile = this.state.personality_profile;

    const formattedBig5Types = big5Types.filter((currType) => currType.code.includes('big5'));

    let scores = {};
    let labels = {};
    let definitions = {};
    formattedBig5Types.forEach((big5) => {
      labels[big5.code] = big5.name;
      scores[big5.code] =
        big5.code === 'big5_neuroticism'
          ? 1 - personality_profile[big5.code] / 5
          : personality_profile[big5.code] / 5;
      definitions[big5.code] = big5.definition;
    });

    this.setState({
      labels: labels,
      scores: scores,
      definitions: definitions,
      dataset: {
        data: scores,
        meta: {
          color: CONST.APP_COLORS['primary']
        }
      }
    });
  };

  render() {
    const { classes } = this.props;

    const labels = this.state.labels;
    const dataset = this.state.dataset;
    const scores = this.state.scores;
    const definitions = this.state.definitions;

    const displayScores = () => {
      if (scores) {
        const keys = Object.keys(scores);

        return keys.map((key, index) => {
          let score = Math.round(scores[key] * 5);
          return (
            <Box key={index} mb={index === keys.length - 1 ? 0 : 4}>
              <AnalysisItem title={labels[key]} score={score} text={definitions[key]} />
            </Box>
          );
        });
      } else {
        return null;
      }
    };

    if (this.state.loadingBig5) {
      return <LoadingData />;
    }

    if (labels && dataset) {
      return (
        <>
          <Box mb={4}>
            <Box mb={1} display="flex" alignItems="center">
              <Box>
                <Typography className={classes.applicantName}>
                  <FormattedMessage
                    id="Big5Analysis.title_bigFive"
                    defaultMessage="Big Five Analysis"
                  />
                </Typography>
              </Box>
              <IconButton
                component={Link}
                href="https://meajuda.recrut.ai/hc/pt-br/articles/360042181494-Big-Five-Personality-Traits-"
                target="_help"
              >
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Box>

            <Typography className={classes.subtitle}>
              <FormattedMessage
                id="Big5Analysis.subtitle_bigFive"
                defaultMessage="5 main traits that make up the candidate's personality."
              />
            </Typography>
          </Box>
          <Box className={classes.mainContainer}>
            <Box mr={4} className={classes.containerElement}>
              {displayScores()}
            </Box>
            <Box className={`${classes.radar} ${classes.containerElement}`}>
              <RadarChart
                captions={labels}
                data={[dataset]}
                size={this.state.size}
                options={defaultOptions}
              />
            </Box>
          </Box>
        </>
      );
    } else {
      return null;
    }
  }
}

Big5Analysis.propTypes = {
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  user: state.user.user
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(Big5Analysis)));
