import React from 'react';
import AddressController from '../../Common/AddressController';

class InfoAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job_address: props.job_address,
      applicant_address: props.applicant_address
    };
  }

  render() {
    const applicant_address = this.state.applicant_address;
    const job_address = this.state.job_address;

    const addrInfo = AddressController.addrApplicantToJob(applicant_address, job_address);

    if (addrInfo) {
      return (
        <span
          style={{
            whiteSpace: 'nowrap'
          }}
        >
          <i className="icon-direction"></i> {addrInfo}
        </span>
      );
    } else {
      return null;
    }
  }
}

export default InfoAddress;
