import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

const messages = defineMessages({});

const useStyles = (theme) => ({
  generalChip: {
    background: theme.palette.gray.lighter,
    color: theme.palette.gray.darker,
    fontSize: '14px',
    fontWeight: 400
    // marginLeft: theme.spacing(1)
  }
});

class ChipPwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Chip
        className={classes.generalChip}
        size="small"
        label={this.props.intl.formatMessage({
          id: 'DetailSummary.pwd'
        })}
      />
    );
  }
}

export default injectIntl(withStyles(useStyles)(ChipPwd));
