import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import UserProfile from '../../Common/UserProfile';
import Api from '../../Api/Api';
import * as CONST from '../../Common/constants';
import Avatar from 'react-avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Highlighter from 'react-highlight-words';
import { withStyles } from '@material-ui/core/styles';
import LoadingData from '../../Layout/LoadingData';
import NoteListRun from './NoteList.run.js';
import Moment from 'moment';
import LocaleFormatter from '../../Common/LocaleFormatter';

const messages = defineMessages({
  buttonDeleteConfirmMsg: {
    id: 'NoteList.buttonDeleteConfirmMsg',
    defaultMessage: 'Are you sure you want to delete this comment?'
  }
});

const useStyles = (theme) => ({
  cardRoot: {
    border: '1px solid ' + theme.palette.gray.main,
    marginBottom: theme.spacing(1)
  },
  content: {
    flex: 1,
    padding: '6px 16px',
    minWidth: '90%'
  },
  dotPositive: {
    borderColor: theme.palette.success.main
  },
  dotNegative: {
    borderColor: theme.palette.error.main
  },
  dotNeutral: {
    borderColor: theme.palette.secondary.main
  }
});

class NoteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant_id: props.applicant_id,
      updatedNotes: props.updatedNotes ? props.updatedNotes : [],
      readonly: props.readonly ? props.readonly : false,
      // Internal State
      _loading: true,
      notes: []
    };
  }

  componentWillMount() {
    this.loadNotes();
  }

  componentDidMount() {
    NoteListRun();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.updatedNotes) !== JSON.stringify(this.props.updatedNotes)) {
      this.setState(
        {
          updatedNotes: this.props.updatedNotes
        },
        () => {
          this.loadNotes();
          NoteListRun();
        }
      );
    }
  }

  loadNotes() {
    const applicant_id = this.state.applicant_id;

    if (applicant_id) {
      this.setState(
        {
          _loading: true
        },
        () => {
          let filter = {
            include: [
              {
                relation: 'applicant',
                scope: {
                  fields: ['id', 'name', 'title']
                }
              },
              {
                relation: 'accountUser',
                scope: {
                  fields: [
                    'id',
                    'username',
                    'role',
                    'unique_name',
                    'user_profile',
                    'avatar_50_path'
                  ]
                }
              }
            ],
            where: {
              applicant_id: applicant_id
            },
            order: ['date_created DESC']
          };

          let api = new Api();
          api.get('ApplicantNotes', filter, (status, notes) => {
            //console.log(notes);
            this.setState(
              {
                _loading: false,
                notes: notes
              },
              () => {
                if (this.props.handlerUpdate) {
                  this.props.handlerUpdate(notes);
                }
                NoteListRun();
              }
            );
          });
        }
      );
    }
  }

  deleteNote = (note_id) => {
    window
      .swal({
        type: 'info',
        title: '',
        text: this.props.intl.formatMessage({
          id: 'NoteList.buttonDeleteConfirmMsg'
        }),
        closeOnConfirm: true,
        buttons: {
          cancel: {
            text: this.props.intl.formatMessage({
              id: 'Base.button.cancel'
            }),
            value: null,
            visible: true,
            className: '',
            closeModal: true
          },
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: '',
            closeModal: true
          }
        }
      })
      .then((isConfirmed) => {
        if (isConfirmed) {
          let api = new Api();
          api.delete('ApplicantNotes', note_id, (status, deleted) => {
            let notes = this.state.notes.map((a) => ({ ...a }));
            const idx = notes.findIndex((a) => a.id === note_id);
            notes.splice(idx, 1);

            this.setState(
              {
                notes: notes
              },
              () => {
                if (this.props.handlerUpdate) {
                  this.props.handlerUpdate(notes);
                }
                NoteListRun();
              }
            );
          });
        }
      });
  };

  render() {
    const { classes } = this.props;

    const notes = this.state.notes;
    const readonly = this.state.readonly;

    const displayNotes = notes.map((note, i) => {
      let color = 'dotNeutral';
      switch (note.sentiment) {
        case 'POSITIVE':
          color = 'dotPositive';
          break;
        case 'NEGATIVE':
          color = 'dotNegative';
          break;
      }

      const date = Moment(note.date_created).format('lll');

      let buttonDelete = null;
      if (readonly === false && note.user_id === UserProfile.getId()) {
        buttonDelete = (
          <IconButton
            disabled={false}
            onClick={() => {
              this.deleteNote(note.id);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }

      let mentionsArr = [];
      if (note.mentions && note.mentions.length > 0) {
        note.mentions.forEach((mention) => {
          mentionsArr.push(mention.display);
        });
      }

      let formmattedNote = note.note.replace(/(?:\r\n|\r|\n)/g, <br />);
      const path_avatar =
        'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + note.accountUser.avatar_50_path;

      return (
        <Card
          classes={{
            root: classes.cardRoot
          }}
        >
          <CardHeader
            avatar={
              <Avatar name={note.accountUser.username} src={path_avatar} size="20px" round={true} />
            }
            title={<small>{note.accountUser.username}</small>}
            subheader={
              <small>
                <LocaleFormatter dateToFormat={date} />
              </small>
            }
            action={buttonDelete}
          />
          <CardContent className="pt0 pb0">
            <p className="text-muted note-text">
              <Highlighter
                searchWords={mentionsArr}
                highlightStyle={{
                  backgroundColor: 'transparent',
                  borderBottom: '1px dotted ' + CONST.APP_COLORS['secondary']
                }}
                style={{
                  whiteSpace: 'pre-wrap'
                }}
                autoEscape={true}
                textToHighlight={note.note}
              />
            </p>
          </CardContent>
        </Card>
      );
    });

    if (this.state._loading === true) {
      return <LoadingData />;
    } else {
      if (notes.length > 0) {
        return <div>{displayNotes}</div>;
      } else {
        return (
          <Row>
            <Col sm={12}>
              <p className="text-center text-muted">
                <i className="fa fa-2x icon-speech"></i>
                <br />
                <FormattedMessage id="NoteList.noNotes" defaultMessage="Write the first note" />
              </p>
            </Col>
          </Row>
        );
      }
    }
  }
}

export default injectIntl(withStyles(useStyles)(NoteList));
