import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import Moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import * as material from 'material-colors';
import Api from '../../Api/Api';
import {
  Grid,
  Table,
  TableHeaderRow,
  SearchPanel,
  Toolbar,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  SearchState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
  SelectionState,
  IntegratedSelection
} from '@devexpress/dx-react-grid';
import Hidden from '@material-ui/core/Hidden';
import GridConstants from '../../Common/GridConstants';
import { Box, Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import DialogBlockListEdit from '../../BlockList/DialogBlockListEdit';
import PropTypes from 'prop-types';
import PagingContainer, { applyCustomButtonColor } from '../../Layout/PagingContainer';
import Avatar from 'react-avatar';
import LocaleFormatter from '../../Common/LocaleFormatter';
import * as CONST from '../../Common/constants';

const messages = defineMessages({
  table_motive: {
    id: 'AlertApplicantBlocked.table.motive',
    defaultMessage: 'Reason'
  },
  table_createdBy: {
    id: 'AlertApplicantBlocked.table.createdBy',
    defaultMessage: 'Created by'
  },
  table_dateCreated: {
    id: 'AlertApplicantBlocked.table.dateCreated',
    defaultMessage: 'Created at'
  },
  table_dateInactive: {
    id: 'AlertApplicantBlocked.table.dateInactive',
    defaultMessage: 'Inactivated at'
  },
  table_dateLimit: {
    id: 'AlertApplicantBlocked.table.dateLimit',
    defaultMessage: 'Date Limit'
  },
  table_undeterminedDateLimit: {
    id: 'AlertApplicantBlocked.table.undeterminedDateLimit',
    defaultMessage: 'Definitive'
  },
  table_status: {
    id: 'AlertApplicantBlocked.table.status',
    defaultMessage: 'Status'
  },
  table_statusActive: {
    id: 'AlertApplicantBlocked.table.statusActive',
    defaultMessage: 'Active'
  },
  table_statusInactive: {
    id: 'AlertApplicantBlocked.table.statusInactive',
    defaultMessage: 'Inactive'
  },
  alert: {
    id: 'AlertApplicantBlocked.alert_selected',
    defaultMessage: 'There are applicants with active restriction in this selection.'
  },
  alert_selectedBlocked: {
    id: 'AlertApplicantBlocked.alert_selectedBlocked',
    defaultMessage: 'Caution: There are candidates with active restrictions in this selection.'
  }
});

const useStyles = (theme) => ({
  alertError: {
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    padding: 0,
    '& .MuiAlert-icon': {
      color: theme.palette.common.white
    },
    '& .MuiAlert-message': {
      padding: '0',
      display: 'flex',
      alignItems: 'center'
    }
  },
  alertInfo: {
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    padding: 0,
    '& .MuiAlert-icon': {
      color: theme.palette.common.white
    },
    '& .MuiAlert-message': {
      padding: '0',
      display: 'flex',
      alignItems: 'center'
    }
  },
  accordion: {
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: 0
    }
  },
  accordionSummary: {
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    '&.Mui-expanded': {
      minHeight: 0
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0'
    }
    // '& .Mui-expanded': {
    //   minHeight: 0,
    //   margin: '12px 0'
    // },
  },
  accordionAlert: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white
  },
  accordionInfo: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white
  },
  alertText: {
    fontSize: '14px',
    color: theme.palette.common.white
  },
  detailsButtonLabel: {
    fontSize: '13px',
    fontWeight: 500
  },
  iconAlert: {
    color: theme.palette.common.white
  },
  iconInfo: {
    color: theme.palette.gray.darker
  },
  statusCircle: {
    borderRadius: '100%',
    width: '12px',
    height: '12px',
    marginRight: '8px'
  },
  inactiveStatus: {
    backgroundColor: theme.palette.gray.darkerLight
  },
  activeStatus: {
    backgroundColor: theme.palette.success.main
  },
  iconWarning: {
    color: theme.palette.gray.darker
  },
  errorIcon: {
    color: theme.palette.common.white
  }
});

class AlertApplicantBlocked extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseInfo: props.baseInfo,
      emailBlocklists: props.emailBlocklists ? props.emailBlocklists : {},
      docBlocklists: props.docBlocklists ? props.docBlocklists : {},
      blockLists: props.blockLists ? props.blockLists : {},
      readonly: props.readonly,
      pastBlock: props.pastBlock,
      isExpanded: false,
      historicMotive: [],
      hasBlockHistoric: false,
      editModal: false,
      firstLoaded: false,
      hideMotive: props.hideMotive,
      row: {},
      columns: [
        {
          name: 'motive_id',
          title: this.props.intl.formatMessage({ id: 'AlertApplicantBlocked.table.motive' }),
          getCellValue: (row) => (row.blockMotives ? row.blockMotives.motive : undefined)
        },
        {
          name: 'username',
          title: this.props.intl.formatMessage({ id: 'AlertApplicantBlocked.table.createdBy' }),
          getCellValue: (row) => {
            if (row.accountUser) {
              return {
                userCreated: row.accountUser,
                date_created: row.date_created
              };
            } else {
              return {
                date_created: row.date_created
              };
            }
          }
        },
        /* {
          name: 'date_created',
          title: this.props.intl.formatMessage({ id: 'AlertApplicantBlocked.table.dateCreated' })
        }, */
        {
          name: 'date_inactive',
          title: this.props.intl.formatMessage({ id: 'AlertApplicantBlocked.table.dateInactive' })
        },
        {
          name: 'date_limit',
          title: this.props.intl.formatMessage({ id: 'AlertApplicantBlocked.table.dateLimit' })
        },
        {
          name: 'active',
          title: this.props.intl.formatMessage({ id: 'AlertApplicantBlocked.table.status' }),
          getCellValue: (row) =>
            row.active
              ? this.props.intl.formatMessage({ id: 'AlertApplicantBlocked.table.statusActive' })
              : this.props.intl.formatMessage({ id: 'AlertApplicantBlocked.table.statusInactive' })
        }
      ],
      columnExtensions: [
        { columnName: 'motive_id' },
        { columnName: 'username', width: '200px', wordWrapEnabled: true },
        { columnName: 'date_created' },
        { columnName: 'date_inactive' },
        { columnName: 'date_limit' },
        { columnName: 'active' }
      ],
      linkCols: ['motive_id'],
      statusCols: ['active'],
      createdByCols: ['username'],
      sorting: [{ columnName: 'motive_id', direction: 'asc' }],
      sortingExtensions: [{ columnName: 'id', sortingEnabled: false }],
      currentPage: 0
    };
    this.currentPageChange = this.currentPageChange.bind(this);
  }

  currentPageChange(currentPage) {
    applyCustomButtonColor(currentPage);
    this.setState({ currentPage });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.emailBlocklists !== this.props.emailBlocklists) {
      this.setState({
        emailBlocklists: this.props.emailBlocklists
      });
    }
    if (prevProps.docBlocklists !== this.props.docBlocklists) {
      this.setState({
        docBlocklists: this.props.docBlocklists
      });
    }
    if (prevProps.blockLists !== this.props.blockLists) {
      this.setState({
        blockLists: this.props.blockLists
      });
    }
    if (prevProps.pastBlock !== this.props.pastBlock) {
      this.setState({
        pastBlock: this.props.pastBlock
      });
    }
    if (prevProps.baseInfo !== this.props.baseInfo) {
      this.setState(
        {
          baseInfo: this.props.baseInfo
        },
        () => {
          const { emailBlocklists, docBlocklists, blockLists } = this.state;
          const activeBlockValidation =
            (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
            (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0) ||
            (typeof blockLists === 'object' && Object.keys(blockLists).length > 0);

          if (!activeBlockValidation && this.state.firstLoaded === false) {
            this.applicantHasBlockHistoric();
          }
        }
      );
    }
  }

  componentDidMount() {
    if (this.state.readonly) {
      this.getAccountMotiveBlock();
    }

    const { emailBlocklists, docBlocklists, blockLists } = this.state;
    const activeBlockValidation =
      (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
      (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0) ||
      (typeof blockLists === 'object' && Object.keys(blockLists).length > 0);

    if (!activeBlockValidation && this.state.firstLoaded === false) {
      this.applicantHasBlockHistoric();
    }
  }

  applicantHasBlockHistoric() {
    const { email, docNumber } = this.state.baseInfo;
    const hasEmail = email !== '' && docNumber !== undefined && docNumber !== null;
    const hasDocNumber = docNumber !== '' && docNumber !== undefined && docNumber !== null;

    if (hasEmail || hasDocNumber) {
      const baseInfoEmail = email || '';
      const baseInfoDocNumber = docNumber || '';
      let userFilter = {
        fields: ['doc_number', 'email', 'id'],
        where: {
          deleted: false,
          and: [
            {
              or: [
                { email: baseInfoEmail !== '' && baseInfoEmail },
                { doc_number: baseInfoDocNumber !== '' && baseInfoDocNumber }
              ]
            }
          ]
        }
      };

      let api = new Api();
      api.get('Blocklists', userFilter, (status, historic) => {
        if (historic && historic.length > 0) {
          this.setState({
            hasBlockHistoric: true,
            firstLoaded: true
          });
        } else {
          this.setState({
            firstLoaded: true
          });
        }
      });
    }
  }

  getAccountMotiveBlock() {
    const { email, docNumber } = this.state.baseInfo;
    const emailBlocklists = email || '';
    const docBlocklists = docNumber || '';
    let userFilter = {
      fields: [
        'doc_number',
        'motive_id',
        'email',
        'user_id',
        'id',
        'date_created',
        'active',
        'name',
        'country',
        'doc_type',
        'date_limit',
        'date_inactive',
        'comments',
        'deleted'
      ],
      where: {
        deleted: false,
        and: [
          {
            or: [
              { email: emailBlocklists !== '' && emailBlocklists },
              { doc_number: docBlocklists !== '' && docBlocklists }
            ]
          }
        ]
      },
      include: ['accountUser', 'blockMotives']
    };

    let api = new Api();
    api.get('Blocklists', userFilter, (status, motive) => {
      if (motive && motive.length > 0) {
        const checkStatusMotive = motive.map((item) => {
          if (item.date_limit) {
            item.active = Moment(item.date_limit).isAfter(new Date()) ? item.active : false;
            item.date_inactive = Moment(item.date_limit).isAfter(new Date())
              ? item.date_inactive
              : item.date_limit;
          }

          return {
            ...item
          };
        });
        this.setState({
          historicMotive: checkStatusMotive
        });
      } else {
        return null;
      }
    });
  }

  callDialogEdit = (e, row) => {
    e.preventDefault();
    this.setState({
      row,
      editModal: true
    });
  };

  closeDialog = () => {
    this.setState({
      editModal: false
    });
  };

  displayBlockMotive = (infoApplicantBlock) => {
    if (infoApplicantBlock.motive) {
      return (
        <Typography>
          <FormattedMessage
            id="AlertApplicantBlocked.details"
            defaultMessage="Reason: {motive}, by {username} at {dateCreated} with deadline: {dateLimit}"
            values={{
              motive: infoApplicantBlock.motive,
              username: infoApplicantBlock.username,
              dateCreated: Moment(infoApplicantBlock.dateCreated).format('ll'),
              dateLimit: infoApplicantBlock.dateLimit
                ? Moment(infoApplicantBlock.dateLimit).format('ll')
                : this.props.intl.formatMessage({
                    id: 'AlertApplicantBlocked.table.undeterminedDateLimit'
                  })
            }}
          />
        </Typography>
      );
    } else {
      return null;
    }
  };

  displayStatus = (isBlocked) => {
    const { classes } = this.props;
    const hideMotive = this.state.hideMotive;
    return (
      <Alert
        classes={{ icon: isBlocked ? classes.errorIcon : classes.iconWarning }}
        severity={isBlocked ? 'error' : 'info'}
        style={{
          color: isBlocked ? CONST.APP_COLORS['white'] : CONST.APP_COLORS['gray-darker']
        }}
        className={isBlocked ? classes.alertError : classes.alertInfo}
      >
        <Typography className={classes.alertText}>
          {isBlocked ? (
            <b>
              <FormattedMessage
                id="AlertApplicantBlocked.statusActive"
                defaultMessage="Candidate has an Active Restriction"
              />
            </b>
          ) : hideMotive ? (
            <FormattedMessage
              id="AlertApplicantBlocked.statusInactiveMotiveHidden"
              defaultMessage="This candidate had an active restriction in the past"
            />
          ) : (
            <FormattedMessage
              id="AlertApplicantBlocked.statusInactive"
              defaultMessage="This candidate had a past active restriction, click to know more..."
            />
          )}
        </Typography>
      </Alert>
    );
  };

  infoBlock = () => {
    const { emailBlocklists, docBlocklists, blockLists } = this.state;

    // This function is called only when there's a block.
    // If there's no email block, there's docBlock.
    let getInfo =
      emailBlocklists && Object.keys(emailBlocklists).length > 0 ? emailBlocklists : docBlocklists;

    if (!getInfo || Object.keys(getInfo).length === 0) {
      getInfo = blockLists;
    }

    const dateCreated = getInfo.date_created;
    const username = getInfo.accountUser.username;
    const motive = getInfo.blockMotives.motive;
    const dateLimit = getInfo.date_limit;

    return {
      dateCreated,
      username,
      motive,
      dateLimit
    };
  };

  render() {
    const { classes } = this.props;
    const { emailBlocklists, docBlocklists, blockLists } = this.state;
    const getRowId = (row) => row.id;

    const Cell = (props) => {
      const { column, value } = props;

      if (column.name === 'date_created' || column.name === 'date_inactive') {
        return <Table.Cell {...props} value={value === null ? null : Moment(value).format('ll')} />;
      } else if (column.name === 'date_limit') {
        return (
          <Table.Cell
            {...props}
            value={
              value === null
                ? this.props.intl.formatMessage({
                    id: 'AlertApplicantBlocked.table.undeterminedDateLimit'
                  })
                : Moment(value).format('lll')
            }
          />
        );
      } else {
        return <Table.Cell {...props} />;
      }
    };

    const LinkFormatter = ({ value, row }) => {
      let blockMotive = value;

      return (
        <a href="#" onClick={(e) => this.callDialogEdit(e, row)}>
          {blockMotive}
        </a>
      );
    };

    const LinkTypeProvider = (props) => (
      <DataTypeProvider formatterComponent={LinkFormatter} {...props} />
    );

    const StatusFormatter = ({ value, row }) => {
      const active = row.active;

      return (
        <Box display="flex" alignItems="center">
          <Box
            className={`${classes.statusCircle} ${
              active ? classes.activeStatus : classes.inactiveStatus
            }`}
          ></Box>
          <span>{value}</span>
        </Box>
      );
    };

    const StatusTypeProvider = (props) => (
      <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
    );

    const CreatedByFormatter = ({ value, row }) => {
      if (value.userCreated) {
        return (
          <Box display="flex">
            <Box mr={1}>
              <Avatar
                name={value.userCreated.username}
                src={value.userCreated.avatar_50_path}
                size="16px"
                round={true}
              />
            </Box>
            <Box display="flex" flexDirection="column">
              <span>{value.userCreated.username}</span>
              <LocaleFormatter dateToFormat={Moment(value.date_created).format('ll')} />
            </Box>
          </Box>
        );
      } else {
        return (
          <Box>
            <LocaleFormatter dateToFormat={Moment(value.date_created).format('ll')} />
          </Box>
        );
      }
    };

    const CreatedByProvider = (props) => (
      <DataTypeProvider formatterComponent={CreatedByFormatter} {...props} />
    );

    const { isExpanded } = this.state;
    const hideMotive = this.state.hideMotive;
    const readonly = this.state.readonly;

    const expanded = (isExpanded || readonly) && !hideMotive;

    const displayAlert = () => {
      const activeBlockValidation =
        (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
        (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0) ||
        (typeof blockLists === 'object' && Object.keys(blockLists).length > 0);

      if (activeBlockValidation || this.state.hasBlockHistoric) {
        const infoApplicantBlock = activeBlockValidation ? this.infoBlock() : null;
        return (
          <>
            <Accordion
              expanded={expanded}
              className={classes.accordion}
              onChange={(event, expanded) => {
                if (!readonly && !hideMotive) {
                  this.setState({ isExpanded: expanded }, () => {
                    if (expanded) {
                      this.getAccountMotiveBlock();
                    }
                  });
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  hideMotive || readonly ? null : (
                    <ExpandMoreIcon className={classes.iconAlert} fontSize="small" />
                  )
                }
                id="blocked-details-header"
                className={`${classes.accordionSummary} ${
                  activeBlockValidation ? classes.accordionAlert : classes.accordionInfo
                }`}
              >
                <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
                  <Box>{this.displayStatus(activeBlockValidation)}</Box>
                  {/* <Box flex="1">
                    {activeBlockValidation && this.displayBlockMotive(infoApplicantBlock)}
                  </Box> */}
                  {hideMotive || readonly ? null : (
                    <Box>
                      <Typography className={classes.detailsButtonLabel}>
                        <FormattedMessage
                          id="AlertApplicantBlocked.label_viewDetails"
                          defaultMessage="View details"
                        />
                      </Typography>
                    </Box>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Paper>
                  <Grid
                    rows={this.state.historicMotive}
                    columns={this.state.columns}
                    getRowId={getRowId}
                  >
                    <LinkTypeProvider for={this.state.linkCols} />
                    <StatusTypeProvider for={this.state.statusCols} />
                    <CreatedByProvider for={this.state.createdByCols} />
                    <SortingState
                      defaultSorting={this.state.sorting}
                      columnExtensions={this.state.sortingExtensions}
                    />
                    <IntegratedSorting />
                    <SearchState />
                    <IntegratedFiltering />
                    <PagingState
                      currentPage={this.state.currentPage}
                      onCurrentPageChange={this.currentPageChange}
                      defaultCurrentPage={0}
                      pageSize={10}
                    />
                    <IntegratedPaging />
                    <Table
                      cellComponent={Cell}
                      columnExtensions={this.state.columnExtensions}
                      messages={GridConstants.getGridMessage(this.props.intl, 'tableMessages')}
                    />
                    <TableHeaderRow
                      showSortingControls
                      messages={GridConstants.getGridMessage(this.props.intl, 'sortMessages')}
                    />
                    <Toolbar />
                    <SearchPanel
                      messages={GridConstants.getGridMessage(this.props.intl, 'searchMessages')}
                    />
                    <PagingPanel
                      containerComponent={PagingContainer}
                      messages={GridConstants.getGridMessage(
                        this.props.intl,
                        'pagingPanelMessages'
                      )}
                    />
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>

            {this.state.editModal && (
              <DialogBlockListEdit
                editModal={this.state.editModal}
                closeDialog={this.closeDialog}
                row={{ ...this.state.row, onlyRead: true }}
              />
            )}
          </>
        );
      } else {
        return null;
      }
    };
    return <>{displayAlert()}</>;
  }
}

AlertApplicantBlocked.propTypes = {
  emailBlocklists: PropTypes.object.isRequired,
  docBlocklists: PropTypes.object.isRequired
};

export default injectIntl(withStyles(useStyles)(AlertApplicantBlocked));
