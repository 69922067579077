import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import * as CONST from '../../Common/constants';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InfoDistanceToJob from '../Card/InfoDistanceToJob';
import AddressController from '../../Common/AddressController';
import InfoAge from '../Card/InfoAge';
import Moment from 'moment';
import UserProfile from '../../Common/UserProfile';
import IconEmail from '../Card/IconEmail';
import IconPhone from '../Card/IconPhone';
import LocaleFormatter from '../../Common/LocaleFormatter';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import IconID from '../Card/IconID';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const messages = defineMessages({});

// https://stackoverflow.com/questions/1199352/smart-way-to-truncate-long-strings
function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '…' : str;
}

const useStyles = (theme) => ({
  root: {
    width: '100%'
    //padding: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(2)
  },
  cardRoot: {
    border: '1px solid ' + theme.palette.gray.main,
    marginBottom: theme.spacing(1)
  }
});

class DetailSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: props.info
      // Internal State
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.info) !== JSON.stringify(this.props.info)) {
      this.setState({
        info: this.props.info
      });
    }
  }

  render() {
    const { classes } = this.props;

    const info = this.state.info;
    //console.log(info);

    const displayAbout = () => {
      let about;

      if (info.cv_text_areas && info.cv_text_areas.resume && info.cv_text_areas.resume.text) {
        about = truncate(info.cv_text_areas.resume.text, 250);
      }
      if (about) {
        return (
          <Grid item sm={12} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.about" defaultMessage="About" />
            </Typography>
            <Typography variant="body1" gutterBottom>
              {about}
            </Typography>
            <Divider light />
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayJob = () => {
      if (info.job && info.job.code && info.job.title) {
        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.job" defaultMessage="Job Info" />
            </Typography>
            [{info.job.code}] {info.job.title}
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayDateCreated = () => {
      if (info.date_created) {
        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.dateCreated" defaultMessage="Date created" />
            </Typography>
            <LocaleFormatter dateToFormat={Moment(info.date_created).format('llll')} />
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayOrigin = () => {
      if (info.origin) {
        let text = '';
        switch (info.origin) {
          case CONST.ORIGIN_APPLY:
            text = this.props.intl.formatMessage({
              id: 'LabelOrigin.originForm'
            });
            break;
          case CONST.ORIGIN_MAILIN:
            text = this.props.intl.formatMessage({
              id: 'LabelOrigin.originMail'
            });
            break;
          case CONST.ORIGIN_RECRUITER:
            text = this.props.intl.formatMessage({
              id: 'LabelOrigin.originRecruiter'
            });
            break;
          case CONST.ORIGIN_IMPORT:
            text = this.props.intl.formatMessage({
              id: 'LabelOrigin.originImport'
            });
            break;
          case CONST.ORIGIN_HUNT:
            text = this.props.intl.formatMessage({
              id: 'LabelOrigin.originHunting'
            });
            break;
          case CONST.ORIGIN_SCRAP:
            text = this.props.intl.formatMessage({
              id: 'LabelOrigin.originScrap'
            });
            break;
          case CONST.ORIGIN_MOVE:
            text = this.props.intl.formatMessage({
              id: 'LabelOrigin.originMove'
            });
            break;
          case CONST.ORIGIN_COPY:
            text = this.props.intl.formatMessage({
              id: 'LabelOrigin.originCopy'
            });
            break;
          case CONST.ORIGIN_CROSSRANK:
            text = this.props.intl.formatMessage({
              id: 'LabelOrigin.originCrossrank'
            });
            break;
          case CONST.ORIGIN_SEARCH:
            text = this.props.intl.formatMessage({
              id: 'ChipOrigin.originSearch'
            });
            break;
          default:
            text = this.props.intl.formatMessage({
              id: 'LabelOrigin.originUnknown'
            });
        }
        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.origin" defaultMessage="Origin" />
            </Typography>
            {text}
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayEmail = () => {
      if (
        info.email &&
        !info.email.startsWith('private_') &&
        !info.email.startsWith('huntln_') &&
        !info.email.startsWith('profile:')
      ) {
        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.email" defaultMessage="E-mail" />
            </Typography>
            <Box display="flex" alignItems="center" width="100%">
              <Box mr={1}>
                <IconEmail email={info.email} verified={info.verified_email} size={20} />
              </Box>
              <Box>{info.email}</Box>
            </Box>
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayPhoneMobile = () => {
      if (info.phone_mobile) {
        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.phoneMobile" defaultMessage="Mobile phone" />
            </Typography>
            <Box display="flex" alignItems="center" width="100%">
              <Box mr={1}>
                <IconPhone phone={info.phone_mobile} size={18} />
              </Box>
              <Box>{info.phone_mobile}</Box>
            </Box>
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayAddress = () => {
      if (info.address) {
        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.address" defaultMessage="Location" />
            </Typography>
            {AddressController.addrApplicantFull(info.address, info.zip)}
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayDistanceJob = () => {
      if (info.distance_job) {
        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage
                id="DetailSummary.distanceToJob"
                defaultMessage="Distance to Position"
              />
            </Typography>
            <InfoDistanceToJob distance_job={info.distance_job} />
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayAge = () => {
      if (info.age) {
        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.age" defaultMessage="Age" />
            </Typography>
            <InfoAge age={info.age} />
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayGender = () => {
      if (info.gender) {
        let text = '';
        switch (info.gender) {
          case 'M':
            text = this.props.intl.formatMessage({ id: 'InfoGender.male' });
            break;
          case 'F':
            text = this.props.intl.formatMessage({ id: 'InfoGender.female' });
            break;
          case 'NB':
            text = this.props.intl.formatMessage({ id: 'InfoGender.nonBinary' });
            break;
          case 'O':
            text = this.props.intl.formatMessage({ id: 'InfoGender.other' });
            break;
          case 'OPTOUT':
            text = this.props.intl.formatMessage({ id: 'InfoGender.optOut' });
            break;
        }

        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.gender" defaultMessage="Gender" />
            </Typography>
            {text}
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayPwd = () => {
      if (info.pwd && info.pwd !== 'no') {
        let text = '';
        switch (info.pwd) {
          case 'physically_impaired':
            text = this.props.intl.formatMessage({
              id: 'InfoPwd.physically_impaired'
            });
            break;
          case 'hearing_impaired':
            text = this.props.intl.formatMessage({
              id: 'InfoPwd.hearing_impaired'
            });
            break;
          case 'visually_impaired':
            text = this.props.intl.formatMessage({
              id: 'InfoPwd.visually_impaired'
            });
            break;
          case 'mentally_impaired':
            text = this.props.intl.formatMessage({
              id: 'InfoPwd.mentally_impaired'
            });
            break;
          case 'multiple_disabilities':
            text = this.props.intl.formatMessage({
              id: 'InfoPwd.multiple_disabilities'
            });
            break;
          case 'OPTOUT':
            text = this.props.intl.formatMessage({
              id: 'InfoPwd.opt_out'
            });
            break;
        }

        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.pwd" defaultMessage="PwD" />
            </Typography>
            {text}
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayInternal = () => {
      if (info.internal) {
        const text = this.props.intl.formatMessage({
          id: 'IconInternal.employee'
        });

        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.internal" defaultMessage="Internal" />
            </Typography>
            <Typography
              style={{
                color: CONST.APP_COLORS['danger-dark'],
                fontWeight: 'bold'
              }}
            >
              {text}
            </Typography>
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayEmployeeID = () => {
      if (info.employee_id) {
        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.employeeID" defaultMessage="Employee ID" />
            </Typography>
            <Typography
              style={{
                color: CONST.APP_COLORS['danger-dark'],
                fontWeight: 'bold'
              }}
            >
              {info.employee_id}
            </Typography>
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayPso = () => {
      if (info.pso && info.pso !== 'no' && (UserProfile.isAdmin() || UserProfile.isDiversity())) {
        let text = '';
        switch (info.pso) {
          case 'A':
            text = this.props.intl.formatMessage({
              id: 'InfoPso.heterosexual'
            });
            break;
          case 'B':
            text = this.props.intl.formatMessage({
              id: 'InfoPso.lesbian'
            });
            break;
          case 'C':
            text = this.props.intl.formatMessage({
              id: 'InfoPso.gay'
            });
            break;
          case 'D':
            text = this.props.intl.formatMessage({
              id: 'InfoPso.bisexual'
            });
            break;
          case 'E':
            text = this.props.intl.formatMessage({
              id: 'InfoPso.transgender'
            });
            break;
          case 'F':
            text = this.props.intl.formatMessage({
              id: 'InfoPso.queer'
            });
            break;
          case 'G':
            text = this.props.intl.formatMessage({
              id: 'InfoPso.intersexuals'
            });
            break;
          case 'H':
            text = this.props.intl.formatMessage({
              id: 'InfoPso.asexuals'
            });
            break;
          case 'I':
            text = this.props.intl.formatMessage({
              id: 'InfoPso.pansexual'
            });
            break;
          case 'J':
            text = this.props.intl.formatMessage({
              id: 'InfoPso.other'
            });
            break;
          case 'OPTOUT':
            text = this.props.intl.formatMessage({
              id: 'InfoPso.opt_out'
            });
            break;
        }

        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.pso" defaultMessage="Sexual orientation" />
            </Typography>
            {text}
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayPwr = () => {
      if (info.pwr && info.pwr !== 'no' && (UserProfile.isAdmin() || UserProfile.isDiversity())) {
        let text = '';
        switch (info.pwr) {
          case 'A':
            text = this.props.intl.formatMessage({
              id: 'InfoPwr.white'
            });
            break;
          case 'B':
            text = this.props.intl.formatMessage({
              id: 'InfoPwr.indigenous'
            });
            break;
          case 'C':
            text = this.props.intl.formatMessage({
              id: 'InfoPwr.yellow'
            });
            break;
          case 'D':
            text = this.props.intl.formatMessage({
              id: 'InfoPwr.black'
            });
            break;
          case 'E':
            text = this.props.intl.formatMessage({
              id: 'InfoPwr.brown'
            });
            break;
          case 'F':
            text = this.props.intl.formatMessage({
              id: 'InfoPwr.other'
            });
            break;
          case 'OPTOUT':
            text = this.props.intl.formatMessage({
              id: 'InfoPwr.opt_out'
            });
            break;
        }

        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              <FormattedMessage id="DetailSummary.pwr" defaultMessage="Race / Ethnicity" />
            </Typography>
            {text}
          </Grid>
        );
      } else {
        return null;
      }
    };

    const displayIDDocument = () => {
      let docName = '';
      let containerId = 'displayApplicantID';
      const identityDocs = this.props.identityDocs.identityDocs;

      if (identityDocs && identityDocs.length > 0) {
        const applicantDoc = identityDocs.findIndex((doc) => doc.code === info.doc_type);
        if (applicantDoc > -1) {
          docName = identityDocs[applicantDoc].name;
          const docMask = identityDocs[applicantDoc].id_mask;
          AddressController.getMaskedID(containerId, docMask);
        } else {
          docName = this.props.intl.formatMessage({ id: 'IconID.defaultIDLabel' });
        }
      }

      if (info.doc_number) {
        return (
          <Grid item sm={6} className={classes.item}>
            <Typography variant="overline" component="h2">
              {docName}
            </Typography>
            <Box display="flex" alignItems="center" width="100%">
              <Box mr={1}>
                <IconID doc_number={info.doc_number} doc_type={info.doc_type} />
              </Box>
              <Box id={containerId}>{info.doc_number}</Box>
            </Box>
          </Grid>
        );
      } else {
        return null;
      }
    };

    return (
      <div className={classes.root}>
        <Card
          classes={{
            root: classes.cardRoot
          }}
        >
          <CardContent>
            <Grid container spacing={2} padding={2}>
              {displayAbout()}
              {displayJob()}
              {displayDateCreated()}
              {displayOrigin()}
              {displayInternal()}
              {displayEmployeeID()}
              {displayEmail()}
              {displayIDDocument()}
              {displayPhoneMobile()}
              {displayAddress()}
              {displayDistanceJob()}
              {displayAge()}
              {displayGender()}
              {displayPwd()}
              {displayPso()}
              {displayPwr()}
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

DetailSummary.propTypes = {
  identityDocs: PropTypes.object
};

const mapStateToProps = (state) => ({
  identityDocs: state.identityDocs
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(DetailSummary)));
