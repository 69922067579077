import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

const messages = defineMessages({
  fit_education: {
    id: 'Hashtags.fit_education',
    defaultMessage: 'Education'
  },
  fit_experience: {
    id: 'Hashtags.fit_experience',
    defaultMessage: 'Experience'
  },
  fit_language: {
    id: 'Hashtags.fit_language',
    defaultMessage: 'Language'
  },
  fit_skills: {
    id: 'Hashtags.fit_skills',
    defaultMessage: 'Skills'
  },
  fit_industry: {
    id: 'Hashtags.fit_industry',
    defaultMessage: 'Industry'
  },
  fit_personality: {
    id: 'Hashtags.fit_personality',
    defaultMessage: 'Personality'
  }
});

const useStyles = () => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '0.5rem',
    width: '100%'
  }
});

const StyledChip = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main,
    fontWeight: 700,
    fontSize: '14px',
    padding: '0.5rem 0'
  }
}))(Chip);

class Hashtags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hashtags: props.hashtags ? props.hashtags : []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hashtags !== this.props.hashtags) {
      this.setState({
        hashtags: this.props.hashtags
      });
    }
  }

  render() {
    const { classes } = this.props;
    const hashtags = this.state.hashtags;

    if (hashtags && hashtags.length > 0) {
      return (
        <Box className={classes.mainContainer}>
          {this.state.hashtags.map((hashtag) => {
            if (hashtag !== 'name_mismatch') {
              return (
                <Box>
                  <StyledChip
                    label={this.props.intl.formatMessage({ id: 'Hashtags.' + hashtag })}
                  />
                </Box>
              );
            } else {
              return null;
            }
          })}
        </Box>
      );
    } else {
      return null;
    }
  }
}
export default injectIntl(withStyles(useStyles)(Hashtags));
