import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import * as CONST from '../Common/constants';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Api from '../Api/Api';
import Avatar from 'react-avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Slider from '@material-ui/core/Slider';
import Moment from 'moment';
import MomentTZ from 'moment-timezone';
import Timezones from '../Common/timezones';
import Select from 'react-select';
import { MobileDateTimePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import 'moment/locale/pt-br'; // FIXME locale
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import DeleteIcon from '@material-ui/icons/Delete';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import MuiAvatar from '@material-ui/core/Avatar';
import RootRef from '@material-ui/core/RootRef';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Alert from '@material-ui/lab/Alert';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SearchIcon from '@material-ui/icons/Search';
import UserProfile from '../Common/UserProfile';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import StageController from './StageController';
import RadioGroup from '@material-ui/core/RadioGroup';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined';
import Typography from '@material-ui/core/Typography';
import ModalAlertBlock from './ModalAlertBlock';

const MAX_QUESTIONS = 10; // Questions
const MAX_DURATION = 900; // 15 Minutes
const DEFAULT_DURATION = 120;

const messages = defineMessages({
  warningDateLimit: {
    id: 'ModalVideoInterviewOptions.warningDateLimit',
    defaultMessage: 'Please define the date limit'
  },
  searchQuestion: {
    id: 'ModalVideoInterviewOptions.searchQuestion',
    defaultMessage: 'Search question...'
  },
  addQuestion: {
    id: 'ModalVideoInterviewOptions.addQuestion',
    defaultMessage: 'Add question...'
  },
  maxDurationMinutes: {
    id: 'ModalVideoInterviewOptions.maxDurationMinutes',
    defaultMessage: 'min'
  },
  maxDurationSeconds: {
    id: 'ModalVideoInterviewOptions.maxDurationSeconds',
    defaultMessage: 'seconds'
  },
  typeAudio: {
    id: 'ModalVideoInterviewOptions.typeAudio',
    defaultMessage: 'Audio'
  },
  typeVideo: {
    id: 'ModalVideoInterviewOptions.typeVideo',
    defaultMessage: 'Video'
  }
});

// MUST BE OUTSIDE OF COMPONENT
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: CONST.APP_COLORS['gray-light']
  })
});

const getListStyle = (isDraggingOver) => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  root: {
    margin: 'auto'
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  card: {
    boxShadow: `0px 3px 6px 0px ${theme.palette.gray.darkerMedium}0A`
  },
  defaultList: {
    height: '80vh',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  interviewList: {
    height: '21vh',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  interviewItem: {
    cursor: 'pointer !important'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  rootColor: {
    color: theme.palette.primary.main,
    marginRight: '4px'
  },
  iconWarning: {
    color: `${theme.palette.warning.main} !important`
  },
  iconError: {
    color: `${theme.palette.error.dark} !important`
  }
});

class ModalVideoInterviewOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      nextStageObj: props.nextStageObj,
      selected_ids: props.selected_ids,
      selected_objs: props.selected_objs,
      submiting: props.submiting,
      // Internal state
      allDefaultQuestions: [],
      defaultQuestions: [],
      interviewQuestions: [],
      checked: [],
      drawer: false,
      search: '',
      edit_index: -1,
      edit_id: undefined,
      edit_question: '',
      edit_max_duration: DEFAULT_DURATION,
      dialog: false,
      limitType: 'DAYS', // default
      disabledDaysOpt: false, // default
      disabledDateOpt: true, // default
      dateLimit: Moment().add(2, 'days').set({ hour: 23, minute: 59, second: 0, millisecond: 0 }),
      daysLimit: 2,
      timezone: MomentTZ.tz.guess(), // FIXME locale
      timezoneList: Timezones,
      interviewType: '',
      interviewTypesList: [
        {
          value: 'AUDIO',
          label: this.props.intl.formatMessage({ id: 'ModalVideoInterviewOptions.typeAudio' })
        },
        {
          value: 'VIDEO',
          label: this.props.intl.formatMessage({ id: 'ModalVideoInterviewOptions.typeVideo' })
        }
      ],
      isBlocked: false,
      modalOpen: false
    };
    this.setLimitType = this.setLimitType.bind(this);
    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(
        {
          open: this.props.open
        },
        () => {
          this.warningApplicantBlocked();
        }
      );
    }
    if (prevProps.nextStageObj !== this.props.nextStageObj) {
      this.setState({
        nextStageObj: this.props.nextStageObj
      });
    }
    if (prevProps.selected_ids !== this.props.selected_ids) {
      this.setState({
        selected_ids: this.props.selected_ids
      });
    }
    if (prevProps.selected_objs !== this.props.selected_objs) {
      this.setState({
        selected_objs: this.props.selected_objs
      });
    }
    if (prevProps.submiting !== this.props.submiting) {
      this.setState({
        submiting: this.props.submiting
      });
    }
  }

  warningApplicantBlocked = () => {
    const isAnyBlocked = this.state.selected_objs.some((applicant) => {
      const { emailBlocklists, docBlocklists } = applicant;
      const activeBlockValidation =
        (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
        (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0);
      return activeBlockValidation === true;
    });

    this.setState({
      isBlocked: isAnyBlocked
    });
  };

  loadApplicants() {
    const selected_objs = this.state.selected_objs;

    if (!selected_objs || selected_objs.length === 0) {
      let api = new Api();
      let filter = {
        fields: ['id', 'name', 'email', 'avatar_50_path'],
        where: {
          id: {
            inq: this.state.selected_ids
          }
        },
        order: ['name']
      };
      api.get('Applicants', filter, (status, data) => {
        //console.log(data);
        this.setState({
          selected_objs: data
        });
      });
    }
  }

  loadDefaultQuestions() {
    let api = new Api();
    let filter = {
      where: {
        lang: this.props.user.language
      },
      order: ['question ASC']
    };
    api.get('DefaultQuestions', filter, (status, data) => {
      //console.log(data);
      this.setState({
        allDefaultQuestions: data,
        defaultQuestions: data
      });
    });
  }

  handleOpen = () => {
    this.loadDefaultQuestions();
    this.loadApplicants();
  };

  handleClose = () => {
    this.setState(
      {
        open: false,
        isBlocked: false,
        modalOpen: false
      },
      () => {
        if (this.props.handlerClose) {
          this.props.handlerClose();
        }
      }
    );
  };

  handleModalOpen = () => {
    this.setState({
      open: false,
      modalOpen: true
    });
  };

  handleUpdate = (options) => {
    if (this.props.handlerPost) {
      this.props.handlerPost({
        ids: this.state.selected_ids,
        options: options
      });
    }
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  };

  handleCheckedRight = () => {
    const defaultChecked = intersection(this.state.checked, this.state.defaultQuestions);
    const defaultQuestions = this.state.defaultQuestions;
    const interviewQuestions = this.state.interviewQuestions;
    const checked = this.state.checked;

    this.setState({
      interviewQuestions: interviewQuestions.concat(defaultChecked),
      defaultQuestions: not(defaultQuestions, defaultChecked),
      checked: not(checked, defaultChecked)
    });
  };

  handleCheckedLeft = () => {
    const interviewChecked = intersection(this.state.checked, this.state.interviewQuestions);
    const defaultQuestions = this.state.defaultQuestions;
    const checked = this.state.checked;

    this.setState({
      defaultQuestions: defaultQuestions.concat(interviewChecked),
      interviewQuestions: not(this.state.interviewQuestions, interviewChecked),
      checked: not(checked, interviewChecked)
    });
  };

  handleDateLimitChange(value) {
    console.log(value.toDate());
    this.setState({
      dateLimit: value.toDate()
    });
  }

  handleDaysLimitChange(e, days) {
    //console.log(days);
    let newDate = Moment()
      .add(days, 'days')
      .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
      .toDate();

    this.setState({
      daysLimit: days,
      dateLimit: newDate
    });
  }

  handleTimezoneChange = (timezone) => {
    let value = '';
    if (timezone) {
      value = timezone.value;
    }
    this.setState({ timezone: value });
  };

  handleChangeSelectType = (e) => {
    switch (e.target.value) {
      case 'VIDEO':
        this.setState({
          interviewType: e.target.value
        });
        break;
      case 'AUDIO':
        this.setState({
          interviewType: e.target.value
        });
        break;
    }
  };

  setLimitType(e) {
    switch (e.target.value) {
      case 'DAYS':
        let newDate = Moment()
          .add(this.state.daysLimit, 'days')
          .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
          .toDate();

        this.setState({
          limitType: e.target.value,
          disabledDaysOpt: false,
          disabledDateOpt: true,
          dateLimit: newDate
        });
        break;
      case 'DATE':
        this.setState({
          limitType: e.target.value,
          disabledDaysOpt: true,
          disabledDateOpt: false
        });
        break;
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const appVersion = this.props.accountInfo.accountInfo.app_version;

    if (!this.state.dateLimit) {
      window.swal({
        type: 'warning',
        title: '',
        text: this.props.intl.formatMessage({
          id: 'ModalVideoInterviewOptions.warningDateLimit'
        }),
        closeOnConfirm: true
      });
    } else {
      const ordered_questions = this.state.interviewQuestions.map((question, i) => {
        return {
          priority: i + 1,
          question: question.question,
          max_duration: question.max_duration,
          user_id: UserProfile.getId()
        };
      });

      const options = {
        date_limit: Moment(this.state.dateLimit).toDate(),
        timezone: this.state.timezone,
        type:
          this.state.interviewType && appVersion && appVersion === 'RECRUTAI'
            ? this.state.interviewType
            : 'VIDEO',
        questions: ordered_questions
      };
      //console.log(options);

      this.handleUpdate(options);
    }
    this.handleClose();
  };

  toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({
      drawer: open
    });
  };

  handleSearch = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const search = value.toLowerCase().trim();

    this.setState((state) => {
      const questions = this.state.allDefaultQuestions.filter((item) =>
        item.question.toLowerCase().includes(search)
      );
      return {
        defaultQuestions: questions,
        [name]: value
      };
    });
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const questions = reorder(
      this.state.interviewQuestions,
      result.source.index,
      result.destination.index
    );

    this.setState({
      interviewQuestions: questions
    });
  }

  openDialog = (index) => {
    let edit_index;
    let edit_id;
    let edit_question = '';
    let edit_max_duration = DEFAULT_DURATION;

    if (index > -1) {
      const interviewQuestions = this.state.interviewQuestions;

      edit_index = index;
      edit_id = interviewQuestions[index].id;
      edit_question = interviewQuestions[index].question;
      edit_max_duration = interviewQuestions[index].max_duration;
    }

    this.setState({
      dialog: true,
      edit_index: edit_index,
      edit_id: edit_id,
      edit_question: edit_question,
      edit_max_duration: edit_max_duration
    });
  };

  closeDialog = () => {
    this.setState({
      dialog: false,
      edit_index: -1,
      edit_id: undefined,
      edit_question: '',
      edit_max_duration: DEFAULT_DURATION
    });
  };

  saveQuestion = () => {
    const interviewQuestions = this.state.interviewQuestions;
    const edit_index = this.state.edit_index;
    let edit = false;

    if (edit_index > -1) {
      // Editing
      interviewQuestions[edit_index].question = this.state.edit_question;
      interviewQuestions[edit_index].max_duration = this.state.edit_max_duration;
      edit = true;
    } else {
      // New
      interviewQuestions.push({
        id: undefined,
        question: this.state.edit_question,
        max_duration: this.state.edit_max_duration
      });
    }

    this.setState(
      {
        interviewQuestions: interviewQuestions
      },
      () => {
        if (edit === true) {
          this.closeDialog();
        } else {
          this.openDialog();
        }
      }
    );
  };

  deletedQuestion = (index) => {
    const interviewQuestions = this.state.interviewQuestions;
    const question = interviewQuestions[index];

    if (question.id) {
      // Came from a default question

      this.setState(
        {
          checked: [question] // Simulating a check
        },
        () => {
          this.handleCheckedLeft();
        }
      );
    } else {
      // User created question
      interviewQuestions.splice(index, 1);

      this.setState({
        interviewQuestions: interviewQuestions
      });
    }
  };

  render() {
    const { classes } = this.props;
    const appVersion = this.props.accountInfo.accountInfo.app_version;

    const interviewQuestions = this.state.interviewQuestions;

    const defaultChecked = intersection(this.state.checked, this.state.defaultQuestions);
    const interviewChecked = intersection(this.state.checked, interviewQuestions);

    const totalDuration =
      interviewQuestions.length > 0
        ? interviewQuestions.map((item) => item.max_duration).reduce((prev, next) => prev + next)
        : 0;

    const listApplicants = this.state.selected_objs.map((applicant, i) => {
      let fullPath;
      if (applicant.avatar_50_path) {
        fullPath = 'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + applicant.avatar_50_path;
      }

      return (
        <ListItem key={applicant.id}>
          <ListItemAvatar>
            <Avatar
              name={applicant.name}
              email={applicant.email}
              src={fullPath}
              maxInitials={2}
              color={CONST.APP_COLORS['primary-light']}
              size="30px"
              round={true}
            />
          </ListItemAvatar>
          <ListItemText id={i} primary={applicant.name} />
        </ListItem>
      );
    });

    const displayDaysLimitDate = () => {
      if (this.state.daysLimit > 0 && this.state.disabledDaysOpt === false) {
        let showDate = Moment(this.state.dateLimit).calendar();

        return (
          <p className="text-center mt0">
            <small>
              <FormattedHTMLMessage
                id="ModalVideoInterviewOptions.newLimitDate"
                defaultMessage="Limit will be at the end of: <b>{newDate}</b>"
                values={{ newDate: showDate }}
              />
            </small>
          </p>
        );
      } else {
        return <p></p>;
      }
    };

    const displayNoCommunicationMsg = () => {
      const nextStageObj = this.state.nextStageObj;

      if (nextStageObj && !nextStageObj.sendEmail) {
        return (
          <Row>
            <Col md={12}>
              <Alert
                severity="warning"
                classes={{ icon: classes.iconWarning }}
                style={{
                  backgroundColor: CONST.APP_COLORS['warning-light'],
                  color: CONST.APP_COLORS['warning']
                }}
              >
                <FormattedMessage id="ModalCustomTestOptions.warningNoCommunication" />
              </Alert>
            </Col>
          </Row>
        );
      } else {
        return null;
      }
    };

    const numberOfChecked = (items) => intersection(this.state.checked, items).length;

    const formattedDuration = (seconds) => {
      let duration = Moment.utc(seconds * 1000).format('mm:ss', { trim: true });
      duration =
        duration +
        ' ' +
        this.props.intl.formatMessage({ id: 'ModalVideoInterviewOptions.maxDurationMinutes' });
      if (seconds < 60) {
        duration = Moment.utc(seconds * 1000).format('ss', { trim: true });
        duration =
          duration +
          ' ' +
          this.props.intl.formatMessage({ id: 'ModalVideoInterviewOptions.maxDurationSeconds' });
      }
      return duration;
    };

    const displayMaxAlert = () => {
      if (this.state.interviewQuestions.length > MAX_QUESTIONS || totalDuration > MAX_DURATION) {
        return (
          <Alert
            severity="error"
            classes={{ icon: classes.iconError }}
            style={{
              backgroundColor: CONST.APP_COLORS['danger-light'],
              color: CONST.APP_COLORS['danger-dark']
            }}
          >
            <p>
              <FormattedMessage
                id="ModalVideoInterviewOptions.warning_maxQuestions"
                defaultMessage="Recorded interview sessions can have up to {totQuestions} questions or {totMinutes} minutes in total"
                values={{
                  totQuestions: MAX_QUESTIONS,
                  totMinutes: MAX_DURATION / 60 // Seconds to Minutes
                }}
              />
            </p>
          </Alert>
        );
      } else {
        return null;
      }
    };

    const defaultList = (items) => (
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={
            <TextField
              name="search"
              value={this.state.search}
              onChange={(e) => this.handleSearch(e)}
              fullWidth
              placeholder={this.props.intl.formatMessage({
                id: 'ModalVideoInterviewOptions.searchQuestion'
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          }
          subheader={`${numberOfChecked(items)} / ${items.length}`}
        />
        <Divider />
        <List className={classes.defaultList} dense component="div" role="list">
          {items.map((question) => {
            const labelId = `transfer-list-all-item-${question.id}-label`;

            return (
              <ListItem key={question} role="listitem" button onClick={this.handleToggle(question)}>
                <ListItemIcon>
                  <Checkbox
                    checked={this.state.checked.indexOf(question) !== -1}
                    tabIndex={-1}
                    color="primary"
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={question.question}
                  secondary={question.max_duration}
                />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );

    const interviewList = (items) => (
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          subheader={
            <small>
              <FormattedMessage
                id="ModalVideoInterviewOptions.seletedTotals"
                defaultMessage="{totQuestions} question(s) | {totMinutes}"
                values={{
                  totQuestions: items.length,
                  totMinutes: formattedDuration(totalDuration)
                }}
              />
            </small>
          }
          action={
            <>
              <Tooltip
                title={
                  <FormattedMessage
                    id="ModalVideoInterviewOptions.button_newQuestion"
                    defaultMessage="Add question"
                  />
                }
                arrow
              >
                <IconButton color="primary" onClick={() => this.openDialog()}>
                  <PlaylistAddIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={
                  <FormattedMessage
                    id="ModalVideoInterviewOptions.button_listQuestions"
                    defaultMessage="Select from default questions"
                  />
                }
                arrow
              >
                <IconButton color="secondary" onClick={this.toggleDrawer(true)}>
                  <FormatListNumberedIcon />
                </IconButton>
              </Tooltip>
            </>
          }
        />
        <Divider />

        {displayMaxAlert()}

        {/* https://codesandbox.io/s/4qp6vjp319?file=/index.js:381-428 */}
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <RootRef rootRef={provided.innerRef}>
                <List
                  className={classes.interviewList}
                  style={getListStyle(snapshot.isDraggingOver)}
                  dense
                  component="div"
                  role="list"
                >
                  {items.map((question, i) => {
                    const labelId = `transfer-list-all-item-${question.id}-label`;

                    return (
                      <Draggable key={question.question} draggableId={question.question} index={i}>
                        {(provided, snapshot) => (
                          <ListItem
                            key={question.question}
                            role="listitem"
                            ContainerComponent="li"
                            ContainerProps={{ ref: provided.innerRef }}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <ListItemIcon>
                              <DragHandleIcon />
                            </ListItemIcon>
                            <ListItemAvatar>
                              <MuiAvatar>{i + 1}</MuiAvatar>
                            </ListItemAvatar>
                            <ListItemText
                              id={labelId}
                              primary={question.question}
                              secondary={formattedDuration(question.max_duration)}
                              onClick={() => this.openDialog(i)}
                              className={classes.interviewItem}
                            />

                            <ListItemSecondaryAction>
                              <IconButton edge="end">
                                {question.id ? (
                                  <ExitToAppIcon onClick={() => this.deletedQuestion(i)} />
                                ) : (
                                  <DeleteIcon onClick={() => this.deletedQuestion(i)} />
                                )}
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </List>
              </RootRef>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
    );

    return (
      <>
        {this.state.isBlocked && (
          <ModalAlertBlock
            open={this.state.open}
            handleModalOpen={this.handleModalOpen}
            handlerClose={this.handleClose}
          />
        )}
        <Dialog
          fullScreen
          open={this.state.isBlocked ? this.state.modalOpen : this.state.open}
          onEnter={this.handleOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <Backdrop open={this.state.submiting} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <form onSubmit={this.onSubmit}>
            <AppBar color="default" position="relative">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.handleClose}
                  aria-label="close"
                  disabled={this.state.submiting}
                >
                  <CloseIcon />
                </IconButton>
                <Box flex={1}>
                  <h5 className="text-center text-uppercase">
                    <FormattedHTMLMessage
                      id="ModalVideoInterviewOptions.title"
                      defaultMessage="Recorded Interview session"
                    />
                  </h5>
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    this.state.submiting ||
                    this.state.interviewQuestions.length === 0 ||
                    this.state.interviewQuestions.length > MAX_QUESTIONS ||
                    totalDuration > MAX_DURATION ||
                    (appVersion && appVersion === 'RECRUTAI' && !this.state.interviewType)
                  }
                  type="submit"
                >
                  <FormattedMessage id="ModalCustomTestOptions.buttonSend" />
                </Button>
              </Toolbar>
            </AppBar>
            {displayNoCommunicationMsg()}
            <Row>
              <Col md={8}>
                {appVersion && appVersion === 'RECRUTAI' && (
                  <div style={{ marginLeft: '1rem' }}>
                    <h4>
                      <FormattedMessage
                        id="ModalVideoInterviewOptions.option_type"
                        defaultMessage="Recording mode"
                      />
                    </h4>
                    <RadioGroup
                      name="interviewType"
                      value={this.state.interviewType}
                      onChange={this.handleChangeSelectType}
                      required={true}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        value="VIDEO"
                        control={<Radio color="primary" />}
                        label={
                          <span style={{ display: 'flex', alignItems: 'center' }}>
                            <VideocamOutlinedIcon className={classes.rootColor} />
                            <FormattedMessage
                              id="ModalVideoInterviewOptions.modal_VIDEO"
                              defaultMessage="Video Interview"
                            />
                          </span>
                        }
                        style={{ marginRight: '4rem' }}
                      />
                      <FormControlLabel
                        value="AUDIO"
                        control={<Radio color="primary" />}
                        label={
                          <span style={{ display: 'flex', alignItems: 'center' }}>
                            <MicNoneOutlinedIcon className={classes.rootColor} />
                            <FormattedMessage
                              id="ModalVideoInterviewOptions.modal_AUDIO"
                              defaultMessage="Audio Interview"
                            />
                          </span>
                        }
                      />
                    </RadioGroup>
                  </div>
                )}
                <div className="panel panel-body">
                  <h4>
                    <FormattedMessage
                      id="ModalVideoInterviewOptions.title_questions"
                      defaultMessage="Recorded interview questions"
                    />
                  </h4>
                  <p className="text-muted">
                    <small>
                      <FormattedHTMLMessage
                        id="ModalVideoInterviewOptions.subTitle_questions"
                        defaultMessage="Each recorded interview session is composed of a series of questions in a specific order. Each seasson cannot contain more then {totQuestions} questions or exceed a total of {totMinutes} minutes. <a href='{link}' target='_new'>Click here</a> to know more..."
                        values={{
                          link: CONST.HELP_URL.BR.VIDEOINTERVIEW_ARTICLE,
                          totQuestions: MAX_QUESTIONS,
                          totMinutes: MAX_DURATION / 60 // Seconds to Minutes
                        }}
                      />
                    </small>
                  </p>
                  <Grid
                    container
                    spacing={2}
                    justify="center"
                    alignItems="center"
                    className={classes.root}
                  >
                    <Grid item xs>
                      {interviewList(this.state.interviewQuestions)}
                    </Grid>
                  </Grid>
                  <hr />
                  <h4>
                    <FormattedMessage
                      id="ModalVideoInterviewOptions.title_limit"
                      defaultMessage="Session Date limit"
                    />
                  </h4>
                  <fieldset>
                    <Row>
                      <Col md={5}>
                        <FormControlLabel
                          name="limit_type"
                          value="DAYS"
                          checked={this.state.limitType === 'DAYS'}
                          onChange={(e) => this.setLimitType(e)}
                          control={<Radio color="primary" />}
                          labelPlacement="end"
                          label={<FormattedMessage id="ModalCustomTestOptions.option_numberDays" />}
                        />
                      </Col>
                      <Col md={7}>
                        <div style={{ width: '100%' }}>
                          <Slider
                            name="dayslimit"
                            className="mt"
                            value={this.state.daysLimit}
                            step={1}
                            marks
                            min={1}
                            max={5}
                            valueLabelDisplay="on"
                            onChange={(e, value) => this.handleDaysLimitChange(e, value)}
                            disabled={this.state.disabledDaysOpt}
                            required={!this.state.disabledDaysOpt}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}>
                        <p className="text-center text-muted">
                          <FormattedMessage id="ModalCustomTestOptions.option_or" />
                        </p>
                      </Col>
                      <Col md={7} className="text-center">
                        {displayDaysLimitDate()}
                      </Col>
                    </Row>
                    <Row className="mt">
                      <Col md={5}>
                        <FormControlLabel
                          name="limit_type"
                          value="DATE"
                          checked={this.state.limitType === 'DATE'}
                          onChange={(e) => this.setLimitType(e)}
                          control={<Radio color="primary" />}
                          labelPlacement="end"
                          label={
                            <FormattedMessage id="ModalCustomTestOptions.option_SpecificDay" />
                          }
                        />
                      </Col>
                      <Col md={7}>
                        <MobileDateTimePicker
                          name="datetime_limit"
                          disablePast={true}
                          minutesStep={5}
                          ampm={false}
                          autoOk={true}
                          //format="d MMM yyyy hh:mm"
                          value={this.state.dateLimit}
                          onChange={(value) => this.handleDateLimitChange(value)}
                          required={!this.state.disabledDateOpt}
                          disabled={this.state.disabledDateOpt}
                          renderInput={(props) => <TextField {...props} />}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={5}>
                        <label>
                          <FormattedMessage id="ModalCustomTestOptions.timezone" />
                        </label>
                        <p className="text-muted">
                          <small>
                            <FormattedMessage id="ModalCustomTestOptions.timezoneHelp" />
                          </small>
                        </p>
                      </Col>
                      <Col md={7}>
                        <Select
                          name="timezone"
                          required={true}
                          clearable={false}
                          value={this.state.timezone}
                          options={this.state.timezoneList}
                          onChange={this.handleTimezoneChange}
                          menuContainerStyle={{ zIndex: 999 }}
                        />
                      </Col>
                    </Row>
                  </fieldset>
                </div>
              </Col>
              <Col md={4} style={{ borderLeft: '1px solid silver' }}>
                <div className="panel panel-body">
                  <h4>
                    <FormattedMessage id="ModalCustomTestOptions.title_selectedApplicants" />
                  </h4>
                  {this.state.isBlocked && (
                    <Alert
                      severity="warning"
                      classes={{ icon: classes.iconWarning }}
                      style={{
                        backgroundColor: CONST.APP_COLORS['warning-light'],
                        color: CONST.APP_COLORS['warning']
                      }}
                    >
                      <Typography>
                        <FormattedMessage id="AlertApplicantBlocked.alert_selected" />
                      </Typography>
                    </Alert>
                  )}
                  <List dense style={{ width: '100%' }}>
                    {listApplicants}
                  </List>
                </div>
              </Col>
            </Row>
          </form>
        </Dialog>
        <Drawer anchor="right" open={this.state.drawer} onClose={this.toggleDrawer(false)}>
          <div style={{ minWidth: '300px', width: '40vw' }}>
            <AppBar color={CONST.APP_COLORS['white']} position="relative">
              <Toolbar variant="dense">
                <Button
                  startIcon={<MenuOpenIcon />}
                  onClick={() => this.handleCheckedRight()}
                  disabled={defaultChecked.length === 0}
                >
                  <FormattedMessage
                    id="ModalVideoInterviewOptions.buttonLoadQuestions"
                    defaultMessage="Load question(s)"
                  />
                </Button>
                {/*}
                <IconButton edge="start" color="inherit" onClick={this.toggleDrawer(false)}>
                  <CloseIcon />
                </IconButton>
                {*/}
                <Box flex={1}></Box>
              </Toolbar>
            </AppBar>
            <div className="p-lg">{defaultList(this.state.defaultQuestions)}</div>
          </div>
        </Drawer>
        <Dialog open={this.state.dialog} onClose={this.closeDialog}>
          <div style={{ minWidth: '300px' }}>
            <DialogTitle>
              <FormattedMessage
                id="ModalVideoInterviewOptions.editDialogTitle"
                defaultMessage="Recorded interview question"
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <small>
                  <FormattedMessage
                    id="ModalVideoInterviewOptions.editDialogTitleMsg"
                    defaultMessage="Tips: Make objective questions to get objectives answers. Avoid long questions and don't ask multiple questions in one."
                  />
                </small>
              </DialogContentText>
              <Row>
                <Col md={8}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="edit_question"
                    name="edit_question"
                    value={this.state.edit_question}
                    onChange={(e) => this.handleChange(e)}
                    color="primary"
                    fullWidth
                    label={
                      <FormattedMessage
                        id="ModalVideoInterviewOptions.editDialog_questions"
                        defaultMessage="Question"
                      />
                    }
                    helperText={
                      <FormattedMessage
                        id="ModalVideoInterviewOptions.editDialog_questionsHelp"
                        defaultMessage="Max 100 caracters per question"
                      />
                    }
                    inputProps={{
                      maxLength: 100
                    }}
                  />
                </Col>
                <Col md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="duration-label">
                      <FormattedMessage
                        id="ModalVideoInterviewOptions.editDialog_maxDuration"
                        defaultMessage="Max duration"
                      />
                    </InputLabel>
                    <MuiSelect
                      labelId="duration-label"
                      id="edit_max_duration"
                      name="edit_max_duration"
                      margin="dense"
                      value={this.state.edit_max_duration}
                      onChange={(e) => this.handleChange(e)}
                      fullWidth
                    >
                      <MenuItem value={30}>30s</MenuItem>
                      <MenuItem value={60}>1min</MenuItem>
                      <MenuItem value={90}>1m 30s</MenuItem>
                      <MenuItem value={120}>2min</MenuItem>
                      <MenuItem value={150}>2m 30s</MenuItem>
                      <MenuItem value={180}>3min</MenuItem>
                      <MenuItem value={210}>3m 30s</MenuItem>
                      <MenuItem value={240}>4min</MenuItem>
                      <MenuItem value={270}>4m 30s</MenuItem>
                      <MenuItem value={300}>5min</MenuItem>
                    </MuiSelect>
                    <FormHelperText>
                      <FormattedMessage
                        id="ModalVideoInterviewOptions.editDialog_maxDurationHelp"
                        defaultMessage="Max 5 minutes"
                      />
                    </FormHelperText>
                  </FormControl>
                </Col>
              </Row>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="default">
                <FormattedMessage id="Base.button.cancel" />
              </Button>
              <Button
                onClick={this.saveQuestion}
                color="primary"
                variant="contained"
                disabled={this.state.edit_question.length === 0}
              >
                <FormattedMessage
                  id="ModalVideoInterviewOptions.buttonSaveQuestion"
                  defaultMessage="Save question"
                />
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </>
    );
  }
}

ModalVideoInterviewOptions.propTypes = {
  accountInfo: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo,
  user: state.user.user
});

export default connect(
  mapStateToProps,
  null
)(injectIntl(withStyles(useStyles)(ModalVideoInterviewOptions)));
