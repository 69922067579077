import { SET_PLAN } from '../actions/actions';
import produce from 'immer';

const INITIAL_STATE = {
  accountPlan: {
    plan_type: '',
    plan_sms_type: '',
    plan_vi_type: '',
    plan_users: {
      count: -1,
      limit: -1,
      perc: -1
    },
    plan_jobs: {
      count: -1,
      limit: -1,
      perc: -1
    },
    plan_personality: {
      count: -1,
      limit: -1,
      perc: -1
    },
    plan_vi: {
      count: -1,
      limit: -1,
      perc: -1
    },
    plan_sms: {
      count: -1,
      limit: -1,
      perc: -1
    },
    plan_crossrank: false
  }
};

export default function planReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_PLAN: {
        draft.accountPlan = action.payload;
        break;
      }
      default:
        return state;
    }
  });
}
