import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Moment from 'moment';
import Typography from '@material-ui/core/Typography';
import LocaleFormatter from '../../../Common/LocaleFormatter';
import Chip from '@material-ui/core/Chip';
import * as CONST from '../../../Common/constants';
import CheckIcon from '@material-ui/icons/Check';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import AddressController from '../../../Common/AddressController';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CopyToClipboard from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import InterviewMenuActions from './InterviewMenuActions';
import InterviewRating from './InterviewRating';
import Utils from '../../../Common/Utils';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ModalChangeEvent from '../../../Pipeline/ModalChangeEvent';
import UserProfile from '../../../Common/UserProfile';
import HumanizeDuration from 'humanize-duration';
import ComingChip from '../Common/ComingChip';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const baseFilters = CONST.getApplicantDetailBaseFields();

const messages = defineMessages({
  opt_PRESENTIAL: {
    id: 'ListInterview.opt_PRESENTIAL',
    defaultMessage: 'In-person'
  },
  opt_DIGITAL: {
    id: 'ListInterview.opt_DIGITAL',
    defaultMessage: 'Video Call'
  },
  opt_PHONE: {
    id: 'ListInterview.opt_PHONE',
    defaultMessage: 'Phone Call'
  },
  msg_sendForm: {
    id: 'ListInterview.msg_sendForm',
    defaultMessage: 'Hello {applicantName}!'
  },
  //You are invited to participate in stage [stage name] in the selection process [name and code of the vacancy] of the company [company name].
  msg_sendForm2: {
    id: 'ListInterview.msg_sendForm2',
    defaultMessage: 'You have been invited for the stage {stageName} '
  },
  msg_sendForm3: {
    id: 'ListInterview.msg_sendForm3',
    defaultMessage: 'in the [{jobCode}] {jobTitle} selection process'
  },
  msg_sendForm4: {
    id: 'ListInterview.msg_sendForm4',
    defaultMessage:
      'Please confirm the invitation by email or to me here. Here are the details of the appointment:'
  },
  msg_sendForm5: {
    id: 'ListInterview.msg_sendForm5',
    defaultMessage: 'We will be waiting for you!'
  },
  title_type: {
    id: 'ListInterview.title_type',
    defaultMessage: 'Type:'
  },
  title_date: {
    id: 'ListInterview.title_date',
    defaultMessage: 'Date:'
  },
  title_duration: {
    id: 'ListInterview.title_duration',
    defaultMessage: 'Duration:'
  },
  title_link: {
    id: 'ListInterview.title_link',
    defaultMessage: 'Link:'
  },
  title_address: {
    id: 'ListInterview.title_address',
    defaultMessage: 'Address:'
  },
  title_phone: {
    id: 'ListInterview.title_phone',
    defaultMessage: 'Phone number:'
  }
});

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '…' : str;
}

const useStyles = (theme) => ({
  printContainer: {
    filter: 'grayscale(100%)'
  },
  list: { padding: 0 },
  mainText: {
    fontSize: '12px',
    color: theme.palette.gray.darker,
    lineHeight: '22px'
  },
  secondaryText: {
    fontSize: '10px',
    color: theme.palette.gray.darkerMedium,
    lineHeight: '22px'
  },
  dayChip: {
    fontSize: '10px',
    height: '18px'
  },
  mediumChip: {
    fontSize: '12px',
    borderRadius: '4px'
  },
  todayChip: {
    background: theme.palette.info.light,
    color: theme.palette.info.main
  },
  greenChip: {
    color: theme.palette.success.main,
    background: `${theme.palette.success.main}10`,
    '& .MuiChip-icon': {
      color: theme.palette.success.main
    }
  },
  redChip: {
    color: theme.palette.error.dark,
    background: theme.palette.error.light,
    '& .MuiChip-icon': {
      color: theme.palette.error.dark
    }
  },
  yellowChip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    '& .MuiChip-icon': {
      color: theme.palette.warning.main
    }
  },
  iconActionContainer: {
    padding: theme.spacing(1)
  },
  avatarGroup: {
    widht: '100%',
    '& .MuiAvatar-root': {
      width: '30px',
      height: '30px',
      fontSize: '14px'
    }
  },
  avatarText: {
    fontSize: '14px',
    color: theme.palette.common.white
  },
  fixedLeftColumn: {
    background: theme.palette.common.white,
    alignSelf: 'stretch',
    position: 'sticky',
    left: 0,
    zIndex: 301,
    paddingLeft: theme.spacing(3)
  },
  fixedRightColumn: {
    background: theme.palette.common.white,
    alignSelf: 'stretch',
    position: 'sticky',
    right: 0,
    zIndex: 301
  },
  listItemGutters: {
    padding: '2px 0'
  },
  mainListContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '16px',
    padding: '0'
  },
  itemWithBorder: {
    borderBottom: `1px solid ${theme.palette.gray.darkerLight}`
  },
  printChip: {
    width: '100%'
  },
  interviewersContainer: {
    '@media print': {
      display: 'none'
    }
  },
  chip: {
    color: theme.palette.gray.darkerMedium,
    backgroundColor: `${theme.palette.gray.darkerMedium}10`,
    borderColor: theme.palette.gray.darkerMedium
  },
  alertMessage: {
    display: 'flex',
    borderRadius: 'var(--borderRadius, 4px)',
    padding: '4px var(--1, 8px)',
    margin: '8px 0',
    alignItems: 'center',
    maxHeight: '7vh',
    '& .MuiAlert-icon': {
      padding: '0',
      color: theme.palette.error.main
    },
    '& .MuiAlert-message': {
      padding: '0',
      color: theme.palette.error.main,
      overflowWrap: 'break-word',
      fontSize: '12px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxHeight: '10vh !important'
    }
  },
  alertContainer: {
    maxHeight: '7vh',
    overflow: 'hidden'
  },
  errorIcon: {
    color: `${theme.palette.error.dark} !important`
  }
});

class ListInterview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interviews: props.interviews ? props.interviews : [],
      pipeline_stages: props.pipeline_stages,
      applicant: props.applicant,
      job: props.job,
      accountUsers: props.accountUsers ? props.accountUsers : [],
      readonly: props.readonly ? props.readonly : false,
      print: props.print ? props.print : false,
      // Internal State
      loadingApplicants: true,
      // Menu
      anchorEl: null,
      modalChange: false,
      modalCancel: false,
      selectedInterviewId: null,
      selectedInterviewJobId: null,
      interviewLinkCopied: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.interviews !== this.props.interviews) {
      this.setState({
        interviews: this.props.interviews ? this.props.interviews : []
      });
    }

    if (prevProps.pipeline_stages !== this.props.pipeline_stages) {
      this.setState({
        pipeline_stages: this.props.pipeline_stages ? this.props.pipeline_stages : []
      });
    }

    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }

    if (prevProps.job !== this.props.job) {
      this.setState({
        job: this.props.job
      });
    }

    if (prevProps.accountUsers !== this.props.accountUsers) {
      this.setState({
        accountUsers: this.props.accountUsers
      });
    }
  }

  componentDidMount() {
    //console.log("mounted " + this.state.applicant_id);
  }

  handleOpenMenu = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleCloseMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleModalClose = () => {
    this.setState({
      modalChange: false
    });
  };

  handleModalUpdate = (interviews) => {
    this.setState(
      {
        modalChange: false
      },
      () => {
        if (interviews && interviews.length > 0) {
          this.setState({
            interview: interviews[0]
          });
        }
      }
    );
  };

  onChangeEvent = (e, interview) => {
    e.preventDefault();
    this.setState({
      selectedInterviewId: interview.id,
      selectedInterviewJobId: interview.job_id,
      modalChange: true,
      anchorEl: null
    });
  };

  handleUpdateInterview = (currInterview) => {
    const interviews = this.state.interviews;

    const updatedInterview = Array.isArray(currInterview) ? currInterview[0] : currInterview;

    const updatedInterviews = interviews.map((interview) => {
      if (interview.id === updatedInterview.id) {
        return {
          ...interview,
          ...updatedInterview
        };
      } else {
        return interview;
      }
    });

    this.setState({
      interviews: updatedInterviews
    });
  };

  render() {
    const { classes } = this.props;
    const applicant = this.state.applicant;

    const readonly = this.state.readonly;
    const print = this.state.print;

    const abbreviation = this.props.accountInfo
      ? this.props.accountInfo.accountInfo.abbreviation
      : '';

    const phone = applicant.phone_mobile;
    let whatsapp;
    if (phone) {
      whatsapp = phone.replace(/\D/g, ''); // https://faq.whatsapp.com/general/chats/how-to-use-click-to-chat/?lang=kk
    }

    const getStageName = (interview) => {
      const pipeline_stages = this.state.pipeline_stages;
      if (interview.pipeline_stage !== null && pipeline_stages && pipeline_stages.length > 0) {
        let stageNameDisplay = '';
        const jobStageStatus = pipeline_stages.find(
          (stageStatus) => stageStatus.id === interview.pipeline_stage
        );

        if (jobStageStatus) {
          stageNameDisplay = jobStageStatus.type.toLowerCase();

          const canTranslateStageName =
            jobStageStatus.type === CONST.STAGE_VIDEOINTERVIEW ||
            jobStageStatus.type === CONST.STAGE_SAVEDLATER;

          if (
            canTranslateStageName &&
            this.props.intl.formatMessage({ id: 'Pipeline.type_' + stageNameDisplay }) !== undefined
          ) {
            stageNameDisplay = this.props.intl.formatMessage({
              id: 'Pipeline.type_' + stageNameDisplay
            });
          } else {
            stageNameDisplay = jobStageStatus.name;
          }
          if (stageNameDisplay.startsWith('Pipeline.')) {
            stageNameDisplay = jobStageStatus.name;
          }
        }

        return stageNameDisplay;
      } else {
        return null;
      }
    };

    const interviewDate = (interview) => {
      const datetime_interview = interview.datetime_interview;
      if (datetime_interview) {
        const capitalizedFirstLetter =
          Moment(datetime_interview).format('dddd').slice(0, 1).toUpperCase() +
          Moment(datetime_interview).format('dddd').slice(1).toLowerCase();

        const dateToCheck = Moment(datetime_interview);

        const today = Moment().startOf('day');
        const tomorrow = Moment().add(1, 'days').startOf('day');

        let label = undefined;
        let chipClasses = `${classes.dayChip} ${classes.printChip}`;

        if (dateToCheck.isSame(today, 'day')) {
          label = <FormattedMessage id="ListInterview.label_today" defaultMessage="Today" />;
          chipClasses += ` ${classes.todayChip}`;
        } else if (dateToCheck.isSame(tomorrow, 'day')) {
          label = <FormattedMessage id="ListInterview.label_tomorrow" defaultMessage="Tomorrow" />;
        }

        return (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography className={classes.mainText}>{capitalizedFirstLetter}</Typography>
              {label ? (
                <Box ml={0.5}>
                  <Chip className={chipClasses} label={label} size="small" />
                </Box>
              ) : null}
            </Box>
            <Typography className={classes.mainText}>
              <LocaleFormatter dateToFormat={Moment(datetime_interview).format('DD MMMM YYYY')} />
            </Typography>
            <Typography className={classes.mainText}>
              <LocaleFormatter dateToFormat={Moment(datetime_interview).format('LT')} />
              {' - '}
              <LocaleFormatter
                dateToFormat={Moment(datetime_interview)
                  .add(interview.duration, 'minutes')
                  .format('LT')}
              />
            </Typography>
          </Box>
        );
      } else {
        return (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}
          >
            <Typography className={classes.secondaryText}>
              <FormattedMessage id="ListInterview.msg_noData" defaultMessage="(no date)" />
            </Typography>
          </Box>
        );
      }
    };

    const statusChip = (interview) => {
      let title = undefined;
      let icon = undefined;
      let chipClass = undefined;
      let message = undefined;
      if (interview.cancelled === true) {
        message =
          interview.cancelled_msg && interview.cancelled_msg !== ''
            ? interview.cancelled_msg
            : null;
        icon = <EventBusyIcon />;
        chipClass = classes.redChip;
        title = <FormattedMessage id="CardInterview.interviewCancelled" />;
      } else if (interview.confirmation === true) {
        icon = <CheckIcon />;
        chipClass = classes.greenChip;
        title = (
          <FormattedMessage
            id="CardInterview.interviewConfirmed"
            values={{
              date: Moment(interview.date_confirm).format('ll')
            }}
          />
        );
      } else if (interview.noshow === true) {
        message = interview.noshow_msg && interview.noshow_msg !== '' ? interview.noshow_msg : null;
        icon = <EventBusyIcon />;
        chipClass = classes.redChip;
        title = <FormattedMessage id="CardInterview.interviewNoShow" />;
      } else if (interview.noschedule === true) {
        message =
          interview.noschedule_msg && interview.noschedule_msg !== ''
            ? interview.noschedule_msg
            : null;
        icon = <EventBusyIcon />;
        chipClass = classes.redChip;
        title = <FormattedMessage id="CardInterview.interviewNoSchedule" />;
      } else if (interview.datetime_interview) {
        icon = <HelpOutlineIcon />;
        chipClass = classes.yellowChip;
        title = <FormattedMessage id="CardInterview.interviewNoAnswer" />;
      } else {
        if (interview.mode === 'AUTO') {
          icon = <HelpOutlineIcon />;
          chipClass = classes.yellowChip;
          title = (
            <FormattedMessage
              id="ListInterview.interviewWaitingCandidatePickSlot"
              defaultMessage="Waiting schedule"
            />
          );
        } else {
          icon = <HelpOutlineIcon />;
          chipClass = classes.yellowChip;
          title = <FormattedMessage id="CardInterview.interviewNotScheduled" />;
        }
      }

      const truncatedMessage = message && message.length > 36 ? truncate(message, 36) : message;

      return (
        <>
          <Chip
            className={`${chipClass} ${classes.printChip}`}
            icon={icon}
            label={title}
            size="small"
          />
          {truncatedMessage && (
            <Tooltip title={message}>
              <Box className={classes.alertContainer}>
                <Alert
                  severity="error"
                  classes={{ icon: classes.errorIcon }}
                  style={{
                    backgroundColor: CONST.APP_COLORS['danger-light'],
                    color: CONST.APP_COLORS['danger-dark']
                  }}
                  className={classes.alertMessage}
                >
                  {truncatedMessage}
                </Alert>
              </Box>
            </Tooltip>
          )}
        </>
      );
    };

    const stageChip = (interview) => {
      const stageName = getStageName(interview);

      if (stageName) {
        return (
          <Chip label={stageName} size="small" className={this.state.print ? classes.chip : ''} />
        );
      }
    };

    const mediumContainer = (interview) => {
      const eventNotScheduled =
        !interview.cancelled &&
        !interview.confirmation &&
        !interview.noshow &&
        !interview.noschedule &&
        !interview.datetime_interview &&
        interview.mode !== 'AUTO';
      if (eventNotScheduled) {
        return (
          <Typography className={classes.secondaryText}>
            <FormattedMessage
              id="ListInterview.msg_noMedium"
              defaultMessage="(no appointment type)"
            />
          </Typography>
        );
      } else {
        let icon = undefined;
        let directions = undefined;
        let link = undefined;
        let hasLink = false;
        let whatsappText = undefined;
        const applicant = this.state.applicant;
        const job = this.state.job;

        let duration_format = interview.datetime_interview
          ? HumanizeDuration(interview.duration * 60 * 1000, {
              language: 'pt', // FIXME Lang!
              units: ['h', 'm'],
              spacer: ' ',
              round: true
            })
          : null;

        let timezone = interview.timezone ? interview.timezone : null;

        if (interview.medium === 'PRESENTIAL') {
          icon = <PinDropOutlinedIcon />;

          link = interview.address ? (
            AddressController.getAddrUrl(interview.address)
          ) : (
            <Typography className={classes.mainText}>
              <FormattedMessage
                id="ListInterview.no_address"
                defaultMessage="(no address provided)"
              />{' '}
              {readonly || print ? null : (
                <>
                  <a href="#" onClick={(e) => this.onChangeEvent(e, interview)}>
                    <FormattedMessage
                      id="ListInterview.msg_clickHere"
                      defaultMessage="Click here"
                    />
                  </a>{' '}
                  <FormattedMessage id="ListInterview.msg_add" defaultMessage="to add." />
                </>
              )}
            </Typography>
          );

          hasLink = !!interview.address;

          directions = hasLink ? (
            <Typography
              className={classes.mainText}
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {link}
            </Typography>
          ) : (
            link
          );

          whatsappText =
            this.props.intl.formatMessage(
              { id: 'ListInterview.msg_sendForm' },
              {
                applicantName: applicant.name
              }
            ) +
            ' \r\n' +
            ' \r\n' +
            this.props.intl.formatMessage(
              { id: 'ListInterview.msg_sendForm2' },
              {
                stageName: getStageName(interview)
              }
            ) +
            ' ' +
            this.props.intl.formatMessage(
              { id: 'ListInterview.msg_sendForm3' },
              {
                jobCode: job.code,
                jobTitle: job.title
              }
            ) +
            this.props.intl.formatMessage(
              { id: 'CVDetail.msg_sendForm3' },
              {
                companyName: abbreviation
              }
            ) +
            ' \r\n' +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.msg_sendForm4' }) +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_type' }) +
            ' ' +
            this.props.intl.formatMessage({ id: 'ListInterview.opt_PRESENTIAL' }) +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_address' }) +
            ' ' +
            link +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_date' }) +
            ' ' +
            Moment(interview.datetime_interview).utc().tz(timezone).format('DD MMMM YYYY, HH:mm') +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_duration' }) +
            ' ' +
            duration_format +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ModalCustomTestOptions.timezone' }) +
            ': ' +
            timezone +
            ' \r\n' +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.msg_sendForm5' });
        }

        if (interview.medium === 'DIGITAL') {
          icon = <VideocamOutlinedIcon />;

          if (interview.digital_url) {
            link = interview.digital_url.startsWith('http')
              ? interview.digital_url
              : 'https://' + interview.digital_url;

            hasLink = true;
          } else {
            link = (
              <Typography className={classes.mainText}>
                <FormattedMessage id="ListInterview.no_link" defaultMessage="(no link provided)" />{' '}
                {readonly || print ? null : (
                  <>
                    <a href="#" onClick={(e) => this.onChangeEvent(e, interview)}>
                      <FormattedMessage
                        id="ListInterview.msg_clickHere"
                        defaultMessage="Click here"
                      />
                    </a>{' '}
                    <FormattedMessage id="ListInterview.msg_add" defaultMessage="to add." />
                  </>
                )}
              </Typography>
            );
          }

          directions = hasLink ? (
            <Typography
              className={classes.mainText}
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {readonly || print ? link : <a href={link}>{link}</a>}
            </Typography>
          ) : (
            link
          );

          whatsappText =
            this.props.intl.formatMessage(
              { id: 'ListInterview.msg_sendForm' },
              {
                applicantName: applicant.name
              }
            ) +
            ' \r\n' +
            ' \r\n' +
            this.props.intl.formatMessage(
              { id: 'ListInterview.msg_sendForm2' },
              {
                stageName: getStageName(interview)
              }
            ) +
            ' ' +
            this.props.intl.formatMessage(
              { id: 'ListInterview.msg_sendForm3' },
              {
                jobCode: job.code,
                jobTitle: job.title
              }
            ) +
            this.props.intl.formatMessage(
              { id: 'CVDetail.msg_sendForm3' },
              {
                companyName: abbreviation
              }
            ) +
            ' \r\n' +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.msg_sendForm4' }) +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_type' }) +
            ' ' +
            this.props.intl.formatMessage({ id: 'ListInterview.opt_DIGITAL' }) +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_link' }) +
            ' ' +
            link +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_date' }) +
            ' ' +
            Moment(interview.datetime_interview).utc().tz(timezone).format('DD MMMM YYYY, HH:mm') +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_duration' }) +
            ' ' +
            duration_format +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ModalCustomTestOptions.timezone' }) +
            ': ' +
            timezone +
            ' \r\n' +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.msg_sendForm5' });
        }

        if (interview.medium === 'PHONE') {
          icon = <PhoneOutlinedIcon />;
          link = interview.phone_in ? (
            interview.phone_in
          ) : (
            <Typography className={classes.mainText}>
              <FormattedMessage
                id="ListInterview.no_phone"
                defaultMessage="(no phone number provided)"
              />{' '}
              {readonly || print ? null : (
                <>
                  <a href="#" onClick={(e) => this.onChangeEvent(e, interview)}>
                    <FormattedMessage
                      id="ListInterview.msg_clickHere"
                      defaultMessage="Click here"
                    />
                  </a>{' '}
                  <FormattedMessage id="ListInterview.msg_add" defaultMessage="to add." />
                </>
              )}
            </Typography>
          );

          hasLink = !!interview.phone_in;

          directions = hasLink ? (
            <Typography className={classes.mainText}>{interview.phone_in}</Typography>
          ) : (
            link
          );

          whatsappText =
            this.props.intl.formatMessage(
              { id: 'ListInterview.msg_sendForm' },
              {
                applicantName: applicant.name
              }
            ) +
            ' \r\n' +
            ' \r\n' +
            this.props.intl.formatMessage(
              { id: 'ListInterview.msg_sendForm2' },
              {
                stageName: getStageName(interview)
              }
            ) +
            ' ' +
            this.props.intl.formatMessage(
              { id: 'ListInterview.msg_sendForm3' },
              {
                jobCode: job.code,
                jobTitle: job.title
              }
            ) +
            this.props.intl.formatMessage(
              { id: 'CVDetail.msg_sendForm3' },
              {
                companyName: abbreviation
              }
            ) +
            ' \r\n' +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.msg_sendForm4' }) +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_type' }) +
            ' ' +
            this.props.intl.formatMessage({ id: 'ListInterview.opt_PHONE' }) +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_phone' }) +
            ' ' +
            link +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_date' }) +
            ' ' +
            Moment(interview.datetime_interview).utc().tz(timezone).format('DD MMMM YYYY, HH:mm') +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.title_duration' }) +
            ' ' +
            duration_format +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ModalCustomTestOptions.timezone' }) +
            ': ' +
            timezone +
            ' \r\n' +
            ' \r\n' +
            this.props.intl.formatMessage({ id: 'ListInterview.msg_sendForm5' });
        }

        const eventPassed =
          new Date(interview.datetime_interview) < new Date() ||
          interview.noshow ||
          interview.cancelled ||
          interview.noschedule;

        return (
          <Box width="100%">
            <Chip
              className={`${classes.mediumChip} ${this.state.print ? classes.chip : ''}`}
              label={this.props.intl.formatMessage({ id: 'ListInterview.opt_' + interview.medium })}
              icon={icon}
              size="small"
            />
            <Box mt={1}>{directions}</Box>
            {hasLink && !readonly && !print ? (
              <Box display="flex" alignItems="center">
                <Box ml={-1}>
                  <CopyToClipboard
                    text={link}
                    onCopy={() => this.setState({ interviewLinkCopied: true })}
                  >
                    <IconButton className={classes.iconActionContainer} disabled={eventPassed}>
                      <FileCopyOutlinedIcon
                        color={eventPassed ? 'secondary' : 'primary'}
                        fontSize="small"
                      />
                    </IconButton>
                  </CopyToClipboard>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    open={this.state.interviewLinkCopied}
                    autoHideDuration={3000}
                    onClose={() => this.setState({ interviewLinkCopied: false })}
                    message={<FormattedMessage id="ModalShareLink.copy_confirmed" />}
                  />
                </Box>

                <Box>
                  {whatsapp ? (
                    <Box>
                      <IconButton
                        className={classes.iconActionContainer}
                        component={Link}
                        href={'https://wa.me/' + whatsapp + `?text=${encodeURI(whatsappText)}`}
                        target="_whatsapp"
                        disabled={eventPassed}
                      >
                        <WhatsAppIcon
                          color={eventPassed ? 'secondary' : 'primary'}
                          fontSize="small"
                        />
                      </IconButton>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            ) : null}
          </Box>
        );
      }
    };

    const interviewersContainer = (interview) => {
      let atendees = [];
      let names = [];
      const accountUsers = this.state.accountUsers;
      const interviewers_user_id = interview.interviewers_user_id;

      if (interviewers_user_id && interviewers_user_id.length > 0) {
        interviewers_user_id.forEach((atendee) => {
          accountUsers.some((user) => {
            if (user.id === atendee) {
              const path_avatar =
                'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + user.avatar_50_path;

              atendees.push(
                {
                  avatar_50_path: path_avatar,
                  username: user.username,
                  email: user.email
                },
                {
                  avatar_50_path: path_avatar,
                  username: user.username,
                  email: user.email
                },
                {
                  avatar_50_path: path_avatar,
                  username: user.username,
                  email: user.email
                },
                {
                  avatar_50_path: path_avatar,
                  username: user.username,
                  email: user.email
                }
              );
              names.push(user.username);
              return true;
            }
          });
        });
        // console.log(atendees)
        // console.log(accountUsers)
        return (
          <Tooltip title={names.join(', ')} arrow>
            <AvatarGroup max={3} className={classes.avatarGroup}>
              {atendees.map((el) => (
                <Avatar
                  src={el.avatar_50_path}
                  style={{
                    width: '32px',
                    height: '32px'
                  }}
                  classes={{
                    fallback: classes.avatarText
                  }}
                >
                  <Typography className={classes.avatarText}>
                    {Utils.getFirstLetters(el.username.toUpperCase())}
                  </Typography>
                </Avatar>
              ))}
            </AvatarGroup>
          </Tooltip>
        );
      } else {
        return (
          <Typography className={classes.secondaryText}>
            <FormattedMessage
              id="ListInterview.msg_noInterviewers"
              defaultMessage="(no attendees)"
            />
          </Typography>
        );
      }
    };

    const actionsContainer = (interview) => {
      return (
        <Box display="flex" alignItems="center" width="100%">
          <Box mr={1}>
            <Button size="small" color="primary" variant="outlined" disabled>
              <FormattedMessage
                id="ListInterview.button_interviewRoom"
                defaultMessage="Interview Room"
              />
            </Button>
          </Box>
          <Box>
            <ComingChip />
          </Box>
          <Box display="flex" flexDirection="column">
            <Box
              style={{
                whiteSpace: 'pre-wrap'
              }}
            >
              <InterviewMenuActions
                interview={interview}
                applicant={applicant}
                thisStage={interview.pipeline_stage}
                pipeline={this.state.pipeline_stages}
                handleUpdateInterview={this.handleUpdateInterview}
              />
            </Box>
          </Box>
        </Box>
      );
    };

    const ratingsContainer = (interview) => {
      return (
        <Box width="100%">
          <InterviewRating interview={interview} readonly={readonly} />
        </Box>
      );
    };

    const interviews = this.state.interviews;

    if (interviews.length > 0) {
      const mostRecentInterviews = interviews.slice(0, 3);
      return (
        <>
          <List className={classes.list}>
            {mostRecentInterviews.map((interview, index) => {
              const eventNotScheduled =
                !interview.cancelled &&
                !interview.confirmation &&
                !interview.noshow &&
                !interview.noschedule &&
                !interview.datetime_interview &&
                interview.mode !== 'AUTO';

              const interviewers_user_id = interview.interviewers_user_id;
              const hasInterviewers = interviewers_user_id && interviewers_user_id.length > 0;

              return (
                <>
                  <ListItem
                    key={index}
                    classes={{
                      gutters: classes.listItemGutters
                    }}
                  >
                    <Box
                      className={`${classes.mainListContainer} ${
                        index === mostRecentInterviews.length - 1 ? '' : classes.itemWithBorder
                      }`}
                    >
                      <Box width="154px" className={classes.fixedLeftColumn}>
                        {interviewDate(interview)}
                      </Box>
                      <Box width="160px">{statusChip(interview)}</Box>
                      <Box width="200px">{mediumContainer(interview)}</Box>

                      <Box width="12%" maxWidth="110px">
                        {ratingsContainer(interview)}
                      </Box>
                      <Box width="160px">{stageChip(interview)}</Box>
                      {!this.state.print ? (
                        <Box width="100px" className={classes.interviewersContainer}>
                          {interviewersContainer(interview)}
                        </Box>
                      ) : null}
                      {!this.state.print ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          width={this.state.readonly ? '150px' : '260px'}
                          pl={1.5}
                          className={classes.fixedRightColumn}
                        >
                          {actionsContainer(interview)}
                        </Box>
                      ) : null}
                    </Box>
                  </ListItem>
                </>
              );
            })}
          </List>
          <ModalChangeEvent
            open={this.state.modalChange}
            selected_id={this.state.selectedInterviewId}
            submiting={false}
            handlerClose={this.handleModalClose}
            handlerUpdate={this.handleUpdateInterview}
            job_id={this.state.selectedInterviewJobId}
            selected_objs={[this.state.applicant]}
          />
        </>
      );
    } else {
      return null;
    }
  }
}

ListInterview.propTypes = {
  accountInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
  accountInfo: state.accountInfo
});

export default connect(mapStateToProps, null)(injectIntl(withStyles(useStyles)(ListInterview)));
