// core
import React from 'react';

// libs external
import Avatar from 'react-avatar';

import IconSync from './IconSync';

class RenderUserOption extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { showUserCalendarSync } = this.props;
    const { username, avatar_50_path } = this.props.user;
    const avatar = 'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + avatar_50_path;

    return (
      <div style={{ position: 'relative' }}>
        <Avatar name={username} src={avatar} size="20px" round={true} style={{ marginRight: 8 }} />
        <IconSync user={this.props.user} showUserCalendarSync={showUserCalendarSync} />
        {username}
      </div>
    );
  }
}

export default RenderUserOption;
