import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Api from '../../Api/Api';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import WorkIcon from '@material-ui/icons/Work';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import LoadingData from '../../Layout/LoadingData';
import TextField from '@material-ui/core/TextField';
import UserStorage from '../../Common/UserStorage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

const messages = defineMessages({
  title: {
    id: 'ButtonFilterAccountPage.title',
    defaultMessage: 'Pool'
  }
});

const useStyles = (theme) => ({
  menuRoot: {
    minWidth: 480,
    maxWidth: 640
    //minHeight: 340
  },
  list: {
    minWidth: 440,
    minHeight: 80,
    maxHeight: '50vh',
    flexGrow: 1,
    overflowY: 'auto',
    padding: '1.5rem 1rem 0 1rem'
  },
  mainButtonLabelContainer: {
    display: 'flex',
    width: '80%'
  },
  mainButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mainButton: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& .MuiButton-startIcon': {
      paddingLeft: '13px'
    }
  },
  mainButtonLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    marginBottom: '0'
  },
  jobViewersLabel: {
    margin: '.25rem 0 .25rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textLabel: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '0.8125rem',
    fontWeight: '500'
  },
  filterLabel: {
    display: 'flex',
    marginBottom: '0.25rem'
  },
  filterLabelActive: {
    color: theme.palette.primary.main
  }
});

class ButtonFilterAccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // State from parent
      selected: this.props.selected ? this.props.selected : [],
      // Internal State
      loading: true,
      allPages: [],
      pages: [],
      selectedAccountPages: [],
      filteredPages: [],
      selectFilterPagesWritten: false,
      search: '',
      // Menu
      openMenu: false,
      anchorEl: null
    };
  }

  componentDidMount() {
    if (this.state.selected && this.state.selected.length > 0) {
      this.loadPages();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected && this.props.selected !== this.state.selected) {
      this.setState(
        {
          selected: this.props.selected
        },
        () => {
          if (this.state.selected && this.state.selected.length > 0) {
            this.loadPages();
          }
        }
      );
    }
  }

  loadPages() {
    this.setState(
      {
        loading: true
      },
      () => {
        let api = new Api();

        let filter = {
          fields: ['id', 'path', 'date_created', 'abbreviation'],
          where: {
            deleted: false
          },
          include: {
            relation: 'jobPool',
            scope: {
              fields: ['id', 'title']
            }
          },
          order: ['date_created DESC']
        };
        api.get('AccountPages', filter, (status, data) => {
          if (data.length > 0) {
            const modifiedData = data.map((page) => {
              if (page.path === '/') {
                return {
                  ...page,
                  path: `/ ${this.props.intl.formatMessage({ id: 'PageList.pathRoot' })}`
                };
              }
              return page;
            });

            const accountPagesWithJobPool = modifiedData.filter((page) => page.jobPool?.length > 0);

            const selectedAccountPages = accountPagesWithJobPool.filter((viewer) =>
              this.state.selected.includes(viewer.id)
            );

            this.setState({
              loading: false,
              allPages: accountPagesWithJobPool,
              pages: accountPagesWithJobPool,
              selectedAccountPages: selectedAccountPages
            });
          } else {
            this.setState({
              loading: false,
              allPages: [],
              pages: [],
              selectedAccountPages: []
            });
          }
        });
      }
    );
  }

  handleMenuOpen = (e) => {
    if (this.state.allPages.length === 0) {
      this.loadPages();
    }

    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleMenuClose = () => {
    this.setState(
      {
        anchorEl: null
      },
      () => {
        if (this.props.handlerUpdate) {
          this.props.handlerUpdate(this.state.selected ? this.state.selected : []);
        }
      }
    );
  };

  handleSelect = (event, selectedPages) => {
    let filterPages = [];
    const filteredPages = selectedPages.length === 0 ? [] : this.state.selectedAccountPages;

    selectedPages.forEach((option) => {
      if (
        this.props.component === 'ApplicantsSearch' ||
        this.props.component === 'ApplicantsDiversityFiltersContainer'
      ) {
        filterPages.push(option.jobPool?.[0]?.id);
      } else {
        filterPages.push(option.id);
      }
    });

    // console.log(filterPages)

    this.setState({
      selected: filterPages,
      selectedAccountPages: selectedPages,
      filteredPages: filteredPages
    });
  };

  handleSelectAll = (e) => {
    const pagesOptions =
      this.state.filteredPages.length > 0 && this.state.selectFilterPagesWritten
        ? this.state.filteredPages
        : this.state.allPages;
    const isAllSelected = this.state.selected.length === pagesOptions.length;
    const selectedPages = isAllSelected ? [] : pagesOptions;
    const selected = isAllSelected ? [] : selectedPages.map((page) => page.id);

    this.setState({
      selected: selected,
      selectedAccountPages: selectedPages,
      selectFilterPagesWritten: false
    });
  };

  setFilteredPages = (event, value) => {
    if (value && value !== '') {
      this.setState({
        selectFilterPagesWritten: true
      });
    }

    if (event && event.type === 'change') {
      let filteredPages = [];
      filteredPages = this.state.allPages.filter((page) =>
        page.path.toLowerCase().includes(value.toLowerCase())
      );
      //return filteredTags

      this.setState({
        filteredPages: filteredPages
      });
    }
  };

  render() {
    const { classes } = this.props;
    const selectedAccountPages = this.state.selectedAccountPages;
    const selected = this.state.selected;

    const displayLabel = () => {
      if (selected.length === 0) {
        return '';
      } else {
        if (selectedAccountPages.length === 0) {
          // Before loading pages
          return '';
        }

        const viewersName =
          selectedAccountPages.length <= 5
            ? selectedAccountPages.map((viewer) => viewer.path).join(', ')
            : selectedAccountPages
                .slice(0, 5)
                .map((viewer) => viewer.path)
                .join(', ') + '...';
        return (
          <span className={classes.mainButtonLabelContainer}>
            <p className={classes.mainButtonLabel}>{viewersName}</p>
          </span>
        );
      }
    };

    return (
      <>
        <div className={classes.mainButtonContainer}>
          <Typography
            variant="subtitle1"
            className={`${
              this.state.selected.length > 0 ? classes.filterLabelActive : 'text-muted'
            } ${classes.filterLabel}`}
          >
            {this.props.component === 'ApplicantsSearch' ||
            this.props.component === 'ApplicantsDiversityFiltersContainer' ? (
              <FormattedMessage id="PageEdit.cvPool" />
            ) : (
              <FormattedMessage
                id="ButtonFilterAccountPage.label_inactive"
                defaultMessage="Company Pages"
              />
            )}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            style={{
              height: this.props.size === 'large' ? 38 : ''
            }}
            color={this.state.selected.length > 0 ? 'primary' : 'secondary'}
            onClick={this.handleMenuOpen}
            className={classes.mainButton}
            startIcon={<WorkIcon />}
            endIcon={<ArrowDropDownIcon />}
          >
            {displayLabel()}
          </Button>
        </div>

        <Menu
          id="action-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose}
          disableEnforceFocus={true} // https://github.com/mui-org/material-ui-pickers/issues/1852
          className={classes.menuRoot}
        >
          <Button
            size="small"
            color="primary"
            startIcon={<WorkIcon />}
            style={{
              width: '100%'
            }}
            selected={this.state.selected.length === 0 ? true : false}
            onClick={(e) => this.handleSelectAll(e)}
          >
            {this.state.selectFilterPagesWritten ? (
              <FormattedMessage
                id="ButtonFilterAccountPage.label_selectVisiblePages"
                defaultMessage="Select Filtered"
              />
            ) : (
              <FormattedMessage
                id="ButtonFilterAccountPage.label_selectAllPages"
                defaultMessage="Select All"
              />
            )}
          </Button>
          <Divider />

          <div className={classes.list}>
            {this.state.loading ? (
              <ListItem>
                <LoadingData />
              </ListItem>
            ) : (
              <Autocomplete
                id="filterJobViewers"
                name="filterJobViewers"
                value={selectedAccountPages}
                options={this.state.allPages}
                getOptionLabel={(option) =>
                  this.props.component === 'ApplicantsSearch' ||
                  this.props.component === 'ApplicantsDiversityFiltersContainer'
                    ? option.jobPool?.[0]?.title || option.path
                    : option.path
                }
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={this.handleSelect}
                disabled={this.state.loading}
                multiple
                onInputChange={this.setFilteredPages}
                limitTags={6}
                filterSelectedOptions
                disableCloseOnSelect
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      this.props.component === 'ApplicantsSearch' ||
                      this.props.component === 'ApplicantsDiversityFiltersContainer' ? (
                        <FormattedMessage id="PageEdit.cvPool" />
                      ) : (
                        <FormattedMessage
                          id="ButtonFilterAccountPage.label_inactive"
                          defaultMessage="Company Pages"
                        />
                      )
                    }
                    inputRef={(input) => input && input.focus()}
                    InputProps={{
                      ...params.InputProps,

                      startAdornment: (
                        <React.Fragment>
                          <WorkIcon color="disabled" style={{ fontSize: 20 }} />
                          {params.InputProps.startAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            )}
          </div>
        </Menu>
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ButtonFilterAccountPage));
