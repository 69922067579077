import React from 'react';
import { injectIntl, defineMessages, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import * as CONST from '../Common/constants';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Api from '../Api/Api';
import Avatar from 'react-avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import UserProfile from '../Common/UserProfile';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import 'moment/locale/pt-br'; // FIXME locale
import StageController from './StageController';
import Typography from '@material-ui/core/Typography';
import ModalAlertBlock from './ModalAlertBlock';
import Button from '@material-ui/core/Button';
import SelectUsersMultipleCustom from '../SelectUsersMultipleCustom/SelectUsersMultipleCustom';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DialogContent from '@material-ui/core/DialogContent';

const messages = defineMessages({});

// MUST BE OUTSIDE OF COMPONENT
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  containerSendEmail: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  labelSendEmail: {
    fontSize: '14px',
    color: theme.palette.gray.darker
  },
  containerWidth: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  containerResponsive: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  resetPaddingMobile: {
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  showLineMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },

  containerSelectApplicants: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '700px', // Set your width here
        maxHeight: 'calc(90% - 60px)'
      }
    }
  },
  dialogTitle: {
    color: theme.palette.gray.darker,
    lineHeight: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%'
    }
  },
  subtitle: {
    fontSize: '12px',
    color: theme.palette.gray.darkerMedium
  },
  iconInfo: {
    color: theme.palette.gray.darkerRegular,
    marginLeft: theme.spacing(0.5)
  },
  shareLink: {
    color: theme.palette.primary.main,
    maxWidth: '300px',
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  alertValid: {
    padding: '0 8px',
    borderRadius: '8px',
    width: 'fit-content !important',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1)
    }
  },
  iconWarning: {
    color: `${theme.palette.warning.main} !important`
  }
});

class ModalShareLink2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      job_id: props.job_id,
      selected_ids: props.selected_ids,
      selected_objs: props.selected_objs,
      type: props.type ? props.type : 'APPLICANT',
      details: props.details,
      // Internal state
      _loading: false,
      shareLink: '',
      shareLinkCopied: false,
      isBlocked: false,
      modalOpen: false,
      selectedUsers: [],
      accountUsers: [],
      shareLinkId: undefined,
      sendingLink: false,
      linkSent: false
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(
        {
          open: this.props.open
        },
        () => {
          this.warningApplicantBlocked();

          if (this.state.open) {
            this.getAccountUsers();
          }
        }
      );
    }
    if (prevProps.selected_ids !== this.props.selected_ids) {
      this.setState({
        selected_ids: this.props.selected_ids
      });
    }
    if (prevProps.selected_objs !== this.props.selected_objs) {
      this.setState({
        selected_objs: this.props.selected_objs
      });
    }
    if (prevProps.type !== this.props.type) {
      this.setState({
        type: this.props.type
      });
    }
    if (prevProps.job_id !== this.props.job_id) {
      this.setState({
        job_id: this.props.job_id
      });
    }
    if (prevProps.details !== this.props.details) {
      this.setState({
        details: this.props.details
      });
    }
  }

  getAccountUsers = () => {
    let api = new Api();
    let userFilter = {
      fields: ['id', 'username', 'email', 'avatar_50_path'],
      where: {
        realm: UserProfile.getRealm()
      }
    };
    api.get('AccountUsers', userFilter, (status, users) => {
      // console.log(usernames);
      this.setState({
        accountUsers: users
      });
    });
  };

  warningApplicantBlocked = () => {
    const isAnyBlocked = this.state.selected_objs.some((applicant) => {
      const { emailBlocklists, docBlocklists } = applicant;
      const activeBlockValidation =
        (typeof emailBlocklists === 'object' && Object.keys(emailBlocklists).length > 0) ||
        (typeof docBlocklists === 'object' && Object.keys(docBlocklists).length > 0);
      return activeBlockValidation === true;
    });

    this.setState({
      isBlocked: isAnyBlocked
    });
  };

  createLink() {
    const selected_ids = this.state.selected_ids;

    this.setState(
      {
        _loading: true
      },
      () => {
        let limit = new Date();
        limit.setDate(new Date().getDate() + 30); // 30 Days limit

        if (selected_ids && selected_ids.length > 0) {
          let api = new Api();

          let filter = {
            fields: ['id', 'unique_name'],
            where: {
              account_id: UserProfile.getRealm(),
              suspended: false
            }
          };
          api.get('Accounts', filter, (status, accounts) => {
            const account = accounts[0];
            let payload = {
              account_id: UserProfile.getRealm(),
              job_id: this.state.job_id,
              applicants: this.state.selected_ids,
              user_id: UserProfile.getId(),
              date_limit: limit,
              type: this.state.type,
              details: this.state.details
            };
            api.post('ApplicantShareLinks', payload, (status, link) => {
              // console.log(link);
              this.setState({
                shareLink: 'https://' + account.unique_name + '.recrut.ai/share-link/' + link.id,
                shareLinkId: link.id,
                shareLinkCopied: false,
                _loading: false
              });
            });
          });
        }
      }
    );
  }

  handleOpen = () => {
    this.createLink();
  };

  handleClose = () => {
    this.setState(
      {
        open: false,
        isBlocked: false,
        modalOpen: false
      },
      () => {
        if (this.props.handlerClose) {
          this.props.handlerClose();
        }
      }
    );
  };

  handleModalOpen = () => {
    this.setState({
      open: false,
      modalOpen: true
    });
  };

  handleChangeSelectUsers = (selected) => {
    let selectedUsers = [];
    selected.forEach((option) => {
      selectedUsers.push(option.id);
    });
    this.setState({
      selectedUsers: selectedUsers
    });
  };

  sendShareLinkByEmail = (e) => {
    e.preventDefault();

    this.setState({ sendingLink: true }, () => {
      const selectedUsers = this.state.selectedUsers;

      const api = new Api();
      const params = {
        users_ids: selectedUsers,
        share_link_id: this.state.shareLinkId,
        share_link_type: this.state.type
      };

      api.post('Applicants/share-by-email', params, (status, data) => {
        if (status >= 400) {
          console.log('Error on sharing job link.');
        }
      });

      this.setState(
        {
          sendingLink: false,
          linkSent: true
        },
        () => {
          this.handleClose();
        }
      );
    });
  };

  render() {
    const { classes } = this.props;

    const listApplicants = this.state.selected_objs.map((applicant, i) => {
      let fullPath;
      if (applicant.avatar_50_path) {
        fullPath = 'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + applicant.avatar_50_path;
      }

      return (
        <ListItem key={applicant.id}>
          <ListItemAvatar
            style={{
              minWidth: 0,
              marginRight: '1rem'
            }}
          >
            <Avatar
              name={applicant.name}
              email={applicant.email}
              src={fullPath}
              maxInitials={2}
              color={CONST.APP_COLORS['primary-light']}
              size="30px"
              round={true}
            />
          </ListItemAvatar>
          <ListItemText id={i} primary={applicant.name} />
        </ListItem>
      );
    });

    const titleMessage = () => {
      let title = undefined;

      switch (this.state.type) {
        case 'APPLICANT':
          title = (
            <FormattedMessage
              id="ModalShareLink2.title_shareApplicant"
              defaultMessage="Share selected candidate/s"
            />
          );
          break;

        case 'CV':
          title = (
            <FormattedMessage
              id="ModalShareLink2.title_shareCV"
              defaultMessage="Share selected candidate's Original CV"
            />
          );
          break;

        case 'PERSONALITY':
          title = (
            <FormattedMessage
              id="ModalShareLink2.title_sharePersonality"
              defaultMessage="Share candidate's Personality Analysis Report"
            />
          );
          break;

        case 'PRINT_DETAIL':
          title = (
            <FormattedMessage
              id="ModalShareLink2.title_sharePrintDetail"
              defaultMessage="Share Recrut.AI's CV from selected candidate/s"
            />
          );
          break;

        default:
          title = (
            <FormattedMessage
              id="ModalShareLink2.title_shareApplicant"
              defaultMessage="Share selected candidate/s"
            />
          );
          break;
      }

      return title;
    };

    return (
      <>
        {this.state.isBlocked && (
          <ModalAlertBlock
            open={this.state.open}
            handleModalOpen={this.handleModalOpen}
            handlerClose={this.handleClose}
          />
        )}
        <Dialog
          fullWidth={true}
          className={classes.dialog}
          open={this.state.isBlocked ? this.state.modalOpen : this.state.open}
          onEnter={this.handleOpen}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <Backdrop open={this.state._loading} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitle
            style={{
              padding: '16px'
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
              <Box display="flex" alignItems="center">
                <Box mr={0.5}>
                  <Typography className={classes.dialogTitle}>
                    <span>{titleMessage()}</span>
                    <Tooltip
                      title={
                        <FormattedMessage
                          id="ModalShareLink2.msg_copy"
                          defaultMessage="Share this link with other users. Only account users with access to this job position will be able to view candidate information."
                        />
                      }
                    >
                      <InfoOutlinedIcon className={classes.iconInfo} fontSize="small" />
                    </Tooltip>
                  </Typography>
                </Box>
              </Box>
              <Box>
                <IconButton
                  edge="start"
                  onClick={this.handleClose}
                  aria-label="close"
                  color="primary"
                  disabled={this.state._loading}
                  style={{
                    padding: 0
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider
              style={{
                width: '100%'
              }}
            />
          </DialogTitle>
          <DialogContent
            style={{
              padding: '0 16px 16px 16px'
            }}
          >
            <Box className={classes.containerSendEmail}>
              <Box className={classes.containerWidth}>
                <Box mb={4} className={classes.containerResponsive}>
                  <Box className={classes.resetPaddingMobile}>
                    <Typography className={classes.labelSendEmail}>
                      <FormattedMessage
                        id="ModalShareLink2.label_displayLink"
                        defaultMessage="Display Link:"
                      />
                    </Typography>
                  </Box>
                  {this.state.shareLink ? (
                    <Box display="flex" alignItems="center">
                      <Typography className={classes.shareLink}>{this.state.shareLink}</Typography>
                      <Box ml={1}>
                        <CopyToClipboard
                          text={this.state.shareLink}
                          onCopy={() => this.setState({ shareLinkCopied: true })}
                        >
                          <Tooltip
                            title={<FormattedMessage id="ModalShareLink.buttonCopy" />}
                            arrow
                          >
                            <IconButton
                              color="primary"
                              style={{
                                padding: 0
                              }}
                            >
                              <FileCopyOutlinedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </CopyToClipboard>

                        <Snackbar
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                          }}
                          open={this.state.shareLinkCopied}
                          autoHideDuration={3000}
                          onClose={() => this.setState({ shareLinkCopied: false })}
                          message={<FormattedMessage id="ModalShareLink.copy_confirmed" />}
                        />
                      </Box>
                    </Box>
                  ) : null}
                  <hr className={classes.showLineMobile} />
                </Box>
                <Box display="flex" alignItems="flex-end">
                  <Box display="flex" flexDirection="column" width="100%" mr={1}>
                    <Box mb={2}>
                      <Typography className={classes.labelSendEmail}>
                        <FormattedMessage
                          id="ModalShareLink2.label_sendEmail"
                          defaultMessage="Send by Email"
                        />
                      </Typography>
                      <Typography className={classes.subtitle}>
                        <FormattedMessage
                          id="ModalShareLink2.subtitle_sendEmail"
                          defaultMessage="Select users"
                        />
                      </Typography>
                    </Box>
                    <SelectUsersMultipleCustom
                      value={this.state.selectedUsers}
                      options={this.state.accountUsers}
                      onChange={this.handleChangeSelectUsers}
                    />
                  </Box>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    open={this.state.linkSent}
                    autoHideDuration={3000}
                    onClose={() => this.setState({ linkSent: false })}
                    message={
                      <FormattedMessage
                        id="ModalShareLink2.msg_linkSent"
                        defaultMessage="Link Sent by Email"
                      />
                    }
                  />
                </Box>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  margin: '0 2rem'
                }}
              />
              <Box className={classes.containerSelectApplicants}>
                <hr className={classes.showLineMobile} />
                <Box>
                  <Typography className={classes.labelSendEmail}>
                    <FormattedMessage id="ModalCustomTestOptions.title_selectedApplicants" />
                  </Typography>
                </Box>
                {this.state.isBlocked && (
                  <Alert
                    severity="warning"
                    classes={{ icon: classes.iconWarning }}
                    style={{
                      backgroundColor: CONST.APP_COLORS['warning-light'],
                      color: CONST.APP_COLORS['warning']
                    }}
                  >
                    <Typography>
                      <FormattedMessage id="AlertApplicantBlocked.alert_selected" />
                    </Typography>
                  </Alert>
                )}
                <List dense style={{ width: '100%' }}>
                  {listApplicants}
                </List>
              </Box>
            </Box>
            <Divider
              style={{
                width: '100%',
                margin: '1rem 0'
              }}
            />
            <Box className={classes.footer}>
              <Alert
                severity="warning"
                classes={{ icon: classes.iconWarning }}
                style={{
                  backgroundColor: CONST.APP_COLORS['warning-light'],
                  color: CONST.APP_COLORS['warning']
                }}
                className={classes.alertValid}
              >
                <FormattedHTMLMessage id="ModalShareLink.alertValidity" />
              </Alert>

              <Button
                disabled={this.state.selectedUsers.length === 0 || this.state.sendingLink}
                onClick={this.sendShareLinkByEmail}
                color="primary"
                variant="outlined"
                size="small"
              >
                <FormattedMessage
                  id="ModalShareLink2.label_sendEmailButton"
                  defaultMessage="Send"
                />
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(ModalShareLink2));
