const messages = {
  tableMessages: {
    noData: 'Base.grid.noData',
    applyFilters: 'Base.grid.applyFilters'
  },
  groupingPanelMessages: {
    groupByColumn: 'Base.grid.groupByCol'
  },
  searchMessages: {
    searchPlaceholder: 'Base.grid.search'
  },
  sortMessages: {
    sortingHint: 'Base.grid.sort'
  },
  filterRowMessages: {
    filterPlaceholder: 'Base.grid.filter'
  },
  pagingPanelMessages: {
    showAll: 'Base.grid.paging_showAll',
    rowsPerPage: 'Base.grid.paging_rowsPerPage',
    info: 'Base.grid.paging_info'
  },
  exportPanelMessages: {
    showExportMenu: 'Base.grid.export',
    exportAll: 'Base.grid.exportAll',
    exportSelected: 'Base.grid.exportSelected'
  },
  summaryMessages: {
    count: 'Base.grid.count',
    avg: 'Base.grid.average',
    sum: 'Base.grid.sum',
    job_avg: 'Base.grid.average',
    sla_avg: 'Base.grid.average',
    freezing_avg: 'Base.grid.average',
    spot_sla_avg: 'Base.grid.average',
    days_open_avg: 'Base.grid.average',
    cvt_app_avg: 'Base.grid.average',
    dql_app_avg: 'Base.grid.average'
  }
};

exports.getGridMessage = function (intl, obj) {
  const keys = Object.keys(messages[obj]);
  let msgs = {};
  keys.forEach((key) => {
    msgs[key] = intl.formatMessage({ id: messages[obj][key] });
  });
  return msgs;
};
