import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import LoopIcon from '@material-ui/icons/Loop';
import UserProfile from '../../Common/UserProfile';
import * as CONST from '../../Common/constants';

const messages = defineMessages({});

class ButtonFav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      favorites: props.favorites,
      applicant_id: props.applicant_id,
      size: props.size ? props.size : 24,
      // Internal state
      _loading: false,
      userFav: false,
      otherFav: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.favorites !== this.props.favorites) {
      this.setState(
        {
          favorites: this.props.favorites
        },
        () => {
          this.loadFavs();
        }
      );
    }
  }

  componentWillMount() {
    this.loadFavs();
  }

  loadFavs() {
    const favorites = this.state.favorites;
    //console.log(favorites);

    let userFav = false;
    let otherFav = false;

    // If not null or not empty
    if (favorites && favorites.length > 0) {
      const user_id = UserProfile.getId().toString();

      // User has favorited
      if (favorites.indexOf(user_id) > -1) {
        userFav = true;
      } else {
        // Others have favorited
        otherFav = true;
      }

      this.setState({
        userFav: userFav,
        otherFav: otherFav
      });
    }
  }

  render() {
    if (this.state._loading) {
      return (
        <LoopIcon style={{ fontSize: this.state.size }} color="disabled" className="fa-spin" />
      );
    } else if (
      (UserProfile.isAdmin() ||
        UserProfile.isHRManager() ||
        UserProfile.isRecruiter() ||
        UserProfile.isJobManager()) &&
      this.state.favorites &&
      this.state.favorites.length > 0
    ) {
      const userFav = this.state.userFav;
      const otherFav = this.state.otherFav;

      let msg, icon;

      if (userFav === true) {
        icon = (
          <StarIcon
            fontSize="small"
            style={{
              color: CONST.APP_COLORS['warning-dark'],
              fontSize: this.state.size
            }}
          />
        );
      } else if (otherFav === true) {
        msg = this.props.intl.formatMessage({
          id: 'ButtonFav.othersFavoriteMsg'
        });
        icon = (
          <StarHalfIcon
            fontSize="small"
            style={{
              color: CONST.APP_COLORS['warning-dark'],
              fontSize: this.state.size
            }}
          />
        );
      }

      return icon;
    } else {
      return null;
    }
  }
}

export default injectIntl(ButtonFav);
