import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import * as CONST from '../../Common/constants';

const messages = defineMessages({
  physically_impaired: {
    id: 'InfoPwd.physically_impaired',
    defaultMessage: 'Physically Impaired'
  },
  hearing_impaired: {
    id: 'InfoPwd.hearing_impaired',
    defaultMessage: 'Hearing Impaired'
  },
  visually_impaired: {
    id: 'InfoPwd.visually_impaired',
    defaultMessage: 'Visually Impaired'
  },
  mentally_impaired: {
    id: 'InfoPwd.mentally_impaired',
    defaultMessage: 'Mentally Impaired'
  },
  multiple_disabilities: {
    id: 'InfoPwd.multiple_disabilities',
    defaultMessage: 'Multiple Disabilities'
  },
  opt_out: {
    id: 'InfoPwd.opt_out',
    defaultMessage: 'Opt Out'
  }
});

class InfoPwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pwd: props.pwd,
      size: props.size ? props.size : 24
    };
  }

  render() {
    const pwd = this.state.pwd;

    if (pwd && pwd !== 'no' && pwd !== '--') {
      let text = '';
      switch (pwd) {
        case 'physically_impaired':
          text = this.props.intl.formatMessage({
            id: 'InfoPwd.physically_impaired'
          });
          break;
        case 'hearing_impaired':
          text = this.props.intl.formatMessage({
            id: 'InfoPwd.hearing_impaired'
          });
          break;
        case 'visually_impaired':
          text = this.props.intl.formatMessage({
            id: 'InfoPwd.visually_impaired'
          });
          break;
        case 'mentally_impaired':
          text = this.props.intl.formatMessage({
            id: 'InfoPwd.mentally_impaired'
          });
          break;
        case 'multiple_disabilities':
          text = this.props.intl.formatMessage({
            id: 'InfoPwd.multiple_disabilities'
          });
          break;
        case 'OPTOUT':
          text = this.props.intl.formatMessage({
            id: 'InfoPwd.opt_out'
          });
          break;
      }
      return (
        <Tooltip title={text} arrow>
          <AccessibleForwardIcon
            style={{
              fontSize: this.state.size,
              color: CONST.APP_COLORS['gray-darker-regular']
            }}
          />
        </Tooltip>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(InfoPwd);
