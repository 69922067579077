import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Api from '../Api/Api';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import JobStatusIcon from '../Jobs/JobStatusIcon';
import AddressController from '../Common/AddressController';
import AutoCompleteJobSelect from '../Jobs/AutoCompleteJobSelect';

const messages = defineMessages({
  placeholder_selectJob: {
    id: 'SelectJob.placeholder_selectJob',
    defaultMessage: 'Select destination job'
  }
});

const useStyles = (theme) => ({
  mainButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  filterLabel: {
    display: 'flex',
    marginBottom: '0.25rem'
  },
  inputRoot: {
    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: '10.5px 14px 10.5px'
    },
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment': {
      paddingRight: '0.5rem' // probably the width of your search IconButton or more if needed
    }
  }
});

class SelectJob extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobsList: [],
      newJob: '',
      loading: false,
      selectedJob: undefined
    };
  }

  componentDidMount() {
    this.getOpenJobs();
  }

  getOpenJobs() {
    this.setState(
      {
        loading: true
      },
      () => {
        const thisStageObj = this.state.thisStageObj;
        let jobsList = [];

        let params = {
          job_id: undefined
        };
        let api = new Api();
        api.getParam('Jobs/transfer-jobs-info', params, (status, data) => {
          if (data.length > 0) {
            this.setState({
              loading: false,
              jobsList: data
            });
          }
        });
      }
    );
  }

  handleChangeSelectJob = (selected) => {
    let value = null;
    let pipeline = null;
    let newJob = '';
    if (selected) {
      value = selected.value;
      pipeline = selected.pipeline;
      newJob = selected.id;
    }

    this.setState(
      {
        newJob: newJob,
        selectedJob: selected,
        newPipeline: pipeline
      },
      () => {
        if (this.props.handleSelectJob) {
          this.props.handleSelectJob(this.state.selectedJob);
        }
      }
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        {/* <Select
          name="newJob"
          value={this.state.newJob}
          options={this.state.jobsList}
          onChange={this.handleChangeSelectJob}
          disabled={false}
          required={true}
          menuContainerStyle={{ zIndex: 999 }}
          placeholder={this.props.intl.formatMessage({
            id: 'ModalTransfer.newJob_placeholder'
          })}
        /> */}
        <AutoCompleteJobSelect
          jobsList={this.state.jobsList}
          handleSelectJob={this.handleChangeSelectJob}
        />

        {/* <div style={{ marginTop: '0.5rem' }}>
          <small className="text-muted mt">
            <FormattedMessage id="ModalTransfer.stageDestination_helper" />{' '}
            <span
              style={{
                color: '#e91e63'
              }}
            >
              <FormattedMessage id="ModalTransfer.stageDestination_helperStage" />
            </span>
          </small>
        </div> */}
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(SelectJob));
