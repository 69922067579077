// Modernizr
import './modernizr.js';

// Whirl
import 'whirl/dist/whirl.css';
// Font Awesome
import '@fortawesome/fontawesome-free/css/brands.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
// Animate.CSS
import 'animate.css/animate.min.css';
import '../node_modules/animate.css/animate.min.css';
// Simple line icons
import 'simple-line-icons/css/simple-line-icons.css';
import '../node_modules/simple-line-icons/css/simple-line-icons.css';

import '../node_modules/jquery.easing/jquery.easing.min.js';
//import "../node_modules/sweetalert/dist/sweetalert.css";
//import "../node_modules/jquery.flot.tooltip/js/jquery.flot.tooltip.min.js";

import '../node_modules/flot/jquery.flot.js';
import '../node_modules/flot/jquery.flot.categories.js';
import '../node_modules/flot/jquery.flot.pie.js';
import '../node_modules/flot/jquery.flot.resize.js';
import '../node_modules/flot/jquery.flot.time.js';
//import "./components/Common/jquery.flot.spline/jquery.flot.spline.js";
//import "./components/Common/jquery.flot.funnel/jquery.flot.funnel.js";
import '../node_modules/jquery-sparkline/jquery.sparkline.min.js';
import '../node_modules/jquery.flot.tooltip/js/jquery.flot.tooltip.min.js';

//import "../node_modules/jquery-validation/dist/jquery.validate.js";
import '../node_modules/bootstrap/js/src/tooltip.js';
import '../node_modules/bootstrap/js/src/popover.js';
//import "../node_modules/eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
//import "../node_modules/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
import '../node_modules/loaders.css/loaders.css';
import '../node_modules/spinkit/css/spinkit.css';
import '../node_modules/react-select/dist/react-select.min.css';
// Weather Icons
import 'weather-icons/css/weather-icons.min.css';
import 'weather-icons/css/weather-icons-wind.min.css';
// Linkify
import '../node_modules/linkifyjs/dist/linkify.min.js';
import '../node_modules/linkifyjs/dist/linkify-jquery.min.js';

window.$ = require('jquery');
window.$.localStorage = require('js-storage').localStorage;
// ChartJS
window.Chart = require('../node_modules/chart.js/dist/Chart.min.js');
//window.ChartFunnel = require("../node_modules/chartjs-funnel/dist/chart.funnel.bundled.min.js");
