import { SET_ACCOUNT } from '../actions/actions';
import produce from 'immer';

const INITIAL_STATE = {
  accountInfo: {
    account_id: '',
    unique_name: '',
    abbreviation: '',
    country: '',
    app_version: '',
    integration_info: {},
    config_params: {},
    crossjob_active: false,
    webhook_hire_config: {},
    blocklist_active: false,
    external_integration_info: []
  }
};

export default function accountReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_ACCOUNT: {
        draft.accountInfo = action.payload;
        break;
      }
      default:
        return state;
    }
  });
}
