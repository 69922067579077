import axios from 'axios';
// import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import UserProfile from '../Common/UserProfile';
import QueryString from 'query-string';
import swal from 'sweetalert';
import jwt from 'jsonwebtoken';
import LogRocket from 'logrocket';

/*
const messages = defineMessages({
    tokenExpired: {
        id: 'Api.tokenExpired',
        defaultMessage: 'Access expired'
    },
    tokenExpiredMsg: {
        id: 'Api.tokenExpiredMsg',
        defaultMessage: 'Please login again...'
    },
    connectionDownMsg: {
        id: 'Api.connectionDownMsg',
        defaultMessage: 'Server or Connection down. Please check your internet connection.'
    }
});
*/

var PORT = '';
var isLocalhost = false;
if (window.location.hostname == 'localhost') {
  PORT = ':3000';
  isLocalhost = true;
}
export const API_URL = window.location.protocol + '//' + window.location.hostname + PORT + '/api/';

class Api {
  constructor(props) {
    //console.log(props);
    let altHearders = null;
    let altTimeout = {};
    if (props) {
      if (props.headers) {
        altHearders = props.headers;
      }

      if (props.timeout) {
        altTimeout = {
          timeout: props.timeout
        };
      }
    }
    const token = UserProfile.getToken();
    let isRefreshingToken = false;

    if (window.location.pathname.startsWith('/register/')) {
      let axiosApi = axios.create({
        headers: {
          //Realm: realm
        }
      });
      axiosApi.interceptors.response.use(this.handleSuccess, this.handleError);
      this.axiosApi = axiosApi;
    } else if (token != null && token != '') {
      const realm = UserProfile.getRealm();

      const now = Date.now().valueOf() / 1000;

      let axiosApi = axios.create({
        headers: {
          Authorization: `Bearer ${token}`,
          Realm: realm,
          ...altHearders
        },
        ...altTimeout
      });
      const initialToken = jwt.decode(token);
      axiosApi.interceptors.response.use(
        (response) => {
          if (
            response &&
            response.headers &&
            response.headers.refresh_token &&
            initialToken.exp < now
          ) {
            if (!isRefreshingToken) {
              isRefreshingToken = true;

              const refreshToken = response.headers.refresh_token;
              // console.log(refreshToken)
              try {
                const decoded = jwt.decode(refreshToken);
                const ttl = decoded.exp;
                UserProfile.setToken(refreshToken, ttl || '');

                axiosApi.defaults.headers.common['Authorization'] = `Bearer ${refreshToken}`;
              } finally {
                isRefreshingToken = false;
              }
            }
          }
          return response;
        },
        (error) => {
          return this.handleError(error);
        }
      );
      axiosApi.defaults.headers.common['locale'] = 'en-US';
      axiosApi.defaults.headers.common['locale'] = 'en-UK';
      this.axiosApi = axiosApi;
    } else {
      this.axiosApi = null;
      swal({
        title: 'Seu acesso expirou',
        text: 'Por favor, faça o login novamente...',
        type: 'info',
        closeOnConfirm: true
      }).then((isConfirm) => {
        if (isConfirm) {
          this.axiosApi = null;
          this.redirectTo(document, '/login');
        }
      });
    }
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    //console.log(error.response);
    // SERVER OFFLINE
    if (error.response === undefined) {
      swal({
        type: 'error',
        title: error.message,
        //text: this.props.intl.formatMessage({id: 'Api.connectionDownMsg'}),
        text: 'Server or Connection down. Please check your internet connection.',
        closeOnConfirm: true
      });
      // SERVER TIMEOUT
    } else if (error.code === 'ECONNABORTED') {
      swal({
        type: 'error',
        title: error.message,
        //text: this.props.intl.formatMessage({id: 'Api.connectionTimeoutMsg'}),
        text: 'Connection Timeout',
        closeOnConfirm: true
      });
      // CUSTOM ERRORS
    } else {
      const err = error.response;
      switch (error.response.status) {
        case 400:
          console.log(error.response);
          if (sessionStorage.getItem('AUDIT_ERROS')) LogRocket.captureException(err);
          return this.handleSuccess(error.response);
        case 401:
          swal({
            title: 'Seu acesso expirou',
            text: 'Por favor, faça o login novamente...',
            type: 'info',
            closeOnConfirm: true
          }).then((isConfirm) => {
            if (isConfirm) {
              this.axiosApi = null;
              this.redirectTo(document, '/login');
            }
          });
          break;
        case 404:
          console.log(error.response);
          this.redirectTo(document, '/not-found');
          break;
        default:
          //console.log(error.response)
          if (isLocalhost) {
            swal({
              title: err.data.error.statusCode + ': ' + err.data.error.name,
              text: err.data.error.message,
              type: 'error',
              closeOnConfirm: true
            });
          } else {
            console.log(error.response);
            if (sessionStorage.getItem('AUDIT_ERROS')) LogRocket.captureException(err);
            return this.handleSuccess(error.response);
            /*
            swal({
              title: 'Erro ' + err.data.error.statusCode,
              text: err.data.error.message,
              type: 'error',
              closeOnConfirm: true
            })
            */
            //this.redirectTo(document, '/error500');
          }

          break;
      }
    }
    return Promise.reject(error);
  };

  redirectTo = (document, path) => {
    document.location = path;
  };

  get(path, filter, cb) {
    let fullPath = API_URL + path;
    if (filter !== null || filter !== {}) {
      fullPath = fullPath + '?filter=' + encodeURI(JSON.stringify(filter));
    }
    //console.log(fullPath);
    return this.axiosApi
      .get(fullPath)
      .then((response) => cb(response.status, response.data, this.axiosApi))
      .catch((error) => {
        console.error('Error on request API:', path, error);
      });
  }

  async getJobs(path, filter) {
    let fullPath = API_URL + path;
    if (filter !== null || filter !== {}) {
      fullPath = fullPath + '?filter=' + encodeURI(JSON.stringify(filter));
    }
    //console.log(fullPath);
    return await this.axiosApi.get(fullPath).then((response) => {
      return response.status, response.data, this.axiosApi;
    });
  }

  getParam(path, params, cb) {
    let fullPath = API_URL + path;
    if (params !== null || params !== {}) {
      fullPath = fullPath + '?' + QueryString.stringify(params);
    }
    //console.log(fullPath);
    return this.axiosApi
      .get(fullPath)
      .then((response) => cb(response.status, response.data, this.axiosApi));
  }

  async getParamAsync(path, params) {
    let fullPath = API_URL + path;
    //console.log(params);
    if (params !== null || params !== {}) {
      fullPath = fullPath + '?' + QueryString.stringify(params);
    }
    //console.log(fullPath);
    return await this.axiosApi.get(fullPath).then((response) => {
      return response;
    });
  }

  getCount(path, filter, cb) {
    let fullPath = API_URL + path + '/count';
    if (filter !== null || filter !== {}) {
      fullPath = fullPath + '?where=' + encodeURI(JSON.stringify(filter));
    }
    return this.axiosApi.get(fullPath).then((response) => cb(response.status, response.data));
  }

  post(path, payload, cb) {
    let fullPath = API_URL + path;
    return this.axiosApi
      .post(fullPath, payload)
      .then((response) => cb(response.status, response.data));
  }

  login(path, payload, cb) {
    let fullPath = API_URL + path;
    return this.axiosApi
      .post(fullPath, payload)
      .then((response) => cb(response.status, response.data));
  }

  update(path, id, payload, cb) {
    let fullPath = API_URL + path + '/' + id;
    return this.axiosApi
      .patch(fullPath, payload)
      .then((response) => cb(response.status, response.data));
  }

  updateMany(path, payload, filter, cb) {
    let fullPath = API_URL + path + '/update';
    if (filter != null || filter != {}) {
      fullPath = fullPath + '?where=' + encodeURI(JSON.stringify(filter));
    }

    return this.axiosApi
      .post(fullPath, payload)
      .then((response) => cb(response.status, response.data));
  }

  upsert(path, payload, cb) {
    let fullPath = API_URL + path;
    return this.axiosApi
      .put(fullPath, payload)
      .then((response) => cb(response.status, response.data));
  }

  upsertWhere(path, payload, filter, cb) {
    let fullPath = API_URL + path + '/upsertWithWhere';
    if (filter != null || filter != {}) {
      fullPath = fullPath + '?where=' + encodeURI(JSON.stringify(filter));
    }

    return this.axiosApi
      .post(fullPath, payload)
      .then((response) => cb(response.status, response.data));
  }

  postRequestURL(url, cb) {
    return this.axiosApi
      .get(url)
      .then((response) => cb(response.status, response.data))
      .catch((error) => {
        console.error('Error on request API:', url, error);
      });
  }

  registerStream(model) {
    //console.log(API_URL)
    var urlToChangeStream = `${API_URL}${model}/change-stream?_format=event-stream&access_token=${UserProfile.getToken()}`;
    var src = new EventSource(urlToChangeStream);
    src.addEventListener('data', function (msg) {
      var data = JSON.parse(msg.data);
      console.log(data); // the change object
    });
  }

  /*
    fullUpdate(path, id, payload, cb) {
        let fullPath = API_URL + path + '/' + id;
        let getFilter = {
            where: {
                id: id
            }
        }
        //console.log(fullPath);
        this.get(path, getFilter, function(status, data, api) {
            //console.log(data);
            const finalPayload = Object.assign({}, data[0], payload); // MERGING PROPERTIES
            //console.log(finalPayload);
            return api.put(fullPath, finalPayload)
            .then(
                (response) => cb(response.status, response.data) 
            );
        })
    }
    */

  delete(path, id, cb) {
    let fullPath = API_URL + path + '/' + id;
    let payload = {
      deleted: true,
      date_deleted: new Date()
    };
    return this.axiosApi
      .patch(fullPath, payload)
      .then((response) => cb(response.status, response.data));
  }

  deleteMany(path, filter, cb) {
    let fullPath = API_URL + path + '/update';
    let payload = {
      deleted: true,
      date_deleted: new Date()
    };
    if (filter != null || filter != {}) {
      fullPath = fullPath + '?where=' + encodeURI(JSON.stringify(filter));
    }

    return this.axiosApi
      .post(fullPath, payload)
      .then((response) => cb(response.status, response.data));
  }
}

export default Api;
