import * as CONST from '../Common/constants';

export default (name, email) => {
  window.zESettings = {
    webWidget: {
      color: {
        launcherText: CONST.APP_COLORS['white']
      },
      contactForm: {
        title: {
          '*': 'Precisa de ajuda?'
        }
      }
    }
  };

  window.zE('webWidget', 'prefill', {
    name: {
      value: name,
      readOnly: true
    },
    email: {
      value: email,
      readOnly: true
    }
  });
};
