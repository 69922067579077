import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import 'moment/locale/pt-br'; // FIXME locale
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ApplicantTagsSelection from '../../Applicant/Tags/ApplicantTagsSelection';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Row } from 'react-bootstrap';
import CardNoteList from './CardNoteList';
import Typography from '@material-ui/core/Typography';
import OtherJobs from './OtherJobs/OtherJobs';
import ChipSource from './ChipSource';
import CardAvatar from './CardAvatar';
import ChipInternal from './ChipInternal';
import ChipHired from './ChipHired';
import IconFavorite from './IconFavorite';
import ChipPwd from './ChipPwd';

const messages = defineMessages({});

const useStyles = (theme) => ({
  avatarContainer: {
    marginRight: '1.5rem'
  },
  disabledCard: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  optionList: {
    //height: 200,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -9,
    marginLeft: -9
  },
  applyContainer: {
    display: 'flex',
    height: '100%',
    marginTop: 4
  },
  notViewedCard: {
    borderLeft: `10px solid ${theme.palette.pink.main}`
  },
  wrappedText: {
    margin: 0,
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  darkText: {
    color: theme.palette.gray.darker
  },
  lightText: {
    color: theme.palette.gray.darkerMedium
  },
  lightBoldSmallText: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '14px',
    fontWeight: 700
  },
  boldText: {
    fontWeight: 500
  },
  smallText: {
    fontSize: '14px',
    lineHeight: 'normal'
  },
  jobsListDarkElement: {
    fontSize: '14px',
    color: theme.palette.gray.darker
  },
  jobsListLightElement: {
    fontSize: '14px',
    color: theme.palette.gray.darkerMedium
  }
});

const StyledCard = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
    boxShadow: 'none',
    position: 'relative',
    borderRadius: '10px'
  }
}))(Card);

export class CardBaseClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Props from parent
      applicant: props.applicant,
      content: props.content,
      actions: props.actions,
      title: props.title,
      relevantInfo: props.relevantInfo,
      location: props.location,
      possibleEmails: props.possibleEmails ? props.possibleEmails : [],
      jobsCount: props.jobsCount,
      //isApplicantInJob: props.isApplicantInJob,
      selectionDisabled: props.selectionDisabled,
      active: props.active ? props.active : true,
      disabled: props.disabled,
      buttonMainAction: props.buttonMainAction,
      viewed: props.viewed,
      selected: props.selected ? props.selected : false,
      variant: props.variant,
      // Internal props
      // Popover
      jobsAnchorEl: null,
      emailsAnchorEl: null,
      notesAnchorEl: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicant !== this.props.applicant) {
      this.setState({
        applicant: this.props.applicant
      });
    }

    if (prevProps.content !== this.props.content) {
      this.setState({
        content: this.props.content
      });
    }

    if (prevProps.actions !== this.props.actions) {
      this.setState({
        actions: this.props.actions
      });
    }

    if (prevProps.title !== this.props.title) {
      this.setState({
        title: this.props.title
      });
    }

    if (prevProps.relevantInfo !== this.props.relevantInfo) {
      this.setState({
        relevantInfo: this.props.relevantInfo
      });
    }

    if (prevProps.location !== this.props.location) {
      this.setState({
        location: this.props.location
      });
    }

    if (prevProps.jobsCount !== this.props.jobsCount) {
      this.setState({
        jobsCount: this.props.jobsCount
      });
    }

    /* if (prevProps.isApplicantInJob !== this.props.isApplicantInJob) {
      this.setState({
        isApplicantInJob: this.props.isApplicantInJob
      });
    } */

    if (prevProps.selectionDisabled !== this.props.selectionDisabled) {
      this.setState({
        selectionDisabled: this.props.selectionDisabled
      });
    }

    if (prevProps.active !== this.props.active) {
      this.setState({
        active: this.props.active
      });
    }

    if (prevProps.possibleEmails !== this.props.possibleEmails) {
      this.setState({
        possibleEmails: this.props.possibleEmails
      });
    }

    if (prevProps.disabled !== this.props.disabled) {
      this.setState({
        disabled: this.props.disabled
      });
    }

    if (prevProps.buttonMainAction !== this.props.buttonMainAction) {
      this.setState({
        buttonMainAction: this.props.buttonMainAction
      });
    }

    if (prevProps.viewed !== this.props.viewed) {
      this.setState({
        viewed: this.props.viewed
      });
    }

    if (prevProps.selected !== this.props.selected) {
      this.setState({
        selected: this.props.selected
      });
    }
  }

  handleClickCard = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  handleSelect = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value
      },
      () => {
        if (this.props.handleSelectApplicant) {
          this.props.handleSelectApplicant();
        }
      }
    );
  };

  handleOpenJobs = (e) => {
    this.setState({
      jobsAnchorEl: e.currentTarget
    });
  };

  handleCloseJobs = () => {
    this.setState({
      jobsAnchorEl: null
    });
  };

  handleOpenEmails = (e) => {
    this.setState({
      emailsAnchorEl: e.currentTarget
    });
  };

  handleCloseEmails = () => {
    this.setState({
      emailsAnchorEl: null
    });
  };

  handleOpenNotes = (e) => {
    this.setState({
      notesAnchorEl: e.currentTarget
    });
  };

  handleCloseNotes = () => {
    this.setState({
      notesAnchorEl: null
    });
  };

  handleUpdateFavorites = (applicantId, editedFavorites) => {
    if (this.props.handleUpdateFavorites) {
      this.props.handleUpdateFavorites(applicantId, editedFavorites);
    }
  };

  render() {
    const { classes } = this.props;
    const applicant = this.state.applicant;

    const jobsOpen = Boolean(this.state.jobsAnchorEl);
    const emailsOpen = Boolean(this.state.emailsAnchorEl);
    const notesOpen = Boolean(this.state.notesAnchorEl);

    const applicantPwd = applicant.pwd ? (
      <Box mr={1}>
        <Chip
          size="small"
          label={this.props.intl.formatMessage({
            id: 'DetailSummary.pwd'
          })}
        />
      </Box>
    ) : null;

    const relevantInfo = this.state.relevantInfo;
    const location = this.state.location;
    const jobsCount = this.state.jobsCount;
    const possibleEmails = this.state.possibleEmails;
    const selectionDisabled = this.state.selectionDisabled;
    const active = this.state.active;
    const disabled = this.state.disabled;
    const viewed = this.state.viewed;

    const hasMoreThanOneChip =
      this.state.variant !== 'hunting' && (applicant.pwd || applicant.hired || applicant.internal);

    const chipsTitle = (
      <Box display="flex" alignItems="center">
        {applicant.pwd && applicant.pwd != 'no' ? (
          <Box mr={1}>
            <ChipPwd />
          </Box>
        ) : null}
        {applicant.hired ? (
          <Box mr={1}>
            <ChipHired hired={true} />
          </Box>
        ) : null}
        {this.state.variant !== 'hunting' ? (
          <Box mr={1}>
            <ChipSource origin={applicant.origin} referral={applicant.referral_id} />
          </Box>
        ) : null}
        {applicant.internal ? (
          <Box>
            <ChipInternal
              internal_verified={applicant.internal_verified}
              employee_id={applicant.employee_id}
              employee_email={applicant.email}
            />
          </Box>
        ) : null}
      </Box>
    );

    let textTitle = (
      <Typography className={`${classes.wrappedText} ${classes.darkText} ${classes.smallText}`}>
        {/* Has scrap profile */}
        {this.state.title}
      </Typography>
    );

    let title = !hasMoreThanOneChip ? (
      <Box display="flex" alignItems="center" mt={0.5} mb={2} width="100%">
        {chipsTitle}
        {textTitle}
      </Box>
    ) : (
      <Box display="flex" alignItems="center" mt={0.5} mb={2} width="100%">
        {chipsTitle}
      </Box>
    );

    if (applicant) {
      const search_parameter =
        this.state.variant === 'hunting' ? applicant.profile_url : applicant.id;

      return (
        <StyledCard className={`${!viewed ? classes.notViewedCard : ''}`}>
          <Box
            display="flex"
            justifyContent="space-between"
            style={{
              marginLeft: !viewed ? '-10px' : '',
              minWidth: 0
            }}
          >
            {this.state.variant !== 'hunting' && (
              <Box mr={3}>
                <Box>
                  <Checkbox
                    checked={this.state.selected}
                    value={this.state.selected}
                    onChange={this.handleSelect}
                    style={{
                      padding: '12px 0'
                    }}
                    name="selected"
                    color="primary"
                    disabled={selectionDisabled}
                  />
                </Box>
              </Box>
            )}
            <Box display="flex" width="100%" minWidth={0}>
              <Box
                className={classes.avatarContainer}
                onClick={this.handleClickCard}
                style={{
                  cursor: `${!active ? 'default' : disabled ? 'not-allowed' : 'pointer'}`,
                  pointerEvents: `${disabled ? 'none' : 'auto'}`
                }}
              >
                <CardAvatar
                  applicant={applicant}
                  handleUpdateFavorites={(applicantId, editedFavorites) =>
                    this.handleUpdateFavorites(applicantId, editedFavorites)
                  }
                />
              </Box>
              <Box width="100%" minWidth={0}>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" flexDirection="column" minWidth={0}>
                    <Box display="flex" justifyContent="space-between">
                      <Box
                        minWidth={0}
                        mr={1}
                        onClick={this.handleClickCard}
                        style={{
                          cursor: `${!active ? 'default' : disabled ? 'not-allowed' : 'pointer'}`,
                          pointerEvents: `${disabled ? 'none' : 'auto'}`,
                          width: '100%'
                        }}
                      >
                        <Box display="flex">
                          <Typography
                            className={classes.darkText}
                            style={{
                              fontWeight: 'bold',
                              margin: '0 6px 0 0',
                              fontSize: '1rem'
                            }}
                          >
                            {applicant.name}
                          </Typography>
                          {applicant.favs && applicant.favs.length > 0 ? (
                            <Box>
                              <IconFavorite
                                size={20}
                                favorites={applicant.favs}
                                applicant_id={applicant.applicant_id}
                              />
                            </Box>
                          ) : null}
                          {/* <p>{applicant.email}</p> */}
                        </Box>

                        {title}
                      </Box>
                      <Box maxWidth="40%">{!active ? null : this.state.buttonMainAction}</Box>
                    </Box>
                    {hasMoreThanOneChip && (
                      <Box
                        display="flex"
                        alignItems="center"
                        mb={1}
                        width="100%"
                        onClick={this.handleClickCard}
                        style={{
                          cursor: `${!active ? 'default' : disabled ? 'not-allowed' : 'pointer'}`,
                          pointerEvents: `${disabled ? 'none' : 'auto'}`
                        }}
                      >
                        {textTitle}
                      </Box>
                    )}
                    {relevantInfo && (
                      <Box
                        width="100%"
                        onClick={this.handleClickCard}
                        style={{
                          cursor: `${!active ? 'default' : disabled ? 'not-allowed' : 'pointer'}`,
                          pointerEvents: `${disabled ? 'none' : 'auto'}`
                        }}
                      >
                        <Typography
                          className={`${classes.wrappedText} ${classes.lightText} ${classes.smallText} `}
                          style={{
                            fontWeight: 400
                          }}
                        >
                          {relevantInfo}
                        </Typography>
                      </Box>
                    )}
                    {location ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        onClick={this.handleClickCard}
                        style={{
                          cursor: `${!active ? 'default' : disabled ? 'not-allowed' : 'pointer'}`,
                          pointerEvents: `${disabled ? 'none' : 'auto'}`
                        }}
                      >
                        <Box mr={0.5}>
                          <PinDropOutlinedIcon
                            className={classes.lightText}
                            style={{
                              fontSize: '1rem'
                            }}
                          />
                        </Box>
                        <Typography
                          className={`${classes.wrappedText} ${classes.smallText} ${classes.lightText}`}
                          style={{
                            margin: 0
                          }}
                        >
                          {location}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>

                  <Box display="flex" flexDirection="column">
                    <Box className={classes.applyContainer}>
                      {/* Has jobs applications */}
                      {jobsCount > 0 ? (
                        <>
                          <Box
                            display="flex"
                            marginRight={0.5}
                            onClick={(e) => this.handleOpenJobs(e)}
                            style={{
                              cursor: 'pointer'
                            }}
                          >
                            <Typography
                              className={`${classes.lightText} ${classes.smallText} ${classes.boldText}`}
                            >
                              <span>
                                <FormattedMessage
                                  id="CardBase.msg_appliedIn"
                                  defaultMessage="Applied in:"
                                />
                                &nbsp;
                              </span>
                              <span className={`${classes.boldText} text-primary`}>
                                {jobsCount > 1 ? (
                                  <FormattedMessage
                                    id="CardBase.jobsCount"
                                    defaultMessage="{jobsCount} jobs"
                                    values={{
                                      jobsCount: jobsCount
                                    }}
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="CardBase.singleJobCount"
                                    defaultMessage="{jobsCount} job"
                                    values={{
                                      jobsCount: jobsCount
                                    }}
                                  />
                                )}
                              </span>
                            </Typography>
                            <KeyboardArrowDownIcon fontSize="small" color="primary" />
                          </Box>

                          <Popover
                            open={jobsOpen}
                            anchorEl={this.state.jobsAnchorEl}
                            onClose={this.handleCloseJobs}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left'
                            }}
                            transformOrigin={{ vertical: -20, horizontal: -20 }}
                          >
                            <Box maxHeight="440px" minWidth="320px">
                              <OtherJobs search_parameter={search_parameter} />
                            </Box>
                          </Popover>
                        </>
                      ) : null}

                      {applicant.notesCount > 0 ? (
                        <>
                          {jobsCount > 0 ? (
                            <Divider
                              style={{
                                height: '1.25rem'
                              }}
                              orientation="vertical"
                            />
                          ) : null}
                          <>
                            <Box
                              display="flex"
                              onClick={(e) => this.handleOpenNotes(e)}
                              style={{
                                margin: '0 0.5rem',
                                cursor: 'pointer'
                              }}
                            >
                              <Typography
                                className={`${classes.smallText} ${classes.boldText} text-primary`}
                                style={{
                                  margin: 0
                                }}
                              >
                                {applicant.notesCount > 1 ? (
                                  <FormattedMessage
                                    id="CardBase.notesCount"
                                    defaultMessage="{notes} notes"
                                    values={{
                                      notes: applicant.notesCount
                                    }}
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="CardBase.singleNotesCount"
                                    defaultMessage="{notes} note"
                                    values={{
                                      notes: applicant.notesCount
                                    }}
                                  />
                                )}
                              </Typography>
                            </Box>
                            <Drawer anchor="right" open={notesOpen} onClose={this.handleCloseNotes}>
                              <div style={{ minWidth: '300px', width: '40vw' }}>
                                <AppBar color="default" position="relative">
                                  <Toolbar variant="dense">
                                    <IconButton
                                      edge="start"
                                      color="inherit"
                                      onClick={this.handleCloseNotes}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                    <h6>
                                      <FormattedMessage id="ButtonNotes.modalTitle" />
                                    </h6>
                                  </Toolbar>
                                </AppBar>
                                <div className="p-lg">
                                  <hr />
                                  <Row>
                                    <Col sm={12}>
                                      <CardNoteList
                                        person_id={applicant.id}
                                        notesCount={applicant.notesCount}
                                        //handlerUpdate={(notes) => this.handleUpdateNotes(notes)}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Drawer>
                          </>
                        </>
                      ) : null}

                      {/* Has possible emails */}
                      {possibleEmails && possibleEmails.length > 0 ? (
                        <>
                          {jobsCount > 0 || applicant.notesCount > 0 ? (
                            <Divider
                              style={{
                                height: '1.25rem'
                              }}
                              orientation="vertical"
                            />
                          ) : null}
                          <>
                            <Box
                              display="flex"
                              onClick={(e) => this.handleOpenEmails(e)}
                              style={{
                                cursor: 'pointer',
                                margin: '0 0.5rem'
                              }}
                            >
                              <Typography
                                className={`${classes.smallText} ${classes.boldText} text-primary`}
                                style={{
                                  margin: 0
                                }}
                              >
                                <FormattedMessage
                                  id="CardBase.possibleEmailsCount"
                                  defaultMessage="{possibleEmails} emails"
                                  values={{
                                    possibleEmails: possibleEmails.length
                                  }}
                                />
                              </Typography>
                              <KeyboardArrowDownIcon fontSize="small" color="primary" />
                            </Box>

                            <Popover
                              open={emailsOpen}
                              anchorEl={this.state.emailsAnchorEl}
                              onClose={this.handleCloseEmails}
                            >
                              <Box maxHeight="240px">
                                <List className={classes.optionList}>
                                  {possibleEmails.map((possibleEmail, currIndex) => {
                                    return (
                                      <>
                                        <ListItem role="listitem" ContainerComponent="li">
                                          <Typography
                                            className={`${classes.darkText} ${classes.smallText}`}
                                          >
                                            {possibleEmail}
                                          </Typography>
                                        </ListItem>
                                        {currIndex < possibleEmails.length - 1 && (
                                          <Divider variant="middle" />
                                        )}
                                      </>
                                    );
                                  })}
                                </List>
                              </Box>
                            </Popover>
                          </>
                        </>
                      ) : null}
                    </Box>

                    {applicant.tags && applicant.tags.length > 0 ? (
                      <Box display="flex" alignItems="center">
                        {/* <Typography
                          className="lightText"
                          style={{
                            fontSize: '12px',
                            margin: '0 0.25rem 0 0'
                          }}
                        >
                          <FormattedMessage id="JobApplicantList.table.tags" />
                        </Typography> */}
                        <ApplicantTagsSelection
                          canChangeTags={false}
                          applicantTags={applicant.tags}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </StyledCard>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withStyles(useStyles)(CardBaseClass));
