import React, { Component } from 'react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import ToggleFullscreen from '../Common/ToggleFullscreen';
import { withRouter } from 'react-router';
import { withStyles, alpha } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import UserProfile from '../Common/UserProfile';
import Popover from '@material-ui/core/Popover';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import CardAccountLimits from '../Metrics/CardAccountLimits';
import ModalSearch from '../Search/ModalSearch';
import { Box } from '@material-ui/core';
import Avatar from 'react-avatar';

const messages = defineMessages({
  search: {
    id: 'Header.search',
    defaultMessage: 'Search candidates…'
  },
  searchJob: {
    id: 'Header.searchJob',
    defaultMessage: 'Search this job…'
  }
});

const useStyles = (theme) => ({
  grow: {
    flexGrow: 1
  },
  bar: {
    background: theme.palette.common.white,
    boxShadow: `0px 8px 20px -8px ${theme.palette.gray.darker}`
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  logo: {
    maxHeight: 40,
    marginRight: '10px'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'primary'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    color: theme.palette.secondary.main,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '200px'
    },
    fontSize: '85%'
  },
  sectionDesktop: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    },
    alignItems: 'center'
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});

class Header extends Component {
  constructor() {
    super();
    this.state = {
      // Search
      search: '',
      modalSearch: false,
      current_job_id: undefined,
      // Menu
      userAnchorEl: null
    };
  }

  toggleUserblock = (e) => {
    e.preventDefault();
    this.props.actions.toggleSetting('showUserBlock');
  };

  toggleOffsidebar = (e) => {
    e.preventDefault();
    this.props.actions.toggleSetting('offsidebarOpen');
  };

  toggleCollapsed = (e) => {
    e.preventDefault();
    this.props.actions.toggleSetting('isCollapsed');
    this.resize();
  };

  toggleAside = (e) => {
    e.preventDefault();
    this.props.actions.toggleSetting('asideToggled');
  };

  resize() {
    // all IE friendly dispatchEvent
    var evt = document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    const search = this.state.search;

    const currPath = this.props.location.pathname;
    const isJob = currPath.includes('/pipeline/') || currPath.includes('/job/');

    if (search && search !== '') {
      let baseUrl = '/search/';
      let job_id;

      if (isJob) {
        const ulrParts = currPath.split('/');
        job_id = ulrParts[2]; // FIXME: Improve this as it may break easy
        baseUrl = '/search/' + job_id + '/';
      }

      //this.props.history.push(baseUrl + encodeURIComponent(search));
      this.setState({
        modalSearch: true,
        current_job_id: isJob ? job_id : undefined
      });
    }
  };

  openUserInfo = (event) => {
    this.setState({
      userAnchorEl: event.currentTarget
    });
  };

  closeUserInfo = () => {
    this.setState({
      userAnchorEl: null
    });
  };

  openUsageInfo = (event) => {
    this.setState({
      usageAnchorEl: event.currentTarget
    });
  };

  closeUsageInfo = () => {
    this.setState({
      usageAnchorEl: null
    });
  };

  handleSearchModalClose = () => {
    this.setState({
      modalSearch: false,
      search: ''
    });
  };

  render() {
    const { classes } = this.props;

    const currPath = this.props.location.pathname;
    const isSearch = currPath.includes('/search');
    const isJob = currPath.includes('/pipeline/') || currPath.includes('/job/');
    const currentAvatarPath = this.props.currentAvatarPath;
    let fullImagePath;

    if (currentAvatarPath) {
      fullImagePath = 'https://s3-sa-east-1.amazonaws.com/recrutai-dev/' + currentAvatarPath;
    }
    return (
      <div className={classes.grow}>
        <AppBar position="fixed" className={classes.bar} color="transparent">
          <Toolbar variant="dense">
            <div className="d-none d-md-block d-lg-block d-xl-block">
              <IconButton
                edge="start"
                className={classes.menuButton}
                //color="inherit"
                aria-label="menu"
                onClick={this.toggleCollapsed}
              >
                <MenuIcon />
              </IconButton>
            </div>

            <div className="d-md-none">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={this.toggleAside}
              >
                <MenuIcon />
              </IconButton>
            </div>

            <img className={classes.logo} src="img/logo.png" alt="Recrut.AI Logo" />

            <div className={classes.grow} />

            {/* {
              // Only certain users can search
              (UserProfile.isAdmin() ||
                UserProfile.isHRManager() ||
                UserProfile.isRecruiter() ||
                UserProfile.isJobManager()) &&
              isSearch === false ? (
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <form onSubmit={this.onSearchSubmit}>
                    <InputBase
                      name="search"
                      value={this.state.search}
                      onChange={this.handleChange}
                      placeholder={
                        isJob
                          ? this.props.intl.formatMessage({ id: 'Header.searchJob' })
                          : this.props.intl.formatMessage({ id: 'Header.search' })
                      }
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                      }}
                    />
                  </form>
                </div>
              ) : null
            } */}

            <div className={classes.sectionDesktop}>
              <Tooltip
                arrow
                title={<FormattedMessage id="Header.accountUsage" defaultMessage="Usage info" />}
              >
                <IconButton edge="end" color="inherit" onClick={(e) => this.openUsageInfo(e)}>
                  <NetworkCheckIcon />
                </IconButton>
              </Tooltip>

              <Popover
                id={this.state.usageAnchorEl ? 'usage-popover' : undefined}
                open={Boolean(this.state.usageAnchorEl)}
                anchorEl={this.state.usageAnchorEl}
                onClose={this.closeUsageInfo}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <div
                  style={{
                    width: 400,
                    maxWidth: '75vw'
                  }}
                >
                  <CardAccountLimits />
                </div>
              </Popover>
              <Box ml={2}>
                <Avatar src={fullImagePath} name={UserProfile.getName()} round size="25px" />
              </Box>
              {/*}
              <Popover
                id={Boolean(this.state.userAnchorEl) ? 'user-popover' : undefined}
                open={Boolean(this.state.userAnchorEl)}
                anchorEl={this.state.userAnchorEl}
                onClose={this.closeUserInfo}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                Popover
              </Popover>
              {*/}

              <Tooltip
                arrow
                title={<FormattedMessage id="Header.fullscreen" defaultMessage="Fullscreen mode" />}
              >
                <ToggleFullscreen edge="end" aria-label="Fullscreen mode" color="inherit" />
              </Tooltip>
            </div>

            <div className={classes.sectionMobile}></div>
          </Toolbar>
        </AppBar>

        {/* <ModalSearch
          open={this.state.modalSearch}
          query={this.state.search}
          current_job_id={this.state.current_job_id}
          scope="NAME_EMAIL" // Default
          handleClose={this.handleSearchModalClose}
        /> */}
      </div>
    );
  }
}

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  user: state.user,
  currentAvatarPath: state.user?.user?.avatar_50_path
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(withStyles(useStyles)(Header))));
