import React from 'react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import * as CONST from '../../Common/constants';

const messages = defineMessages({});

const useStyles = (theme) => ({
  paper: {
    padding: theme.spacing(2)
  },
  mainContainer: {
    padding: `0 ${theme.spacing(1.5)}px`
  },
  iconActionContainer: {
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}10`
    }
  },
  dialog: {
    minWidth: 480
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white
  },
  applicantEmail: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px'
  },
  actionsLabel: {
    color: theme.palette.gray.darkerMedium,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    textAlign: 'center'
  },
  mailIcon: {
    color: theme.palette.gray.darkerMedium
  },
  actionContainer: {
    padding: `0 ${theme.spacing(3)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconButtonContainer: {
    padding: theme.spacing(1),
    color: theme.palette.gray.darkerRegular,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  iconWarningCustom: {
    color: `${theme.palette.warning.main} !important`
  }
});

class IconEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email:
        props.email &&
        !props.email.startsWith('private_') &&
        !props.email.startsWith('huntln_') &&
        !props.email.startsWith('profile:')
          ? props.email
          : null,
      verified: props.verified === true ? true : false,
      size: props.size ? props.size : 24,
      variant: props.variant,
      // Internal State
      copied: false,
      // Popover
      anchorEl: null
    };
  }

  handleOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { classes } = this.props;
    const variant = this.state.variant;

    const email = this.state.email;

    const verified = this.state.verified;
    let color = { color: CONST.APP_COLORS['gray-darker-light'] };
    if (email) {
      color = verified ? { color: CONST.APP_COLORS['green-dark'] } : color;
    } else {
      color = { color: CONST.APP_COLORS['danger-dark'] };
    }

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'email-popover' : undefined;

    if (this.state.variant === 'cv') {
      color = { color: CONST.APP_COLORS['gray-darker-regular'] };
    }

    return (
      <>
        {variant === 'cv' ? (
          <IconButton onClick={(e) => this.handleOpen(e)} className={classes.iconButtonContainer}>
            <MailOutlineIcon
              style={{
                cursor: 'pointer',
                fontSize: this.state.size
              }}
            />
          </IconButton>
        ) : (
          <MailOutlineIcon
            onClick={(e) => this.handleOpen(e)}
            style={{
              cursor: 'pointer',
              fontSize: this.state.size,
              ...color
            }}
          />
        )}
        <Popover id={id} open={open} anchorEl={this.state.anchorEl} onClose={this.handleClose}>
          <Box className={classes.mainContainer}>
            {email ? (
              <>
                <Box width="100%" py={1.5} px={2} className="text-center">
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Box mr={0.5}>
                      <MailOutlineIcon className={classes.mailIcon} fontSize="small" />
                    </Box>

                    <Box>
                      <Typography className={classes.applicantEmail}>{email}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Divider />
                <Box display="flex" alignItems="center" justifyContent="center" py={3}>
                  <Box className={classes.actionContainer}>
                    <IconButton
                      className={classes.iconActionContainer}
                      component={Link}
                      href={'mailto:' + email}
                      target="_email"
                    >
                      <MailOutlineIcon color="primary" fontSize="large" />
                    </IconButton>
                    <Box maxWidth={50}>
                      <Typography className={classes.actionsLabel}>
                        <FormattedMessage id="IconEmail.sendEmail" defaultMessage="Send e-mail" />
                      </Typography>
                    </Box>
                  </Box>

                  <Divider orientation="vertical" flexItem />

                  <Box className={classes.actionContainer}>
                    <CopyToClipboard text={email} onCopy={() => this.setState({ copied: true })}>
                      <IconButton className={classes.iconActionContainer}>
                        <FileCopyOutlinedIcon color="primary" fontSize="large" />
                      </IconButton>
                    </CopyToClipboard>

                    <Box maxWidth={50}>
                      <Typography className={classes.actionsLabel}>
                        <FormattedMessage id="IconEmail.copyEmail" defaultMessage="Copy address" />
                      </Typography>
                    </Box>

                    <Snackbar
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      open={this.state.copied}
                      autoHideDuration={3000}
                      onClose={() => this.setState({ copied: false })}
                      message={
                        <FormattedMessage
                          id="IconEmail.copy_confirmed"
                          defaultMessage="E-mail successfully copied"
                        />
                      }
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Alert
                severity="warning"
                classes={{ icon: classes.iconCustomInfo }}
                style={{
                  backgroundColor: CONST.APP_COLORS['warning-light'],
                  color: CONST.APP_COLORS['warning']
                }}
              >
                <FormattedMessage
                  id="IconEmail.noEmail"
                  defaultMessage="E-mail not found or not publicly available"
                />
              </Alert>
            )}
          </Box>
        </Popover>
      </>
    );
  }
}

export default injectIntl(withStyles(useStyles)(IconEmail));
