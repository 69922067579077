import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

// UserProfile for Access controls
import UserProfile from './components/Common/UserProfile';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
import ApplicantsSearchPage from './components/Search2/ApplicantsSearchPage';

// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const Home = lazy(() => import('./components/Home/Home'));

const Login = lazy(() => import('./components/Pages/Login'));
const LoginLoad = lazy(() => import('./components/Pages/LoginLoad'));
const ResetPassword = lazy(() => import('./components/Pages/ResetPassword'));
const UserRegister = lazy(() => import('./components/Pages/UserRegister'));
const Recover = lazy(() => import('./components/Pages/Recover'));
const Lock = lazy(() => import('./components/Pages/Lock'));
const NotFound = lazy(() => import('./components/Pages/NotFound'));
const Error500 = lazy(() => import('./components/Pages/Error500'));
const Maintenance = lazy(() => import('./components/Pages/Maintenance'));

const AccessDenied = lazy(() => import('./components/Layout/AccessDenied'));

const CandidateView = lazy(() => import('./components/Views/CandidateView'));
const ShareLink = lazy(() => import('./components/Views/ShareLink'));
const JobRequestView = lazy(() => import('./components/Views/JobRequestView'));
const HiredList = lazy(() => import('./components/Hired/HiredList'));
const ApplicantInternalList = lazy(() => import('./components/Internal/ApplicantInternalList'));

const ReferralList = lazy(() => import('./components/Referral/ReferralList'));
const ApplicantsInListInterview = lazy(() =>
  import('./components/ApplicantsInInterview/ApplicantsInListInterview')
);

// Job Requests
const JobRequestsList = lazy(() => import('./components/JobRequest/JobRequestsList'));
const JobRequestEdit = lazy(() => import('./components/JobRequest/JobRequestEdit'));

const CalendarAuth = lazy(() => import('./components/Pages/CalendarAuth'));

// Jobs
const JobsList = lazy(() => import('./components/Jobs/JobsList'));
const JobDetails = lazy(() => import('./components/Jobs/JobDetails'));
const JobEdit = lazy(() => import('./components/Jobs/JobEdit'));
const JobMap = lazy(() => import('./components/Jobs/JobMap'));
const JobScoreDist = lazy(() => import('./components/Jobs/JobScoreDist'));
const JobProfilesList = lazy(() => import('./components/JobProfile/JobProfilesList'));
const JobProfileEdit = lazy(() => import('./components/JobProfile/JobProfileEdit'));

// Company Pages
const PageList = lazy(() => import('./components/CompanyPage/PageList'));
const PageEdit = lazy(() => import('./components/CompanyPage/PageEdit'));

// Pipeline
const Pipeline = lazy(() => import('./components/Pipeline/Pipeline'));

// Settings
const Settings = lazy(() => import('./components/Settings/Settings'));

// CompanyTree
const CompanyTreeDefinition = lazy(() => import('./components/CompanyTree/CompanyTreeDefinition'));

// Job Pipeline
const JobPipelineList = lazy(() => import('./components/JobPipeline/JobPipelineList'));
const JobPipelineEdit = lazy(() => import('./components/JobPipeline/JobPipelineEdit'));

// Custom tests
const CustomTestList = lazy(() => import('./components/CustomTest/CustomTestList'));
const CustomTestEdit = lazy(() => import('./components/CustomTest/CustomTestEdit'));

// Reports
const JobReports = lazy(() => import('./components/Reports/JobReports'));
const DiversityReports = lazy(() => import('./components/Reports/DiversityReports'));
const JobAnalyticalReport = lazy(() =>
  import('./components/Reports/Components/JobAnalyticalReport')
);
const JobAllReport = lazy(() => import('./components/Reports/Components/JobAllReport'));
const JobTimeOpen = lazy(() => import('./components/Reports/Components/JobTimeOpen'));
const JobApplicantList = lazy(() => import('./components/Reports/Components/JobApplicantList'));
const BlockApplicantActivity = lazy(() =>
  import('./components/Reports/Components/BlockApplicantActivity')
);
const JobApplicantFormAnswers = lazy(() =>
  import('./components/Reports/Components/JobApplicantFormAnswers')
);
const JobGraphs = lazy(() => import('./components/Reports/Components/JobGraphs'));
const JobTimeToClose = lazy(() => import('./components/Reports/Components/JobTimeToClose'));
const JobDiversity = lazy(() => import('./components/Reports/Components/JobDiversity'));
const DiversityGraphs = lazy(() => import('./components/Reports/Components/DiversityGraphs'));
const JobUserPerformanceReport = lazy(() =>
  import('./components/Reports/Components/JobUserPerformanceReport')
);
const JobApplicantStageInfoReport = lazy(() =>
  import('./components/Reports/Components/JobApplicantStageInfoReport')
);

const AccountDashboard = lazy(() => import('./components/Reports/AccountDashboard'));

//Tags
const JobTagsList = lazy(() => import('./components/Jobs/Tags/JobTagsList'));
const ApplicantTagsList = lazy(() => import('./components/Applicant/Tags/ApplicantTagsList'));

// BlockList
const BlockList = lazy(() => import('./components/BlockList/BlockList'));

// Jobs Review
const JobsReviewList = lazy(() => import('./components/Jobs/JobReview/JobsReviewList'));
const ApplicantsReviewList = lazy(() => import('./components/Jobs/JobReview/ApplicantsReviewList'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  '/login',
  '/login-load',
  '/recover',
  '/reset-password',
  '/not-found',
  '/error-500',
  '/maintenance',
  '/register',
  '/calendar-auth',
  '/view-candidate/',
  '/viewCandidate/', // RETIRE SOON
  '/view-request/',
  '/share-link/'
];

function hasLoggedIn() {
  return UserProfile.hasLogged();
}

const Routes = ({ location }) => {
  const currentKey = location.pathname.split('/')[1] || '/';
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = 'rag-fadeIn';

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/login" component={waitFor(Login)} />
            <Route path="/login-load" component={waitFor(LoginLoad)} />
            <Route path="/register" component={waitFor(UserRegister)} />
            <Route path="/recover" component={waitFor(Recover)} />
            <Route path="/lock" component={waitFor(Lock)} />
            <Route path="/not-found" component={waitFor(NotFound)} />
            <Route path="/error500" component={waitFor(Error500)} />
            <Route path="/maintenance" component={waitFor(Maintenance)} />
            <Route path="/calendar-auth" component={(props) => <CalendarAuth {...props} />} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else if (location.pathname.startsWith('/login/')) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route
              exact
              path="/login/:ref?"
              component={waitFor((props) => {
                if (hasLoggedIn()) {
                  return <Redirect to="/home" />;
                } else {
                  return <Login {...props} />;
                }
              })}
            />
            <Route path="*" component={waitFor(NotFound)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else if (location.pathname.startsWith('/login-load/')) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route
              exact
              path="/login-load/:ref"
              component={waitFor((props) => (
                <LoginLoad {...props} />
              ))}
            />
            <Route path="*" component={waitFor(NotFound)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else if (location.pathname.startsWith('/register/')) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route
              exact
              path="/register/:invite_id"
              component={waitFor((props) => (
                <UserRegister {...props} />
              ))}
            />
            <Route path="*" component={waitFor(NotFound)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else if (location.pathname.startsWith('/reset-password/')) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route
              path="/reset-password/:token"
              component={(props) => <ResetPassword {...props} />}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else if (location.pathname.startsWith('/viewCandidate/')) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route
              path="/viewCandidate/:applicant_id"
              component={(props) => <CandidateView {...props} />}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else if (location.pathname.startsWith('/view-candidate/')) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route
              path="/view-candidate/:applicant_id"
              component={(props) => <CandidateView {...props} />}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else if (location.pathname.startsWith('/view-request/')) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/view-request/:id" component={(props) => <JobRequestView {...props} />} />
            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else if (location.pathname.startsWith('/share-link/')) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/share-link/:link_id" component={(props) => <ShareLink {...props} />} />
            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Base>
        <TransitionGroup>
          <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
            <div>
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  {/*Home*/}
                  <Route
                    path="/home"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <Home {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  {/* Jobs */}
                  <Route
                    exact
                    location={location}
                    path="/jobs"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <JobsList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/jobs/:status"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <JobsList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/hired-list"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <HiredList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/internals-list"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <ApplicantInternalList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />

                  <Route
                    exact
                    location={location}
                    path="/referral-list"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <ReferralList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/interview-list"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <ApplicantsInListInterview {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/job/add"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <JobEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/job/:id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <JobDetails {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/job/edit/:id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <JobEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/job/map/:job_id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <JobMap {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-score-dist/:job_id/:score_opt"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <JobScoreDist {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/* Jobs Review */}
                  <Route
                    exact
                    location={location}
                    path="/job-review"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isReviewer()
                        ) {
                          return <JobsReviewList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />

                  {/* Job Applicants Review */}
                  <Route
                    exact
                    location={location}
                    path="/job-review/:job_id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isReviewer()
                        ) {
                          return <ApplicantsReviewList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Pipeline*/}
                  <Route
                    exact
                    location={location}
                    path="/pipeline"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <Pipeline {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/pipeline/:job_id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <Pipeline {...props} />;
                        } else {
                          if (UserProfile.isReviewer()) {
                            let idReview = props.match.params.job_id;

                            return (
                              <Redirect
                                to={{
                                  pathname: `/job-review/${idReview}`
                                }}
                              />
                            );
                          }

                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/pipeline/:job_id/search/:query"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <Pipeline {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/pipeline/:job_id/stage/:stage_id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <Pipeline {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/pipeline/:job_id/candidate/:applicant_id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isJobManager()
                        ) {
                          return <Pipeline {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Settings*/}
                  <Route
                    exact
                    location={location}
                    path="/settings"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <Settings {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  {/*Job Profiles*/}
                  <Route
                    exact
                    location={location}
                    path="/job-profiles"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
                          return <JobProfilesList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-profiles/add"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
                          return <JobProfileEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    location={location}
                    path="/job-profiles/edit/:id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
                          return <JobProfileEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Company Tree*/}
                  <Route
                    exact
                    location={location}
                    path="/company-tree"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin()) {
                          return <CompanyTreeDefinition {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Job Pipeline List*/}
                  <Route
                    exact
                    location={location}
                    path="/job-pipeline"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
                          return <JobPipelineList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Job Pipeline Add*/}
                  <Route
                    exact
                    location={location}
                    path="/job-pipeline/add/:type"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
                          return <JobPipelineEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Job Pipeline Copy*/}
                  <Route
                    exact
                    location={location}
                    path="/job-pipeline/add-copy/:copy"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
                          return <JobPipelineEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Job Pipeline Edit*/}
                  <Route
                    exact
                    location={location}
                    path="/job-pipeline/edit/:id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
                          return <JobPipelineEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Custom Tests List*/}
                  <Route
                    exact
                    location={location}
                    path="/custom-test"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
                          return <CustomTestList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Custom Tests Add*/}
                  <Route
                    exact
                    location={location}
                    path="/custom-test/add/:type"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
                          return <CustomTestEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Custom Tests Copy*/}
                  <Route
                    exact
                    location={location}
                    path="/custom-test/add-copy/:copy"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
                          return <CustomTestEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Custom Tests Edit*/}
                  <Route
                    exact
                    location={location}
                    path="/custom-test/edit/:id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isProcessManager()) {
                          return <CustomTestEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Company Pages*/}
                  <Route
                    exact
                    location={location}
                    path="/company-page"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isPageAdmin()) {
                          return <PageList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/company-page/add"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isPageAdmin()) {
                          return <PageEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/company-page/edit/:id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isPageAdmin()) {
                          return <PageEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/* Job Tags */}
                  <Route
                    exact
                    location={location}
                    path="/job-tag-page"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isTagUser()) {
                          return <JobTagsList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/applicant-tag-page"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isTagUser()) {
                          return <ApplicantTagsList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/blocklist"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter()
                        ) {
                          return <BlockList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/* Talent Search */}
                  <Route
                    exact
                    location={location}
                    path="/search"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isRecruiter() ||
                          UserProfile.isHRManager()
                        ) {
                          return <ApplicantsSearchPage {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*Job Request*/}
                  <Route
                    exact
                    location={location}
                    path="/job-requests"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isApprover() ||
                          UserProfile.isRequester()
                        ) {
                          return <JobRequestsList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-requests/:status"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isApprover() ||
                          UserProfile.isRequester()
                        ) {
                          return <JobRequestsList {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-request/add"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRequester()
                        ) {
                          return <JobRequestEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-request/edit/:id"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRequester()
                        ) {
                          return <JobRequestEdit {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-reports"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter()
                        ) {
                          return <JobReports {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-reports/jobs-activity"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <JobAnalyticalReport {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-reports/all-jobs"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <JobAllReport {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-reports/time-open"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <JobTimeOpen {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-reports/applicants-list"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <JobApplicantList {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-reports/block-applicant-activity"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <BlockApplicantActivity {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-reports/applicant-form-answers"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <JobApplicantFormAnswers {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-reports/time-to-close"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <JobTimeToClose {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-reports/jobs-dashboard"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <JobGraphs {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-reports/stages-info"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <JobApplicantStageInfoReport {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/job-reports/users-performance"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <JobUserPerformanceReport {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/diversity-reports"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (UserProfile.isAdmin() || UserProfile.isDiversity()) {
                          return <DiversityReports {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    exact
                    location={location}
                    path="/diversity-reports/graphs"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <DiversityGraphs {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/diversity-reports/report"
                    component={(props) =>
                      hasLoggedIn() ? (
                        <JobDiversity {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login/' + encodeURIComponent(location.pathname)
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    exact
                    location={location}
                    path="/account-dashboard"
                    component={(props) => {
                      if (hasLoggedIn) {
                        if (
                          UserProfile.isAdmin() ||
                          UserProfile.isHRManager() ||
                          UserProfile.isRecruiter()
                        ) {
                          return <AccountDashboard {...props} />;
                        } else {
                          return <AccessDenied {...props} />;
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: '/login/' + encodeURIComponent(location.pathname)
                            }}
                          />
                        );
                      }
                    }}
                  />
                  {/*DEFAULT*/}
                  <Redirect to="/home" />
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};

export default withRouter(Routes);
