import * as CONST from '../Common/constants';

const K_WIDTH = 55;
const K_HEIGHT = 55;

const applicantPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT,
  border: '1px solid ' + CONST.APP_COLORS['primary'],
  borderRadius: K_HEIGHT,
  backgroundColor: 'white',
  //backgroundImage: 'img/user/avatar-empty-50.jpg',
  //backgroundSize: K_WIDTH + 'px ' + K_HEIGHT + 'px',
  //backgroundRepeat: 'no-repeat',
  textAlign: 'center',
  color: CONST.APP_COLORS['info'],
  fontSize: 16,
  fontWeight: 'bold',
  padding: 4
};

export { applicantPlaceStyle };
